import React, { useState, useEffect } from 'react';
import styles from './FilterModalV1.module.scss';
import { FilterModalComponentProps } from '../FilterModalComponentProps';
import {
  CategoryFilterMapping,
  ColorFilterMapping,
  RatingFilterMapping,
  AvailabilityFilterMapping,
  PriceRangeFilterMapping,
  CategoryFilterVersions,
  ColorFilterVersions,
  RatingFilterVersions,
  AvailabilityFilterVersions,
  PriceRangeFilterVersions,
  DefaultFilterVersions,
  DefaultFilterMapping
} from '../../Filter/FilterComponentProps';
import DefaultFilterV1 from '../../Filter/DefaultFilter/DefaultFilterV1';

const FilterModalV1: React.FC<FilterModalComponentProps> = ({
  isOpen = true,
  onClose = () => null,
  options = [ 
    { label: 'size', options: ['s', 'm', 'l', 'xl'] },  
    { label: 'material', options: [ "gold", "silver"] },
    { label: 'brand', options:[ "samsung", "apple" ] },
  ],
  showCategoryFilter = true,
  categoryFilterVersion = CategoryFilterVersions.V1,
  showColorFilter = true,
  colorFilterVersion = ColorFilterVersions.V1,
  showRatingFilter = true,
  ratingFilterVersion = RatingFilterVersions.V1,
  showAvailabilityFilter = true,
  availabilityFilterVersion = AvailabilityFilterVersions.V1,
  showPriceRangeFilter = true,
  priceRangeFilterVersion = PriceRangeFilterVersions.V1,
  defaultFilterVersion = DefaultFilterVersions.V1
}) => {
  const [internalIsOpen, setInternalIsOpen] = useState(isOpen);
  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    setInternalIsOpen(isOpen);
  }, [isOpen]);

  const handleToggle = () => {
    setInternalIsOpen((prev) => !prev);
  };

  const handleClose = () => {
    setInternalIsOpen(false);
    onClose();
  };

  const handleApply = () => {
    handleClose();
  };

  const handleOnMultiSelect = (key: string, value: any) => {
    setSelectedOptions((prev) => {
      const existingValues = prev[key];
      if (Array.isArray(existingValues)) {
        if (existingValues.includes(value)) {
          return { ...prev, [key]: existingValues.filter((item) => item !== value) };
        } else {
          return { ...prev, [key]: [...existingValues, value] };
        }
      } else {
        return { ...prev, [key]: [value] };
      }
    });
  };

  const handleOnSingleSelect = (key: string, value: any) => {
    setSelectedOptions((prev) => ({ ...prev, [key]: value }));
  };

  const SelectedCategoryFilter = CategoryFilterMapping[categoryFilterVersion];
  const SelectedColorFilter = ColorFilterMapping[colorFilterVersion];
  const SelectedRatingFilter = RatingFilterMapping[ratingFilterVersion];
  const SelectedAvailabilityFilter = AvailabilityFilterMapping[availabilityFilterVersion];
  const SelectedPriceRangeFilter = PriceRangeFilterMapping[priceRangeFilterVersion];
  const SelectedDefaultFilter = DefaultFilterMapping[defaultFilterVersion];

  return (
    <>
      <button className={styles.filterButton} onClick={handleToggle}>
        {internalIsOpen ? 'Hide Filters' : 'Show Filters'}
      </button>
      <div className={`${styles.overlay} ${internalIsOpen ? styles.show : ''}`}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h2>Filters</h2>
            <button onClick={handleClose} className={styles.closeButton}>
              &times;
            </button>
          </div>
          <div className={styles.modalContent}>
            {showCategoryFilter && (
              <SelectedCategoryFilter
                onSelect={(value:string) => handleOnMultiSelect('category', value)}
                selectedOptions={selectedOptions['category']}
              />
            )}
            {options?.map((option, index) => {
              if (option.label === 'color') {
                return (
                  <SelectedColorFilter
                    onSelect={(value:string) => handleOnMultiSelect('color', value)}
                    selectedOptions={selectedOptions['color']}
                />
                )
              }
              else {
                return (
                  <SelectedDefaultFilter
                    label={option.label}
                     options={option.options}
                     onSelect={(value:any)=>handleOnMultiSelect(option.label, value)}
                     selectedOptions={selectedOptions[option.label]}
                    />
                )
              }
            })}
            {showRatingFilter && (
              <SelectedRatingFilter onRatingSelect={(value:any) => handleOnSingleSelect('rating', value)} />
            )}
            {showAvailabilityFilter && (
              <SelectedAvailabilityFilter
                onSelect={(value:any) => handleOnMultiSelect('availability', value)}
                selectedOptions={selectedOptions['availability']}
              />
            )}
            {showPriceRangeFilter && (
              <SelectedPriceRangeFilter
                onPriceChange={(min:number, max:number) => handleOnSingleSelect('price', { min, max })}
              />
            )}
          </div>
          <div className={styles.modalFooter}>
            <button className={styles.applyButton} onClick={handleApply}>
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterModalV1;
