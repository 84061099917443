import React from 'react';
import styles from './PaymentMethodsList.module.scss';
import { PaymentMethodType } from '../../../../types/Data/PayementSettingsType';

interface PaymentMethodsTableProps {
    paymentMethods: PaymentMethodType[];
    onEdit: (name: string) => void;
    onDelete: (name: string) => void;
    onToggleStatus: (name: string, currentStatus: boolean) => void;
}

const PaymentMethodsTable: React.FC<PaymentMethodsTableProps> = ({ paymentMethods, onEdit, onDelete, onToggleStatus }) => {
    return (
        <div className={styles.tableContainer}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Public Key</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {paymentMethods.map((method) => (
                        <tr key={method.name}>
                            <td>{method.name}</td>
                            <td>{method.publicKey ? method.publicKey : 'N/A'}</td>
                            <td className={method.enabled ? styles.enabled : styles.disabled}>
                                {method.enabled ? 'Enabled' : 'Disabled'}
                            </td>
                            <td className={styles.actionButtons}>
                                <button className={styles.editButton} onClick={() => onEdit(method.name)}>Edit</button>
                                <button className={styles.deleteButton} onClick={() => onDelete(method.name)}>Delete</button>
                                <button 
                                    className={method.enabled ? styles.disableButton : styles.enableButton} 
                                    onClick={() => onToggleStatus(method.name, method.enabled)}
                                >
                                    {method.enabled ? 'Disable' : 'Enable'}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PaymentMethodsTable;
