import React from 'react'
import styles from './IncentiveV5.module.scss';
import { IncentiveComponentProps } from '../IncentiveComponentProps';
import { getIconComponent } from '../IncentiveEditor/util';

const IncentiveV5: React.FC<IncentiveComponentProps> = ({ title, description, items }) => {
  return (
    <div className={styles.incentiveContainer}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>
      <div className={styles.cardList}>
        {(items ?? []).map((item, index) => (
          <div key={index} className={styles.incentiveCard}>
            <div className={styles.incentiveIcon}>
            {item?.icon ? getIconComponent(item?.icon) : null}
            </div>
            <p className={styles.incentiveTitle}>{item.title}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default IncentiveV5;
