import React from 'react'
import './SectionHeader1.scss'

interface SectionHeader1Props {
    label?:string;
    title?:string;
    description?:string;
}

const SectionHeader1:React.FC<SectionHeader1Props> = ({title, description, label}) => {
  return (
    <div className='sectionHeader1'>
        <p className='label'>{label}</p>
        <h2 className='title'>{title}</h2>
        <p className='description'>{description}</p>
    </div>
  )
}

SectionHeader1.defaultProps = {
    label: '',
    title: 'Section Titlte',
    description: ''

}
export default SectionHeader1