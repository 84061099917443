import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles for React Quill
import styles from './EmailEditorV1.module.scss';

interface EmailEditorProps {
    value?: string,
    onChange?: (htmlString: string) => void,
}

const EmailEditorV1:React.FC<EmailEditorProps> = ({value='', onChange = ()=>null}) => {
    return (
        <div className={styles.editorContainer}>
            <h1 className={styles.title}>Email Editor</h1>
            <div className={styles.editorSection}>
                <ReactQuill
                    value={value}
                    onChange={onChange}
                    className={styles.editor}
                    placeholder="Compose your email..."
                />
            </div>
            <div className={styles.previewSection}>
                <h2>Preview</h2>
                <div
                    className={styles.previewContent}
                    dangerouslySetInnerHTML={{ __html: value }}
                />
            </div>
        </div>
    );
};

export default EmailEditorV1;
