import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaxRateType, TaxSettingsType } from '../../../types/Data/TaxSettingsType';

const initialState: TaxSettingsType = {
  taxRates: [],
};

const taxSettingsSlice = createSlice({
  name: 'taxSettings',
  initialState,
  reducers: {
    addTaxRate(state, action: PayloadAction<TaxRateType>) {
      state.taxRates.push(action.payload);
    },
    updateTaxRate(state, action: PayloadAction<TaxRateType>) {
      const index = state.taxRates.findIndex(rate => rate.id === action.payload.id);
      if (index !== -1) {
        state.taxRates[index] = action.payload;
      }
    },
    deleteTaxRate(state, action: PayloadAction<string>) {
      state.taxRates = state.taxRates.filter(rate => rate.id !== action.payload);
    },
  },
});

export const {
  addTaxRate,
  updateTaxRate,
  deleteTaxRate,
} = taxSettingsSlice.actions;

export default taxSettingsSlice.reducer;
