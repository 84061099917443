import React, { useEffect, useState } from "react";
import DashboardHeader from "../../components/Header/DashboardHeader/DashboardHeader";
import ReviewList from "./ReviewList.tsx/ReviewList";
import Filters from "./ReviewFilters/Filters";
import styles from './ReviewListPage.module.scss'
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store/store";
import { ReviewType } from "../../types/Data/ReviewType";
import { dispatchLoadReviews, fetchReviews } from "../DashboardState/reviews/reviewSlice";
import reviewsData from "../DashboardState/reviews/reviewsData";

const ReviewListPage = () => {
    const dispatch = useDispatch<AppDispatch>();

    const [query, setQuery] = useState({
        search: "",
        rating: undefined,
        status: undefined,
        flagged: undefined,
        sortBy: undefined,
        order: 'asc',
    });

    const [page, setPage] = useState<number>(1); // Separate page state
    
    const reviews = useSelector<RootState>((state) => state.dashboard.reviews.reviews) as Partial<ReviewType>[];
    const loading = useSelector<RootState, boolean>((state) => state.dashboard.reviews.loading);
    const error = useSelector<RootState, string | null>((state) => state.dashboard.reviews.error);
    
    const handleFilterChange = (key: string, value: any) => {
        setQuery((prevQuery) => ({ ...prevQuery, [key]: value }));
        setPage(1); // Reset page to 1 when query changes
    };
    
    /*
    useEffect(() => {
        dispatch(fetchReviews({ ...query, page }));
    }, [query]);
    */
    
    return (
        <div>
            <DashboardHeader title="Reveiw List"/>
            <div className={styles.content}>
                <Filters query={query} onQueryChange={handleFilterChange}/>
                <ReviewList/>
            </div>
        </div>
    )
};

export default ReviewListPage