import { ReactElement } from "react";
import CustomerNavigationV1 from "./CustomerNavigationV1/CustomerNavigationV1";

enum CustomerNavigationComponentVersions {
    V1 = "v1",
};

const CustomerNavigationComponentMapping:Record<CustomerNavigationComponentVersions, React.FC<any>> = {
    [CustomerNavigationComponentVersions.V1]: CustomerNavigationV1,  
};


interface CustomerNavigationComponentProps {
    tabs?: {name: string, icon: ReactElement}[];
    onClick?: (page:string) => void;
    activeTab?: string;
};

export {
    CustomerNavigationComponentVersions,
    CustomerNavigationComponentMapping
};

export type {
    CustomerNavigationComponentProps
}