import TestimonialV1 from "./TestimonialV1/TestimonialV1"
import TestimonialV2 from "./TestimonialV2/TestimonialV2"
import TestimonialV3 from "./TestimonialV3/TestimonialV3"

enum TestimonialVersions {
    V1 = 'v1',
    V2 = 'v2',
    V3 = 'v3',
}

const TestimonialMapping: Record<TestimonialVersions, React.FC<any>> = {
    [TestimonialVersions.V1]: TestimonialV1,
    [TestimonialVersions.V2]: TestimonialV2,
    [TestimonialVersions.V3]: TestimonialV3,
}

interface TestimonialComponentProps {

}

export {
    TestimonialMapping,
    TestimonialVersions,
}

export type {
    TestimonialComponentProps
}