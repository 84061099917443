import React from 'react';
import styles from './ColorPickerV1.module.scss';
import { ColorPickerComponentProps } from '../ColorPickerComponentProps';

const ColorPickerV1: React.FC<ColorPickerComponentProps> = ({ label, color, onChange }) => {
    return (
        <div className={styles.colorPickerContainer}>
            { label && <label className={styles.colorPickerLabel}>{label}</label> }
            <input
                type="color"
                value={color || undefined}
                onChange={(e) => onChange && onChange(e.target.value)}
                className={styles.colorPickerInput}
            />
        </div>
    );
};

export default ColorPickerV1;
