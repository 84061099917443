import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Store/store';
import { NavigationItem } from '../../../../types/navigation';
import styles from './TabNavigationCategoriesSelection.module.scss';
import { CategoryType } from '../../../../types/Data/CategoryType';

interface TabNavigationCategoriesSelectionProps {
  items: NavigationItem[];
  onItemChange: (items: NavigationItem[]) => void;
}

const TabNavigationCategoriesSelection: React.FC<TabNavigationCategoriesSelectionProps> = ({
  items,
  onItemChange,
}) => {
  const categories = useSelector<RootState, CategoryType[]>((state) => state.dashboard.categories.categories);
  const [expandedCategories, setExpandedCategories] = useState<Set<string>>(new Set());

  const toggleCategory = (categoryId: string) => {
    setExpandedCategories((prevState) => {
      const newExpandedCategories = new Set(prevState);
      if (newExpandedCategories.has(categoryId)) {
        newExpandedCategories.delete(categoryId);
      } else {
        newExpandedCategories.add(categoryId);
      }
      return newExpandedCategories;
    });
  };

  const handleAddCategory = (category: CategoryType) => {
    const topParentCategory = getTopParentCategory(category._id!) || category;

    const updatedItems = [...items];
    const topParentNavItemIndex = updatedItems.findIndex((item) => item.id === topParentCategory._id);

    if (topParentNavItemIndex !== -1) {
      // Update existing top parent item
      const updatedSubcategories = addSubcategoryToNavItem(updatedItems[topParentNavItemIndex].subcategories || [], category);
      updatedItems[topParentNavItemIndex] = {
        ...updatedItems[topParentNavItemIndex],
        subcategories: updatedSubcategories,
      };
    } else {
      // Add new top parent navigation item
      const newSubItem: NavigationItem = {
        id: category._id!,
        name: category.name,
        link: `/${category.name.toLowerCase().replace(/\s+/g, '-')}`,
      };

      const newTopParentItem: NavigationItem = {
        id: topParentCategory._id!,
        name: topParentCategory.name,
        link: `/${topParentCategory.name.toLowerCase().replace(/\s+/g, '-')}`,
        subcategories: category._id !== topParentCategory._id ? [newSubItem] : [],
      };

      updatedItems.push(newTopParentItem);
    }

    onItemChange(updatedItems);
  };

  const addSubcategoryToNavItem = (subcategories: NavigationItem[], category: CategoryType): NavigationItem[] => {
    if (subcategories.some((sub) => sub.id === category._id)) {
      return subcategories;
    }

    return [
      ...subcategories,
      {
        id: category._id!,
        name: category.name,
        link: `/${category.name.toLowerCase().replace(/\s+/g, '-')}`,
      },
    ];
  };

  const getTopParentCategory = (categoryId: string): CategoryType | undefined => {
    let currentCategory = categories.find((category) => category._id === categoryId);
    while (currentCategory && currentCategory.parentCategoryId) {
      currentCategory = categories.find((category) => category._id === currentCategory?.parentCategoryId!);
    }
    return currentCategory;
  };

  const isInNavigation = (categoryId: string) => {
    return (
      items.some((item) => item.id === categoryId) ||
      items.some((item) => item.subcategories && item.subcategories.some((sub) => sub.id === categoryId))
    );
  };

  const handleRemoveCategory = (category: CategoryType) => {
    const updatedItems = items.map((item) => {
      if (item.id === category._id) {
        // Remove the entire item if it matches the category ID
        return null;
      }
      if (item.subcategories) {
        // Remove the subcategory if it matches the category ID
        const updatedSubcategories = item.subcategories.filter((sub) => sub.id !== category._id);
        return { ...item, subcategories: updatedSubcategories };
      }
      return item;
    }).filter(Boolean) as NavigationItem[];

    onItemChange(updatedItems);
  };

  const renderCategories = (categories: CategoryType[], parentId: string | null = null) => {
    return categories
      .filter((category) => category.parentCategoryId === parentId)
      .map((category) => (
        <div key={category._id} className={styles.category_item}>
          <div className={styles.category_header}>
            <button className={styles.toggle_button} onClick={() => toggleCategory(category._id!)}>
              {expandedCategories.has(category._id!) ? '-' : '+'}
            </button>
            <span>{category.name}</span>
            {isInNavigation(category._id!) && <span className={styles.chevron}>&#10003;</span>}
            {!isInNavigation(category._id!) ? 
            <button className={styles.add_button} onClick={() => handleAddCategory(category)}>
              Add
            </button>
            :
            <button className={styles.remove_button} onClick={() => handleRemoveCategory(category)}>
              Remove
            </button>
          }
          </div>
          {expandedCategories.has(category._id!) && (
            <div className={styles.subcategory_list}>{renderCategories(categories, category._id)}</div>
          )}
        </div>
      ));
  };

  return (
    <div className={styles.category_selection}>
      <h2>Select Categories</h2>
      <div className={styles.category_list}>{renderCategories(categories)}</div>
    </div>
  );
};

export default TabNavigationCategoriesSelection;
