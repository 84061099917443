import React from 'react';
import styles from './ImagesFilter.module.scss';
import SearchBar4 from '../../../components/SearchBar/SearchBarV4/SerachBarV4';
import Select4 from '../../../components/Select/Select4/Select4';
import InputCalendar from '../../../components/Input/InputCalendar/InputCalendarV1/InputCalendarV1';

interface FiltersProps {
    query: {
        search: string;
        category: string;
    };
    onQueryChange: (key: string, value: any) => void;
}

const ImagesFilters: React.FC<FiltersProps> = ({ query, onQueryChange }) => {
    const categoryOptions = [
        { value: 'Products', label: 'Products' },
        { value: 'Assets', label: 'Assets' },
        { value: 'Blogs', label: 'Blogs' },
    ];

    return (
        <div className={styles.filters}>
            <SearchBar4
                placeholder="Search by title, alt, url..."
                value={query.search}
                onSearch={(search) => onQueryChange('search', search)}
            />
            <div className={styles.selectRow}>
                <Select4
                    options={categoryOptions}
                    value={query.category}
                    onChange={(value) => onQueryChange('status', value as string)}
                    placeholder="Select status"
                />
            </div>
        </div>
    );
};

export default ImagesFilters;
