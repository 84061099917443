import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerType } from '../../../types/Data/CustomerType';
import { WishlistItemType } from '../../../types/Data/WishlistType';
import { OrderType } from '../../../types/Data/OrderType';
import { AddressType } from '../../../types/Data/AdressType';

interface UserState {
  isAuthenticated: boolean;
  userInfo: CustomerType | null;
  shippingAddress: AddressType;
  billingAddress: AddressType;
  wishlist: WishlistItemType[];
  orderHistory: Partial<OrderType>[];
}

// Define initial state
const initialState: UserState = {
  isAuthenticated: false,
  userInfo: {
    _id: "643f1f8e5b34a28e68a6c7a5",
    accountId: "8291fe82-38f4-472d-bbcb-9f1c42f4f6cb",
    name: "John Doe",
    email: "john.doe@example.com",
    phone: "+1 555-123-4567",
    shippingAddress: {
      street: "123 Elm Street",
      city: "Springfield",
      state: "IL",
      postalCode: "62704",
      country: "USA",
    },
    billingAddress: {
      street: "456 Oak Avenue",
      city: "Springfield",
      state: "IL",
      postalCode: "62705",
      country: "USA",
    },
    age: 35,
    gender: "male",
    totalSpend: 2500.75,
    numOrders: 15,
    lastOrderDate: "2024-09-28T14:45:30Z",
    preferredLanguage: "English",
    communicationPreference: "email",
    loyaltyLevel: "Gold",
    notes: "Regular customer, prefers email communication",
    createdAt: new Date("2022-04-15T10:00:00Z"),
    updatedAt: new Date("2024-10-03T15:30:00Z"),
  },
  shippingAddress: {
    street: "123 Elm Street",
    city: "Springfield",
    state: "IL",
    postalCode: "62704",
    country: "USA",
  },
  billingAddress: {
    street: "456 Oak Avenue",
    city: "Springfield",
    state: "IL",
    postalCode: "62705",
    country: "USA",
  },
  wishlist: [],
  orderHistory: [],
};

// Create the slice
const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    // Authenticate user
    setAuthentication(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
    // Update customer information
    updateCustomerInfo(state, action: PayloadAction<Partial<CustomerType>>) {
      state.userInfo = state.userInfo ? { ...state?.userInfo, ...action.payload } : null;
    },
    // Reset customer to initial state
    resetCustomer(state) {
      Object.assign(state, initialState);
    },
    // Update shipping address
    updateShippingAddress(state, action: PayloadAction<Partial<AddressType>>) {
      state.shippingAddress = { ...state.shippingAddress, ...action.payload };
    },
    // Update billing address
    updateBillingAddress(state, action: PayloadAction<Partial<AddressType>>) {
      state.billingAddress = { ...state.billingAddress, ...action.payload };
    },
    // Add item to wishlist
    addToWishlist(state, action: PayloadAction<WishlistItemType>) {
      const itemExists = state.wishlist.some(item => item.product === action.payload.product);
      if (!itemExists) {
        state.wishlist.push(action.payload);
      }
    },
    // Remove item from wishlist
    removeFromWishlist(state, action: PayloadAction<string>) {
      state.wishlist = state.wishlist.filter(item => item.product !== action.payload);
    },
    // Add an order to order history
    addOrderToHistory(state, action: PayloadAction<OrderType>) {
      state.orderHistory.unshift(action.payload);
    },
    // Clear order history (useful for account deletion)
    clearOrderHistory(state) {
      state.orderHistory = [];
    },
  },
});

// Export actions
export const {
  setAuthentication,
  updateCustomerInfo,
  resetCustomer,
  updateShippingAddress,
  updateBillingAddress,
  addToWishlist,
  removeFromWishlist,
  addOrderToHistory,
  clearOrderHistory,
} = customerSlice.actions;

// Export the reducer
export default customerSlice.reducer;
