import React from "react";
import { useNavigate } from "react-router-dom";
import styles from './EcommerceHome.module.scss';
import renderPageElement from "../renderPageElement";
import { ComponentPropsMapping, ComponentType } from "../../types/Data/BaseElementType";
import { useDispatch } from "react-redux";
import { dispatchLoadProducts } from "../../Dashboard/DashboardState/products/productsSlice";
import techProducts from "../../Dashboard/DashboardState/products/dataProducts";
import { useSelector } from "react-redux";
import { RootState } from "../../Store/store";

const EcommerceHome: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const homePageElements = useSelector((state:RootState)=> state.ecommerce.ui.elements.filter((el) => el.page === 'home'));

    const handleProductClick = (productId: string) => {
        navigate(`./product/${productId}`)
    };

    const handleCategoryClick = (categoryName: string) => {
        navigate(`./shop/?category=${categoryName}`)
    };

    const handleBlogClick = (blogId: string) => {
        navigate(`./blog/${blogId}`)
    };


    const getComponentProps = (
        componentType: ComponentType,
      ) => {
        switch (componentType) {
          case ComponentType.FeaturedProducts:
            return {
              onClick: handleProductClick,
            };
          case ComponentType.BlogSection:
            return {
                onClick: handleBlogClick,
             };
          case ComponentType.CategoryDisplay:
            return {
                onClick: handleCategoryClick,
                };
          default:
            return {};
        }
      };
      
      //dispatch(dispatchLoadProducts(techProducts))
    return (
        <div className={styles.page}>
            <div className={styles.grid}>
                {homePageElements.map((element, index) => {
                    const Component = renderPageElement(element);
                    const { row, column, span, order, spanRows } = element.position || {};
                    const props = {...getComponentProps(element.type), ...element.props}
                    return (
                        <div
                            key={index}  // Add unique key to prevent warnings
                            className={styles.gridItem}  // Add a specific class for the grid items
                            style={{
                                gridColumn: column ? `${column} / span ${span || 1}` : '1 / span 12',  // Set column start and span
                                gridRow: row && spanRows ? `${row} / span ${spanRows}` : row || 'auto',  // Set row start and span
                                order: order || 'initial',  // Set the order in case it's specified
                            }}
                        >
                            <Component {...props} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default EcommerceHome;
