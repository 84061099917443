import React from 'react';
import styles from './FeaturedProductsV7.module.scss';
import { ProductType } from '../../../types/Data/ProductType';
import exampleProducts from '../../../Dashboard/DashboardState/products/dataProducts';
import { FeaturedProductsComponentProps } from '../FeaturedProductsComponentProps';

const FeaturedProductsV7:React.FC<FeaturedProductsComponentProps> = ({ products = exampleProducts.slice(0, 5) }) => {
  return (
    <div className={styles.listContainer}>
      {products.map((product, index) => (
        <div key={index} className={styles.productItem}>
          <img src={product?.images![0].url} alt={product.name} className={styles.productImage} />
          <div className={styles.productInfo}>
            <h3 className={styles.productName}>{product.name}</h3>
            <p className={styles.productPrice}>${product.price}</p>
            <button className={styles.detailsButton}>View Details</button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeaturedProductsV7;
