import React from "react";
import styles from './CancellationInfo.module.scss';
import { CancellationType } from "../../../../types/Data/CancellationType";

interface CancellationInfoProps {
    cancellation: CancellationType;
}

const CancellationInfo: React.FC<CancellationInfoProps> = ({ cancellation }) => {
    return (
        <div className={styles.details}>
            <div className={styles.detailItem}>
                <strong>Customer:</strong> {cancellation?.customerName}
            </div>
            <hr className={styles.separator} />
            <div className={styles.detailItem}>
                <strong>Cancellation Date:</strong> {cancellation?.createdAt!.toDateString()}
            </div>
            <hr className={styles.separator} />
            <div className={styles.detailItem}>
                <strong>Reason:</strong> {cancellation?.reason}
            </div>
            <hr className={styles.separator} />
            <div className={styles.detailItem}>
                <strong>Status:</strong> {cancellation?.status}
            </div>
            <hr className={styles.separator} />
            <div className={styles.detailItem}>
                <strong>Refund Amount:</strong> ${cancellation?.refundAmount}
            </div>
            <hr className={styles.separator} />
            <div className={styles.detailItem}>
                <strong>Payment Method:</strong> {cancellation?.paymentInfo?.paymentMethod}
            </div>
        </div>
    );
};

export default CancellationInfo;
