import React, { useEffect, useState } from 'react';
import styles from '../../UIElementsMainSettings/UiElementEditor.module.scss';

import { BaseElementType, ComponentType } from '../../../types/Data/BaseElementType';
import ButtonV1 from '../../../components/Button/ButtonV1/ButtonV1';
import { defaultProductImageGalleryProps } from '../defaultProductImageGalleryProps';
import { ProductImageGalleryComponentProps, ProductImageGalleryMapping, ProductImageGalleryVersions } from '../ProductImageGalleryComponentProps';
import UIElementsMainSettings from '../../UIElementsMainSettings/UIElementsMainSettings';
import ButtonDelete from '../../../components/Button/ButtonDelete/ButtonDelete';

interface ImageGalleryEditorProps {
  element?: BaseElementType<ProductImageGalleryComponentProps>;
}

const ProductImageGalleryComponentEditor: React.FC<ImageGalleryEditorProps> = ({
  element,
}) => {
  
  const defaultElement: BaseElementType<ProductImageGalleryComponentProps> = {
    type: ComponentType.ProductImageGallery,
    version: 'v1',
    page: 'product',
    props: defaultProductImageGalleryProps,
    position: undefined,
    isVisible: true,
  };

  const [currentElement, setCurrentElement] = useState(element ? element : defaultElement);

  useEffect(() => {
    if (element) {
      setCurrentElement(element)
    } else {
      setCurrentElement(defaultElement)
    }
  }, [element])

  const handleElementUpdate = (key:string, value: any, ) => {
      setCurrentElement((prevElement) => ({
        ...prevElement,
        [key]: value,
      }));
  };

  const handlePropsChange = (key:string, value:any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      props: {
        ...prevElement.props,
        [key]: value,
      }
    }));
  }


  const handleElementVisibilityUpdate = () => {
    setCurrentElement(prev => ({ ...prev, isVisible: !prev.isVisible }));
  };

  const SelectedComponent = ProductImageGalleryMapping[currentElement.version as ProductImageGalleryVersions] || ProductImageGalleryMapping[ProductImageGalleryVersions.V1];
  
  const elementsVersion = Object.keys(ProductImageGalleryVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: ProductImageGalleryVersions[key as keyof typeof ProductImageGalleryVersions],
  }));


  return (
    <div className={styles.container}>
      
      <div className={styles.componentPreview}>
        <SelectedComponent banner={currentElement?.props} />
      </div>

      <div className={styles.componentEditor}>
      <UIElementsMainSettings
            element={currentElement}
            elementVersions={elementsVersion}
            onElementUpdate={handleElementUpdate}
            onVisibilitytUpdate={handleElementVisibilityUpdate}
        />
      </div>
      <div className={styles.buttonContainer}>
        <ButtonV1 label="Save Element" onClick={()=>null} />
        <ButtonDelete/>
      </div>
    </div>
  );
};

export default ProductImageGalleryComponentEditor;
