import { CancellationStatus, CancellationType } from "../../../types/Data/CancellationType";

const exempleCancellations: CancellationType[] = [
    {
        _id: '1',
        order: '101',
        customerName: 'Alice Johnson',
        createdAt: new Date('2024-07-10'),
        reason: 'Incorrect size',
        items: [
            { product: '1', productName: 'T-shirt', price: 20.00, quantity: 2 },
            { product: '2', productName: 'Jeans', price: 40.00, quantity: 1 },
        ],
        refundAmount: 80.00,
        paymentInfo: {
            paymentMethod: 'Credit Card',
            paymentProvider: 'Stripe',
            paymentStatus: 'Pending',
            transactionId: 'ch_1Hx0X1234567890abcdef1',
            paymentFees: 2.00
        },
        status: CancellationStatus.Pending
    },
    {
        _id: '2',
        order: '102',
        customerName: 'Bob Smith',
        createdAt: new Date('2024-07-12'),
        reason: 'Changed mind',
        items: [
            { product: '3', productName: 'Shoes', price: 60.00, quantity: 1 },
        ],
        refundAmount: 60.00,
        paymentInfo: {
            paymentMethod: 'PayPal',
            paymentProvider: 'PayPal',
            paymentStatus: 'Completed',
            transactionId: 'ch_1Hx0X1234567890abcdef2',
            paymentFees: 1.50
        },
        status: CancellationStatus.Approved
    },
    {
        _id: '3',
        order: '103',
        customerName: 'Carol White',
        createdAt: new Date('2024-07-15'),
        reason: 'Product damaged',
        items: [
            { product: '4', productName: 'Watch', price: 120.00, quantity: 1 },
        ],
        refundAmount: 120.00,
        paymentInfo: {
            paymentMethod: 'Bank Transfer',
            paymentStatus: 'Failed',
            transactionId: 'ch_1Hx0X1234567890abcdef3',
            paymentFees: 3.00
        },
        status: CancellationStatus.Rejected
    },
    {
        _id: '4',
        order: '104',
        customerName: 'David Brown',
        createdAt: new Date('2024-07-20'),
        reason: 'Late delivery',
        items: [
            { product: '5', productName: 'Headphones', price: 80.00, quantity: 1 },
            { product: '6', productName: 'Keyboard', price: 50.00, quantity: 1 },
        ],
        refundAmount: 130.00,
        paymentInfo: {
            paymentMethod: 'Credit Card',
            paymentProvider: 'Stripe',
            paymentStatus: 'Pending',
            transactionId: 'ch_1Hx0X1234567890abcdef4',
            paymentFees: 2.60
        },
        status: CancellationStatus.Pending
    },
    {
        _id: '5',
        order: '105',
        customerName: 'Eve Black',
        createdAt: new Date('2024-07-22'),
        reason: 'Found cheaper alternative',
        items: [
            { product: '7', productName: 'Laptop', price: 900.00, quantity: 1 },
        ],
        refundAmount: 900.00,
        paymentInfo: {
            paymentMethod: 'Credit Card',
            paymentProvider: 'Stripe',
            paymentStatus: 'Completed',
            transactionId: 'ch_1Hx0X1234567890abcdef5',
            paymentFees: 18.00
        },
        status: CancellationStatus.Approved
    },
];

export default exempleCancellations;
