import React from 'react'
import styles from '../Component.module.scss';
import OrderSummaryV1 from '../../UIElements/OrderSummary/OrderSummaryV1/OrderSummaryV1';
import OrderSummaryV2 from '../../UIElements/OrderSummary/OrderSummaryV2/OrderSummaryV2';
import OrderSummaryV3 from '../../UIElements/OrderSummary/OrderSummaryV3/OrderSummaryV3';

const OrderSummaryComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <OrderSummaryV1/>
        </div>
        <div className={styles.element}>
            <OrderSummaryV2/>
        </div>
        <div className={styles.element}>
            <OrderSummaryV3/>
        </div>
    </div>
  )
}

export default OrderSummaryComponentPage