import { BlogPostType } from "../../../types/Data/BlogPostType";

const exampleBlogPost:BlogPostType[] = [
    {
    _id: '1',
    title: 'The Future of Web Development',
    content: `
        <div>
      <p>Web development is constantly evolving, with new technologies and frameworks emerging all the time. In this post, we explore the latest trends and what the future might hold for web developers.</p>
      <h2>New Technologies</h2>
      <p>Technologies such as WebAssembly and Progressive Web Apps are changing the landscape.</p>
      <ul>
        <li>WebAssembly</li>
        <li>Progressive Web Apps</li>
        <li>Serverless Architecture</li>
      </ul>
      </div>
    `,
    author: 'John Doe',
    createdAt: new Date('2023-01-15'),
    tags: ['Web Development', 'Future Trends', 'JavaScript'],
    imageUrl: 'https://images.unsplash.com/photo-1498050108023-c5249f4df085?q=80&w=3272&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    isPublished: true,
  },
  {
    _id: '2',
    title: 'Understanding React Hooks',
    content: `
    <div>
      <p>React hooks have revolutionized how we build components in React. This post provides a deep dive into hooks, how they work, and how you can use them to build more efficient and readable React applications.</p>
      <h2>Basic Hooks</h2>
      <p>React provides a few built-in hooks like <code>useState</code>, <code>useEffect</code>, and <code>useContext</code>.</p>
      </div>
    `,
    author: 'Jane Smith',
    createdAt: new Date('2023-02-20'),
    tags: ['React', 'JavaScript', 'Hooks'],
    imageUrl: 'https://images.unsplash.com/photo-1633356122544-f134324a6cee?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    isPublished: true,
  },
];

export default exampleBlogPost;