import React from 'react';
import styles from './RatingFilterV1.module.scss';
import { IoStar, IoStarOutline } from 'react-icons/io5';
import { RatingFilterComponentProps } from '../../FilterComponentProps';

const RatingFilterV1: React.FC<RatingFilterComponentProps> = ({ maxRating = 5, selectedRating = undefined, onRatingSelect = () => null }) => {
  return (
    <div className={styles.ratingFilterContainer}>
      <h3>Filter by Rating</h3>
      <div className={styles.ratingList}>
        {[...Array(maxRating)].map((_, index) => (
          <div
            key={index}
            className={styles.star}
            onClick={() => onRatingSelect(index + 1)}
          >
            {selectedRating && index < selectedRating ? <IoStar /> : <IoStarOutline />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RatingFilterV1;
