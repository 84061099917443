import { TeamMemberComponentProps } from "./TeamMemberComponentProps";

export const defaultTeamMemberProps: TeamMemberComponentProps = {
    name: 'John Doe',
    position: 'Chief Executive Officer',
    photoUrl: 'https://via.placeholder.com/150',
    bio: 'John Doe is a seasoned professional with over 20 years of experience in the industry. He is passionate about innovation and leadership.',
    socialLinks: {
      linkedin: 'https://www.linkedin.com/in/johndoe/',
      twitter: 'https://twitter.com/johndoe',
      github: 'https://github.com/johndoe'
    }
  };
  