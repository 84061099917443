import React from 'react'
import styles from '../Component.module.scss';
import ColorFilterV1 from '../../UIElements/Filter/ColorFilter/ColorFilterV1/ColorFilterV1';
import CategoryFilterV1 from '../../UIElements/Filter/CategoryFilter/CategoryFilterV1/CategoryFilterV1';
import PriceRangeFilterV1 from '../../UIElements/Filter/PriceRangeFilter/PriceRangeFilterV1/PriceRangeFilterV1';
import AvailabilityFilterV1 from '../../UIElements/Filter/AvailabilityFilter/AvailabilityFilterV1/AvailabilityFilterV1';
import DefaultFilterV1 from '../../UIElements/Filter/DefaultFilter/DefaultFilterV1';

const FilterComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <DefaultFilterV1/>
        </div>
        <div className={styles.element}>
            <ColorFilterV1/>
        </div>
        <div className={styles.element}>
            <CategoryFilterV1/>
        </div>
        <div className={styles.element}>
            <PriceRangeFilterV1/>
        </div>
        <div className={styles.element}>
            <AvailabilityFilterV1/>
        </div>
    </div>
  )
}

export default FilterComponentPage