import * as IoIcons from 'react-icons/io5';
import * as FaIcons from 'react-icons/fa';

const getIconComponent = (iconName: string) => {
  // Check first in IoIcons
  const IoIconComponent = IoIcons[iconName as keyof typeof IoIcons];
  if (IoIconComponent) {
    return <IoIconComponent />;
  }

  // Check in FaIcons if not found in IoIcons
  const FaIconComponent = FaIcons[iconName as keyof typeof FaIcons];
  return FaIconComponent ? <FaIconComponent /> : null;
};

export {
  getIconComponent
};
