import React from 'react';
import styles from './EligibleItemsTable.module.scss';
import { ReturnOrderItemType } from '../../../../types/Data/ReturnRequestType';
import { RefundItemCondition, RefundReturnedProduct } from '../../../../types/Data/RefundProcessType';

interface EligibleItemsTableProps {
  items: ReturnOrderItemType[];
  onItemSelect: (selectedItems: ReturnOrderItemType[]) => void;
}

const EligibleItemsTable: React.FC<EligibleItemsTableProps> = ({ items, onItemSelect }) => {
  const [selectedItems, setSelectedItems] = React.useState<ReturnOrderItemType[]>([]);

  const handleSelectItem = (item: ReturnOrderItemType) => {
    const updatedItems = selectedItems.some((i)=>i.product === item.product)
      ? selectedItems.filter(i => i.product !== item.product)
      : [...selectedItems, item];

    setSelectedItems(updatedItems);
    onItemSelect(updatedItems);
  };

  return (
    <div className={styles.eligibleItemsTable}>
      <h2>Eligible Items for Return</h2>
      <table>
        <thead>
          <tr>
            <th>Item</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Authorize Return</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <tr key={item.productName}>
              <td>{item.productName}</td>
              <td>{item.quantity}</td>
              <td>${(item.price * item.quantity).toFixed(2)}</td>
              <td>
                <input
                  type="checkbox"
                  checked={selectedItems.some((el)=>el.product === item.product)}
                  onChange={() => handleSelectItem(item)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EligibleItemsTable;
