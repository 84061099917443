import React, { ButtonHTMLAttributes } from 'react'
import { BsGoogle } from 'react-icons/bs';
import styles from './ButtonGoogleAuth.module.scss';

interface ButtonGoogleAuth extends ButtonHTMLAttributes<HTMLButtonElement> {

}

const ButtonGoogleAuth:React.FC<ButtonGoogleAuth> = ({...props}) => {
  return (
    <button className={styles.button_google_auth} {...props}>
        <BsGoogle className={styles.google_icon}/>
        Sign in with Google
    </button>
  )
}

export default ButtonGoogleAuth