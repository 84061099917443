import React, { useState } from 'react';
import styles from './FormLogin.module.scss';
import Input1 from '../../Input/InputText/InputV1/InputV1';
import Button1 from '../../Button/ButtonV1/ButtonV1';
import GoogleAuth from '../../Button/ButtonGoogleAuth/GoogleAuth';
import { useNavigate } from 'react-router-dom';

interface LoginFormProps {
    onSubmit?: (username: string, password: string) => void;
}

const FormLogin: React.FC<LoginFormProps> = ({ onSubmit = () => null }) => {
    const navigate = useNavigate();

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (username === '' || password === '') {
            setError('Please fill in both fields');
        } else {
            setError('');
            onSubmit(username, password);
        }
    };

    const handleNavigateRegister = () => {
        navigate('/ecommerce/register')
    };

    return (
        <div className={styles.loginForm}>
            <form onSubmit={handleSubmit} className={styles.formContainer}>
                <h2 className={styles.title}>Sign in to your account</h2>
                <p className={styles.registerText}>
                    Not a member?{' '}
                    <button type="button" className={styles.registerButton} onClick={handleNavigateRegister}>
                        Register
                    </button>
                </p>
                {error && <p className={styles.error}>{error}</p>}
                <div className={styles.inputGroup}>
                    <Input1 
                        name='email' 
                        label='Email' 
                        type='email' 
                        value={username} 
                        onChange={(e) => setUsername(e.target.value)} 
                    />
                    <Input1 
                        name='password' 
                        label='Password' 
                        type='password' 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        marginTop={40} 
                    />
                </div>
                <div className={styles.buttonContainer}>
                    <Button1 type='submit' title='Login' onClick={() => null} />
                </div>
                <p className={styles.forgotPassword}>Forgot your password?</p>
            </form>
            <div className={styles.separationRow}>
                <div className={styles.separationLine} />
                <p>Or continue with</p>
                <div className={styles.separationLine} />
            </div>
            <GoogleAuth />
        </div>
    );
};

export default FormLogin;
