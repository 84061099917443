import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import styles from './ChartCancellationMainReasons.module.scss';

const data = [
    { reason: "Out of Stock", count: 50 },
    { reason: "Found a Better Price", count: 30 },
    { reason: "Changed Mind", count: 20 },
];

const ChartCancellationMainReasons: React.FC = () => {
    const [chartData, setChartData] = useState<ChartData<'pie'> | null>(null);

    useEffect(() => {
        const labels = data.map((entry: any) => entry.reason);
        const values = data.map((entry: any) => entry.count);
        setChartData({
            labels: labels,
            datasets: [
                {
                    label: 'Reasons for Cancellations',
                    data: values,
                    backgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#FFCE56',
                        '#4BC0C0',
                        '#9966FF',
                        '#FF9F40',
                    ],
                    hoverBackgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#FFCE56',
                        '#4BC0C0',
                        '#9966FF',
                        '#FF9F40',
                    ],
                },
            ],
        });
    }, []);

    const options: ChartOptions<'pie'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    color: '#ffffff', // Legend text color
                },
            },
        },
        animation: {
            animateScale: true,
            animateRotate: true,
        },
    };

    return (
        <div className={styles.container}>
            <h3>Cancellation Main Reassons</h3>
            <div className={styles.chart_container}>
                {chartData && <Pie data={chartData} options={options} />}
            </div>
        </div>
    );
};

export default ChartCancellationMainReasons;
