
import { ColorSelectorVersions } from "../ColorSelector/ColorSelectorComponentProps";
import { SizeSelectorVersions } from "../SizeSelector/SizeSelectorComponentProps";
import VariantSelectorV1 from "./VariantSelectorV1/VariantSelectorV1";

enum VariantSelectorVersions {
    V1 = "v1",
};

const VariantSelectorMapping:Record<VariantSelectorVersions, React.FC<any>> = {
    [VariantSelectorVersions.V1]: VariantSelectorV1,
};

interface Variant {
    name: string;
    options: string[];
}

interface VariantSelectorComponentProps {
    variants?: Variant[],
    onOptionSelect?: (variantName: string, selectedOption: string) => void;
    colorSelectorVersion?: ColorSelectorVersions;
    sizeSelectorVersion?: SizeSelectorVersions;
};

export {
    VariantSelectorVersions,
    VariantSelectorMapping
};

export type {
    VariantSelectorComponentProps
};