import React from 'react'
import styles from '../Component.module.scss';
import SizeSelectorV1 from '../../UIElements/SizeSelector/SizeSelectorV1/SizeSelectorV1';

const SizeSelectorComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <SizeSelectorV1/>
        </div>
    </div>
  )
}

export default SizeSelectorComponentPage