import React from 'react'
import styles from '../Component.module.scss';
import ProductReviewV1 from '../../UIElements/ProductReview/ProductReviewV1/ProductReviewV1';
import ProductReviewV2 from '../../UIElements/ProductReview/ProductReviewV2/ProductReviewV2';
import ProductReviewV3 from '../../UIElements/ProductReview/ProductReviewV3/ProductReviewV3';
import ProductReviewV5 from '../../UIElements/ProductReview/ProductReviewV5/ProductReviewV5';
import ProductReviewV4 from '../../UIElements/ProductReview/ProductReviewV4/ProductReviewV4';

const ProductReviewComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <ProductReviewV1/>
        </div>
        <div className={styles.element}>
            <ProductReviewV2/>
        </div>
        <div className={styles.element}>
            <ProductReviewV3/>
        </div>
        <div className={styles.element}>
            <ProductReviewV4/>
        </div>
        <div className={styles.element}>
            <ProductReviewV5/>
        </div>
    </div>
  )
}

export default ProductReviewComponentPage