
import { OrderItemType } from "../../types/Data/OrderType";
import CartItemListV1 from "./CartItemListV1/CartItemListV1";
import CartItemListV2 from "./CartItemListV2/CartItemListV2";
import CartItemListV3 from "./CartItemListV3/CartItemListV3";

enum CartItemListVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const CartItemListMapping:Record<CartItemListVersions, React.FC<any>> = {
    [CartItemListVersions.V1]: CartItemListV1,
    [CartItemListVersions.V2]: CartItemListV2,
    [CartItemListVersions.V3]: CartItemListV3,
};


interface CartItemListComponentProps {
    items?: OrderItemType[];
    onQuantityChange?: (id: string, quantity: number) => void;
    onRemove?: (id: string) => void;
}

export {
    CartItemListVersions,
    CartItemListMapping,

}

export type {
    CartItemListComponentProps
}
  