import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { CategoryDisplayComponentProps } from "../CategoryDisplayComponentProps";
import { CategoryType } from '../../../types/Data/CategoryType';
import styles from './CategoryDisplayV4.module.scss';

const CategoryDisplayV4: React.FC<CategoryDisplayComponentProps> = ({selectedCategoriesId, onClick=()=>null}) => {
    //var winScroll = element.scrollLeft || 0;
    //var width = element.scrollWidth - element.clientWidth;
    
    const allCategories = useSelector<RootState>(state => state.dashboard.categories.categories) as CategoryType[];

    const categories = selectedCategoriesId
        ? allCategories.filter((category) => selectedCategoriesId.includes(category._id!))
        : allCategories.slice(0, 10); 


    useEffect(() => {
        const handleScroll = () => {
            const scrollView = document.getElementsByClassName(styles.horizontal_scroll_view)[0] as HTMLElement;
            var winScroll:number = scrollView.scrollLeft || 0;
            var width:number = scrollView.scrollWidth - scrollView.clientWidth;
            var scrolled = (winScroll / width) * 100;

            let scrollBar = document.getElementById(styles.scroll_bar_category)
            if (scrollBar) {
                scrollBar.style.width = scrolled + '%'; 
            }
        };
            document.getElementsByClassName(styles.horizontal_scroll_view)[0].addEventListener('scroll', handleScroll);
            return () => {
                document.getElementsByClassName(styles.horizontal_scroll_view)[0]?.removeEventListener('scroll', handleScroll);
            }
    })
    
  
  
    return (
        <div className={styles.product_category}>
            <div className={styles.horizontal_scroll_view}>
            {categories.map((category, index) => {
                return(
                    <div className={styles.product_category_card} onClick={()=>{onClick && onClick(category.name)}}>
                        <img src={category.image?.url} alt={category.name} />
                        <div className={styles.overlay}></div>
                        <div className={styles.content}>
                            <h5>{category.name}</h5>
                            <p>{category.description}</p>
                        </div>
                    </div>
                )
            })}
            </div>
            <div id={styles.scroll_bar_category}/>
        </div>
  );
};

export default CategoryDisplayV4;







