import React from 'react'
import styles from '../Component.module.scss';
import ButtonGroupV1 from '../../components/ButtonGroup/ButtonGroupV1/ButtonGroupV1';
import ButtonGroupV2 from '../../components/ButtonGroup/ButtonGroupV2/ButtonGroupV2';

const ButtonGroupComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <ButtonGroupV1/>
        </div>
        <div className={styles.element}>
            <ButtonGroupV2/>
        </div>
    </div>
  )
}

export default ButtonGroupComponentPage