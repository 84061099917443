import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllProducts } from '../../../api/product/productApi';
import { AdminType } from '../../../types/Data/AdminType';
import exampleAdmin from './exampleAdmin';
import { getAllAdmins } from '../../../api/admin/adminAPi';

export const fetchAdmins = createAsyncThunk(
    'admins/fetchAdmins',
    async (params: { page: number; [key: string]: any }, { rejectWithValue }) => {
        try {
            const response = await getAllAdmins(params);
            return {
                admins: response.data.data.admins,
                total: response.data.data.total,
                pages: response.data.data.pages,
                page: 1,
            };
            
        } catch (err: any) {
            return rejectWithValue('Failed to load admins');
        }
    }
);

// Define the state interface
interface AdminState {
    admins: Partial<AdminType>[];
    loading: boolean;
    error: string | null | {};
    page: number;
    pages: number;
    total: number
}

// Set the initial state
const initialState: AdminState = {
    admins: [...exampleAdmin],
    loading: false,
    error: null,
    page: 1,
    pages: 1,
    total: 0,
};


// Create the products slice
const adminSlice = createSlice({
    name: 'admins',
    initialState,
    reducers: {
        addAdmin(state, action: PayloadAction<AdminType>) {
            state.admins.push(action.payload);
        },
        updateAdmin(state, action: PayloadAction<AdminType>) {
            const index = state.admins.findIndex(admin => admin._id! === action.payload._id);
            if (index !== -1) {
                state.admins[index] = action.payload;
            }
        },
        deleteAdmin(state, action: PayloadAction<string>) {
            state.admins = state.admins.filter(admin => admin._id! !== action.payload);
        },
        loadAdmins(state, action: PayloadAction<Partial<AdminType>[]>) {
            state.admins = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAdmins.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
           .addCase(fetchAdmins.fulfilled, (state, action: PayloadAction<{ admins: AdminType[]; total: number; page: number; pages: number }>) => {
                state.loading = false;
                state.admins = action.payload.admins;
                state.page = action.payload.page;
                state.pages = action.payload.pages;
                state.total = action.payload.total
            })
            .addCase(fetchAdmins.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || action.error.message || 'Unknown error';
            });
    },
});

export const { addAdmin, updateAdmin, deleteAdmin, loadAdmins } = adminSlice.actions;
export default adminSlice.reducer;
