import React, { useEffect, useState } from 'react';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';
import styles from './SocialMediaLinksPage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../Store/store';
import SocialMediaEdit from './SocialMediaEdit/SocialMediaEditPage';
import { SocialMediaLinkType } from '../../../types/Data/SocialMediaSettingsType';
import ButtonV5 from '../../../components/Button/ButtonV5/ButtonV5';
import SlideOverV3 from '../../../components/SlideOver/SlideOverV3/SliderOverV3';
import SocialMediaLinksTable from './SocialMediaList/SocialMediaLinkList';
import { dispatchLoadSocialMediaLinks, fetchSocialMediaLinks } from '../../DashboardState/socialMedia/socialMediaSlice';
import exampledataSocialMediaLinks from '../../DashboardState/socialMedia/exampleDataSocialMedia';
import { useNavigate } from 'react-router-dom';

const SocialMediaLinksPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const socialMediaSettings = useSelector<RootState>((state)=> state.dashboard?.socialMedia?.socialMediaLinks) as SocialMediaLinkType []

  /*
  useEffect(() => {
    dispatch(fetchSocialMediaLinks())
  }, [])
  */
 
  const handleAddSocialMedia = () => {
    navigate('./edit')
  };

  const handleEditSocialMedia = (plateform:string) => {
    navigate(`./edit/?socialMediaPlateform=${plateform}`)
  };

  return (
    <div className={styles.social_media_settings_page}>
      <DashboardHeader title="Social Media Settings" />
      <ButtonV5 
        style={{marginTop:10}}
        title='Add Social Media'
        onClick={handleAddSocialMedia}
        />
      
      <div className={styles.shippingMethod_wrapper}>
        <SocialMediaLinksTable links={ socialMediaSettings} onEdit={handleEditSocialMedia} onRemove={()=>null}/>
      </div>
    </div>
  );
};

export default SocialMediaLinksPage;
