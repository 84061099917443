import React from "react";
import styles from "./TestimonialV1.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/store";
import { TestimonialType } from "../../../types/Data/TestimonialType";


const TestimonialV1: React.FC = () => {
  const testimonials = useSelector<RootState>((state)=>state.dashboard.testimonials.testimonials) as TestimonialType[];

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Testimonials</h3>
      <div className={styles.testimonialV1}>
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className={styles.testimonialCard}>
            <div className={styles.testimonialContent}>
              <img src={testimonial.avatar} alt={testimonial.name} className={styles.avatar} />
              <div className={styles.name}>{testimonial.name}</div>
              <div className={styles.role}>{testimonial.role}</div>
              <div className={styles.text}>{testimonial.text}</div>
            </div>
          </div>
        ))}
      </div>
    </div>

  );
};

export default TestimonialV1;
