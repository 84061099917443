import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ProductType } from '../../../types/Data/ProductType';
import { getAllProducts } from '../../../api/product/productApi';
import exampleProducts from './dataProducts';

// Define the state interface
interface ProductsState {
    products: Partial<ProductType>[];
    loading: boolean;
    error: string | null | {};
    page: number;
    totalPages: number;
}

// Set the initial state
const initialState: ProductsState = {
    products: [...exampleProducts],
    loading: false,
    error: null,
    page: 1,
    totalPages: 1,
};

// Create the async thunk for fetching products
export const fetchProducts = createAsyncThunk(
    'products/fetchProducts',
    async (params: { page: number; [key: string]: any }, { rejectWithValue }) => {
        try {
            const response = await getAllProducts(params);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch products');
        }
    }
);

// Create the products slice
const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        dispatchAddProduct(state, action: PayloadAction<ProductType>) {
            state.products.push(action.payload);
        },
        dispatchUpdateProduct(state, action: PayloadAction<ProductType>) {
            const index = state.products.findIndex(product => product._id! === action.payload._id);
            if (index !== -1) {
                state.products[index] = action.payload;
            }
        },
        dispatchDeleteProduct(state, action: PayloadAction<string>) {
            state.products = state.products.filter(product => product._id! !== action.payload);
        },
        dispatchLoadProducts(state, action: PayloadAction<Partial<ProductType>[]>) {
            state.products = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProducts.fulfilled, (state, action: PayloadAction<{ products: Partial<ProductType>[]; total: number; page: number; pages: number }>) => {
                state.loading = false;
                state.products = action.payload.products;
                state.page = action.payload.page;
                state.totalPages = action.payload.pages;
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || action.error.message || 'Unknown error';
            });
    },
});

export const { dispatchAddProduct, dispatchUpdateProduct, dispatchDeleteProduct, dispatchLoadProducts } = productsSlice.actions;
export default productsSlice.reducer;
