import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './EmailCampaigns.module.scss';
import { AppDispatch, RootState } from '../../Store/store';
import { deleteCampaign, fetchEmailCampaings, loadCampaign, toggleCampaignStatus } from '../DashboardState/emailCampaings/emailCampaingsSlice'
import DashboardHeader from '../../components/Header/DashboardHeader/DashboardHeader';
import { useNavigate } from 'react-router-dom';
import ButtonV5 from '../../components/Button/ButtonV5/ButtonV5';
import EmailCampaignList from './EmailCampaignList/EmailCampaignList';
import { deleteEmailCampaign, sendEmailCampaign } from '../../api/emailCampaing/emailCampaingApi';
import { handleApiError } from '../../api/apiError';
import exampleCampaigns from '../DashboardState/emailCampaings/dataCampaigns';

const Campaigns: React.FC = () => {
  const naviagte = useNavigate();
  const {campaigns, loading, error} = useSelector((state: RootState) => state.dashboard.emailCampaigns);
  const dispatch = useDispatch<AppDispatch>();
  
  /*
  useEffect(()=>{
    dispatch(fetchEmailCampaings({}))
  });
  */

  const handleEditCampaign = (campaignId:string) => {
    naviagte(`./edit/?campaignId=${campaignId}`)
  };

  const handleVewDetail = (campaignId:string) => {
    naviagte(`./detail/?campaignId=${campaignId}`)
  };

  const handleCreateNewCampaign = () => {
    naviagte(`./edit`)
  };

  const handleDeleteCampaing = async(id: string) => {
    try {
      const response = await deleteEmailCampaign(id);
      if (response) {
        alert('Campaign deleted')
      } else {
        alert('An error occured while deleting the campaing')
      }
    } catch (error: any) {
      console.log(error);
      const handledError = handleApiError(error);
      alert(handledError.message)
    } 
  };

  const handleSend = async(id: string) => {
    try {
      const response = await sendEmailCampaign(id);
      if (response) {
        alert('Campaign send')
      } else {
        alert('An error occured while sending the campaing')
      }
    } catch (error: any) {
      console.log(error);
      const handledError = handleApiError(error);
      alert(handledError.message)
    } 
  };

  return (
    <div className={styles.email_campaigns_page}>
      <DashboardHeader title="Email Campaigns" />
      <div className={styles.tab}>
        <ButtonV5
          title='Add Campaign'
          onClick={() => handleCreateNewCampaign()}
          style={{marginBottom: 10}}
          />
          <EmailCampaignList
            campaigns={campaigns}
            onViewDetails={(id)=>handleVewDetail(id)}
            onEdit={(id)=>handleEditCampaign(id)}
            onSend={(id)=>handleSend(id)}
            onDelete={(id)=>handleDeleteCampaing(id)} 
          /> 
      </div>
    </div>
  );
};

export default Campaigns;
