import QuantitySelectorV1 from "./QuantitySelectorV1/QuantitySelectorV1";
import QuantitySelectorV2 from "./QuantitySelectorV2/QuantitySelectorV2";
import QuantitySelectorV3 from "./QuantitySelectorV3/QuantitySelectorV3";

enum QuantitySelectorVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const QuantitySelectorMapping:Record<QuantitySelectorVersions, React.FC<any>> = {
    [QuantitySelectorVersions.V1]: QuantitySelectorV1,
    [QuantitySelectorVersions.V2]: QuantitySelectorV2,
    [QuantitySelectorVersions.V3]: QuantitySelectorV3,
};

interface QuantitySelectorComponentProps {
    [key:string]: any
};

export {
    QuantitySelectorVersions,
    QuantitySelectorMapping
};

export type {
    QuantitySelectorComponentProps 
};