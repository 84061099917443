import React from 'react'
import styles from '../Component.module.scss';
import ProductVariantEditorV1 from '../../Dashboard/Products/ProductAdd/ProductVariantEditor/ProductVariantEditorV1/ProductVariantEditorV1';

const ProductVariantEditorComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <ProductVariantEditorV1/>
        </div>
    </div>
  )
}

export default ProductVariantEditorComponentPage