// src/components/SalesLineChart.tsx
import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartData, ChartOptions } from 'chart.js';
import styles from './ChartCancellationOverTime.module.scss';

const ChartCancellationOverTime: React.FC = () => {
  const data: ChartData<'line'> = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Cancellation Request',
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: '#4bc0c0',
        backgroundColor: '#4bc0c0',
        pointBorderColor: '#4bc0c0',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#4bc0c0',
        pointHoverBorderColor: '#4bc0c0',
        pointHoverBorderWidth: 2,
        pointRadius: 3,
        pointHitRadius: 10,
        tension: 0.4, // Smooth curves
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          color: '#ffffff', // Legend text color
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Months',
          color: '#ffffff',
        },
        ticks: {
          color: '#ffffff',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Cancellation requests',
          color: '#ffffff',
        },
        ticks: {
          color: '#ffffff',
        },
      },
    },
    elements: {
      line: {
        borderWidth: 3,
      },
      point: {
        radius: 5,
      },
    },
  };

  return (
    <div className={styles.container}>
      <h3>Cancellation Over Time</h3>
      <div className={styles.chart_container}>
        <Line data={data} options={options} />
      </div>
    </div>
    
  );
};

export default ChartCancellationOverTime;
