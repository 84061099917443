import React from 'react';
import styles from './SortV2.module.scss';
import { defaultSortOptions } from '../sortDefaultProps';
import { SortComponentProps } from '../SortComponentProps';


const SortV2: React.FC<SortComponentProps> = ({ options = defaultSortOptions.options, selectedOption = [], onOptionSelect = () => null }) => {
  return (
    <div className={styles.buttonGroupSortContainer}>
      <div className={styles.buttonGroup}>
        {options?.map((option, index) => (
          <button
            key={index}
            className={`${styles.button} ${selectedOption === option ? styles.active : ''}`}
            onClick={() => onOptionSelect(option)}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SortV2;
