import React from 'react'
import styles from '../Component.module.scss';
import ProductDescriptionV1 from '../../UIElements/ProductDescription/ProductDescriptionV1/ProductDescriptionV1';
import ProductDescriptionV2 from '../../UIElements/ProductDescription/ProductDescriptionV2/ProductDescriptionV2';
import ProductDescriptionV3 from '../../UIElements/ProductDescription/ProductDescriptionV3/ProductDescriptionV3';

const ProductDescriptionComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <ProductDescriptionV1/>
        </div>
        <div className={styles.element}>
            <ProductDescriptionV2/>
        </div>
        <div className={styles.element}>
            <ProductDescriptionV3/>
        </div>
    </div>
  )
}

export default ProductDescriptionComponentPage