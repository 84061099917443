import React, { useState } from 'react';
import styles from './ContactInfoV3.module.scss';
import { defaultContactInfoProps } from '../companyInfoDefaultProps';
import { ContactInfoComponentProps } from '../ContactInfoComponentProps';

const ContactInfoV3: React.FC<ContactInfoComponentProps> = ({ 
    address = defaultContactInfoProps.address, 
    phone = defaultContactInfoProps.phone, 
    email = defaultContactInfoProps.email, 
    workingHours  = defaultContactInfoProps.workingHours
}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className={`${styles.card} ${isFlipped ? styles.flipped : ''}`} onClick={handleFlip}>
      <div className={styles.front}>
        <h2>Contact Us</h2>
        <p>Tap to view details</p>
      </div>
      <div className={styles.back}>
        <div>   
            <h3>Address :</h3>
            <p>{address}</p>
        </div>
        <div>
            <h3>Phone :</h3>
            <p>{phone}</p>
        </div>
        <div>
            <h3>Email :</h3>
            <p>{email}</p>
        </div>
        {workingHours && (
          <div>
            <h3>Working Hours</h3>
            <p>{workingHours}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactInfoV3;
