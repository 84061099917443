import axios, { AxiosResponse } from 'axios';
import api from '../config';
import { EmailCampaignType } from '../../types/Data/EmailCampaignType';
import { ApiResponse } from '../apiType';

// Create a new email campaign
export const createEmailCampaign = async (campaignData: EmailCampaignType):Promise<AxiosResponse<ApiResponse<EmailCampaignType>>> => {
  try {
    const response = await api.post('/email-campaigns', campaignData);
    return response;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Error creating email campaign');
  }
};

// Get all email campaigns
export const getAllEmailCampaigns = async ():Promise<AxiosResponse<ApiResponse<{campaings: EmailCampaignType[]}>>> => {
  try {
    const response = await api.get('/email-campaigns');
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Error fetching email campaigns');
  }
};

// Get an email campaign by ID
export const getEmailCampaignById = async (id: string):Promise<AxiosResponse<ApiResponse<EmailCampaignType>>> => {
  try {
    const response = await axios.get(`/email-campaigns/${id}`);
    return response;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || `Error fetching email campaign with ID ${id}`);
  }
};

// Update an email campaign by ID
export const updateEmailCampaign = async (id: string, campaignData: any) => {
  try {
    const response = await axios.put(`/email-campaigns/${id}`, campaignData);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || `Error updating email campaign with ID ${id}`);
  }
};

// Delete an email campaign by ID
export const deleteEmailCampaign = async (id: string) => {
  try {
    const response = await axios.delete(`/email-campaigns/${id}`);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || `Error deleting email campaign with ID ${id}`);
  }
};

// Send an email campaign by ID
export const sendEmailCampaign = async (id: string) => {
  try {
    const response = await axios.post(`/email-campaigns/${id}/send`);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || `Error sending email campaign with ID ${id}`);
  }
};
