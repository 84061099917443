import { TrustSignalsComponentProps } from "./TrustSignalsComponentProps";

export const trustSignalsDefaultProps: TrustSignalsComponentProps = {
    securePaymentText: "Secure Payment",
    guaranteeText: "Money-Back Guarantee",
    satisfactionText: "100% Satisfaction",
    paymentMethods: ["Visa", "Mastercard", "PayPal"],
    secureCheckoutText: "100% Secure Checkout",
    qualityGuaranteeText: "Quality Guarantee",
    customerSupportText: "24/7 Customer Support"
}