import React from 'react';
import styles from './PaginationV1.module.scss';
import { PaginationComponentProps } from '../PaginationComponentProps';

const PaginationV1: React.FC<PaginationComponentProps> = ({ currentPage= 1, totalCount=50, pageSize=5, onPageChange = () => null }) => {
  
  const totalPages = Math.floor(totalCount / pageSize) + 1;

  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <div className={styles.pagination}>
      <button className={styles.button} onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>
        Previous
      </button>
      <span>Page {currentPage} of {totalPages}</span>
      <button className={styles.button} onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages}>
        Next
      </button>
    </div>
  );
};

export default PaginationV1;