import React from "react";
import styles from './StatCardV1.module.scss';
import { StatItem } from "../../StatsComponentProps";

const StatCardV1:React.FC<StatItem> = ({title, value, comparaison}) => {
    return (
        <div className={styles.metric}>
            <h2>{title}</h2>
            <p>{value}</p>
            {comparaison && <span className={styles.comparison}>{comparaison}</span>}
        </div>
    )
}

export default StatCardV1