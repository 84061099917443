import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import exampleAccounts from './exampleAccountData';
import { AccountType } from '../../../types/Data/AccountType';

interface AccountState {
    accounts: Partial<AccountType>[];
    loading: boolean;
    error: string | null;
    page: number;
    totalPages: number;
}

const initialState: AccountState = {
    accounts: exampleAccounts,
    loading: false,
    error: null,
    page: 1,
    totalPages: 1,
};

const accountSlice = createSlice({
    name: 'accounts',
    initialState,
    reducers: {
        dispatchAddAccount(state, action: PayloadAction<AccountType>) {
            state.accounts.push(action.payload);
        },
        dispatchUpdateAccount(state, action: PayloadAction<AccountType>) {
            const index = state.accounts.findIndex(account => account._id === action.payload._id);
            if (index !== -1) {
                state.accounts[index] = action.payload;
            }
        },
        dispatchDeleteAccount(state, action: PayloadAction<string>) {
            state.accounts = state.accounts.filter(account => account._id !== action.payload);
        },
        dispatchLoadAccount(state, action: PayloadAction<AccountType[]>) {
            state.accounts = action.payload;
        },
    },
});

export const { dispatchAddAccount, dispatchUpdateAccount, dispatchDeleteAccount, dispatchLoadAccount } = accountSlice.actions;
export default accountSlice.reducer;
