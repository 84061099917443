import React from 'react'
import styles from '../Component.module.scss';
import TabV1 from '../../components/Tab/TabV1/TabV1';
import TabV2 from '../../components/Tab/TabV2/TabV2';
import TabV3 from '../../components/Tab/TabV3/TabV3';
import { tabDefaultProps } from '../../components/Tab/tabDefaultProps';

const TabComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <TabV1/>
        </div>
        <div className={styles.element}>
            <TabV2/>
        </div>
        <div className={styles.element}>
            <TabV3 {...tabDefaultProps}/>
        </div>
    </div>
  )
}

export default TabComponentPage