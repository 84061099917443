import React from 'react'
import styles from '../Component.module.scss';
import SocialMediaLinksV1 from '../../UIElements/SocialMediaLinks/SocialMediaLinksV1/SocialMediaLinksV1';
import SocialMediaLinksV2 from '../../UIElements/SocialMediaLinks/SocialMediaLinksV2/SocialMediaLinksV2';
import SocialMediaLinksV3 from '../../UIElements/SocialMediaLinks/SocialMediaLinksV3/SocialMediaLinksV3';

const SocialMediaLinkComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <SocialMediaLinksV1/>
        </div>
        <div className={styles.element}>
            <SocialMediaLinksV2/>
        </div>
        <div className={styles.element}>
            <SocialMediaLinksV3/>
        </div>
    </div>
  )
}

export default SocialMediaLinkComponentPage