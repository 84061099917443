import PaginationV1 from "./PaginationV1/PaginationV1";
import PaginationV2 from "./PaginationV2/PaginationV2";
import PaginationV3 from "./PaginationV3/PaginationV3";

enum PaginationVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const PaginationMapping:Record<PaginationVersions, React.FC<any>> = {
    [PaginationVersions.V1]: PaginationV1,
    [PaginationVersions.V2]: PaginationV2,
    [PaginationVersions.V3]: PaginationV3,
};

interface PaginationComponentProps {
    currentPage?: number;
    totalCount?: number;
    pageSize?: number;
    siblingCount?:number;
    onPageChange?: (page: number | string) => void;
}

export {
    PaginationMapping,
    PaginationVersions
}


export type {
    PaginationComponentProps
}
