import { TestimonialType } from "../../../types/Data/TestimonialType";

const exampleDataTestimonial: TestimonialType[] = [
    {
      id: "1",
      name: "John Doe",
      role: "CEO of Company",
      text: "This is the best service I've ever used!",
      avatar: "https://via.placeholder.com/80",
    },
    {
      id: "2",
      name: "Jane Smith",
      role: "Marketing Manager",
      text: "Absolutely wonderful experience!",
      avatar: "https://via.placeholder.com/80",
    },
];

export default exampleDataTestimonial