import { CustomerFeedbackType } from "../../types/Data/feedback";
import api from "../config";

// Interface for the pagination and sorting parameters
interface QueryParams {
    page?: number;
    limit?: number;
    sortBy?: string;
    sortOrder?: 'asc' | 'desc';
    [key: string]: any;
}

// Get all customer feedbacks with optional filters, pagination, and sorting
export const getAllFeedbacks = async (params: QueryParams) => {
    const response = await api.get<{ feedbacks: CustomerFeedbackType[]; total: number; pages: number }>('/feedbacks', { params });
    return response.data;
}

// Get a single customer feedback by ID
export const getFeedbackById = async (feedbackId: string) => {
    const response = await api.get<CustomerFeedbackType>(`/feedbacks/${feedbackId}`);
    return response.data;
}

// Create a new customer feedback
export const createFeedback = async (feedbackData: Partial<CustomerFeedbackType>) => {
    const response = await api.post<CustomerFeedbackType>('/feedbacks', feedbackData);
    return response.data;
}

// Update a customer feedback by ID
export const updateFeedbackById = async (feedbackId: string, feedbackData: Partial<CustomerFeedbackType>) => {
    const response = await api.put<CustomerFeedbackType>(`/feedbacks/${feedbackId}`, feedbackData);
    return response.data;
}

// Delete a customer feedback by ID
export const deleteFeedbackById = async (feedbackId: string) => {
    const response = await api.delete<{ message: string }>(`/feedbacks/${feedbackId}`);
    return response.data;
}
