import React from 'react';
import styles from './EcommerceRegister.module.scss';
import FormRegistration from '../../components/Form/FormRegistration/FormRegistration';

const EcommerceRegister = () => {
  return (
        <div className={styles.registerPage}>
            <div className={styles.formContainer}>
                <FormRegistration />
            </div>
            <div className={styles.imageContainer}>
                <img 
                src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80" 
                alt="Register" 
                />
            </div>
    </div>
  );
};

export default EcommerceRegister;
