import React from 'react';
import styles from './TrustBadgeIconsV3.module.scss';
import { TrustBadgeIconsComponentProps } from '../TrustBadgeIconsComponentProps';
import { getIconComponent } from '../utils';
import { defaultTrustBadgeItems } from '../defaultTrustBadgeData';

const TrustBadgeIconsV3:React.FC<TrustBadgeIconsComponentProps> = ({items = defaultTrustBadgeItems.items }) => {
  return (
    <div className={styles.gridContainer}>
      {items?.map((item, index) => {
        return(
          <div className={styles.gridItem}>
            <div className={styles.icon}>{getIconComponent(item.icon!)}</div>
            <h4>{item?.title}</h4>
          <p>{item?.description}</p>
        </div>
        )
      })}
    </div>
  );
};

export default TrustBadgeIconsV3;
