import React from 'react';
import styles from './ProductReviewV2.module.scss';
import { defaultProductReviewsProps } from '../defaultProductReviewProps';
import { ProductReviewComponentProps } from '../ProductReviewComponentProps';

const ProductReviewV2:React.FC<ProductReviewComponentProps> = ({ reviews = defaultProductReviewsProps }) => {
  return (
    <div className={styles.gridContainer}>
      {reviews.map((review, index) => (
        <div key={index} className={styles.reviewCard}>
          <div className={styles.reviewHeader}>
            <h3>{review.userId}</h3>
            <p className={styles.rating}>Rating: {review.rating} / 5</p>
          </div>
          <p className={styles.reviewText}>{review.content}</p>
        </div>
      ))}
    </div>
  );
};

export default ProductReviewV2;
