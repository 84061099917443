import React, { useState } from 'react';
import styles from './ProductDescriptionV3.module.scss';
import { ProductDescriptionComponentProps } from '../ProducrDescriptionComponentProps';

const ProductDescriptionV3: React.FC<ProductDescriptionComponentProps> = ({
  product = {
    description: "This is the description of the component.",
    attributes:[],
  },
  activeColor = '#333',
  inactiveColor = '#777',
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    { label: 'Description', content: product.description },
    {
      label: 'Attributes',
      content: product?.attributes ? product?.attributes?.map((attr) => `${attr.name}: ${attr.value}`).join('\n') : '',
    },
  ];

  return (
    <div className={styles.tabbedContainer}>
      <div className={styles.tabHeader}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={styles.tab}
            style={{ color: activeTab === index ? activeColor : inactiveColor }}
            onClick={() => setActiveTab(index)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className={styles.tabContent}>
        <p>{tabs[activeTab].content}</p>
      </div>
    </div>
  );
};

export default ProductDescriptionV3;
