import React, { useState } from 'react';
import styles from './QuantitySelectorV3.module.scss';
import { QuantitySelectorComponentProps } from '../QuantitySelectorComponentProps';

const QuantitySelectorV3:React.FC<QuantitySelectorComponentProps> = () => {
  const [quantity, setQuantity] = useState(1);

  const increment = () => setQuantity(quantity + 1);
  const decrement = () => setQuantity(quantity > 1 ? quantity - 1 : 1);
  const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(Number(event.target.value));
  };

  return (
    <div className={styles.stepper_selector}>
      <button onClick={decrement} className={styles.stepper_button}>-</button>
      <input
        type="number"
        value={quantity}
        onChange={handleChange}
        className={styles.quantity_input}
        min="1"
      />
      <button onClick={increment} className={styles.stepper_button}>+</button>
    </div>
  );
};

export default QuantitySelectorV3;
