import BillingInformationFormV1 from "../../UIElements/BillingInformationComponentForm/BillingInformationFormV1/BillingInformationFormV1";
import BillingInformationFormV2 from "../../UIElements/BillingInformationComponentForm/BillingInformationFormV2/BillingInformationFormV2";
import BillingInformationFormV3 from "../../UIElements/BillingInformationComponentForm/BillingInformationFormV3/BillingInformationFormV3";

enum BillignInformationFormVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const BillingInformationMapping:Record<BillignInformationFormVersions, React.FC<any>> = {
    [BillignInformationFormVersions.V1]: BillingInformationFormV1,
    [BillignInformationFormVersions.V2]: BillingInformationFormV2,
    [BillignInformationFormVersions.V3]: BillingInformationFormV3,
};

interface BillingInformationFormComponentProps {
    fullName?: string;
    address?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    country?: string;
    onInputChange?: (field: string, value: string) => void;
    onFormSubmit?: () => void;
    sameAsShipping?: boolean;
    onSameAsShippingChange?: (value: boolean) => void;
};

export {
    BillignInformationFormVersions,
    BillingInformationMapping
};

export type {
    BillingInformationFormComponentProps
};