import axios, { AxiosResponse } from 'axios';
import { ApiResponse } from '../apiType';
import { SubscriberType } from '../../types/Data/SubscriberType';
import api from '../config';

const BASE_URL = '/api/subscribers'; // Assuming your API base URL is `/api`

// Create a new subscriber
export const createSubscriber = async (subscriberData: any) => {
  try {
    const response = await axios.post(`${BASE_URL}`, subscriberData);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Error creating subscriber');
  }
};

// Get all subscribers (Admin only)
export const getAllSubscribers = async ():Promise<AxiosResponse<ApiResponse<{subscribers:SubscriberType[]}>>> => {
  try {
    const response = await api.get(`${BASE_URL}`);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Error fetching subscribers');
  }
};

// Get a subscriber by email (Admin only)
export const getSubscriberByEmail = async (email: string, token: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/${email}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || `Error fetching subscriber with email: ${email}`);
  }
};

// Update a subscriber by email (Admin only)
export const updateSubscriberByEmail = async (email: string, subscriberData: any, token: string) => {
  try {
    const response = await axios.put(`${BASE_URL}/${email}`, subscriberData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || `Error updating subscriber with email: ${email}`);
  }
};

// Delete a subscriber by email
export const deleteSubscriberByEmail = async (email: string) => {
  try {
    const response = await axios.delete(`${BASE_URL}/${email}`);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || `Error deleting subscriber with email: ${email}`);
  }
};
