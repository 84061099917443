import React from 'react';
import styles from './FeaturedProductsV5.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { ProductType } from '../../../types/Data/ProductType';
import { FeaturedProductsComponentProps } from '../FeaturedProductsComponentProps';

const FeaturedProductsV5: React.FC<FeaturedProductsComponentProps> = ({ productsId,  onClick=()=>null }) => {
  const allProducts = useSelector<RootState>(state => state.dashboard.products.products) as ProductType[];

  const products = productsId
    ? allProducts.filter((product) => productsId.includes(product._id!))
    : allProducts.slice(0, 10);

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardsWrapper}>
        {products?.map((product) => (
          <div key={product._id!} className={styles.card} onClick={()=>onClick(product._id!)}>
            <img src={product?.images![0].url} alt={product.name} className={styles.productImage} />
            <div className={styles.productInfo}>
              <h3>{product.name}</h3>
              <p>{product.description}</p>
              <span className={styles.price}>{product.price}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedProductsV5;
