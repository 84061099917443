import React from 'react';
import styles from './SwitchV1.module.scss';
import { SwitchComponentProps } from '../SwitchComponentProps';

const SwitchV1: React.FC<SwitchComponentProps> = ({ isChecked = true, onToggle = () => null, wrapperStyle, label }) => {
  return (
    <div className={styles.row} style={{...wrapperStyle}}>
      <label className={styles.switch} >
        <input className={styles.input} type="checkbox" checked={isChecked} onChange={onToggle} />
        <span className={styles.slider}></span>
      </label>
      {label && <p>{label}</p>}
    </div>
  );
};

export default SwitchV1;
