import React, { useState } from 'react';
import styles from './ProductReviewV1.module.scss';
import { defaultProductReviewsProps } from '../defaultProductReviewProps';
import { ProductReviewComponentProps } from '../ProductReviewComponentProps';

const ProductReviewV1: React.FC<ProductReviewComponentProps> = ({ reviews = defaultProductReviewsProps }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextReview = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  };

  const prevReview = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
  };

  return (
    <div className={styles.carouselContainer}>
      <button className={styles.prevButton} onClick={prevReview}>❮</button>
      <div className={styles.carousel} style={{ '--current-index': currentIndex } as React.CSSProperties}>
        {reviews.map((review, index) => (
          <div
            key={index}
            className={`${styles.reviewCard} ${index === currentIndex ? styles.active : ''}`}
          >
            <div className={styles.reviewHeader}>
              <h3>{review.userId}</h3>
              <p className={styles.rating}>Rating: {review.rating} / 5</p>
            </div>
            <p className={styles.reviewText}>{review.content}</p>
          </div>
        ))}
      </div>
      <button className={styles.nextButton} onClick={nextReview}>❯</button>
    </div>
  );
};

export default ProductReviewV1;
