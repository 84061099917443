import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../Store/store";
import { BlogPostType } from "../../types/Data/BlogPostType";
import styles from "./BlogPage.module.scss";

const BlogPage: React.FC = () => {
  const { blogId } = useParams<{ blogId: string }>();

  const blog = useSelector((state: RootState) =>
    state.dashboard.blogPosts.blogPosts.find((el) => el._id === blogId)
  ) as BlogPostType;

  return (
    <div className={styles.blogPage}>
      <div className={styles.heroImage}>
        <img src={blog.imageUrl} alt={blog.title} />
      </div>
      <div className={styles.contentContainer}>
        <h1 className={styles.title}>{blog.title}</h1>
        <div
          className={styles.blogContent}
          dangerouslySetInnerHTML={{ __html: blog?.content as string }}
        />
      </div>
    </div>
  );
};

export default BlogPage;
