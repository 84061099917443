import { CustomerFeedbackType } from '../../../types/Data/feedback';

const exampleFeedbacks: CustomerFeedbackType[] = [
    {
        id: '1',
        customer_name: 'John Doe',
        email: 'john.doe@example.com',
        message: 'Great service!',
        date: '2023-06-01',
        rating: 5,
        status: 'published',
    },
    {
        id: '2',
        customer_name: 'Jane Smith',
        email: 'jane.smith@example.com',
        message: 'Very satisfied with the product.',
        date: '2023-06-02',
        rating: 4,
        status: 'published',
    },
    {
        id: '3',
        customer_name: 'Alice Johnson',
        email: 'alice.johnson@example.com',
        message: 'Could be better.',
        date: '2023-06-03',
        rating: 3,
        status: 'pending',
    },
];

export default exampleFeedbacks;
