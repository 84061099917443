// exampleRefunds.ts

import {
  RefundProcessType,
  RefundPaymentMethod,
  RefundStatus,
  RefundItemCondition,
} from '../../../types/Data/RefundProcessType';

const exampleRefunds: RefundProcessType[] = [
  {
    _id: 'RFND001',
    customer: 'CUST1001',
    customerName: 'John Doe',
    order: 'ORD5001',
    returnRequest: 'RET1001',
    returnedProducts: [
      {
        product: 'ITEM001',
        productName: 'Wireless Mouse',
        quantity: 1,
        condition: RefundItemCondition.New,
        price: 25.0,
        refundAmount: 25.0,
      },
      {
        product: 'ITEM002',
        productName: 'Keyboard Cover',
        quantity: 2,
        condition: RefundItemCondition.Used,
        price: 10.0,
        refundAmount: 15.0,
      },
    ],
    totalRefundAmount: 40.0,
    refundStatus: RefundStatus.Pending,
    paymentInfo: {
      paymentMethod: RefundPaymentMethod.CreditCard,
      paymentProvider: 'Visa',
      paymentStatus: 'Pending',
      paymentFees: 0,
      transactionId: '',
    },
    notes: 'Customer returned items due to wrong order.',
    createdAt: new Date('2024-08-15'),
    updatedAt: new Date('2024-08-15'),
  },
  {
    _id: 'RFND002',
    customer: 'CUST1002',
    customerName: 'Jane Smith',
    order: 'ORD5002',
    returnRequest: 'RET1002',
    returnedProducts: [
      {
        product: 'ITEM003',
        productName: 'Bluetooth Speaker',
        quantity: 1,
        condition: RefundItemCondition.Damaged,
        price: 50.0,
        refundAmount: 50.0,
      },
    ],
    totalRefundAmount: 50.0,
    refundStatus: RefundStatus.Approved,
    paymentInfo: {
      paymentMethod: RefundPaymentMethod.CreditCard,
      paymentProvider: 'Visa',
      paymentStatus: 'Completed',
      paymentFees: 0,
      transactionId: 'TRX1234',
    },
    refundDate: new Date('2024-08-20'),
    notes: 'Item was damaged upon arrival.',
    createdAt: new Date('2024-08-18'),
    updatedAt: new Date('2024-08-20'),
  },
  {
    _id: 'RFND003',
    customer: 'CUST1003',
    customerName: 'Robert Brown',
    order: 'ORD5003',
    returnRequest: 'RET1003',
    returnedProducts: [
      {
        product: 'ITEM004',
        productName: 'Smartphone Case',
        quantity: 3,
        condition: RefundItemCondition.New,
        price: 15.0,
        refundAmount: 45.0,
      },
    ],
    totalRefundAmount: 45.0,
    refundStatus: RefundStatus.Refunded,
    paymentInfo: {
      paymentMethod: RefundPaymentMethod.PayPal,
      paymentProvider: 'PayPal',
      paymentStatus: 'Completed',
      paymentFees: 0,
      transactionId: 'TRX7890',
    },
    refundDate: new Date('2024-08-22'),
    notes: 'Customer changed their mind and returned the items.',
    createdAt: new Date('2024-08-19'),
    updatedAt: new Date('2024-08-22'),
  },
  {
    _id: 'RFND004',
    customer: 'CUST1004',
    customerName: 'Alice Green',
    order: 'ORD5004',
    returnRequest: 'RET1004',
    returnedProducts: [
      {
        product: 'ITEM005',
        productName: 'Noise Cancelling Headphones',
        quantity: 1,
        condition: RefundItemCondition.Used,
        price: 150.0,
        refundAmount: 130.0,
      },
    ],
    totalRefundAmount: 130.0,
    refundStatus: RefundStatus.Rejected,
    paymentInfo: {
      paymentMethod: RefundPaymentMethod.CreditCard,
      paymentProvider: 'MasterCard',
      paymentStatus: 'Failed',
      paymentFees: 0,
      transactionId: '',
    },
    notes: 'Refund rejected due to signs of extensive use and damage.',
    createdAt: new Date('2024-08-20'),
    updatedAt: new Date('2024-08-21'),
  },
];

export { exampleRefunds };
