export default class HtmlSanitizer {
    private allowedTags: string[];
    private allowedAttributes: string[];
  
    constructor(allowedTags: string[] = ['b', 'i', 'em', 'strong', 'a'], allowedAttributes: string[] = ['href', 'title']) {
      this.allowedTags = allowedTags;
      this.allowedAttributes = allowedAttributes;
    }
  
    sanitize(html: string): string {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const body = doc.body || doc.createElement('body');
        this.cleanNode(body);
        return body.innerHTML;
      }
  
    private cleanNode(node: Node): void {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;
        if (!this.allowedTags.includes(element.tagName.toLowerCase())) {
          element.remove();
          return;
        }
  
        for (let i = element.attributes.length - 1; i >= 0; i--) {
          const attr = element.attributes[i];
          if (!this.allowedAttributes.includes(attr.name.toLowerCase())) {
            element.removeAttribute(attr.name);
          }
        }
      }
  
      Array.from(node.childNodes).forEach(child => this.cleanNode(child));
    }
  }
  