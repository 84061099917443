import React from 'react';
import styles from './EmailTemplatesList.module.scss';
import { EmailTemplateType, TemplateNames } from '../../../types/Data/EmailTemplateType';
import TableInteractive from '../../../components/Tableau/TableInteractive/TableInteractive';
import { useNavigate, useNavigation } from 'react-router-dom';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';

const EmailTemplateListPage: React.FC = () => {
   const navigate = useNavigate();
   
    const templatesModel = Object.values(TemplateNames);


    const handleEditNewTemplate = (templateName:string) => {
        navigate(`./edit/?templateName=${templateName}`)
    };

    const handleEdiExistingTemplate = (templateId:string) => {
        navigate(`./edit/?templateId=${templateId}`)
    };

    return (
        <div>
            <DashboardHeader title='Emails Templates'/>
            <div className={styles.content}>
            <div className={styles.email_template_list}>
                {templatesModel.map((template) => (
                    <div
                        key={template}
                        className={styles.template_item}
                        onClick={() => handleEditNewTemplate(template)}
                    >
                        <h3>{template}</h3>
                        <button>Select Template</button>
                    </div>
                ))}
            </div>
            <TableInteractive data={templatesModel} onItemClick={handleEdiExistingTemplate}/>
            </div>
        </div>
    );
};

export default EmailTemplateListPage;
