import React, { useEffect, useState } from 'react';
import styles from './OrderSearchBar.module.scss';
import InputV4 from '../../../../components/Input/InputText/InputV4/InputV4';
import { getAllOrders } from '../../../../api/order/orderApi';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Store/store';
import { OrderType } from '../../../../types/Data/OrderType';
import { handleApiError } from '../../../../api/apiError';
import LoadingIndicatorV1 from '../../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';


interface OrderSearchBarProps {
    onOrderSelection: (order:OrderType) => void;
}

const OrderSearchBar: React.FC<OrderSearchBarProps> = ({ onOrderSelection = () => null }) => {
    const defaultOrders = useSelector((state: RootState) => state.dashboard.orders.orders) as OrderType[];
    const [orders, setOrders] = useState<OrderType[]>(defaultOrders);
    const [filteredOrders, setFilteredOrders] = useState<OrderType[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<{ message: string, status?: number } | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);

    // Fetch all orders based on search term
    useEffect(() => {
        const fetchOrders = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await getAllOrders({ search: searchTerm });
                if (response.status === 200) {
                    setOrders(response.data.data.orders as OrderType[]);
                } else {
                    throw new Error('No orders found');
                }
            } catch (err: any) {
                const handledError = handleApiError(err);
                setError({ message: handledError.message, status: handledError?.status });
            } finally {
                setLoading(false);
            }
        };
        
        if (searchTerm) fetchOrders();
    }, [searchTerm]);

    // Update filtered orders based on search term
    useEffect(() => {
        const filtered = orders.filter(order => 
            order._id.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredOrders(filtered);
    }, [searchTerm, orders]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setShowSuggestions(true);
    };

    const handleSelectSuggestion = (suggestion: string) => {
        setSearchTerm(suggestion);
        setShowSuggestions(false);
    };

    return (
        <div className={styles.orderSearchBar}>
            <InputV4
                label="Order ID"
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Enter Order ID"
            />

            {loading && <LoadingIndicatorV1 />}
            {error && <ErrorBannerV1 message={error.message} />}

            {showSuggestions && filteredOrders.length > 0 && (
                <ul className={styles.suggestionsList}>
                    {filteredOrders.map((order) => (
                        <li
                            key={order._id}
                            className={styles.suggestionItem}
                            onClick={() => { onOrderSelection(order); setShowSuggestions(false) }}
                        >
                            {order._id}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default OrderSearchBar;
