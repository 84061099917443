import React, { useState } from "react";
import styles from "./ImageEditPage.module.scss";
import { ImageType } from "../../../types/Data/ImageType";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/store";
import InputV4 from "../../../components/Input/InputText/InputV4/InputV4";
import SaveButton from "../../../components/Button/SaveButton/SaveButton";
import Select4 from "../../../components/Select/Select4/Select4";
import DashboardHeader from "../../../components/Header/DashboardHeader/DashboardHeader";

const ImageEditPage: React.FC = ({}) => {
  
  const { imageId } = useParams<{ imageId: string }>();
  const image = useSelector((state: RootState)=>state.dashboard.images.images.find((el)=>el._id === imageId)) as ImageType;

  const [title, setTitle] = useState(image.title || "");
  const [alt, setAlt] = useState(image.alt || "");
  const [tags, setTags] = useState(image.tags || []);
  const [category, setCategory] = useState(image.category || "Asset");

  const categoryOptions = [
    { value: 'Products', label: 'Products' },
    { value: 'Assets', label: 'Assets' },
    { value: 'Blogs', label: 'Blogs' },
  ];
  
  const handleSave = () => {
    const updatedImage: ImageType = {
      ...image,
      title,
      alt,
      tags,
      category,
    };
  };

  const handleDelete = () => {
  };

  return (
    <div className={styles.imageEditPage}>
      <DashboardHeader title="Edit Image"/>
      <div className={styles.imagePreview}>
        <img className={styles.img} src={image.url} alt={alt} />
      </div>

      <div className={styles.content}>
        {/* Title */}
        <InputV4
         label="Title" 
          type="text" 
          value={title} 
          onChange={(e) => setTitle(e.target.value)} 
          placeholder="Image Title" 
        />

        {/* Alt Text */}
        <InputV4
          label="Alt Text" 
          type="text" 
          value={alt} 
          onChange={(e) => setAlt(e.target.value)} 
          placeholder="Alternative Text (for accessibility)" 
          wrapperStyle={{marginTop:10}}
        />

        {/* Tags */}
        <InputV4 
          label="Tags"
          type="text" 
          value={tags.join(", ")} 
          onChange={(e) => setTags(e.target.value.split(", ").map(tag => tag.trim()))} 
          placeholder="Tags (comma separated)" 
          wrapperStyle={{marginTop:10}}
        />

        {/* Category */}

        <Select4
            label="Category"
            options={categoryOptions}
            value={category}
            onChange={(value) => setCategory(value as any)}
            placeholder="Select category"
        />

        {/* Replace Image */}
        <div className={styles.inputFileContainer}>
            <label>Replace Image</label>
            <input type="file" />
        </div>
        
        <div className={styles.usageContainer}>
        {/* Linked Usage */}
        <h4>Linked Usage:</h4>
        {image.usage && image.usage.length > 0 ? (
          <ul>
            {image.usage.map((usageId) => (
              <li key={usageId}>Linked to {usageId}</li> // Replace with actual names
            ))}
          </ul>
        ) : (
          <p>No linked usage</p>
        )}
        </div>
      </div>

      <div className={styles.actions}>
        <SaveButton/>
        <button className={styles.deleteButton} onClick={handleDelete}>Delete Image</button>
      </div>
    </div>
  );
};

export default ImageEditPage;
