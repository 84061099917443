import { OrderType, OrderStatus, PaymentStatus } from "../../../types/Data/OrderType";

const dataOrders: OrderType[] = [
    {
        _id: '1',
        customer: {
            _id: '1', // Reference to customer
            name: 'John Doe',
            email: 'john.doe@example.com',
            phone: '123-456-7890'
        },
        shippingAddress: {
            street: '123 Main St',
            city: 'Anytown',
            state: 'CA',
            postalCode: '12345',
            country: 'USA'
        },
        billingAddress: {
            street: '123 Main St',
            city: 'Anytown',
            state: 'CA',
            postalCode: '12345',
            country: 'USA'
        },
        items: [
            {
                productId: 'A1',
                name: 'Widget 1',
                quantity: 2,
                unitPrice: 25.0,
                totalPrice: 50.0,
                imageUrl: 'https://via.placeholder.com/100',
                discount: 5.0 // Item-specific discount
            },
            {
                productId: 'B2',
                name: 'Gadget 2',
                quantity: 1,
                unitPrice: 100.0,
                totalPrice: 100.0,
                imageUrl: 'https://via.placeholder.com/100'
            }
        ],
        orderSummary: {
            subtotal: 150.0,
            shippingCost: 10.0,
            discounts: 5.0,
            tax: 13.5,
            totalAmount: 168.5
        },
        payment: {
            paymentMethod: 'Credit Card',
            paymentProvider: 'Stripe',
            paymentStatus: PaymentStatus.COMPLETED,
            transactionId: 'txn123',
            paymentFees: 2.0 // Added payment fees
        },
        shipping: {
            shippingMethod: 'Standard Shipping',
            trackingNumber: 'tracking123',
            shippingCarrier: 'UPS',
            estimatedDeliveryDate: new Date('2023-06-30'),
            shippingStatus: OrderStatus.SHIPPED,
            fulfilledBy: 'Warehouse 3' // Order fulfilled by a specific warehouse
        },
        status: OrderStatus.SHIPPED,
        timestamps: {
            orderDate: new Date('2023-06-20'),
            paymentDate: new Date('2023-06-20'),
            shippingDate: new Date('2023-06-21'),
            deliveryDate: null
        },
        statusHistory: [
            { status: OrderStatus.PENDING, changedAt: new Date('2023-06-20') },
            { status: OrderStatus.PAID, changedAt: new Date('2023-06-20') },
            { status: OrderStatus.SHIPPED, changedAt: new Date('2023-06-21') }
        ],
        notes: {
            customerNotes: 'Please leave the package at the front door.',
            internalNotes: 'Customer prefers weekend delivery.'
        },
        coupons: {
            couponCode: 'SUMMER21',
            discountAmount: 5.0
        },
        comments: 'Urgent order'
    },
    {
        _id: '2',
        customer: {
            _id: '124',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
            phone: '987-654-3210'
        },
        shippingAddress: {
            street: '456 Elm St',
            city: 'Othertown',
            state: 'NY',
            postalCode: '67890',
            country: 'USA'
        },
        billingAddress: {
            street: '456 Elm St',
            city: 'Othertown',
            state: 'NY',
            postalCode: '67890',
            country: 'USA'
        },
        items: [
            {
                productId: 'C3',
                name: 'Doodad 3',
                quantity: 3,
                unitPrice: 30.0,
                totalPrice: 90.0,
                imageUrl: 'https://via.placeholder.com/100',
                discount: 10.0
            },
            {
                productId: 'D4',
                name: 'Thingamajig 4',
                quantity: 1,
                unitPrice: 150.0,
                totalPrice: 150.0,
                imageUrl: 'https://via.placeholder.com/100'
            }
        ],
        orderSummary: {
            subtotal: 240.0,
            shippingCost: 15.0,
            discounts: 20.0,
            tax: 19.25,
            totalAmount: 254.25
        },
        payment: {
            paymentMethod: 'PayPal',
            paymentProvider: 'PayPal',
            paymentStatus: PaymentStatus.COMPLETED,
            transactionId: 'txn124'
        },
        shipping: {
            shippingMethod: 'Express Shipping',
            trackingNumber: 'tracking124',
            shippingCarrier: 'FedEx',
            estimatedDeliveryDate: new Date('2023-06-25'),
            shippingStatus: OrderStatus.DELIVERED,
            fulfilledBy: 'FedEx Facility'
        },
        status: OrderStatus.DELIVERED,
        timestamps: {
            orderDate: new Date('2023-06-19'),
            paymentDate: new Date('2023-06-19'),
            shippingDate: new Date('2023-06-20'),
            deliveryDate: new Date('2023-06-23')
        },
        statusHistory: [
            { status: OrderStatus.PENDING, changedAt: new Date('2023-06-19') },
            { status: OrderStatus.PAID, changedAt: new Date('2023-06-19') },
            { status: OrderStatus.SHIPPED, changedAt: new Date('2023-06-20') },
            { status: OrderStatus.DELIVERED, changedAt: new Date('2023-06-23') }
        ],
        notes: {
            customerNotes: 'Deliver to the side door if no one answers.',
            internalNotes: 'Expedited shipping requested.'
        },
        coupons: {
            couponCode: 'FREESHIP',
            discountAmount: 15.0
        },
        comments: 'Customer requested gift wrap'
    },
    {
        _id: '3',
        customer: {
            _id: '125',
            name: 'Alice Johnson',
            email: 'alice.johnson@example.com',
            phone: '555-123-4567'
        },
        shippingAddress: {
            street: '789 Maple Ave',
            city: 'Newcity',
            state: 'IL',
            postalCode: '10112',
            country: 'USA'
        },
        billingAddress: {
            street: '789 Maple Ave',
            city: 'Newcity',
            state: 'IL',
            postalCode: '10112',
            country: 'USA'
        },
        items: [
            {
                productId: 'E5',
                name: 'Whatchamacallit 5',
                quantity: 2,
                unitPrice: 75.0,
                totalPrice: 150.0,
                imageUrl: 'https://via.placeholder.com/100'
            },
            {
                productId: 'F6',
                name: 'Doohickey 6',
                quantity: 4,
                unitPrice: 20.0,
                totalPrice: 80.0,
                imageUrl: 'https://via.placeholder.com/100'
            }
        ],
        orderSummary: {
            subtotal: 230.0,
            shippingCost: 12.0,
            discounts: 10.0,
            tax: 18.5,
            totalAmount: 250.5
        },
        payment: {
            paymentMethod: 'Credit Card',
            paymentProvider: 'Stripe',
            paymentStatus: PaymentStatus.COMPLETED,
            transactionId: 'txn125'
        },
        shipping: {
            shippingMethod: 'Standard Shipping',
            trackingNumber: 'tracking125',
            shippingCarrier: 'USPS',
            estimatedDeliveryDate: new Date('2023-07-01'),
            shippingStatus: OrderStatus.PROCESSING,
            fulfilledBy: 'Warehouse 1'
        },
        status: OrderStatus.PROCESSING,
        timestamps: {
            orderDate: new Date('2023-06-22'),
            paymentDate: new Date('2023-06-22'),
            shippingDate: null,
            deliveryDate: null
        },
        statusHistory: [
            { status: OrderStatus.PENDING, changedAt: new Date('2023-06-22') },
            { status: OrderStatus.PAID, changedAt: new Date('2023-06-22') }
        ],
        notes: {
            customerNotes: 'Call me when the package arrives.',
            internalNotes: 'Awaiting stock for one item.'
        },
        coupons: null,
        comments: 'First-time customer'
    }
];

export default dataOrders;
