import React, { useEffect, useState } from 'react';
import styles from './CancellationsPage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Filters from './CancellationFilter/Filters';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../Store/store';
import DashboardHeader from '../../components/Header/DashboardHeader/DashboardHeader';
import TableInteractive from '../../components/Tableau/TableInteractive/TableInteractive';
import { CancellationType } from '../../types/Data/CancellationType';
import LoadingIndicatorV1 from '../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import { fetchAllCancellations, loadCancellation } from '../DashboardState/cancellations/cancellationsSlice';
import CancellationList from './Cancellationlist/CancellationList';
import exempleCancellations from '../DashboardState/cancellations/dataCancellations';

const CancellationsPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const [query, setQuery] = useState({
        search:"",
        status: "",
        startDate: "",
        endDate: ""
    });

    const [page, setPage] = useState(1);

    const cancellations = useSelector<RootState>((state) => state.dashboard.cancellations.cancellations) as CancellationType[];
    const loading = useSelector<RootState, boolean>((state) => state.dashboard.cancellations.loading);
    const error = useSelector<RootState, string | null>((state) => state.dashboard.cancellations.error);

    const handleFilterChange = (key: string, value: any) => {
        setQuery((prevQuery) => ({ ...prevQuery, [key]: value }));
        setPage(1); // Reset page to 1 when query changes
    };

    const handleViewCancellation = (cancellationId: string) => {
        navigate(`./${cancellationId}`)
    };

    /*
    useEffect(() => {
        dispatch(fetchAllCancellations({ ...query, page }));
    }, [query, dispatch]);
    */

    //dispatch(loadCancellation(exempleCancellations))

    return (
        <div className={styles.cancelledOrdersPage}>
            <DashboardHeader title='Cancellations'/>
            <Filters
                query={query}
                onQueryChange={handleFilterChange}
                
            />
            <div className={styles.orders_array}>


             {/* Handle loading state */}
             {loading && <LoadingIndicatorV1 />}
                
            {/* Handle error state */}
            {error && <ErrorBannerV1 message={typeof error === 'string' ? error : 'An error occurred'} />}
                
            <CancellationList/>
            {/* Display product list if not loading and no error */}
            </div>
        </div>
    );
};

export default CancellationsPage;
