import React from 'react';
import styles from './ProductCardListV6.module.scss';
import { ProductCardListComponentProps } from '../ProductCardListComponentProps';

const ProductCardListV6: React.FC<ProductCardListComponentProps> = ({ product, onProductClick = () => null  }) => {
  return (
    <div className={styles.card}  onClick={()=>onProductClick(product?._id!)}>
      <div className={styles.imageContainer}>
        <img src={product?.thumbnail || product?.images![0].url} alt={product?.name} className={styles.image} />
      </div>
      <div className={styles.info}>
        <h3 className={styles.name}>{product?.name}</h3>
        <p className={styles.description}>{product?.description}</p>
        <div className={styles.priceContainer}>
          <span className={styles.price}>{product?.price} {product?.currency}</span>
          {product?.discountPrice && <span className={styles.discountPrice}>{product?.discountPrice} {product?.currency}</span>}
        </div>
      </div>
    </div>
  );
};

export default ProductCardListV6;
