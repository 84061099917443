import React, { useState, ChangeEvent, FormEvent } from 'react';
import styles from './CustomerEditProfilFormV1.module.scss';
import { CustomerType } from '../../../types/Data/CustomerType';
import { AddressType } from '../../../types/Data/AdressType';
import { CustomerEditProfilComponentProps } from '../CustomerEditProfilComponentProps';
import CustomerAddressForm from '../../CustomerAddressForm/CustomerAddressForm/CustomerAddressForm';

const CustomerEditProfilFormV1: React.FC<CustomerEditProfilComponentProps> = ({customer, onSave=()=>null, onCancel=()=>null}) => {
  // Assuming customer is fetched asynchronously, initially it might be undefined

  const [formData, setFormData] = useState<Partial<CustomerType> | undefined>(customer || undefined);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => (prev ? { ...prev, [name]: value } : { [name]: value }) as CustomerType);
  };

  const handleAddressChange = (addressType: 'shippingAddress' | 'billingAddress', updatedAddress: AddressType) => {
    setFormData((prev) => (prev ? { ...prev, [addressType]: updatedAddress } : { [addressType]: updatedAddress }) as CustomerType);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (formData) {
      onSave && onSave(formData);
    }
  };

  if (!formData) {
    return <div>Loading customer data...</div>;
  }

  return (
    <form className={styles.customerForm} onSubmit={handleSubmit}>
      <h2>Edit Profile Information</h2>

      {/* Name */}
      <div className={styles.field}>
        <label>Name</label>
        <input 
          type="text" 
          name="name" 
          value={formData.name || ''}  // Handle case where formData is initially empty
          onChange={handleChange} 
          required 
        />
      </div>

      {/* Email */}
      <div className={styles.field}>
        <label>Email</label>
        <input 
          type="email" 
          name="email" 
          value={formData.email || ''}  // Handle case where formData is initially empty
          onChange={handleChange} 
          required 
        />
      </div>

      {/* Phone */}
      <div className={styles.field}>
        <label>Phone</label>
        <input 
          type="tel" 
          name="phone" 
          value={formData.phone || ''}  // Handle case where phone is undefined
          onChange={handleChange} 
        />
      </div>

      {/* Preferred Language */}
      <div className={styles.field}>
        <label>Preferred Language</label>
        <input 
          type="text" 
          name="preferredLanguage" 
          value={formData.preferredLanguage || ''}  // Handle case where preferredLanguage is undefined
          onChange={handleChange} 
        />
      </div>

      {/* Communication Preference */}
      <div className={styles.field}>
        <label>Communication Preference</label>
        <input 
          type="text" 
          name="communicationPreference" 
          value={formData.communicationPreference || ''}  // Handle case where communicationPreference is undefined
          onChange={handleChange} 
        />
      </div>

      {/* Shipping Address */}
      <div className={styles.section}>
        <h3>Shipping Address</h3>
        <CustomerAddressForm
          address={formData.shippingAddress || {}}  // Provide a fallback empty object for address
          onChange={(updatedAddress) => handleAddressChange('shippingAddress', updatedAddress)}
        />
      </div>

      {/* Billing Address */}
      <div className={styles.section}>
        <h3>Billing Address</h3>
        <CustomerAddressForm
          address={formData.billingAddress || {}}  // Provide a fallback empty object for address
          onChange={(updatedAddress) => handleAddressChange('billingAddress', updatedAddress)}
        />
      </div>

      {/* Submit and Cancel buttons */}
      <div className={styles.actions}>
        <button type="submit" className={styles.saveButton}>Save Changes</button>
        <button type="button" onClick={onCancel} className={styles.cancelButton}>Cancel</button>
      </div>
    </form>
  );
};

export default CustomerEditProfilFormV1;
