import React, { useState } from 'react';
import { IoStarOutline, IoStar } from 'react-icons/io5';
import styles from './ReviewSubmissionV1.module.scss';
import { ReviewSubmissionComponentProps } from '../ReviewSubmissionComponentProps';

const ReviewSubmissionV1:React.FC<ReviewSubmissionComponentProps> = ({ onSubmitReview }) => {
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState('');

  const handleStarClick = (value: number) => {
    setRating(value);
  };

  const handleSubmit = () => {
    if (onSubmitReview) {
        onSubmitReview({ rating, reviewText });
    }
    setRating(0);
    setReviewText('');
  };

  return (
    <div className={styles.reviewContainer}>
      <div className={styles.stars}>
        {[1, 2, 3, 4, 5].map((star) => (
          <span key={star} onClick={() => handleStarClick(star)}>
            {star <= rating ? <IoStar className={styles.star} /> : <IoStarOutline className={styles.star} />}
          </span>
        ))}
      </div>
      <textarea
        className={styles.textArea}
        value={reviewText}
        onChange={(e) => setReviewText(e.target.value)}
        placeholder="Write your review here..."
      />
      <button className={styles.submitButton} onClick={handleSubmit}>
        Submit Review
      </button>
    </div>
  );
};

export default ReviewSubmissionV1;
