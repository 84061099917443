import React, { ButtonHTMLAttributes, ReactElement } from 'react'
import styles from './ButtonDelete.module.scss';

interface buttonProps extends ButtonHTMLAttributes <HTMLButtonElement> {
    title?:string;
    style?:object;
    icon?: ReactElement;
}

const ButtonDelete:React.FC<buttonProps> = ({title = "Delete", style={}, icon, ...props}) => {
  return (
    <button style={{...style}} className={styles.button} {...props}>
        <div className={styles.content}>
          {icon && <div className={styles.icon}>{icon}</div>}
          {title}
        </div>
    </button>
  )
}

export default ButtonDelete