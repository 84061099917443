import React, { useState, useEffect } from 'react';
import styles from './HeroBannerV3.module.scss';
import defaultHeroBanner from '../defaultHeroBanner';
import { HeroBannerComponentProps } from '../HeroBannerComponentProps';
import { BannerSlideType } from '../../../types/Data/BannerSlideType';

const HeroBannerV3: React.FC<HeroBannerComponentProps> = ({ slides = defaultHeroBanner.slides, interval = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides!.length);
    }, interval);

    return () => clearInterval(slideInterval);
  }, [slides, interval]);

  return (
    <div className={styles.kenBurnsBanner}>
      {slides!.map((slide: BannerSlideType, index: number) => (
        <div key={index} className={`${styles.slide} ${index === currentIndex ? styles.active : ''}`}>
          {slide.imageUrl && <img src={slide.imageUrl} alt={slide.title} className={styles.image} />}
          {slide.videoUrl && (
            <video className={styles.video} autoPlay loop muted>
              <source src={slide.videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
          <div className={styles.overlay}>
            <div className={styles.content}>
              <h2>{slide.title}</h2>
              <p>{slide.text}</p>
              {slide.buttonText && slide.buttonUrl && (
                <a href={slide.buttonUrl} className={styles.button}>
                  {slide.buttonText}
                </a>
              )}
            </div>
          </div>
        </div>
      ))}
      <div className={styles.controls}>
        {slides!.map((_, index) => (
          <div
            key={index}
            className={`${styles.dot} ${currentIndex === index ? styles.active : ''}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default HeroBannerV3;
