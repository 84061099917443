import api from '../config';
import { AxiosResponse } from "axios";
import { ApiResponse } from "../apiType";
import { ImageType } from '../../types/Data/ImageType';


export const uploadImage = async (formData: FormData) => {
    try {
      const response = await api.post('/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };
  
  // Upload multiple images
  export const uploadMultipleImages = async (formData: FormData):Promise<AxiosResponse<ApiResponse<ImageType[]>>> => {
    try {
      const response = await api.post('/upload-multiple', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data
    } catch (error) {
      console.error('Error uploading multiple images:', error);
      throw error;
    }
  };
  
  // Update image details
  export const updateImage = async (imageId: string, updateData: any) => {
    try {
      const response = await api.put(`/${imageId}`, updateData);
      return response.data;
    } catch (error) {
      console.error('Error updating image:', error);
      throw error;
    }
  };
  
  // Delete an image
  export const deleteImage = async (imageId: string) => {
    try {
      const response = await api.delete(`/${imageId}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting image:', error);
      throw error;
    }
  };
  
  // Fetch an image by ID
  export const getImageById = async (imageId: string) => {
    try {
      const response = await api.get(`/${imageId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching image by ID:', error);
      throw error;
    }
  };
  
  // List images with optional filters
  export const listImages = async (filters: any = {}) => {
    try {
      const response = await api.get('/', {
        params: filters, // Pass optional filters
      });
      return response.data;
    } catch (error) {
      console.error('Error listing images:', error);
      throw error;
    }
  };
