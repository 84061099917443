import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartData, ChartOptions } from 'chart.js';
import styles from './ChartCustomerLoyalty.module.scss';
import { CustomerType } from '../../../../../types/Data/CustomerType';

interface CustomerLoyaltyChartProps {
    customers: CustomerType[];
}

const ChartCustomerLoyalty: React.FC<CustomerLoyaltyChartProps> = ({ customers }) => {
    const getLoyaltyLevelsDistribution = () => {
        const levels: { [key: string]: number } = {};
        customers.forEach(customer => {
            const level = customer.loyaltyLevel || 'Unknown';
            levels[level] = (levels[level] || 0) + 1;
        });
        return levels;
    };

    const loyaltyLevels = getLoyaltyLevelsDistribution();
    const levelLabels = Object.keys(loyaltyLevels);
    const levelData = Object.values(loyaltyLevels);

    const data: ChartData<'doughnut'> = {
        labels: levelLabels,
        datasets: [
            {
                data: levelData,
                backgroundColor: [
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(153, 102, 255, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options: ChartOptions<'doughnut'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    color: 'white',
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.label}: ${context.raw}`;
                    },
                },
            },
        },
    };

    return (
        <div className={styles.container}>
            <h3>Customer Lpoyalty Beakdown</h3>
            <div className={styles.chart_container}>
                <Doughnut data={data} options={options} />
            </div>
        </div>
        
    );
};

export default ChartCustomerLoyalty;
