import { AxiosResponse } from 'axios';
import api from '../config'; // Adjust the path as necessary to your API config
import { PrivatePolicyType } from '../../types/Data/PrivatePolicyType';
import { ApiResponse } from '../apiType';
import { TermOfServiceType } from '../../types/Data/TermOfServiceType';

// Get Private Policy
export const getTermOfService = async (): Promise<AxiosResponse<ApiResponse<TermOfServiceType>>> => {
    try {
        const response: AxiosResponse<ApiResponse<TermOfServiceType>> = await api.get('/term-of-service');
        return response;
    } catch (error) {
        console.error('Error fetching term of service:', error);
        throw new Error('Failed to fetch term of service');
    }
};

// Update Private Policy
export const saveTermOfService = async ( data: Partial<PrivatePolicyType>): Promise<AxiosResponse<ApiResponse<TermOfServiceType>>> => {
    try {
        const response: AxiosResponse<ApiResponse<TermOfServiceType>> = await api.post(`/term-of-service`, data);
        return response;
    } catch (error) {
        console.error(`Error saving term of service:`, error);
        throw new Error('Failed to save term of service');
    }
};

// Delete Private Policy
export const deleteTermOfService = async (id: string): Promise<AxiosResponse<ApiResponse<null>>> => {
    try {
        const response: AxiosResponse<ApiResponse<null>> = await api.delete(`/term-of-service/${id}`);
        return response;
    } catch (error) {
        console.error(`Error deleting term of service:`, error);
        throw new Error('Failed to delete term of service');
    }
};
