import React, { useState } from 'react';
import styles from './QuantitySelectorV1.module.scss';
import { QuantitySelectorComponentProps } from '../QuantitySelectorComponentProps';

const QuantitySelectorV1:React.FC<QuantitySelectorComponentProps> = () => {
  const [quantity, setQuantity] = useState(1);

  const increment = () => setQuantity(quantity + 1);
  const decrement = () => setQuantity(quantity > 1 ? quantity - 1 : 1);

  return (
    <div className={styles.circular_selector}>
      <button onClick={decrement} className={styles.circular_button}>-</button>
      <span className={styles.quantity}>{quantity}</span>
      <button onClick={increment} className={styles.circular_button}>+</button>
    </div>
  );
};

export default QuantitySelectorV1;
