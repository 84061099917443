import LogoCloudV1 from "./LogoCloudsV1/LogoCloudV1";

enum LogoCloudVersions {
    V1 = "v1",
};

const LogoCloudMapping:Record<LogoCloudVersions, React.FC<any>> = {
    [LogoCloudVersions.V1]: LogoCloudV1,
};

interface LogoCloudComponentProps {
    title?: string,
    logos?: string[]
}

export {
    LogoCloudMapping,
    LogoCloudVersions
}

export type {
    LogoCloudComponentProps
}