import FooterV1 from "./FooterV1/FooterV1";

interface FooterLink {
    title: string;
    url: string;
};

interface FooterItem {
    type: string;
    content: any;
};

enum FooterVersions {
    V1 = "v1",
};

const FooterMapping:Record<FooterVersions, React.FC<any>> = {
    [FooterVersions.V1]: FooterV1,
};

interface FooterComponentProps {
    showCompanyInfo?: boolean;
    showSocialMedia?: boolean,
    showPaymentMethods?: boolean,
    showDeliveryMethods?: boolean,
    showNewsletterSubscription?: boolean,
    backgroundColor?: string;
    textColor?: string;
    items?: FooterItem[];
};

export {
    FooterMapping,
    FooterVersions
};

  
export type {
    FooterLink,
    FooterItem,
    FooterComponentProps,
};
  