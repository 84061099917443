import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FaqType } from '../../../types/Data/FaqType';
import { faqDataExample } from './exampleDataFaq';

interface FaqState {
    faq: FaqType[];
}

const initialState: FaqState = {
    faq: faqDataExample,
};

const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {
        addFaq(state, action: PayloadAction<FaqType>) {
            state.faq.push(action.payload);
        },
        updateFaq(state, action: PayloadAction<FaqType>) {
            const index = state.faq.findIndex(feedback => feedback.id === action.payload.id);
            if (index !== -1) {
                state.faq[index] = action.payload;
            }
        },
        deleteFaq(state, action: PayloadAction<string>) {
            state.faq = state.faq.filter(faq => faq.id !== action.payload);
        },
        loadFaq(state, action: PayloadAction<FaqType[]>) {
            state.faq = action.payload;
        }
    },
});

export const { addFaq, updateFaq, deleteFaq, loadFaq } = faqSlice.actions;
export default faqSlice.reducer;
