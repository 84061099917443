// defaultProps.ts

import { ProductType, StockStatus } from "../../types/Data/ProductType";


export const defaultProductInfoCardProps: Partial<ProductType> = {
  _id: 'default-id',
  name: 'Default Product Name',
  description: 'This is a default description for the product. It provides basic details and information.',
  categories: ['Default Category'],
  tags: ['default', 'tag'],
  price: 99.99,
  discountPrice: 79.99,
  currency: 'USD',
  thumbnail: 'https://via.placeholder.com/150?text=Thumbnail',
  stockStatus: StockStatus.IN_STOCK,
  sku: 'DEFAULT-SKU',
  rating: 4.5,
  attributes: [
    { name: 'Color', value: 'Default Color' },
    { name: 'Size', value: 'Default Size' }
  ],
  createdAt: new Date(),
  updatedAt: new Date(),
  variants: [
    { name: 'Default Variant', options: ['Option 1', 'Option 2'] }
  ],
  seoTitle: 'Default SEO Title',
  seoDescription: 'Default SEO description for better search engine visibility.',
  specs: { weight: '1kg', dimensions: '10x10x10 cm' },
  weight: '1kg',
  dimensions: '10x10x10 cm',
  shippingCost: 10.0,
  isActive: true,
  purchasePrice: 50.0,
  supplierLink: 'https://example.com/supplier',
  link: 'https://example.com/product'
};
