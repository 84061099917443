import { ProductType, StockStatus } from "../../types/Data/ProductType";
import { ReviewType } from "../../types/Data/ReviewType";



const productCardListDefaultProps: ProductType = {
    _id: 'prod-001',
    name: 'Example Product',
    description: 'This is an example product description. It highlights the key features and benefits of the product.',
    categories: ['Electronics', 'Gadgets'],
    tags: ['new', 'tech', 'gadget'],
    price: 299.99,
    discountPrice: 249.99,
    currency: 'USD',
    images: [
        { url: 'https://via.placeholder.com/500', alt: 'Product Image 1' },
        { url: 'https://via.placeholder.com/500', alt: 'Product Image 2' },
    ],
    thumbnail: 'https://via.placeholder.com/150',
    stockStatus: StockStatus.IN_STOCK,
    sku: 'EX-001',
    rating: 4.5,
    
    attributes: [
        { name: 'Color', value: 'Black' },
        { name: 'Size', value: 'Medium' },
    ],
    createdAt: new Date('2023-01-01'),
    updatedAt: new Date('2023-02-01'),
    variants: [
        {
            name: 'Color',
            options: ['Black', 'White', 'Blue'],
        },
        {
            name: 'Size',
            options: ['Small', 'Medium', 'Large'],
        },
    ],
    seoTitle: 'Buy Example Product - Best Deals Online',
    seoDescription: 'Purchase Example Product at the best price with top-notch features and specifications.',
    specs: {
        'Battery Life': '10 hours',
        'Material': 'Aluminum',
        'Warranty': '2 years',
    },
    weight: '1.2 kg',
    dimensions: '10 x 5 x 3 inches',
    shippingCost: 9.99,
    isActive: true,
    purchasePrice: 200.00,
    supplierLink: 'https://supplier.example.com',
    link: 'https://example.com/product/prod-00'
}

export {
    productCardListDefaultProps
}
