// src/components/Filters.tsx
import React, { useState } from 'react';
import styles from './Filters.module.scss';
import SearchBarV4 from '../../../../components/SearchBar/SearchBarV4/SerachBarV4';
import Select4 from '../../../../components/Select/Select4/Select4';
import { SupportTicketCategory, SupportTicketStatus } from '../../../../types/Data/SupportTicketType';

interface FiltersProps {
    searchTerm: string,
    category: SupportTicketCategory | undefined,
    status: SupportTicketStatus | undefined,
    onSearch?: (searchTerm: string) => void;
    onCategoryChange?: (category: SupportTicketCategory) => void;
    onStatusChange?: (status: SupportTicketStatus) => void;
}

const Filters: React.FC<FiltersProps> = ({
    searchTerm = '',
    category = '',
    status = '',
    onSearch,
    onCategoryChange,
    onStatusChange,
}) => {

    const categoriesOption =Object.values(SupportTicketCategory).map((value)=>({label: value, value}))
    const statusOptions = Object.values(SupportTicketStatus).map((value)=>({label: value, value}))

    return (
        <div className={styles.filters} >
             <SearchBarV4
                placeholder="Search a ticket..."
                value={searchTerm}
                onSearch={(value)=> onSearch && onSearch(value)}
            />
            <div className={styles.selectRow}>
                <Select4
                    options={categoriesOption}
                    placeholder='Categories'
                    onChange={(value)=> onCategoryChange && onCategoryChange(value)}
                    value={category}
                />
                <Select4
                    options={statusOptions}
                    placeholder='Sort By'
                    onChange={(value) => onStatusChange && onStatusChange(value)}
                    value={status}
                />
            </div>
        </div>
    );
};

export default Filters;
