import React, { useEffect, useState } from "react";
import styles from "./CommentBox.module.scss";
import { IoSend } from "react-icons/io5";

interface CommentBoxProps {
    comment?: string;
    onChange?: (comment: string) => void;
    onSubmit?: () => void;
}

const CommentBox: React.FC<CommentBoxProps> = ({ comment = "", onChange, onSubmit }) => {
    return (
        <div className={styles.commentBox}>
            <h2>Leave a Comment</h2>
            <div>
                <div className={styles.formGroup}>
                    <textarea
                        id="comment"
                        name="comment"
                        value={comment}
                        onChange={(e)=> onChange && onChange(e.target.value)}
                        required
                    ></textarea>
                </div>
                <button type="submit" className={styles.submitButton} onClick={onSubmit}>
                    <IoSend/>
                </button>
            </div>
        </div>
    );
};

export default CommentBox;
