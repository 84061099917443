import React, { useState } from 'react';
import styles from './ProductImageGalleryV1.module.scss';
import { defaultProductImageGalleryProps } from '../defaultProductImageGalleryProps';
import { ProductImageGalleryComponentProps } from '../ProductImageGalleryComponentProps';

const ProductImageGalleryV1:React.FC<ProductImageGalleryComponentProps> = ({ images = defaultProductImageGalleryProps.images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div className={styles.galleryContainer}>
      <div className={styles.carousel}>
        <img
          src={images[currentImageIndex].url}
          alt={images[currentImageIndex].alt}
          className={styles.image}
        />
      </div>
      <button className={styles.prevButton} onClick={handlePrev}>❮</button>
      <button className={styles.nextButton} onClick={handleNext}>❯</button>
    </div>
  );
};

export default ProductImageGalleryV1;
