import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../Store/store';
import { EmailCampaignStatus, EmailCampaignType } from '../../../types/Data/EmailCampaignType';
import { TemplateNames, EmailTemplateType } from '../../../types/Data/EmailTemplateType';
import Tab2 from '../../../components/Tab/TabV2/TabV2';
import styles from './EmailCampaignsEditor.module.scss';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';
import EmailEditor from '../../../components/EmailEditor/EmailEditorV1/EmailEditorV1';
import { addCampaign, updateCampaign } from '../../DashboardState/emailCampaings/emailCampaingsSlice';
import Button1 from '../../../components/Button/ButtonV1/ButtonV1';
import ButtonCancel from '../../../components/Button/ButtonCancel/ButtonCancel';
import TemplatePreview from '../../MarketingEmails/TemplatePreview/TemplatePreview';
import EmailTemplateEditor from './TemplateEditor/EmailTemplateEditor';
import CampaignInfoForm from './CampaignInfoForm/CampaignInfoForm';
import { createEmailCampaign, getEmailCampaignById, updateEmailCampaign } from '../../../api/emailCampaing/emailCampaingApi';
import { handleApiError } from '../../../api/apiError';

const EmailCampaignsEditor: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  
  const campaignId = new URLSearchParams(location.search).get('campaignId');
  
  const campaignToEdit = useSelector((state:RootState) => state.dashboard.emailCampaigns.campaigns.find((el)=>el._id === campaignId)) as EmailCampaignType;

  const defaultCampaign: EmailCampaignType = useMemo(() => ({
    name: '',
    subject: '',
    scheduledDate: '',
    status: EmailCampaignStatus.Draft,
    template: undefined,
    htmlContent: '',
  }), []);

  const defaultTemplate: EmailTemplateType = {
    template: TemplateNames.Welcome,
    placeholders: [],
    data: {}
  };

  const [originalCampaign, setOriginalCampaign] = useState<EmailCampaignType>(campaignToEdit || defaultCampaign);
  const [currentCampaign, setCurrentCampaign] = useState<EmailCampaignType>(campaignToEdit || defaultCampaign);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError]=useState<{message:string, status:number}>();

  const [isSaving, setIsSaving] = useState<boolean>(false);


  const [activeTab, setActiveTab] = useState<string>('Templates Email');

  const getCampaing = async () => {
    setIsLoading(true);
    try {
      const response = await getEmailCampaignById(campaignId!);
      if (response && response.status === 200) {
        setOriginalCampaign(response.data.data);
        setCurrentCampaign(response.data.data);
      } else {
        alert('Error occured')
      }
 
    } catch(error: any) {
      console.log(error);
      const handledError = handleApiError(error);
      setError({message:handledError.message, status: handledError.status!})
    }
  };

  /*
  useEffect(() => {
    if (campaignId) {
      getCampaing()
    } else {
      setOriginalCampaign(defaultCampaign);
      setCurrentCampaign(defaultCampaign)
    }
  }, [campaignId]);
  */

  const handleCampaignInfoChange = (key: string, value: any) => {
    setCurrentCampaign((prevCampaign) => ({
      ...prevCampaign,
      [key]: value,
    }));
  };

  const handleTemplateChange = (updatedTemplate: EmailTemplateType) => {
    setCurrentCampaign((prevCampaign) => ({
      ...prevCampaign,
      template: updatedTemplate,
    }));
  };

  const handleCancel = () => {
    setCurrentCampaign(originalCampaign)
  };

  const handleSaveCampaign = async() => {
    setIsSaving(true);
    try {
      const response = await createEmailCampaign(currentCampaign);
      if (response && response.status === 201) {
        alert('Email campaign created')
      } else {
        alert('An error occured while creating the email campaign')
      }
    } catch(error:any) {
      console.log(error);
      const handledError = handleApiError(error);
      alert(handledError.message)
    }
  };

  const handleUpdateCampaign = async() => {
    setIsSaving(true);
    try {
      const response = await updateEmailCampaign(currentCampaign._id!, currentCampaign);
      if (response && response.status === 201) {
        alert('Email campaign updated')
      } else {
        alert('An error occured while updating the email campaign')
      }
    } catch(error:any) {
      console.log(error);
      const handledError = handleApiError(error);
      alert(handledError.message)
    }
  };

  return (
    <div className={styles.email_campaign_editor}>
      <DashboardHeader title={campaignId ? 'Edit Campaign' : 'Add New Campaign'} />
      <CampaignInfoForm
        campaign={currentCampaign}
        onCampaignInfoChange={handleCampaignInfoChange}
      />
      <Tab2
        tabs={['Templates Email', 'Custom Email']}
        activeTab={activeTab}
        onTabChange={setActiveTab}
        wrapperStyle={{ marginTop: 20, marginBottom: 20 }}
      />
      {activeTab === 'Templates Email' ? (
        <div className={styles.templates_section}>
          <EmailTemplateEditor
            template={currentCampaign.template || defaultTemplate}
            onTemplateChange={handleTemplateChange}
          />
        </div>
      ) : (
        <EmailEditor
          value={currentCampaign.htmlContent || ''}
          onChange={(content) => setCurrentCampaign((prev) => ({ ...prev, htmlContent: content }))}
        />
      )}
      <div className={styles.button_container}>
        <Button1 label={campaignId ? 'Update Campaign' : 'Save Campaign'} onClick={currentCampaign?._id ? handleUpdateCampaign : handleSaveCampaign} />
        <ButtonCancel title="Cancel" onClick={handleCancel} />
      </div>
    </div>
  );
};

export default EmailCampaignsEditor;
