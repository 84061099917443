import React, { useState } from 'react';
import styles from './RefuseCancellation.module.scss';
import ButtonCancel from '../../../../components/Button/ButtonCancel/ButtonCancel';
import Select4 from '../../../../components/Select/Select4/Select4';

interface RefundRefuseComponentProps {
    onRefuse?: (reason: string, message: string) => void;
}

const RefuseCancellation: React.FC<RefundRefuseComponentProps> = ({ onRefuse }) => {
    const [refuseReason, setRefuseReason] = useState('');
    const [refuseMessage, setRefuseMessage] = useState('');

    const handleRefuse = () => {
        if (onRefuse) {
            onRefuse(refuseReason, refuseMessage);
        }
        setRefuseReason('');
        setRefuseMessage('');
    };

    const options = [ { label: 'Select Reason for Refuse', value: "" }, { label: 'Policy Violation', value: 'policy_violation' }, { label: 'Late Request', value: 'late_request' }, { label: 'Other', value: 'other'}   ]
    return (
        <div className={styles.refuseCancellationContainer}>
            <h2>Refuse Cancellation</h2>
            <Select4 value={""} options={options} onChange={()=>null}/>
            <textarea
                value={refuseMessage}
                onChange={(e) => setRefuseMessage(e.target.value)}
                placeholder="Provide detailed reason for refusal"
                className={styles.refuseTextarea}
            />
            <ButtonCancel title='Refuse Refund' onClick={handleRefuse} />
        </div>
    );
};

export default RefuseCancellation;
