import { FaCogs, FaHome, FaUser } from "react-icons/fa";

const defaultTabs = [
    {
        label: 'Home',
        icon: <FaHome />,
    },
    {
        label: 'Profile',
        icon: <FaUser />,
    },
    {
        label: 'Settings',
        icon: <FaCogs />,
    },
];

const tabDefaultProps = {
    tabs: defaultTabs,
    activeTab: 'Home', // Default active tab
    onTabChange: (label: string) => {
        console.log(`Active tab changed to: ${label}`);
    },
};

export {
    tabDefaultProps
}