import React from 'react';
import styles from './EmailCampaignList.module.scss';
import { EmailCampaignType, EmailCampaignStatus } from '../../../types/Data/EmailCampaignType';

interface CampaignListProps {
  campaigns: Partial<EmailCampaignType>[];
  onEdit: (campaignId: string) => void;
  onSend: (campaignId: string) => void;
  onDelete: (campaignId: string) => void;
  onViewDetails: (campaignId: string) => void;
}

const EmailCampaignList: React.FC<CampaignListProps> = ({
  onEdit,
  onSend,
  onViewDetails,
  onDelete,
  campaigns = []
}) => {
  return (
    <div className={styles.campaignList}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Campaign Name</th>
            <th>Subject</th>
            <th>Status</th>
            <th>Scheduled Date</th>
            <th>Sent Date</th>
            <th>Segment</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {campaigns.map((campaign) => (
            <tr key={campaign._id}>
              <td>{campaign.name}</td>
              <td>{campaign.subject}</td>
              <td>{campaign.status}</td>
              <td>{campaign.scheduledDate ? new Date(campaign.scheduledDate).toLocaleDateString() : '—'}</td>
              <td>{campaign.sentDate ? new Date(campaign.sentDate).toLocaleDateString() : '—'}</td>
              <td>{campaign.segment || 'All Customers'}</td>
              <td className={styles.actions}>
                {campaign.status !== EmailCampaignStatus.Sent && (
                  <button className={styles.send} onClick={() => onSend(campaign._id!)}>Send</button>
                )}
                {campaign.status === EmailCampaignStatus.Sent && (
                  <button className={styles.view} onClick={() => onViewDetails(campaign._id!)}>View Details</button>
                )}
                {campaign.status !== EmailCampaignStatus.Sent && (
                  <button className={styles.edit} onClick={() => onEdit(campaign._id!)}>Edit</button>
                )}
                <button className={styles.delete} onClick={() => onDelete(campaign._id!)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EmailCampaignList;
