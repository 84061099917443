import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL || 'http://localhost:3001',  // Replace with your server's base URL
    withCredentials: true,  // Ensures cookies and credentials are included in cross-site requests if needed
    headers: {
        'Content-Type': 'application/json',
    },
});


// Function to handle success responses
export function handleSuccess(response: any) {
    const message = response.data?.message || 'Operation completed successfully';
    
    // Optionally, you can log the success or show a success toast message
    console.log(message);
    alert(message); // For user feedback, consider using a toast notification system like react-toastify
    
    return response.data; // Return the data for further processing
}

// Function to handle errors
export function handleError(error: any) {
    let errorMessage = 'Something went wrong. Please try again.';
    
    // Extract error message based on response status
    if (error.response) {
        // Server responded with a status code out of 2xx range
        errorMessage = error.response.data?.message || `Error: ${error.response.status}`;
        console.error(`Error ${error.response.status}: ${errorMessage}`);
    } else if (error.request) {
        // Request was made, but no response was received
        console.error('No response received from server', error.request);
    } else {
        // Something happened during the request setup
        console.error('Error setting up the request:', error.message);
    }

    // Log or display the error
    alert(errorMessage); // Consider replacing with a toast notification for better user experience

    // Optionally, you can log the error to a tracking service here
    return Promise.reject(errorMessage); // Return a rejected promise with the error message
}

export default api;
