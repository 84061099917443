import React from 'react';
import styles from './ContactInfoV1.module.scss';
import { defaultContactInfoProps } from '../companyInfoDefaultProps';
import { FaClock, FaEnvelope, FaMapMarker, FaPhone } from 'react-icons/fa';
import { ContactInfoComponentProps } from '../ContactInfoComponentProps';

const ContactInfoV1: React.FC<ContactInfoComponentProps> = ({ 
    address = defaultContactInfoProps.address, 
    phone = defaultContactInfoProps.phone, 
    email = defaultContactInfoProps.email, 
    workingHours  = defaultContactInfoProps.workingHours
}) => {
  return (
    <div className={styles.contactInfo}>
      <h2>Contact Information</h2>
      <div className={styles.infoItem}>
        <FaMapMarker/>
        <p>{address}</p>
      </div>
      <div className={styles.infoItem}>
        <FaPhone/>
        <p>{phone}</p>
      </div>
      <div className={styles.infoItem}>
        <FaEnvelope/>
        <p>{email}</p>
      </div>
      {workingHours && (
        <div className={styles.infoItem}>
            <FaClock/>
          <p>{workingHours}</p>
        </div>
      )}
    </div>
  );
};

export default ContactInfoV1;
