import React from "react";
import { useSelector } from "react-redux";
import styles from "./InventoryList.module.scss";
import { RootState } from "../../../Store/store";
import { ProductType } from "../../../types/Data/ProductType";

const InventoryList: React.FC = () => {
  const products = useSelector((state: RootState) => state.dashboard.products.products) as ProductType[];
  return (
      <div className={styles.tableContainer}>
        <table className={styles.inventoryTable}>
          <thead>
            <tr>
              <th>Product Name</th>
              <th>SKU</th>
              <th>Stock Level</th>
              <th>Reorder Threshold</th>
              <th>Supplier</th>
              <th>Sales Trend</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product._id}>
                <td>{product.name}</td>
                <td>{product.sku}</td>
                <td>{product.stockLevel}</td>
                <td>{product.lowStockThreshold}</td>
                <td><a href={product.supplierLink}>Order from Supplier</a></td>
                <td>
                  {/* Add a simple sales trend graph or icon */}
                  <span className={styles.salesTrend}>Sales Data</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
  );
};

export default InventoryList
