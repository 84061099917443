import React from 'react';
import styles from './CategoryDisplayV5.module.scss';
import { CategoryDisplayComponentProps } from "../CategoryDisplayComponentProps";
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { CategoryType } from '../../../types/Data/CategoryType';

const CategoryDisplayV5: React.FC<CategoryDisplayComponentProps> = ({ selectedCategoriesId = [], onClick=()=>null }) => {
  const allCategories = useSelector<RootState>(state => state.dashboard.categories.categories) as CategoryType[];

  const categories = selectedCategoriesId.length > 0
    ? allCategories.filter((category) => selectedCategoriesId.includes(category._id!))
    : allCategories.slice(0, 10);

  return (
    <div className={styles.productCategoryContainer}>
      {categories.map((category, index) => {
        const size = (index % 3 === 0) ? 'big' : 'small';

        return (
          <div key={index} className={`${styles.productCategoryWrapper} ${styles[size]}`} onClick={()=>{onClick && onClick(category.name)}}>
            <div className={styles.productCategoryCard}>
              <img src={category.image?.url} alt={category.name} />
              <div className={styles.overlay} />
              <div className={styles.content}>
                <h5>{category.name}</h5>
                <p>{category.seoDescription}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CategoryDisplayV5;
