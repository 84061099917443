import React from 'react';
import styles from './ContactInfoV2.module.scss';
import { defaultContactInfoProps } from '../companyInfoDefaultProps';
import { ContactInfoComponentProps } from '../ContactInfoComponentProps';

const ContactInfoV2: React.FC<ContactInfoComponentProps> = ({ 
    address = defaultContactInfoProps.address, 
    phone = defaultContactInfoProps.phone, 
    email = defaultContactInfoProps.email, 
    workingHours  = defaultContactInfoProps.workingHours
 }) => {
  return (
    <div className={styles.contactInfo}>
      <div className={styles.column}>
        <h3>Address</h3>
        <p>{address}</p>
      </div>
      <div className={styles.column}>
        <h3>Phone</h3>
        <p>{phone}</p>
        <h3>Email</h3>
        <p>{email}</p>
        {workingHours && (
          <>
            <h3>Working Hours</h3>
            <p>{workingHours}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default ContactInfoV2;
