import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartData, ChartOptions } from 'chart.js';
import styles from './ChartCustomerCommunicationPreference.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Store/store';
import { CustomerType } from '../../../../../types/Data/CustomerType';

interface ChartCustomerGenderDistributionProps {}

const ChartCustomerCommunicationPreference: React.FC<ChartCustomerGenderDistributionProps> = () => {
    const customers = useSelector((state: RootState) => state.dashboard.customers.customers) as CustomerType[];

    // Function to calculate gender distribution
    const getCommunicationDistribution = () => {
        const communicationDistribution: { [key: string]: number } = {};
        
        customers.forEach((customer) => {
            const communication = customer.communicationPreference || 'Unknown';  // Handle cases where gender is not defined
            communicationDistribution[communication] = (communicationDistribution[communication] || 0) + 1;
        });

        return communicationDistribution;
    };

    const communicationDistribution = getCommunicationDistribution();
    const communicationLabels = Object.keys(communicationDistribution);
    const communicationCounts = Object.values(communicationDistribution);

    const data: ChartData<'bar'> = {
        labels: communicationLabels,
        datasets: [
            {
                label: 'Number of Customers',
                data: communicationCounts,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                ticks: {
                    color: 'white', // X-axis labels color
                },
            },
            y: {
                beginAtZero: true,
                ticks: {
                    color: 'white', // Y-axis labels color
                },
            },
        },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: 'white', // Legend text color
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.dataset.label}: ${context.raw}`;
                    },
                },
            },
        },
    };

    return (
        <div className={styles.container}>
            <h3>Customer Communication Preference</h3>
            <div className={styles.chartContainer}>
                <Bar data={data} options={options} />
            </div>
        </div>
    );
};

export default ChartCustomerCommunicationPreference;
