import React, { useEffect } from 'react';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';

import styles from './PaymentMethodsPage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../Store/store';
import { PaymentMethodType } from '../../../types/Data/PayementSettingsType';
import PaymentMethodForm from './EditPaymentMethod/PaymentMethodEditPage';
import { dispatchLoadPaymentMethod, fetchAllPaymentMethods } from '../../DashboardState/paymentMethods/paymentMethodsSlice';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import PaymentMethodsTable from './PaymentMethodList/PaymentMethodsList';
import ButtonV5 from '../../../components/Button/ButtonV5/ButtonV5';
import { useNavigate } from 'react-router-dom';
import { deletePaymentMethod, togglePaymentMethodStatus } from '../../../api/paymentMethod/paymentMethodApi';
import { handleApiError } from '../../../api/apiError';

const PaymentMethodsPage: React.FC = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const { methods, loading, error } = useSelector((state: RootState) => state.dashboard.paymentMethods);
  
  /*
  useEffect(() => {
    dispatch(fetchAllPaymentMethods())
  })
  */

  const handleAddPaymentMethod = () => {
    navigate('./edit');
  };

  const handleEditPaymentMethod = (name: string) => {
    navigate(`./edit/?paymentMethodName=${name}`);
  };

  const handleDeletePaymentMethod = async (id: string) => {
    try {
      const response = await deletePaymentMethod(id);
      if (response.status === 200) {
        alert('Payment method deleted!');
      } else {
        alert('An error occurred while deleting payment method');
      }
    } catch (error: any) {
      const handledError = handleApiError(error);
      alert(handledError.message);
    }
  };

  const handleToggleStatusPaymentMethod = async (id: string) => {
    try {
      const response = await togglePaymentMethodStatus(id);
      if (response.status === 200) {
        alert('Payment method status updated!');
      } else {
        alert('An error occurred while updating payment method status');
      }
    } catch (error: any) {
      const handledError = handleApiError(error);
      alert(handledError.message);
    }
  };

  if (loading) return <LoadingIndicatorV1 />;
  if (error) return <ErrorBannerV1 message={error} />;

  return (
    <div className={styles.payment_settings_page}>
      <DashboardHeader title="Payment Settings" />
      <ButtonV5
        style={{ marginTop: 10 }}
        title="Add Payment Method"
        onClick={handleAddPaymentMethod}
      />
      <div className={styles.payement_wrapper}>
        <PaymentMethodsTable 
          paymentMethods={methods} 
          onDelete={handleDeletePaymentMethod} 
          onEdit={handleEditPaymentMethod} 
          onToggleStatus={handleToggleStatusPaymentMethod}
        />
      </div>
    </div>
  );
};

export default PaymentMethodsPage;
