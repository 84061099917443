import React from 'react'
import styles from '../Component.module.scss';
import CouponPromotionV1 from '../../UIElements/CouponPromotion/CouponPromotionV1/CouponPromotionV1';
import CouponPromotionV2 from '../../UIElements/CouponPromotion/CouponPromotionV2/CouponPromotionV2';
import CouponPromotionV3 from '../../UIElements/CouponPromotion/CouponPromotionV3/CouponPromotionV3';

const CouponPromotionComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <CouponPromotionV1/>
        </div>
        <div className={styles.element}>
            <CouponPromotionV2/>
        </div>
        <div className={styles.element}>
            <CouponPromotionV3/>
        </div>
    </div>
  )
}

export default CouponPromotionComponentPage