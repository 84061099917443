import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartData, ChartOptions } from 'chart.js';
import styles from './ChartOrderEvolutionOverTime.module.scss';
import { OrderType } from '../../../../types/Data/OrderType';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Store/store';


interface OrderAmountsOverTimeProps {
}

const ChartOrderEvolutionOverTime: React.FC<OrderAmountsOverTimeProps> = ({ }) => {
    const orders = useSelector((state:RootState)=>state.dashboard.orders.orders) as OrderType[]

    const getOrderAmountsOverTime = () => {
        const dates: { [key: string]: number } = {};
        orders.forEach(order => {
            const date = new Date(order.timestamps.orderDate).toLocaleDateString();
            dates[date] = (dates[date] || 0) + order?.orderSummary?.totalAmount!;
        });
        return dates;
    };

    const orderAmounts = getOrderAmountsOverTime();
    const dateLabels = Object.keys(orderAmounts);
    const dateData = Object.values(orderAmounts);

    const data: ChartData<'line'> = {
        labels: dateLabels,
        datasets: [
            {
                label: 'Total Amount',
                data: dateData,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                fill: true,
                tension: 0.4,
            },
        ],
    };

    const options: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    color: 'white', // Legend text color
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.raw !== null) {
                            label += new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }).format(context.raw as number);
                        }
                        return label;
                    },
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: 'white', // X-axis label color
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)', // X-axis grid line color
                },
            },
            y: {
                ticks: {
                    color: 'white', // Y-axis label color
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)', // Y-axis grid line color
                },
            },
        },
    };

    return (
        <div className={styles.container}>
            <h3>Orders evolution Over Time</h3>
            <div className={styles.chart_container}>
                <Line data={data} options={options} />
            </div>
        </div>
    );
};

export default ChartOrderEvolutionOverTime;
