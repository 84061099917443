import react, { useEffect, useState } from 'react';
import DashboardHeader from '../../components/Header/DashboardHeader/DashboardHeader';
import styles from './CustomersPage.module.scss';
import TableInteractive from '../../components/Tableau/TableInteractive/TableInteractive';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store/store';
import Filters from './CustomersFilters/Filters';
import { CustomerType } from '../../types/Data/CustomerType';
import { useNavigate } from 'react-router-dom';
import { dispatchLoadCustomers, fetchAllCustomers } from '../DashboardState/customers/customersSlice';
import ErrorBannerV1 from '../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import LoadingIndicatorV1 from '../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import exampleCustomers from '../DashboardState/customers/exampleCustomers';
import CustomerList from './CustomerList/CustomerList';

const CustomersPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    
    const customers = useSelector<RootState>((state) => state.dashboard.customers.customers) as CustomerType[];
    const loading = useSelector<RootState, boolean>((state) => state.dashboard.customers.loading);
    const error = useSelector<RootState, string | null>((state) => state.dashboard.customers.error);
    
    const [query, setQuery] = useState({
        search:"",
    });

    const [page, setPage] = useState(1); // Separate page state

    /*
    useEffect(() => {
        dispatch(fetchAllCustomers({ ...query, page }));
    }, [dispatch]);
    */
    
    const handleFilterChange = (key: string, value: any) => {
        setQuery((prevQuery) => ({ ...prevQuery, [key]: value }));
        setPage(1); // Reset page to 1 when query changes
    };

    const handleViewCustomer = (customerId:string) => {
        navigate(`./${customerId}`)
    }

    return (
        <div className={styles.dashboard}>
            <DashboardHeader title='Customers List'/>
            <div className={styles.content}>
                <Filters 
                    query={query}
                    onQueryChange={handleFilterChange}
                />
                <div className={styles.orders_array}>
                {/* Handle loading state */}
                {loading && <LoadingIndicatorV1 />}
                
                {/* Handle error state */}
                {error && <ErrorBannerV1 message={typeof error === 'string' ? error : 'An error occurred'} />}
                
                {/* Display product list if not loading and no error */}
                {
                    <CustomerList/>
                }
                </div>
            </div>
        </div>
    )
}

export default CustomersPage