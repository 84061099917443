// NotificationsPage.tsx
import React, { useState, useEffect } from 'react';
import styles from './NotificationListPage.module.scss';
import { NotificationType } from '../../types/Data/NotificationType';
import NotificationCard from './NotificationCard/NotificationCard';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store/store';
import DashboardHeader from '../../components/Header/DashboardHeader/DashboardHeader';

const NotificationsListPage: React.FC = () => {

    const notifications = useSelector<RootState>((state)=>state.dashboard.notifications.notifications) as NotificationType[]
  
    return (
        <div className={styles.notificationsPage}>
        <DashboardHeader title='Notifications'/>
        <div className={styles.notificationsGrid}>
            {notifications.map((notification) => (
            <NotificationCard key={notification._id} notification={notification} />
            ))}
        </div>
        </div>
    );
};

export default NotificationsListPage;
