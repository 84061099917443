import React from 'react';
import styles from './OrderSummaryV1.module.scss';
import { OrderSummaryComponentProps } from '../OrderSummaryComponentProps';
import { FaShoppingCart, FaTruck, FaPercent, FaDollarSign } from 'react-icons/fa';

const OrderSummaryV1: React.FC<OrderSummaryComponentProps> = ({ subtotal = 0, shipping = 0, discount = 0, total = 0 }) => {
  return (
    <div className={styles.orderSummary}>
      <h2 className={styles.title}>Order Summary</h2>
      
      <div className={styles.lineItem}>
        <FaShoppingCart className={styles.icon} />
        <span>Subtotal</span>
        <span>${subtotal.toFixed(2)}</span>
      </div>

      <hr className={styles.divider} />

      <div className={styles.lineItem}>
        <FaTruck className={styles.icon} />
        <span>Shipping</span>
        <span>${shipping.toFixed(2)}</span>
      </div>

      <hr className={styles.divider} />

      <div className={styles.lineItem}>
        <FaPercent className={styles.icon} />
        <span>Discount</span>
        <span>-${discount.toFixed(2)}</span>
      </div>

      <hr className={styles.divider} />

      <div className={`${styles.lineItem} ${styles.total}`}>
        <FaDollarSign className={styles.icon} />
        <span>Total</span>
        <span>${total.toFixed(2)}</span>
      </div>
    </div>
  );
};

export default OrderSummaryV1;
