import React from 'react';
import styles from './CheckboxV1.module.scss';
import { CheckboxComponentProps } from '../CheckboxComponentProps';

const CheckboxV1: React.FC<CheckboxComponentProps> = ({ label = "Checbox", checked = true, onChange = ()=>null, wrapperStyle = {} }) => {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <label className={styles.checkbox} style={{...wrapperStyle}}>
      <input
        type="checkbox"
        checked={checked}
        onChange={handleCheckboxChange}
      />
      <span className={styles.checkmark}></span>
      <span className={styles.labelText}>{label}</span>
    </label>
  );
};

export default CheckboxV1;
