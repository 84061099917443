import React, { ReactNode, useState } from "react";
import styles from './RefundNoteSection.module.scss';

interface NotesSectionProps {
    notes: string;
    onNoteChange:(notes:string)=>void;
    children?: ReactNode;
}

const RefundNoteSection: React.FC<NotesSectionProps> = ({ notes, onNoteChange, children }) => {

   

    return (
        <div className={styles.notes}>
            <h2>Notes</h2>
            <textarea
            className={styles.notesTextArea}
                value={notes}
                onChange={(e) => onNoteChange(e.target.value)}
            />
            {children && children}
        </div>
    );
};

export default RefundNoteSection;
