// AnalyticCard.tsx
import React, { ReactElement } from 'react';
import styles from './StatCardV2.module.scss';
import { StatItem } from '../../StatsComponentProps';

const StatCardV2: React.FC<StatItem> = ({ title, value, icon, status }) => {
  return (
    <div className={styles.card}>
      <div className={styles.icon}>
        {icon}
      </div>
      <div className={` ${styles.card_content} ${ status ? styles[status] : ''}`}>
        <h2>{title}</h2>
        <p>{value}</p>
      </div>
    </div>
  );
};

export default StatCardV2;
