import React from 'react';
import styles from './ButtonProceedToCheckoutV1.module.scss';
import { ButtonProceedToCheckoutProps } from '../ButtonCheckoutComponent';
import { FaShoppingCart } from 'react-icons/fa';

const ButtonProceedToCheckoutV1: React.FC<ButtonProceedToCheckoutProps> = ({
  label = 'Proceed to Checkout',
  onClick = ()=>null
}) => {
  return (
    <button className={styles.checkoutButton} onClick={onClick}>
      <FaShoppingCart/> 
      {label}
    </button>
  );
};

export default ButtonProceedToCheckoutV1;
