import React from 'react'
import styles from '../Component.module.scss';
import HeroBannerV1 from '../../UIElements/HeorBanner/HeroBannerV1/HeroBannerV1';
import HeroBannerV2 from '../../UIElements/HeorBanner/HeroBannerV2/HeroBannerV2';
import HeroBannerV3 from '../../UIElements/HeorBanner/HeroBannerV3/HeroBannerV3';
import HeroBannerV4 from '../../UIElements/HeorBanner/HeroBannerV4/HeroBannerV4';
import HeroBannerV5 from '../../UIElements/HeorBanner/HeroBannerV5/HeroBannerV5';
import HeroBannerV6 from '../../UIElements/HeorBanner/HeroBannerV6/HeroBannerV6';

const HeroBannerComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <HeroBannerV1/>
        </div>
        <div className={styles.element}>
            <HeroBannerV2/>
        </div>
        <div className={styles.element}>
            <HeroBannerV3/>
        </div>
        <div className={styles.element}>
            <HeroBannerV4/>
        </div>
        <div className={styles.element}>
            <HeroBannerV4/>
        </div>
        <div className={styles.element}>
            <HeroBannerV5/>
        </div>
        <div className={styles.element}>
            <HeroBannerV6/>
        </div>
    </div>
  )
}

export default HeroBannerComponentPage