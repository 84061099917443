import React from 'react';
import styles from './AdminList.module.scss';
import { AdminType } from '../../../types/Data/AdminType';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { useNavigate } from 'react-router-dom';

interface AdminListProps {
    admins: AdminType[];
    onDelete: (adminId: string) => void;
}

const AdminList: React.FC<AdminListProps> = ({ admins=[], onDelete }) => {
    const navigate = useNavigate();

    const handleEdit = (adminId:string) => {
        navigate(`/dashboard/admins/edit/${adminId}`)
    };

    return (
        <div className={styles.adminList}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {admins.map(admin => (
                        <tr key={admin._id}>
                            <td>
                                <div className={styles.profile}>
                                    <img src={admin.profileImageUrl || '/default-profile.png'} alt={admin.username} className={styles.avatar} />
                                    <span>{admin.firstName} {admin.lastName}</span>
                                </div>
                            </td>
                            <td>{admin.email}</td>
                            <td>{admin.role}</td>
                            <td>
                                <span className={`${styles.status} ${admin.isActive ? styles.active : styles.inactive}`}>
                                    {admin.isActive ? 'Active' : 'Inactive'}
                                </span>
                            </td>
                            <td className={styles.actions}>
                                <button className={`${styles.button} ${styles.edit}`} onClick={() => handleEdit(admin._id)}>Edit</button>
                                <button className={`${styles.button} ${styles.delete}`} onClick={() => onDelete(admin._id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AdminList;
