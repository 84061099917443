import React from 'react'
import styles from '../Component.module.scss';
import CategoryDisplayV1 from '../../UIElements/CategoryDisplay/CategoryDisplayV1/CategoryDisplayV1';
import CategoryDisplayV3 from '../../UIElements/CategoryDisplay/CategoryDisplayV3/CategoryDisplayV3';
import CategoryDisplayV2 from '../../UIElements/CategoryDisplay/CategoryDisplayV2/CategoryDisplayV2';
import CategoryDisplayV4 from '../../UIElements/CategoryDisplay/CategoryDisplayV4/CategoryDisplayV4';
import CategoryDisplayV5 from '../../UIElements/CategoryDisplay/CategoryDisplayV5/CategoryDisplayV5';

const CategoryDisplayComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <CategoryDisplayV1/>
        </div>
        <div className={styles.element}>
            <CategoryDisplayV2/>
        </div>
        <div className={styles.element}>
            <CategoryDisplayV3/>
        </div>
        <div className={styles.element}>
            <CategoryDisplayV4/>
        </div>
        <div className={styles.element}>
            <CategoryDisplayV5/>
        </div>
    </div>
  )
}

export default CategoryDisplayComponentPage