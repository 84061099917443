// src/components/PriceRangeInput.tsx
import React, { useState, useEffect } from 'react';
import styles from './InputPriceRangeV1.module.scss';
import { InputPriceRangeComponentProps } from '../../InputComponentProps';

const InputPriceRangeV1: React.FC<InputPriceRangeComponentProps> = ({ minValue = 0, maxValue = 1000, onChange }) => {
  const [minPrice, setMinPrice] = useState(minValue);
  const [maxPrice, setMaxPrice] = useState(maxValue);

  useEffect(() => {
    if (onChange) {
        onChange(minPrice, maxPrice);
    }
  }, [minPrice, maxPrice, onChange]);

  const handleMinPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMinPrice(Number(e.target.value));
  };

  const handleMaxPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMaxPrice(Number(e.target.value));
  };

  return (
    <div className={styles.priceRangeInput}>
      <input
        type="number"
        value={minPrice}
        onChange={handleMinPriceChange}
        placeholder="Min Price"
        className={styles.input}
      />
      <input
        type="number"
        value={maxPrice}
        onChange={handleMaxPriceChange}
        placeholder="Max Price"
        className={styles.input}
      />
    </div>
  );
};

export default InputPriceRangeV1;
