import React, { useEffect, useState } from 'react';
import styles from '../../UIElementsMainSettings/UiElementEditor.module.scss';
import { FilterModalComponentMapping, FilterModalComponentProps, FilterModalComponentVersions } from '../FilterModalComponentProps';
import Switch1 from '../../../components/Switch/SwitchV1/SwitchV1';
import Select4 from '../../../components/Select/Select4/Select4';
import { AvailabilityFilterVersions, BrandFilterVersions, CategoryFilterVersions, ColorFilterVersions, DefaultFilterVersions, MaterialFilterVersions, PriceRangeFilterVersions, RatingFilterVersions, SizeFilterVersions } from '../../Filter/FilterComponentProps';
import { defaultFilterModalProps } from '../defaultFilterModalProps';
import { BaseElementType, ComponentType } from '../../../types/Data/BaseElementType';
import ButtonV1 from '../../../components/Button/ButtonV1/ButtonV1';
import UIElementsMainSettings from '../../UIElementsMainSettings/UIElementsMainSettings';
import ButtonDelete from '../../../components/Button/ButtonDelete/ButtonDelete';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../Store/store';
import { fetchCreateElement, fetchUpdateElement } from '../../../Dashboard/DashboardState/element/elementSlice';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';

interface FilterModalEditorProps {
  element?: BaseElementType<FilterModalComponentProps>;
}

const FilterModalEditor: React.FC<FilterModalEditorProps> = ({ element }) => {
  
  const defaultElement: BaseElementType<FilterModalComponentProps> = {
    type: ComponentType.FilterModal,
    version: 'v1',
    page: 'home',
    props: defaultFilterModalProps,
    position: undefined,
    isVisible: true,
  };

  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state:RootState)=>state.dashboard.elements.loading);
  const error = useSelector((state:RootState)=>state.dashboard.elements.error);
  const [currentElement, setCurrentElement] = useState<BaseElementType<FilterModalComponentProps>>(element ? element : defaultElement);

  useEffect(() => {
    if (element) {
      setCurrentElement(element);
    } else {
      setCurrentElement(defaultElement);
    }
  }, [element]);
  
  const handleElementUpdate = (key: string, value: any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      [key]: value,
    }));
  };

  const handleElementVisibilityUpdate = () => {
    setCurrentElement((prevElement) => ({ ...prevElement, isVisible: !prevElement.isVisible }));
  };

  const handlePropsChange = (key:string, value:any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      props: {
        ...prevElement.props,
        [key]: value,
      }
    }));
  };

  const handleSaveElement = () => {
    if (currentElement?._id) {
      dispatch(fetchUpdateElement({id:currentElement._id, element:currentElement}))
    } else {
      dispatch(fetchCreateElement(currentElement))
    }
  };

  const categoryFilterOptions = Object.keys(CategoryFilterVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: CategoryFilterVersions[key as keyof typeof CategoryFilterVersions],
  }));

  const colorFilterOptions = Object.keys(ColorFilterVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: ColorFilterVersions[key as keyof typeof ColorFilterVersions],
  }));

  const availabilityFilterOptions = Object.keys(AvailabilityFilterVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: AvailabilityFilterVersions[key as keyof typeof AvailabilityFilterVersions],
  }));

  const ratingFilterOptions = Object.keys(RatingFilterVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: RatingFilterVersions[key as keyof typeof RatingFilterVersions],
  }));

  const priceRangeFilterOptions = Object.keys(PriceRangeFilterVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: PriceRangeFilterVersions[key as keyof typeof PriceRangeFilterVersions],
  }));

  const defaultFilterOptions = Object.keys(DefaultFilterVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: DefaultFilterVersions[key as keyof typeof DefaultFilterVersions],
  }));


  const SelectedComponent = FilterModalComponentMapping[currentElement.version as FilterModalComponentVersions] || FilterModalComponentMapping[FilterModalComponentVersions.V1];

  const elementsVersion = Object.keys(FilterModalComponentVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: FilterModalComponentVersions[key as keyof typeof FilterModalComponentVersions],
  }));

  return (
    <div className={styles.container}> 
    { error && <ErrorBannerV1 message={error}/>}
    { loading && <LoadingIndicatorV1/>}
      <div className={styles.componentPreview}>
        <SelectedComponent {...currentElement.props}/>
      </div>    
      <div className={styles.componentEditor}>
        <UIElementsMainSettings
            element={currentElement}
            elementVersions={elementsVersion}
            onElementUpdate={handleElementUpdate}
            onVisibilitytUpdate={handleElementVisibilityUpdate}
        />
        <div className={styles.slides}>
          <div className={styles.slide}>
            <Switch1
              label='Show Category Filter'
              isChecked={currentElement.props?.showCategoryFilter || false}
              onToggle={() => handlePropsChange('showCategoryFilter', !currentElement.props?.showCategoryFilter)}
            />
            {currentElement.props?.showCategoryFilter && (
              <Select4
                value={currentElement?.props?.categoryFilterVersion}
                options={categoryFilterOptions}
                onChange={(value) => handlePropsChange('categoryFilterVersion', value as string)}
              />
            )}
         </div>
         <div className={styles.slide}>
          <Switch1
            label='Show Color Filter'
            isChecked={currentElement?.props?.showColorFilter || false}
            onToggle={() => handlePropsChange('showColorFilter', !currentElement?.props?.showColorFilter )}
          />
          {currentElement?.props?.showColorFilter && (
            <Select4
              value={currentElement?.props.colorFilterVersion}
              options={colorFilterOptions}
              onChange={(value) => handlePropsChange('colorFilterVersion', value as string)}
            />
          )}
         </div>
         <div className={styles.slide}>
          <Switch1
            label='Show Rating Filter'
            isChecked={currentElement?.props?.showRatingFilter || false}
            onToggle={() => handlePropsChange('showRatingFilter', !currentElement?.props?.showRatingFilter)}
          />
          {currentElement?.props?.showRatingFilter && (
            <Select4
              value={currentElement?.props?.ratingFilterVersion}
              options={ratingFilterOptions}
              onChange={(value) => handlePropsChange('ratingFilterVersion', value as string)}
            />
          )}
         </div>
         <div className={styles.slide}>
          <Switch1
            label='Show Availability Filter'
            isChecked={currentElement?.props?.showAvailabilityFilter || false}
            onToggle={() => handlePropsChange('showAvailabilityFilter', !currentElement?.props?.showAvailabilityFilter )}
          />
          {currentElement.props?.showAvailabilityFilter && (
            <Select4
              value={currentElement.props?.availabilityFilterVersion}
              options={availabilityFilterOptions}
              onChange={(value) => handlePropsChange('availabilityFilterVersion', value as string)}
            />
          )}
         </div>
         <div className={styles.slide}>
          <Switch1
            label='Show Price Range Filter'
            isChecked={currentElement?.props?.showPriceRangeFilter || false}
            onToggle={() => handlePropsChange('showPriceRangeFilter', !currentElement?.props?.showPriceRangeFilter )}
          />
          {currentElement.props?.showPriceRangeFilter && (
            <Select4
              value={currentElement.props?.availabilityFilterVersion}
              options={priceRangeFilterOptions}
              onChange={(value) => handlePropsChange('priceRangeFilterVersion', value as string)}
            />
          )}
         </div>
         <div className={styles.slide}>
            <Select4
              label='Default filter version'
              value={currentElement.props?.defaultFilterVersion}
              options={defaultFilterOptions}
              onChange={(value) => handlePropsChange('defaultFilterVersion', value as string)}
            />
         </div>
        </div>
        </div>
        <div className={styles.buttonContainer}>
        <ButtonV1 label="Save Element" onClick={handleSaveElement} />
        <ButtonDelete/>
      </div>



      
    </div>
  );
};

export default FilterModalEditor;
