import React from 'react';
import { EmailTemplateType } from '../../../../types/Data/EmailTemplateType';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Store/store';
import { CustomerType } from '../../../../types/Data/CustomerType';

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    animation: 'bounceIn 1s ease-in-out'
  },
  header: {
    backgroundColor: '#ff6600', // replace with your $accent-color
    color: '#ffffff',
    textAlign: 'center',
    padding: '20px',
    fontSize: '24px',
    fontWeight: 'bold'
  },
  body: {
    padding: '20px',
    textAlign: 'center'
  },
  h2: {
    color: '#333333',
    fontSize: '22px',
    marginBottom: '20px'
  },
  p: {
    color: '#666666',
    fontSize: '16px',
    marginBottom: '30px'
  },
  ctaButton: {
    display: 'inline-block',
    padding: '10px 20px',
    backgroundColor: '#007bff', // replace with your $primary-color
    color: '#ffffff',
    textDecoration: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease, transform 0.2s ease',
    cursor: 'pointer'
  },
  ctaButtonHover: {
    backgroundColor: '#0056b3', // replace with darkened $primary-color
    transform: 'translateY(-2px)'
  },
  footer: {
    backgroundColor: '#f8f9fa',
    color: '#666666',
    textAlign: 'center',
    padding: '10px',
    fontSize: '14px'
  }
};

const MembershipRenewalEmail: React.FC<EmailTemplateType> = ({ data }) => {
  
  const customer = useSelector<RootState>((state)=>state.dashboard.customers.customers.find((customer)=>customer._id === data?.customerId)) as Partial<CustomerType>

  return (
    <div>
      <style>
        {`
          @keyframes bounceIn {
            0%, 20%, 40%, 60%, 80%, 100% {
              transition-timing-function: cubic-bezier(0.8, 0, 1, 1);
            }
            0% {
              opacity: 0;
              transform: translate3d(0, -3000px, 0);
            }
            20% {
              transform: translate3d(0, 25px, 0);
            }
            40% {
              transform: translate3d(0, -10px, 0);
            }
            60% {
              transform: translate3d(0, 5px, 0);
            }
            80% {
              transform: translate3d(0, -2px, 0);
            }
            100% {
              opacity: 1;
              transform: none;
            }
          }
        `}
      </style>
      <div style={styles.container}>
        <div style={styles.header}>
          Renew Your Membership
        </div>
        <div style={styles.body}>
          <h2>Hi {customer?.name},</h2>
          { data?.body && <div dangerouslySetInnerHTML={{ __html: data?.body }} /> }
          {data?.importantLinks && data.importantLinks.map((link, index) => (
            <a key={index} href={link.url} style={styles.ctaButton}>
              {link.label}
            </a>
          ))}
        </div>
        <div style={styles.footer}>
          <p>Don't miss out on our new products!</p>
          <p>Unsubscribe | Contact Us</p>
        </div>
      </div>
    </div>
  );
};

export default MembershipRenewalEmail;
