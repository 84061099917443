import ButtonAddToCartV1 from "./ButtonAddToCartV1/ButtonAddToCartV1";
import ButtonAddToCartV2 from "./ButtonAddToCartV2/ButtonAddToCartV2";

enum ButtonAddToCartVersions {
    V1 = "v1",
    V2 = "v2",
};

const ButtonAddToCartMapping:Record<ButtonAddToCartVersions, React.FC<any>> = {
    [ButtonAddToCartVersions.V1]: ButtonAddToCartV1,
    [ButtonAddToCartVersions.V2]: ButtonAddToCartV2,
};

interface ButtonAddToCartComponentProps {
    label?:string;
    onClick?:()=>void;
};

export {
    ButtonAddToCartVersions,
    ButtonAddToCartMapping
};

export type {
    ButtonAddToCartComponentProps
};



