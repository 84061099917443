import React, { useState } from "react";
import CustomerInfoForm from "./CustomerInfoForm/CustomerInfoForm";
import DashboardHeader from "../../../components/Header/DashboardHeader/DashboardHeader";
import AddressForm from "./AddressForm/AddressForm";
import ProductSelection from "./ProductSelection/ProductSelection";
import OrderSummary from "./OrderSummary/OrderSummary";
import PaymentMethod from "./PaymentMethod/PaymentMethod";
import OrderNotes from "./OrderNotes/OrderNotes";
import { OrderItemType, OrderStatus, OrderType, PaymentStatus } from "../../../types/Data/OrderType";
import SaveButton from "../../../components/Button/SaveButton/SaveButton";
import { createOrder } from "../../../api/order/orderApi";
import { handleApiError } from "../../../api/apiError";



const CreateOrderPage:React.FC = () => {
    // Initial default structure for a new order
const defaultOrder: OrderType = {
    _id: '',
    customer: {
        name: '',
        email: '',
        phone: ''
    },
    shippingAddress: {
        street: '',
        city: '',
        state: '',
        postalCode: '',
        country: ''
    },
    billingAddress: {
        street: '',
        city: '',
        state: '',
        postalCode: '',
        country: ''
    },
    items: [],
    orderSummary: {
        subtotal: 0,
        shippingCost: 0,
        discounts: 0,
        tax: 0,
        totalAmount: 0,
    },
    payment: {
        paymentMethod: '',
        paymentStatus: PaymentStatus.PENDING,
        paymentProvider: '',
        transactionId: '',
        paymentFees: 0,
    },
    shipping: {
        shippingMethod: '',
        trackingNumber: '',
        shippingCarrier: '',
        estimatedDeliveryDate: new Date(),
        shippingStatus: OrderStatus.PENDING,
    },
    status: OrderStatus.PENDING,
    timestamps: {
        orderDate: new Date(),
    },
    statusHistory: [
        {
            status: OrderStatus.PENDING,
            changedAt: new Date(),
        },
    ],
    notes: {
        customerNotes: '',
        internalNotes: ''
    },
    coupons: null,
    comments: null,
    createdAt: new Date(),
    updatedAt: new Date(),
    };

    const [currentOrder, setCurrentOrder] = useState<OrderType>(defaultOrder);

    // Handlers for each sub-component to update `currentOrder`
    const handleCustomerInfoChange = (customerInfo: Partial<OrderType["customer"]>) => {
        setCurrentOrder((prevOrder) => ({
            ...prevOrder,
            customer: { ...prevOrder.customer, ...customerInfo },
        }));
    };

    const handleAddressChange = (shippingAddress: OrderType["shippingAddress"], billingAddress: OrderType["billingAddress"]) => {
        setCurrentOrder((prevOrder) => ({
            ...prevOrder,
            shippingAddress,
            billingAddress,
        }));
    };

    const handleAddToOrder = (product: OrderItemType) => {
        setCurrentOrder((prevOrder) => ({
            ...prevOrder,
            items: [...prevOrder.items, product],
            orderSummary: {
                ...prevOrder.orderSummary,
                subtotal: (prevOrder?.orderSummary?.subtotal || 0) + product.totalPrice,
                totalAmount: prevOrder.orderSummary.totalAmount + product.totalPrice,
            },
        }));
    };

    const handleQuantityChange = (index: number, quantity: number) => {
        setCurrentOrder((prevOrder) => {
            const updatedItems = [...prevOrder.items];
            const item = updatedItems[index];
            const difference = (quantity - item.quantity) * item.unitPrice;
            updatedItems[index] = { ...item, quantity, totalPrice: item.unitPrice * quantity };

            return {
                ...prevOrder,
                items: updatedItems,
                orderSummary: {
                    ...prevOrder.orderSummary,
                    subtotal: (prevOrder.orderSummary.subtotal || 0) + difference,
                    totalAmount: prevOrder.orderSummary.totalAmount + difference,
                },
            };
        });
    };

    const handleRemoveItem = (index: number) => {
        setCurrentOrder((prevOrder) => {
            const updatedItems = [...prevOrder.items];
            const [removedItem] = updatedItems.splice(index, 1);

            return {
                ...prevOrder,
                items: updatedItems,
                orderSummary: {
                    ...prevOrder.orderSummary,
                    subtotal: (prevOrder.orderSummary.subtotal || 0) - removedItem.totalPrice,
                    totalAmount: prevOrder.orderSummary.totalAmount - removedItem.totalPrice,
                },
            };
        });
    };

    const handlePaymentChange = (paymentData:{ method: string; provider?: string; transactionId?: string; status: PaymentStatus }) => {
        setCurrentOrder((prevOrder) => ({
            ...prevOrder,
            payment: {
                ...prevOrder.payment,
                ...paymentData,
            },
        }));
    };

    const handleNotesChange = (internalNotes?: string, customerNotes?: string) => {
        setCurrentOrder((prevOrder) => ({
            ...prevOrder,
            notes: {
                customerNotes: customerNotes || prevOrder.notes?.customerNotes || '',
                internalNotes: internalNotes || prevOrder.notes?.internalNotes || '',
            },
        }));
    };

    const handleSaveOrder = async () => {
        try {
            const response = await createOrder(currentOrder);
            if (response.status === 201) {
                alert('Order Created')
            }
            else throw new Error('Error while creating order')
        } catch (error: any) {
            const handledError = handleApiError(error)
            alert(handledError.message)
        }
    };

    return (
        <div>
            <DashboardHeader title="Create Order"/>
            <CustomerInfoForm 
                onCustomerInfoChange={handleCustomerInfoChange}
            />
            <AddressForm
                shippingAddress={currentOrder.shippingAddress}
                billingAddress={currentOrder.billingAddress}
                onAddressChange={handleAddressChange}
            />
            <ProductSelection 
                onAddToOrder={handleAddToOrder}
            />
            <OrderSummary 
                items={currentOrder.items} 
                onQuantityChange={handleQuantityChange} 
                onRemoveItem={handleRemoveItem} 
                shippingCost={currentOrder.orderSummary.shippingCost || 0} 
                discount={currentOrder.orderSummary.discounts || 0}
            />
            <PaymentMethod 
                onPaymentChange={handlePaymentChange}
            />
            <OrderNotes 
                notes={currentOrder?.notes || {internalNotes:'', customerNotes:''}} 
                onNotesChange={handleNotesChange}
            />
            <SaveButton 
                style={{marginTop: 20}}
                onClick={handleSaveOrder}
            />
        </div>

    )
};

export default CreateOrderPage;