import React from 'react'
import styles from '../Component.module.scss';
import ReviewSubmissionV1 from '../../components/ReviewSubmission/ReviewSubmissionV1/ReviewSubmissionV1';
import FormLogin from '../../components/Form/FormLogin/FormLogin';
import FormRegistration from '../../components/Form/FormRegistration/FormRegistration';
import FormForgotPassword from '../../components/Form/FormForgetPassword/FormForgetPassword';

const RegistrationFormComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <FormLogin/>
        </div>
        <div className={styles.element}>
            <FormRegistration/>
        </div>
        <div className={styles.element}>
            <FormForgotPassword/>
        </div>
    </div>
  )
}

export default RegistrationFormComponentPage