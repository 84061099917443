import React, { useState } from "react";
import styles from './FAQsPage.module.scss';
import DashboardHeader from "../../../components/Header/DashboardHeader/DashboardHeader";
import FaqForm from "./AddFAQ/FaqForm";
import SlideOverV3 from "../../../components/SlideOver/SlideOverV3/SliderOverV3";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Store/store";
import { FaqType } from "../../../types/Data/FaqType";
import { addFaq, deleteFaq, updateFaq } from "../../DashboardState/faq/faqSlice";


const FAQsPage: React.FC = () => {
  const dispatch = useDispatch();
  const faqs = useSelector<RootState>((state)=>state.dashboard.faq.faq) as FaqType[]
  
  const [isEditing, setIsEditing] = useState(false);
  const [currentFaq, setCurrentFaq] = useState<FaqType | null>(null);

  const handleAddFaq = () => {
    setIsEditing(true);
    setCurrentFaq(null);
  };

  const handleEditFaq = (faq: FaqType) => {
    setIsEditing(true);
    setCurrentFaq(faq);
  };

  const handleSaveFaq = (faq:Partial<FaqType>) => {
    if (currentFaq) {
      dispatch(updateFaq(faq as FaqType))    
    } else {
      dispatch(addFaq({id: new Date().toISOString(), ...faq} as FaqType))
    }
    setIsEditing(false);
  };

  const handleDeleteFaq = (faqQuestion: string) => {
    dispatch(deleteFaq(faqQuestion));
  };

  return (
    <div className={styles.faqsPage}>
      <DashboardHeader title="Manage FAQs" />
      <div className={styles.content}>
            <button className={styles.addButton} onClick={handleAddFaq}>Add FAQ</button>
            <ul className={styles.faqList}>
              {faqs.map((faq, index) => (
                <li key={index} className={styles.faqItem}>
                  <div className={styles.question}>{faq.question}</div>
                  <div className={styles.answer}>{faq.answer}</div>
                  <div className={styles.actions}>
                    <button className={styles.editButton} onClick={() => handleEditFaq(faq)}>Edit</button>
                    <button className={styles.deleteButton} onClick={() => handleDeleteFaq(faq.question)}>Delete</button>
                  </div>
                </li>
              ))}
            </ul>
          
          <SlideOverV3 
            isOpen={isEditing}
            onClose={()=>setIsEditing(false)}
            
            >
            <FaqForm
            faq={currentFaq || undefined}
            onSave={handleSaveFaq}
            onCancel={() => setIsEditing(false)}
            />
          </SlideOverV3>
        
      </div>
    </div>
  );
};

export default FAQsPage;
