import React from 'react';
import styles from './CartItemListV1.module.scss';
import { cartItemListdefaultProps } from '../defaultCartItemProps';
import { CartItemListComponentProps } from '../CartItemListComponentProps';
import { IoTrash } from 'react-icons/io5';


const CartItemListV1: React.FC<CartItemListComponentProps> = ({ items = cartItemListdefaultProps.items, onQuantityChange = () => null, onRemove = () => null }) => {
  return (
    <div className={styles.cartItemList}>
      <h2>Cart List</h2>
      {items && items.map(item => (
        <div key={item.productId} className={styles.cartItem}>
          <img src={item.imageUrl} alt={item.name} className={styles.image} />
          <div className={styles.details}>
            <h2 className={styles.name}>{item.name}</h2>
            <div className={styles.actions}>
              <input
                type="number"
                value={item.quantity}
                onChange={(e) => onQuantityChange(item.productId, Number(e.target.value))}
                className={styles.quantity}
              />
              <span className={styles.price}>${item.unitPrice.toFixed(2)}</span>
              <button onClick={() => onRemove(item.productId)} className={styles.removeButton}>
                <IoTrash/>
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CartItemListV1;
