import React from 'react';
import styles from './ProductCardListV7.module.scss';
import { ProductCardListComponentProps } from '../ProductCardListComponentProps';


const ProductCardListV7: React.FC<ProductCardListComponentProps> = ({ product, onProductClick = () => null   }) => {
  return (
        <div key={product?._id} className={styles.card}  onClick={()=>onProductClick(product?._id!)}>
          <img src={product?.thumbnail || product?.images![0].url} alt={product?.name!} className={styles.image} />
          <div className={styles.cardContent}>
            <h3 className={styles.productName}>{product?.name}</h3>
            <p className={styles.productDescription}>{product?.description}</p>
            <p className={styles.productPrice}>{product?.price} {product?.currency}</p>
          </div>
        </div>
  );
};

export default ProductCardListV7;
