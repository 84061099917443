import React, { useEffect } from "react";
import styles from './RecentOrder.module.scss';
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../Store/store";
import { OrderType } from "../../../types/Data/OrderType";
import { fetchOrders } from "../../DashboardState/orders/ordersSlice";
import LoadingIndicatorV1 from "../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1";
import ErrorBannerV1 from "../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1";
import { useNavigate } from "react-router-dom";

interface RecentOrderProps {
    title?: string;
}

const RecentOrder: React.FC<RecentOrderProps> = ({ title = "Recent Order" }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const orders = useSelector((state: RootState) => state.dashboard.orders.orders) as OrderType[];
    const loading = useSelector((state: RootState) => state.dashboard.orders.loading) as boolean;
    const error = useSelector((state: RootState) => state.dashboard.orders.error);

    useEffect(() => {
        if (orders.length === 0) {
            dispatch(fetchOrders({ limit: 10, page: 1 }));
        }
    }, [dispatch, orders.length]);

    const handleClick = (orderId: string) => {
        navigate(`/dashboard/orders/${orderId}`)
    };

    return (
        <div className={styles.recentActivity}>
            <h3>{title}</h3>
            {loading && <LoadingIndicatorV1 />}
            {error && <ErrorBannerV1 message={error} />}
            <ul className={styles.list}>
                {orders.slice(0, 4).map((order) => (
                    <li className={styles.item} key={order._id} onClick={()=>handleClick(order._id)}>
                        {`OrderId: ${order._id} - Total: ${order.orderSummary.totalAmount}$ - Status: ${order.status}`}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RecentOrder;
