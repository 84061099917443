import React from 'react';
import styles from './ProductListV1.module.scss';
import { ProductListComponentProps } from '../ProductListComponentProps';
import exampleProducts from '../../../Dashboard/DashboardState/products/dataProducts';
import { ProductCardListVersions, ProductCardListMapping } from '../../ProductCardList/ProductCardListComponentProps';

const ProductListV1: React.FC<ProductListComponentProps> = ({ products = exampleProducts, cardVersion=ProductCardListVersions.V1, onProductClick = (id: string) => null }) => {
  const CardComponent = ProductCardListMapping[cardVersion];
  return (
    <div className={styles.gridContainer}>
      {products.map((product) => (
        <CardComponent product={product} onProductClick={onProductClick}/>
      ))}
    </div>
  );
};

export default ProductListV1;
