import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Store/store";
import styles from './EcommerceFAQ.module.scss';
import { BaseElementType } from "../../types/Data/BaseElementType";
import renderPageElement from "../renderPageElement";


const EcommerceFAQ: React.FC = () => {
    const faqPageElements = useSelector<RootState>((state) => state.ecommerce.ui.elements) as BaseElementType<any>[];

    return (
     
            <div className={styles.FAQPageContainer}>
                {faqPageElements.map((element, index) => {
                    const Component = renderPageElement(element);
                    return (
                        <div className={styles.animatedSection} key={index}>
                            <Component />
                        </div>
                    );
                })}
            </div>
    );
};

export default EcommerceFAQ;
