import React, { useEffect, useState } from 'react';
import styles from '../../UIElementsMainSettings/UiElementEditor.module.scss';
import { IoRemove } from 'react-icons/io5';

import { BaseElementType, ComponentType } from '../../../types/Data/BaseElementType';
import ButtonV1 from '../../../components/Button/ButtonV1/ButtonV1';
import UIElementsMainSettings from '../../UIElementsMainSettings/UIElementsMainSettings';
import ButtonDelete from '../../../components/Button/ButtonDelete/ButtonDelete';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../Store/store';
import { fetchCreateElement, fetchUpdateElement } from '../../../Dashboard/DashboardState/element/elementSlice';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import { CustomerNavigationComponentMapping, CustomerNavigationComponentProps, CustomerNavigationComponentVersions } from '../CustomerNavigationComponentProps';

interface ContactFormEditorProps {
  element?: BaseElementType<CustomerNavigationComponentProps>;
}

const CustomerNavigationComponentEditor: React.FC<ContactFormEditorProps> = ({
  element,
}) => {
  
  const defaultElement: BaseElementType<CustomerNavigationComponentProps> = {
    type: ComponentType.CustomerNavigation,
    version: 'v1',
    page: 'customerProfil',
    props: undefined,
    position: undefined,
    isVisible: true,
  };

  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state:RootState)=>state.dashboard.elements.loading);
  const error = useSelector((state:RootState)=>state.dashboard.elements.error);

  const [currentElement, setCurrentElement] = useState<BaseElementType<CustomerNavigationComponentProps>>(element ? element : defaultElement);

  useEffect(() => {
    if (element) {
      setCurrentElement(element)
    } else {
      setCurrentElement(defaultElement)
    }
  }, [element])

  const handleElementUpdate = (key:string, value: any, ) => {
      setCurrentElement((prevElement) => ({
        ...prevElement,
        [key]: value,
      }));
  };

  const handlePropsChange = (key:string, value:any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      props: {
        ...prevElement.props,
        [key]: value,
      }
    }));
  }


  const handleElementVisibilityUpdate = () => {
    setCurrentElement(prev => ({ ...prev, isVisible: !prev.isVisible }));
  };

  const handleSaveElement = () => {
    if (currentElement?._id) {
      dispatch(fetchUpdateElement({id:currentElement._id, element:currentElement}))
    } else {
      dispatch(fetchCreateElement(currentElement))
    }
  };

  const SelectedComponent = CustomerNavigationComponentMapping[currentElement.version as CustomerNavigationComponentVersions] || CustomerNavigationComponentMapping[CustomerNavigationComponentVersions.V1];
  
  const elementsVersion = Object.keys(CustomerNavigationComponentVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: CustomerNavigationComponentVersions[key as keyof typeof CustomerNavigationComponentVersions],
  }));


  return (
    <div className={styles.container}>
      { error && <ErrorBannerV1 message={error}/>}
      { loading && <LoadingIndicatorV1/>}
      <div className={styles.componentPreview}>
        <SelectedComponent banner={currentElement?.props} />
      </div>

      <div className={styles.componentEditor}>
        <UIElementsMainSettings
            element={currentElement}
            elementVersions={elementsVersion}
            onElementUpdate={handleElementUpdate}
            onVisibilitytUpdate={handleElementVisibilityUpdate}
        />
      </div>
      <div className={styles.buttonContainer}>
        <ButtonV1 label="Save Element" onClick={handleSaveElement} />
        <ButtonDelete/>
      </div>
    </div>
  );
};

export default CustomerNavigationComponentEditor;
