import React from 'react';
import styles from './TrustSignalsV3.module.scss';
import { FaLock, FaThumbsUp, FaRegSmile } from 'react-icons/fa';
import { TrustSignalsComponentProps } from '../TrustSignalsComponentProps';
import { trustSignalsDefaultProps } from '../trustSignalsDefaultProps';


const TrustSignalsV3: React.FC<TrustSignalsComponentProps> = ({
  secureCheckoutText = trustSignalsDefaultProps.secureCheckoutText,
  qualityGuaranteeText = trustSignalsDefaultProps.qualityGuaranteeText,
  customerSupportText = trustSignalsDefaultProps.customerSupportText,
}) => {
  return (
    <div className={styles.trustSignals}>
      <div className={styles.trustItem}>
        <FaLock className={styles.icon} />
        <p>{secureCheckoutText}</p>
      </div>
      <div className={styles.trustItem}>
        <FaThumbsUp className={styles.icon} />
        <p>{qualityGuaranteeText}</p>
      </div>
      <div className={styles.trustItem}>
        <FaRegSmile className={styles.icon} />
        <p>{customerSupportText}</p>
      </div>
    </div>
  );
};

export default TrustSignalsV3;
