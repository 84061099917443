import React, { useEffect, useState } from 'react';
import { FeaturedProductsComponentProps, FeaturedProductsMapping, FeaturedProductsVersions } from '../FeaturedProductsComponentProps';
import ProductPickerV1 from '../../../components/ProductPicker/ProductPickerV1/ProductPickerV1';
import styles from '../../UIElementsMainSettings/UiElementEditor.module.scss';
import InputV4 from '../../../components/Input/InputText/InputV4/InputV4';
import { BaseElementType, ComponentType } from '../../../types/Data/BaseElementType';
import defaultFeatureProducts from '../defaultFeatureProducts';
import UIElementsMainSettings from '../../UIElementsMainSettings/UIElementsMainSettings';
import ButtonV1 from '../../../components/Button/ButtonV1/ButtonV1';
import ButtonDelete from '../../../components/Button/ButtonDelete/ButtonDelete';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../Store/store';
import { fetchCreateElement, fetchUpdateElement } from '../../../Dashboard/DashboardState/element/elementSlice';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';

interface FeaturedProductsEditorProps {
  element?: BaseElementType<FeaturedProductsComponentProps>
}

const FeaturedProductsEditor: React.FC<FeaturedProductsEditorProps> = ({element }) => {

  const defaultElement: BaseElementType<FeaturedProductsComponentProps> = {
    type: ComponentType.FeaturedProducts,
    version: 'v1',
    page: 'home',
    props: defaultFeatureProducts,
    position: undefined,
    isVisible: true,
  };

  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state:RootState)=>state.dashboard.elements.loading);
  const error = useSelector((state:RootState)=>state.dashboard.elements.error);

  const [currentElement, setCurrentElement] = useState(element ? element : defaultElement);

  useEffect(() => {
    if (element) {
      setCurrentElement(element)
    } else {
      setCurrentElement(defaultElement)
    }
  }, [element])

  const handleElementUpdate = (key:string, value: any, ) => {
      setCurrentElement((prevElement) => ({
        ...prevElement,
        [key]: value,
      }));
  };

  const handleElementVisibilityUpdate = () => {
    setCurrentElement(prev => ({ ...prev, isVisible: !prev.isVisible }));
  };

  const handleSelectProduct = (productId: string, id?: string) => {
      if (currentElement.props?.productsId?.includes(productId)) {
        setCurrentElement(prev => ({
          ...prev,
          data: { products_id: prev!.props?.productsId!.filter(el => el !== productId) }
        }));
      } else {
        setCurrentElement(prev => ({
          ...prev,
          data: { products_id: [...prev!.props?.productsId!, productId] }
        }));
      }
  }
  
  const handlePropsChange = (key: string, value: any) => {
    setCurrentElement((prevElement) => ({
        ...prevElement,
        props: {
          ...prevElement.props,
          [key]: value
        },
    }));
  }


  const handleSaveElement = () => {
    if (currentElement?._id) {
      dispatch(fetchUpdateElement({id:currentElement._id, element:currentElement}))
    } else {
      dispatch(fetchCreateElement(currentElement))
    }
  };

  const SelectedComponent = FeaturedProductsMapping[currentElement.version as FeaturedProductsVersions] || FeaturedProductsMapping[FeaturedProductsVersions.V1];
  
  const elementsVersion = Object.keys(FeaturedProductsVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: FeaturedProductsVersions[key as keyof typeof FeaturedProductsVersions],
  }));

  return (
    <div className={styles.container}>
      { error && <ErrorBannerV1 message={error}/>}
      { loading && <LoadingIndicatorV1/>}

      <div className={styles.componentPreview}>
        <SelectedComponent {...currentElement.props}/>
      </div>
      
      <div className={styles.componentEditor}>
        <UIElementsMainSettings
            element={currentElement}
            elementVersions={elementsVersion}
            onElementUpdate={handleElementUpdate}
            onVisibilitytUpdate={handleElementVisibilityUpdate}
        />
        <div className={styles.componentProps}>
          <InputV4
                title='Title'
                label='Title'
                value={currentElement.props?.title}
                onChange={(e) => handlePropsChange('title', e.target.value) }
            />
          <ProductPickerV1
            selectedProductsId={currentElement.props?.productsId}
            onSelectProduct={(productId:string)=>handleSelectProduct(productId)}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <ButtonV1 label="Save Element" onClick={handleSaveElement}/>
        <ButtonDelete/>
      </div>
    </div>
  );
};

export default FeaturedProductsEditor;






