import React, { useState } from 'react';
import styles from './VariantSelectorV1.module.scss';
import { VariantSelectorComponentProps } from '../VariantSelectorComponentProps';
import defaultVariantSelectorProps from '../variantSelectorDefaultProps';
import { ColorSelectorMapping, ColorSelectorVersions } from '../../ColorSelector/ColorSelectorComponentProps';
import { SizeSelectorMapping, SizeSelectorVersions } from '../../SizeSelector/SizeSelectorComponentProps';

const VariantSelectorV1: React.FC<VariantSelectorComponentProps> = ({ 
    variants = defaultVariantSelectorProps.variants, 
    onOptionSelect = defaultVariantSelectorProps.onOptionSelect,
    colorSelectorVersion = defaultVariantSelectorProps.colorSelectorVersion,
    sizeSelectorVersion = defaultVariantSelectorProps.sizeSelectorVersion,
}) => {
    const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: string }>({});

    const handleOptionClick = (variantName: string, option: string) => {
        setSelectedOptions(prev => ({ ...prev, [variantName]: option }));
        onOptionSelect && onOptionSelect(variantName, option);
    };

    const ColorSelector = ColorSelectorMapping[colorSelectorVersion as ColorSelectorVersions];
    const SizeSelector = SizeSelectorMapping[sizeSelectorVersion as SizeSelectorVersions];

    return (
        <div className={styles.variantSelector}>
            {(variants ?? []).map(variant => { 
                /*
                if (/^colors?$/i.test(variant.name)) {
                    return (
                      <ColorSelector
                        
                        onSelect={(value:string) => handleOptionClick('color', value)}
                        selectedOptions={selectedOptions['color']}
                    />
                    )
                }
                */
                if (/^sizes?$/i.test(variant.name)) {
                    return (
                      <SizeSelector
                        onSelect={(value:string) => handleOptionClick('size', value)}
                        selectedOptions={selectedOptions['size']}
                    />
                    )
                }
                return (
                    <div key={variant.name} className={styles.variant}>
                        <h3 className={styles.variantName}>{variant.name}</h3>
                        <div className={styles.options}>
                            {variant.options.map(option => (
                                <button
                                    key={option}
                                    className={`${styles.option} ${selectedOptions[variant.name] === option ? styles.selected : ''}`}
                                    onClick={() => handleOptionClick(variant.name, option)}
                                >
                                    {option}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            )}
        </div>
    );
};

export default VariantSelectorV1;
