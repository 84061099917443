import React, { useState } from 'react';
import styles from './QAListV2.module.scss';
import { QAListComponentProps } from '../QAListComponentProps';
import { defaultQAListProps } from '../defaultQAListProps';

const QAListV2: React.FC<QAListComponentProps> = ({ items = defaultQAListProps.items }) => {
  const [expanded, setExpanded] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <div className={styles.qaList}>
      {items!.map((item, index) => (
        <div key={index} className={`${styles.qaItem} ${expanded === index ? styles.expanded : ''}`}>
          <div className={styles.cardHeader} onClick={() => handleToggle(index)}>
            {item.question}
          </div>
          <div className={styles.cardBody}>
            <p>{item.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default QAListV2;
