import { SupportTicketStatus, SupportTicketType , SupportTicketCategory} from "../../../types/Data/SupportTicketType";

const exempleDataTickets: SupportTicketType[] = [
    {
        _id: "1",
        subject: "Issue with order #12345",
        description: "I have a problem with my order.",
        status: SupportTicketStatus.Pending,
        email: "johndoe@example.com",
        category: SupportTicketCategory.OrderIssue, // Use enum value
        orderId: "12345",
        
    },
    {
        _id: "2",
        subject: "Unable to log in",
        description: "I forgot my password and can't log in.",
        status: SupportTicketStatus.Pending,
        email: "janedoe@example.com",
        category: SupportTicketCategory.AccountIssue, // Updated to match enum
        orderId: undefined,
        
    },
    {
        _id: "3",
        subject: "Product arrived damaged",
        description: "The product I received is damaged.",
        status: SupportTicketStatus.InProgress,
        email: "marksmith@example.com",
        category: SupportTicketCategory.ProductInquiry, // Use enum value
        orderId: "54321",
       
    },
    {
        _id: "4",
        subject: "Refund request for order #67890",
        description: "I would like to request a refund for my order.",
        status: SupportTicketStatus.Pending,
        email: "lucy@example.com",
        category: SupportTicketCategory.RefundRequest, // Use enum value
        orderId: "67890",
        
    },
    {
        _id: "5",
        subject: "Question about product warranty",
        description: "How long is the warranty period for this product?",
        status: SupportTicketStatus.Resolved,
        email: "emily@example.com",
        category: SupportTicketCategory.ProductInquiry, // Use enum value
        orderId: undefined,
       
    }
];

export { exempleDataTickets };
