import React, { useState, useEffect } from 'react';
import styles from './ProductSelection.module.scss';
import { ProductType, VariantCombinationType } from '../../../../types/Data/ProductType';
import InputV4 from '../../../../components/Input/InputText/InputV4/InputV4';
import { getAllProducts } from '../../../../api/product/productApi';
import { handleApiError } from '../../../../api/apiError';
import LoadingIndicatorV1 from '../../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Store/store';
import { OrderItemType } from '../../../../types/Data/OrderType';

interface ProductSelectionProps {
    onAddToOrder: (product: OrderItemType, variant?: VariantCombinationType) => void;
};

const ProductSelection: React.FC<ProductSelectionProps> = ({ onAddToOrder }) => {
    const defaultProducts = useSelector((state:RootState)=>state.dashboard.products.products) as ProductType[];
    const [products, setProducts] = useState<ProductType[]>(defaultProducts);
    
    const [filteredProducts, setFilteredProducts] = useState<ProductType[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<{ message: string, status?: number } | null>(null);
    const [searchTerm, setSearchTerm] = useState('');


    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await getAllProducts({ search: searchTerm });
                if (response.status === 200) {
                    setProducts(response.data.data.products as ProductType[]);
                    setFilteredProducts(response.data.data.products as ProductType[]);
                } else {
                    throw new Error('No products found');
                }
            } catch (err: any) {
                const handledError = handleApiError(err);
                setError({ message: handledError.message, status: handledError?.status });
            } finally {
                setLoading(false);
            }
        };
        
        fetchProducts();
    }, [searchTerm]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const filtered = products.filter(product => 
            product.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
            product.variants?.some(variant => variant.name.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        setFilteredProducts(filtered);
    }, [searchTerm, products]);

    return (
        <div className={styles.productSelection}>
            <h4>Select Products to Add to Order</h4>
            <InputV4
                label="Search Products"
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search by product name or variant..."
                style={{marginBottom:20}}
            />
            {loading && <LoadingIndicatorV1 />}
            {error && <ErrorBannerV1 message={error.message} />}
            <ul className={styles.productList}>
                {filteredProducts.map(product => (
                    <li key={product._id} className={styles.productItem}>
                        <div className={styles.productInfo}>
                            <span className={styles.productName}>{product.name}</span>
                            <button 
                                className={styles.addButton} 
                                onClick={() => onAddToOrder({productId:product._id!, name:product.name, quantity:1, unitPrice: product.price, totalPrice:product.price, imageUrl:product.thumbnail, variant: 'main', discount:0})}
                            >
                                Add to Order
                            </button>
                        </div>
                        {product.variantComination && product.variantComination.map((variant, index) => (
                            <div key={index} className={styles.variantItem}>
                                <span className={styles.variantName}>
                                    {`${product.name} ${Object.values(variant.options).join(' ')}`}
                                </span>
                                <button 
                                    className={styles.addButton} 
                                    onClick={() => onAddToOrder({productId:product._id!, name:product.name, quantity:1, unitPrice: variant.price || product.price, totalPrice: variant.price || product.price, imageUrl: variant?.images ? variant?.images[0].url : product.thumbnail, variant: Object.values(variant.options).join('-') , discount:0, })}
                                >
                                    Add to Order
                                </button>
                            </div>
                        ))}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ProductSelection;
