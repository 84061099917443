import React from 'react'
import styles from '../Component.module.scss';
import IncentiveV1 from '../../UIElements/Incentive/IncentiveV1/IncentiveV1';
import IncentiveV2 from '../../UIElements/Incentive/IncentiveV2/IncentiveV2';
import IncentiveV3 from '../../UIElements/Incentive/IncentiveV3/IncentiveV3';
import IncentiveV4 from '../../UIElements/Incentive/IncentiveV4/IncentiveV4';
import IncentiveV5 from '../../UIElements/Incentive/IncentiveV5/IncentiveV5';
import IncentiveV6 from '../../UIElements/Incentive/IncentiveV6/IncentiveV6';
import { defaultIncentiveProps } from '../../UIElements/Incentive/IncentiveDefaultProps';
import InputV1 from '../../components/Input/InputText/InputV1/InputV1';
import InputV2 from '../../components/Input/InputText/InputV2/InputV2';
import InputV3 from '../../components/Input/InputText/InputV3/InputV3';
import InputV4 from '../../components/Input/InputText/InputV4/InputV4';
import InputPriceRangeV1 from '../../components/Input/InputPriceRange/InputPriceRangeV1/InputPriceRangeV1';
import InputPriceV1 from '../../components/Input/InputPrice/InputPriceV1/InputPriceV1';
import InputDurationV1 from '../../components/Input/InputDuration/InputDurationV1/InputDurationV1';
import InputTagsV1 from '../../components/Input/InputTags/InputTagsV1/InputTagsV1';
import InputCalendarV1 from '../../components/Input/InputCalendar/InputCalendarV1/InputCalendarV1';

const InputComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <InputV1/>
        </div>
        <div className={styles.element}>
            <InputV2 />
        </div>
        <div className={styles.element}>
            <InputV3/>
        </div>
        <div className={styles.element}>
            <InputV4/>
        </div>
        <div className={styles.element}>
            <InputPriceRangeV1/>
        </div>
        <div className={styles.element}>
            <InputPriceV1/>
        </div>
        <div className={styles.element}>
            <InputDurationV1/>
        </div>
        <div className={styles.element}>
            <InputTagsV1/>
        </div>
        <div className={styles.element}>
            <InputCalendarV1/>
        </div>
        
    </div>
  )
}

export default InputComponentPage