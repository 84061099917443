import { IncentiveComponentProps } from "./IncentiveComponentProps";

const defaultIncentiveProps: IncentiveComponentProps = {
    title: 'Why Shop With Us?',
    description: 'We offer the best services and benefits to ensure your shopping experience is top-notch.',
    items: [
        {
            icon: 'FaShippingFast',
            title: 'Free Shipping',
            description: 'Enjoy free shipping on all orders over $50.'
        },
        {
            icon: 'FaCreditCard',
            title: 'Secure Payment',
            description: 'Your payment information is processed securely.'
        },
        {
            icon: 'IoCheckmarkCircleOutline',
            title: 'Easy Returns',
            description: 'Hassle-free returns within 30 days of purchase.'
        },
        {
            icon: 'IoSunnyOutline',
            title: 'Best Quality',
            description: 'We ensure the best quality in all our products.'
        }
    ]
};

export {
    defaultIncentiveProps
};
