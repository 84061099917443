import React from 'react';
import styles from './PaginationV2.module.scss';
import { PaginationComponentProps } from '../PaginationComponentProps';

const PaginationV2: React.FC<PaginationComponentProps> = ({ currentPage = 1, totalCount = 50, pageSize = 5, onPageChange = () => null }) => {
  
  const totalPages = Math.floor(totalCount / pageSize) + 1;

  const getPageNumbers = (): number[] => {
    const pageNumbers = [];
    const totalPagesToShow = 5; // Change this value to adjust the number of pages displayed
    let startPage = Math.max(1, currentPage - Math.floor(totalPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + totalPagesToShow - 1);

    if (endPage - startPage + 1 < totalPagesToShow) {
      startPage = Math.max(1, endPage - totalPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (startPage > 1) {
      pageNumbers.unshift(-1); // Adding a marker for the dots
    }
    if (endPage < totalPages) {
      pageNumbers.push(-1); // Adding a marker for the dots
    }

    return pageNumbers;
  };

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <div className={styles.pagination}>
      <button className={styles.button} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
        Previous
      </button>
      {getPageNumbers().map((pageNumber, index) => (
        <React.Fragment key={index}>
          {pageNumber === -1 ? (
            <span>...</span>
          ) : (
            <button
              className={`${styles.button} ${pageNumber === currentPage ? styles.active : ''}`}
              onClick={() => handlePageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          )}
        </React.Fragment>
      ))}
      <button className={styles.button} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
        Next
      </button>
    </div>
  );
};

export default PaginationV2;