import { AxiosResponse } from 'axios';
import { RefundProcessType } from '../../types/Data/RefundProcessType';
import { ApiResponse } from '../apiType';
import api from '../config';

interface RefundProcessQueryParams {
    search?: string;
    status?: string;
    page?: number;
    limit?: number;
    sortBy?: string;
    sortOrder?: 'asc' | 'desc';
}

// Create a New Refund Process
export const createRefundProcess = async (data: Partial<RefundProcessType>): Promise<AxiosResponse<ApiResponse<RefundProcessType>>> => {
    try {
        const response: AxiosResponse<ApiResponse<RefundProcessType>> = await api.post('/refund-processes', data);
        return response;
    } catch (error: any) {
        console.error('Error creating refund process:', error);
        throw new Error(error.response?.data?.message || 'Failed to create refund process');
    }
};

// Get a Refund Process by ID
export const getRefundProcessById = async (id: string): Promise<AxiosResponse<ApiResponse<RefundProcessType>>> => {
    try {
        const response: AxiosResponse<ApiResponse<RefundProcessType>> = await api.get(`/refund-processes/${id}`);
        return response;
    } catch (error: any) {
        console.error(`Error fetching refund process with ID ${id}:`, error);
        throw new Error(error.response?.data?.message || 'Failed to fetch refund process');
    }
};

// Get All Refund Processes with Filters, Pagination, and Sorting
export const getAllRefundProcesses = async (params: RefundProcessQueryParams): Promise<AxiosResponse<ApiResponse<{ refundProcesses: RefundProcessType[]; total: number; page: number; pages: number }>>> => {
    try {
        const response: AxiosResponse<ApiResponse<{ refundProcesses: RefundProcessType[]; total: number; page: number; pages: number }>> = await api.get('/refund-processes', { params });
        return response;
    } catch (error: any) {
        console.error('Error fetching refund processes:', error);
        throw new Error(error.response?.data?.message || 'Failed to fetch refund processes');
    }
};

// Update a Refund Process by ID
export const updateRefundProcessById = async (id: string, data: Partial<RefundProcessType>): Promise<AxiosResponse<ApiResponse<RefundProcessType>>> => {
    try {
        const response: AxiosResponse<ApiResponse<RefundProcessType>> = await api.put(`/refund-processes/${id}`, data);
        return response;
    } catch (error: any) {
        console.error(`Error updating refund process with ID ${id}:`, error);
        throw new Error(error.response?.data?.message || 'Failed to update refund process');
    }
};

// Delete a Refund Process by ID
export const deleteRefundProcessById = async (id: string): Promise<AxiosResponse<ApiResponse<null>>> => {
    try {
        const response: AxiosResponse<ApiResponse<null>> = await api.delete(`/refund-processes/${id}`);
        return response;
    } catch (error: any) {
        console.error(`Error deleting refund process with ID ${id}:`, error);
        throw new Error(error.response?.data?.message || 'Failed to delete refund process');
    }
};

// Update the Status of a Refund Process
export const updateRefundProcessStatus = async (id: string, status: string): Promise<AxiosResponse<ApiResponse<RefundProcessType>>> => {
    try {
        const response: AxiosResponse<ApiResponse<RefundProcessType>> = await api.patch(`/refund-processes/${id}/status`, { status });
        return response;
    } catch (error: any) {
        console.error(`Error updating refund process status for ID ${id}:`, error);
        throw new Error(error.response?.data?.message || 'Failed to update refund process status');
    }
};

// Patch Refund Process
export const patchRefundProcess = async (refundProcessId: string, updateData: Partial<RefundProcessType>): Promise<AxiosResponse<ApiResponse<RefundProcessType>>> => {
    try {
        const response: AxiosResponse<ApiResponse<RefundProcessType>> = await api.patch(`/refund-process/${refundProcessId}`, updateData);
        return response;
    } catch (error: any) {
        console.error(`Error patching refund process with ID ${refundProcessId}:`, error);
        throw new Error(error.response?.data?.message || 'Failed to patch refund process');
    }
};

// Process Refund Transaction
export const processRefund = async (refundId: string): Promise<AxiosResponse<ApiResponse<null>>> => {
    try {
        const response: AxiosResponse<ApiResponse<null>> = await api.post(`/refund-transaction/${refundId}`);
        return response;
    } catch (error: any) {
        console.error(`Error processing refund transaction for ID ${refundId}:`, error);
        throw new Error(error.response?.data?.message || 'Failed to process refund transaction');
    }
};
