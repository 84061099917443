import React, { useState } from 'react';
import styles from './InputPriceV1.module.scss';
import { FaDollarSign, FaEuroSign } from 'react-icons/fa';
import { InputPriceComponentProps } from '../../InputComponentProps';

const InputPriceV1: React.FC<InputPriceComponentProps> = ({ label, value, currency, placeholder, onChange }) => {
  const [curr, setCurr] = useState<'EUR' | 'USD'>(currency ? currency : 'EUR')
  
  const currencies = [ { label:'EUR', icon: <FaEuroSign/> }, { label: 'USD', icon: <FaDollarSign/>}]
  
  const currentCurrency = currencies.filter(cur => cur.label === curr)[0]
  
  return (
    <div className={styles.inputPrice}>
      <label>{label}</label>
      <div className={styles.input_wrapper}>
        <div className={styles.icon_container}>
            {currentCurrency.icon}  
        </div>
        <input
          type="text"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
        <select
          value={curr}
          onChange={(e) => setCurr(e.target.value as 'EUR' | 'USD')}
        >
          {currencies.map((currency) => (
            <option key={currency.label} value={currency.label}>
              {currency.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default InputPriceV1;