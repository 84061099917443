import { ColorSelectorVersions } from "../ColorSelector/ColorSelectorComponentProps";
import { SizeSelectorVersions } from "../SizeSelector/SizeSelectorComponentProps";
import { VariantSelectorComponentProps } from "./VariantSelectorComponentProps";

const defaultVariantSelectorProps: VariantSelectorComponentProps = {
    variants: [ { name: "Material", options: ["Cotton", "Polyester", "Silk"] }, { name:"Sizes", options: ['Small', 'Medium', 'Large', 'X-Large'] }, { name:"Color", options: ['red', 'blue', 'black', 'green']} ],
    onOptionSelect: (variantName, selectedOption) => null,
    colorSelectorVersion: ColorSelectorVersions.V1,
    sizeSelectorVersion: SizeSelectorVersions.V1,
};

export default defaultVariantSelectorProps
