import React from 'react';
import styles from './PaymentMethodSelectorV1.module.scss';
import { PaymentMethodSelectordProps } from '../PaymentMethodSelectorComponentProps';

const PaymentMethodSelectorV1: React.FC<PaymentMethodSelectordProps> = ({
    selectedMethod = '',
    methods = ['Credit Card', 'PayPal', 'Bank Transfer'],
    onMethodChange = () => {},
    onSubmit = () => {}
}) => {
  return (
    <div className={styles.paymentMethod}>
      <h2>Select Payment Method</h2>
      <form onSubmit={onSubmit}>
        {methods.map((method) => (
          <label key={method} className={styles.methodLabel}>
            <input
              type="radio"
              name="paymentMethod"
              value={method}
              checked={selectedMethod === method}
              onChange={() => onMethodChange(method)}
            />
            {method}
          </label>
        ))}
        <button type="submit">Proceed to Payment</button>
      </form>
    </div>
  );
};

export default PaymentMethodSelectorV1;
