import React, { useState } from "react";
import Input4 from "../../../../components/Input/InputText/InputV4/InputV4";
import InputCalendar from "../../../../components/Input/InputCalendar/InputCalendarV1/InputCalendarV1";
import styles from './GeneralPromotionInfoForm.module.scss';
import Switch1 from "../../../../components/Switch/SwitchV1/SwitchV1";
import { PromotionGenre, PromotionType } from "../../../../types/Data/PromotionType";
import Select4 from "../../../../components/Select/Select4/Select4";

interface GeneralPromotionInfoFormProps {
    promotion?: Partial<PromotionType>;
    onPromotionInfoChange: (key:string, value:any) => void;
    onPromotionTypeChange: (value: PromotionGenre) => void;
};

const GeneralPromotionInfoForm: React.FC<GeneralPromotionInfoFormProps> = ({
    promotion,
    onPromotionInfoChange,
    onPromotionTypeChange,
}) => {

    const promotionTypeOptions = Object.values(PromotionGenre).map((value) => ({label: value, value }));

    return (
            <section className={styles.section}>
                <h2>General Info</h2>
                    <Input4
                        label="Name"
                        type="text"
                        name="name"
                        placeholder="Promotion name"
                        value={promotion?.name}
                        onChange={(value)=>onPromotionInfoChange('name', value)}
                        required
                        wrapperStyle={{marginTop:20}}
                    />
                    <Input4
                        label="Description"
                        type="text"
                        name="description"
                        placeholder="Promotion description"
                        value={promotion?.description}
                        onChange={(value)=>onPromotionInfoChange('description', value)}
                        required
                        wrapperStyle={{marginTop:20}}
                    />
                    <Select4
                        options={promotionTypeOptions}
                        value={promotion?.promotionType}
                        onChange={(value)=>onPromotionTypeChange(value)}
                        wrapperStyle={{marginTop:20}}
                    />
                    <div className={styles.row_calendar}>
                        <InputCalendar
                            label="Start Date"
                            placeholder="Start date"
                            name="startDate"
                            onDateChange={(date) => onPromotionInfoChange('startDate', date)}
                        />
                        <InputCalendar
                            label="End Date"
                            placeholder="End date"
                            name="endDate"
                            onDateChange={(date) => onPromotionInfoChange('endDate', date)}
                        />
                    </div>
                        <Switch1
                            wrapperStyle={{marginTop:20}}
                            label="Is Active"
                            isChecked={promotion?.isActive || false}
                            onToggle={() => onPromotionInfoChange('isActive', !promotion?.isActive || false )}
                        />
                        <Switch1
                            wrapperStyle={{marginTop:20}}
                            label="Is cummalable"
                            isChecked={promotion?.isCumulative || false}
                            onToggle={() => onPromotionInfoChange('isCumulative', !promotion?.isCumulative || false )}
                        />

               

                </section>
    
    );
};

export default GeneralPromotionInfoForm;
