import React from 'react';
import styles from './IncentiveV3.module.scss';
import { IncentiveComponentProps } from '../IncentiveComponentProps';
import { getIconComponent } from '../IncentiveEditor/util';

const IncentiveV3: React.FC<IncentiveComponentProps> = ({ title = '', description = '', items=[]}) => {
  return (
    <div className={styles.incentiveContainer}>
      {title  && <h2>{title}</h2>}
      {description && <p>{description}</p>}
      <div className={styles.itemsContainer}>
        {items.map((item, index) => (
          <div key={index} className={styles.item}>
            <div className={styles.icon}>{item?.icon ? getIconComponent(item?.icon) : null}
            </div>
            <div className={styles.textContent}>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IncentiveV3;
