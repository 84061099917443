import { AxiosResponse } from "axios";
import api from "../config";
import { ApiResponse } from "../apiType";
import { ReviewType } from "../../types/Data/ReviewType";

interface GetReviewParams {
    search?: string;
    rating?: string;
    status?: string;
    flagged?: boolean;
    sortBy?: string;
    order?: 'asc' | 'desc';
    page?: number;
    limit?: number;
};

// Get paginated and filtered reviews
export const getAllReviews = async ( params: GetReviewParams ) => {
    const response: AxiosResponse<ApiResponse<{ reviews: Partial<ReviewType>[]; total: number; page: number; pages: number }>> = await api.get('/reviews/all', { params });
    return response.data;
};

// Get reviews for a product
export const getReviewsByProduct = async (productId: string) => {
    const response = await api.get(`/reviews/product/${productId}`);
    return response.data; 
};

// Get reviews by a user
export const getReviewsByUser = async (userId: string) => {
    const response = await api.get(`/reviews/user/${userId}`);
    return response.data;
};

// Get a single review by ID
export const getReviewById = async (reviewId: string) => {
    const response = await api.get(`/reviews/${reviewId}`);
    return response.data;
};

// Create a new review (Authenticated)
export const createReview = async (reviewData: any) => {
    const response = await api.post('/reviews', reviewData);
    return response.data;
};

// Delete a review (Authenticated)
export const deleteReview = async (reviewId: string) => {
    const response = await api.delete(`/reviews/${reviewId}`);
    return response.data;
};

// Update a review (Admin)
export const updateReview = async (reviewId: string, reviewData: any) => {
    const response = await api.put(`/reviews/${reviewId}`, reviewData);
    return response.data;
};

// Approve a review (Admin)
export const approveReview = async (reviewId: string) => {
    const response = await api.put(`/reviews/${reviewId}/approve`);
    return response.data;
};

// Reject a review (Admin)
export const rejectReview = async (reviewId: string) => {
    const response = await api.put(`/reviews/${reviewId}/reject`);
    return response.data;
};

// Flag a review (Authenticated)
export const flagReview = async (reviewId: string) => {
    const response = await api.put(`/reviews/${reviewId}/flag`);
    return response.data;
};

// Unflag a review (Admin)
export const unflagReview = async (reviewId: string) => {
    const response = await api.put(`/reviews/${reviewId}/unflag`);
    return response.data;
};

// Get all flagged reviews (Admin)
export const getFlaggedReviews = async () => {
    const response = await api.get('/reviews/flagged');
    return response.data;
};


