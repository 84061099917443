import React, { useEffect, useState } from "react";
import styles from './FaqForm.module.scss';
import InputV4 from "../../../../components/Input/InputText/InputV4/InputV4";
import TextAreaV1 from "../../../../components/TextArea/TextAreaV1/TextAreaV1";
import { FaqType } from "../../../../types/Data/FaqType";

interface FaqFormProps {
  faq?: FaqType | null;
  onSave: (faq: Partial<FaqType> ) => void;
  onCancel: () => void;
}

const FaqForm: React.FC<FaqFormProps> = ({ faq = null, onSave, onCancel }) => {
  const [faqToUpdate, setFaqToUpdate] = useState<Partial<FaqType> >(faq ? faq : { question: '', answer: ''} )

  useEffect(() => {
    if (faq) {
      setFaqToUpdate(faq)
    }
  }, [faq]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(faqToUpdate);
  };

  return (
    <form className={styles.faqForm} onSubmit={handleSubmit}>
        <InputV4
          label="Question"
          type="text" 
          value={faqToUpdate.question} 
          onChange={e => setFaqToUpdate((prev) => ({...prev, question:e.target.value}))}
          required 
        />
      <TextAreaV1
        label="Answer"
          value={faqToUpdate.answer} 
          onChange={e => setFaqToUpdate((prev) => ({...prev, answer:e.target.value}))}
          required 
        />
      <div className={styles.actions}>
        <button className={styles.submitButton} type="submit">Save FAQ</button>
        <button className={styles.cancelButton} type="button" onClick={onCancel}>Cancel</button>
      </div>
    </form>
  );
};

export default FaqForm;
