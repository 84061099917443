enum SupportTicketStatus {
    Pending = "Pending",
    Resolved = "Resolved",
    InProgress = "In Progress",
    Closed = "Closed" // Added an option to close the ticket
}

enum TicketPriority {
    Low = "Low",
    Medium = "Medium",
    High = "High",
    Urgent = "Urgent"
}

enum SupportTicketCategory {
    OrderIssue = 'Order Issue',
    ProductInquiry = 'Product Inquiry',
    ShippingIssue = 'Shipping Issue',
    PaymentIssue = 'Payment Issue',
    RefundRequest = 'Refund Request',
    AccountIssue = 'Account Issue',
    TechnicalSupport = 'Technical Support',
    Other = 'Other'
}

interface Attachment {
    filename: string;
    url: string;
    uploadedAt: string;
}

interface SupportTicketType {
    _id?: string;
    subject: string;
    description: string;
    status: SupportTicketStatus;
    email: string;
    priority?: TicketPriority; // Added priority field
    createdAt?: string; // Added creation date
    updatedAt?: string; // Added last updated date
    category?: SupportTicketCategory;
    orderId?: string;
    chatRoomId?: string;
    attachments?: Attachment[]; // Added attachments field
    assignedTo?: string; // Added assignedTo field for support agents
    tags?: string[]; // Optional tags for better categorization
    customer?: string; // Reference to the customer who created the ticket
    isRead?: boolean
    notes?: string;
}

export {
    SupportTicketStatus,
    SupportTicketCategory,
    TicketPriority
}

export type {
    SupportTicketType,
    Attachment
}
