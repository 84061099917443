import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './PageList.module.scss'
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';

const pages = [
  { id: 'home', name: 'Home Page' },
  { id: 'shop', name: 'Shop Page' },
  { id: 'product', name: 'Product Page' },
  { id: 'cart', name: 'Cart Page' },
  { id: 'billingShipping', name: 'Billing Shipping'},
  { id: 'payment', name:'Payment'},
  { id: 'orderReview', name:'Order Review'},
  { id: 'orderConfirmation', name: 'Order Confirmation'},
  { id: 'orderError', name: 'OrderError'},
  { id: 'aboutUs', name: 'About Us Page' },
  { id: 'contactUs', name: 'Contact Us Page' },
  { id: 'faq', name: 'FAQ Page' },
  { id: 'privatePolicy', name: 'Privacy Policy Page' },
  { id: 'termAndCondition', name: 'Terms and Conditions Page' },
  { id: 'customerProfil', name: 'Customer Profil' },
  { id: 'customerOrderHistory', name: 'Customer Order History' },
  { id: 'customerWishList', name: 'Customer Wish List' },
  { id: 'customerOrderDetail', name: 'Customer Order Detail' },
  { id: 'customerReturnRequest', name: 'Customer Return Request' },
  { id: 'customerMessageHistory', name: 'Customer Message History' },
  { id: 'customerChatRoom', name: 'Customer Chat Room' },
  { id: 'tabNavigation', name: 'Tab Navigation' },
  { id: 'footer', name: 'Footer' },

];

const PageList: React.FC = () => {
  const navigate = useNavigate();

  const handlePageClick = (pageId: string) => {
    navigate(`/dashboard/page-edition/${pageId}`);
  };

  return (
    <div className={styles.pageList}>
      <DashboardHeader title="Page Content Management"/>
      <ul>
        {pages.map(page => (
          <li key={page.id} onClick={() => handlePageClick(page.id)}>
            {page.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PageList;
