import React from 'react';
import styles from './EmailTemplateEditor.module.scss';
import { EmailTemplateType, TemplateNames, EmailTemplatePlaceholder, EmailTemplateDataType, emailSkeletonMapping } from '../../../../types/Data/EmailTemplateType';
import Select4 from '../../../../components/Select/Select4/Select4';
import InputV4 from '../../../../components/Input/InputText/InputV4/InputV4';
import TextAreaV1 from '../../../../components/TextArea/TextAreaV1/TextAreaV1';
import ImportantLinksEditor from './ImportantLinksEditor/ImportantLinksEditor';
import TemplatePlaceholdersEditor from './TemplatePlaceHolderEditor/TemplatePlaceHolderEditor';
import ProductPickerV2 from '../../../../components/ProductPicker/ProductPickerV2/ProductPickerV2';

interface EmailTemplateEditorProps {
  template: EmailTemplateType;
  onTemplateChange: (template: EmailTemplateType) => void;
}

const EmailTemplateEditor: React.FC<EmailTemplateEditorProps> = ({ template, onTemplateChange }) => {
    
    const handleTemplateChange = (templateName: string) => {
    onTemplateChange({ ...template, template: templateName as TemplateNames });
  };

  const handleDataChange = (key: keyof EmailTemplateDataType, value: any) => {
    onTemplateChange({ ...template, data: { ...template.data, [key]: value } });
  };

  const handlePlaceholdersChange = (updatedPlaceholders: EmailTemplatePlaceholder[]) => {
    onTemplateChange({ ...template, placeholders: updatedPlaceholders });
  };

  const handleLinksChange = (updatedLinks: { label: string; url: string }[]) => {
    onTemplateChange({ ...template, data: { ...template.data, importantLinks: updatedLinks } });
  };

  const handleProductsIdChange = (productId: string) => {
    const currentProductIds = template.data?.productIds || [];  // Default to an empty array if undefined

    const newProductIds = currentProductIds.includes(productId)
        ? currentProductIds.filter((id) => id !== productId)  // Remove the productId if it's already included
        : [...currentProductIds, productId];  // Add the productId if it's not included

    onTemplateChange({
        ...template,
        data: { 
            ...template.data, 
            productIds: newProductIds 
        }
    });
}

  const EmailTemplatePreview = emailSkeletonMapping[template.template];

  return (
    <div className={styles.emailTemplateEditor}>
      <div className={styles.editorSidebar}>
        <Select4
          label="Template Name"
          options={Object.values(TemplateNames).map(name => ({ label: name, value: name }))}
          value={template.template}
          onChange={handleTemplateChange}
        />

        <InputV4
          label="Title"
          type="text"
          value={template?.data?.title || ''}
          onChange={e => handleDataChange('title', e.target.value)}
          wrapperStyle={{ marginTop: 20 }}
        />

        <TextAreaV1
          label="Body"
          value={template?.data?.body || ''}
          onChange={e => handleDataChange('body', e.target.value)}
          wrapperStyle={{ marginTop: 20 }}
        />

        <ImportantLinksEditor links={template?.data?.importantLinks || []} onLinksChange={handleLinksChange} />

        <TemplatePlaceholdersEditor
          initialPlaceholders={template.placeholders || []}
          onPlaceholdersChange={handlePlaceholdersChange}
        />

         <ProductPickerV2 onSelectProduct={handleProductsIdChange} selectedProductIds={template.data?.productIds || []} />
      </div>

      <div className={styles.previewSection}>
        <h3 className={styles.previewTitle}>Template Preview</h3>
        <div className={styles.previewContent}>
          <EmailTemplatePreview {...template} />
        </div>
      </div>
    </div>
  );
};

export default EmailTemplateEditor;
