import React, { ReactNode } from 'react';
import styles from './ReturnRequestStatus.module.scss';
import { ReturnStatus } from '../../../../types/Data/ReturnRequestType';
import Select4 from '../../../../components/Select/Select4/Select4';

interface ReturnRequestStatusProps {
    returnRequestStatus: ReturnStatus;
    onStatusChange: (status: ReturnStatus) => void;
    children: ReactNode;
}

const ReturnRequestStatus: React.FC<ReturnRequestStatusProps> = ({ returnRequestStatus, onStatusChange, children }) => {
    const handleStatusChange = (status: string) => {
        if (onStatusChange) {
            onStatusChange(status as ReturnStatus);
        }
    };

    // Use Object.values to get enum values correctly
    const statusOptions = Object.values(ReturnStatus).map((value) => ({ label: value, value }));


    return (
        <div className={styles.returnRequestUpdate}>
            <h2>Return Request Status</h2>
            <Select4
                options={statusOptions}
                onChange={(value) => handleStatusChange(value as ReturnStatus)}
                value={returnRequestStatus || ReturnStatus.Pending}
            />
            {children && children}
        </div>
    );
};

export default ReturnRequestStatus;
