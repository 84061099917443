import React from 'react';
import styles from './HeroBannerV4.module.scss';
import defaultHeroBanner from '../defaultHeroBanner';
import { BannerSlideType } from '../../../types/Data/BannerSlideType';
import { HeroBannerComponentProps } from '../HeroBannerComponentProps';

const HeroBannerV4: React.FC<HeroBannerComponentProps> = ({ slides = defaultHeroBanner.slides }) => {
  return (
    <div className={styles.splitScreenBanner}>
      {slides!.map((slide: BannerSlideType, index: number) => (
        <div key={index} className={styles.slide}>
          <div className={styles.imageContainer}>
            { slide.imageUrl && <img src={slide.imageUrl} alt={slide.title} className={styles.image} /> }
            { slide.videoUrl && <video className={styles.image} autoPlay loop muted>
                  <source src={slide.videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video> 
            }
          </div>
          <div className={styles.textContainer}>
            <div className={styles.content}>
              <h2>{slide.title}</h2>
              <p>{slide.text}</p>
              {slide.buttonText && slide.buttonUrl && (
                <a href={slide.buttonUrl} className={styles.button}>
                  {slide.buttonText}
                </a>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HeroBannerV4;
