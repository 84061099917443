import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import exampleCustomers from './exampleCustomers';
import { CustomerType } from '../../../types/Data/CustomerType';
import { createCustomer, deleteCustomer, getAllCustomers, getCustomerById, updateCustomer } from '../../../api/customer/customerApi';

interface CustomersState {
    customers: CustomerType[];
    loading: boolean;
    error: string | null;
}

const initialState: CustomersState = {
    customers: [...exampleCustomers],
    loading:false,
    error:null, 
};

// Thunk to fetch all customers
export const fetchAllCustomers = createAsyncThunk(
    'customers/fetchAll',
    async (params: any, { rejectWithValue }) => {
        try {
            const data = await getAllCustomers(params);
            return data.data.customers;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to load customers');
        }
    }
);

// Thunk to fetch a customer by ID
export const fetchCustomerById = createAsyncThunk(
    'customers/fetchById',
    async (customerId: string, { rejectWithValue }) => {
        try {
            const data = await getCustomerById(customerId);
            return data.data as CustomerType;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to load customer');
        }
    }
);

// Thunk to create a new customer
export const createNewCustomer = createAsyncThunk(
    'customers/create',
    async (customerData: Partial<CustomerType>, { rejectWithValue }) => {
        try {
            const data = await createCustomer(customerData);
            return data.data as CustomerType;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to create customer');
        }
    }
);

// Thunk to update a customer
export const updateExistingCustomer = createAsyncThunk(
    'customers/update',
    async ({ customerId, customerData }: { customerId: string, customerData: Partial<CustomerType> }, { rejectWithValue }) => {
        try {
            const data = await updateCustomer(customerId, customerData);
            return data.data as CustomerType;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to update customer');
        }
    }
);

// Thunk to delete a customer
export const deleteExistingCustomer = createAsyncThunk(
    'customers/delete',
    async (customerId: string, { rejectWithValue }) => {
        try {
            await deleteCustomer(customerId);
            return customerId;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to delete customer');
        }
    }
);

const customersSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        dispatchAddCustomer(state, action: PayloadAction<CustomerType>) {
            state.customers.push(action.payload);
        },
        dispatchUpdateCustomer(state, action: PayloadAction<CustomerType>) {
            const index = state.customers.findIndex(customer => customer._id === action.payload._id);
            if (index !== -1) {
                state.customers[index] = action.payload;
            }
        },
        dispatchDeleteCustomer(state, action: PayloadAction<string>) {
            state.customers = state.customers.filter(customer => customer._id !== action.payload);
        },
        dispatchLoadCustomers(state, action: PayloadAction<CustomerType[]>) {
            state.customers = action.payload;
        }
    },
    extraReducers: (builder) => {
        // Fetch all customers
        builder.addCase(fetchAllCustomers.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchAllCustomers.fulfilled, (state, action: PayloadAction<CustomerType[]>) => {
            state.customers = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchAllCustomers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });

        // Fetch customer by ID
        builder.addCase(fetchCustomerById.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchCustomerById.fulfilled, (state, action: PayloadAction<CustomerType>) => {
            const index = state.customers.findIndex(customer => customer._id === action.payload._id);
            if (index !== -1) {
                state.customers[index] = action.payload;
            } else {
                state.customers.push(action.payload);
            }
            state.loading = false;
        });
        builder.addCase(fetchCustomerById.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });

        // Create new customer
        builder.addCase(createNewCustomer.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(createNewCustomer.fulfilled, (state, action: PayloadAction<CustomerType>) => {
            state.customers.push(action.payload);
            state.loading = false;
        });
        builder.addCase(createNewCustomer.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });

        // Update existing customer
        builder.addCase(updateExistingCustomer.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateExistingCustomer.fulfilled, (state, action: PayloadAction<CustomerType>) => {
            const index = state.customers.findIndex(customer => customer._id === action.payload._id);
            if (index !== -1) {
                state.customers[index] = action.payload;
            }
            state.loading = false;
        });
        builder.addCase(updateExistingCustomer.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });

        // Delete customer
        builder.addCase(deleteExistingCustomer.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteExistingCustomer.fulfilled, (state, action: PayloadAction<string>) => {
            state.customers = state.customers.filter(customer => customer._id !== action.payload);
            state.loading = false;
        });
        builder.addCase(deleteExistingCustomer.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
    }
});

export const { dispatchAddCustomer, dispatchUpdateCustomer, dispatchDeleteCustomer, dispatchLoadCustomers } = customersSlice.actions;
export default customersSlice.reducer;
