import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import exampledataSocialMediaLinks from "./exampleDataSocialMedia";
import { SocialMediaLinkType, SocialMediaSettingsType } from "../../../types/Data/SocialMediaSettingsType";
import { getAllSocialMediaLinks } from "../../../api/socialMedia/socialMediaApi";

// Async Thunks
export const fetchSocialMediaLinks = createAsyncThunk(
  'socialMedia/fetchSocialMediaLinks',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllSocialMediaLinks();
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

interface SocialMediaState {
  socialMediaLinks: SocialMediaLinkType[],
  loading: boolean,
  error: null | string,
}

const initialState: SocialMediaState = {
  socialMediaLinks: [...exampledataSocialMediaLinks],
  loading: false,
  error: null,
};

const socialMediaSlice = createSlice({
  name: 'socialMedia',
  initialState,
  reducers: {
    dispatchLoadSocialMediaLinks(state, action: PayloadAction<SocialMediaLinkType[]>) {
        state.socialMediaLinks = action.payload
    },
    dispatchAddSocialMediaLink(state, action: PayloadAction<SocialMediaLinkType>) {
      state.socialMediaLinks.push(action.payload);
    },
    dispatchUpdateSocialMediaLink(state, action: PayloadAction<SocialMediaLinkType>) {
      const index = state.socialMediaLinks.findIndex(link => link.platform === action.payload.platform);
      if (index !== -1) {
        state.socialMediaLinks[index] = action.payload;
      }
    },
    dispatchDeleteSocialMediaLink(state, action: PayloadAction<string>) {
      state.socialMediaLinks = state.socialMediaLinks.filter(link => link.platform !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSocialMediaLinks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSocialMediaLinks.fulfilled, (state, action: PayloadAction<SocialMediaLinkType[]>) => {
        state.loading = false;
        state.socialMediaLinks = action.payload;
      })
      .addCase(fetchSocialMediaLinks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
  }
});

export const { dispatchAddSocialMediaLink, dispatchUpdateSocialMediaLink, dispatchDeleteSocialMediaLink, dispatchLoadSocialMediaLinks } = socialMediaSlice.actions;

export default socialMediaSlice.reducer;
