import React, { useState, useEffect } from 'react';
import styles from './TabV1.module.scss';
import { TabComponentProps } from '../TabComponentProps';

const TabV1: React.FC<TabComponentProps> = ({ 
  tabs = ["Tab 1", "Tab 2"], 
  wrapperStyle = undefined, 
  activeTab = "Tab 1", 
  onTabChange 
}) => {
  const [currentTab, setCurrentTab] = useState(activeTab);

  useEffect(() => {
    setCurrentTab(activeTab);
  }, [activeTab]);

  const handleTabClick = (tab: string) => {
    setCurrentTab(tab);
    if (onTabChange) {
      onTabChange(tab);
    }
  };

  return (
    <div className={styles.tabContainer} style={wrapperStyle}>
      <div className={styles.tabList}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`${styles.tab} ${tab === currentTab ? styles.active : ''}`}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </div>
        ))}
      </div>
      <div className={styles.tabContent}>
        {/* Content for the active tab */}
        {currentTab === "Tab 1" && <div>Content for Tab 1</div>}
        {currentTab === "Tab 2" && <div>Content for Tab 2</div>}
        {/* Add more conditional content rendering as needed */}
      </div>
    </div>
  );
};

export default TabV1;
