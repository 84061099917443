import React, { useState } from 'react';
import styles from './FormRegistration.module.scss';
import Input1 from '../../Input/InputText/InputV1/InputV1';
import Button1 from '../../Button/ButtonV1/ButtonV1';
import GoogleAuth from '../../Button/ButtonGoogleAuth/GoogleAuth';
import { useNavigate } from 'react-router-dom';

interface RegistrationFormProps {
  onSubmit?: (username: string, password: string) => void;
}

const FormRegistration: React.FC<RegistrationFormProps> = ({ onSubmit = () => null }) => {
  
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (username === '' || password === '' || confirmPassword === '') {
      setError('Please fill in all fields');
    } else if (password !== confirmPassword) {
      setError('Passwords do not match');
    } else {
      setError('');
      onSubmit(username, password);
    }
  };

  const handleNavigate = () => {
    navigate('/ecommerce/login')
  }

  return (
    <div className={styles.registrationForm}>
      <form onSubmit={handleSubmit} className={styles.formContent}>
        <h2>Create an Account</h2>
        <p>
          Already a member? <button type="button" className={styles.loginButton} onClick={handleNavigate}>Log in</button>
        </p>
        {error && <p className={styles.error}>{error}</p>}
        <div className={styles.inputGroup}>
          <Input1 name="first_name" label="First Name" type="text" />
          <Input1 name="last_name" label="Last Name" type="text" marginTop={20} />
          <Input1 name="email" label="Email" type="email" marginTop={20} />
          <Input1 name="password" label="Password" type="password" marginTop={20} />
          <Input1 name="confirm_password" label="Confirm Password" type="password" marginTop={20} />
        </div>
        <div className={styles.buttonContainer}>
          <Button1 type="submit" title="Register" onClick={() => null} />
        </div>
      </form>
      <div className={styles.separatorRow}>
        <div className={styles.separatorLine} />
        <p>Or continue with</p>
        <div className={styles.separatorLine} />
      </div>
      <GoogleAuth />
    </div>
  );
};

export default FormRegistration;
