import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { createOrder } from '../../../api/order/orderApi';
import { OrderStatus, OrderSummary, OrderType, Payment, PaymentStatus, Shipping } from '../../../types/Data/OrderType';
import { AddressType } from '../../../types/Data/AdressType';
import { CheckoutStep } from '../../../types/Data/CheckoutStep';


interface CheckoutState {
  orderSummary: OrderSummary;
  payment: Payment;
  shipping: Shipping;
  shippingAddress: AddressType;
  billingAddress: AddressType;
  step: CheckoutStep;
  isOrderPlaced: boolean;
  loading: boolean;
  error: string | null;
}

// Initial state for the checkout slice
const initialState: CheckoutState = {
  orderSummary: {
    subtotal: 0,
    shippingCost: 0,
    discounts: 0,
    tax: 0,
    totalAmount: 0,
  },
  payment: {
    paymentMethod: '',
    paymentStatus: PaymentStatus.PENDING,
  },
  shippingAddress: {
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  },
  billingAddress: {
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  },
  shipping: {
    shippingMethod: '',
    shippingStatus: OrderStatus.PENDING,
    shippingCarrier: '',
    trackingNumber: '',
    estimatedDeliveryDate: new Date(),
  },
  step: CheckoutStep.Cart, // Step can be 'Cart', 'Shipping', 'Payment', 'Review', 'Confirmation'
  isOrderPlaced: false,
  loading: false,
  error: null,
};

// Async thunk to handle order creation
export const createOrderThunk = createAsyncThunk(
  'checkout/createOrder',
  async (orderData: Partial<OrderType>, { rejectWithValue }) => {
    try {
      const response = await createOrder(orderData);
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to place order');
    }
  }
);

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    // Update shipping details
    updateShipping(state, action: PayloadAction<Shipping>) {
      state.shipping = { ...state.shipping, ...action.payload };
    },
    // Update shipping address
    updateShippingAddress(state, action: PayloadAction<AddressType>) {
      state.shippingAddress = { ...state.shippingAddress, ...action.payload };
    },
    // Update billing address
    updateBillingAddress(state, action: PayloadAction<AddressType>) {
      state.billingAddress = { ...state.billingAddress, ...action.payload };
    },
    // Update payment method
    updatePayment(state, action: PayloadAction<Payment>) {
      state.payment = { ...state.payment, ...action.payload };
    },
    // Update order summary
    updateOrderSummary(state, action: PayloadAction<OrderSummary>) {
      state.orderSummary = { ...state.orderSummary, ...action.payload };
    },
    // Advance to the next step in the checkout process
    nextStep(state) {
      const stepOrder: CheckoutStep[] = [CheckoutStep.Cart, CheckoutStep.Shipping, CheckoutStep.Payment, CheckoutStep.Review, CheckoutStep.Confirmation];
      const currentIndex = stepOrder.indexOf(state.step);
      if (currentIndex < stepOrder.length - 1) {
        state.step = stepOrder[currentIndex + 1];
      }
    },
    // Go back to the previous step in the checkout process
    previousStep(state) {
      const stepOrder: CheckoutStep[] = [CheckoutStep.Cart, CheckoutStep.Shipping, CheckoutStep.Payment, CheckoutStep.Review, CheckoutStep.Confirmation];
      const currentIndex = stepOrder.indexOf(state.step);
      if (currentIndex > 0) {
        state.step = stepOrder[currentIndex - 1];
      }
    },
    // Reset the checkout process
    resetCheckout(state) {
      state.orderSummary = initialState.orderSummary;
      state.payment = initialState.payment;
      state.shipping = initialState.shipping;
      state.shippingAddress = initialState.shippingAddress;
      state.billingAddress = initialState.billingAddress;
      state.step = CheckoutStep.Cart;
      state.isOrderPlaced = false;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createOrderThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createOrderThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.isOrderPlaced = true;
        state.step = CheckoutStep.Confirmation;
      })
      .addCase(createOrderThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const {
  updateShipping,
  updateShippingAddress,
  updateBillingAddress,
  updatePayment,
  updateOrderSummary,
  nextStep,
  previousStep,
  resetCheckout,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
