// RefundProcessInfo.tsx

import React from "react";
import styles from './RefundProcessInfo.module.scss';
import {
  RefundProcessType,
  RefundStatus,
  RefundPaymentMethod,
} from "../../../../types/Data/RefundProcessType";

interface RefundProcessInfoProps {
  refundProcess: RefundProcessType;
}

const RefundProcessInfo: React.FC<RefundProcessInfoProps> = ({ refundProcess }) => {
  // Helper function to format dates
  const formatDate = (date: string | Date) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  // Function to convert enum values to friendly strings
  const getRefundStatusLabel = (status: RefundStatus) => {
    switch (status) {
      case RefundStatus.Pending:
        return 'Pending';
      case RefundStatus.Approved:
        return 'Approved';
      case RefundStatus.Rejected:
        return 'Rejected';
      default:
        return status;
    }
  };

  const getPaymentMethodLabel = (method: RefundPaymentMethod) => {
    switch (method) {
      case RefundPaymentMethod.CreditCard:
        return 'Credit Card';
      case RefundPaymentMethod.PayPal:
        return 'PayPal';
      case RefundPaymentMethod.BankTransfer:
        return 'Bank Transfer';
      case RefundPaymentMethod.Stripe:
        return 'Stripe';
      case RefundPaymentMethod.Other:
            return 'Other';
      default:
        return method;
    }
  };

  return (
    <div className={styles.refundProcesstInfo}>
      <h2>Refund Process</h2>
      <div className={styles.section}>
        <p className={styles.label}><strong>Customer:</strong> {refundProcess.customerName}</p>
      </div>
      <hr className={styles.separator}/>
      <div className={styles.section}>
        <p className={styles.label}><strong>Order ID:</strong> {typeof refundProcess.order === "string" ? refundProcess.order : refundProcess?.order?._id}</p>
      </div>
      <hr className={styles.separator}/>
      <div className={styles.section}>
        <p className={styles.label}><strong>Total Refund Amount:</strong> ${refundProcess?.totalRefundAmount?.toFixed(2)}</p>
      </div>
      <hr className={styles.separator}/>
      <div className={styles.section}>
      <p className={styles.label}><strong>Status:</strong> {getRefundStatusLabel(refundProcess?.refundStatus)}</p>
      </div>
      {refundProcess.refundDate && (
        <>
        <hr className={styles.separator}/>
        <div className={styles.section}>
          <p className={styles.label}><strong>Refund Date:</strong> {formatDate(refundProcess?.refundDate)}</p>
        </div>
        </>
      )}
      {refundProcess.paymentInfo && (
        <>
          <hr className={styles.separator}/>
          <div className={styles.section}>
          <p className={styles.label}><strong>Payment Method:</strong>{' '}</p>
            {getPaymentMethodLabel(refundProcess?.paymentInfo?.paymentMethod as RefundPaymentMethod)}
          </div>
          <div className={styles.section}>
          <p className={styles.label}><strong>Payment Provider:</strong> {refundProcess?.paymentInfo?.paymentProvider}</p>
          </div>
          {refundProcess.paymentInfo?.transactionId && (
            <div className={styles.section}>
              <p className={styles.label}><strong>Transaction ID:</strong> {refundProcess?.paymentInfo?.transactionId}</p>
            </div>
          )}
        </>
      )}
      {refundProcess?.notes && (
        <div className={styles.section}>
          <p className={styles.label}><strong>Notes:</strong> {refundProcess?.notes}</p>
        </div>
      )}
    </div>
  );
};

export default RefundProcessInfo;
