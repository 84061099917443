import OrderErrorV1 from "../../UIElements/OrderError/OrderErrorV1/OrderErrorV1";
import OrderErrorV2 from "../../UIElements/OrderError/OrderErrorV2/OrderErrorV2";
import OrderErrorV3 from "../../UIElements/OrderError/OrderErrorV3/OrderErrorV3";

enum OrderErrorVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const OrderErrorMapping:Record<OrderErrorVersions, React.FC<any>> = {
    [OrderErrorVersions.V1]: OrderErrorV1,
    [OrderErrorVersions.V2]: OrderErrorV2,
    [OrderErrorVersions.V3]: OrderErrorV3,
};

interface OrderErrorComponentProps {
    message?: string;
    onRetry?: () => void;
};

export {
    OrderErrorVersions,
    OrderErrorMapping
};

export type {
    OrderErrorComponentProps
};
  