import React from 'react';
import styles from './CustomerReturnHistoryV1.module.scss';
import { FaTruck, FaCheckCircle, FaTimesCircle, FaBoxOpen } from 'react-icons/fa';
import { RefundEligibility, ReturnAdminApprovalStatus, ReturnRequestType, ReturnStatus } from '../../../types/Data/ReturnRequestType';
import { CustomerReturnHistoryComponentProps } from '../CustomerReturnHistoryComponentProps';
import { customerReturnHistoryDefaultProps } from '../CustomerReturnHistoryDefaultProps';

const CustomerReturnHistoryV1: React.FC<CustomerReturnHistoryComponentProps> = ({ 
  returnRequest = customerReturnHistoryDefaultProps.returnRequest
}) => {

  return (
    <div className={styles.returnCard}>
      <h2 className={styles.header}>Return Request for {returnRequest?.customerName}</h2>
      
      <div className={styles.statusSection}>
        <h3>Status: <span className={`${styles.status} ${styles[returnRequest?.status!.toLowerCase() || ReturnStatus.Pending]}`}>{returnRequest?.status}</span></h3>
        <p>Requested on: {new Date(returnRequest?.createdAt!).toLocaleDateString()}</p>
        {returnRequest?.updatedAt && <p>Last updated on: {new Date(returnRequest?.updatedAt!).toLocaleDateString()}</p>}
      </div>

      {returnRequest?.shipmentInfo && (
        <div className={styles.shipmentInfo}>
          <h3>Shipment Information</h3>
          <p>Carrier: {returnRequest.shipmentInfo.shippingCarrier}</p>
          <p>Tracking Number: {returnRequest?.shipmentInfo.trackingNumber}</p>
          <p>Shipping Date: { returnRequest?.shipmentInfo.shippingDate ? new Date(returnRequest?.shipmentInfo.shippingDate).toLocaleDateString() : 'N/A'}</p>
        </div>
      )}

      <div className={styles.itemsSection}>
        <h3>Items Returned</h3>
        {returnRequest?.items!.map((item, index) => (
          <div key={index} className={styles.itemCard}>
            <h4>{item.productName} - {item.quantity}x</h4>
            <p>Price: ${item.price.toFixed(2)}</p>
            <p>Return Reason: {item.returnReason || 'N/A'}</p>
            <p>Refund Eligibility: <span className={item.refundEligibility === RefundEligibility.Eligible ? styles.eligible : styles.notEligible}>{item.refundEligibility}</span></p>
            <p>Admin Approval: {item.adminApprovalStatus === ReturnAdminApprovalStatus.Approved ? <FaCheckCircle color="green" /> : <FaTimesCircle color="red" />}</p>
            {item.conditionAfterReturn && <p>Item Condition: {item.conditionAfterReturn}</p>}
          </div>
        ))}
      </div>

      {returnRequest?.adminNotes && (
        <div className={styles.adminNotes}>
          <h3>Admin Notes</h3>
          <p>{returnRequest?.adminNotes}</p>
        </div>
      )}

      {returnRequest?.chats && (
        <div className={styles.chats}>
          <h3>Chat History</h3>
          {/* Assuming chats are a list of messages, you can map them here */}
          
        </div>
      )}
    </div>
  );
};

export default CustomerReturnHistoryV1;
