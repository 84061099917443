import { PromotionGenre, PromotionType } from "../../../types/Data/PromotionType";


const examplePromotions: PromotionType[] = [
    {
        _id: '1',
        name: 'Summer Sale',
        description: 'Get 20% off on summer items',
        promotionType: PromotionGenre.PercentageDiscount,
        startDate: '2023-06-01',
        endDate: '2023-06-30',
        isActive: true,
        products: [],
        banner: undefined,
        isCumulative: false,
        priority: 1,
    },
    // Add more promotions here
];

export default examplePromotions;
