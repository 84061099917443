import { ChatRoomType } from "../../types/Data/ChatRoomType";
import { MessageType } from "../../types/Data/MessageType";


// Sample messages for the chat room
const messages: MessageType[] = [
  {
    _id: 'msg1',
    chatRoom: 'chat1',
    sender: 'customer1',
    senderModel: 'customer',
    content: 'Hello, I need assistance with my recent order.',
    createdAt: new Date('2023-10-20T10:00:00Z'),
  },
  {
    _id: 'msg2',
    chatRoom: 'chat1',
    sender: 'admin1',
    senderModel: 'admin',
    content: 'Sure, I can help you. Could you please provide your order ID?',
    createdAt: new Date('2023-10-20T10:02:00Z'),
  },
  {
    _id: 'msg3',
    chatRoom: 'chat1',
    sender: 'customer1',
    senderModel: 'customer',
    content: 'My order ID is 12345. I would like to return the product.',
    createdAt: new Date('2023-10-20T10:05:00Z'),
  },
  {
    _id: 'msg4',
    chatRoom: 'chat1',
    sender: 'admin1',
    senderModel: 'admin',
    content: 'Thank you for the information. I will initiate the return process for you.',
    createdAt: new Date('2023-10-20T10:07:00Z'),
  },
];

// Sample chat room data
const chatRoomMock: ChatRoomType = {
  _id: 'chat1',
  orderId: '12345',
  participants: ['customer1', 'admin1'],
  messages: messages,
  createdAt: new Date('2023-10-20T09:55:00Z'),
  updatedAt: new Date('2023-10-20T10:07:00Z'),
};

export { chatRoomMock };
