import React from 'react'
import styles from '../Component.module.scss';
import BadgeV1 from '../../UIElements/Badge/BadgeV1/BadgeV1';
import BadgeV2 from '../../UIElements/Badge/BadgeV2/BadgeV2';
import BadgeV3 from '../../UIElements/Badge/BadgeV3/BadgeV3';

const BadgeComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <BadgeV1/>
        </div>
        <div className={styles.element}>
            <BadgeV2/>
        </div>
        <div className={styles.element}>
            <BadgeV3/>
        </div>
    
    </div>
  )
}

export default BadgeComponentPage