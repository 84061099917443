import React, { useEffect, useState } from 'react';
import { BsGoogle } from 'react-icons/bs';
import ButtonGoogleAuth from './ButtonGoogleAuth';

interface GoogleAuth2 {
    init: (options: { client_id: string }) => Promise<void>;
    getAuthInstance: () => GoogleAuthInstance;
}
  
interface GoogleAuthInstance {
    signIn: () => Promise<GoogleUser>;
}
  
interface GoogleUser {
    getBasicProfile: () => GoogleUserProfile;
    getAuthResponse: () => { id_token: string };
}
  
interface GoogleUserProfile {
    getId: () => string;
    getName: () => string;
    getImageUrl: () => string;
    getEmail: () => string;
}
  
  // Extend the Window interface to include the Google API
declare global {
    interface Window {
      gapi: {
        load: (library: string, callback: () => void) => void;
        auth2: GoogleAuth2;
      };
    }
}
  

const GoogleAuth: React.FC = () => {
    const [isGapiLoaded, setIsGapiLoaded] = useState<boolean>(false);

    useEffect(() => {
        const loadScript = (src:string, onLoad:()=>void) => {
            const script = document.createElement('script');
            script.src = src;
            script.async = true;
            script.defer = true;
            script.onload = onLoad;
            document.body.appendChild(script);
        };
        
        const initializeGapi = (): Promise<void> => {
            return new Promise((resolve, reject) => {
              window.gapi.load('auth2', () => {
                window.gapi.auth2.init({
                  client_id: 'YOUR_GOOGLE_CLIENT_ID.apps.googleusercontent.com',
                }).then(() => {
                  setIsGapiLoaded(true);
                  resolve();
                }).catch(reject);
              });
            });
        };
      
        if (window.gapi) {
            initializeGapi().catch(error => console.error('Error initializing Google API:', error));
        } else {
            loadScript('https://apis.google.com/js/platform.js', ()=> {
                initializeGapi().catch(error => console.error('Error initializing Google API:', error));
            });
          }
        }, 
    []);

    const handleSignIn = () => {
        if (!isGapiLoaded) {
          console.error('Google API is not loaded yet.');
          return;
        }
    
        const auth2 = window.gapi.auth2.getAuthInstance();
        auth2.signIn().then(googleUser => {
          const profile = googleUser.getBasicProfile();
          console.log('ID: ' + profile.getId());
          console.log('Name: ' + profile.getName());
          console.log('Image URL: ' + profile.getImageUrl());
          console.log('Email: ' + profile.getEmail());
    
          // You can also get the token to authenticate with your backend
          const id_token = googleUser.getAuthResponse().id_token;
          console.log('ID Token: ' + id_token);
        }).catch(error => {
          console.error('Error signing in', error);
        });
      };
    

    const handleSuccess = (token: string) => {
        console.log('Successfully authenticated with Google. Token:', token);
        // Handle successful authentication, e.g., send token to server
      };
    
    const handleFailure = () => {
        console.error('Failed to authenticate with Google.');
        // Handle authentication failure
    };

    return (
        <ButtonGoogleAuth onClick={handleSignIn}/>
    );
};

export default GoogleAuth;