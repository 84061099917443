import { SubscriberType } from "../../../types/Data/SubscriberType";

const exampleSubscribers:SubscriberType[] = [
    { id: '1', name: 'Alice Johnson', email: 'alice.johnson@example.com', dateSubscribed: '2023-01-15' },
    { id: '2', name: 'Bob Smith', email: 'bob.smith@example.com', dateSubscribed: '2023-02-20' },
    { id: '3', name: 'Carol Williams', email: 'carol.williams@example.com', dateSubscribed: '2023-03-10' },
    { id: '4', name: 'David Brown', email: 'david.brown@example.com', dateSubscribed: '2023-04-05' },
    { id: '5', name: 'Eva Green', email: 'eva.green@example.com', dateSubscribed: '2023-05-25' },
];

export default exampleSubscribers