import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import exampleCategories from './exampleCategories';
import { CategoryType } from '../../../types/Data/CategoryType';
import { getAllCategories } from '../../../api/category/categoryApi';

// Define the state interface
interface CategoriesState {
    categories: CategoryType[];
    loading: boolean;
    error: string | null | {};
    page: number;
    totalPages: number;
}

const initialState:CategoriesState = {
    categories: [...exampleCategories],
    loading: false,
    error: null,
    page: 1,
    totalPages: 1,
}

// Create the async thunk for fetching products
export const fetchCategories = createAsyncThunk(
    'categories/fetchCategories',
    async ( _, { rejectWithValue }) => {
        try {
            const response = await getAllCategories();
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch categories');
        }
    }
);

const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        dispatchAddCategory(state, action: PayloadAction<CategoryType>) {
            state.categories.push(action.payload);
        },
        dispatchUpdateCategory(state, action: PayloadAction<CategoryType>) {
            const index = state.categories.findIndex(category => category._id === action.payload._id);
            if (index !== -1) {
                state.categories[index] = action.payload;
            }
        },
        dispatchDeleteCategory(state, action: PayloadAction<string>) {
            state.categories = state.categories.filter(category => category._id !== action.payload);
        },
        dispatchLoadCategories(state, action: PayloadAction<CategoryType[]>) {
            state.categories = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCategories.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCategories.fulfilled, (state, action: PayloadAction<CategoryType[]>) => {
                state.loading = false;
                state.categories = action.payload;
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || action.error.message || 'Unknown error';
            });
    },
})

export const { dispatchAddCategory, dispatchUpdateCategory, dispatchDeleteCategory, dispatchLoadCategories } = categoriesSlice.actions;
export default categoriesSlice.reducer;