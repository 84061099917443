import React, { useState, useEffect } from 'react';
import styles from './TabV3.module.scss';
import { FaCogs, FaHome, FaUser } from 'react-icons/fa';

interface Tab {
    label: string;
    icon: React.ReactNode;
}

interface EnhancedTabsProps {
    tabs: Tab[];
    activeTab?: string;  // optional prop to set the initial active tab
    onTabChange?: (label: string) => void;  // callback function when the active tab changes
}



const TabV3: React.FC<EnhancedTabsProps> = ({ tabs, activeTab = tabs[0].label, onTabChange }) => {
    const [currentTab, setCurrentTab] = useState(activeTab);

    useEffect(() => {
        setCurrentTab(activeTab);
    }, [activeTab]);

    const handleTabClick = (label: string) => {
        setCurrentTab(label);
        if (onTabChange) {
            onTabChange(label);
        }
    };

    return (
        <div className={styles.tabs}>
            <div className={styles.tabList}>
                {tabs.map((tab) => (
                    <div
                        key={tab.label}
                        className={`${styles.tab} ${tab.label === currentTab ? styles.active : ''}`}
                        onClick={() => handleTabClick(tab.label)}
                    >
                        {tab.icon}
                        <span>{tab.label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TabV3;
