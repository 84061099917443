import React, { useState, useEffect, useRef } from 'react';
import { ImageType } from '../../../types/Data/ImageType';
import styles from './ImageSelectionModal.module.scss';

interface ImageSelectionModalProps {
  onClose: () => void;
  onImageSelect: (image: ImageType) => void;
}

const ImageSelectionModal: React.FC<ImageSelectionModalProps> = ({ onClose, onImageSelect }) => {
 
  const modalRef = useRef<HTMLDivElement>(null);  // Reference to the modal container

  const [activeTab, setActiveTab] = useState('products'); // Default tab is "Products"
  const [images, setImages] = useState<ImageType[]>([]); // Array of images from selected tab

  // Function to load images based on the selected tab
  const loadImages = (category: string) => {
    // You can make API calls to load images based on category (products, assets, blogs)
    setActiveTab(category);
    // Example API call
    // fetchImagesByCategory(category).then((response) => setImages(response.data));
  };

  // Function to handle click outside the modal content
  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose(); // Close the modal if clicked outside the modal content
    }
  };

  // Add event listener on mount and clean up on unmount
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.modal}>
      <div ref={modalRef} className={styles.modalContent}>
        <div className={styles.header}>
          <h2>Select an Image</h2>
          <button onClick={onClose}>Close</button>
        </div>

        <div className={styles.tabs}>
          <button className={activeTab === 'products' ? styles.active : ''} onClick={() => loadImages('products')}>Products</button>
          <button className={activeTab === 'assets' ? styles.active : ''} onClick={() => loadImages('assets')}>Assets</button>
          <button className={activeTab === 'blogs' ? styles.active : ''} onClick={() => loadImages('blogs')}>Blogs</button>
        </div>

        <div className={styles.imageGrid}>
          {images.length > 0 ? (
            images.map((image) => (
              <div key={image._id} className={styles.imageItem} onClick={() => onImageSelect(image)}>
                <img src={image.url} alt={image.alt} />
                <span>{image.title}</span>
              </div>
            ))
          ) : (
            <p>No images found in this category.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageSelectionModal;
