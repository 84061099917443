import React, { CSSProperties } from 'react';
import { EmailTemplateType } from '../../../../types/Data/EmailTemplateType';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Store/store';
import { OrderType } from '../../../../types/Data/OrderType';
import { CustomerType } from '../../../../types/Data/CustomerType';

// Inline styles derived from SCSS
const styles:{ [key: string]: CSSProperties } = {
  emailContainer: {
    maxWidth: '100%',
    margin: '20px auto',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    animation: 'fadeIn 1s ease-in-out'
  },
  header: {
    backgroundColor: '#1a1a2e',
    color: '#fff',
    padding: '20px',
    textAlign: 'center',
    borderRadius: '10px 10px 0 0'
  },
  headerH1: {
    margin: 0,
    fontSize: '1.8em'
  },
  body: {
    padding: '20px'
  },
  bodyH2: {
    color: '#4bc0c0'
  },
  bodyP: {
    color: '#333',
    lineHeight: 1.5
  },
  cartItems: {
    listStyle: 'none',
    padding: 0
  },
  cartItem: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f4f4f4',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
  },
  itemImage: {
    width: '50px',
    height: '50px',
    objectFit: 'cover',
    borderRadius: '5px',
    marginRight: '10px'
  },
  itemDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  cartTotal: {
    fontSize: '1.2em',
    margin: '20px 0'
  },
  ctaButton: {
    display: 'inline-block',
    backgroundColor: '#1a1a2e',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '5px',
    textDecoration: 'none',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '20px'
  },
  footer: {
    backgroundColor: '#f4f4f4',
    color: '#333',
    textAlign: 'center',
    padding: '20px',
    borderRadius: '0 0 10px 10px'
  },
  footerP: {
    margin: 0,
    fontSize: '0.9em'
  }
};

const AbandonedCartEmail: React.FC<EmailTemplateType> = ({ data }) => {
    
  const customer = useSelector<RootState>((state)=>state.dashboard.customers.customers.find((customer)=>customer._id === data?.customerId)) as Partial<CustomerType>
  const order = useSelector<RootState>((state)=>state.dashboard.orders.orders.find((order)=>order._id === data?.orderId)) as Partial<OrderType>

  return (
        <div style={styles.emailContainer}>
            <header style={styles.header}>
                <h1 style={styles.headerH1}>Don't Forget Your Cart!</h1>
            </header>
            <section style={styles.body}>
                <h2 style={styles.bodyH2}>Hi {customer?.name},</h2>
                <p style={styles.bodyP}>We noticed you left some items in your cart. Complete your purchase now to make sure you don't miss out!</p>
                <h3>Your Cart</h3>
                <ul style={styles.cartItems}>
                    {order?.items?.map((item, index) => (
                        <li key={index} style={styles.cartItem}>
                            <img src={item.imageUrl} alt={item.name} style={styles.itemImage} />
                            <div style={styles.itemDetails}>
                                <span>{item.quantity}x {item.name}</span>
                                <span>${item.unitPrice.toFixed(2)}</span>
                            </div>
                        </li>
                    ))}
                </ul>
                <p style={styles.cartTotal}><strong>Total:</strong> ${order?.orderSummary?.subtotal?.toFixed(2)}</p>
                {data?.importantLinks && data.importantLinks.map((link, index) => (
                    <a key={index} href={link.url} style={styles.ctaButton}>
                        {link.label}
                    </a>
                ))}
            </section>
            <footer style={styles.footer}>
                <p style={styles.footerP}>Thank you for shopping with us!</p>
            </footer>
        </div>
    );
};

export default AbandonedCartEmail;
