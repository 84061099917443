import React, {  } from 'react'
import styles from './PaginationV3.module.scss';
import { FaEllipsisH } from 'react-icons/fa';
import {  IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { PaginationComponentProps } from '../PaginationComponentProps';


const PaginationV3:React.FC<PaginationComponentProps> = ({currentPage = 1, totalCount = 50, siblingCount = 2, pageSize = 6, onPageChange = () => null}) => {

    const firstElement = currentPage * pageSize - pageSize || 1;

    const numberOfPage = (totalCount / pageSize) - Math.floor((totalCount / pageSize)) > 0 ? Math.floor(totalCount / pageSize) + 1 : (totalCount / pageSize);

    const range = (start:number, end:number) => {
        let length = end - start + 1;
        return Array.from({ length }, (_, idx) => idx + start);
    };

    
    const pagination = () => {
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
        const rightSiblingIndex = Math.min(currentPage + siblingCount, numberOfPage)
        
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < numberOfPage - 2;

        const firstPageIndex = 1;
        const lastPageIndex = numberOfPage;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 1 + siblingCount;
            let leftRange = range(1, leftItemCount);
      
            return [...leftRange, 'DOTS', numberOfPage];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 1 + siblingCount;
            let rightRange = range(
              numberOfPage - rightItemCount + 1,
              numberOfPage
            );
            return [firstPageIndex, 'DOTS', ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, 'DOTS', ...middleRange, 'DOTS', lastPageIndex];
        }
        return [currentPage, 'DOTS', numberOfPage]
    }



    const pages = pagination();



    return (
        <div className={styles.pagination_container}>
            <p className={styles.text}>Showing { firstElement } to { firstElement + pageSize } of { totalCount } results</p>
            <div className={styles.pagination_list}>
                <div className={styles.pagination_item}>
                    <IoChevronBack/>
                </div>
                {pages.map((page, index) => {
                    if (typeof page === 'number') {
                        return (
                            <div className={styles.pagination_item}>
                                {page}
                            </div>
                        )
                    }
                    else return (
                        <div className={styles.pagination_item}>
                            <FaEllipsisH/>
                        </div>
                    )
                })}
                <div className={styles.pagination_item}>
                    <IoChevronForward/>
                </div>
            </div>
        </div>
       
    );
}

export default PaginationV3