// src/components/AllProducts.tsx
import React from 'react';
import styles from './ProductsList.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { ProductType } from '../../../types/Data/ProductType';
import ReviewStars from '../../../UIElements/ProductReview/ReviewStars';
import { dispatchDeleteProduct } from '../../DashboardState/products/productsSlice';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';


interface ProductListProps {
    products?: Partial<ProductType>[];
};

const ProductList: React.FC<ProductListProps> = ({products = []}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleEdit = (productId: string) => {
        navigate(`./edit/?productId=${productId}`)
    };

    const handleDelete = (productId: string) => {
        dispatch(dispatchDeleteProduct(productId))
    };
    return (
        <div className={styles.productTable}>
        <table >
            <thead>
                <tr>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Category</th>
                    <th>Price</th>
                    <th>StockStatus</th>
                    <th>Rating</th>
                    <th>SKU</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {products.map((product) => (
                    <tr key={product?._id!}>
                        <td><img src={product?.images![0].url} alt={product?.name} className={styles.productImage} /></td>
                        <td>{product?.name!}</td>
                        <td>{product?.categories!.toString()}</td>
                        <td>{product?.price!}</td>
                        <td>{product?.stockStatus!}</td>
                        <td><ReviewStars rating={product?.rating!}/></td>
                        <td>{product?.sku!}</td>
                        <td>
                            <button className={styles.edit_btn} onClick={()=>handleEdit(product._id!)}>
                                <FaEdit/>
                            </button>
                            <button className={styles.delete_btn}>
                                <FaTrash/>
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        </div>
    );
};

export default ProductList;
