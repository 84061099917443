import React from "react";
import styles from "./NewsletterSignupV3.module.scss";
import { NewsLetterSignupComponentProps } from "../NewsLetterSignupProps";

const NewsletterSignupV3: React.FC<NewsLetterSignupComponentProps> = () => {
  return (
    <div className={styles.newsletterV3}>
      <div className={styles.newsletterContent}>
        <h2>Get Exclusive Updates!</h2>
        <p>Subscribe to our newsletter and be the first to know about our latest news and offers.</p>
        <input type="email" placeholder="Enter your email" />
        <button>Subscribe</button>
      </div>
    </div>
  );
};

export default NewsletterSignupV3;
