import React from 'react';
import styles from './AvatarV2.module.scss';
import { AvatarComponentProps } from '../AvatarComponentProps';


const AvatarV2: React.FC<AvatarComponentProps> = ({ name, imageUrl, description }) => {
  return (
    <div className={styles.avatar_container}>
      <img src={imageUrl} alt={`${name}'s avatar`} className={styles.avatar_image} />
      <div className={styles.avatar_info}>
        <div className={styles.avatar_name}>{name}</div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};

export default AvatarV2;