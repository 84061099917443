import React from 'react'
import styles from '../Component.module.scss';
import ProductImageGalleryV1 from '../../UIElements/ProductImageGallery/ProductImageGalleryV1/ProductImageGalleryV1';
import ProductImageGalleryV2 from '../../UIElements/ProductImageGallery/ProductImageGalleryV2/ProductImageGalleryV2';
import ProductImageGalleryV3 from '../../UIElements/ProductImageGallery/ProductImageGalleryV3/ProductImageGalleryV3';
import ProductImageGalleryV4 from '../../UIElements/ProductImageGallery/ProductImageGalleryV4/ProductImageGalleryV4';

const ProductImageGalleryComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <ProductImageGalleryV1/>
        </div>
        <div className={styles.element}>
            <ProductImageGalleryV2/>
        </div>
        <div className={styles.element}>
            <ProductImageGalleryV3/>
        </div>
        <div className={styles.element}>
            <ProductImageGalleryV4/>
        </div>
    </div>
  )
}

export default ProductImageGalleryComponentPage