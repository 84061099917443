import React from 'react';
import styles from './ProductInfoCardV1.module.scss';
import { ProductInfoCardComponentProps } from '../ProductInfoCardComponentProps';
import { defaultProductInfoCardProps } from '../defaultProductInfoCardProps';

const ProductInfoCardV1:React.FC<ProductInfoCardComponentProps> = ({ product = defaultProductInfoCardProps }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardInner}>
        <div className={styles.cardFront}>
          <h2>{product.name}</h2>
          <p className={styles.price}>${product.price}</p>
        </div>
        <div className={styles.cardBack}>
          <p>{product.description}</p>
          <ul className={styles.features}>
            {product?.attributes!.map((attribute, index) => (
              <li key={index}>{attribute.name}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProductInfoCardV1;
