import React, { useEffect, useState } from 'react';
import styles from './ReturnHistoryReview.module.scss';
import { ReturnRequestType  } from '../../../../types/Data/ReturnRequestType';
import { getAllReturnRequests } from '../../../../api/returnRequest/returnRequestApi';
import { handleApiError } from '../../../../api/apiError';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Store/store';

interface ReturnHistoryReviewProps {
    customerId: string;
}

const ReturnHistoryReview: React.FC<ReturnHistoryReviewProps> = ({ customerId }) => {
   
    
    const returnRequests = useSelector<RootState>((state)=>state.dashboard.returnRequest.returnRequests) as ReturnRequestType[];

    const [returnRequetFromUser, setReturnRequestFromUser ] = useState(returnRequests.filter((el)=>el.customer === customerId))
    // Filter the return requests for the specific customer

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<any>({})



    const getAllReturnFromCustomer = async(customerId: string) =>{
        setIsLoading(true); // Set loading to true before request
        try {
          let response = await getAllReturnRequests({customer:customerId});
          if (response.status === 200) return response.data.data.returnRequests;
        } catch (error: any) {
          if (error.name === 'AbortError') {
            console.log('Request cancelled');
          } else {
            let handledError = handleApiError(error);
            setError({ message: handledError.message, status: handledError.status });
          }
        } finally {
          setIsLoading(false); // Ensure loading is turned off
        }
    };

    useEffect(() => {  
        if (returnRequests) {
            getAllReturnFromCustomer(customerId)
            .then(data => {
              if (data) {
                setReturnRequestFromUser(data);  // Set the original review
              }
            })
            .catch(error => {
              let handledError = handleApiError(error);
              setError({ message: handledError.message, status: handledError.status });
            });
        }
    }, [customerId]);
    





    return (
        <div className={styles.returnHistory}>
            <h2>Return History</h2>
            {returnRequetFromUser && Array.isArray(returnRequetFromUser) && returnRequetFromUser.length > 0 ? (
                <table className={styles.returnTable}>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Items</th>
                            <th>Reason</th>
                            <th>Condition</th>
                        </tr>
                    </thead>
                    <tbody>
                        {returnRequetFromUser.map((request) => (
                            <tr key={request._id}>
                                <td>{new Date(request.createdAt).toLocaleDateString()}</td>
                                <td>{request.status}</td>
                                <td>
                                    <ul>
                                        {request.items.map(item => (
                                            <li key={item.product as string}>
                                                {item.productName} (x{item.quantity})
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        {request.items.map(item => (
                                            <li key={item.product as string}>
                                                {item.returnReason || "N/A"}
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        {request.items.map(item => (
                                            <li key={item.product as string}>
                                                {item.conditionAfterReturn || "Not Returned"}
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No return history found for this customer.</p>
            )}
        </div>
    );
};

export default ReturnHistoryReview;
