import React from 'react';
import styles from './ShippingInformationFormV3.module.scss';
import { ShippingInformationComponentProps } from '../ShippingInformationFormComponentProps';

const ShippingInformationFormV3: React.FC<ShippingInformationComponentProps> = ({
    fullName = "",
    address = "",
    city = "",
    state = "",
    zipCode = "",
    country = "",
    onInputChange = () => null,
    onFormSubmit = () => null,
}) => {
  return (
    <div className={styles.glassForm}>
      <h2>Shipping Info</h2>
      <form onSubmit={onFormSubmit}>
        <input
          type="text"
          placeholder="Full Name"
          value={fullName}
          onChange={(e) => onInputChange('fullName', e.target.value)}
        />
        <input
          type="text"
          placeholder="Address"
          value={address}
          onChange={(e) => onInputChange('address', e.target.value)}
        />
        <input
          type="text"
          placeholder="City"
          value={city}
          onChange={(e) => onInputChange('city', e.target.value)}
        />
        <input
          type="text"
          placeholder="State"
          value={state}
          onChange={(e) => onInputChange('state', e.target.value)}
        />
        <input
          type="text"
          placeholder="ZIP Code"
          value={zipCode}
          onChange={(e) => onInputChange('zipCode', e.target.value)}
        />
        <input
          type="text"
          placeholder="Country"
          value={country}
          onChange={(e) => onInputChange('country', e.target.value)}
        />
        <button type="submit">Next</button>
      </form>
    </div>
  );
};

export default ShippingInformationFormV3;
