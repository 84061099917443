import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styles from './EditCategoryPage.module.scss';
import TextEditor from '../../../components/RichTextEditor/RichTextEditorV1/RichTextEditorV1';
import TextArea1 from '../../../components/TextArea/TextAreaV1/TextAreaV1';
import InputTags from '../../../components/Input/InputTags/InputTagsV1/InputTagsV1';
import Button1 from '../../../components/Button/ButtonV1/ButtonV1';
import Switch1 from '../../../components/Switch/SwitchV1/SwitchV1';
import { dispatchAddCategory, dispatchUpdateCategory } from '../../DashboardState/categories/categoriesSlice';
import { CategoryType } from '../../../types/Data/CategoryType';
import { RootState } from '../../../Store/store';
import Input4 from '../../../components/Input/InputText/InputV4/InputV4';
import Select4 from '../../../components/Select/Select4/Select4';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';
import { createCategory, updateCategory } from '../../../api/category/categoryApi';
import SelectImageButton from '../../Images/ImageSelector/SelectImageButton';
import ImagesList from '../../Images/ImagesList/ImagesList';
import ImageUploadComponent from '../../Images/ImageUploadComponent/ImageUploadComponent';
import FileList from '../../../components/DragAndDrop/DragAndDropV1/FileList';
import { uploadMultipleImages } from '../../../api/image/imageApi';
import { ImageType } from '../../../types/Data/ImageType';
import { handleApiError } from '../../../api/apiError';
import SaveButton from '../../../components/Button/SaveButton/SaveButton';

interface EditCategoryProps {
  category?: CategoryType | null;
  wrapperStyle?: React.CSSProperties;
  handleEditEnd?: () => void;
}

const EditCategoryPage: React.FC<EditCategoryProps> = ({ category, handleEditEnd = () => null }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedCategoryId = queryParams.get('categoryId');
  const categories = useSelector<RootState, CategoryType[]>((state) => state.dashboard.categories.categories);

  const selectedCategoryFromState = selectedCategoryId
    ? categories.find((cat) => cat._id === selectedCategoryId)
    : undefined;

  const selectedCategory = category || selectedCategoryFromState;

  const [categoryToEdit, setCategoryToEdit] = useState<CategoryType>({
    _id: selectedCategory?._id || undefined,
    name: selectedCategory?.name || '',
    description: selectedCategory?.description || '',
    seoTitle: selectedCategory?.seoTitle || '',
    seoDescription: selectedCategory?.seoDescription || '',
    seoKeywords: selectedCategory?.seoKeywords || [],
    isActive: selectedCategory?.isActive ?? true,
    parentCategoryId: selectedCategory?.parentCategoryId || null,
    image: selectedCategory?.image || undefined,
  });

  const [file, setFile] = useState<File>()
  const isEditing = Boolean(selectedCategory);

  useEffect(() => {
    if (selectedCategory) {
      setCategoryToEdit(selectedCategory);
    }
  }, [selectedCategory]);

  const handleChange = (key: keyof CategoryType, value: any) => {
    setCategoryToEdit((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleUpdate = async () => {
    try {
      const response = await updateCategory(categoryToEdit._id!, categoryToEdit)
      if (response.status === 200) {
        alert('Category updated')
      } else {
        throw new Error('Error while updating caregory')
      }
    } catch (error:any) {
      const handledError = handleApiError(error)
      alert(handledError.message)
    }
  };

  const handleCreate = async () => {
    try {
      const response = await createCategory(categoryToEdit)
      if (response.status === 200) {
        alert('Category updated')
      } else {
        throw new Error('Error while updating caregory')
      }
    } catch (error:any) {
      const handledError = handleApiError(error)
      alert(handledError.message)
    }
  };

  const handleImagesUpload = async (files: File[]) => {
    const formData = new FormData();

    // Append each file individually to the formData
    files.forEach((file, idx) => {
        formData.append('images', file); // 'images' key for each file
    });

    // Add additional metadata (title, alt, category, etc.)
    formData.append('title', '');
    formData.append('alt', '');
    formData.append('category', 'category');
    formData.append('usage', JSON.stringify(['category']));
    formData.append('tags', JSON.stringify(['category']));  // Ensure array fields are stringified

    try {
        const response = await uploadMultipleImages(formData);

        if (response.status === 201 && response.data) {
            let newImage = response.data.data[response.data.data.length - 1]
            setCategoryToEdit((prev) => ({
              ...prev,
              ['image']: newImage as ImageType
            }))
          }
    } catch (error: any) {
        console.log(error);
        alert(`An error occurred while uploading images: ${error.message}`);
    }
};

  const handleSelectImage = (image: ImageType) => {
    setCategoryToEdit((prev) => ({
      ...prev,
      ['image']: image
    })
  )}

  return (
    <div className={styles.categoryForm}>
      { !category && <DashboardHeader title={ categoryToEdit._id ? "Edit Category" : 'Add Category'}/>}
      <div className={styles.content}>
      <Input4
        type="text"
        label="Category Name"
        value={categoryToEdit.name}
        onChange={(e) => handleChange('name', e.target.value)}
        wrapperStyle={{ marginTop: 20, width: '100%' }}
      />
      <TextEditor
        label='Description'
        value={categoryToEdit.description || ''}
        onChange={(content) => handleChange('description', content)}
        wrapperStyle={{ marginTop: 20, width: '100%' }}
      />
      <InputTags
        placeholder="SEO Keywords"
        tags={categoryToEdit.seoKeywords}
        onChange={(tags) => handleChange('seoKeywords', tags)}
      />
      <Select4
        onChange={(id) => handleChange('parentCategoryId', id)}
        value={categoryToEdit.parentCategoryId || ''}
        label="Parent Category"
        options={categories.filter((cat) => cat._id !== categoryToEdit._id).map((cat) => ({
          label: cat.name,
          value: cat._id,
        }))}
        wrapperStyle={{ marginTop: 20 }}
      />
      <Input4
        type="text"
        label="SEO Title"
        value={categoryToEdit.seoTitle}
        onChange={(e) => handleChange('seoTitle', e.target.value)}
        wrapperStyle={{ marginTop: 20, width: '100%' }}
      />


      <FileList files={categoryToEdit?.image ? [categoryToEdit?.image!] : []} setFiles={(files)=>setCategoryToEdit((prev)=>({...prev, image:files[0]}))}/>
      <ImageUploadComponent onUpload={handleImagesUpload}/>
      <SelectImageButton styles={{marginTop:20}} onImageSelect={handleSelectImage}/>

      <TextArea1
        label='Seo Description'
        placeholder="SEO Description"
        value={categoryToEdit.seoDescription}
        onChange={(e) => handleChange('seoDescription', e.target.value)}
        wrapperStyle={{ marginTop: 20 }}
      />
      
      <div className={styles.switchRow}>
        <Switch1
        wrapperStyle={{marginTop: 20}}
          label='Active'
          isChecked={categoryToEdit.isActive}
          onToggle={() => handleChange('isActive', !categoryToEdit.isActive)}
        />
      </div>
      <SaveButton
        style={{marginTop: 20, width: '100%'}}
        onClick={categoryToEdit._id ? handleUpdate : handleCreate}
      />
      </div>
    </div>
  );
};

export default EditCategoryPage;
