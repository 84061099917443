// ErrorBanner.tsx
import React, { useEffect, useState } from 'react';
import styles from './ErrorBannerV1.module.scss';

interface ErrorBannerProps {
    message: string;
    status?: number;
    duration?: number; // Duration in milliseconds
}

const ErrorBannerV1: React.FC<ErrorBannerProps> = ({ message, status, duration = 10000 }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, duration);

        return () => clearTimeout(timer);
    }, [duration]);

    if (!visible) return null;

    return (
        <div className={styles.errorBanner}>
            <div className={styles.message}>
                {status}
                {message}
            </div>
        </div>
    );
};

export default ErrorBannerV1;
