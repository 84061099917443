import { AxiosResponse } from 'axios';
import api from '../config'; // Adjust the path as necessary to your API config
import { PrivatePolicyType } from '../../types/Data/PrivatePolicyType';
import { ApiResponse } from '../apiType';

// Get Private Policy
export const getPrivatePolicy = async (): Promise<AxiosResponse<ApiResponse<PrivatePolicyType>>> => {
    try {
        const response: AxiosResponse<ApiResponse<PrivatePolicyType>> = await api.get('/private-policy');
        return response;
    } catch (error) {
        console.error('Error fetching private policy:', error);
        throw new Error('Failed to fetch private policy');
    }
};

// Update Private Policy
export const savePrivatePolicy = async ( data: Partial<PrivatePolicyType>): Promise<AxiosResponse<ApiResponse<PrivatePolicyType>>> => {
    try {
        const response: AxiosResponse<ApiResponse<PrivatePolicyType>> = await api.post(`/private-policy`, data);
        return response;
    } catch (error) {
        console.error(`Error saving private policy:`, error);
        throw new Error('Failed to save private policy');
    }
};

// Delete Private Policy
export const deletePrivatePolicy = async (id: string): Promise<AxiosResponse<ApiResponse<null>>> => {
    try {
        const response: AxiosResponse<ApiResponse<null>> = await api.delete(`/private-policy`);
        return response;
    } catch (error) {
        console.error(`Error deleting private policy`, error);
        throw new Error('Failed to delete private policy');
    }
};
