// src/components/ProductVariants.tsx
import React, { useState } from 'react';
import styles from './ProductVariantEditorV1.module.scss';
import { ProductVariantEditorComponentProps } from '../ProductVariantEditorComponentProps';
import { IoAdd, IoTrash } from 'react-icons/io5';

const ProductVariantEditorV1: React.FC<ProductVariantEditorComponentProps> = ({ variants = [], setVariants, variantsWrapper }) => {
  const [newVariantName, setNewVariantName] = useState('');
  const [newOption, setNewOption] = useState('');
  const [selectedVariantIndex, setSelectedVariantIndex] = useState<number | null>(null);

  const handleAddVariant = () => {
    if (newVariantName) {
      setVariants && setVariants([...variants, { name: newVariantName, options: [] }]);
      setNewVariantName('');
    }
  };

  const handleDeleteVariant = (index: number) => {
    setVariants && setVariants(variants.filter((_, i) => i !== index));
  };

  const handleAddOption = (variantIndex: number) => {
    if (newOption) {
      const updatedVariants = variants.map((variant, index) =>
        index === variantIndex
          ? { ...variant, options: [...variant.options, newOption] }
          : variant
      );
      setVariants && setVariants(updatedVariants);
      setNewOption('');
    }
  };

  const handleDeleteOption = (variantIndex: number, optionIndex: number) => {
    const updatedVariants = variants.map((variant, index) =>
      index === variantIndex
        ? { ...variant, options: variant.options.filter((_, i) => i !== optionIndex) }
        : variant
    );
    setVariants && setVariants(updatedVariants);
  };

  return (
    <div className={styles.variantsWrapper} style={{...variantsWrapper}}>
      <div className={styles.variantsContainer}>

      <div className={styles.addVariant}>
        <input
          type="text"
          placeholder="Variant Name"
          value={newVariantName}
          onChange={(e) => setNewVariantName(e.target.value)}
        />
        <button onClick={handleAddVariant}>Add Variant</button>
      </div>
      {variants.map((variant, variantIndex) => (
        <div key={variantIndex} className={styles.variant}>
          <div className={styles.variantHeader}>
            <h3>{variant.name}</h3>
            <button onClick={() => handleDeleteVariant(variantIndex)}><IoTrash/></button>
          </div>
          <div className={styles.addOption}>
            <input
              type="text"
              placeholder={`Add option for ${variant.name}`}
              value={selectedVariantIndex === variantIndex ? newOption : ''}
              onChange={(e) => {
                setSelectedVariantIndex(variantIndex);
                setNewOption(e.target.value);
              }}
            />
            <button onClick={() => handleAddOption(variantIndex)}><IoAdd/></button>
          </div>
          <ul>
            {variant.options.map((option, optionIndex) => (
              <li key={optionIndex}>
                {option}
                <button onClick={() => handleDeleteOption(variantIndex, optionIndex)}><IoTrash/></button>
              </li>
            ))}
          </ul>
        </div>
      ))}
      </div>
    </div>
  );
};

export default ProductVariantEditorV1;
