import React from 'react'
import styles from '../Component.module.scss';
import PromotionalBannerV1 from '../../UIElements/PromotionalBanner/PromotionalBannerV1/PromotionalBannerV1';
import PromotionalBannerV2 from '../../UIElements/PromotionalBanner/PromotionalBannerV2/PromotionalBannerV2';
import PromotionalBannerV3 from '../../UIElements/PromotionalBanner/PromotionalBannerV3/PromotionalBannerV3';
import PromotionalBannerV4 from '../../UIElements/PromotionalBanner/PromotionalBannerV4/PromotionalBannerV4';

const PromotionBannerComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <PromotionalBannerV1/>
        </div>
        <div className={styles.element}>
            <PromotionalBannerV2/>
        </div>
        <div className={styles.element}>
            <PromotionalBannerV3/>
        </div>
        <div className={styles.element}>
            <PromotionalBannerV4/>
        </div>
    </div>
  )
}

export default PromotionBannerComponentPage