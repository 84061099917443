import React from 'react';
import styles from './DiscountCodeList.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { DiscountCodeType } from '../../../types/Data/DiscountCodeType';
import { useNavigate } from 'react-router-dom';

interface DiscountCodeListProps {
    onViewDiscountDetail?: (discountId: string) => void;
}

const DiscountCodeList: React.FC<DiscountCodeListProps> = ({ onViewDiscountDetail }) => {
    const navigate = useNavigate();
    const discountCodes = useSelector((state: RootState) => state.dashboard.discountCodes.discountCodes) as DiscountCodeType[];

    const handleDiscountDetailView = (id: string) => {
        navigate(`./edit/?discountCodeId=${id}`)
    };

    return (
        <div className={styles.discountCodeList}>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Type</th>
                        <th>Value</th>
                        <th>Active</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                    </tr>
                </thead>
                <tbody>
                    {discountCodes.map(discount => (
                        <tr key={discount._id} onClick={() => handleDiscountDetailView(discount._id || '')}>
                            <td>{discount.name}</td>
                            <td>{discount.code}</td>
                            <td>{discount.type === 'percentage' ? 'Percentage' : 'Fixed Amount'}</td>
                            <td>{discount.type === 'percentage' ? `${discount.value}%` : `$${discount.value}`}</td>
                            <td className={styles.activeStatus}>
                                {discount.isActive ? (
                                    <FaCheckCircle className={styles.activeIcon} />
                                ) : (
                                    <FaTimesCircle className={styles.inactiveIcon} />
                                )}
                            </td>
                            <td>{new Date(discount.startDate).toLocaleDateString()}</td>
                            <td>{new Date(discount.endDate).toLocaleDateString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DiscountCodeList;
