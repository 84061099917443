import React from 'react';
import FormLogin from '../../components/Form/FormLogin/FormLogin';
import styles from './EcommerceLogin.module.scss';

const EcommerceLogin: React.FC = () => {
  return (
    <div>
    <div className={styles.loginPage}>
      <div className={styles.formContainer}>
        <FormLogin />
      </div>
      <div className={styles.imageContainer}>
        <img src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80" alt="Login Background" />
      </div>
    </div>
    </div>
    
  );
}

export default EcommerceLogin;
