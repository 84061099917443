import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Switch1 from '../../../../components/Switch/SwitchV1/SwitchV1';
import Button1 from '../../../../components/Button/ButtonV1/ButtonV1';
import styles from './TaxRateCard.module.scss';
import { deleteTaxRate, updateTaxRate } from '../../../DashboardState/taxSettings/taxSettingsSlice';
import { TaxRateType } from '../../../../types/Data/TaxSettingsType';

interface TaxRateCardProps {
  rate: TaxRateType;
}

const TaxRateCard: React.FC<TaxRateCardProps> = ({ rate }) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [rateName, setRateName] = useState(rate.name);
  const [rateValue, setRateValue] = useState(rate.rate);

  const handleToggleRate = () => {
    dispatch(updateTaxRate({ ...rate, isEnabled: !rate.isEnabled }));
  };

  const handleDeleteRate = () => {
    dispatch(deleteTaxRate(rate.id));
  };

  const handleSaveChanges = () => {
    dispatch(updateTaxRate({ ...rate, name: rateName, rate: rateValue }));
    setIsEditing(false);
  };

  return (
    <div className={styles.tax_rate_card}>
      {isEditing ? (
        <div className={styles.card_body}>
          <input
            type="text"
            value={rateName}
            onChange={(e) => setRateName(e.target.value)}
          />
          <input
            type="number"
            value={rateValue}
            onChange={(e) => setRateValue(Number(e.target.value))}
          />
          <Button1 type="button" title="Save" onClick={handleSaveChanges} />
        </div>
      ) : (
        <div className={styles.card_body}>
          <span>{rate.name}</span>
          <span>{rate.rate.toFixed(2)}%</span>
          <div className={styles.card_actions}>
            <Switch1 isChecked={rate.isEnabled} onToggle={handleToggleRate} />
            <Button1 type="button" title="Edit" onClick={() => setIsEditing(true)} />
            <Button1 type="button" title="Delete" onClick={handleDeleteRate} />
          </div>
        </div>
      )}
    </div>
  );
};

export default TaxRateCard;
