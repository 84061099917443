import React from 'react'
import styles from '../Component.module.scss';
import TrustBadgeIconsV1 from '../../UIElements/TrustBadgeIcons/TrustBadgeIconsV1/TrustBadgeIconsV1';
import TrustBadgeIconsV2 from '../../UIElements/TrustBadgeIcons/TrustBadgeIconsV2/TrustBadgeIconsV2';
import TrustBadgeIconsV3 from '../../UIElements/TrustBadgeIcons/TrustBadgeIconsV3/TrustBadgeIconsV3';
import { defaultTrustBadgeItems } from '../../UIElements/TrustBadgeIcons/defaultTrustBadgeData';

const TrustBadgeIconComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <TrustBadgeIconsV1 {...defaultTrustBadgeItems}/>
        </div>
        <div className={styles.element}>
            <TrustBadgeIconsV2  {...defaultTrustBadgeItems}/>
        </div>
        <div className={styles.element}>
            <TrustBadgeIconsV3  {...defaultTrustBadgeItems}/>
        </div>
    </div>
  )
}

export default TrustBadgeIconComponentPage