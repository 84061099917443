import React, { useEffect, useState } from 'react';
import styles from '../../UIElementsMainSettings/UiElementEditor.module.scss';

import { BaseElementType, ComponentType } from '../../../types/Data/BaseElementType';
import InputV4 from '../../../components/Input/InputText/InputV4/InputV4';
import ButtonV1 from '../../../components/Button/ButtonV1/ButtonV1';
import TextAreaV1 from '../../../components/TextArea/TextAreaV1/TextAreaV1';
import { HeroSectionComponentProps, HeroSectionMapping, HeroSectionVersions } from '../HeroSectionComponentProps';
import defaultHeroSectionProps from '../heroSectionDefaulftProps';
import UIElementsMainSettings from '../../UIElementsMainSettings/UIElementsMainSettings';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../Store/store';
import { fetchCreateElement, fetchUpdateElement } from '../../../Dashboard/DashboardState/element/elementSlice';
import ButtonDelete from '../../../components/Button/ButtonDelete/ButtonDelete';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';

interface HeroSectionEditorProps {
  element?: BaseElementType<HeroSectionComponentProps>;
}

const HeroSectionComponentEditor: React.FC<HeroSectionEditorProps> = ({
  element,
}) => {
  
  const defaultElement: BaseElementType<HeroSectionComponentProps> = {
    type: ComponentType.HeroSection,
    version: 'v1',
    page: 'home',
    props: defaultHeroSectionProps,
    position: undefined,
    isVisible: true,
  };

  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state:RootState)=>state.dashboard.elements.loading);
  const error = useSelector((state:RootState)=>state.dashboard.elements.error);

  const [currentElement, setCurrentElement] = useState(element ? element : defaultElement);

  useEffect(() => {
    if (element) {
      setCurrentElement(element)
    } else {
      setCurrentElement(defaultElement)
    }
  }, [element])

  const handleElementUpdate = (key:string, value: any, ) => {
      setCurrentElement((prevElement) => ({
        ...prevElement,
        [key]: value,
      }));
  };

  const handlePropsChange = (key:string, value:any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      props: {
        ...prevElement.props,
        [key]: value,
      }
    }));
  }


  const handleElementVisibilityUpdate = () => {
    setCurrentElement(prev => ({ ...prev, isVisible: !prev.isVisible }));
  };

  const handleSaveElement = () => {
    if (currentElement?._id) {
      dispatch(fetchUpdateElement({id:currentElement._id, element:currentElement}))
    } else {
      dispatch(fetchCreateElement(currentElement))
    }
  };

  const SelectedComponent = HeroSectionMapping[currentElement.version as HeroSectionVersions] || HeroSectionMapping[HeroSectionVersions.V1];
  
  const elementsVersion = Object.keys(HeroSectionVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: HeroSectionVersions[key as keyof typeof HeroSectionVersions],
  }));


  return (
    <div className={styles.container}>
      { error && <ErrorBannerV1 message={error}/>}
      { loading && <LoadingIndicatorV1/>}
      <div className={styles.componentPreview}>
        <SelectedComponent banner={currentElement?.props} />
      </div>

      <div className={styles.componentEditor}>
        <UIElementsMainSettings
            element={currentElement}
            elementVersions={elementsVersion}
            onElementUpdate={handleElementUpdate}
            onVisibilitytUpdate={handleElementVisibilityUpdate}
        />
        <div>
          <InputV4
            label=" Title"
            value={currentElement.props?.title || ''}
            onChange={(e) => handlePropsChange('title', e.target.value)}
          />
          <TextAreaV1
            label=" Description"
            value={currentElement.props?.imageUrl || ''}
            onChange={(e) => handlePropsChange('imageUrl', e.target.value)}
          />
          <TextAreaV1
            label=" Mission Statement"
            value={currentElement.props?.text || ''}
            onChange={(e) => handlePropsChange('missionStatement', e.target.value)}
          />
          <InputV4
            label=" Image URL"
            value={currentElement.props?.buttonText || ''}
            onChange={(e) => handlePropsChange('imageUrl', e.target.value)}
          />
          <InputV4
            label=" Image URL"
            value={currentElement.props?.buttonUrl || ''}
            onChange={(e) => handlePropsChange('imageUrl', e.target.value)}
          />
          <InputV4
            label=" Image URL"
            value={currentElement.props?.videoUrl || ''}
            onChange={(e) => handlePropsChange('videoUrl', e.target.value)}
          />
      </div>
      </div>
      <div className={styles.buttonContainer}>
        <ButtonV1 label="Save Element" onClick={handleSaveElement} />
        <ButtonDelete/>
      </div>
    </div>
  );
};

export default HeroSectionComponentEditor;
