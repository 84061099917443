import React, { useState } from 'react';
import styles from './SortV1.module.scss';
import { SortComponentProps } from '../SortComponentProps';
import { defaultSortOptions } from '../sortDefaultProps';

const SortV1: React.FC<SortComponentProps> = ({
  options = defaultSortOptions.options,
  selectedOption = '',
  onOptionSelect = () => null,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option: string) => {
    onOptionSelect(option);
    setIsOpen(false);
  };

  return (
    <div className={styles.dropdownSortContainer}>
      <div
        className={styles.dropdown}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p>{selectedOption || "Select an option"}</p>
        <span className={`${styles.arrow} ${isOpen ? styles.up : styles.down}`}></span>
      </div>
      {isOpen && (
        <div className={styles.optionsList}>
          {options?.map((option, index) => (
            <div
              key={index}
              className={styles.optionItem}
              onClick={() => handleOptionClick(option)}
            >
              <p>{option}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SortV1;
