import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { ReviewType } from '../../../types/Data/ReviewType';
import styles from './ReviewList.module.scss';
import ButtonEdit from '../../../components/Button/ButtonEdit/ButtonEdit';
import ButtonDelete from '../../../components/Button/ButtonDelete/ButtonDelete';
import ReviewStars from '../../../UIElements/ProductReview/ReviewStars';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';

const ReviewList: React.FC = () => {
  const reviews = useSelector<RootState>((state) => state.dashboard.reviews.reviews) as Partial<ReviewType>[];
  
  const navigate= useNavigate()
  
  const onEdit = (reviewId: string) => {
    navigate(`./edit/${reviewId}`)
  }

  return (
    <div className={styles.reviewTable}>
      <table>
        <thead>
          <tr>
            <th>Image</th>
            <th>User Name</th>
            <th>Rating</th>
            <th>Review Content</th>
            <th>Date</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {reviews.map((review) => (
            <tr key={review._id}>
              <td>
                {review.images?.[0] && (
                  <img src={review.images[0]} alt={`Review by ${review.userName}`} className={styles.reviewImage} />
                )}
              </td>
              <td>{review.userName}</td>
              <td><ReviewStars rating={review?.rating!} /></td>
              <td>{review.content}</td>
              <td>{new Date(review.reviewDate || '').toLocaleDateString()}</td>
              <td>{review?.status}</td>
              <td onClick={()=>onEdit(review._id!)}>
                <button className={styles.edit_btn}>
                  <FaEdit/>
                </button>
                <button className={styles.delete_btn}>
                  <FaTrash/>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReviewList;
