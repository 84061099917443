import React from 'react';
import styles from './CustomerOrderDetailV1.module.scss';
import { CustomerOrderDetailComponentProps } from '../customerOrderDetailComponentProps';


const CustomerOrderDetailV1: React.FC<CustomerOrderDetailComponentProps> = ({ order }) => {


  return (
    <div className={styles.orderDetail}>
      <h2>Order Detail</h2>

      <div className={styles.orderInfo}>
        {/* Order ID */}
        <div className={styles.infoItem}>
          <strong>Order ID:</strong>
          <span>{order?._id}</span>
        </div>

        {/* Date */}
        <div className={styles.infoItem}>
          <strong>Date of Purchase:</strong>
          <span>{new Date(order?.createdAt as Date).toLocaleDateString()}</span>
        </div>

        {/* Status */}
        <div className={styles.infoItem}>
          <strong>Status:</strong>
          <span className={styles.status}>{order?.status}</span>
        </div>

        {/* Shipping Address */}
        <div className={styles.infoItem}>
          <strong>Shipping Address:</strong>
          <div className={styles.address}>
            <p>{order?.shippingAddress?.street},</p>
            <p>{order?.shippingAddress?.city}, {order?.shippingAddress?.state}</p>
            <p>{order?.shippingAddress?.postalCode}, {order?.shippingAddress?.country}</p>
          </div>
        </div>

        {/* Payment Information */}
        <div className={styles.infoItem}>
          <strong>Payment Method:</strong>
          <span>{order?.payment?.paymentMethod} Status: {order?.payment?.paymentStatus}</span>
        </div>

        {/* Total Amount */}
        <div className={styles.infoItem}>
          <strong>Total Amount:</strong>
          <span>${order?.orderSummary?.totalAmount.toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
};

export default CustomerOrderDetailV1;
