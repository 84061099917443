import React, { useState } from 'react';
import styles from './FooterNewsletterSubscription.module.scss';

const FooterNewsletterSubscription: React.FC = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log('Email submitted:', email);
    setSubmitted(true);
    setEmail('');
  };

  return (
    <div className={styles.newsletterContainer}>
      <h2 className={styles.newsletterTitle}>Subscribe to our Newsletter</h2>
      <p className={styles.newsletterDescription}>Get the latest updates and offers.</p>
      {!submitted ? (
        <form className={styles.newsletterForm} onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.newsletterInput}
            required
          />
          <button type="submit" className={styles.newsletterButton}>Subscribe</button>
        </form>
      ) : (
        <p>Thank you for subscribing!</p>
      )}
    </div>
  );
};

export default FooterNewsletterSubscription;
