import React from 'react'
import styles from '../Component.module.scss';
import EmailEditorV2 from '../../components/EmailEditor/EmailEditorV2/EmailEditorV2';
import EmailEditorV1 from '../../components/EmailEditor/EmailEditorV1/EmailEditorV1';

const EmailEditorComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <EmailEditorV1/>
        </div>
        <div className={styles.element}>
            <EmailEditorV2/>
        </div>
        
    </div>
  )
}

export default EmailEditorComponentPage