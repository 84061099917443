import { ImageType } from "./ImageType";

interface AttributeType {
    name: string;
    value: string;
}

interface Specs {
    [key: string]: string;
}

interface Variant {
    name: string;  // E.g., "Color" or "Size"
    options: string[];  // E.g., ["Red", "Blue", "Green"] or ["S", "M", "L"]
}

interface VariantCombinationType {
    options: { [variantName: string]: string };  // E.g., { "Color": "Red", "Size": "M" }
    price?: number;  // Price for this specific combination
    discountPrice?: number;  // Discounted price for this combination
    stockLevel?: number;  // Stock level for this combination
    sku?: string;  // SKU for this combination
    images?: ImageType[];  // Images specific to this combination
    isActive: boolean;  // Whether this combination is available for sale
}

export enum StockStatus {
    IN_STOCK = "In Stock",
    OUT_OF_STOCK = "Out of Stock",
    PRE_ORDER = "Pre Order",
    BACK_ORDER = "Back Order"
}

export enum ProductNature {
    Physical = 'Physical',         // Tangible items that can be shipped or handled
    Digital = 'Digital',           // Products that can be downloaded or accessed online
    Service = 'Service',           // Non-tangible items, where the product is a service
    Subscription = 'Subscription', // Recurring products with regular payments
}

interface ProductType {
    _id?: string;
    name: string;
    description: string;
    categories: string[];
    tags?: string[];
    
    // Prices
    price: number;               // Selling Price
    discountPrice?: number;      // Discounted Price (if applicable)
    purchasePrice?: number;      // Purchase Price (cost from supplier)
    profitMargin?: number;       // Optional: Can calculate automatically based on purchase price and selling price
    currency: string;

    // Media
    images?: ImageType[];
    thumbnail?: string;
    video?: string;

    // Stock and Inventory
    stockStatus: StockStatus;
    manageStock?: boolean;
    stockLevel?: number;
    lowStockThreshold?: number;

    // Additional Information
    sku: string;
    rating?: number;
    attributes?: AttributeType[];
    brand?: string;
    variants?: Variant[];
    relatedProducts?: string[];

    // Variant Combinaisons
    variantComination?: VariantCombinationType[]
    
    // SEO
    seoTitle?: string;
    seoDescription?: string;
    seoKeywords?: string[];

    // Nature and Shipping
    productNature?: ProductNature;
    specs?: Specs;
    weight?: string;
    dimensions?: string;
    shippingCost?: number;
    
    // Warranty, Supplier Info, and Other
    warrantyPeriod?: string;
    isActive: boolean;
    supplierLink?: string;
    link?: string;
    
    // Metadata
    createdAt?: Date;
    updatedAt?: Date;
}

export type {
    ProductType,
    AttributeType,
    Specs,
    Variant,
    VariantCombinationType
}
