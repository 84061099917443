import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';

import Input4 from '../../../components/Input/InputText/InputV4/InputV4';
import Button1 from '../../../components/Button/ButtonV1/ButtonV1';
import styles from './TaxSettingsForm.module.scss';
import { addTaxRate, updateTaxRate, deleteTaxRate } from '../../DashboardState/taxSettings/taxSettingsSlice';
import TaxRateCard from './TaxRateCard/TaxRateCard';
import { TaxRateType, TaxSettingsType } from '../../../types/Data/TaxSettingsType';

const TaxSettingsForm: React.FC = () => {
  const dispatch = useDispatch();
  const taxSettings = useSelector((state: RootState) => state.dashboard.taxSettings) as TaxSettingsType;

  const [taxName, setTaxName] = useState('');
  const [taxRate, setTaxRate] = useState<number | ''>('');

  const handleAddRate = () => {
    if (taxName && taxRate !== '') {
      const newRate: TaxRateType = {
        id: new Date().getTime().toString(),
        name: taxName,
        rate: Number(taxRate),
        isEnabled: true,
      };
      dispatch(addTaxRate(newRate));
      setTaxName('');
      setTaxRate('');
    }
  };

  return (
    <form className={styles.tax_settings_form} onSubmit={(e) => e.preventDefault()}>
      <div className={styles.form_group}>
        <label>Tax Rates</label>
        {taxSettings.taxRates.map((rate) => (
          <TaxRateCard key={rate.id} rate={rate} />
        ))}
      </div>

      <div className={styles.form_group}>
        <Input4
          label="New Tax Rate Name"
          type="text"
          id="taxName"
          name="taxName"
          value={taxName}
          onChange={(e) => setTaxName(e.target.value)}
          required
        />
      </div>

      <div className={styles.form_group}>
        <Input4
          label="New Tax Rate"
          type="number"
          id="taxRate"
          name="taxRate"
          value={taxRate}
          onChange={(e) => setTaxRate(Number(e.target.value))}
          required
        />
      </div>

      <div className={styles.button_container}>
        <Button1 type="button" title="Add Tax Rate" onClick={handleAddRate} />
      </div>
    </form>
  );
};

export default TaxSettingsForm;
