import QAListV1 from "../../components/QAList/QAListV1/QAListV1";
import QAListV2 from "../../components/QAList/QAListV2/QAListV2";
import QAListV3 from "../../components/QAList/QAListV3/QAListV3";

enum QAListVersions {
  V1 = "v1",
  V2 = "v2",
  V3 = "v3",
};

const QAListMapping:Record<QAListVersions, React.FC<any>> = {
  [QAListVersions.V1]: QAListV1,
  [QAListVersions.V2]: QAListV2,
  [QAListVersions.V3]: QAListV3,
};

interface FAQItemType {
  question: string;
  answer: string;
};
  
interface QAListComponentProps {
  items?: FAQItemType[];
};

export {
  QAListVersions,
  QAListMapping,
};

export type {
  FAQItemType,
  QAListComponentProps
};