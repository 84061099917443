import { AvatarComponentProps } from "../AvatarComponentProps";

const avatarDefaultProps: AvatarComponentProps = {
    name: 'John Doe',
    imageUrl: 'https://via.placeholder.com/150',  // Placeholder image URL
    description: 'This is a default user description.',
    status: 'offline'
};

export {
    avatarDefaultProps
};