import React, { useState } from 'react';
import { QAListComponentProps } from '../QAListComponentProps';
import { defaultQAListProps } from '../defaultQAListProps';
import { FaMinus, FaPlus } from 'react-icons/fa';
import styles from './QAListV4.module.scss';

const QAListV4: React.FC<QAListComponentProps> = ({ items = defaultQAListProps.items }) => {
  const [selected, setSelected] = useState<number | null>(null);

  const toggleItem = (index: number) => {
    setSelected(selected === index ? null : index);
  };

  return (
    <div className={styles.faqContainer}>
      {items?.map((question, index) => {
        const isSelected = selected === index;
        return (
          <div key={index} className={styles.faqCard}>
            <div className={styles.questionContainer} onClick={() => toggleItem(index)}>
              <h5>{question.question}</h5>
              {isSelected ? <FaMinus /> : <FaPlus />}
            </div>
            <div className={`${styles.answerContainer} ${isSelected ? styles.open : ''}`}>
              <p>{question.answer}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default QAListV4;
