import React from 'react';
import styles from './TrustBadgeIconsV1.module.scss';
import { getIconComponent } from '../utils';
import { defaultTrustBadgeItems } from '../defaultTrustBadgeData';
import { TrustBadgeIconsComponentProps } from '../TrustBadgeIconsComponentProps';

const TrustBadgeIconsV1:React.FC<TrustBadgeIconsComponentProps> = ({items = defaultTrustBadgeItems.items }) => {
  return (
    <div className={styles.badgeContainer}>
      {items?.map((item, index) => (
        <div key={index} className={styles.badge}>
            <div className={styles.icon}>{getIconComponent(item.icon!)}</div>
            <p>{item.title}</p>
          <div className={styles.tooltip}>{item.description}</div>
        </div>
      ))}
    </div>
  );
};

export default TrustBadgeIconsV1;
