import React from 'react';
import styles from './FeaturedProductsV4.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { ProductType } from '../../../types/Data/ProductType';
import { FeaturedProductsComponentProps } from '../FeaturedProductsComponentProps';

const FeaturedProductsV4: React.FC<FeaturedProductsComponentProps> = ({ productsId,  onClick=()=>null }) => {
  
    const allProducts = useSelector<RootState>(state => state.dashboard.products.products) as ProductType[];

    const products = productsId
        ? allProducts.filter((product) => productsId.includes(product._id!))
        : allProducts.slice(0, 10); 

    return (
    <div className={styles.parallaxContainer}>
      {products.map((product) => (
        <div key={product._id!} className={styles.parallaxItem} onClick={()=>onClick(product._id!)}>
          <div className={styles.parallaxBackground} style={ product.images && { backgroundImage: `url(${product.images[0].url})` }}></div>
          <div className={styles.productInfo}>
            <h3>{product.name}</h3>
            <p>{product.description}</p>
            <span>{product.price}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeaturedProductsV4;
