import React from 'react'
import styles from '../Component.module.scss';
import CheckboxV1 from '../../components/Checkbox/CheckboxV1/CheckboxV1';

const CheckboxComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <CheckboxV1/>
        </div>
    </div>
  )
}

export default CheckboxComponentPage