import React, { useState } from 'react';
import styles from './QAListV1.module.scss';
import { defaultQAListProps } from '../defaultQAListProps';
import { QAListComponentProps } from '../QAListComponentProps';

const QAListV1: React.FC<QAListComponentProps> = ({ items = defaultQAListProps.items }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggle = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={styles.qaList}>
      {items!.map((item, index) => (
        <div key={index} className={styles.qaItem}>
          <div className={styles.question} onClick={() => toggle(index)}>
            {item.question}
            <span className={styles.icon}>{activeIndex === index ? '-' : '+'}</span>
          </div>
          {activeIndex === index && (
            <div className={styles.answer}>
              {item.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default QAListV1;
