import React, { useState } from "react";
import styles from "./EmailEditorV2.module.scss";
import { IoSend } from "react-icons/io5";

interface EmailSenderProps {
    onSend?: (email: { to: string; subject: string; body: string }) => void;
}

const EmailEditorV2: React.FC<EmailSenderProps> = ({ onSend }) => {
    const [email, setEmail] = useState({ to: "", subject: "", body: "" });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setEmail(prevEmail => ({ ...prevEmail, [name]: value }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (onSend) {
            onSend(email);
        }
    };

    return (
        <div className={styles.emailSender}>
            <h2>Send Email</h2>
            <form onSubmit={handleSubmit}>
                <div className={styles.formGroup}>
                    <label>To</label>
                    <input
                        type="email"
                        name="to"
                        value={email.to}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label>Subject</label>
                    <input
                        type="text"
                        name="subject"
                        value={email.subject}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label>Body</label>
                    <textarea
                        name="body"
                        value={email.body}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit" className={styles.sendButton}>
                    <IoSend/>
                </button>
            </form>
        </div>
    );
};

export default EmailEditorV2;
