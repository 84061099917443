import React, { useEffect, useState } from 'react';
import styles from './AddressForm.module.scss';
import InputV4 from '../../../../components/Input/InputText/InputV4/InputV4';
import { AddressType } from '../../../../types/Data/AdressType';

interface AddressFormProps {
    shippingAddress: AddressType;
    billingAddress: AddressType;
    onAddressChange: (shippingAddress: AddressType, billingAddress: AddressType) => void;
}

const AddressForm: React.FC<AddressFormProps> = ({ shippingAddress, billingAddress, onAddressChange }) => {
    const [sameAsShipping, setSameAsShipping] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'shipping' | 'billing') => {
        const { name, value } = e.target;
        if (type === 'shipping') {
            const updatedShippingAddress = { ...shippingAddress, [name]: value };
            onAddressChange(updatedShippingAddress, sameAsShipping ? updatedShippingAddress : billingAddress);
        } else {
            const updatedBillingAddress = { ...billingAddress, [name]: value };
            onAddressChange(shippingAddress, updatedBillingAddress);
        }
    };

    const handleSameAsShippingToggle = () => {
        setSameAsShipping(!sameAsShipping);
        if (!sameAsShipping) {
            onAddressChange(shippingAddress, { ...shippingAddress });
        }
    };

    return (
        <div className={styles.addressForm}>
            <h4>Shipping Address</h4>
            <div className={styles.formGroup}>
                <InputV4
                    label="Street"
                    type="text"
                    id="shippingStreet"
                    name="street"
                    value={shippingAddress.street}
                    onChange={(e) => handleChange(e, 'shipping')}
                />
            </div>
            <div className={styles.formGroup}>
                <InputV4
                    label="City"
                    type="text"
                    id="shippingCity"
                    name="city"
                    value={shippingAddress.city}
                    onChange={(e) => handleChange(e, 'shipping')}
                />
            </div>
            <div className={styles.formGroup}>
                <InputV4
                    label="State"
                    type="text"
                    id="shippingState"
                    name="state"
                    value={shippingAddress.state}
                    onChange={(e) => handleChange(e, 'shipping')}
                />
            </div>
            <div className={styles.formGroup}>
                <InputV4
                    label="Postal Code"
                    type="text"
                    id="shippingPostalCode"
                    name="postalCode"
                    value={shippingAddress.postalCode}
                    onChange={(e) => handleChange(e, 'shipping')}
                />
            </div>
            <div className={styles.formGroup}>
                <InputV4
                    label="Country"
                    type="text"
                    id="shippingCountry"
                    name="country"
                    value={shippingAddress.country}
                    onChange={(e) => handleChange(e, 'shipping')}
                />
            </div>

            <div className={styles.sameAsShipping}>
                <label>
                    <input 
                        type="checkbox"
                        checked={sameAsShipping}
                        onChange={handleSameAsShippingToggle}
                    />
                    Use same address for billing
                </label>
            </div>

            {!sameAsShipping && (
                <>
                    <h4>Billing Address</h4>
                    <div className={styles.formGroup}>
                        <InputV4
                            label="Street"
                            type="text"
                            id="billingStreet"
                            name="street"
                            value={billingAddress.street}
                            onChange={(e) => handleChange(e, 'billing')}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <InputV4
                            label="City"
                            type="text"
                            id="billingCity"
                            name="city"
                            value={billingAddress.city}
                            onChange={(e) => handleChange(e, 'billing')}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <InputV4
                            label="State"
                            type="text"
                            id="billingState"
                            name="state"
                            value={billingAddress.state}
                            onChange={(e) => handleChange(e, 'billing')}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <InputV4
                            label="Postal Code"
                            type="text"
                            id="billingPostalCode"
                            name="postalCode"
                            value={billingAddress.postalCode}
                            onChange={(e) => handleChange(e, 'billing')}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <InputV4
                            label="Country"
                            type="text"
                            id="billingCountry"
                            name="country"
                            value={billingAddress.country}
                            onChange={(e) => handleChange(e, 'billing')}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default AddressForm;
