import React from 'react';
import styles from './StatsSectionV1.module.scss';
import { FaUsers, FaChartLine, FaDollarSign } from 'react-icons/fa';
import { StatComponentProps } from '../../StatsComponentProps';

const StatsSectionV1: React.FC<StatComponentProps> = ({ stats, title, description, img }) => {
    return (
        <div className={styles.stats_section}>
          <div>
            <img src={img}/>
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.description}>{description}</p>
          </div>
            {stats?.map((stat, index) => {
              return(
                <div className={styles.stat_card1}>
                <div className={styles.stat_icon}>{stat.icon}</div>
                <div className={styles.stat_info}>
                  <h3 className={styles.stat_title}>{stat.title}</h3>
                  <p className={styles.stat_value}>{stat.value}</p>
                </div>
              </div>
              )
            } )}
        </div>
    );
  };

  StatsSectionV1.defaultProps = {
    title: "Trusted by creators worldwide",
    description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    img: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80",
    stats: [
        {
          title: 'Users',
          value: 1500,
          icon: <FaUsers />
        },
        {
          title: 'Revenue',
          value: '$120K',
          icon: <FaDollarSign />
        },
        {
          title: 'Growth',
          value: '25%',
          icon: <FaChartLine />
        }
     ]
}
export default StatsSectionV1;