import React, { useState, useEffect, ReactNode } from "react";
import styles from "./OrderShipping.module.scss";
import { OrderStatus, OrderType } from "../../../../types/Data/OrderType";
import Input4 from "../../../../components/Input/InputText/InputV4/InputV4";
import Select4 from "../../../../components/Select/Select4/Select4";

// Default shipping info object
const defaultOrderShipping = {
    shippingMethod: '',
    trackingNumber: '',
    shippingCarrier: '',
    estimatedDeliveryDate: '', // Could also be initialized to new Date().toISOString().substring(0, 10)
    fulfilledBy: '',
    shippingStatus: 'Pending', // Assuming "Pending" as default
};

interface OrderShippingProps {
    order: Partial<OrderType>;
    onUpdate: (updatedShipping: any) => void; // Callback to pass updated shipping info to parent
    children?: ReactNode
}

const OrderShipping: React.FC<OrderShippingProps> = ({ order, onUpdate, children }) => {
    const [editableShipping, setEditableShipping] = useState(order.shipping || defaultOrderShipping);

    useEffect(() => {
        setEditableShipping(order.shipping || defaultOrderShipping);
    }, [order]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditableShipping({
            ...editableShipping,
            [name]: value,
        });
    };

    const handleUpdateShipping = () => {
        onUpdate(editableShipping); // Send updated shipping info to parent
    };

    const shippingStatusOptions = Object.values(OrderStatus).map((value) => ({ label: value, value }));

    return (
        <div className={styles.shippingInfo}>
            <h2>Shipping Information</h2>
            <Input4
                label="Shipping Method"
                type="text"
                name="shippingMethod"
                value={editableShipping?.shippingMethod || ''}
                onChange={handleInputChange}
            />
            <Input4
                wrapperStyle={{ marginTop: 10 }}
                label="Tracking Number"
                type="text"
                name="trackingNumber"
                value={editableShipping?.trackingNumber || ''}
                onChange={handleInputChange}
            />
            <Input4
                wrapperStyle={{ marginTop: 10 }}
                label="Shipping Carrier"
                type="text"
                name="shippingCarrier"
                value={editableShipping?.shippingCarrier || ''}
                onChange={handleInputChange}
            />
            <Input4
                wrapperStyle={{ marginTop: 10 }}
                label="Estimated Delivery Date"
                type="date"
                name="estimatedDeliveryDate"
                value={
                    editableShipping?.estimatedDeliveryDate instanceof Date
                        ? editableShipping?.estimatedDeliveryDate?.toISOString().substring(0, 10)
                        : ''
                }
                onChange={handleInputChange}
            />
            <Input4
                wrapperStyle={{ marginTop: 10 }}
                label="Fulfilled By"
                type="string"
                name="fulfilledBy"
                value={editableShipping?.fulfilledBy || ''}
                onChange={handleInputChange}
            />
            <Select4
                options={shippingStatusOptions}
                wrapperStyle={{ marginTop: 10 }}
                label="Shipping Status"
                value={editableShipping?.shippingStatus || ''}
                onChange={(value) =>
                    handleInputChange({ target: { name: 'shippingStatus', value: value } } as React.ChangeEvent<HTMLInputElement>)
                }
            />
            { children && children }
        </div>
    );
};

export default OrderShipping;
