import React from 'react'
import styles from '../Component.module.scss';
import ButtonV1 from '../../components/Button/ButtonV1/ButtonV1';
import ButtonV2 from '../../components/Button/ButtonV2/ButtonV2';
import ButtonV3 from '../../components/Button/ButtonV3/ButtonV3';
import ButtonV4 from '../../components/Button/ButtonV4/ButtonV4';
import ButtonV5 from '../../components/Button/ButtonV5/ButtonV5';
import ButtonV8 from '../../components/Button/ButtonV8/ButtonV8';
import ButtonV9 from '../../components/Button/ButtonV9/ButtonV9';
import ButtonV12 from '../../components/Button/ButtonV12/ButtonV12';
import ButtonV6 from '../../components/Button/ButtonV6/ButtonV6';
import ButtonV7 from '../../components/Button/ButtonV7/ButtonV7';
import ButtonV10 from '../../components/Button/ButtonV10/ButtonV10';
import ButtonV11 from '../../components/Button/ButtonV11/ButtonV11';

const ButtonComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <ButtonV1/>
        </div>
        <div className={styles.element}>
            <ButtonV2/>
        </div>
        <div className={styles.element}>
            <ButtonV3/>
        </div>
        <div className={styles.element}>
            <ButtonV4/>
        </div>
        <div className={styles.element}>
            <ButtonV5/>
        </div>
        <div className={styles.element}>
            <ButtonV6/>
        </div>
        <div className={styles.element}>
            <ButtonV7/>
        </div>
        <div className={styles.element}>
            <ButtonV8/>
        </div>
        <div className={styles.element}>
            <ButtonV9/>
        </div>
        <div className={styles.element}>
            <ButtonV10/>
        </div>
        <div className={styles.element}>
            <ButtonV11/>
        </div>
        <div className={styles.element}>
            <ButtonV12/>
        </div>
    </div>
  )
}

export default ButtonComponentPage