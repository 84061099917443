import React from 'react'
import styles from '../Component.module.scss';
import TestimonialV1 from '../../UIElements/Testimonial/TestimonialV1/TestimonialV1';
import TestimonialV2 from '../../UIElements/Testimonial/TestimonialV2/TestimonialV2';
import TestimonialV3 from '../../UIElements/Testimonial/TestimonialV3/TestimonialV3';
import TestimonialV4 from '../../UIElements/Testimonial/TestimonialV4/TestimonialV4';

const TestimonialComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <TestimonialV1/>
        </div>
        <div className={styles.element}>
            <TestimonialV2/>
        </div>
        <div className={styles.element}>
            <TestimonialV3/>
        </div>
        <div className={styles.element}>
            <TestimonialV4/>
        </div>
    </div>
  )
}

export default TestimonialComponentPage