import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { RootState } from '../Store/store';
import { FooterComponentProps, FooterMapping, FooterVersions } from '../UIElements/Footer/FooterComponentProps';
import { TabNavigationComponentProps, TabNavigationMapping, TabNavigationVersions } from '../UIElements/TabNavigation/TabNavigationComponentProps';
import { BaseElementType } from '../types/Data/BaseElementType';

const EcommerceLayout: React.FC = () => {
    const navigate = useNavigate();

    const footer = useSelector<RootState>((state)=>state.ecommerce.ui.elements.find((el)=>el.type==='footer')) as BaseElementType<FooterComponentProps> | undefined
    const Footer = FooterMapping[(footer?.version || FooterVersions.V1) as FooterVersions];

    const tabNavigation = useSelector<RootState>((state)=>state.dashboard.elements.elements.find((el)=>el.type==='tabNavigation')) as BaseElementType<TabNavigationComponentProps> | undefined;
    const TabNavigation = TabNavigationMapping[(tabNavigation?.version || TabNavigationVersions.V1) as TabNavigationVersions];

    const handleShopClick = () => {
      navigate('/ecommerce')
    };

    const handleCartClick = () => {
      navigate('/ecommerce/cart')
    };

    const handleUserClick = () => {
      navigate('/ecommerce/customer')
    };


    return (
    <div>
      {/* Add any shared layout components like headers, footers, or sidebars here */}
      <TabNavigation {...tabNavigation?.props} onShopClick={handleShopClick} onCartClick={handleCartClick} onUserClick={handleUserClick}/>
      <Outlet /> {/* Renders the child routes */}
      <Footer {...footer?.props} />
    </div>
  );
};

export default EcommerceLayout;
