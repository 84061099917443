import React, { InputHTMLAttributes } from 'react';
import styles from './InputV2.module.scss';
import { FaUser } from 'react-icons/fa';
import { InputComponentProps } from '../../InputComponentProps';


const InputV2: React.FC<InputComponentProps> = ({ label, icon = <FaUser/>, value, onChange, placeholder , ...props}) => {
  return (
    <div id={styles.input2}>
      <label>{label}</label>
      <div className={styles.input_wrapper}>
        {icon && 
          <div className={styles.icon_container}>
            {icon}
          </div>
        }
      <input
          type="text"
          value={value}
          placeholder={placeholder}
          {...props}
        />
      </div>
    </div>
  );
};

export default InputV2;