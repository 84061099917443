import React from 'react';
import styles from './DashboardHome.module.scss';
import { FaEye, FaPlus } from 'react-icons/fa';
import Button1 from '../../components/Button/ButtonV1/ButtonV1';
import Stats2 from '../../components/Stats/StatCard/StatCardV1/StatCardV1';
import ChartLineSales from '../Analytics/Orders/ChartSalesEvolutionOverTime/ChartSalesEvolutionOverTime';
import ChartRevenueBar from '../Analytics/Orders/ChartRevenueBar/ChartRevenueBar';
import ChartTopProducts from '../Analytics/Products/ChartTopProducts/ChartTopProducts';
import ChartCustomerGrowth from '../Analytics/Customers/charts/ChartCustomerGrowth/ChartCustomerEvolutionOverTime';
import ChartSalesByCategories from '../Analytics/Products/ChartSalesByCategories/ChartSalesByCategories';
import RecentActivity from '../../components/Notification/RecentActivity/RecentActivity';
import Notification3 from '../../components/Notification/Notification3/Notification3';
import QuickLinks1 from '../../components/QuickLinks/QuickLinks1/QuickLinks1';
import { useNavigate } from 'react-router-dom';
import RecentNotification from '../Notification/RecentNotification/RecentNotification';
import RecentOrder from '../Orders/RecentOrder/RecentOrder';
import RecentReview from '../Reviews/RecentReview/RecentReview';
import RecentCustomer from '../Customers/RecentCustomer/RecentCustomer';

const DashboardHome = () => {
    const navigate = useNavigate();
    
    const handleNavigateAddProduct = () => {
        navigate('/dashboard/products/edit')
    };

    const handleNavigateOrder = () => {
        navigate('/dashboard/orders')
    }
    
    
    
    return (
        <div className={styles.dashboard}>
            <header className={styles.dashboardHeader}>
                <h1 className={styles.h1}>Dashboard Overview</h1>
                <div className={styles.quickActions}>
                    <button title='Add product' onClick={()=>handleNavigateAddProduct()}> <FaPlus/> Add product </button> 
                    <button title='View Orders' onClick={()=>handleNavigateOrder()}> <FaEye/> View Orders </button>
                </div>
            </header>
            <section className={styles.keyMetrics}>
                <Stats2 title='Total Sales' value='$50,000' comparaison='+10% from last month'/> 
                <Stats2 title='Total Orders' value='1,200' comparaison='+5% from last month'/> 
                <Stats2 title='Total Customers' value='3,500' comparaison='+8% from last month'/> 
                <Stats2 title='Total Revenue' value='$70,000' comparaison='+12% from last month'/> 
            </section>
            <section className={styles.charts}>
                <div className={styles.chart}>
                    <ChartLineSales/>
                </div>
                <div className={styles.chart}>
                    <ChartRevenueBar/>
                </div>
                <div className={styles.chart}>
                    <ChartTopProducts/>
                </div>
                <div className={styles.chart}>
                    <ChartCustomerGrowth/>
                </div>
                <div className={styles.chart}>
                    <ChartSalesByCategories/>
                </div>
            </section>
            <section className={styles.recentActivity}>
               <RecentOrder/>
               <RecentReview/>
               <RecentCustomer/>
            </section> 
            <section className={styles.notifications}>
                <RecentNotification/>
            </section>
        </div>
    );
};

export default DashboardHome;