import React from 'react';
import styles from './TrustSignalsV1.module.scss';
import { FaLock, FaShieldAlt, FaRegThumbsUp } from 'react-icons/fa';
import { TrustSignalsComponentProps } from '../TrustSignalsComponentProps';
import { trustSignalsDefaultProps } from '../trustSignalsDefaultProps';

const TrustSignalsV1: React.FC<TrustSignalsComponentProps> = ({
  securePaymentText = trustSignalsDefaultProps.securePaymentText,
  guaranteeText = trustSignalsDefaultProps.guaranteeText,
  satisfactionText = trustSignalsDefaultProps.satisfactionText
}) => {
  return (
    <div className={styles.trustSignals}>
      <div className={styles.trustItem}>
        <FaLock className={styles.icon} />
        <p>{securePaymentText}</p>
      </div>
      <div className={styles.trustItem}>
        <FaShieldAlt className={styles.icon} />
        <p>{guaranteeText}</p>
      </div>
      <div className={styles.trustItem}>
        <FaRegThumbsUp className={styles.icon} />
        <p>{satisfactionText}</p>
      </div>
    </div>
  );
};

export default TrustSignalsV1;
