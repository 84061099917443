import React from 'react';
import styles from './SocialMediaLinkList.module.scss';
import { SocialMediaLinkType } from '../../../../types/Data/SocialMediaSettingsType';
import ButtonV5 from '../../../../components/Button/ButtonV5/ButtonV5';

interface SocialMediaLinksTableProps {
  links: SocialMediaLinkType[];
  onEdit: (platform: string) => void;
  onRemove: (platform: string) => void;
}

const SocialMediaLinksTable: React.FC<SocialMediaLinksTableProps> = ({ links, onEdit, onRemove }) => {
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Platform</th>
            <th>Link</th>
            <th>Icon</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {(links ?? []).map((link) => (
            <tr key={link.platform}>
              <td>{link.platform}</td>
              <td>
                <a href={link.url} target="_blank" rel="noopener noreferrer">
                  {link.url}
                </a>
              </td>
              <td>
                <img src={link.iconUrl} alt={`${link.platform} icon`} className={styles.icon} />
              </td>
              <td className={styles.actions}>
                <ButtonV5 title="Edit" onClick={() => onEdit(link.platform)} />
                <ButtonV5 title="Remove" onClick={() => onRemove(link.platform)} style={{ backgroundColor: '#dc3545' }} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SocialMediaLinksTable;
