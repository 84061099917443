import { OrderItemType } from '../../types/Data/OrderType';

const mockItems: OrderItemType[] = [
  {
    productId: '1',
    name: 'Wireless Headphones',
    quantity: 1,
    unitPrice: 99.99,
    totalPrice: 99.99,
  },
  {
    productId: '2',
    name: 'Bluetooth Speaker',
    quantity: 1,
    unitPrice: 49.99,
    totalPrice: 49.99,
  },
  {
    productId: '3',
    name: 'Smart Watch',
    quantity: 1,
    unitPrice: 199.99,
    totalPrice: 199.99,

  },
  {
    productId: '4',
    name: 'Portable Charger',
    quantity: 2,
    unitPrice: 29.99,
    totalPrice: 59.98
  },
];

export const returnRequestDefaultProps = {
  items: mockItems,
  onSubmit: (selectedItems: OrderItemType[], reason: string, notes: string) => {
    console.log('Return Request Submitted:', { selectedItems, reason, notes });
  },
};
