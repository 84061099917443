import React from 'react';
import styles from './CustomerOrderHistoryV1.module.scss';
import { CustomerOrderHistoryComponentProps } from '../CustomerOrderHistoryType';
import { customerOrderHistoryDefaultProps } from '../customerOrderHistoryDefaultProps';
import { FaShippingFast, FaCheckCircle } from 'react-icons/fa'; // Icons for status
import { IoMdTime } from 'react-icons/io'; // Icon for order date

const CustomerOrderHistoryV1: React.FC<CustomerOrderHistoryComponentProps> = ({ 
    orders = customerOrderHistoryDefaultProps 
}) => {
  return (
    <div className={styles.orderHistory}>
      {orders.length > 0 ? (
        <div className={styles.orders}>
          {orders.map((order) => (
            <div key={order._id} className={styles.orderCard}>
              <div className={styles.orderHeader}>
                <div className={styles.orderIcon}>
                  {order.status === "Delivered" ? <FaCheckCircle className={styles.iconDelivered} /> : <FaShippingFast className={styles.iconShipped} />}
                </div>
                <div className={styles.orderInfo}>
                  <h3>Order ID: {order._id}</h3>
                  <div className={styles.orderDetails}>
                    <IoMdTime className={styles.iconDate} />
                    <span>{new Date(order.createdAt!).toLocaleDateString()}</span>
                  </div>
                  <div className={styles.orderStatus}>
                    <span>Status: {order.status}</span>
                    <span>Total: ${order.orderSummary?.totalAmount.toFixed(2)}</span>
                  </div>
                </div>
              </div>
              <div className={styles.items}>
                {order.items?.map((item) => (
                  <div key={item.productId} className={styles.itemCard}>
                    <img src={item.imageUrl} alt={item.name} className={styles.itemImage} />
                    <div className={styles.itemInfo}>
                      <h4>{item.name}</h4>
                      <p>{item.quantity} x ${item.totalPrice.toFixed(2)}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No orders found.</p>
      )}
    </div>
  );
};

export default CustomerOrderHistoryV1;
