enum CheckoutStep {
    Cart = 'cart',
    Shipping = "shipping",
    Payment = "payment",
    Review= 'review',
    Confirmation = 'confirmation'  
}

export {
    CheckoutStep
}