import React, { useState } from 'react';
import styles from './ContactFormV2.module.scss';
import { ContactFormComponentProps, ContactUsSubject } from '../ContactFormComponentProps';

const ContactFormV2: React.FC<ContactFormComponentProps> = ({ onSubmit = () => null, initialData = {}, submitButtonText = "Submit" }) => {
  const [formData, setFormData] = useState({
    name: initialData.name || '',
    email: initialData.email || '',
    subject: initialData.subject || '',
    message: initialData.message || '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const subjectOptions = Object.values(ContactUsSubject).map((value) => ({ label: value, value }));

  return (
    <form className={styles.contactForm} onSubmit={handleSubmit}>
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          <h2>Contact Us</h2>
        </div>
        <div className={styles.cardBody}>
          <div className={styles.formGroup}>
            <label className={styles.label}>Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Subject</label>
            <select
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
              className={`${styles.select} ${formData.email ? styles.filled : ''}`}
            >
              <option value="" disabled>Select a Subject</option>
              {subjectOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
            ))}
            </select>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Message</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              className={styles.textarea}
            />
          </div>
        </div>
        <div className={styles.cardFooter}>
          <button type="submit" className={styles.submitButton}>{submitButtonText}</button>
        </div>
      </div>
    </form>
  );
};

export default ContactFormV2;
