import React from 'react';
import styles from './ButtonV9.module.scss';
import { ButtonComponentProps } from '../ButtonComponentProps';

const ButtonV9: React.FC<ButtonComponentProps> = ({ title="Button 2", onClick }) => {
  return <button id={styles.button_9} onClick={onClick}>
    <div className={styles.overlay}/>
        <p>{title}</p>
    </button>;
};

export default ButtonV9;