import AbandonedCartEmail from '../../Dashboard/MarketingEmails/EmailSkeleton/AbandonedCartEmailSkeleton/AbandonedCartEmailSkeleton';
import StoreLaunchEmailSkeleton from '../../Dashboard/MarketingEmails/EmailSkeleton/StoreLaunchEmailSkeleton/StoreLaunchEmailSkeleton';
import FeedbackRequestEmail from '../../Dashboard/MarketingEmails/EmailSkeleton/FeedbackRequestEmailSkeleton/FeedbackRequestEmail';
import NewProductEmailSkeleton from '../../Dashboard/MarketingEmails/EmailSkeleton/NewProductEmailSkeleton/NewProductEmailSkeleton';
import OrderConfirmationEmailSkeleton from '../../Dashboard/MarketingEmails/EmailSkeleton/OrderConfirmationEmailSkeleton/OrderConfirmationEmailSkeleton';
import OrderDeliveredEmail from '../../Dashboard/MarketingEmails/EmailSkeleton/OrderDeliveredEmailSkeleton/OrderDeliveredEmailSkeleton';
import PromotionEmailSkeleton from '../../Dashboard/MarketingEmails/EmailSkeleton/PromotionalEmailSkeleton/PromotionEmailSkeleton';
import ReengagementEmailSkeleton from '../../Dashboard/MarketingEmails/EmailSkeleton/ReengagementEmailSkeleton/ReengagementEmailSkeleton';
import ShippingNotificationEmail from '../../Dashboard/MarketingEmails/EmailSkeleton/ShippingNotificationEmailSkeleton/ShippingNotificationEmailSkeleton';
import WelcomeEmailSkeleton from '../../Dashboard/MarketingEmails/EmailSkeleton/WelcomeEmailSkeleton/WelcomeEmailSkeleton';
import SeasonalSaleEmail from '../../Dashboard/MarketingEmails/EmailSkeleton/SeasonalSaleEmailSkeleton/SeasonalSaleEmailSkeleton';
import EventInvitationEmail from '../../Dashboard/MarketingEmails/EmailSkeleton/EventInvitationEmailSkeleton/EventInvitationEmail';
import MembershipRenewalEmail from '../../Dashboard/MarketingEmails/EmailSkeleton/MembershipRenewalEmailSkeleton/MembershipRenewalEmail';
import { CustomerType } from "./CustomerType";
import { OrderType } from "./OrderType";
import { ProductType } from "./ProductType";

enum TemplateNames {
    AbandonedCart = "Abandoned Cart",
    EventInvitation = "Event Invitation",
    FeedbackRequest = "Feedback Request",
    MembershipRenewal = "Membership Renewal",
    StoreLaunch = "Store Launch",
    NewProduct = 'New Product',
    Promotion = 'Promotion',
    Welcome = 'Welcome',
    SeasonalSale = 'Seasonal Sale',
    OrderConfirmation = "Order Confirmation",
    OrderDelivered = "Order Delivered",
    ShippingNotification = "Shippin gNotification",
    Reengagement = "Reengagement"
}
const emailSkeletonMapping: Record<TemplateNames, React.FC<any>> = {
    [TemplateNames.AbandonedCart]: AbandonedCartEmail,
    [TemplateNames.EventInvitation]: EventInvitationEmail,
    [TemplateNames.FeedbackRequest]: FeedbackRequestEmail,
    [TemplateNames.MembershipRenewal]: MembershipRenewalEmail,
    [TemplateNames.StoreLaunch]: StoreLaunchEmailSkeleton,
    [TemplateNames.NewProduct]: NewProductEmailSkeleton,
    [TemplateNames.Promotion]: PromotionEmailSkeleton,
    [TemplateNames.Welcome]: WelcomeEmailSkeleton,
    [TemplateNames.SeasonalSale]: SeasonalSaleEmail,
    [TemplateNames.OrderConfirmation]: OrderConfirmationEmailSkeleton,
    [TemplateNames.OrderDelivered]: OrderDeliveredEmail,
    [TemplateNames.ShippingNotification]: ShippingNotificationEmail,
    [TemplateNames.Reengagement]: ReengagementEmailSkeleton,
};


interface EmailTemplatePlaceholder {
    key: string;                            // Placeholder key used in the template (e.g., {{customerName}})
    description: string;                    // Description of what the placeholder represents
    defaultValue?: string;                  // Optional default value for the placeholder
}

interface EmailTemplateDataType {
    title?: string;
    body?: string;
    productIds?: string[];
    orderId?: string;
    customerId?: string;
    importantLinks?: { label: string, url: string }[];
}

interface EmailTemplateType {
    template: TemplateNames;                // Template name to identify the type of template
    placeholders?: EmailTemplatePlaceholder[];  // Array of placeholders used in the template
    data?: EmailTemplateDataType;            // Dynamic data required to populate the template (e.g., title, products, etc.)
    version?: number;                       // Version number for template version control
    isActive?: boolean;                     // Flag to mark if the template is active or archived
}

export type {
    EmailTemplateType,
    EmailTemplateDataType,
    EmailTemplatePlaceholder
}

export { 
    TemplateNames,
    emailSkeletonMapping
};
