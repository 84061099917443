import React, { useState } from 'react';
import styles from './ProductImageGalleryV3.module.scss';
import { ProductImageGalleryComponentProps } from '../ProductImageGalleryComponentProps';
import { defaultProductImageGalleryProps } from '../defaultProductImageGalleryProps';


const ProductImageGalleryV3:React.FC<ProductImageGalleryComponentProps>  = ({ images = defaultProductImageGalleryProps.images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState<boolean>(false);

  const handleImageClick = (index:number) => {
    setCurrentImageIndex(index);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  return (
    <div className={styles.galleryContainer}>
      <div className={styles.mainImage} onClick={() => handleImageClick(currentImageIndex)}>
        <img src={images[currentImageIndex].url} alt={images[currentImageIndex].alt} />
      </div>
      <div className={styles.thumbnails}>
        {images.map((image, index) => (
          <div
            key={index}
            className={`${styles.thumbnail} ${index === currentImageIndex ? styles.active : ''}`}
            onClick={() => handleImageClick(index)}
          >
            <img src={image.url} alt={image.alt} />
          </div>
        ))}
      </div>
      {isLightboxOpen && (
        <div className={styles.lightbox} onClick={closeLightbox}>
          <div className={styles.lightboxContent} onClick={(e) => e.stopPropagation()}>
            <img src={images[currentImageIndex].url} alt={images[currentImageIndex].alt} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductImageGalleryV3;
