import React, { useState } from "react";
import styles from './DashboardMenu.module.scss';
import { FaBan, FaBell, FaBox, FaCartPlus, FaChartBar, FaChartLine, FaChartPie, FaCheck, FaClipboardList, FaCog, FaCogs, FaCommentDots, FaCreditCard, FaDollarSign, FaEnvelope, FaEnvelopeOpen, FaFileAlt, FaFileContract, FaFileInvoiceDollar, FaFolder, FaGoogle, FaHeadset, FaHome, FaImages, FaLayerGroup, FaLifeRing, FaListAlt, FaPaperPlane, FaPen, FaPenFancy, FaPercentage, FaPlus, FaQuestionCircle, FaRssSquare, FaShareAlt, FaSignOutAlt, FaSitemap, FaStar, FaTachometerAlt, FaTag, FaTicketAlt, FaTimes, FaTrafficLight, FaTruck, FaUndo, FaUpload, FaUser, FaUsers, FaUserShield } from "react-icons/fa";
import { IoClose, IoMenu } from "react-icons/io5";

interface SidebarProps {
    isOpen: boolean;
    onToggle?: (collapsed: boolean) => void;
}

const DashboardMenu: React.FC<SidebarProps> = ({ onToggle, isOpen = true }) => {
    const [collapsed, setCollapsed] = useState(isOpen);
    const [expandedMenu, setExpandedMenu] = useState("");

    const handleToggleMenu = (menu: string) => {
        setExpandedMenu(expandedMenu === menu ? "" : menu);
    };

    const handleToggleSidebar = () => {
        setCollapsed(!collapsed);
        onToggle && onToggle(!collapsed);
    }

    return (
        <div className={`${styles.sidebar} ${collapsed ? styles.collapsed : ""}`}>
            <div className={styles.header}>
                {!collapsed && <h3>Admin Dashboard</h3>}
                <button className={styles.collapseBtn} onClick={() => handleToggleSidebar()}>
                    {collapsed ? <IoMenu className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> : <IoClose className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} />}
                </button>
            </div>
            <ul className={styles.menu}>
                <li><a href="/dashboard/home"><FaTachometerAlt className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Dashboard'}</a></li>
                <li className={expandedMenu === "products" ? styles.expanded : ""}>
                    <a href="#productsSubmenu" onClick={() => handleToggleMenu("products")}><FaBox className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Products'}</a>
                    <ul className={styles.collapse} id="productsSubmenu">
                        <li><a href="/dashboard/products"><FaListAlt className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'All Products'}</a></li>
                        <li><a href="/dashboard/products/edit"><FaPlus className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Add New Product'}</a></li>
                        <li><a href="/dashboard/reviews"><FaStar className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Product Reviews'}</a></li>
                        <li><a href="/dashboard/inventory"><FaTruck className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Inventory Management'}</a></li>
                    </ul>
                </li>
                <li className={expandedMenu === "categories" ? styles.expanded : ""}>
                    <a href="#categoriesSubmenu" onClick={() => handleToggleMenu("categories")}><FaLayerGroup className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Categories'}</a>
                    <ul className={styles.collapse} id="categoriesSubmenu">
                        <li><a href="/dashboard/categories"><FaListAlt className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Categories'}</a></li>
                        <li><a href="/dashboard/categories/edit"><FaPlus className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Add Category'}</a></li>
                    </ul>
                </li>
                <li className={expandedMenu === "orders" ? styles.expanded : ""}>
                    <a href="#ordersSubmenu" onClick={() => handleToggleMenu("orders")}><FaCartPlus className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Orders'}</a>
                    <ul className={styles.collapse} id="ordersSubmenu">
                        <li><a href="/dashboard/orders"><FaListAlt className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'All Orders'}</a></li>
                        <li><a href="/dashboard/orders/create"><FaPlus className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Add Order'}</a></li>
                    </ul>
                </li>
                <li className={expandedMenu === "returns" ? styles.expanded : ""}>
                    <a href="#returns" onClick={() => handleToggleMenu("returns")}>
                        <FaUndo className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Returns Request'}
                    </a>
                    <ul className={styles.collapse} id="returnsSubmenu">
                        <li><a href="/dashboard/returns-request/all"><FaListAlt className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'All Return Requests'}</a></li>
                        <li><a href="/dashboard/returns-request/create"><FaPlus className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Add Return Request'}</a></li>
                    </ul>
                </li>

                <li className={expandedMenu === "cancellations" ? styles.expanded : ""}>
                    <a href="#cancellationsSubmenu" onClick={() => handleToggleMenu("cancellations")}>
                        <FaBan className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Cancellations'}
                    </a>
                    <ul className={styles.collapse} id="cancellationsSubmenu">
                        <li><a href="/dashboard/cancellations"><FaListAlt className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'All Cancellations'}</a></li>
                        <li><a href="/dashboard/cancellations/create"><FaPlus className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Add Cancellation'}</a></li>
                    </ul>
                </li>

                <li className={expandedMenu === "refunds" ? styles.expanded : ""}>
                    <a href="#refunds" onClick={() => handleToggleMenu("refunds")}>
                        <FaDollarSign className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Refunds'}
                    </a>
                    <ul className={styles.collapse} id="refundsSubmenu">
                        <li><a href="/dashboard/refund-process/all"><FaListAlt className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'All Refunds'}</a></li>
                    </ul>
                </li>

                <li className={expandedMenu === "customers" ? styles.expanded : ""}>
                    <a href="#customersSubmenu" onClick={() => handleToggleMenu("customers")}><FaUser className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Customers'}</a>
                    <ul className={styles.collapse} id="customersSubmenu">
                        <li><a href="/dashboard/customers"><FaListAlt className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'All Customers'}</a></li>
                        <li><a href="/dashboard/customers/feedback"><FaCommentDots className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Customer Feedback'}</a></li>
                    </ul>
                </li>
                <li className={expandedMenu === "discounts" ? styles.expanded : ""}>
                    <a href="#discountsSubmenu" onClick={() => handleToggleMenu("discounts")}><FaTag className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Discounts & Promotions'}</a>
                    <ul className={styles.collapse} id="discountsSubmenu">
                        <li><a href="/dashboard/discounts"><FaListAlt className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Discount Codes'}</a></li>
                        <li><a href="/dashboard/promotions"><FaChartBar className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Promotions'}</a></li>
                    </ul>
                </li>
                <li className={expandedMenu === "email" ? styles.expanded : ""}>
                    <a href="#emailSubmenu" onClick={() => handleToggleMenu("email")}><FaEnvelope className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Email Marketing'}</a>
                    <ul className={styles.collapse} id="emailSubmenu">
                        <li><a href="/dashboard/emails/campaigns"><FaEnvelopeOpen className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Campaigns'}</a></li>
                        <li><a href="/dashboard/emails/subscribers"><FaUsers className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Subscribers List'}</a></li>
                    </ul>
                </li>
                <li className={expandedMenu === "analytics" ? styles.expanded : ""}>
                    <a href="#analyticsSubmenu" onClick={() => handleToggleMenu("analytics")}><FaChartLine className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Analytics'}</a>
                    <ul className={styles.collapse} id="analyticsSubmenu">
                        <li><a href="/dashboard/analytics/salesReport"><FaFileInvoiceDollar className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Sales Report'}</a></li>
                        <li><a href="/dashboard/analytics/customers"><FaChartPie className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Customer Analytics'}</a></li>
                        <li><a href="/dashboard/analytics/products"><FaChartBar className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Product Performance'}</a></li>
                        <li><a href="/analytics/traffic"><FaTrafficLight className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Traffic Sources'}</a></li>
                    </ul>
                </li>
                <li className={expandedMenu === "settings" ? styles.expanded : ""}>
                    <a href="#settingsSubmenu" onClick={() => handleToggleMenu("settings")}><FaCogs className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Settings'}</a>
                    <ul className={styles.collapse} id="settingsSubmenu">
                        <li><a href="/dashboard/settings/general"><FaCog className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'General Settings'}</a></li>
                        <li><a href="/dashboard/settings/paymentMethod"><FaCreditCard className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Payment Methods'}</a></li>
                        <li><a href="/dashboard/settings/shipping"><FaTruck className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Shipping Methods'}</a></li>
                        <li><a href="/dashboard/settings/socialMedia"><FaShareAlt className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Social Medias'}</a></li>
                        <li><a href="/dashboard/settings/tax"><FaPercentage className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Tax Settings'}</a></li>
                    </ul>
                </li>
                <li className={expandedMenu === "privatePolicy" ? styles.expanded : ""}>
                    <a href="#privatePolicySubmenu" onClick={() => handleToggleMenu("privatePolicy")}><FaUserShield className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Private Policy'}</a>
                    <ul className={styles.collapse} id="privatePolicySubmenu">
                        <li><a href="/dashboard/privatePolicy"><FaUserShield className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Private Policy'}</a></li>
                    </ul>
                </li>
                <li className={expandedMenu === "termOfService" ? styles.expanded : ""}>
                    <a href="#termOfServiceSubmenu" onClick={() => handleToggleMenu("termOfService")}><FaFileContract className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Term of Service'}</a>
                    <ul className={styles.collapse} id="termOfServiceSubmenu">
                        <li><a href="/dashboard/termOfService"><FaFileContract className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Term of Service'}</a></li>
                    </ul>
                </li>
                <li className={expandedMenu === "admins" ? styles.expanded : ""}>
                    <a href="#adminsSubmenu" onClick={() => handleToggleMenu("admins")}><FaUser className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Admin Management'}</a>
                    <ul className={styles.collapse} id="adminsSubmenu">
                        <li><a href="/dashboard/admins"><FaUser className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Admin Users'}</a></li>
                        <li><a href="/dashboard/admins/create"><FaPlus className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Add Admin'}</a></li>
                    </ul>
                </li>
                <li className={expandedMenu === "content" ? styles.expanded : ""}>
                    <a href="/dashboard/content-management" onClick={() => handleToggleMenu("content")}><FaClipboardList className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Content Management'}</a>
                </li>
                <li className={expandedMenu === "blogPost" ? styles.expanded : ""}>
                    <a href="#blogPostSubmenu" onClick={() => handleToggleMenu("blogPost")}><FaRssSquare className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Blog Post'}</a>
                    <ul className={styles.collapse} id="blogPostSubmenu">
                        <li><a href="/dashboard/blogPost"><FaListAlt className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Al Blog Post'}</a></li>
                    </ul>
                    <ul className={styles.collapse} id="blogPostSubmenu">
                        <li><a href="/dashboard/blogPost/edit"><FaPenFancy className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Edit Blog Post'}</a></li>
                    </ul>
                </li>
                <li className={expandedMenu === "support" ? styles.expanded : ""}>
                    <a href="#supportSubmenu" onClick={() => handleToggleMenu("support")}><FaLifeRing className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Support'}</a>
                    <ul className={styles.collapse} id="supportSubmenu">
                        <li><a href="/dashboard/support/tickets"><FaTicketAlt className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Tickets'}</a></li>
                        <li><a href="/dashboard/support/faq"><FaQuestionCircle className={`${styles.subIcon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'FAQs'}</a></li>
                    </ul>
                </li>
                
                <li><a href="/dashboard/notifications"><FaBell className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Notifications'}</a></li>
                

                <li className={expandedMenu === "images" ? styles.expanded : ""}>
                    <a href="#imagesSubmenu" onClick={() => handleToggleMenu("images")}><FaImages className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Images'}</a>
                    <ul className={styles.collapse} id="imagesSubmenu">
                        <li><a href="/dashboard/images"><FaImages className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Images'}</a></li>
                        <li><a href="/dashboard/images/upload"><FaUpload className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Image Upload'}</a></li>
                    </ul>
                </li>
                
                <li><a href="/profile"><FaUser className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'My Profile'}</a></li>
                <li><a href="/ecommerce"><FaHome className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'ecommerce'}</a></li>
                <li><a href="/logout"><FaSignOutAlt className={`${styles.icon} ${!collapsed ? styles.icon_expanded : ''}`} /> {!collapsed && 'Logout'}</a></li>
            </ul>
        </div>
    );
}

export default DashboardMenu;
