import React from "react";
import styles from "./NewsletterSignupV2.module.scss";
import { NewsLetterSignupComponentProps } from "../NewsLetterSignupProps";

const NewsletterSignupV2: React.FC<NewsLetterSignupComponentProps> = () => {
  return (
    <div className={styles.newsletterV2}>
      <div className={styles.newsletterContent}>
        <h2>Join Our Community!</h2>
        <p>Subscribe to our newsletter for the latest updates and offers.</p>
        <input type="email" placeholder="Enter your email" />
        <button>Subscribe</button>
      </div>
    </div>
  );
};

export default NewsletterSignupV2;
