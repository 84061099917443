import FilterModalV1 from "./FilterModalV1/FilterModalV1";
import FilterModalV2 from "./FilterModalV2/FilterModalV2";
import FilterModalV3 from "./FilterModalV3/FilterModalV3";
import { AvailabilityFilterVersions, CategoryFilterVersions, ColorFilterVersions, DefaultFilterVersions, MaterialFilterVersions, PriceRangeFilterVersions, RatingFilterVersions, SizeFilterVersions } from "../Filter/FilterComponentProps";

enum FilterModalComponentVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const FilterModalComponentMapping:Record<FilterModalComponentVersions, React.FC<any>> = {
    [FilterModalComponentVersions.V1]: FilterModalV1,
    [FilterModalComponentVersions.V2]: FilterModalV2,
    [FilterModalComponentVersions.V3]: FilterModalV3,  
};

interface Option {
    label: string,
    options: string[],
};

interface FilterModalComponentProps {
    isOpen?: boolean;
    onClose?: () => void;
    children?: React.ReactNode;
    onApply?: () => void;
    onClear?: () => void;
    options?: Option[]; 
    showCategoryFilter?: boolean;
    categoryFilterVersion?: CategoryFilterVersions;
    showColorFilter?: boolean;
    colorFilterVersion?: ColorFilterVersions;
    showRatingFilter?: boolean;
    ratingFilterVersion?: RatingFilterVersions;
    showAvailabilityFilter?: boolean;
    availabilityFilterVersion?: AvailabilityFilterVersions;
    showPriceRangeFilter?: boolean;
    priceRangeFilterVersion?: PriceRangeFilterVersions;   
    defaultFilterVersion?: DefaultFilterVersions
}

export {
    FilterModalComponentVersions,
    FilterModalComponentMapping
}

export type {
    FilterModalComponentProps,
    
}

