import React from 'react';
import styles from './PaymentMethodSelectorV2.module.scss';
import { PaymentMethodSelectordProps } from '../PaymentMethodSelectorComponentProps';

const PaymentMethodSelectorV2: React.FC<PaymentMethodSelectordProps> = ({
    selectedMethod = '',
    methods = ['Credit Card', 'PayPal', 'Bank Transfer'],
    onMethodChange = () => {},
    onSubmit = () => {}
}) => {
  return (
    <div className={styles.paymentCard}>
      <h2>Payment Options</h2>
      <form onSubmit={onSubmit}>
        <div className={styles.methodGrid}>
          {methods.map((method) => (
            <label key={method} className={`${styles.methodItem} ${selectedMethod === method ? styles.selected : ''}`}>
              <input
                type="radio"
                name="paymentMethod"
                value={method}
                checked={selectedMethod === method}
                onChange={() => onMethodChange(method)}
              />
              <div className={styles.methodName}>{method}</div>
            </label>
          ))}
        </div>
        <button type="submit">Confirm Payment</button>
      </form>
    </div>
  );
};

export default PaymentMethodSelectorV2;
