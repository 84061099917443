import React, { useState, useEffect } from 'react';
import styles from './HeroBannerV2.module.scss';
import defaultHeroBanner from '../defaultHeroBanner';
import { BannerSlideType } from '../../../types/Data/BannerSlideType';
import { HeroBannerComponentProps } from '../HeroBannerComponentProps';

const HeroBannerV2: React.FC<HeroBannerComponentProps> = ({ slides= defaultHeroBanner.slides, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides!.length);
    }, interval);

    return () => clearInterval(slideInterval);
  }, [slides, interval]);

  const renderMedia = (slide: BannerSlideType, index:number) => {
    return (
      <div
        key={index}
        className={`${styles.carousel_slide} ${currentIndex === index ? styles.active : ''}`}
        style={{ backgroundImage: slide.imageUrl ? `url(${slide.imageUrl})` : 'none' }}
      >
        {slide.videoUrl && (
          <video className={styles.video} autoPlay loop muted>
            <source src={slide.videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        <div className={styles.overlay} />
        <div className={styles.content}>
          <h2>{slide.title}</h2>
          <p>{slide.text}</p>
          {slide.customContent}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.parallax_banner}>
      <div className={styles.carousel_track} style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {slides!.map((slide, index) => renderMedia(slide, index))}
      </div>
      <div className={styles.controls}>
        {slides!.map((_, index) => (
          <div
            key={index}
            className={`${styles.dot} ${currentIndex === index ? styles.active : ''}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default HeroBannerV2;
