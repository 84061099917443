import React, { useState, useEffect } from 'react';
import styles from './HeroBannerV5.module.scss';
import defaultHeroBanner from '../defaultHeroBanner';
import { HeroBannerComponentProps } from '../HeroBannerComponentProps';
import { BannerSlideType } from '../../../types/Data/BannerSlideType';


const HeroBannerV5: React.FC<HeroBannerComponentProps> = ({ slides = defaultHeroBanner.slides, interval = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides!.length);
    }, interval);

    return () => clearInterval(slideInterval);
  }, [slides, interval]);

  const renderMedia = (slide: BannerSlideType) => {
    if (slide.videoUrl) {
      return (
        <video className={styles.media} autoPlay loop muted>
          <source src={slide.videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
    if (slide.imageUrl) {
      return <img src={slide.imageUrl} alt={slide.title} className={styles.media} />;
    }
    return null;
  };

  return (
    <div className={styles.gradientOverlayBanner}>
      {slides!.map((slide, index) => (
        <div key={index} className={`${styles.slide} ${index === currentIndex ? styles.active : ''}`}>
          {renderMedia(slide)}
          <div className={styles.overlay}></div>
          <div className={styles.content}>
            <h2>{slide.title}</h2>
            <p>{slide.text}</p>
            {slide.buttonText && <a href={slide.buttonUrl} className={styles.button}>{slide.buttonText}</a>}
          </div>
        </div>
      ))}
      <div className={styles.controls}>
        {slides!.map((_, index) => (
          <div
            key={index}
            className={`${styles.dot} ${currentIndex === index ? styles.active : ''}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default HeroBannerV5;
