import { ButtonComponentProps } from "../../../components/Button/ButtonComponentProps";
import ButtonWishListV1 from "./ButtonWishListV1/ButtonWishListV1";



enum ButtonAddToWishlistVersions {
    V1 = "v1",
};

const ButtonAddToWishlistMapping:Record<ButtonAddToWishlistVersions, React.FC<any>> = {
    [ButtonAddToWishlistVersions.V1]: ButtonWishListV1,
};

interface ButtonAddToWishlistComponentProps extends ButtonComponentProps {};

export {
    ButtonAddToWishlistVersions,
    ButtonAddToWishlistMapping
};

export type {
    ButtonAddToWishlistComponentProps
};



