import React from 'react';
import { CustomerMessageHistoryProps } from './CustomerMessageHistoryType';
import { ChatRoomType } from '../../types/Data/ChatRoomType';
import { MessageType } from '../../types/Data/MessageType';

// Example messages for each chat room
const messages: MessageType[] = [
  {
    _id: '1',
    chatRoom: 'chat1',
    sender: 'admin',
    senderModel: 'admin',
    content: 'Hello, how can I assist you with your order?',
    createdAt: new Date('2023-10-25T10:00:00Z'),
  },
  {
    _id: '2',
    chatRoom: 'chat2',
    sender: 'customer',
    senderModel: 'customer',
    content: 'I need help with a return request.',
    createdAt: new Date('2023-10-25T10:05:00Z'),
  },
  {
    _id: '3',
    chatRoom: 'chat3',
    sender: 'admin',
    senderModel: 'admin',
    content: 'Sure, I can guide you through the process.',
    createdAt: new Date('2023-10-25T10:10:00Z'),
  },
];

// Mock chat room data
const customerMessageHistoryDefaultProps: CustomerMessageHistoryProps = {
  chatRooms: [
    {
      _id: 'chat1',
      orderId: '12345',
      participants: ['customer1', 'admin1'],
      messages: [messages[0]], // Last message in chat1
      createdAt: new Date('2023-10-20T09:55:00Z'),
      updatedAt: new Date('2023-10-25T10:00:00Z'),
    },
    {
      _id: 'chat2',
      orderId: '67890',
      participants: ['customer2', 'admin1'],
      messages: [messages[1]], // Last message in chat2
      createdAt: new Date('2023-10-20T09:55:00Z'),
      updatedAt: new Date('2023-10-25T10:05:00Z'),
    },
    {
      _id: 'chat3',
      returnRequestId: '54321',
      participants: ['customer3', 'admin1'],
      messages: [messages[2]], // Last message in chat3
      createdAt: new Date('2023-10-20T09:55:00Z'),
      updatedAt: new Date('2023-10-25T10:10:00Z'),
    },
  ],
};

export { customerMessageHistoryDefaultProps };
