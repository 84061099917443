import React, { useEffect, useState } from "react";
import DashboardHeader from "../../../../components/Header/DashboardHeader/DashboardHeader";
import InputV4 from "../../../../components/Input/InputText/InputV4/InputV4";
import { FaRandom } from "react-icons/fa";
import Select4 from "../../../../components/Select/Select4/Select4";
import InputCalendarV1 from "../../../../components/Input/InputCalendar/InputCalendarV1/InputCalendarV1";
import SwitchV1 from "../../../../components/Switch/SwitchV1/SwitchV1";
import styles from './DiscountCodeInfo.module.scss';
import { DiscountCodeType } from "../../../../types/Data/DiscountCodeType";

interface DiscountCodeFormProps {
    discountCode: DiscountCodeType;
    onDiscountCodeChange: (discountCode: DiscountCodeType) => void;
};

const DiscountCodeInfo:React.FC<DiscountCodeFormProps> = ({discountCode, onDiscountCodeChange=()=>null}) => {

    const generateRandomCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let discountCode = '';
        for (let i = 0; i < 10; i++) {
          discountCode += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return discountCode
    };

    return (
        <div className={styles.discountCodeInfo}>
            <h2>Discount Code Info</h2>
            <InputV4
                label="Name"
                type="text"
                name="name"
                value={discountCode?.name!}
                onChange={(e)=>onDiscountCodeChange({...discountCode, ['name']: e.target.value})}
                required
                wrapperStyle={{width:'100%'}}
            />
            <InputV4
                label="Description"
                type="text"
                name="description"
                value={discountCode?.description}
                onChange={(e)=>onDiscountCodeChange({...discountCode, ['description']: e.target.value})}
                required
                wrapperStyle={{width:'100%'}}
            />
            <div className={styles.row_code}>
                <InputV4
                    label="Code"
                    type="text"
                    name="code"
                    value={discountCode?.code}
                    onChange={(e)=>onDiscountCodeChange({...discountCode, ['code']: e.target.value})}
                    required
                    wrapperStyle={{width:'100%'}}
                />
                <div 
                    className={styles.icon} 
                    onClick={() => {
                        let code = generateRandomCode();
                        onDiscountCodeChange({ ...discountCode!, ['code']: code })
                    }}>
                    <FaRandom/>
                </div>
            </div>
            <InputV4
                label="Value"
                type="number"
                name="value"
                value={discountCode?.value}
                onChange={(e)=>onDiscountCodeChange({...discountCode, ['name']: e.target.value})}
                required
                wrapperStyle={{width:'100%'}}
            />
            <Select4
                label="Type"
                value={discountCode?.type}
                onChange={(value)=>onDiscountCodeChange({...discountCode, ['type']: value})}
                options={[
                    { label: 'Percentage', value: 'percentage' },
                    { label: 'Fixed', value: 'fixed' }
                ]}
                wrapperStyle={{width:'100%'}}
            />
            <div className={styles.row_calendar}>
                <InputCalendarV1
                    label="Start Date"
                    name="startDate"
                    onDateChange={(date)=>onDiscountCodeChange({...discountCode, ['startDate']: date})}
                />
                <InputCalendarV1
                    label="End Date"
                    name="endDate"
                    onDateChange={(date)=>onDiscountCodeChange({...discountCode, ['endDate']: date})}
                />
            </div>
            <SwitchV1
                wrapperStyle={{marginTop: 20}}
                label="Active:"
                isChecked={discountCode.isActive || false}
                onToggle={() => onDiscountCodeChange({...discountCode, ['isActive']: !discountCode.isActive})}
            />
        </div>
       
    )
};

export default DiscountCodeInfo