
import React from 'react';
import styles from './PromotionList.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { PromotionType } from '../../../types/Data/PromotionType';
import PromotionCard from './PromotionCard/PromotionCard';
import { useNavigate } from 'react-router-dom';
import { dispatchLoadPromotions } from '../../DashboardState/promotions/promotionsSlice';
import examplePromotions from '../../DashboardState/promotions/examplePromotions';

interface PromotionListProps {
  promotions: Partial<PromotionType>[];
  onDelete: (id: string) => void;
  onToggleActive: (id: string, isActive: boolean) => void;
}

const PromotionList: React.FC<PromotionListProps> = ({ onDelete, onToggleActive, promotions=[]}) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  
  const handleOnEdit = (id:string) => {
    navigate(`./edit/?promotionId=${id}`)
  };

  dispatch(dispatchLoadPromotions(examplePromotions))
  console.log('promotion', promotions);

  return (
    <div className={styles.promotion_list}>
      {promotions.map((promotion) => (
        <PromotionCard
          key={promotion._id}
          promotion={promotion}
          onEdit={handleOnEdit}
          onDelete={onDelete}
          onToggleActive={onToggleActive}
        />
      ))}
    </div>
  );
};

export default PromotionList;
