import React from "react";
import styles from './ReviewInfo.module.scss';
import { ReviewType, ReviewStatus } from "../../../../types/Data/ReviewType";

interface ReviewInfoProps {
    review?: ReviewType;
}

const ReviewInfo: React.FC<ReviewInfoProps> = ({ review }) => {
    return (
        <div className={styles.details}>
            <h2>Review Info</h2>

            <div className={styles.detailItem}>
                <strong>User Name:</strong> {review?.userName}
            </div>
            <hr className={styles.separator} />
            
            <div className={styles.detailItem}>
                <strong>Product ID:</strong> {review?.productId}
            </div>
            <hr className={styles.separator} />

            <div className={styles.detailItem}>
                <strong>Rating:</strong> {review?.rating} / 5
            </div>
            <hr className={styles.separator} />
            
            {review?.title && (
                <>
                    <div className={styles.detailItem}>
                        <strong>Title:</strong> {review?.title}
                    </div>
                    <hr className={styles.separator} />
                </>
            )}

            <div className={styles.detailItem}>
                <strong>Review Content:</strong> {review?.content}
            </div>
            <hr className={styles.separator} />
            
            <div className={styles.detailItem}>
                <strong>Status:</strong> {review?.status}
            </div>
            <hr className={styles.separator} />
            
            <div className={styles.detailItem}>
                <strong>Review Date:</strong> {new Date(review?.reviewDate!).toLocaleDateString()}
            </div>
            <hr className={styles.separator} />

            {review?.isVerifiedPurchase && (
                <div className={styles.detailItem}>
                    <strong>Verified Purchase:</strong> Yes
                </div>
            )}
            <hr className={styles.separator} />

            {review?.images && review?.images.length > 0 && (
                <div className={styles.detailItem}>
                    <strong>Images:</strong>
                    <ul>
                        {review.images.map((image, index) => (
                            <li key={index}>
                                <a href={image} target="_blank" rel="noopener noreferrer">View Image {index + 1}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {review?.adminResponse && (
                <div className={styles.detailItem}>
                    <strong>Admin Response:</strong> {review.adminResponse}
                </div>
            )}

            {review?.flagged && (
                <div className={styles.detailItem}>
                    <strong>Flagged:</strong> Yes (This review has been flagged for moderation)
                </div>
            )}
        </div>
    );
};

export default ReviewInfo;
