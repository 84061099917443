import { GeneralSettingsType } from "../../../types/Data/ShopSettingsType";

const exampleDataShopSettings: GeneralSettingsType = {
    shopName: 'My Online Store',
    shopDescription: 'Welcome to My Online Store, your one-stop shop for all your needs. We offer a wide range of products at competitive prices.',
    shopLogo: 'https://example.com/logo.png', 
    contactEmail: 'support@myonlinestore.com',
    contactPhone: '+1234567890',
    address: '123 Main Street, Anytown, USA',
    defaultCurrency: 'USD',
    supportedCurrencies: ['USD', 'EUR', 'GBP'],
    timeZone: 'America/New_York',
    language: 'en',
    emailFromAddress: 'no-reply@myonlinestore.com',
    metaTitle: 'My Online Store - Best Products at Best Prices',
    metaDescription: 'Shop at My Online Store for the best products at the best prices. Fast shipping and excellent customer service.',
    metaKeywords: ['online store', 'shop', 'buy', 'products', 'best prices'],
};

export default exampleDataShopSettings