import React, { useState } from 'react';
import styles from './FileList.module.scss';
import { ImageType } from '../../../types/Data/ImageType';

interface FileListProps<T extends File | ImageType> {
  title?: string;
  files: T[]; // Generic type for files
  setFiles: (files: T[]) => void; // Setter will use the same type as files
}

const FileList = <T extends File | ImageType>({ files, setFiles, title }: FileListProps<T>) => {
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  // Type guard to differentiate between File and ImageType
  const isFile = (file: File | ImageType): file is File => {
    return (file as File).name !== undefined;
  };

  const handleDragStart = (index: number) => {
    setDraggedIndex(index);
  };

  const handleDragEnter = (index: number) => {
    if (draggedIndex === null) return;

    const updatedFiles = [...files];
    const [draggedFile] = updatedFiles.splice(draggedIndex, 1);
    updatedFiles.splice(index, 0, draggedFile);
    setDraggedIndex(index);
    setFiles(updatedFiles); // Use the generic type T for setFiles
  };

  const handleDragEnd = () => {
    setDraggedIndex(null);
  };

  const handleDelete = (index: number) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles); // Use the generic type T for setFiles
  };

  return (
    <div className={styles.fileList}>
      {title && <h4>{title}</h4>}
      {files.map((file, index) => {
        const fileName = isFile(file) ? file.name : (file as ImageType).url.split('/').pop();
        const fileUrl = isFile(file) ? URL.createObjectURL(file) : (file as ImageType).url;

        return (
          <div
            key={index}
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragEnter={() => handleDragEnter(index)}
            onDragEnd={handleDragEnd}
            className={`${styles.fileItem} ${draggedIndex === index ? styles.dragging : ''}`}
          >
            <div className={styles.imagePreview}>
              <img src={fileUrl} alt={fileName || 'File'} />
            </div>
            <div className={styles.fileDetails}>
              <span className={styles.fileName}>{fileName}</span>
              <button onClick={() => handleDelete(index)} className={styles.deleteButton}>
                Delete
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FileList;
