import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartData, ChartOptions } from 'chart.js';
import styles from './ChartOrderByRegionBreakdown.module.scss';
import { OrderType } from '../../../../types/Data/OrderType';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Store/store';
import { getSalesByRegion } from '../../../../api/analytic/analyticApi';

interface OrderSByRegionistributionProps {
}

const ChartOrderByRegionBreakdown: React.FC<OrderSByRegionistributionProps> = ({ }) => {
    const orders = useSelector((state:RootState)=>state.dashboard.orders.orders) as OrderType[]
    
    const [analytic, setAnalytic] = useState();

    const getOrdeCountByRegion = () => {
        const counts: { [key: string]: number } = {};
        orders.forEach(order => {
            counts[order.shippingAddress.country!] = (counts[order.shippingAddress!.country!] || 0) + 1;
        });
        return counts;
    };

    const orderCounts = getOrdeCountByRegion();
    const statusLabels = Object.keys(orderCounts);
    const statusData = Object.values(orderCounts);

    const data: ChartData<'pie'> = {
        labels: statusLabels,
        datasets: [
            {
                label: 'Order Status',
                data: statusData,
                backgroundColor: [
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                    'rgba(255, 159, 64, 0.6)',
                    'rgba(255, 205, 86, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(255, 205, 86, 1)',
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
                hoverBackgroundColor: [
                    'rgba(75, 192, 192, 0.8)',
                    'rgba(153, 102, 255, 0.8)',
                    'rgba(255, 159, 64, 0.8)',
                    'rgba(255, 205, 86, 0.8)',
                    'rgba(54, 162, 235, 0.8)',
                ],
            },
        ],
    };

    const options: ChartOptions<'pie'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    color: 'white', // Legend text color
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.raw !== null) {
                            label += context.raw as number;
                        }
                        return label;
                    },
                },
            },
        },
    };

    /*
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getSalesByRegion();
                setAnalytic(result);
            } catch (error: any) {
                console.error('Error fetching sales by region evolution:', error.message);
            }
        };
        fetchData();
    }, []);

    */
   
    return (
        <div className={styles.container}>
            <h3>Order By Region Breakdown</h3>
            <div className={styles.chart_container}>
                <Pie data={data} options={options} />
            </div>
        </div>
        
    );
};

export default ChartOrderByRegionBreakdown;
