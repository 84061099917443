import { OrderType } from "../../types/Data/OrderType";
import CustomerOrderDetailV1 from "./CustomerOrderDetailV1/CustomerOrderDetailV1";

enum CustomerOrderDetailtComponentVersions {
    V1 = "v1",
};

const CustomerOrderDetailComponentMapping:Record<CustomerOrderDetailtComponentVersions, React.FC<any>> = {
    [CustomerOrderDetailtComponentVersions.V1]: CustomerOrderDetailV1,  
};

interface CustomerOrderDetailComponentProps {
    order?: Partial<OrderType>
};

export {
    CustomerOrderDetailtComponentVersions,
    CustomerOrderDetailComponentMapping
};

export type {
    CustomerOrderDetailComponentProps
};