import axios, { AxiosResponse } from 'axios';
import { AdminType, AdminPermissionsType } from '../../types/Data/AdminType';
import { ApiResponse } from '../apiType';
import api from '../config';

// Create a New Admin
export const createAdmin = async (data: Partial<AdminType>): Promise<AxiosResponse<ApiResponse<AdminType>>> => {
    try {
        return await api.post('/admins', data);
    } catch (error) {
        console.error('Error creating admin:', error);
        throw error;
    }
};

// Update an Admin by ID
export const updateAdmin = async (id: string, data: Partial<AdminType> & { newPassword?: string }): Promise<AxiosResponse<ApiResponse<AdminType>>> => {
    try {
        return await api.put(`/admins/${id}`, data);
    } catch (error) {
        console.error(`Error updating admin with ID ${id}:`, error);
        throw error;
    }
};

// Get an Admin by ID
export const getAdminById = async (id: string): Promise<AxiosResponse<ApiResponse<AdminType>>> => {
    try {
        return await api.get(`/admins/${id}`);
    } catch (error) {
        console.error(`Error retrieving admin with ID ${id}:`, error);
        throw error;
    }
};

// Delete an Admin by ID
export const deleteAdmin = async (id: string): Promise<AxiosResponse<ApiResponse<null>>> => {
    try {
        return await api.delete(`/admins/${id}`);
    } catch (error) {
        console.error(`Error deleting admin with ID ${id}:`, error);
        throw error;
    }
};

// Get All Admins with optional filters, pagination, and sorting
export const getAllAdmins = async (params: any = {}): Promise<AxiosResponse<ApiResponse<{ admins: AdminType[]; total: number; pages: number }>>> => {
    try {
        return await api.get('/admins', { params });
    } catch (error) {
        console.error('Error fetching all admins:', error);
        throw error;
    }
};

// Update Admin Permissions by ID
export const updateAdminPermissions = async (id: string, permissions: Partial<AdminPermissionsType>): Promise<AxiosResponse<ApiResponse<AdminType>>> => {
    try {
        return await api.patch(`/admins/${id}/permissions`, { permissions });
    } catch (error) {
        console.error(`Error updating permissions for admin with ID ${id}:`, error);
        throw error;
    }
};

// Set Admin Active Status by ID
export const setAdminActiveStatus = async (id: string, isActive: boolean): Promise<AxiosResponse<ApiResponse<AdminType>>> => {
    try {
        return await api.patch(`/admins/${id}/status`, { isActive });
    } catch (error) {
        console.error(`Error updating active status for admin with ID ${id}:`, error);
        throw error;
    }
};

// Get Admin by Email
export const getAdminByEmail = async (email: string): Promise<AxiosResponse<ApiResponse<AdminType>>> => {
    try {
        return await api.get(`/admins/email/${email}`);
    } catch (error) {
        console.error(`Error retrieving admin with email ${email}:`, error);
        throw error;
    }
};

// Check if Admin Exists by ID
export const doesAdminExist = async (id: string): Promise<AxiosResponse<ApiResponse<{ exists: boolean }>>> => {
    try {
        return await api.get(`/admins/exists/${id}`);
    } catch (error) {
        console.error(`Error checking if admin exists with ID ${id}:`, error);
        throw error;
    }
};
