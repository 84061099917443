import React, { ChangeEvent } from "react";
import styles from './InputDurationV1.module.scss';
import { IoTime } from "react-icons/io5";
import { InputDurationComponentProps } from "../../InputComponentProps";

const InputDurationV1: React.FC<InputDurationComponentProps> = ({ placeholder = "Duration", onChange }) => {
    
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && onChange) {
            onChange(value);
        }
    };

    return (
        <div className={styles.input_wrapper}>
            <div className={styles.icon_container}>
                <IoTime />
            </div>
            <input
                className={styles.input}
                onChange={handleChange}
                type="number"
                placeholder={placeholder}
                min="0"
            />
        </div>
    );
};

export default InputDurationV1;