import { FaqType } from "../../../types/Data/FaqType";

const faqDataExample: FaqType[] = [
    {
        id: "1",
        question: "What is your return policy?",
        answer: "Our return policy allows you to return products within 30 days of purchase. To be eligible for a return, the item must be unused and in the same condition that you received it, along with the original packaging."
    },
    {
        id: "2",
        question: "How long does shipping take?",
        answer: "Shipping typically takes 3-5 business days for domestic orders. International shipping times may vary depending on the destination and customs processing. You will receive a tracking number once your order has been shipped."
    },
    {
        id: "3",
        question: "Do you offer customer support?",
        answer: "Yes, we offer 24/7 customer support. You can reach us via email, phone, or live chat on our website. Our team is here to assist you with any questions or issues you may have."
    },
    {
        id: "4",
        question: "Can I track my order?",
        answer: "Yes, once your order has been shipped, you will receive a tracking number via email. You can use this number to track your order on our website or the courier's tracking page."
    },
    {
        id: "5",
        question: "What payment methods do you accept?",
        answer: "We accept all major credit cards, PayPal, Apple Pay, and Google Pay. We also offer financing options through our partner services at checkout."
    },
    {
        id: "6",
        question: "How can I cancel or change my order?",
        answer: "You can cancel or change your order within 1 hour of placing it. After this period, we may have already started processing your order. Please contact our customer support as soon as possible to request any changes."
    },
    {
        id: "7",
        question: "Are your products covered under warranty?",
        answer: "Yes, all of our products come with a 1-year warranty that covers manufacturing defects. If you experience any issues with your product, please contact us to initiate a warranty claim."
    }
];

export {
    faqDataExample
}