import React, { useEffect, useState } from "react";
import styles from './TicketsPage.module.scss';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';
import TableInteractive from '../../../components/Tableau/TableInteractive/TableInteractive';
import { SupportTicketCategory, SupportTicketStatus, SupportTicketType } from "../../../types/Data/SupportTicketType";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../Store/store";
import Filters from "./TicketFilter/TicketFilter";
import { useNavigate } from "react-router-dom";
import { fetchAllSupportTickets } from "../../DashboardState/tickets/ticketsSlice";
import SupportTicketList from "./TicketList/TicketList";

const TicketsPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    const [searchTerm, setSearchTerm] = useState('');
    const [category, setCategory] = useState<SupportTicketCategory | undefined>(undefined);
    const [status, setStatus] = useState<SupportTicketStatus | undefined>(undefined);

    const navigate = useNavigate();
    const tickets = useSelector<RootState>((state)=>state.dashboard.tickets.tickets) as SupportTicketType[];

    const labels = ['subject', 'email', 'category', 'orderNumber', 'status']; // Column headers

    const handleActionButtonClick = (ticket: SupportTicketType) => {
        navigate(`/dashboard/support/tickets/${ticket._id}`);
    };

    /*
    useEffect(()=> {
        dispatch(fetchAllSupportTickets({searchTerm, category, status}))
    }, [searchTerm, category, status]);
    */

    return (
        <div className={styles.ticketsPage}>
            <DashboardHeader title="Support Tickets" />
            <Filters
                searchTerm={searchTerm}
                category={category}
                status={status}
                onSearch={(search)=>setSearchTerm(search)}
                onCategoryChange={(category)=>setCategory(category)}
                onStatusChange={(status)=>setStatus(status)}
            />
            <SupportTicketList/>
        </div>
    );
};

export default TicketsPage;
