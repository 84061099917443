import React from 'react';
import styles from './CategoryCard.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { CategoryType } from '../../../types/Data/CategoryType';
import ButtonEdit from '../../../components/Button/ButtonEdit/ButtonEdit';
import ButtonDelete from '../../../components/Button/ButtonDelete/ButtonDelete';
import ButtonV1 from '../../../components/Button/ButtonV1/ButtonV1';

interface CategoryCardProps {
  category: CategoryType;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onView: (id: string) => void;
}

const CategoryCard: React.FC<CategoryCardProps> = ({ category, onEdit, onDelete, onView }) => {
  
  const subCategories = useSelector<RootState>((state) => state.dashboard.categories.categories.filter((item) => item.parentCategoryId === category._id)) as CategoryType[]
  return (
    <div className={styles.categoryCard} onClick={() => onView(category._id!)}>
      <div className={styles.cardHeader}>
        {category.isActive && <img src={category.image?.url} alt={category.name} className={styles.categoryImage} />}
        <div>
          <h3>{category.name}</h3>
          <p>{category.description}</p>
        </div>
        <div className={styles.actions}>
          <button className={styles.buttonEdit} title='Edit'  onClick={() => onEdit(category._id!)}>Edit</button>
          <button className={styles.buttonDelete} onClick={() => onDelete(category._id!)}>Delete</button>
        </div>
      </div>
      {subCategories && subCategories.length > 0 && (
        <div className={styles.subCategories}>
          <h4>Subcategories</h4>
          <ul>
            {subCategories.map((sub) => (
              <li key={sub._id} className={styles.subCategoryItem}>
                {sub.image && <img src={sub.image.url} alt={sub.name} className={styles.subCategoryImage} />}
                <span>{sub.name}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CategoryCard;
