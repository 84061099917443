import React, { useState } from 'react';
import styles from './MessagingV1.module.scss';
import { IoSend } from 'react-icons/io5';

interface ChatMessageType {
  sender: 'Admin' | 'Customer';
  message: string;
  timestamp: Date;
}

interface ReturnAdminNoteProps {
  chatHistory: ChatMessageType[];
  onSendMessage: (message: string) => void;
  wrapperStyle?: React.CSSProperties;
  title?: string;
}

const ReturnAdminNote: React.FC<ReturnAdminNoteProps> = ({
  chatHistory,
  onSendMessage,
  wrapperStyle,
  title,
}) => {
  const [newMessage, setNewMessage] = useState('');

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      onSendMessage(newMessage.trim());
      setNewMessage('');
    }
  };

  return (
    <div className={styles.container} style={{...wrapperStyle}}>
      {title && <h2>{title}</h2>}
      <div className={styles.chatHistory}>
        {chatHistory.map((chat, index) => (
          <div
            key={index}
            className={`${styles.chatMessage} ${
              chat.sender === 'Admin' ? styles.adminMessage : styles.customerMessage
            }`}
          >
            <div className={styles.chatMessageHeader}>
              <strong>{chat.sender}</strong> - {chat.timestamp.toLocaleString()}
            </div>
            <div className={styles.chatMessageBody}>{chat.message}</div>
          </div>
        ))}
      </div>

      <div className={styles.newMessageContainer}>
        <textarea
          className={styles.newMessageTextarea}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message here..."
          rows={3}
        />
        <button className={styles.sendButton} onClick={handleSendMessage}>
          <IoSend/>
        </button>
      </div>
    </div>
  );
};

export default ReturnAdminNote;
