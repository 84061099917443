import React, { useState } from 'react';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import styles from './ProductReviewV3.module.scss';
import { defaultProductReviewsProps } from '../defaultProductReviewProps';
import { ProductReviewComponentProps } from '../ProductReviewComponentProps';

const ProductReviewV3:React.FC<ProductReviewComponentProps> = ({ reviews = defaultProductReviewsProps }) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const toggleExpand = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className={styles.listContainer}>
      {reviews.map((review, index) => (
        <div key={index} className={styles.reviewItem}>
          <div className={styles.reviewSummary} onClick={() => toggleExpand(index)}>
            <h3>{review.userId}</h3>
            <p className={styles.rating}>Rating: {review.rating} / 5</p>
            <span className={styles.toggleIcon}>
              {expandedIndex === index ? <IoChevronUp /> : <IoChevronDown />}
            </span>
          </div>
          {expandedIndex === index && (
            <div className={styles.reviewDetails}>
              <p>{review.content}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProductReviewV3;
