// CustomerSecurity.tsx

import React from 'react';
import styles from './CustomerSecurity.module.scss';
import { CustomerType } from '../../../../types/Data/CustomerType';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Store/store';
import { AccountType } from '../../../../types/Data/AccountType';
import {
  FaKey,
  FaCheckCircle,
  FaTimesCircle,
  FaLock,
  FaClock,
  FaDesktop,
  FaGlobe,
} from 'react-icons/fa';

interface SecurityInfoProps {
  customer: CustomerType;
}

const CustomerSecurity: React.FC<SecurityInfoProps> = ({ customer }) => {
  const account = useSelector<RootState>(
    (state) =>
      state.dashboard.accounts.accounts.find((account) => account._id === customer.accountId)
  ) as AccountType | undefined;

  if (!account) {
    return (
      <div className={styles.security_info}>
        <h2>Security Information</h2>
        <p>No account information available.</p>
      </div>
    );
  }

  const formatDate = (dateString: string | Date | undefined): string => {
    if (!dateString) return 'N/A';
    try {
      const date = typeof dateString === 'string' ? new Date(dateString) : dateString;
      return date.toISOString().substring(0, 10); // e.g., 'Aug 29, 2024, 5:24 PM'
    } catch {
      return 'Invalid Date';
    }
  };

  const handleResetPassword = () => {
    // Implement password reset logic
    alert('Password reset initiated.');
  };

  const handleToggleTwoFactor = () => {
    // Implement logic to enable/disable two-factor authentication
    alert('Two-factor authentication toggled.');
  };

  const handleVerifyAccount = () => {
    // Implement account verification logic
    alert('Account verification initiated.');
  };

  return (
    <div className={styles.security_info}>
      <div className={styles.header}>
        <h2>Security Information</h2>
        <div className={styles.actions}>
          <button onClick={handleResetPassword}>Reset Password</button>
          <button onClick={handleToggleTwoFactor}>
            {account.isTwoFactorEnabled ? 'Disable' : 'Enable'} Two-Factor Auth
          </button>
          {!account.isVerified && (
            <button onClick={handleVerifyAccount}>Verify Account</button>
          )}
        </div>
      </div>

      <div className={styles.info_list}>
        <div className={styles.info_item}>
          <span className={styles.label}>
            <FaKey /> Last Password Reset:
          </span>
          <span className={styles.value}>{formatDate(account.lastPasswordReset)}</span>
        </div>
        <div className={styles.info_item}>
          <span className={styles.label}>
            <FaCheckCircle /> Account Verified:
          </span>
          <span
            className={`${styles.value} ${
              account.isVerified ? styles.verified : styles.notVerified
            }`}
          >
            {account.isVerified ? 'Yes' : 'No'}
          </span>
        </div>
        <div className={styles.info_item}>
          <span className={styles.label}>
            <FaLock /> Two-Factor Authentication:
          </span>
          <span
            className={`${styles.value} ${
              account.isTwoFactorEnabled ? styles.enabled : styles.disabled
            }`}
          >
            {account.isTwoFactorEnabled ? 'Enabled' : 'Disabled'}
          </span>
        </div>
        <div className={styles.info_item}>
          <span className={styles.label}>
            <FaClock /> Last Login Date:
          </span>
          <span className={styles.value}>{formatDate(account.lastLoginDate)}</span>
        </div>
        <div className={styles.info_item}>
          <span className={styles.label}>
            <FaDesktop /> Common Devices Used:
          </span>
          <span className={styles.value}>
            {Array.isArray(account.commonDevices) && account.commonDevices.length > 0 ? (
              <ul className={styles.list}>
                {account.commonDevices.map((device, index) => (
                  <li key={index}>{device}</li>
                ))}
              </ul>
            ) : (
              'N/A'
            )}
          </span>
        </div>
        <div className={styles.info_item}>
          <span className={styles.label}>
            <FaGlobe /> IP Address History:
          </span>
          <span className={styles.value}>
            {Array.isArray(account.ipAddressHistory) &&
            account.ipAddressHistory.length > 0 ? (
              <ul className={styles.list}>
                {account.ipAddressHistory.map((ip, index) => (
                  <li key={index}>{ip}</li>
                ))}
              </ul>
            ) : (
              'N/A'
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CustomerSecurity;
