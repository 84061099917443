import React, { useState } from 'react';
import styles from './CancellationDetails.module.scss';
import { useParams } from 'react-router-dom';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';
import CancellationInfo from './CancellationInfo/CancellattionInfo';
import CancelledItemsTable from './CancelledItemsTable/CancelledItemsTable';
import EmailSender from '../../../components/EmailEditor/EmailEditorV2/EmailEditorV2';
import FormRefuseRefund from '../../../components/Form/FormRefuseRefund/FormRefuseRefund';
import FormAcceptRefund from '../../../components/Form/FormAcceptRefund/FormAcceptRefund';
import FormProcessRefund from '../../../components/Form/FormProcessRefund/FormProcessRefund';
import { CancellationType } from '../../../types/Data/CancellationType';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import CreateRefund from './CreateRefund/CreateRefund';
import RefuseCancellation from './RefuseCancellation/RefuseCancellation';


interface CancellationDetailsProps {
    onRefund?: (cancellationId: number | string) => void;
    onRefuse?: (cancellationId: number | string) => void;
    onSendEmail?: (cancellationId: number | string, message: string) => void;
}

const CancellationDetails: React.FC<CancellationDetailsProps> = ({ onRefund, onRefuse, onSendEmail }) => {
    const { cancellationId } = useParams<{cancellationId: string}>();
    
    const cancellation = useSelector<RootState>((state) => state.dashboard.cancellations.cancellations.find(cancellation =>  cancellation._id === cancellationId)) as CancellationType;
    const [emailMessage, setEmailMessage] = useState('');

    const handleRefund = () => {
        if (onRefund) {
            onRefund(cancellation._id);
        }
    };

    const handleRefuse = () => {
        if (onRefuse) {
            onRefuse(cancellation._id);
        }
    };

    const handleSendEmail = () => {
        if (onSendEmail) {
            onSendEmail(cancellation._id, emailMessage);
        }
        setEmailMessage('');
    };

    return (
        <div className={styles.cancellationDetails}>
            <DashboardHeader title='Cancellation Details'/>
            <CancellationInfo cancellation={cancellation}/>
            <CancelledItemsTable items={cancellation?.items || []} onRefundSelectionChange={()=>null}/>
            <CreateRefund cancellation={cancellation} onValidate={()=>null}/>
            <RefuseCancellation/>
            <EmailSender/>
        </div>
    );
};

export default CancellationDetails;
