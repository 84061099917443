import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import exampleDataShippingMethod from './exampleDataShippingMethod';
import { ShippingMethodType, ShippingSettingsType } from '../../../types/Data/ShippingSettingsType';
import { getAllShippingMethods } from '../../../api/shippingMethod/shippingMethodApi';


// Async Thunks
export const fetchShippingMethods = createAsyncThunk(
  'refund/fetchShippingMethods',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllShippingMethods();
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

interface shippingMethodsStateType {
  shippingMethods: ShippingMethodType[],
  loading: boolean,
  error: string | null,
};

const initialState: shippingMethodsStateType = {
  shippingMethods: [...exampleDataShippingMethod],
  loading: false,
  error: null,
};

const shippingMethodsSlice = createSlice({
  name: 'shippingMethods',
  initialState,
  reducers: {
    dispatchAddShippingMethod(state, action: PayloadAction<ShippingMethodType>) {
      state.shippingMethods.push(action.payload);
    },
    dispatchUpdateShippingMethod(state, action: PayloadAction<ShippingMethodType>) {
      const index = state.shippingMethods.findIndex(method => method.name === action.payload.name);
      if (index !== -1) {
        state.shippingMethods[index] = action.payload;
      }
    },
    dispatchDeleteShippingMethod(state, action: PayloadAction<string>) {
      state.shippingMethods = state.shippingMethods.filter(method => method.name !== action.payload);
    },
    displatchLoadShippingMethods(state, action: PayloadAction<ShippingMethodType[]>) {
      state.shippingMethods = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShippingMethods.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShippingMethods.fulfilled, (state, action: PayloadAction<ShippingMethodType[]>) => {
        state.loading = false;
        state.shippingMethods = action.payload;
      })
      .addCase(fetchShippingMethods.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
  }
});

export const {
  dispatchAddShippingMethod,
  dispatchUpdateShippingMethod,
  dispatchDeleteShippingMethod,
  displatchLoadShippingMethods,
} = shippingMethodsSlice.actions;

export default shippingMethodsSlice.reducer;
