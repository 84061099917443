import React from "react";
import styles from "./RecentNotification.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/store";
import { NotificationType } from "../../../types/Data/NotificationType";

interface NotificationsProps {
};

const RecentNotification: React.FC<NotificationsProps> = ({ }) => {
  
    const notifications = useSelector((state:RootState) => state.dashboard.notifications.notifications) as NotificationType[]
    const error = useSelector((state:RootState) => state.dashboard.notifications)
    const loading = useSelector((state:RootState) => state.dashboard.notifications)

    return (
        <div className={styles.notifications}>
        <h3 className={styles.title}>Notifications</h3>
        <ul>
            {notifications.map((notification) => (
            <li key={notification._id} className={styles[notification.type]}>
                <div className={styles.message}>{notification.description}</div>
                <div className={styles.time}>{notification.createdAt.toDateString()}</div>
            </li>
            ))}
        </ul>
        </div>
  );
};

export default RecentNotification;