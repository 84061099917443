import React, { useState, useRef, useEffect } from "react";
import styles from './InputCalendarV1.module.scss';
import Calendar2 from "../../../Calendar/CalendarV2/CalendarV2";
import { IoCalendar } from "react-icons/io5";
import { InputCalendarComponentProps } from "../../InputComponentProps";

const InputCalendarV1: React.FC<InputCalendarComponentProps> = ({ onDateChange = () => null, label, ...props }) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const calendarRef = useRef<HTMLDivElement>(null);

    const handleDateClick = (date: Date) => {
        setSelectedDate(date);
        setShowCalendar(false);
        if (onDateChange) {
            onDateChange(date);
        }
    };

    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
            setShowCalendar(false);
        }
    };

    useEffect(() => {
        if (showCalendar) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showCalendar]);

    return (
        <div className={styles.calendarInputContainer} ref={calendarRef}>
            {label && <p className={styles.label}>{label}</p>}
            <div className={styles.input_wrapper}>
                <div className={styles.icon_container}>
                    <IoCalendar />
                </div>
                <input
                    type="text"
                    value={selectedDate ? selectedDate.toLocaleDateString() : ''}
                    onClick={toggleCalendar}
                    readOnly
                    className={styles.dateInput}
                    {...props}
                />
            </div>
            {showCalendar && (
                <div className={`${styles.calendarPopup} ${showCalendar ? styles.show : ''}`}>
                    <Calendar2 onClick={handleDateClick} selectedDate={selectedDate} />
                </div>
            )}
        </div>
    );
};

export default InputCalendarV1;
