import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IoRadioButtonOff, IoRadioButtonOn } from 'react-icons/io5';
import styles from './ProductPictureV1.module.scss';
import { RootState } from '../../../Store/store';
import { ProductType } from '../../../types/Data/ProductType';

interface ProductPickerProps {
  selectedProductsId?: string[]; // Array of selected product IDs
  onSelectProduct: (productId: string) => void;
}

const ProductPickerV1: React.FC<ProductPickerProps> = ({ selectedProductsId, onSelectProduct }) => {
  const products = useSelector((state: RootState) => state.dashboard.products.products) as ProductType[];
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = products.filter((product) => 
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product?.tags?.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className={styles.productPictureComponent}>
      <input 
        type="text"
        placeholder="Search products..."
        value={searchTerm}
        onChange={handleSearch}
        className={styles.searchBar}
      />
      <div className={styles.productList}>
        {filteredProducts.map((product) => (
          <div key={product._id!} className={styles.productCard} onClick={() => onSelectProduct(product._id!)}>
            <img src={product?.images![0].url} alt={product.name} className={styles.productImage} />
            <div className={styles.productInfo}>
              <h3>{product.name}</h3>
              <p>{product.description}</p>
            </div>
            {selectedProductsId?.includes(product._id!) ? (
              <IoRadioButtonOn className={styles.selectedIcon} />
            ) : (
              <IoRadioButtonOff className={styles.unselectedIcon} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductPickerV1;
