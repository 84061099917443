import React, { useEffect, useState } from 'react';
import styles from './PaymentMethod.module.scss';
import Select4 from '../../../../components/Select/Select4/Select4';
import { PaymentStatus } from '../../../../types/Data/OrderType';

interface PaymentMethodProps {
    onPaymentChange: (paymentData: { method: string; provider?: string; transactionId?: string; status: PaymentStatus }) => void;
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({ onPaymentChange }) => {
    const [paymentMethod, setPaymentMethod] = useState('');
    const [paymentProvider, setPaymentProvider] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(PaymentStatus.PENDING);

    const paymentMethodOptions = [ { label: "Credit Card", value: "credit card" }, { label: "Paypal", value: "paypal" }, { label: "Bank Transfer", value: "bank transfer" }, { label: "Cash on Delivery", value: "cash on delivery" } ];
    const paymentStatusOptions = Object.values(PaymentStatus).map((status) => ({label: status, value: status}))

    useEffect(() => {
        onPaymentChange({
            method: paymentMethod,
            provider: paymentProvider,
            transactionId,
            status: paymentStatus,
        });
    }, [paymentMethod, paymentProvider, transactionId, paymentStatus])
    return (
        <div className={styles.paymentMethodForm}>
            <h4>Payment Method</h4>

            <div className={styles.formGroup}>
                <Select4 
                    label="Payment Method"
                    options={paymentMethodOptions} 
                    value={paymentMethod}
                    onChange={(value) => setPaymentMethod(value)}
                />
            </div>

            {paymentMethod && (
                <div className={styles.formGroup}>
                    <label htmlFor="paymentProvider">Payment Provider (optional)</label>
                    <input
                        type="text"
                        id="paymentProvider"
                        value={paymentProvider}
                        onChange={(e) => setPaymentProvider(e.target.value)}
                        placeholder="e.g., Stripe, PayPal"
                    />
                </div>
            )}

            <div className={styles.formGroup}>
                <label htmlFor="transactionId">Transaction ID (if applicable)</label>
                <input
                    type="text"
                    id="transactionId"
                    value={transactionId}
                    onChange={(e) => setTransactionId(e.target.value)}
                    placeholder="Enter Transaction ID"
                />
            </div>

            <div className={styles.formGroup}>
                <Select4
                    label="Payment Status"
                    options={paymentStatusOptions}
                    value={paymentStatus}
                    onChange={(value) => setPaymentStatus(value as PaymentStatus)}
                />
            </div>
        </div>
    );
};

export default PaymentMethod;
