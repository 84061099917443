import React from 'react';
import styles from './OrderCoupon.module.scss';

interface Coupon {
    couponCode: string;
    discountAmount: number;
}

interface CouponDisplayProps {
    coupon: Coupon;
}

const OrderCoupon: React.FC<CouponDisplayProps> = ({ coupon }) => {
    return (
        <div className={styles.couponContainer}>
            <div className={styles.couponCode}>
                <h3>{coupon.couponCode}</h3>
                <span>Coupon Code</span>
            </div>
            <div className={styles.discountAmount}>
                <h3>- ${coupon.discountAmount.toFixed(2)}</h3>
                <span>Discount</span>
            </div>
        </div>
    );
};

export default OrderCoupon;
