import ProductRatingV1 from "./ProductRatingV1/ProductRatingV1";

enum ProductRattingVersions {
    V1 = "v1",
};

const ProductRatingMapping:Record<ProductRattingVersions, React.FC<any>> = {
    [ProductRattingVersions.V1]: ProductRatingV1,
};

interface ProductRatingComponentProps {
    rating?: number;
    maxStars?: number;
};

export {
    ProductRatingMapping,
    ProductRattingVersions 
};

export type {
    ProductRatingComponentProps
}