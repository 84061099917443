import React, { ReactElement } from 'react';
import { FaStar, FaStarHalf } from 'react-icons/fa';

interface ReviewStarsProps {
    rating: number;
}

const ReviewStars: React.FC<ReviewStarsProps> = ({ rating }) => {
    const stars = (): ReactElement[] => {
        const icons: ReactElement[] = [];
        const average = rating ?? 0;
        const int = Math.trunc(average);
        const dec = average - int;

        for (let i = 0; i < int; i++) {
            icons.push(<FaStar key={`star-full-${i}`} className="stars" />);
        }

        if (dec >= 0.75) {
            icons.push(<FaStar key="star-full-dec" className="stars" />);
        } else if (dec >= 0.25) {
            icons.push(<FaStarHalf key="star-half-dec" className="stars" />);
        } else if (dec > 0) {
            icons.push(<FaStarHalf key="star-half-dec" className="stars" />);
        } else if (int === 0 && dec > 0) {
            icons.push(<FaStarHalf key="star-half-default" className="stars" />);
        }

        return icons;
    };

    return <div>{stars()}</div>;
};

export default ReviewStars;
