import React, { useEffect, useState } from "react";
import DashboardHeader from "../../components/Header/DashboardHeader/DashboardHeader";

import styles from './ImagesPage.module.scss'
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store/store";
import ImagesList from "./ImagesList/ImagesList";
import { ImageType } from "../../types/Data/ImageType";
import { dispatchLoadImages } from "../DashboardState/images/imagesSlice";
import ImagesFilters from "./ImagesFilter/ImagesFilter";
import { useNavigate } from "react-router-dom";


const ImagesPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const images = useSelector((state: RootState) => state.dashboard?.images?.images) as ImageType[];
    const loading = useSelector<RootState, boolean>((state) => state.dashboard?.images?.loading);
    const error = useSelector<RootState, string | null>((state) => state.dashboard?.images?.error);

    const [query, setQuery] = useState({
        search: "",
        category: "",
    });

    const [page, setPage] = useState<number>(1); // Separate page state
    

    const handleFilterChange = (key: string, value: any) => {
        setQuery((prevQuery) => ({ ...prevQuery, [key]: value }));
        setPage(1); // Reset page to 1 when query changes
    };
    
    /*
    useEffect(() => {
        dispatch(fetchReviews({ ...query, page }));
    }, [query]);
    */
    
    const handleImageClick = (id: string) => {
        navigate(`./edit/${id}`)
    };


    return (
        <div>
            <DashboardHeader title="Images List"/>
            <div className={styles.content}>
                <ImagesFilters query={query} onQueryChange={handleFilterChange}/>
                <ImagesList images={images} onImageClick={handleImageClick}/>
            </div>
        </div>
    )
};

export default ImagesPage