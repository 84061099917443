import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from './EditPromotion.module.scss';
import DashboardHeader from "../../../components/Header/DashboardHeader/DashboardHeader";
import { ProductType } from "../../../types/Data/ProductType";
import { PromotionGenre, PromotionType } from "../../../types/Data/PromotionType";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Store/store";
import ProductPromotionTable from "./ProductPromotionTable/ProductPromotionTable";
import PromotionConditionForm from "./EditPromotionCondition/PromotionConditionForm";
import GeneralPromotionInfoForm from "./GeneralPromotionInfoForm/GeneralPromotionInfoForm";
import SaveButton from "../../../components/Button/SaveButton/SaveButton";
import ButtonCancel from "../../../components/Button/ButtonCancel/ButtonCancel";
import { createPromotion, getPromotionById, updatePromotion } from "../../../api/promotion/promotionApi";
import { handleApiError } from "../../../api/apiError";
import LoadingIndicatorV1 from "../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1";
import ErrorBannerV1 from "../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1";

const products: ProductType[] = [
    // Sample product data
];

interface EditPromotionProps {
}

const EditPromotion: React.FC<EditPromotionProps> = () => {
    const dispatch = useDispatch();

    const location = useLocation();
    const promotionId = new URLSearchParams(location.search).get('promotionId');
    
    const promotionToEdit = useSelector<RootState>((state)=>state.dashboard.promotions.promotions.find(el=>el._id === promotionId)) as PromotionType;

    const defaultPromotion:PromotionType = {
        name: '',
        description: '',
        promotionType: PromotionGenre.PercentageDiscount,
        startDate: new Date(),
        endDate: new Date(),
        isActive: true,
        conditions:undefined,
        products: [],
        priority: 1,
        isCumulative: false,
    }
    
    const initialPromotion:PromotionType = promotionToEdit || defaultPromotion
    
    const [originalPromotion, setOriginalPromotion] = useState<PromotionType>(initialPromotion);
    const [currentPromotion, setCurrentPromotion] = useState<PromotionType>(initialPromotion);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<{message: string, status:number}>();

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const getPromotion = async () => {
        setIsLoading(true);
        try {
            const response = await getPromotionById(promotionId as string);
            if (response && response.data) {
                setOriginalPromotion(response.data)
                setCurrentPromotion(response.data);

            }
        } catch(error:any) {
            console.log(error)
            const handledError = handleApiError(error);
            setError({message: handledError.message, status: handledError.status!})
        } finally {
            setIsLoading(false)
        }
    } 

    /*
    useEffect(() => {
        if (promotionId) {
            getPromotion()
        } else {
            setOriginalPromotion(defaultPromotion)
            setCurrentPromotion(defaultPromotion)
        }
    }, [promotionId])
    */
    
    const handlePromotionInfoChange = (key:string, value:any) => {
        setCurrentPromotion((prev) => ({...prev!, [key]: value}))
    }

    const handlePromotionConditionChange = (key:string, value:any) => {
        alert(key)
        setCurrentPromotion((prev) => ({...prev!, conditions:{...prev!.conditions, [key]: value} }))
    }

    const handleDiscountTypeChange = (newDiscountType: PromotionGenre) => {
        setCurrentPromotion({...currentPromotion!, promotionType: newDiscountType, products: [] })
    };
      
    const savePormotion = async () => {
        setIsSaving(true);
        try {
            const response = await createPromotion(currentPromotion!);
            if (response) {
                alert('Promotion created!')
            } else {
                alert('An error occured while creating promotion')
            }
        } catch (error: any) {
            console.log(error)
            const handledError = handleApiError(error);
            alert(handledError.message)
        } finally {
            setIsSaving(false)
        }
    };

    const updatePromtion= async () => {
        setIsSaving(true);
        try {
            const response = await updatePromotion(currentPromotion?._id!, currentPromotion!);
            if (response) {
                alert('Promotion updated!')
            } else {
                alert('An error occured while updating promotion')
            }
        } catch (error: any) {
            console.log(error)
            const handledError = handleApiError(error);
            alert(handledError.message)
        } finally {
            setIsSaving(false)
        }
    };




    if (isLoading) return <LoadingIndicatorV1/>
    if (error) return <ErrorBannerV1 {...error}/>

    return (
        <div className={styles.edit_promotion}>
            <DashboardHeader title="Edit Promotion"/>
            <div className={styles.content}>
                <GeneralPromotionInfoForm
                    promotion={currentPromotion}
                    onPromotionInfoChange={handlePromotionInfoChange}
                    onPromotionTypeChange={handleDiscountTypeChange}
                />
                <PromotionConditionForm 
                    promotion={currentPromotion}
                    onConditionChange={handlePromotionConditionChange}
                />
                {(currentPromotion!.promotionType === PromotionGenre.FixedAmountDiscount || currentPromotion!.promotionType === PromotionGenre.PercentageDiscount) &&
                    <ProductPromotionTable
                    discountType={currentPromotion!.promotionType === PromotionGenre.FixedAmountDiscount ? 'fixed' : 'percentage'}
                    discountProducts={currentPromotion!.products}
                    onProductsChange={(products)=>handlePromotionInfoChange('products', products)}
                />
                }
                <div style={{marginTop:20}}>
                    <SaveButton  onClick={currentPromotion?._id ? updatePromtion : savePormotion} isSaving/>
                    <ButtonCancel style={{marginLeft: 20}}/>
                </div>
            </div>
        </div>
    );
};

export default EditPromotion;
