import CouponPromotionV1 from "./CouponPromotionV1/CouponPromotionV1";
import CouponPromotionV2 from "./CouponPromotionV2/CouponPromotionV2";
import CouponPromotionV3 from "./CouponPromotionV3/CouponPromotionV3";

enum CouponPromotionVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const CouponPromotionMapping:Record<CouponPromotionVersions, React.FC<any>> = {
    [CouponPromotionVersions.V1]: CouponPromotionV1,
    [CouponPromotionVersions.V2]: CouponPromotionV2,
    [CouponPromotionVersions.V3]: CouponPromotionV3,
};


interface CouponPromotionComponentProps {
    couponCode?: string;
    onCouponCodeChange?: (newCouponCode: string) => void;
    onApplyCoupon?: () => void;
    isCouponApplied?: boolean;
};

interface CouponPromotionComponentCustomizationProps {
    successMessage?: string;
    placeholderText?: string;
    buttonText?: string;
};

export {
    CouponPromotionMapping,
    CouponPromotionVersions
};

export type {
    CouponPromotionComponentProps,
    CouponPromotionComponentCustomizationProps
};