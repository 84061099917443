import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ProductType } from '../../../types/Data/ProductType';
import { getAllProducts, getProductById } from '../../../api/product/productApi';

interface ProductState {
  products: ProductType[];
  page: number;
  pages: number;
  total: number;
  productDetails: ProductType | null;
  categories: string[];
  filteredProducts: ProductType[];
  loading: boolean;
  error: string | null;
}

// Initial state for the product slice
const initialState: ProductState = {
  products: [],
  pages: 1,
  page:1,
  total: 0,
  productDetails: null,
  categories: [],
  filteredProducts: [],
  loading: false,
  error: null,
};

// Thunks for async actions

// Fetch all products
export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllProducts();
      return response.data.data;
    } catch (err: any) {
      return rejectWithValue('Failed to load products');
    }
  }
);

// Fetch a single product by ID
export const fetchProductById = createAsyncThunk(
  'products/fetchProductById',
  async (productId: string, { rejectWithValue }) => {
    try {
      const response = await getProductById(productId);
      return response.data.data;
    } catch (err: any) {
      return rejectWithValue('Failed to load product');
    }
  }
);

// Product slice
const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setCategories(state, action: PayloadAction<string[]>) {
      state.categories = action.payload;
    },
    filterProductsByCategory(state, action: PayloadAction<string>) {
      state.filteredProducts = state.products.filter(
        product => product.categories.includes( action.payload)
      );
    },
    clearProductDetails(state) {
      state.productDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all products
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload.products as ProductType[];
        state.pages = action.payload.pages;
        state.page = action.payload.page;
        state.total = action.payload.total;
        state.filteredProducts = action.payload.products as ProductType[]; // Initialize filtered products with all products
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Fetch product by ID
      .addCase(fetchProductById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductById.fulfilled, (state, action) => {
        state.loading = false;
        state.productDetails = action.payload;
      })
      .addCase(fetchProductById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

// Export actions
export const { setCategories, filterProductsByCategory, clearProductDetails } = productSlice.actions;

// Export reducer
export default productSlice.reducer;
