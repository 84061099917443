import { SortComponentProps } from "./SortComponentProps";

export const defaultSortOptions: SortComponentProps = {
    options: [
        'Relevance',
        'Price: Low to High',
        'Price: High to Low',
        'Newest Arrivals',
        'Customer Ratings',
        'Best Sellers'
    ]
}
    


  