import { AxiosResponse } from "axios";
import api from "../config";
import { ApiResponse } from "../apiType";
import { BaseElementType } from "../../types/Data/BaseElementType";

// Create a new element
export async function createElement(elementData: BaseElementType<any>): Promise<AxiosResponse<ApiResponse<BaseElementType<any>>>> {
    try {
        const response = await api.post('/elements', elementData);
        return response.data;
    } catch (error) {
        console.error('Error creating element:', error);
        throw error;
    }
}

// Get an element by ID
export async function getAllElements(): Promise<AxiosResponse<ApiResponse<BaseElementType<any>[]>>> {
    try {
        const response = await api.get(`/elements`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching elements:`, error);
        throw error;
    }
}

// Get an element by ID
export async function getElementById(id: string): Promise<AxiosResponse<ApiResponse<BaseElementType<any>>>> {
    try {
        const response = await api.get(`/elements/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching element with ID: ${id}`, error);
        throw error;
    }
}

// Get all elements by page ID
export async function getElementsByPageId(pageId: string): Promise<AxiosResponse<ApiResponse<BaseElementType<any>[]>>> {
    try {
        const response = await api.get(`/elements/page/${pageId}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching elements for page ID: ${pageId}`, error);
        throw error;
    }
}

// Update an element by ID
export async function updateElement(id: string, updateData: Partial<BaseElementType<any>>): Promise<AxiosResponse<ApiResponse<BaseElementType<any>>>> {
    try {
        const response = await api.put(`/elements/${id}`, updateData);
        return response.data;
    } catch (error) {
        console.error(`Error updating element with ID: ${id}`, error);
        throw error;
    }
}

// Delete an element by ID
export async function deleteElement(id: string): Promise<AxiosResponse<ApiResponse<null>>> {
    try {
        const response = await api.delete(`/elements/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error deleting element with ID: ${id}`, error);
        throw error;
    }
}

// Add a property to the 'props' field of an element
export async function addElementProp(id: string, prop: any): Promise<AxiosResponse<ApiResponse<BaseElementType<any>>>> {
    try {
        const response = await api.patch(`/elements/${id}/props`, { prop });
        return response.data;
    } catch (error) {
        console.error(`Error adding property to element with ID: ${id}`, error);
        throw error;
    }
}

// Remove a property from the 'props' field of an element
export async function removeElementProp(id: string, propKey: string): Promise<AxiosResponse<ApiResponse<BaseElementType<any>>>> {
    try {
        const response = await api.delete(`/elements/${id}/props/${propKey}`);
        return response.data;
    } catch (error) {
        console.error(`Error removing property from element with ID: ${id}`, error);
        throw error;
    }
}
