import React from 'react';
import styles from './CompanyOverviewV3.module.scss';
import { defaultCompanyOverviewProps } from '../compagnieOverviewDefaultProps';
import { CompanyOverviewProps } from '../CompanyOverviewComponentProps';

const CompanyOverviewV3: React.FC<CompanyOverviewProps> = ({
    title = defaultCompanyOverviewProps.title,
    description = defaultCompanyOverviewProps.description,
    imageUrl = defaultCompanyOverviewProps.imageUrl,
    missionStatement = defaultCompanyOverviewProps.missionStatement
}) => {
  return (
    <div className={styles.companyOverview}>
      <div className={styles.glassCard}>
        <img src={imageUrl} alt={title} className={styles.image} />
        <div className={styles.content}>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.description}>{description}</p>
          {missionStatement && <blockquote className={styles.mission}>{missionStatement}</blockquote>}
        </div>
      </div>
    </div>
  );
};

export default CompanyOverviewV3;
