import React from 'react';
import styles from './ProductCardListV8.module.scss';
import { ProductCardListComponentProps, ProductCardListVersions } from '../ProductCardListComponentProps';

const ProductCardListV8: React.FC<ProductCardListComponentProps> = ({ product, onProductClick = () => null  }) => {
  return (
          <div key={product?._id} className={styles.carouselCard}  onClick={()=>onProductClick(product?._id!)}>
            <img src={product?.thumbnail || product?.images![0].url} alt={product?.name} className={styles.image} />
            <div className={styles.carouselContent}>
              <h3 className={styles.productName}>{product?.name}</h3>
              <p className={styles.productPrice}>{product?.price} {product?.currency}</p>
            </div>
          </div>

  );
};

export default ProductCardListV8;
