import React from 'react';
import styles from './CustomerMessageHistoryV1.module.scss';
import { CustomerMessageHistoryProps } from '../CustomerMessageHistoryType';
import { customerMessageHistoryDefaultProps } from '../customerMessageHistoryDefaultProps';
import { isMessage } from '../../../types/Data/MessageType';

const CustomerMessageHistoryV1: React.FC<CustomerMessageHistoryProps> = ({ 
    chatRooms = customerMessageHistoryDefaultProps.chatRooms,
    onClick = () => null,
}) => {
  return (
    <div className={styles.messageList}>
      {Array.isArray(chatRooms) && chatRooms.map((chatRoom) => {
        const potentialLastMessage = chatRoom?.messages && chatRoom?.messages[chatRoom.messages.length - 1];

        // Check if the last message is indeed a MessageType
        const lastMessage = isMessage(potentialLastMessage) ? potentialLastMessage : null;

        if (lastMessage) {
          return (
            <div
              key={chatRoom._id}
              className={`${styles.messageItem} ${lastMessage.senderModel === 'admin' ? styles.adminMessage : styles.customerMessage}`}
              onClick={() => onClick && onClick(chatRoom._id)}
            >
              <div className={styles.messageHeader}>
                <span className={styles.sender}>{lastMessage.senderModel === 'admin' ? 'Admin' : 'Customer'}</span>
                <span className={styles.timestamp}>{new Date(lastMessage.createdAt).toDateString()}</span>
              </div>
              <div className={styles.messageContent}>{lastMessage.content}</div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default CustomerMessageHistoryV1;
