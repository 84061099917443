
export enum ReviewStatus {
    PENDING = 'pending',
    APPROVED = 'approved',
    REJECTED = 'rejected',
}

interface ReviewType {
    _id: string;
    productId: string;// Identifier for the product being reviewed
    userId: string;   // Identifier for the user who wrote the review
    userName: string;                  // Name of the user who wrote the review
    rating: number;                    // Rating given by the user (1-5)
    title?: string;                    // Optional title of the review
    content?: string;                  // Optional content/body of the review
    reviewDate?: Date | string;                 // Date when the review was submitted
    status?: ReviewStatus;             // Status of the review (pending, approved, rejected)
    isVerifiedPurchase?: boolean;      // Whether the review is from a verified purchase
    images?: string[];                 // Array of image URLs related to the review
    videos?: string[];                 // Array of video URLs related to the review
    adminResponse?: string | null;     // Optional response from admin (if any)
    flagged?: boolean;                 // Whether the review has been flagged for review
    flagReason?: string;               // Reason why the review was flagged (optional)
}

export type {
    ReviewType,
}
