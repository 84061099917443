import React, { useState } from "react";
import styles from './TicketStatus.module.scss';
import Select4 from "../../../../../components/Select/Select4/Select4";
import { SupportTicketStatus } from "../../../../../types/Data/SupportTicketType";

interface TicketStatusProps {
    status?: SupportTicketStatus,
    onStatusChange?: (status: SupportTicketStatus) => void,
}

const TicketStatus: React.FC<TicketStatusProps> = ({
    status = SupportTicketStatus.Pending,
    onStatusChange = () => null,
}) => {
    
    const statusOptions = Object.values(SupportTicketStatus).map((value) => ({ label: value, value }));
    
    return (
        <div className={styles.section}>
            <Select4
                options={statusOptions}
                value={status}
                label="Status"
                onChange={onStatusChange}
            />
        </div>

    );
};

export default TicketStatus;
