import React from "react"
import styles from './ComponentList.module.scss'

interface ComponentListProps {
  onElementClick?: (id:string) => void;
};

const ComponentList:React.FC<ComponentListProps> = ({onElementClick=()=>null}) => {
    
    const components = [ 
        { id: 'heroBanner', name: 'Hero Banner', imageUrl: '/images/hero-banner.png' },
        { id: 'heroSection', name: 'Hero Banner', imageUrl: '/images/hero-banner.png' },
        { id: 'incentive', name: 'Incentive', imageUrl: '/images/hero-banner.png' },
        { id: 'featureSection', name: 'Incentive', imageUrl: '/images/hero-banner.png' },
        { id: 'trustSignals', name: 'Trust Signals'},
        { id: 'trustBadgeIcon', name: 'Trust Badge Icon'},
        { id: 'featuredProducts', name: 'Featured Products', imageUrl: '/images/featured-products.png' },
        { id: 'categoryDisplay', name: 'Category Display', imageUrl: '/images/categories.png' },
        { id: 'newsletterSignup', name: 'Newsletter Signup', imageUrl: '/images/newsletter-signup.png' },
        { id: 'blogSection', name: 'Blog Section', imageUrl: '/images/blog-section.png' },
        { id: 'socialMediaLinks', name: 'Social Media Links', imageUrl: '/images/social-media-links.png' },
        { id: 'promotionalBanner', name: 'Promotional Banner', imageUrl: '/images/promotional-banners.png' },
        { id: 'testimonials', name: 'Testimonials', imageUrl: '/images/testimonials.png' },
        { id: 'companyOverview', name: 'Company Overview'},
        { id: 'companyHistory', name: 'Company History'},
        { id: 'customerSupport', name: 'Customer Support'},
        { id: 'contactInformation', name: 'Contact Information' },
        { id: 'logoCloud', name: 'Logo Cloud' },
        { id: 'filterModal', name: 'Filter Modal' },
        { id: 'sort', name: 'Sort'},
        { id: 'productList', name: 'Product List'},
        { id: 'pagination', name: 'Pagination'},
        { id: 'activeFilter', name: 'Active Filter'},
        { id: 'productImageGallery', name: 'Product Image Gallery' },
        { id: 'variantSelector', name: 'Variant Selector' },
        { id: 'productDescription', name: 'Product Description'},
        { id: 'productReviews', name: 'Product Reviews'},
        { id: 'productName', name: 'Product Name'},
        { id: 'productPrice', name: 'Product Price'},
        { id: 'quantitySelector', name: 'Quantity Selector'},
        { id: 'buttonProceedCheckout', name: 'Button Proceed Checkout'},
        { id: 'buttonBuy', name: 'Button Buy'},
        { id: 'buttonCheckout', name: 'button Checkout'},
        { id: 'buttonAddWishlist', name: 'button Add Wishlist'},
        { id: 'couponPromotion', name: 'Coupon Promotion'},
        { id: 'cartItemList', name: 'Cart Item List' },
        { id: 'orderSummary', name: 'Order Summary'},
        { id: 'stepIndicator', name: 'Step Indicator'},
        { id: 'shippingInformationForm', name: 'Shipping Information Form' },
        { id: 'billingInformationForm', name: 'Billing Information Form' },
        { id: 'paymentMethodSelector', name: 'Payment Method Selector'},
        { id: 'cartSummarySidebar', name: 'Cart Summary Sidebar'},
        { id: 'orderConfirmation', name: 'Order Confirmation'},
        { id: 'orderError', name:'Order Error'},
        { id: 'contactForm', name: 'Contact Form' },
        { id: 'breadcrumb', name: 'Breadcrumb' },
        { id: 'qa', name: 'QA' },
        { id: 'teamMemberCard', name: 'Team Member Card' },
        { id: 'cartSummary', name: 'Cart Summary' },    
    ]

    return (
      <div className={styles.componentList}>
        {components.map((component) => (
            <div key={component.id} className={styles.componentItem} onClick={()=>onElementClick(component.id)}>
                {component.imageUrl && <img src={component.imageUrl} alt={component.name} />}
                <span className={styles.componentName}>{component.name}</span>
            </div>
        ))}
      </div>
    )
}

export default ComponentList