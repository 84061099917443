import React, { ReactElement } from 'react';
import styles from './ButtonV12.module.scss';
import { ButtonComponentProps } from '../ButtonComponentProps';

const ButtonV12: React.FC<ButtonComponentProps> = ({ onClick, disabled = false, label, style, icon }) => {
    return (
        <button
            className={styles.roundButton}
            onClick={onClick}
            disabled={disabled}
            style={{...style}}
        >
            { label && label}
            { icon && icon}
        </button>
    );
};

export default ButtonV12;