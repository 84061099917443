import React from 'react';
import styles from './StepIndicatorV1.module.scss';
import { defaultStepIndicatorProps } from '../stepIndicatorDefaultProps';
import { StepIndicatorComponentProps } from '../StepIndicatorComponentProps';

const StepIndicatorV1: React.FC<StepIndicatorComponentProps> = ({ 
  currentStep = defaultStepIndicatorProps.currentStep,
  steps = defaultStepIndicatorProps.steps,
  onStepClick = defaultStepIndicatorProps.onStepClick, 
}) => {
  const currentIndex = steps?.indexOf(currentStep!)
  return (
    <div className={styles.stepIndicator}>
      {steps!.map((step, index) => (
        <div
          key={index}
          className={`${styles.step} ${index <= currentIndex! ? styles.active : ''}`}
          onClick={() => onStepClick && onStepClick(step)}
        >
          <div className={styles.number}>{index + 1}</div>
          <div className={styles.label}>{step}</div>
        </div>
      ))}
    </div>
  );
};

export default StepIndicatorV1;
