import BlogSectionV1 from "./BlogSectionV1/BlogSectionV1";
import BlogSectionV2 from "./BlogSectionV2/BlogSectionV2";
import BlogSectionV3 from "./BlogSectionV3/BlogSectionV3";

enum BlogSectionVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const BlogSectionMapping:Record<BlogSectionVersions, React.FC<any>> = {
    [BlogSectionVersions.V1]: BlogSectionV1,
    [BlogSectionVersions.V2]: BlogSectionV2,
    [BlogSectionVersions.V3]: BlogSectionV3,
};

interface BlogSectionComponentProps {
    onClick?: (blockId:string) => void
};

export {
    BlogSectionVersions,
    BlogSectionMapping,
};

export type {
    BlogSectionComponentProps,
};