import React from 'react';
import styles from './CompanyHistoryV1.module.scss';
import { defaultCompanyHistoryProps } from '../companyHistoryDefaultProps';
import { CompanyHistoryComponentProps } from '../CompanyHistoryComponentProps';

const CompanyHistoryV1: React.FC<CompanyHistoryComponentProps> = ({ 
  title = defaultCompanyHistoryProps.title, 
  milestones = defaultCompanyHistoryProps.milestones 
}) => {
  return (
    <div className={styles.historySection}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.timeline}>
        {milestones!.map((milestone, index) => (
          <div key={index} className={styles.timelineItem}>
            <div className={styles.date}>{milestone.date}</div>
            <div className={styles.content}>
              {milestone.imageUrl && <img src={milestone.imageUrl} alt={milestone.event} className={styles.image} />}
              <h3 className={styles.event}>{milestone.event}</h3>
              {milestone.description && <p className={styles.description}>{milestone.description}</p>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanyHistoryV1;
