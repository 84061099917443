import { CustomerType } from "../../types/Data/CustomerType";
import CustomerEditProfilFormV1 from "./CustomerEditProfilFormV1/CustomerEditProfilFormV1";

enum CustomerEditProfilComponentVersions {
    V1 = "v1",
};

const CustomerEditProfilComponentMapping:Record<CustomerEditProfilComponentVersions, React.FC<any>> = {
    [CustomerEditProfilComponentVersions.V1]: CustomerEditProfilFormV1,  
};

interface CustomerEditProfilComponentProps {
    customer?: Partial<CustomerType>,
    onSave?: (data: Partial<CustomerType>)=>void,
    onCancel?: ()=>void
};

export {
    CustomerEditProfilComponentVersions,
    CustomerEditProfilComponentMapping
};

export type {
    CustomerEditProfilComponentProps
};