import React from 'react';
import styles from './FeaturedProductsV3.module.scss';
import { RootState } from '../../../Store/store';
import { useSelector } from 'react-redux';
import { ProductType } from '../../../types/Data/ProductType';
import { FeaturedProductsComponentProps } from '../FeaturedProductsComponentProps';

const FeaturedProductsV3: React.FC<FeaturedProductsComponentProps> = ({ productsId,  onClick=()=>null }) => {
  
    const allProducts = useSelector<RootState>(state => state.dashboard.products.products) as ProductType[];

    const products = productsId
        ? allProducts.filter((product) => productsId.includes(product._id!))
        : allProducts.slice(0, 10); 

    return (
        <div className={styles.masonryGrid}>
        {products.map((product) => (
            <div key={product._id!} className={styles.masonryItem} onClick={()=>onClick(product._id!)}>
            {product.images && <img src={product.images[0].url} alt={product.name} className={styles.productImage} />}
            <div className={styles.productInfo}>
                <h3>{product.name}</h3>
                <p>{product.description}</p>
                <span>{product.price}</span>
            </div>
            </div>
        ))}
        </div>
  );
};

export default FeaturedProductsV3;
