import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import examplePromotions from './examplePromotions';
import { PromotionType } from '../../../types/Data/PromotionType';
import { getAllPromotions } from '../../../api/promotion/promotionApi';

// Create the async thunk for fetching products
export const fetchPromotions = createAsyncThunk(
    'promotions/fetchPromotions',
    async (params: { page?: number; [key: string]: any } = {}, { rejectWithValue }) => {
      try {
        // Pass the params to getAllPromotions
        const response = await getAllPromotions();
        return response.data.data;
      } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || 'Failed to fetch promotions');
      }
    }
);

interface PromotionsState {
    promotions: Partial<PromotionType>[];
    loading: boolean;
    error: string | null | {};
    page: number;
    totalPages: number;
};

const initialState: PromotionsState = {
    promotions: examplePromotions,
    loading: false,
    error: null,
    page: 1,
    totalPages: 1,
};

const promotionsSlice = createSlice({
    name: 'promotions',
    initialState,
    reducers: {
        dispatcAddPromotion(state, action: PayloadAction<PromotionType>) {
            state.promotions.push(action.payload);
        },
        dispatchUpdatePromotion(state, action: PayloadAction<PromotionType>) {
            const index = state.promotions.findIndex(promotion => promotion._id === action.payload._id);
            if (index !== -1) {
                state.promotions[index] = action.payload;
            }
        },
        dispatchDeletePromotion(state, action: PayloadAction<string>) {
            state.promotions = state.promotions.filter(promotion => promotion._id !== action.payload);
        },
        dispatchLoadPromotions(state, action: PayloadAction<PromotionType[]>) {
            state.promotions = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPromotions.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPromotions.fulfilled, (state, action: PayloadAction<{ promotions: Partial<PromotionType>[] }>) => {
                state.loading = false;
                state.promotions = action.payload.promotions;
            })
            .addCase(fetchPromotions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || action.error.message || 'Unknown error';
            });
        }
});

export const { dispatcAddPromotion, dispatchUpdatePromotion, dispatchDeletePromotion, dispatchLoadPromotions } = promotionsSlice.actions;
export default promotionsSlice.reducer;
