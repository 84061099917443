import React, { useState } from 'react';
import styles from './CouponPromotionV3.module.scss';
import { CouponPromotionComponentCustomizationProps, CouponPromotionComponentProps } from '../CouponPromotionComponentProps';

const CouponPromotionV3: React.FC<CouponPromotionComponentProps & CouponPromotionComponentCustomizationProps> = ({
  couponCode = "12345678",
  onCouponCodeChange = () => null,
  onApplyCoupon = () => null,
  isCouponApplied = false,
  successMessage = "Coupon Applied!",
  placeholderText = "Enter your coupon code",
  buttonText = "Apply"
}) => {
  
  return (
    <div className={styles.couponContainer}>
      <div className={styles.inputGroup}>
        <input
          type="text"
          value={couponCode}
          placeholder={placeholderText}
          onChange={(e) => onCouponCodeChange(e.target.value)}
          required
        />
        <label className={styles.label}>Coupon Code</label>
      </div>
      <button onClick={onApplyCoupon} className={styles.applyButton}>
        {buttonText}
      </button>
      {isCouponApplied && <p className={styles.successMessage}>{successMessage}</p>}
    </div>
  );
};

export default CouponPromotionV3;
