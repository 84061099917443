import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './AdminPage.module.scss';
import { AppDispatch, RootState } from '../../Store/store';
import { UserType } from '../../types/Data/UserType';
import UserEditor from './AdminEditor/AdminEditorPage';
import DashboardHeader from '../../components/Header/DashboardHeader/DashboardHeader';
import Button1 from '../../components/Button/ButtonV1/ButtonV1';
import SlideOver1 from '../../components/SlideOver/SlideOverV1/SlideOverV1';
import AdminList from './AdminList/AdminList';
import { useNavigate } from 'react-router-dom';
import { fetchAdmins } from '../DashboardState/admin/adminSlice';
import LoadingIndicatorV1 from '../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import { AdminType } from '../../types/Data/AdminType';

const AdminUserPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>(); // Typing the dispatch with your store's dispatch type
  const admins = useSelector((state: RootState) => state.dashboard.admins.admins) as AdminType[];

  const [query, setQuery] = useState({
      search: '',
  })

  //dispatch(dispatchLoadProducts(exampleProducts))
  
  const [page, setPage] = useState(1); // Separate page state

  // Select loading, error, and products state from Redux store
  const { loading, error, products } = useSelector((state: RootState) => state.dashboard.products);


  const handleQueryChange = (key:string, value:any ) => {
      setQuery((prev) => ({...prev, [key]:value}))
      setPage(1); // Reset page to 1 when query changes
  };

  /*
  useEffect(() => {
      // Fetch products when query changes
      dispatch(fetchAdmins({ ...query, page }));
  }, [query, dispatch]);
  */

  return (
    <div className={styles.adminUserPage}>
      <DashboardHeader title='Admin User Management'/>
       {/* Handle loading state */}
       {loading && <LoadingIndicatorV1 />}
                
        {/* Handle error state */}
      {error && <ErrorBannerV1 message={typeof error === 'string' ? error : 'An error occurred'} />}
      <div className={styles.content}>
        <AdminList
          admins={admins}
          onDelete={()=>null}
        />
      </div>
    </div>
  );
};

export default AdminUserPage;
