import React, { useState, useEffect } from 'react';
import Input1 from '../../Input/InputText/InputV1/InputV1';
import Button1 from '../../Button/ButtonV1/ButtonV1';
import './FormForgetPassword.scss';

interface ForgotPasswordFormValues {
  email: string;
}

const FormForgotPassword: React.FC = () => {
  const [values, setValues] = useState<ForgotPasswordFormValues>({ email: '' });
  const [errors, setErrors] = useState<{ email?: string }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [counter]);


  const validate = (values: ForgotPasswordFormValues) => {
    const errors: { email?: string } = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    return errors;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);
      setCounter(30);
      // Call the backend API to handle password reset
      // For example:
      // fetch('/api/password-reset', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify(values),
      // }).then(response => {
      //   // Handle response
      // });
      console.log(values);
      setIsSubmitting(false);
    }
  };

  return (
    <div className='forget-password-form'>
      <h2>Forgot Password</h2>
      <form onSubmit={handleSubmit}>
        <div className='input-group'>
            <Input1 label="email" name="email" type="email"/>
            {errors.email && <div className='error'>{errors.email}</div>}
        </div>
        <div className='button-container'>
            <Button1 type='submit' title='Submit' onClick={()=>null} disabled={isSubmitting || counter > 0}/>
            <p className='counter'>{counter > 0 ? `Please wait ${counter}s` : ''}</p>
        </div>
      </form>
    </div>
  );
};

export default FormForgotPassword;