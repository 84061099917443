import React, { useEffect, useState } from 'react';
import styles from './ReturnRequestStatus.module.scss';
import Select4 from '../../../../components/Select/Select4/Select4';
import { ReturnStatus } from '../../../../types/Data/ReturnRequestType';

interface PaymentMethodProps {
    returnRequestStatus?: ReturnStatus;
    onReturnRequestStatusChange: (status:ReturnStatus) => void;
}

const ReturnRequestStatus: React.FC<PaymentMethodProps> = ({ returnRequestStatus, onReturnRequestStatusChange }) => {

    const returnRequestStatusOptions = Object.values(ReturnStatus).map((status) => ({label: status, value: status}))

    return (
        <div className={styles.returnRequestStatus}>
            <div className={styles.formGroup}>
                <Select4
                    label="Payment Status"
                    options={returnRequestStatusOptions}
                    value={returnRequestStatus}
                    onChange={(value) => onReturnRequestStatusChange(value as ReturnStatus)}
                />
            </div>
        </div>
    );
};

export default ReturnRequestStatus;
