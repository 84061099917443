import React, { useEffect, useState } from 'react';
import styles from './OrderNotes.module.scss';

interface OrderNotesProps {
    notes?: { internalNotes?: string | undefined, customerNotes?: string | undefined },
    onNotesChange: (internalNotes?: string | undefined, customerNotes?: string | undefined) => void;
}

const OrderNotes: React.FC<OrderNotesProps> = ({ notes, onNotesChange }) => {

    return (
        <div className={styles.orderNotesForm}>
            <h4>Order Notes</h4>

            <div className={styles.formGroup}>
                <label htmlFor="customerNotes">Customer Notes</label>
                <textarea
                    id="customerNotes"
                    value={notes?.customerNotes}
                    onChange={(e) => onNotesChange(e.target.value, notes?.internalNotes || '')}
                    placeholder="Add any notes that will be visible to the customer..."
                    rows={4}
                />
            </div>

            <div className={styles.formGroup}>
                <label htmlFor="internalNotes">Internal Notes</label>
                <textarea
                    id="internalNotes"
                    value={notes?.internalNotes}
                    onChange={(e) => onNotesChange(notes?.customerNotes || '', e.target.value )}
                    placeholder="Add any internal notes for reference..."
                    rows={4}
                />
            </div>

        </div>
    );
};

export default OrderNotes;
