import IncentiveV1 from "./IncentiveV1/IncentiveV1";
import IncentiveV2 from "./IncentiveV2/IncentiveV2";
import IncentiveV3 from "./IncentiveV3/IncentiveV3";
import IncentiveV4 from "./IncentiveV4/IncentiveV4";


enum IncentiveVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
    V4 = "v4",
};

const IncentiveMapping:Record<IncentiveVersions, React.FC<any>> = {
    [IncentiveVersions.V1]: IncentiveV1,
    [IncentiveVersions.V2]: IncentiveV2,
    [IncentiveVersions.V3]: IncentiveV3,
    [IncentiveVersions.V4]: IncentiveV4,

};

interface IncentiveItem {
    icon?: string;
    title?: string;
    description?: string;
}
  
  
interface IncentiveComponentProps {
    title?: string;
    description?: string;
    items?: IncentiveItem[];
};

export {
    IncentiveMapping,
    IncentiveVersions,
};

export type {
    IncentiveItem,
    IncentiveComponentProps,
};