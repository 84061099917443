import React from 'react'
import styles from '../Component.module.scss';
import PricingV1 from '../../components/Pricing/PricingV1/PricingV1';

const PricingCardComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <PricingV1/>
        </div>
    </div>
  )
}

export default PricingCardComponentPage