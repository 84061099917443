import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Input4 from '../../../../components/Input/InputText/InputV4/InputV4';
import Button1 from '../../../../components/Button/ButtonV1/ButtonV1';
import Switch1 from '../../../../components/Switch/SwitchV1/SwitchV1';
import styles from './ShippingMethodEdit.module.scss';
import { dispatchAddShippingMethod, dispatchUpdateShippingMethod } from '../../../DashboardState/shippingMethods/shippingMethodsSlice';
import { ShippingMethodType } from '../../../../types/Data/ShippingSettingsType';
import { useLocation, useParams } from 'react-router-dom';
import { RootState } from '../../../../Store/store';
import DashboardHeader from '../../../../components/Header/DashboardHeader/DashboardHeader';
import { createShippingMethod, getShippingMethodByName, updateShippingMethod } from '../../../../api/shippingMethod/shippingMethodApi';
import { handleApiError } from '../../../../api/apiError';
import LoadingIndicatorV1 from '../../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import SaveButton from '../../../../components/Button/SaveButton/SaveButton';
import ButtonCancel from '../../../../components/Button/ButtonCancel/ButtonCancel';

const ShippingMethodEditPage: React.FC = () => {
  const location = useLocation();
  const shippingMethodName = new URLSearchParams(location.search).get('shippingMethodName');

  const shippingMethodToEdit = useSelector<RootState>((state)=>state.dashboard.shippingMethods.shippingMethods.find(el=>el.name === shippingMethodName)) as ShippingMethodType
  
  const dispatch = useDispatch();

  const defaultShippingMethod: ShippingMethodType = {
    name: '',
    rate: 0,
    averageDeliveryDays: 0,
    international: false,
    maxWeight: undefined,
    insuranceAvailable: false,
    deliveryOptions: [],
    trackingUrl: '',
    description: '',
    logoUrl: '',
    dimensionsLimit: {
      length: undefined,
      width: undefined,
      height: undefined
    },
    freeShippingThreshold: undefined,
    isEnabled: true
  };

  const [originalShippingMethod, setOriginalShippingMethod] = useState<ShippingMethodType>(shippingMethodToEdit || defaultShippingMethod)
  const [currentShippingMethod, setCurrentShippingMethod] = useState<ShippingMethodType>(shippingMethodToEdit || defaultShippingMethod);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>()

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const getShippingMethod = async () => {
    setIsLoading(false);
    try {
      const response = await getShippingMethodByName(shippingMethodName!)
      if (response.status === 200 && response.data) {
        setOriginalShippingMethod(response.data.data);
        setCurrentShippingMethod(response.data.data)
      } else {
        alert('An error occured while retrieving the shipping method!')
      }
    } catch (error:any) {
      const handledError = handleApiError(error);
      setError({message: handledError.message, status: handledError.status})
    } finally {
      setIsLoading(false)
    }
  }
  /*
  useEffect(() => {
    if (shippingMethodName) {
      getShippingMethod()
    } else {
      setOriginalShippingMethod(defaultShippingMethod);
      setCurrentShippingMethod(defaultShippingMethod)
    }
  }, [shippingMethodName])
  */
 
  const handleChange = (key: string, value: any) => {
    setCurrentShippingMethod({ ...currentShippingMethod, [key]: value });
  };

  const handleDimensionChange = (dimension: string, value: number) => {
    setCurrentShippingMethod({
      ...currentShippingMethod,
      dimensionsLimit: {
        ...currentShippingMethod.dimensionsLimit,
        [dimension]: value
      }
    });
  };

  const handleSaveShippingMethod = async () => {
    setIsSaving(true);
    try {
      const response = await createShippingMethod(currentShippingMethod);
      if (response.status === 201 && response.data) {
        setOriginalShippingMethod(response.data.data)
        setCurrentShippingMethod(response.data.data)
      } else {
        alert('An error occured while creating shipping method')
      }
    } catch (error: any) {
      const handledError = handleApiError(error);
      alert(handledError.message)
    } finally {
      setIsSaving(false)
    }
  };

  const handleUpdateShippingMethod = async () => {
    setIsSaving(true);
    try {
      const response = await updateShippingMethod(currentShippingMethod.name,  currentShippingMethod);
      if (response.status === 201 && response.data) {
        setOriginalShippingMethod(response.data.data)
        setCurrentShippingMethod(response.data.data)
      } else {
        alert('An error occured while updating shipping method')
      }
    } catch (error: any) {
      const handledError = handleApiError(error);
      alert(handledError.message)
    } finally {
      setIsSaving(false)
    }
  }

  const handleCancel = () => {
    setCurrentShippingMethod(originalShippingMethod)
  };

  if (isLoading) return <LoadingIndicatorV1/>

  if (error) return <ErrorBannerV1 message={error.message} status={error.status}/>
 
  return (
    <div>
      <DashboardHeader title={shippingMethodName ? "Edit Shipping Method" : "Add Shipping Method"}/>
      <div className={styles.shippingMethod_edit}>
        <Input4
          wrapperStyle={{ marginTop: 20 }}
          label="Shipping Method Name"
          type="text"
          id="methodName"
          name="methodName"
          value={currentShippingMethod.name}
          onChange={(e) => handleChange('name', e.target.value)}
          required
        />

        <Input4
          wrapperStyle={{ marginTop: 20 }}
          label="Shipping Method Rate"
          type="number"
          id="methodRate"
          name="methodRate"
          value={currentShippingMethod.rate}
          onChange={(e) => handleChange('rate', Number(e.target.value))}
          required
        />

        <Input4
          wrapperStyle={{ marginTop: 20 }}
          label="Average Delivery Days"
          type="number"
          id="averageDeliveryDays"
          name="averageDeliveryDays"
          value={currentShippingMethod.averageDeliveryDays}
          onChange={(e) => handleChange('averageDeliveryDays', Number(e.target.value))}
          required
        />

        <div className={styles.switch_container}>
          <Switch1
            isChecked={currentShippingMethod.international}
            onToggle={() => handleChange('international', !currentShippingMethod.international)}
          />
          <p>International Shipping</p>
        </div>

        <Input4
          wrapperStyle={{ marginTop: 20 }}
          label="Max Weight (kg)"
          type="number"
          id="maxWeight"
          name="maxWeight"
          value={currentShippingMethod.maxWeight || ''}
          onChange={(e) => handleChange('maxWeight', Number(e.target.value))}
        />

        <div className={styles.switch_container}>
          <Switch1
            isChecked={currentShippingMethod.insuranceAvailable}
            onToggle={() => handleChange('insuranceAvailable', !currentShippingMethod.insuranceAvailable)}
          />
          <p>Insurance Available</p>
        </div>

        <Input4
          wrapperStyle={{ marginTop: 20 }}
          label="Delivery Options (comma separated)"
          type="text"
          id="deliveryOptions"
          name="deliveryOptions"
          value={currentShippingMethod.deliveryOptions.join(', ')}
          onChange={(e) => handleChange('deliveryOptions', e.target.value.split(',').map(option => option.trim()))}
        />

        <Input4
          wrapperStyle={{ marginTop: 20 }}
          label="Tracking URL"
          type="text"
          id="trackingUrl"
          name="trackingUrl"
          value={currentShippingMethod.trackingUrl}
          onChange={(e) => handleChange('trackingUrl', e.target.value)}
        />

        <Input4
          wrapperStyle={{ marginTop: 20 }}
          label="Description"
          type="text"
          id="description"
          name="description"
          value={currentShippingMethod.description}
          onChange={(e) => handleChange('description', e.target.value)}
        />

        <Input4
          wrapperStyle={{ marginTop: 20 }}
          label="Logo URL"
          type="text"
          id="logoUrl"
          name="logoUrl"
          value={currentShippingMethod.logoUrl}
          onChange={(e) => handleChange('logoUrl', e.target.value)}
        />

        <Input4
          wrapperStyle={{ marginTop: 20 }}
          label="Max Length (cm)"
          type="number"
          id="length"
          name="length"
          value={currentShippingMethod.dimensionsLimit?.length || ''}
          onChange={(e) => handleDimensionChange('length', Number(e.target.value))}
        />

        <Input4
          wrapperStyle={{ marginTop: 20 }}
          label="Max Width (cm)"
          type="number"
          id="width"
          name="width"
          value={currentShippingMethod.dimensionsLimit?.width || ''}
          onChange={(e) => handleDimensionChange('width', Number(e.target.value))}
        />

        <Input4
          wrapperStyle={{ marginTop: 20 }}
          label="Max Height (cm)"
          type="number"
          id="height"
          name="height"
          value={currentShippingMethod.dimensionsLimit?.height || ''}
          onChange={(e) => handleDimensionChange('height', Number(e.target.value))}
        />

        <div className={styles.button_container}>
          <SaveButton onClick={ shippingMethodName ? handleSaveShippingMethod : handleUpdateShippingMethod } isSaving/>
          <ButtonCancel onClick={handleCancel}/>
        </div>
      </div>
    </div>
  );
};

export default ShippingMethodEditPage;
