import React from 'react';
import DiscountStatsCard from './DiscountStatsCard';
import styles from './DiscountStatsOverview.module.scss';
import { FaPercentage, FaDollarSign, FaCalendarAlt, FaShoppingCart } from 'react-icons/fa';

const DiscountStatsOverview: React.FC = () => {
    const stats = [
        { icon: <FaPercentage />, title: 'Total Discounts', value: 25 },
        { icon: <FaDollarSign />, title: 'Total Savings', value: '$5,200' },
        { icon: <FaCalendarAlt />, title: 'Active Discounts', value: 10 },
        { icon: <FaShoppingCart />, title: 'Redeemed Discounts', value: 200 },
    ];

    return (
        <div className={styles.discount_stats_overview}>
            {stats.map((stat, index) => (
                <DiscountStatsCard key={index} icon={stat.icon} title={stat.title} value={stat.value} />
            ))}
        </div>
    );
};

export default DiscountStatsOverview;
