import React, { useState } from 'react';
import { FaStar } from 'react-icons/fa';
import styles from './CustomerProductReviewV1.module.scss';
import { OrderItemType } from '../../../types/Data/OrderType';
import { CustomerProductReviewComponentProps } from '../CustomerProductReviewComponentProps';
import { customerProductReviewDefaultProps } from '../customerProductReviewDefaultProps';

const CustomerProductReviewV1: React.FC<CustomerProductReviewComponentProps> = ({ 
  product=customerProductReviewDefaultProps.product, 
  onSubmitReview=customerProductReviewDefaultProps.onSubmitReview 

}) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [reviewText, setReviewText] = useState('');

  const handleRatingClick = (ratingValue: number) => {
    setRating(ratingValue);
  };

  const handleSubmit = () => {
    if (rating > 0 && reviewText.trim()) {
      onSubmitReview && onSubmitReview(product?.productId!, rating, reviewText);
      setRating(0);
      setReviewText('');
    } else {
      alert('Please provide a rating and a review before submitting.');
    }
  };

  return (
    <div className={styles.reviewCard}>
      <div className={styles.productInfo}>
        <img src={product!.imageUrl} alt={product!.name} className={styles.productImage} />
        <h3>{product!.name}</h3>
      </div>
      
      <div className={styles.rating}>
        {[...Array(5)].map((_, index) => {
          const ratingValue = index + 1;
          return (
            <FaStar
              key={index}
              className={styles.star}
              size={24}
              color={ratingValue <= (hover || rating) ? '#ffc107' : '#e4e5e9'}
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(0)}
              onClick={() => handleRatingClick(ratingValue)}
            />
          );
        })}
      </div>
      
      <textarea
        className={styles.reviewText}
        placeholder="Write your review..."
        value={reviewText}
        onChange={(e) => setReviewText(e.target.value)}
      />
      
      <button className={styles.submitButton} onClick={handleSubmit}>
        Submit Review
      </button>
    </div>
  );
};

export default CustomerProductReviewV1;
