import React from 'react';
import styles from './OrderConfirmationV3.module.scss';
import { defaultOrderConfirmationProps } from '../orderConfirmationDefaultProps';
import { OrderConfirmationComponentProps } from '../OrderConfirmationComponentProps';


const OrderConfirmationV3: React.FC<OrderConfirmationComponentProps> = ({
    orderNumber = defaultOrderConfirmationProps.orderNumber,
    orderDate = defaultOrderConfirmationProps.orderDate,
    totalAmount = defaultOrderConfirmationProps.totalAmount,
    items = defaultOrderConfirmationProps.items
}) => {
  return (
    <div className={styles.orderConfirmation}>
      <h2>Order Complete</h2>
      <p>Thank you for your purchase. Your order is now being processed.</p>
      <div className={styles.details}>
        <p>Order Number: <strong>{orderNumber}</strong></p>
        <p>Order Date: <strong>{orderDate}</strong></p>
        <p>Total Amount: <strong>{totalAmount}</strong></p>
      </div>
      <div className={styles.items}>
        <h3>Order Summary</h3>
        {items!.map((item, index) => (
          <div key={index} className={styles.item}>
            <p>{item.name}</p>
            <p>{item.quantity} x {item.price}</p>
          </div>
        ))}
      </div>
      <button>Continue Shopping</button>
    </div>
  );
};

export default OrderConfirmationV3;
