import React from 'react'
import styles from '../Component.module.scss';
import DialogV1 from '../../components/Dialog/DialogV1/DialogV1';
import DialogV2 from '../../components/Dialog/DialogV2/DialogV2';

const DialogComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <DialogV1/>
        </div>
        <div className={styles.element}>
            <DialogV2/>
        </div>
    </div>
  )
}

export default DialogComponentPage