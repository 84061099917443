import React from 'react';
import styles from './AvailabilityFilterV1.module.scss';
import { AvailabilityComponentFilterProps } from '../../FilterComponentProps';

const AvailabilityFilterV1: React.FC<AvailabilityComponentFilterProps> = ({ options = [ "on stock", "out stock"], selectedOptions = [], onSelect = () => null }) => {
  return (
    <div className={styles.availabilityFilterContainer}>
      <h3>Filter by Availability</h3>
      <div className={styles.availabilityList}>
        {options.map((option, index) => (
          <div key={index} className={styles.availabilityItem}>
            <input
              type="checkbox"
              id={`availability-${option}`}
              name="availability"
              value={option}
              checked={selectedOptions.includes(option)}
              onChange={() => onSelect(option)}
            />
            <label htmlFor={`availability-${option}`}>{option}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AvailabilityFilterV1;
