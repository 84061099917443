import React from 'react';
import { useSelector } from 'react-redux';
import styles from './FooterV1.module.scss';
import FooterPaymentMethod from './FooterPaymentMethod/FooterPaymentMethod';
import FooterDeliveryMethod from './FooterDeliveryMethod/FooterDeliveryMethod';
import FooterSocialMedia from './FooterSocialMedia/FooterSocialMedia';
import FooterNewsletterSubscription from './FooterNewsLetterSubscription/FooterNewsLetterSubscription';
import FooterLinks from './FooterLinks/FooterLinks';
import FooterContactInformation from './FooterContactInformation/FooterContactInformation';
import { SocialMediaLinkType } from '../../../types/Data/SocialMediaSettingsType';
import { ShippingMethodType } from '../../../types/Data/ShippingSettingsType';
import { PaymentMethodType } from '../../../types/Data/PayementSettingsType';
import { RootState } from '../../../Store/store';
import { FooterComponentProps, FooterLink } from '../FooterComponentProps';


const FooterV1: React.FC<FooterComponentProps> = ({
  showCompanyInfo = true,
  showSocialMedia = true,
  showPaymentMethods = true,
  showDeliveryMethods = true,
  showNewsletterSubscription = true,
  backgroundColor = '',
  textColor = 'string',
  items =[],
}) => {
  
  const paymentsMethods = useSelector<RootState>((state) => state.dashboard.paymentMethods.methods) as PaymentMethodType[];
  const deliveryMethods = useSelector<RootState>((state) => state.dashboard.shippingMethods.shippingMethods) as ShippingMethodType [];
  const socialMediaLinks = useSelector<RootState>((state) => state.dashboard?.socialMedia?.socialMediaLinks) as SocialMediaLinkType [];

  const footerLinks: FooterLink[] = [
    { title: 'About Us', url: '/ecommerce/aboutUs' },
    { title: 'Contact Us', url: '/ecommerce/contactUs' },
    { title: 'Privacy Policy', url: '/privacy-policy' },
    { title: 'Terms of Service', url: '/terms-of-service' },
    { title: 'FAQ', url: '/ecommerce/faq' },
  ];

  return (
    <footer className={styles.footer}>
      {items.map((item, index) => (
        <div key={index} className={styles.footerElement}>
          {item.type === 'link' && (
            <a href={item.content} target="_blank" rel="noopener noreferrer">
              {item.content}
            </a>
          )}
          {item.type === 'text' && <p>{item.content}</p>}
        </div>
      ))}
      { showCompanyInfo &&  <FooterContactInformation/> }
      { showNewsletterSubscription && <FooterNewsletterSubscription/> }
      <div className={styles.row}>
        { showPaymentMethods && paymentsMethods && ( <FooterPaymentMethod payments={paymentsMethods}/> ) }
        { showDeliveryMethods && deliveryMethods && ( <FooterDeliveryMethod deliveryMethods={deliveryMethods}/> ) }
        { showSocialMedia && socialMediaLinks && ( <FooterSocialMedia socialMediaLinks={socialMediaLinks}/>)}
      </div>
      
      <FooterLinks footerLinks={footerLinks}/>
    </footer>
  );
};

export default FooterV1;
