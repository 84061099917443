import { CustomerType } from "../../../types/Data/CustomerType";

const exampleCustomers: CustomerType[] = [
    {
        _id: 'C1',  
        accountId: '64b0e69f0b8b19aabb1b9f2a', 
        name: 'John Doe',
        email: "johndoe@gmail.com",
        phone: '123-456-7890',
        
        // Separate shipping and billing addresses
        shippingAddress: {
            street: '123 Main St',
            city: 'Anytown',
            state: 'CA',
            postalCode: '12345',
            country: 'USA'
        },
        billingAddress: {
            street: '456 Market St',
            city: 'Anytown',
            state: 'CA',
            postalCode: '12345',
            country: 'USA'
        },

        age: 30,
        gender: 'male',
        totalSpend: 1000,  // Updated field names for total spend and number of orders
        numOrders: 5,
        lastOrderDate: '2023-01-01',
        
        preferredLanguage: 'English',
        communicationPreference: 'Email',
        loyaltyLevel: 'Gold',
        notes: 'Frequent customer',
    },
    // Add more example customers as needed
];

export default exampleCustomers;
