import React, { useEffect } from 'react';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';
import styles from './ShippingMethodsPage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../Store/store';
import { ShippingMethodType } from '../../../types/Data/ShippingSettingsType';
import ShippingMethodsTable from './ShippingMethodsTable/ShippingMethodsTable';
import { useNavigate } from 'react-router-dom';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import { fetchShippingMethods } from '../../DashboardState/shippingMethods/shippingMethodsSlice';
import ButtonV5 from '../../../components/Button/ButtonV5/ButtonV5';

const ShippingMethodsPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  const { shippingMethods, loading, error}  = useSelector((state: RootState)=> state.dashboard.shippingMethods)


  const handleAdd = () => {
    navigate(`./edit`)
  };

  const handleEdit = (name:string) => {
    navigate(`./edit/?shippingMethodName=${name}`)
  };

  /*
  useEffect(() => {
    dispatch(fetchShippingMethods())
  }, [])
  */

  if (loading) return <LoadingIndicatorV1/>

  if (error) return <ErrorBannerV1 message={error}/>

  return (
    <div className={styles.shipping_settings_page}>
      <DashboardHeader title="Shipping Settings" />
      <div className={styles.shippingMethod_wrapper}>
      <ButtonV5
            style={{marginTop:10}}
            title='Add Discount Code'
            onClick={handleAdd}
          />
        <ShippingMethodsTable 
          shippingMethods={shippingMethods} 
          onEdit={(method)=>handleEdit(method.name)} 
          onToggleEnable={()=>null}/>
      </div>
    </div>
  );
};

export default ShippingMethodsPage;
