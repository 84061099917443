// CustomerSupportTickets.tsx

import React, { useEffect, useState } from 'react';
import styles from './CustomerSupportTickets.module.scss';
import axios from 'axios';
import { FaRegEnvelopeOpen, FaRegEnvelope, FaChevronRight } from 'react-icons/fa';
import {  SupportTicketType  } from '../../../../types/Data/SupportTicketType';

interface CustomerSupportTicketsProps {
  customerId: string;
}

const CustomerSupportTickets: React.FC<CustomerSupportTicketsProps> = ({ customerId }) => {
  const [tickets, setTickets] = useState< SupportTicketType []>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // Fetch the customer's support tickets
    axios
      .get(`/api/support-tickets?customerId=${customerId}`)
      .then((response) => {
        setTickets(response.data.tickets);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching support tickets:', error);
        setLoading(false);
      });
  }, [customerId]);

  if (loading) {
    return <div className={styles.loading}>Loading support tickets...</div>;
  }

  return (
    <div className={styles.customerSupportTickets}>
      <h2>Support Tickets</h2>
      {tickets.length === 0 ? (
        <div className={styles.noTickets}>No support tickets found for this customer.</div>
      ) : (
        <table className={styles.ticketsTable}>
          <thead>
            <tr>
              <th>Status</th>
              <th>Ticket ID</th>
              <th>Subject</th>
              <th>Date Created</th>
              <th>Last Updated</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tickets.map((ticket) => (
              <tr key={ticket._id}>
                <td>
                  <span
                    className={`${styles.statusBadge} ${
                      styles[`status${ticket.status.replace(/\s+/g, '')}`]
                    }`}
                  >
                    {ticket.status}
                  </span>
                </td>
                <td>{ticket._id}</td>
                <td>
                  {ticket.isRead ? (
                    <FaRegEnvelopeOpen className={styles.readIcon} />
                  ) : (
                    <FaRegEnvelope className={styles.unreadIcon} />
                  )}
                  {ticket.subject}
                </td>
                <td>{new Date(ticket?.createdAt!).toISOString().substring(0, 10)}</td>
                <td>{new Date(ticket?.updatedAt!).toISOString().substring(0, 10)}</td>
                <td>
                  <a href={`/admin/support-tickets/${ticket._id}`} className={styles.viewButton}>
                    View <FaChevronRight />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CustomerSupportTickets;
