import React from 'react'
import styles from '../Component.module.scss';
import ProductListV1 from '../../UIElements/ProductList/ProductListV1/ProductListV1';
import ProductListV2 from '../../UIElements/ProductList/ProductListV2/ProductListV2';
import ProductListV3 from '../../UIElements/ProductList/ProductListV3/ProductListV3';

const ProductListComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <ProductListV1/>
        </div>
        <div className={styles.element}>
            <ProductListV2/>
        </div>
        <div className={styles.element}>
            <ProductListV3/>
        </div>
    </div>
  )
}

export default ProductListComponentPage