import OrderSummaryV1 from "./OrderSummaryV1/OrderSummaryV1";
import OrderSummaryV2 from "./OrderSummaryV2/OrderSummaryV2";
import OrderSummaryV3 from "./OrderSummaryV3/OrderSummaryV3";


enum OrderSummaryVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const OrderSummaryMapping:Record<OrderSummaryVersions, React.FC<any>> = {
    [OrderSummaryVersions.V1]: OrderSummaryV1,
    [OrderSummaryVersions.V2]: OrderSummaryV2,
    [OrderSummaryVersions.V3]: OrderSummaryV3,
};

interface OrderSummaryComponentProps {
    subtotal?: number;
    shipping?: number;
    discount?: number;
    total?: number;     
};

export {
    OrderSummaryVersions,
    OrderSummaryMapping
};

export type {
    OrderSummaryComponentProps  
};