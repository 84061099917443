import React, { useEffect, useState } from 'react';
import styles from './CancellationStatusEdit.module.scss';
import Select4 from '../../../../components/Select/Select4/Select4';
import { CancellationStatus } from '../../../../types/Data/CancellationType';

interface CancellationStatusProps {
    cancellationStatus?: CancellationStatus;
    onCancellationStatusChange: (status:CancellationStatus) => void;
}

const CancellationStatusEdit: React.FC<CancellationStatusProps> = ({ cancellationStatus, onCancellationStatusChange }) => {

    const cancellationStatusOptions = Object.values(CancellationStatus).map((status) => ({label: status, value: status}))

    return (
        <div className={styles.cancellationStatus}>
            <div className={styles.formGroup}>
                <Select4
                    label="Cancellation Status"
                    options={cancellationStatusOptions}
                    value={cancellationStatus}
                    onChange={(value) => onCancellationStatusChange(value as CancellationStatus)}
                />
            </div>
        </div>
    );
};

export default CancellationStatusEdit;
