// src/components/CustomerGrowthChart.tsx
import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartData, ChartOptions } from 'chart.js';
import styles from './ChartCustomerEvolutionOverTime.module.scss'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Store/store';
import { CustomerType } from '../../../../../types/Data/CustomerType';

const ChartCustomerEvolutionOverTime: React.FC = () => {
  const customers = useSelector((state:RootState)=>state.dashboard.customers.customers) as CustomerType[];
  
  const getCustomersAmountsOverTime = () => {
    const dates: { [key: string]: number } = {};
    customers.forEach(customer => {
        const date = new Date(customer.createdAt!).toLocaleDateString();
        dates[date] = (dates[date] || 0) + 1;
    });
    return dates;
};

const customersNumber = getCustomersAmountsOverTime();
const dateLabels = Object.keys(customersNumber);
const dateData = Object.values(customersNumber);


const data: ChartData<'line'> = {
    labels: dateLabels,
    datasets: [
      {
        label: 'Customer Growth',
        data: dateData,
        fill: true,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        pointBorderColor: 'rgba(75, 192, 192, 1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75, 192, 192, 1)',
        pointHoverBorderColor: 'rgba(220, 220, 220, 1)',
        pointHoverBorderWidth: 2,
        pointRadius: 3,
        pointHitRadius: 10,
        tension: 0.4, // Smooth curves
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          color: '#ffffff', 
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: 'Months',
          color: '#ffffff',
        },
        ticks: {
          color: '#ffffff',
        },
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        title: {
          display: true,
          text: 'Number of Customers',
          color: '#ffffff',
        },
        ticks: {
          color: '#ffffff',
        },
      },
    },
  };

  return (
    <div className={styles.container}>
      <h3>Cistomer Evolution Over tTme</h3>
      <div className={styles.chart_container}>
        <Line data={data} options={options} />
      </div>
    </div>
    
  );
};

export default ChartCustomerEvolutionOverTime;
