import TabNavigationV1 from "./TabNavigationV1/TabNavigationV1";

interface NavigationItem {
    id: string;
    name: string;
    link: string;
    subcategories?: NavigationItem[];
    icon?: string;
}

enum TabNavigationVersions {
    V1 = "v1",
};

const TabNavigationMapping:Record<TabNavigationVersions, React.FC<any>> = {
    [TabNavigationVersions.V1]: TabNavigationV1,
};

interface TabNavigationComponentProps {
    items?: NavigationItem[]
    showCart?: boolean;
    showUserIcon?: boolean;
    headerStyle?: 'fixed' | 'sticky' | 'drawer';
    backgroundColor?: string;
    textColor?: string | undefined;
    fontStyle?: 'normal' | 'italic' | 'oblique';
    headerHeight?: number | undefined;
    borderStyle?: 'none' | 'solid' | 'dotted' | 'dashed';
    shadow?: boolean;
    displayMode?: 'inline' | 'flyout';
    onShopClick?: () => void;
    onUserClick?: () => void;
    onCartClick?: () => void
};

export {
    TabNavigationMapping,
    TabNavigationVersions
};

  
export type {
    NavigationItem,
    TabNavigationComponentProps,
};
  