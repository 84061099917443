import React, { useEffect, useState } from "react";
import { DiscountCodeType } from "../../../../types/Data/DiscountCodeType";
import InputV4 from "../../../../components/Input/InputText/InputV4/InputV4";
import Select4 from "../../../../components/Select/Select4/Select4";
import { CategoryType } from "../../../../types/Data/CategoryType";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Store/store";
import styles from './DiscountCodeCondition.module.scss';

interface DiscountCodeConditionsProps {
    discountCodeCondition?: DiscountCodeType['conditions']
    onConditionChange?: (conditions: DiscountCodeType['conditions']) => void;
};

const DiscountCodeConditions:React.FC<DiscountCodeConditionsProps> = ({discountCodeCondition, onConditionChange=()=>null}) => {

    const categories = useSelector<RootState>(state=>state.dashboard.categories.categories) as CategoryType[];
    const categoriesOptions = [...categories].map((category) => ({ label: category.name, value: category.name }))
    
    const customerSegmentsOptions = [
        { label: 'Regular Customers', value: 'regular' },
        { label: 'VIP Customers', value: 'vip' },
        { label: 'New Customers', value: 'new' }
    ];

    return (
        <div className={styles.discountCodeCondition}>
            <h2>Conditions</h2>
            <InputV4
                label="Minimum Order Value"
                type="number"
                name="minOrderValue"
                value={discountCodeCondition?.minOrderValue}
                onChange={(e) => onConditionChange({...discountCodeCondition, ['minOrderValue']: parseInt(e.target.value, 10)})}
                wrapperStyle={{width:'100%'}}
            />
            <Select4
                label="Customer Segment"
                value={discountCodeCondition?.customerSegment}
                onChange={(value) => onConditionChange({...discountCodeCondition, ['customerSegment']: value as string})}
                options={customerSegmentsOptions}
                wrapperStyle={{ width: '100%' }}
            />
            <Select4
                label="Product Categories"
                value={Array.isArray(discountCodeCondition?.productCategories!) ? discountCodeCondition?.productCategories!.join(', ') : discountCodeCondition?.productCategories!}
                onChange={(value) => onConditionChange({...discountCodeCondition, ['productCategories']: value})}
                options={categoriesOptions}
                isMulti
                wrapperStyle={{ width: '100%' }}
            /> 
        </div>
    )
};

export default DiscountCodeConditions