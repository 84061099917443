import React, { useEffect, useState } from "react";
import DashboardHeader from "../../components/Header/DashboardHeader/DashboardHeader";
import styles from './ReturnRequestPage.module.scss'
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store/store";
import { ReturnRequestType } from "../../types/Data/ReturnRequestType";
import TableInteractive from "../../components/Tableau/TableInteractive/TableInteractive";
import Filters from "./ReturnRequestFilter/ReturnRequestFilter";
import { useNavigate } from "react-router-dom";
import ErrorBannerV1 from "../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1";
import LoadingIndicatorV1 from "../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1";
import { dispatchLoadReturnRequest } from "../DashboardState/returnRequest/returnRequestSlice";
import { exampleReturnRequests } from "../DashboardState/returnRequest/exampleReturns";
import ReturnRequestList from "./ReturnRequestList/ReturnRequestList";

const ReturnRequestPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const [query, setQuery] = useState({
        search:"",
        status: "",
    });

    const [page, setPage] = useState(1); // Separate page state
    
    const returnRequests = useSelector<RootState>((state)=>state.dashboard.returnRequest.returnRequests) as ReturnRequestType[]
    const loading = useSelector<RootState, boolean>((state) => state.dashboard.returnRequest.loading);
    const error = useSelector<RootState, string | null>((state) => state.dashboard.returnRequest.error);

    /*
    useEffect(() => {
        dispatch(fetchReturnRequestsThunk({ ...query, page }));
    }, [dispatch]);
    */

    const handleFilterChange = (key: string, value: any) => {
        setQuery((prevQuery) => ({ ...prevQuery, [key]: value }));
        setPage(1); // Reset page to 1 when query changes
    };

    const handleItemClick = (id:string) => {
        navigate(`/dashboard/returns-request/detail/${id}`)
    };

    dispatch(dispatchLoadReturnRequest(exampleReturnRequests))

    return (
        <div>
            <DashboardHeader title="Return Request List"/>
            <Filters 
                query={query}
                onQueryChange={handleFilterChange}
            />
            <div className={styles.content}>
                {/* Handle loading state */}
                {loading && <LoadingIndicatorV1 />}
                
                {/* Handle error state */}
                {error && <ErrorBannerV1 message={typeof error === 'string' ? error : 'An error occurred'} />}
                
                {/* Display product list if not loading and no error */}
                {<ReturnRequestList/>}
            </div>
        </div>
    )
};

export default ReturnRequestPage