import React from 'react';
import { EmailTemplateType } from '../../../../types/Data/EmailTemplateType';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Store/store';
import { ProductType } from '../../../../types/Data/ProductType';
import { CustomerType } from '../../../../types/Data/CustomerType';

const ReengagementEmail:React.FC<EmailTemplateType> = ({ data }) => {
    const styles: {[key:string]: React.CSSProperties} = {
        emailContainer: {
            maxWidth: '100%',
            margin: '20px auto',
            padding: '20px',
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            animation: 'fadeIn 1s ease-in-out'
        },
        header: {
            backgroundColor: '#1a1a2e',
            color: '#fff',
            padding: '20px',
            textAlign: 'center',
            borderRadius: '10px 10px 0 0'
        },
        headerH1: {
            margin: '0',
            fontSize: '1.8em'
        },
        body: {
            padding: '20px'
        },
        bodyH2: {
            color: '#4bc0c0'
        },
        bodyP: {
            color: '#333',
            lineHeight: '1.5'
        },
        specialOffer: {
            fontSize: '1.5em',
            color: '#4bc0c0',
            fontWeight: 'bold',
            margin: '20px 0'
        },
        callToAction: {
            display: 'inline-block',
            backgroundColor: '#1a1a2e',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '5px',
            textDecoration: 'none',
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: '20px',
            transition: 'background-color 0.3s'
        },
        callToActionHover: {
            backgroundColor: '#14142b'
        },
        recommendedProducts: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
        },
        productCard: {
            backgroundColor: '#f4f4f4',
            padding: '10px',
            margin: '10px 0',
            borderRadius: '5px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            width: '48%'
        },
        productImage: {
            width: '100%',
            borderRadius: '5px',
            marginBottom: '10px'
        },
        productInfo: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: '#333'
        },
        footer: {
            backgroundColor: '#f4f4f4',
            color: '#333',
            textAlign: 'center',
            padding: '20px',
            borderRadius: '0 0 10px 10px'
        },
        footerP: {
            margin: '0',
            fontSize: '0.9em'
        }
    };

    const products = useSelector<RootState>((state) => 
        state.dashboard.products.products.filter((product) => 
            data?.productIds?.includes(product._id as string) ?? false
        )
    ) as Partial<ProductType>[];

    const customer = useSelector<RootState>((state)=>state.dashboard.customers.customers.find((customer)=>customer._id === data?.customerId)) as Partial<CustomerType>

    return (
        <div style={styles.emailContainer}>
            <style>
            {`@keyframes fadeIn {
                from {
                    opacity: 0;
                    transform: translateY(-10px);
                }
                to {
                    opacity: 1;
                    transform: translateY(0);
                }
            }`}
            </style>
            <header style={styles.header}>
                <h1 style={styles.headerH1}>We Miss You!</h1>
            </header>
            <section style={styles.body}>
                <h2 style={styles.bodyH2}>Hi {customer?.name},</h2>
                <p style={styles.bodyP}>It's been a while since we last saw you. To show you how much we care, we're offering you a special deal:</p>
                {data?.body &&
                <div
                    style={styles.specialOffer}
                    dangerouslySetInnerHTML={{ __html: data.body }}
                />}
                <a
                    href={data?.importantLinks![0].url}
                    style={styles.callToAction}
                    onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = styles?.callToActionHover?.backgroundColor!;
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = styles?.callToAction?.backgroundColor!;
                    }}
                >
                    {data?.importantLinks![0].label}
                </a>
                <h3>Recommended For You</h3>
                <div style={styles.recommendedProducts}>
                    {products!.map((product, index) => (
                        <div key={index} style={styles.productCard}>
                            <img src={product?.images![0].url} alt={product?.name} style={styles.productImage} />
                            <div style={styles.productInfo}>
                                <span>{product?.name}</span>
                                <span>{product?.price!.toFixed(2)}€</span>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <footer style={styles.footer}>
                <p style={styles.footerP}>Thank you for being a valued customer!</p>
            </footer>
        </div>
    );
};

export default ReengagementEmail;
