import React from 'react';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';

import styles from './TaxSettingsPage.module.scss';
import TaxSettingsForm from './TaxSettingsForm';

const TaxSettingsPage: React.FC = () => {
  return (
    <div className={styles.tax_settings_page}>
      <DashboardHeader title="Tax Settings" />
      <TaxSettingsForm />
    </div>
  );
};

export default TaxSettingsPage;
