import React, { useEffect, useState } from 'react';
import styles from './CustomersFeedback.module.scss';
import FeedbackStats from './FeedbackStats/FeedbackStats';
import DashboardHeader from '../../components/Header/DashboardHeader/DashboardHeader';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store/store';
import TableInteractive from '../../components/Tableau/TableInteractive/TableInteractive';
import Filters from './FeedbackFilters/FeedbackFilters';
import { useNavigate } from 'react-router-dom';
import { CustomerFeedbackType } from '../../types/Data/feedback';
import LoadingIndicatorV1 from '../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import { fetchAllFeedbacks } from '../DashboardState/customerFeedbacks/customerFeedbacksSlice';
import CustomerFeedbackList from './CustomerFeedbackList/CustomerFeedbackList';


const CustomersFeedbackPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [query, setQuery] = useState({
    search:"",
    status: "",
    rating: undefined,
  });

  const [page, setPage] = useState(1); // Separate page state
    
  const customersFeedbacks = useSelector<RootState>((state) => state.dashboard.customerFeedback.customerFeedbacks) as Partial<CustomerFeedbackType>[];
  const loading = useSelector<RootState, boolean>((state) => state.dashboard.orders.loading);
  const error = useSelector<RootState, string | null>((state) => state.dashboard.orders.error);

  const handleFilterChange = (key: string, value: any) => {
    setQuery((prevQuery) => ({ ...prevQuery, [key]: value }));
    setPage(1); // Reset page to 1 when query changes
  };

  const handleViewFeedback = (id: string) => {
    navigate(`./${id}`);
  };

  /*
  useEffect(() => {
    dispatch(fetchAllFeedbacks({ ...query, page }));
  },[query, dispatch]);
  */
    
  return (
    <div className={styles.feedback_page}>
      <DashboardHeader title='Customer Feedback'/>
      <div className={styles.content}>
        <FeedbackStats feedbackData={customersFeedbacks} />
        <Filters 
          query={query}
          onQueryChange={handleFilterChange}
        />
        <div className={styles.orders_array}>
          {/* Handle loading state */}
          {loading && <LoadingIndicatorV1 />}
                
          {/* Handle error state */}
          {error && <ErrorBannerV1 message={typeof error === 'string' ? error : 'An error occurred'} />}
                
          {/* Display product list if not loading and no error */}
          {
            <CustomerFeedbackList/>
          }
          </div>
      </div>
    </div>
  );
};

export default CustomersFeedbackPage;
