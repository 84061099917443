import React from 'react';
import styles from './ButtonBuyV1.module.scss';
import { ButtonComponentProps } from '../../../../components/Button/ButtonComponentProps';

const ButtonBuyV1: React.FC<ButtonComponentProps> = () => (
  <button className={styles.buyButton}>
    Buy Now
  </button>
);

export default ButtonBuyV1;
