import React from 'react';
import styles from './AlertV2.module.scss';
import { FaCheckCircle } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { AlertComponentProps } from '../Type/AlertType';
import { alertDefaultProps } from '../alertDefaultProps/alertDefaultProps';

const AlertV2: React.FC<AlertComponentProps> = ({ 
  title = alertDefaultProps.title,
  message = alertDefaultProps.message, 
  onClose = alertDefaultProps.onClose
}) => {
  return (
    <div className={styles.success_alert}>
      <div className={styles.success_alert_check_icon}>
        <FaCheckCircle className={styles.icon}/>
      </div>
      <div>
        <h3>{title}</h3>
        <p className={styles.success_alert_message}>{message}</p>
      </div>
      <div className={styles.success_alert_close_button} onClick={onClose}>
        <IoClose/>
      </div>
    </div>
  );
};

export default AlertV2;
