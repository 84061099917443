import { ProductType, StockStatus } from "../../../types/Data/ProductType";

const techProducts: Partial<ProductType>[] = [
    {
        _id: '1',
        name: 'Apple iPhone 15 Pro',
        description: '5G-enabled smartphone with the new A16 Bionic chip and ProMotion display.',
        categories: ['Phones'],
        tags: ['new', 'featured'],
        price: 1199.99,
        discountPrice: 1099.99,
        currency: 'USD',
        images: [
            { url: 'https://m.media-amazon.com/images/I/81NDDPNpt4L._AC_UF1000,1000_QL80_.jpg', alt: 'iPhone 15 Pro Front' },
            { url: 'https://mobile.free.fr/webpublic/Master_Phone_Apple_21_286c39d0ca.png', alt: 'iPhone 15 Pro Back' }
        ],
        thumbnail: 'https://mobile.free.fr/webpublic/Master_Phone_Apple_37769baac0.png',
        stockStatus: StockStatus.IN_STOCK,
        sku: 'IPH15PRO',
        rating: 4.9,
        attributes: [
            { name: 'Color', value: 'Graphite' },
            { name: 'Storage', value: '256GB' }
        ],
        variants: [
            {
              name: 'Color',
              options: ['Graphite', 'Silver', 'Gold', 'Sierra Blue']
            },
            {
              name: 'Storage',
              options: ['128GB', '256GB', '512GB', '1TB']
            }
        ],
        variantComination: [
            {
              options: { 'Color': 'Graphite', 'Storage': '256GB' },
              price: 1199.99,
              discountPrice: 1099.99,
              stockLevel: 10,
              sku: 'IPH15PRO-GRAPHITE-256',
              images: [
                { url: 'https://m.media-amazon.com/images/I/81NDDPNpt4L._AC_UF1000,1000_QL80_.jpg', alt: 'iPhone 15 Pro Graphite Front' },
                { url: 'https://mobile.free.fr/webpublic/Master_Phone_Apple_21_286c39d0ca.png', alt: 'iPhone 15 Pro Graphite Back' }
              ],
              isActive: true,
            },
            {
              options: { 'Color': 'Silver', 'Storage': '256GB' },
              price: 1199.99,
              discountPrice: 1099.99,
              stockLevel: 8,
              sku: 'IPH15PRO-SILVER-256',
              images: [
                { url: 'https://example.com/silver_front.jpg', alt: 'iPhone 15 Pro Silver Front' },
                { url: 'https://example.com/silver_back.jpg', alt: 'iPhone 15 Pro Silver Back' }
              ],
              isActive: true,
            },
            {
              options: { 'Color': 'Gold', 'Storage': '512GB' },
              price: 1399.99,
              discountPrice: 1299.99,
              stockLevel: 5,
              sku: 'IPH15PRO-GOLD-512',
              images: [
                { url: 'https://example.com/gold_front.jpg', alt: 'iPhone 15 Pro Gold Front' },
                { url: 'https://example.com/gold_back.jpg', alt: 'iPhone 15 Pro Gold Back' }
              ],
              isActive: true,
            },
            {
              options: { 'Color': 'Sierra Blue', 'Storage': '1TB' },
              price: 1599.99,
              discountPrice: 1499.99,
              stockLevel: 2,
              sku: 'IPH15PRO-SIERRABLUE-1TB',
              images: [
                { url: 'https://example.com/blue_front.jpg', alt: 'iPhone 15 Pro Sierra Blue Front' },
                { url: 'https://example.com/blue_back.jpg', alt: 'iPhone 15 Pro Sierra Blue Back' }
              ],
              isActive: true,
            }
        ],
        createdAt: new Date(),
        updatedAt: new Date()
    },
    {
        _id: '2',
        name: 'Samsung Galaxy Z Fold 5',
        description: 'Foldable phone with dynamic AMOLED 2X display and multitasking capabilities.',
        categories: ['Phones'],
        tags: ['new'],
        price: 1799.99,
        discountPrice: 1699.99,
        currency: 'USD',
        images: [
            { url: 'https://auixaysgjr.cloudimg.io/https://cdn.easycash.fr/img/prod/0/0/5/1/5/3/1/9/0/src/fr-galaxy-z-fold5-f946-sm-f946blbceub-thumb-537292576.jpg?ci_sign=c6185ba34e36628b4c6fe1cdbd4dc7f2ab43d521', alt: 'Galaxy Z Fold 5 Front' },
            { url: 'https://ss7.vzw.com/is/image/VerizonWireless/samsung-q5-icy-blue?$device-lg$', alt: 'Galaxy Z Fold 5 Back' }
        ],
        thumbnail: 'https://auixaysgjr.cloudimg.io/https://cdn.easycash.fr/img/prod/0/0/5/1/5/3/1/9/0/src/fr-galaxy-z-fold5-f946-sm-f946blbceub-thumb-537292576.jpg?ci_sign=c6185ba34e36628b4c6fe1cdbd4dc7f2ab43d521',
        stockStatus: StockStatus.IN_STOCK,
        sku: 'GALZFOLD5',
        rating: 4.8,
        attributes: [
            { name: 'Color', value: 'Phantom Black' },
            { name: 'Storage', value: '512GB' }
        ],
        createdAt: new Date(),
        updatedAt: new Date()
    },
    {
        _id: '3',
        name: 'Dell Alienware m15 R6',
        description: 'Powerful gaming laptop with NVIDIA RTX 3080 and 165Hz refresh rate.',
        categories: ['Laptops', 'Gaming Gear'],
        tags: ['new', 'gaming'],
        price: 2499.99,
        discountPrice: 2399.99,
        currency: 'USD',
        images: [
            { url: 'https://www.notebookcheck.biz/uploads/tx_nbc2/Alienwarem15R6__1__06.png', alt: 'Alienware m15 Front' },
            { url: 'https://www.notebookcheck.biz/fileadmin/Notebooks/News/_nc3/AW_m15_R6_open_back_red_LED.png', alt: 'Alienware m15 Side' }
        ],
        thumbnail: 'https://www.notebookcheck.biz/uploads/tx_nbc2/Alienwarem15R6__1__06.png',
        stockStatus: StockStatus.IN_STOCK,
        sku: 'ALIENM15',
        rating: 4.7,
        attributes: [
            { name: 'Color', value: 'Dark Side of the Moon' },
            { name: 'Storage', value: '1TB SSD' }
        ],
        variants: [
            {
              name: 'Color',
              options: ['Phantom Black', 'Icy Blue', 'Cream']
            },
            {
              name: 'Storage',
              options: ['256GB', '512GB', '1TB']
            }
        ],
        variantComination: [
            {
              options: { 'Color': 'Phantom Black', 'Storage': '512GB' },
              price: 1799.99,
              discountPrice: 1699.99,
              stockLevel: 15,
              sku: 'GALZFOLD5-PBLACK-512',
              images: [
                { url: 'https://auixaysgjr.cloudimg.io/https://cdn.easycash.fr/img/prod/0/0/5/1/5/3/1/9/0/src/fr-galaxy-z-fold5-f946-sm-f946blbceub-thumb-537292576.jpg?ci_sign=c6185ba34e36628b4c6fe1cdbd4dc7f2ab43d521', alt: 'Galaxy Z Fold 5 Phantom Black Front' },
                { url: 'https://ss7.vzw.com/is/image/VerizonWireless/samsung-q5-icy-blue?$device-lg$', alt: 'Galaxy Z Fold 5 Phantom Black Back' }
              ],
              isActive: true,
            },
            {
              options: { 'Color': 'Icy Blue', 'Storage': '512GB' },
              price: 1799.99,
              discountPrice: 1699.99,
              stockLevel: 12,
              sku: 'GALZFOLD5-ICYBLUE-512',
              images: [
                { url: 'https://ss7.vzw.com/is/image/VerizonWireless/samsung-q5-icy-blue?$device-lg$', alt: 'Galaxy Z Fold 5 Icy Blue Front' },
                { url: 'https://ss7.vzw.com/is/image/VerizonWireless/samsung-q5-icy-blue?$device-lg$', alt: 'Galaxy Z Fold 5 Icy Blue Back' }
              ],
              isActive: true,
            },
            {
              options: { 'Color': 'Cream', 'Storage': '1TB' },
              price: 1999.99,
              discountPrice: 1899.99,
              stockLevel: 5,
              sku: 'GALZFOLD5-CREAM-1TB',
              images: [
                { url: 'https://example.com/cream_front.jpg', alt: 'Galaxy Z Fold 5 Cream Front' },
                { url: 'https://example.com/cream_back.jpg', alt: 'Galaxy Z Fold 5 Cream Back' }
              ],
              isActive: true,
            }
        ],
        createdAt: new Date(),
        updatedAt: new Date()
    },
    {
        _id: '4',
        name: 'Sony PlayStation 5',
        description: 'Next-gen gaming console with ray tracing, 4K gaming, and ultra-high-speed SSD.',
        categories: ['Gaming Gear'],
        tags: ['bestseller'],
        price: 499.99,
        discountPrice: 449.99,
        currency: 'USD',
        images: [
            { url: 'https://static.digitecgalaxus.ch/productimages/4/1/0/8/5/6/3/5/7/8/5/3/3/9/8/8/8/9/9/4dfaf1cb-d098-4880-b8c6-b259fbfc457a.png', alt: 'PS5 Console Front' },
            { url: 'https://www.max-e-informatique.com/wp-content/uploads/2022/02/PS5-300x214.png', alt: 'PS5 Controller' }
        ],
        thumbnail: 'https://static.digitecgalaxus.ch/productimages/4/1/0/8/5/6/3/5/7/8/5/3/3/9/8/8/8/9/9/4dfaf1cb-d098-4880-b8c6-b259fbfc457a.png',
        stockStatus: StockStatus.IN_STOCK,
        sku: 'PS5CONSOLE',
        rating: 4.9,
        attributes: [
            { name: 'Color', value: 'White' },
            { name: 'Storage', value: '825GB SSD' }
        ],
        variants: [
            {
              name: 'Edition',
              options: ['Standard', 'Digital']
            },
            {
              name: 'Storage',
              options: ['825GB SSD']
            }
        ],
        variantComination: [
            {
              options: { 'Edition': 'Standard', 'Storage': '825GB SSD' },
              price: 499.99,
              discountPrice: 449.99,
              stockLevel: 20,
              sku: 'PS5CONSOLE-STANDARD-825GB',
              images: [
                { url: 'https://static.digitecgalaxus.ch/productimages/4/1/0/8/5/6/3/5/7/8/5/3/3/9/8/8/8/9/9/4dfaf1cb-d098-4880-b8c6-b259fbfc457a.png', alt: 'PS5 Console Front' },
                { url: 'https://www.max-e-informatique.com/wp-content/uploads/2022/02/PS5-300x214.png', alt: 'PS5 Controller' }
              ],
              isActive: true,
            },
            {
              options: { 'Edition': 'Digital', 'Storage': '825GB SSD' },
              price: 399.99,
              discountPrice: 349.99,
              stockLevel: 15,
              sku: 'PS5CONSOLE-DIGITAL-825GB',
              images: [
                { url: 'https://example.com/ps5_digital_front.jpg', alt: 'PS5 Digital Edition Front' },
                { url: 'https://example.com/ps5_digital_controller.jpg', alt: 'PS5 Digital Edition Controller' }
              ],
              isActive: true,
            }
        ],
        createdAt: new Date(),
        updatedAt: new Date()
    },
    {
        _id: '5',
        name: 'Apple AirPods Pro (2nd Gen)',
        description: 'Noise-canceling wireless earphones with adaptive EQ and spatial audio.',
        categories: ['Earphones'],
        tags: ['new', 'featured'],
        price: 249.99,
        discountPrice: 229.99,
        currency: 'USD',
        images: [
            { url: 'https://cdsassets.apple.com/live/SZLF0YNV/images/sp/111851_sp880-airpods-Pro-2nd-gen.png', alt: 'AirPods Pro Front' },
            { url: 'https://products.mpowerpromo.com/SJ/AIRPODSPRO2/147410_576/202209/20220912214057-9082882s1am4b3s.png', alt: 'AirPods Pro Case' }
        ],
        thumbnail: 'https://cdsassets.apple.com/live/SZLF0YNV/images/sp/111851_sp880-airpods-Pro-2nd-gen.png',
        stockStatus: StockStatus.IN_STOCK,
        sku: 'AIRPRO2',
        rating: 4.8,
        attributes: [
            { name: 'Color', value: 'White' },
            { name: 'Battery Life', value: '24 hours with case' }
        ],
        createdAt: new Date(),
        updatedAt: new Date()
    },
    {
        _id: '6',
        name: 'Google Nest Hub Max',
        description: '10-inch smart display with Google Assistant and built-in camera for smart home control.',
        categories: ['Connected Home'],
        tags: ['smart home', 'new'],
        price: 229.99,
        discountPrice: 199.99,
        currency: 'USD',
        images: [
            { url: 'https://techxpressug.com/wp-content/uploads/2023/06/google-nest_hub-max.webp', alt: 'Nest Hub Max Front' },
            { url: 'https://crdms.images.consumerreports.org/prod/products/cr/models/400091-smart-speakers-google-nest-hub-max-10009090.png', alt: 'Nest Hub Max Side' }
        ],
        thumbnail: 'https://techxpressug.com/wp-content/uploads/2023/06/google-nest_hub-max.webp',
        stockStatus: StockStatus.IN_STOCK,
        sku: 'NESTHUBMAX',
        rating: 4.6,
        attributes: [
            { name: 'Color', value: 'Chalk' },
            { name: 'Screen Size', value: '10 inches' }
        ],
        createdAt: new Date(),
        updatedAt: new Date()
    },
    {
        _id: '7',
        name: 'Razer DeathAdder V2',
        description: 'Ergonomic gaming mouse with Razer Speedflex cable and optical sensor.',
        categories: ['Gaming Gear'],
        tags: ['gaming', 'bestseller'],
        price: 69.99,
        discountPrice: 59.99,
        currency: 'USD',
        images: [
            { url: 'https://www.csl-computer.com/fr/media/catalog/product/cache/5/image/3000x/9df78eab33525d08d6e5fb8d27136e95/r/a/razer_deathadder_v2_usb_maus-83519_01_3000px.png', alt: 'Razer DeathAdder V2 Front' },
            { url: 'https://cdn.itgadgetsonline.com/wp-content/uploads/2023/12/Razer-DeathAdder-V2-Pro-Ergonomic-Wireless-Gaming-Mouse-AP-Packaging-2.webp', alt: 'Razer DeathAdder V2 Side' }
        ],
        thumbnail: 'https://www.csl-computer.com/fr/media/catalog/product/cache/5/image/3000x/9df78eab33525d08d6e5fb8d27136e95/r/a/razer_deathadder_v2_usb_maus-83519_01_3000px.png',
        stockStatus: StockStatus.IN_STOCK,
        sku: 'RAZDEATHADDER',
        rating: 4.7,
        attributes: [
            { name: 'Color', value: 'Black' },
            { name: 'DPI', value: '20,000' }
        ],
        createdAt: new Date(),
        updatedAt: new Date()
    },
    {
        _id: '8',
        name: 'Bose Smart Soundbar 900',
        description: 'Premium soundbar with Dolby Atmos and voice assistants built-in.',
        categories: ['Connected Home', 'Audio'],
        tags: ['new', 'featured'],
        price: 899.99,
        discountPrice: 799.99,
        currency: 'USD',
        images: [
            { url: 'https://assets.bose.com/content/dam/cloudassets/Bose_DAM/Web/consumer_electronics/global/products/speakers/bose_smart_soundbar_900/product_silo_images/STEVIE_Angus_LAUNCH_AEM_PDP_ECOMGALLERY_WHITE_04.png/_jcr_content/renditions/cq5dam.web.320.320.png', alt: 'Bose Smart Soundbar 900 Front' },
            { url: 'https://assets.bose.com/content/dam/cloudassets/Bose_DAM/Web/consumer_electronics/global/products/speakers/bose_smart_soundbar_900/product_silo_images/Angus_Ecomm_Gallery_2_White.png/jcr:content/renditions/cq5dam.web.1920.1920.png', alt: 'Bose Smart Soundbar 900 Side' }
        ],
        thumbnail: 'https://assets.bose.com/content/dam/cloudassets/Bose_DAM/Web/consumer_electronics/global/products/speakers/bose_smart_soundbar_900/product_silo_images/STEVIE_Angus_LAUNCH_AEM_PDP_ECOMGALLERY_WHITE_04.png/_jcr_content/renditions/cq5dam.web.320.320.png',
        stockStatus: StockStatus.IN_STOCK,
        sku: 'BOSE900SB',
        rating: 4.8,
        attributes: [
            { name: 'Color', value: 'Black' },
            { name: 'Connectivity', value: 'Wi-Fi, Bluetooth, HDMI eARC' }
        ],
        createdAt: new Date(),
        updatedAt: new Date()
    },
    {
        _id: '13',
        name: 'Google Pixel 8 Pro',
        description: 'The latest Google Pixel with enhanced AI features and a 120Hz display.',
        categories: ['Electronics', 'Smartphones'],
        tags: ['new'],
        price: 999.99,
        discountPrice: 899.99,
        currency: 'USD',
        images: [
            { url: 'https://static.s-sfr.fr/media/catalogue/article/mobile/dppghdfh/3_Pixel8Pro_black_Front-and-Back__400x540px.png', alt: 'Pixel 8 Pro Front' },
            { url: 'https://crdms.images.consumerreports.org/prod/products/cr/models/412132-smartphones-google-pixel-8-pro-10037369.png', alt: 'Pixel 8 Pro Back' }
        ],
        thumbnail: 'https://static.s-sfr.fr/media/catalogue/article/mobile/dppghdfh/3_Pixel8Pro_black_Front-and-Back__400x540px.png',
        stockStatus: StockStatus.IN_STOCK,
        sku: 'PIXEL8PRO',
        rating: 4.7,
        attributes: [
            { name: 'Color', value: 'Obsidian' },
            { name: 'Storage', value: '256GB' }
        ],
        createdAt: new Date(),
        updatedAt: new Date()
    },
    {
        _id: '14',
        name: 'MacBook Pro 2023 M2 Max',
        description: 'Apple’s most powerful MacBook with the M2 Max chip and 14-inch Retina display.',
        categories: ['Electronics', 'Laptops'],
        tags: ['new'],
        price: 2799.99,
        discountPrice: 2599.99,
        currency: 'USD',
        images: [
            { url: 'https://yabloki.ua/media/cache/sylius_shop_product_original/87/59/noutbuk-macbook-pro-16-apple-m2-max-32gb-38-gpu-1tb-ssd-space-gray-2023-mnwa3_d12c3575-1580-411b-becf-13c05b42a337-1.png.webp', alt: 'MacBook Pro 2023 Front' },
            { url: 'https://www.usatoday.com/gcdn/presto/2023/01/17/USAT/d532400a-2949-4598-a8bd-664db4680a9f-Apple-MacBook-Pro-M2-Pro-and-M2-Max-hero_screen.png', alt: 'MacBook Pro 2023 Side' }
        ],
        thumbnail: 'https://yabloki.ua/media/cache/sylius_shop_product_original/87/59/noutbuk-macbook-pro-16-apple-m2-max-32gb-38-gpu-1tb-ssd-space-gray-2023-mnwa3_d12c3575-1580-411b-becf-13c05b42a337-1.png.webp',
        stockStatus: StockStatus.IN_STOCK,
        sku: 'MBP2023M2',
        rating: 4.9,
        attributes: [
            { name: 'Color', value: 'Space Gray' },
            { name: 'Storage', value: '1TB SSD' }
        ],
        variants: [
            {
              name: 'Color',
              options: ['Space Gray', 'Silver']
            },
            {
              name: 'Storage',
              options: ['512GB SSD', '1TB SSD', '2TB SSD']
            }
        ],
        variantComination: [
            {
              options: { 'Color': 'Space Gray', 'Storage': '1TB SSD' },
              price: 2799.99,
              discountPrice: 2599.99,
              stockLevel: 10,
              sku: 'MBP2023M2-SG-1TB',
              images: [
                { url: 'https://yabloki.ua/media/cache/sylius_shop_product_original/87/59/noutbuk-macbook-pro-16-apple-m2-max-32gb-38-gpu-1tb-ssd-space-gray-2023-mnwa3_d12c3575-1580-411b-becf-13c05b42a337-1.png.webp', alt: 'MacBook Pro 2023 Space Gray Front' },
                { url: 'https://www.usatoday.com/gcdn/presto/2023/01/17/USAT/d532400a-2949-4598-a8bd-664db4680a9f-Apple-MacBook-Pro-M2-Pro-and-M2-Max-hero_screen.png', alt: 'MacBook Pro 2023 Space Gray Side' }
              ],
              isActive: true,
            },
            {
              options: { 'Color': 'Silver', 'Storage': '512GB SSD' },
              price: 2499.99,
              discountPrice: 2299.99,
              stockLevel: 15,
              sku: 'MBP2023M2-SL-512GB',
              images: [
                { url: 'https://example.com/macbook_silver_front.png', alt: 'MacBook Pro 2023 Silver Front' },
                { url: 'https://example.com/macbook_silver_side.png', alt: 'MacBook Pro 2023 Silver Side' }
              ],
              isActive: true,
            },
            {
              options: { 'Color': 'Space Gray', 'Storage': '2TB SSD' },
              price: 3499.99,
              discountPrice: 3299.99,
              stockLevel: 5,
              sku: 'MBP2023M2-SG-2TB',
              images: [
                { url: 'https://example.com/macbook_space_gray_front_2tb.png', alt: 'MacBook Pro 2023 Space Gray 2TB Front' },
                { url: 'https://example.com/macbook_space_gray_side_2tb.png', alt: 'MacBook Pro 2023 Space Gray 2TB Side' }
              ],
              isActive: true,
            }
        ],
        createdAt: new Date(),
        updatedAt: new Date()
    },
    {
        _id: '15',
        name: 'Microsoft Surface Laptop 6',
        description: 'A sleek and lightweight laptop with a 13.5-inch PixelSense touchscreen.',
        categories: ['Electronics', 'Laptops'],
        tags: ['new'],
        price: 1499.99,
        discountPrice: 1399.99,
        currency: 'USD',
        images: [
            { url: 'https://cf-images.dustin.eu/cdn-cgi/image/format=auto,quality=75,width=828,,fit=contain/image/d2000010011228655/microsoft-surface-laptop-6-for-business-platinum-core-ultra-5-8gb-256gb-15.png', alt: 'Surface Laptop 6 Front' },
        ],
        thumbnail: 'https://cf-images.dustin.eu/cdn-cgi/image/format=auto,quality=75,width=828,,fit=contain/image/d2000010011228655/microsoft-surface-laptop-6-for-business-platinum-core-ultra-5-8gb-256gb-15.png',
        stockStatus: StockStatus.IN_STOCK,
        sku: 'SURFLAP6',
        rating: 4.7,
        attributes: [
            { name: 'Color', value: 'Matte Black' },
            { name: 'Storage', value: '512GB SSD' }
        ],
        createdAt: new Date(),
        updatedAt: new Date()
    },
    {
        _id: '17',
        name: 'Bose QuietComfort Ultra Earbuds',
        description: 'Noise-canceling wireless earbuds with immersive audio and 24-hour battery life.',
        categories: ['Electronics', 'Earphones'],
        tags: ['new'],
        price: 349.99,
        discountPrice: 299.99,
        currency: 'USD',
        images: [
            { url: 'https://assets.bose.com/content/dam/cloudassets/Bose_DAM/Web/consumer_electronics/global/products/headphones/qcue-headphonein/product_silo_images/AEM_PDP_GALLERY_BLACK-1.png/jcr:content/renditions/cq5dam.web.1920.1920.png', alt: 'Bose QuietComfort Earbuds Front' },
            { url: 'https://assets.bose.com/content/dam/cloudassets/Bose_DAM/Web/consumer_electronics/global/products/headphones/qcue-headphonein/images/AEM_PDP_IMG-9.png/jcr:content/renditions/cq5dam.web.320.320.png', alt: 'Bose QuietComfort Earbuds Side' }
        ],
        thumbnail: 'https://assets.bose.com/content/dam/cloudassets/Bose_DAM/Web/consumer_electronics/global/products/headphones/qcue-headphonein/product_silo_images/AEM_PDP_GALLERY_BLACK-1.png/jcr:content/renditions/cq5dam.web.1920.1920.png',
        stockStatus: StockStatus.IN_STOCK,
        sku: 'BOSEQCULTRA',
        rating: 4.8,
        attributes: [
            { name: 'Color', value: 'White' },
            { name: 'Battery Life', value: '24 hours' }
        ],
        createdAt: new Date(),
        updatedAt: new Date()
    },
];

export default techProducts;
