import { CartSummarySidebarComponentProps } from "./CartSummarySidebarComponentProps";

const defaultCartSummaryProps: CartSummarySidebarComponentProps = {
    items: [
      {
       productId: '1',
        name: 'Wireless Headphones',
        quantity: 1,
        unitPrice: 99.99,
        imageUrl: 'https://example.com/images/headphones.jpg',
        totalPrice: 99.99
      },
      {
        productId: '2',
        name: 'Bluetooth Speaker',
        quantity: 2,
        unitPrice: 49.99 * 2,
        imageUrl: 'https://example.com/images/speaker.jpg',
        totalPrice: 49.99 * 2
      },
    ],
    discount: 10, 
    tax: 7.5, 
    deliveryPrice: 5.99, 
    onCheckout: () => alert('Proceed to Checkout'),
  };

export {
    defaultCartSummaryProps
}
  