import CompanyOverviewV1 from "../../UIElements/CompanyOverview/CompanyOverviewV1/CompanyOverviewV1";
import CompanyOverviewV2 from "../../UIElements/CompanyOverview/CompanyOverviewV2/CompanyOverviewV2";
import CompanyOverviewV3 from "../../UIElements/CompanyOverview/CompanyOverviewV3/CompanyOverviewV3";

enum CompanyOverviewVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const CompanyOverviewMapping:Record<CompanyOverviewVersions, React.FC<any>> = {
    [CompanyOverviewVersions.V1]: CompanyOverviewV1,
    [CompanyOverviewVersions.V2]: CompanyOverviewV2,
    [CompanyOverviewVersions.V3]: CompanyOverviewV3,
};

interface CompanyOverviewProps {
  title?: string;
  description?: string;
  imageUrl?: string;
  missionStatement?: string;
};

export {
  CompanyOverviewVersions,
  CompanyOverviewMapping
};

export type {
  CompanyOverviewProps
};
  