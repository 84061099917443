import { EmailTemplateType } from "./EmailTemplateType";

enum EmailCampaignStatus {
    Draft = 'draft',
    Scheduled = 'scheduled',
    Sent = 'sent',
    InProgress = 'in-progress',
    Failed = 'failed'
}

interface EmailCampaignType {
    _id?: string;
    name: string;
    subject: string;
    scheduledDate?: Date | string;
    sentDate?: Date | string;
    template?: EmailTemplateType;
    htmlContent?: string;
    status?: EmailCampaignStatus;
    sentCount?: number | null;        // Number of emails sent
    openedCount?: number | null;      // Number of emails opened
    clickedCount?: number | null;     // Number of clicks in the email
    bounceCount?: number | null;      // Number of emails bounced
    unsubscribeCount?: number | null; // Number of unsubscribes
    segment?: string; // Reference to a segment
    createdAt?: Date | string;
    updatedAt?: Date | string;
}

export {
    EmailCampaignStatus
};

export type {
    EmailCampaignType
}
