import React, { useState } from "react";
import styles from "./OrderCustomerInfo.module.scss"; // Assuming you use CSS Modules for styling
import { CustomerType } from "../../../../types/Data/CustomerType";

interface CustomerInfoProps {
    customer: Partial<CustomerType>;
}

const OrderCustomerInfo: React.FC<CustomerInfoProps> = ({ 
    customer, 
}) => {

    return (
        <div className={styles.orderCustomerInfo}>
            <h2>Customer Information</h2>
            <div className={styles.section}>
                <p className={styles.label}><strong>Name:</strong> {customer.name}</p>
                <p className={styles.label}><strong>Email:</strong> {customer.email}</p>
                <p className={styles.label}><strong>Phone Number:</strong> {customer.phone || 'N/A'}</p>
            </div>
        </div>
    );
};

export default OrderCustomerInfo;
