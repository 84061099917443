import React from 'react'
import styles from '../Component.module.scss';
import OrderErrorV1 from '../../UIElements/OrderError/OrderErrorV1/OrderErrorV1';
import OrderErrorV2 from '../../UIElements/OrderError/OrderErrorV2/OrderErrorV2';
import OrderErrorV3 from '../../UIElements/OrderError/OrderErrorV3/OrderErrorV3';

const OrderErrorComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <OrderErrorV1/>
        </div>
        <div className={styles.element}>
            <OrderErrorV2/>
        </div>
        <div className={styles.element}>
            <OrderErrorV3/>
        </div>
    </div>
  )
}

export default OrderErrorComponentPage