import React from 'react';
import styles from './StepIndicatorV2.module.scss';
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';
import { StepIndicatorComponentProps } from '../StepIndicatorComponentProps';
import { defaultStepIndicatorProps } from '../stepIndicatorDefaultProps';

const StepIndicatorV2: React.FC<StepIndicatorComponentProps> = ({ 
  currentStep = defaultStepIndicatorProps.currentStep,
  steps = defaultStepIndicatorProps.steps,
  onStepClick = defaultStepIndicatorProps.onStepClick,
}) => {
  const currentIndex = steps?.indexOf(currentStep!)
  return (
    <div className={styles.stepIndicator}>
      {steps!.map((step, index) => (
        <div
          key={index}
          className={`${styles.step} ${index <= currentIndex! ? styles.active : ''}`}
          onClick={() => onStepClick && onStepClick(step)}
        >
          <div className={styles.icon}>
            {index < currentIndex! ? <FaCheckCircle /> : <FaRegCircle />}
          </div>
          <div className={styles.label}>{step}</div>
        </div>
      ))}
    </div>
  );
};

export default StepIndicatorV2;
