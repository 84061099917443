import HeroBannerV1 from "../../UIElements/HeorBanner/HeroBannerV1/HeroBannerV1";
import HeroBannerV2 from "../../UIElements/HeorBanner/HeroBannerV2/HeroBannerV2";
import HeroBannerV3 from "../../UIElements/HeorBanner/HeroBannerV3/HeroBannerV3";
import HeroBannerV4 from "../../UIElements/HeorBanner/HeroBannerV4/HeroBannerV4";
import HeroBannerV5 from "../../UIElements/HeorBanner/HeroBannerV5/HeroBannerV5";

enum HeroBannerVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
    V4 = "v4",
    V5 = "v5",   
}

const HeroBannerMapping:Record<HeroBannerVersions, React.FC<any>> = {
    [HeroBannerVersions.V1]: HeroBannerV1,
    [HeroBannerVersions.V2]: HeroBannerV2,
    [HeroBannerVersions.V3]: HeroBannerV3,
    [HeroBannerVersions.V4]: HeroBannerV4,
    [HeroBannerVersions.V5]: HeroBannerV5,
};

interface BannerSlideType {
    title?: string;
    text?: string;
    imageUrl?: string;
    videoUrl?: string;
    buttonText?: string;
    buttonUrl?: string;
    linkUrl?: string;
    linkTarget?: '_blank' | '_self' | '_parent' | '_top';
    customContent?: React.ReactNode;
  }


interface HeroBannerComponentProps {
    slides?: BannerSlideType[];
    layout?: 'left' | 'right' | 'top' | 'bottom';
    interval?: number; 
};

export {
    HeroBannerMapping,
    HeroBannerVersions,

};

export type {
    HeroBannerComponentProps,
};