import ShippingInformationFormV1 from "../../UIElements/ShippingInformationForm/ShippingInformationFormV1/ShippingInformationFormV1";
import ShippingInformationFormV2 from "../../UIElements/ShippingInformationForm/ShippingInformationFormV2/ShippingInformationFormV2";
import ShippingInformationFormV3 from "../../UIElements/ShippingInformationForm/ShippingInformationFormV3/ShippingInformationFormV3";

enum ShippingInformationFormVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const ShippingInformationFormMapping:Record<ShippingInformationFormVersions, React.FC<any>> = {
    [ShippingInformationFormVersions.V1]: ShippingInformationFormV1,
    [ShippingInformationFormVersions.V2]: ShippingInformationFormV2,
    [ShippingInformationFormVersions.V3]: ShippingInformationFormV3,
};

interface ShippingInformationComponentProps {
    fullName?: string;
    address?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    country?: string;
    onInputChange?: (field: string, value: string) => void;
    onFormSubmit?: () => void;
};

export {
    ShippingInformationFormVersions,
    ShippingInformationFormMapping
};

export type {
    ShippingInformationComponentProps
};