import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseElementType } from '../../../types/Data/BaseElementType';
import elementsExample from '../../../Dashboard/DashboardState/element/elementExample';
import { getAllElements } from '../../../api/element/elementApi';

// Fetch all elements
export const fetchAllElement = createAsyncThunk(
    'elements/fetchAll',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getAllElements();
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to load elements');
        }
    }
);

interface ModalState {
  isOpen: boolean;
  modalType: 'login' | 'signup' | 'cart' | 'none'; // Extendable modal types
}

interface NotificationState {
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
  isVisible: boolean;
}

interface UISliceState {
  elements: BaseElementType<any>[];
  loading: boolean;
  error: string | null
  theme: 'light' | 'dark';
  modal: ModalState;
  notification: NotificationState;
  isSidebarOpen: boolean;
}

const initialState: UISliceState = {
  elements: [...elementsExample],
  loading: false,
  error: null,
  theme: 'light',
  modal: {
    isOpen: false,
    modalType: 'none',
  },
  notification: {
    message: '',
    type: 'info',
    isVisible: false,
  },
  isSidebarOpen: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    // Toggle loading state
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    // Toggle theme
    toggleTheme(state) {
      state.theme = state.theme === 'light' ? 'dark' : 'light';
    },

    // Open a specific modal
    openModal(state, action: PayloadAction<ModalState['modalType']>) {
      state.modal = {
        isOpen: true,
        modalType: action.payload,
      };
    },

    // Close modal
    closeModal(state) {
      state.modal = {
        isOpen: false,
        modalType: 'none',
      };
    },

    // Show a notification
    showNotification(state, action: PayloadAction<Omit<NotificationState, 'isVisible'>>) {
      state.notification = {
        ...action.payload,
        isVisible: true,
      };
    },

    // Hide notification
    hideNotification(state) {
      state.notification.isVisible = false;
    },

    // Toggle sidebar
    toggleSidebar(state) {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
  },
  extraReducers: (builder) => {
    // Handle fetchAllElement
    builder
        .addCase(fetchAllElement.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchAllElement.fulfilled, (state, action) => {
            state.loading = false;
            state.elements = action.payload;
        })
        .addCase(fetchAllElement.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
    }
    },
);

export const {
  setLoading,
  toggleTheme,
  openModal,
  closeModal,
  showNotification,
  hideNotification,
  toggleSidebar,
} = uiSlice.actions;

export default uiSlice.reducer;
