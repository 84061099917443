import React, { useState } from 'react';
import { IoCartOutline, IoCheckmarkCircleOutline } from 'react-icons/io5';
import styles from './ButtonAddToCartV2.module.scss';
import { ButtonAddToCartComponentProps } from '../ButtonAddToCartComponentProps';

const ButtonAddToCartV2: React.FC<ButtonAddToCartComponentProps> = ({ onClick =()=>null}) => {
  const [added, setAdded] = useState(false);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    setAdded(true);
    setTimeout(() => setAdded(false), 2000);
  };

  return (
    <button className={`${styles.button} ${added ? styles.added : ''}`} onClick={handleClick}>
      <span className={styles.iconWrapper}>
        {added ? <IoCheckmarkCircleOutline className={styles.icon} /> : <IoCartOutline className={styles.icon} />}
      </span>
      {added ? 'Added!' : 'Add to Cart'}
    </button>
  );
};

export default ButtonAddToCartV2;
