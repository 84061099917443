import React, { useState, useEffect, useRef } from 'react';
import styles from './Select4.module.scss';

interface Option {
    value: string | number | any;
    label: string;
}

interface CustomSelectProps {
    label?: string;
    options: Option[];
    value: string | number | (string | number)[] | any; // Updated to allow array of values for multi-select
    onChange: (value: string | number | any | (string | number)[]) => void; // Updated to accept array of values
    placeholder?: string;
    customClassName?: string;
    wrapperStyle?: React.CSSProperties;
    isMulti?: boolean; // New prop to enable multi-select
}

const Select4: React.FC<CustomSelectProps> = ({ options, value, onChange, placeholder = "Select an option", customClassName, wrapperStyle, label, isMulti = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLabel, setSelectedLabel] = useState<string | number>("");
    const selectRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isMulti && Array.isArray(value)) {
            // Join selected labels for multi-select
            const selectedOptions = options.filter(option => value.includes(option.value));
            setSelectedLabel(selectedOptions.map(option => option.label).join(', '));
        } else {
            // Set label for single-select
            const selectedOption = options.find(option => option.value === value);
            if (selectedOption) {
                setSelectedLabel(selectedOption.label);
            } else {
                setSelectedLabel("");
            }
        }
    }, [value, options, isMulti]);

    const handleOptionClick = (selectedValue: string | number) => {
        if (isMulti && Array.isArray(value)) {
            // Toggle selection in multi-select mode
            const newSelectedValues = value.includes(selectedValue)
                ? value.filter(v => v !== selectedValue)
                : [...value, selectedValue];
            onChange(newSelectedValues);
        } else {
            onChange(selectedValue);
            setIsOpen(false);
        }
    };

    return (
        <div className={customClassName ? customClassName : styles.customSelect} style={{ ...wrapperStyle }} ref={selectRef}>
            {label && <p className={styles.label}>{label}</p>}
            <div className={styles.selectBox} onClick={() => setIsOpen(!isOpen)}>
                <span>{selectedLabel || placeholder}</span>
                <span className={styles.arrow}>{isOpen ? '▲' : '▼'}</span>
            </div>
            {isOpen && (
                <div className={styles.options}>
                    {options.map(option => (
                        <div
                            key={option.value}
                            className={`${styles.option} ${isMulti && Array.isArray(value) && value.includes(option.value) ? styles.selected : ''}`}
                            onClick={() => handleOptionClick(option.value)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Select4;
