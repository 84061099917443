import React, { ReactNode } from "react";
import Select4 from "../../../../components/Select/Select4/Select4";
import styles from "./OrderStatus.module.scss"; // Assuming you use CSS Modules for styling
import { OrderType } from "../../../../types/Data/OrderType";

interface OrderInfoProps {
    order: OrderType;
    onOrderStatusChange?: (status: string) => void;
    children?: ReactNode
}

const OrderStatus: React.FC<OrderInfoProps> = ({ order, onOrderStatusChange, children }) => {

    const orderStatus = [
        { label: 'Pending', value: 'pending' },
        { label: 'Confirmed', value: 'confirmed' },
        { label: 'Completed', value: 'completed' },
        { label: 'Shipped', value: 'shipped' },
        { label: 'Cancelled', value: 'cancelled' }
    ];

    const handleOrderStatusChange = (status: string) => {
        if (onOrderStatusChange) {
            onOrderStatusChange(status);
        }
    };

    return (
        <div className={styles.orderStatus}>
            <h2>Order Status</h2>
                <strong className={styles.label}>Order Status:</strong>
                <Select4
                    onChange={(status) => handleOrderStatusChange(status as string)}
                    options={orderStatus}
                    value={order.status}
                    wrapperStyle={{marginTop:8}} 
                />
                { children && children}
        </div>
    );
};

export default OrderStatus;
