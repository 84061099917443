import React, { useState } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles for the text editor
import styles from './RichTextEditorV1.module.scss'; // Import your custom styles
import { RichTextEditorCommponentProps } from '../RichTextEditorComponentProps';

const RichTextEditorV1: React.FC<RichTextEditorCommponentProps> = ({ value = "", onChange = ()=>null, wrapperStyle, label, ...props }) => {
  return (
    <div className={styles.editorContainer} style={{...wrapperStyle}}>
      {label && <p className={styles.label}>{label}</p>}
      <ReactQuill theme='snow' className={styles.quill_editor} value={value} onChange={onChange} {...props} />
    </div>
  );
};

export default RichTextEditorV1;