import axios, { AxiosResponse } from 'axios';
import api from '../config';
import { CategoryType } from '../../types/Data/CategoryType';
import { ApiResponse } from '../apiType';

// Get all categories
export const getAllCategories = async (): Promise<AxiosResponse<ApiResponse<CategoryType[]>>> => {
  const response: AxiosResponse<ApiResponse<CategoryType[]>> = await api.get('/categories');
  return response;
};

// Get a single category by ID
export const getCategoryById = async (id: string): Promise<AxiosResponse<ApiResponse<CategoryType>>> => {
  const response: AxiosResponse<ApiResponse<CategoryType>> = await api.get(`/categories/${id}`);
  return response;
};

// Get subcategories of a specific category
export const getSubcategories = async (id: string): Promise<AxiosResponse<ApiResponse<CategoryType[]>>> => {
  const response: AxiosResponse<ApiResponse<CategoryType[]>> = await api.get(`/categories/subCategories/${id}`);
  return response;
};

// Search categories
export const searchCategories = async (query: string): Promise<AxiosResponse<ApiResponse<CategoryType[]>>> => {
  const response: AxiosResponse<ApiResponse<CategoryType[]>> = await api.get('/categories/search', {
    params: {
      q: query,
    },
  });
  return response;
};


// Create a new category
export const createCategory = async (categoryData: CategoryType): Promise<AxiosResponse<ApiResponse<CategoryType>>> => {
  const response: AxiosResponse<ApiResponse<CategoryType>> = await api.post('/categories', categoryData, {});
  return response;
};

// Update an existing category
export const updateCategory = async (id: string, categoryData: Partial<CategoryType>): Promise<AxiosResponse<ApiResponse<CategoryType>>>=> {
  const response: AxiosResponse<ApiResponse<CategoryType>> = await api.put(`/categories/${id}`, categoryData, {});
  return response;
};

// Delete a category
export const deleteCategory = async (id: string): Promise<void> => {
  await api.delete(`/categories/${id}`, {});
};

// Toggle category active status
export const toggleCategoryActiveStatus = async (id: string): Promise<AxiosResponse<ApiResponse<CategoryType>>> => {
  const response: AxiosResponse<ApiResponse<CategoryType>> = await api.put(`/categories/${id}/toggleStatus`, null, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, // Adjust based on your token storage
    },
  });
  return response;
};
