import React, { useState } from 'react';
import styles from './QuantitySelectorV2.module.scss';
import { QuantitySelectorComponentProps } from '../QuantitySelectorComponentProps';

const QuantitySelectorV2:React.FC<QuantitySelectorComponentProps> = () => {
  const [quantity, setQuantity] = useState(1);

  const handleSliderChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(Number(event.target.value));
  };

  return (
    <div className={styles.slider_selector}>
      <input
        type="range"
        min="1"
        max="10"
        value={quantity}
        onChange={handleSliderChange}
        className={styles.slider}
      />
      <span className={styles.quantity_display}>{quantity}</span>
    </div>
  );
};

export default QuantitySelectorV2;
