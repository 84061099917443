import React from 'react';
import styles from './ButtonV11.module.scss';

interface CancelButtonProps {
    label?: string;
    onClick?: () => void;
    disabled?: boolean;
}

const ButtonV11: React.FC<CancelButtonProps> = ({ label = "Cancel", onClick, disabled = false }) => {
    return (
        <button
            className={styles.cancelButton}
            onClick={onClick}
            disabled={disabled}
        >
            {label}
        </button>
    );
};

export default ButtonV11;