import { ReturnOrderItemType, ReturnReason, ReturnRequestType, ReturnStatus, ReturnItemCondition, RefundEligibility, ReturnAdminApprovalStatus } from "../../../types/Data/ReturnRequestType";

// Sample order items with additional fields
const sampleItems: ReturnOrderItemType[] = [
    { 
        product: 'P12345', 
        productName: 'Wireless Headphones', 
        quantity: 1, 
        price: 99.99, 
        isReturnable: true, 
        conditionAfterReturn: ReturnItemCondition.LikeNew, 
        exchangeEligible: false,
        refundRequested: true,
        refundEligibility: RefundEligibility.Eligible,
        adminApprovalStatus: ReturnAdminApprovalStatus.Pending
    },
    { 
        product: 'P67890', 
        productName: 'Bluetooth Speaker', 
        quantity: 1, 
        price: 49.99, 
        isReturnable: true, 
        conditionAfterReturn: ReturnItemCondition.Damaged, 
        exchangeEligible: true,
        refundRequested: true,
        refundEligibility: RefundEligibility.NotEligible,
        adminApprovalStatus: ReturnAdminApprovalStatus.Pending
    },
    { 
        product: 'P54321', 
        productName: 'USB-C Charging Cable', 
        quantity: 2, 
        price: 19.99, 
        isReturnable: false, 
        exchangeEligible: false,
        refundRequested: false,
        refundEligibility: RefundEligibility.NotEligible,
        adminApprovalStatus: ReturnAdminApprovalStatus.Pending
    },
];

// Updated sample return requests
const exampleReturnRequests: ReturnRequestType[] = [
    {
        _id: 'R1001',
        order: 'O1001',
        customer: 'C1001',
        customerName: 'John Doe',
        items: [sampleItems[0]], // Returning the Wireless Headphones
        status: ReturnStatus.Pending,
        shipmentInfo: {
            shippingCarrier: 'UPS',
            trackingNumber: 'TRACK123456',
            shippingMethod: 'Standard',
            shippingDate: new Date('2024-01-16')
        },
        lastUpdatedByAdminId: 'A1001',
        adminNotes: 'Initial request received. Awaiting item return for inspection.',
        createdAt: new Date('2024-01-15'),
        updatedAt: new Date('2024-01-16'),
    },
    {
        _id: 'R1002',
        order: 'O1002',
        customer: 'C1002',
        customerName: 'Jane Smith',
        items: [sampleItems[1]], // Returning the Bluetooth Speaker
        status: ReturnStatus.Approved,
        shipmentInfo: {
            shippingCarrier: 'FedEx',
            trackingNumber: 'TRACK789012',
            shippingMethod: 'Expedited',
            shippingDate: new Date('2024-01-12')
        },
        refundRequestId: 'F1002', // Assuming a refund request linked to this return
        lastUpdatedByAdminId: 'A1002',
        adminNotes: 'Return approved, refund initiated for eligible items.',
        createdAt: new Date('2024-01-10'),
        updatedAt: new Date('2024-01-12'),
    },
    {
        _id: 'R1003',
        order: 'O1003',
        customer: 'C1003',
        customerName: 'Alice Johnson',
        items: [sampleItems[2]], // Returning the USB-C Charging Cables
        status: ReturnStatus.Rejected,
        lastUpdatedByAdminId: 'A1003',
        adminNotes: 'Return request rejected due to non-returnable item policy.',
        createdAt: new Date('2024-01-05'),
        updatedAt: new Date('2024-01-07'),
    },
    {
        _id: 'R1004',
        order: 'O1004',
        customer: 'C1004',
        customerName: 'Bob Lee',
        items: [sampleItems[0], sampleItems[1]], // Returning Headphones and Speaker
        status: ReturnStatus.Returned,
        shipmentInfo: {
            shippingCarrier: 'DHL',
            trackingNumber: 'TRACK345678',
            shippingMethod: 'Standard',
            shippingDate: new Date('2024-01-20')
        },
        lastUpdatedByAdminId: 'A1004',
        adminNotes: 'Items received and inspected. Return process completed.',
        createdAt: new Date('2024-01-18'),
        updatedAt: new Date('2024-01-20'),
    },
];

export { exampleReturnRequests };
