import React, { useEffect, useState } from "react";
import DashboardHeader from "../../../components/Header/DashboardHeader/DashboardHeader";
import styles from './EditDiscountCode.module.scss'
import { DiscountCodeType } from "../../../types/Data/DiscountCodeType";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../../Store/store";
import { createDiscountCode, getDiscountCodeById, updateDiscountCode } from "../../../api/discountCode/discountCodeApi";
import { handleApiError } from "../../../api/apiError";
import LoadingIndicatorV1 from "../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1";
import ErrorBannerV1 from "../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1";
import DiscountCodeProduct from "./DiscountCodeProduct/DiscountCodeProduct";
import SaveButton from "../../../components/Button/SaveButton/SaveButton";
import ButtonCancel from "../../../components/Button/ButtonCancel/ButtonCancel";
import DiscountCodeConditions from "./DiscountCodeCondition/DiscountCodeCondition";
import DiscountCodeInfo from "./DiscountCodeInfo/DiscountCodeInfo";

interface DiscountCodeFormProps {
    onAddDiscountCode?: (discountCode: DiscountCodeType) => void;
};

const EditDiscountCodePage:React.FC<DiscountCodeFormProps> = ({onAddDiscountCode}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const discountCodeId = new URLSearchParams(location.search).get('discountCodeId');
    
    const discountCodeToEdit = useSelector<RootState>((state) => state.dashboard.discountCodes.discountCodes.find(el=>el._id === discountCodeId)) as DiscountCodeType;

    const defaultDiscountCode:DiscountCodeType = {
        name: '',
        description: '',
        code: '',
        type: 'percentage',
        value: 0,
        startDate: '',
        endDate: '',
        isActive: true,
        productIds: [],
        conditions: {
            minOrderValue: 0,
            customerSegment: '',
            productCategories: [],
        },
    };

    const [originalDiscountCode, setOriginalDiscountCode] = useState<DiscountCodeType>(discountCodeToEdit || defaultDiscountCode);
    const [currentDiscountCode, setCurrentDiscountCode] = useState<DiscountCodeType>(discountCodeToEdit || defaultDiscountCode)

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>();
     
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const getDiscountCode = async () => {
        setIsLoading(true) 
        try {
            const response = await getDiscountCodeById(discountCodeId as string)

            if (response.data) {
                return response.data
            } else {
                alert('An error occured!')
            }
        } catch (error:any) {
            const handledError = handleApiError(error);
            setError(handledError)
        } finally {
            setIsLoading(false)
        }
    }

    /*
    useEffect(() => {  
        if (discountCodeId) {
          getDiscountCode()
            .then(data => {
              if (data) {
                setOriginalDiscountCode(data);  // Set the original review
                setCurrentDiscountCode(data);   // Set the current review
              }
            })
            .catch(error => {
              let handledError = handleApiError(error);
              setError({ message: handledError.message, status: handledError.status });
            });
        } else {
            setOriginalDiscountCode(defaultDiscountCode);  // Set the original review
            setCurrentDiscountCode(defaultDiscountCode); 
        }
    }, [discountCodeId]);
    */
    
    const handleDiscountCodeChange = (discountCode: DiscountCodeType) => {
        setCurrentDiscountCode(discountCode);
    };

    // Function to handle updates to specific sections of the order
    const handleUpdateDiscountCode = (segment: Partial<DiscountCodeType>) => {
        const updatedRequest = { ...currentDiscountCode, ...segment };
        setCurrentDiscountCode(updatedRequest);
    };

    const handleCancel = () => {
        setCurrentDiscountCode(originalDiscountCode)
    }


    const handleSave = async () => {
        setIsSaving(true);
        try {
            const response = await createDiscountCode(currentDiscountCode);
            if (response) {
                alert('Discount code created')
            } else {
                alert('An error occured while saving discount code!')
            }
        } catch (error:any) {
            const handledError = handleApiError(error);
            alert(handledError.message)
        } finally {
            setIsSaving(false)
        }
    };

    const handleUpdate = async () => {
        setIsSaving(true);
        try {
            const response = await updateDiscountCode(currentDiscountCode._id as string, currentDiscountCode);
            if (response) {
                alert('Discount code updated')
            } else {
                alert('An error occured while updating discount code!')
            }
        } catch (error:any) {
            const handledError = handleApiError(error);
            alert(handledError.message)
        } finally {
            setIsSaving(false)
        }
    };

    if (isLoading) {
        return <LoadingIndicatorV1/>
    }

    if (error) {
        return <ErrorBannerV1 message={error.message} status={error.status}/>
    }

    return (
        <div className={styles.edit_discount_code}>
            <DashboardHeader title="Edit Discount Code"/>
            <div className={styles.content}>
                <DiscountCodeInfo
                    discountCode={currentDiscountCode}
                    onDiscountCodeChange={(discountCode:DiscountCodeType)=>handleDiscountCodeChange(discountCode)}
                />
                <DiscountCodeProduct
                    selectedProductIds={currentDiscountCode.productIds || []}
                    onProductsChange={(ids)=>handleUpdateDiscountCode({productIds:ids})}
                />
                <DiscountCodeConditions
                    discountCodeCondition={currentDiscountCode.conditions}
                    onConditionChange={(conditions)=>handleUpdateDiscountCode({conditions:conditions})}
                />
                <div style={{marginTop:20}}>
                    <SaveButton onClick={discountCodeId ? handleUpdate : handleSave} isSaving={isSaving}/>
                    <ButtonCancel style={{marginLeft: 10}} onClick={handleCancel}/>
                 </div>
            </div>
        </div>
       
    )
};

export default EditDiscountCodePage