import { BadgeMapping, BadgeVersions } from "../UIElements/Badge/BadgeComponentProps";
import { BillignInformationFormVersions, BillingInformationMapping } from "../UIElements/BillingInformationComponentForm/BillingInformationFormComponentProps";
import { BlogSectionMapping, BlogSectionVersions } from "../UIElements/BlogSection/BlogSectionComponentProps";
import { BreadcrumbMapping, BreadcrumbVersions } from "../UIElements/Breadcrumb/BreadcrumbComponentProps";
import { ButtonAddToCartMapping, ButtonAddToCartVersions } from "../UIElements/Button/ButtonAddToCart/ButtonAddToCartComponentProps";
import { ButtonBuyMapping, ButtonBuyVersions } from "../UIElements/Button/ButtonBuy/ButtonBuyComponentProps";
import { ButtonProceedToCheckoutMapping, ButtonProceedToCheckoutVersions } from "../UIElements/Button/ButtonProceedToCheckout/ButtonCheckoutComponent";
import { ButtonAddToWishlistMapping, ButtonAddToWishlistVersions } from "../UIElements/Button/ButtonWishList/ButtonAddToWishlistComponentProps";
import { CartItemListMapping, CartItemListVersions } from "../UIElements/CartItemList/CartItemListComponentProps";
import { CartSummarySidebarMapping, CartSummarySidebarVersions } from "../UIElements/CartSummarySidebar/CartSummarySidebarComponentProps";
import { CategoryDisplayMapping, CategoryDisplayVersions } from "../UIElements/CategoryDisplay/CategoryDisplayComponentProps";
import { ColorSelectorMapping, ColorSelectorVersions } from "../UIElements/ColorSelector/ColorSelectorComponentProps";
import { ContactFormMapping, ContactFormVersions } from "../UIElements/ContactForm/ContactFormComponentProps";
import { ContactInfoMapping, ContactInfoVersions } from "../UIElements/ContactInfo/ContactInfoComponentProps";
import { CouponPromotionMapping, CouponPromotionVersions } from "../UIElements/CouponPromotion/CouponPromotionComponentProps";
import { CustomerSupportMapping, CustomerSupportVersions } from "../UIElements/CustomerSupport/CustomerSupportComponentProps";
import { FeaturedProductsMapping, FeaturedProductsVersions } from "../UIElements/FeaturedProducts/FeaturedProductsComponentProps";
import { FeatureSectionMapping, FeatureSectionVersions } from "../UIElements/FeatureSection/FeatureSectionComponentProps";
import { FilterModalComponentMapping, FilterModalComponentVersions } from "../UIElements/FilterModal/FilterModalComponentProps";
import { HeroBannerMapping, HeroBannerVersions } from "../UIElements/HeorBanner/HeroBannerComponentProps";
import { IncentiveMapping, IncentiveVersions } from "../UIElements/Incentive/IncentiveComponentProps";
import { NewsLetterSignupMapping, NewsLetterSignupVersions } from "../UIElements/NewsLetterSignup/NewsLetterSignupProps";
import { OrderConfirmationMapping, OrderConfirmationVersions } from "../UIElements/OrderConfirmation/OrderConfirmationComponentProps";
import { OrderSummaryMapping, OrderSummaryVersions } from "../UIElements/OrderSummary/OrderSummaryComponentProps";
import { PaginationMapping, PaginationVersions } from "../UIElements/Pagination/PaginationComponentProps";
import { PaymentMethodSelectorMapping, PaymentMethodSelectorVersions } from "../UIElements/PaymentMethodSelector/PaymentMethodSelectorComponentProps";
import { ProductDescriptionMapping, ProductDescriptionVersions } from "../UIElements/ProductDescription/ProducrDescriptionComponentProps";
import { ProductImageGalleryMapping, ProductImageGalleryVersions } from "../UIElements/ProductImageGallery/ProductImageGalleryComponentProps";
import { ProductListMapping, ProductListVersions } from "../UIElements/ProductList/ProductListComponentProps";
import { ProductNameMapping, ProductNameVersions } from "../UIElements/ProductName/ProductTitleComponentProps";
import { ProductPriceMapping, ProductPriceVersions } from "../UIElements/ProductPrice/ProductPricingComponentProps";
import { ProductRatingMapping, ProductRattingVersions } from "../UIElements/ProductRating/ProductRatingComponentProps";
import { ProductReviewMapping, ProductReviewVersions } from "../UIElements/ProductReview/ProductReviewComponentProps";
import { PromotionalBannerMapping, PromotionalBannerVersions } from "../UIElements/PromotionalBanner/PromotionalBannerComponentProps";
import { QAListMapping, QAListVersions } from "../components/QAList/QAListComponentProps";
import { QuantitySelectorMapping, QuantitySelectorVersions } from "../UIElements/QuantitySelector/QuantitySelectorComponentProps";
import { ShippingInformationFormMapping, ShippingInformationFormVersions } from "../UIElements/ShippingInformationForm/ShippingInformationFormComponentProps";
import { SizeSelectorMapping, SizeSelectorVersions } from "../UIElements/SizeSelector/SizeSelectorComponentProps";
import { SocialMediaLinksMapping, SocialMediaLinksVersions } from "../UIElements/SocialMediaLinks/SocialMediaLnksComponentProps";
import { SortMapping, SortVersions } from "../UIElements/Sort/SortComponentProps";
import { StepIndicatorMapping, StepIndicatorVersions } from "../UIElements/StepIndicator/StepIndicatorComponentProps";
import { TestimonialMapping, TestimonialVersions } from "../UIElements/Testimonial/TestimonialComponentProps";
import { TrustBadgeIconMapping, TrustBadgeIconVersions } from "../UIElements/TrustBadgeIcons/TrustBadgeIconsComponentProps";
import { TrustSignalsMapping, TrustSignalsVersions } from "../UIElements/TrustSignals/TrustSignalsComponentProps";
import { VariantSelectorMapping, VariantSelectorVersions } from "../UIElements/VariantSelector/VariantSelectorComponentProps";
import { BaseElementType } from "../types/Data/BaseElementType";
import { CompanyHistoryMapping, CompanyHistoryVersions } from "../UIElements/CompanyHistory/CompanyHistoryComponentProps";
import { CompanyOverviewMapping, CompanyOverviewVersions } from "../UIElements/CompanyOverview/CompanyOverviewComponentProps";
import { HeroSectionMapping, HeroSectionVersions } from "../UIElements/HeroSection/HeroSectionComponentProps";
import { LogoCloudMapping, LogoCloudVersions } from "../UIElements/LogoClouds/LogoCloudComponentProps";
import { OrderErrorMapping, OrderErrorVersions } from "../UIElements/OrderError/OrderErrorComponentProps";
import { CustomerEditProfilComponentMapping, CustomerEditProfilComponentVersions } from "../UIElements/CustomerEditProfilForm/CustomerEditProfilComponentProps";
import { CustomerOrderHistoryComponentMapping, CustomerOrderHistoryComponentVersions } from "../UIElements/CustomerOrderHistory/CustomerOrderHistoryType";
import { CustomerWishListComponentMapping, CustomerWishListComponentVersions } from "../UIElements/CustomerWishList/CustomerWishListComponentProps";
import { CustomerOrderDetailComponentMapping, CustomerOrderDetailtComponentVersions } from "../UIElements/CustomerOrderDetail/customerOrderDetailComponentProps";
import { CustomerOrderTrackingComponentMapping, CustomerOrderTrackingComponentVersions } from "../UIElements/CustomerOrderTracking/customerOrderTrackingComponentProps";
import { CustomerProductReviewComponentMapping, CustomerProductReviewComponentVersions } from "../UIElements/CustomerProductReview/CustomerProductReviewComponentProps";
import { CustomerReturnRequestComponentMapping, CustomerReturnRequestComponentVersions } from "../UIElements/CustomerOrderReturnRequest/CustomerOrderReturnRequestComponentProps";
import { CustomerNavigationComponentMapping, CustomerNavigationComponentVersions } from "../UIElements/CustomerNavigation/CustomerNavigationComponentProps";
import { CustomerReturnHistoryComponentMapping, CustomerReturnHistoryComponentVersions } from "../UIElements/CustomerReturnHistory/CustomerReturnHistoryComponentProps";
import { CustomerMessageHistoryMapping } from "../UIElements/CustomerMessageHistory/CustomerMessageHistoryType";
import { CustomerChatRoomMapping, CustomerChatRoomVersions } from "../UIElements/CustomerChatRoom/CustomerChatRoomProps";

const InvalidComponent: React.FC = () => <div>Invalid Component</div>;

const renderPageElement = (element: BaseElementType<any>): React.FC<any> => {
    switch (element.type) {
        case 'trustSignals':
            return TrustSignalsMapping[element.version as TrustSignalsVersions];
        case 'billingInformation':
            return BillingInformationMapping[element.version as BillignInformationFormVersions];
        case 'customerSupport':
            return CustomerSupportMapping[element.version as CustomerSupportVersions];
        case 'orderSummary':
            return OrderSummaryMapping[element.version as OrderSummaryVersions];
        case 'paymentMethodSelector':
            return PaymentMethodSelectorMapping[element.version as PaymentMethodSelectorVersions];
        case 'shippingInformation':
            return ShippingInformationFormMapping[element.version as ShippingInformationFormVersions];
        case 'stepIndicator':
            return StepIndicatorMapping[element.version as StepIndicatorVersions];
        case 'cartSummarySidebar':
            return CartSummarySidebarMapping[element.version as CartSummarySidebarVersions];
        case 'couponPromotion':
            return CouponPromotionMapping[element.version as CouponPromotionVersions];
        case 'orderConfirmation':
            return OrderConfirmationMapping[element.version as OrderConfirmationVersions];
        case 'orderError':
            return OrderErrorMapping[element.version as OrderErrorVersions];
        case 'productImageGallery':
            return ProductImageGalleryMapping[element.version as ProductImageGalleryVersions];
        case 'productName':
            return ProductNameMapping[element.version as ProductNameVersions];
        case 'buttonAddToCart':
            return ButtonAddToCartMapping[element.version as ButtonAddToCartVersions];
        case 'buttonAddWishList':
            return ButtonAddToWishlistMapping[element.version as ButtonAddToWishlistVersions];
        case 'buttonBuy':
            return ButtonBuyMapping[element.version as ButtonBuyVersions];
        case 'productDescription':
            return ProductDescriptionMapping[element.version as ProductDescriptionVersions];
        case 'productPrice':
            return ProductPriceMapping[element.version as ProductPriceVersions];
        case 'productRating':
            return ProductRatingMapping[element.version as ProductRattingVersions];
        case 'quantitySelector':
            return QuantitySelectorMapping[element.version as QuantitySelectorVersions];
        case 'sizeSelector':
            return SizeSelectorMapping[element.version as SizeSelectorVersions];
        case 'productReviews':
            return ProductReviewMapping[element.version as ProductReviewVersions];
        case 'trustBadgeIcon':
            return TrustBadgeIconMapping[element.version as TrustBadgeIconVersions];
         case 'variantSelector':
            return VariantSelectorMapping[element.version as VariantSelectorVersions];
        case 'filterModal':
            return FilterModalComponentMapping[element.version as FilterModalComponentVersions];
        case 'productList':
          return ProductListMapping[element.version as ProductListVersions];
        case 'sort':
            return SortMapping[element.version as SortVersions];
        case 'breadcrumb':
            return BreadcrumbMapping[element.version as BreadcrumbVersions];
        case 'badge':
            return BadgeMapping[element.version as BadgeVersions];
        case 'heroBanner':
            return HeroBannerMapping[element.version as HeroBannerVersions];
        case 'pagination':
            return PaginationMapping[element.version as PaginationVersions];
        case 'cartItemList':
            return CartItemListMapping[element.version as CartItemListVersions];
        case 'orderSummary':
            return OrderSummaryMapping[element.version as OrderSummaryVersions];
        case 'buttonCheckout':
            return ButtonProceedToCheckoutMapping[element.version as ButtonProceedToCheckoutVersions];
        case 'couponPromotion':
            return CouponPromotionMapping[element.version as CouponPromotionVersions];
        case 'newsletterSignup':
            return NewsLetterSignupMapping[element.version as NewsLetterSignupVersions];
        case 'blogSection':
            return BlogSectionMapping[element.version as BlogSectionVersions];
        case 'socialMedia':
            return SocialMediaLinksMapping[element.version as SocialMediaLinksVersions];
        case 'categoryDisplay':
            return CategoryDisplayMapping[element.version as CategoryDisplayVersions];
        case 'featuredProducts':
            return FeaturedProductsMapping[element.version as FeaturedProductsVersions];
        case 'testimonial':
            return TestimonialMapping[element.version as TestimonialVersions];
        case 'incentive':
            return IncentiveMapping[element.version as IncentiveVersions];
        case 'featureSection':
            return FeatureSectionMapping[element.version as FeatureSectionVersions];
        case 'promotionalBanner':
            return PromotionalBannerMapping[element.version as PromotionalBannerVersions];
        case 'qaCard':
            return QAListMapping[element.version as QAListVersions];
        case 'contactForm':
            return ContactFormMapping[element.version as ContactFormVersions];
        case 'contactInformation':
            return ContactInfoMapping[element.version as ContactInfoVersions];
        case 'companyHistory':
            return CompanyHistoryMapping[element.version as CompanyHistoryVersions];
        case 'companyOverview':
            return CompanyOverviewMapping[element.version as CompanyOverviewVersions];
        case 'heroSection':
            return HeroSectionMapping[element.version as HeroSectionVersions];
        case 'logoCloud':
            return LogoCloudMapping[element.version as LogoCloudVersions];
        case 'customerEditProfilForm':
            return CustomerEditProfilComponentMapping[element.version as CustomerEditProfilComponentVersions];
        case 'customerOrderHistory':
            return CustomerOrderHistoryComponentMapping[element.version as CustomerOrderHistoryComponentVersions];
        case 'customerWishList':
            return CustomerWishListComponentMapping[element.version as CustomerWishListComponentVersions];
        case 'customerOrderDetail':
            return CustomerOrderDetailComponentMapping[element.version as CustomerOrderDetailtComponentVersions];
        case 'customerOrderTracking':
            return CustomerOrderTrackingComponentMapping[element.version as CustomerOrderTrackingComponentVersions];
        case 'customerProductReview':
            return CustomerProductReviewComponentMapping[element.version as CustomerProductReviewComponentVersions];
        case 'customerReturnRequest':
            return CustomerReturnRequestComponentMapping[element.version as CustomerReturnRequestComponentVersions];
        case 'customerMessageHistory':
            return CustomerMessageHistoryMapping[element.version as CustomerReturnRequestComponentVersions];
        case 'customerNavigation':
            return CustomerNavigationComponentMapping[element.version as CustomerNavigationComponentVersions];
        case 'customerReturnHistory':
                return CustomerReturnHistoryComponentMapping[element.version as CustomerReturnHistoryComponentVersions];
        case 'customerChatRoom':
            return CustomerChatRoomMapping[element.version as CustomerChatRoomVersions];
        default:
            return InvalidComponent;
    }
}

export default renderPageElement