import React, { useEffect, useState } from 'react';
import styles from './Badge2.module.scss';
import { IoCloseCircle } from 'react-icons/io5';
import { BadgeComponentProps, BadgeType } from '../BadgeComponentProps';
import { defaultBadges } from '../defaultBadgeProps';

const BadgeV2: React.FC<BadgeComponentProps> = ({ badges = defaultBadges, onBadgeChange = () => null }) => {
  const [badgeList, setBadgeList] = useState<BadgeType[]>(badges);

  const handleDelete = (index: number) => {
    const newBadgeList = badgeList.filter((_, i) => i !== index);
    setBadgeList(newBadgeList);
  };

  useEffect(() => {
    if (onBadgeChange) {
        onBadgeChange(badgeList)
    }
    
  },[badgeList]);

  return (
    <div className={styles.badges_list}>
      {badgeList.map((badge, index) => (
        <div key={index} className={styles.badge_container}>
          <span
            className={styles.badge}
            style={{ backgroundColor: badge.color || styles.default_color }}
          >
            {badge.label}
            <span
            className={styles.delete_icon}
            onClick={() => handleDelete(index)}
          >
            <IoCloseCircle/>
          </span>
          </span>
          
        </div>
      ))}
    </div>
  );
};

export default BadgeV2;