import React from 'react';
import styles from './IncentiveV6.module.scss';
import { IncentiveComponentProps } from '../IncentiveComponentProps';
import { getIconComponent } from '../IncentiveEditor/util';

const IncentiveV6: React.FC<IncentiveComponentProps> = ({ title, description, items }) => {
  return (
    <div className={styles.incentive_container}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>
      <div className={styles.card_list}>
        {(items ?? []).map((item, index) => {
            return(
                <div className={styles.incentive_card}>
                    <div className={styles.icon}>
                        {item?.icon ? getIconComponent(item?.icon) : null}
                    </div>
                    <h3 className={styles.title}>{item.title}</h3>
                    <p className={styles.description}>{item.description}</p>
     
                </div>
            )
        })}
      </div>
    </div>
  );
};

export default IncentiveV6;