import React, { useEffect, useState } from 'react';
import styles from '../../UIElementsMainSettings/UiElementEditor.module.scss';

import { TabNavigationComponentProps, NavigationItem, TabNavigationMapping, TabNavigationVersions } from '../TabNavigationComponentProps';
import TabNavigationCategoriesSelection from './TabNavigationCategoriesSelectionProps/TabavigationCategoriesSelection';
import { tabNavigationDefaultProps } from '../TabNavigationDefaultProps';
import RadioGroup1 from '../../../components/RadioGroup/RadioGroup1/RadioGroup1';
import ColorPickerV1 from '../../../components/ColorPicker/ColorPickerV1/ColorPickerV1';
import SliderV1 from '../../../components/Slider/SliderV1/SliderV1';
import { BaseElementType, ComponentType } from '../../../types/Data/BaseElementType';
import UIElementsMainSettings from '../../UIElementsMainSettings/UIElementsMainSettings';
import ButtonV1 from '../../../components/Button/ButtonV1/ButtonV1';
import ButtonDelete from '../../../components/Button/ButtonDelete/ButtonDelete';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../Store/store';
import { useSelector } from 'react-redux';
import { fetchCreateElement, fetchUpdateElement } from '../../../Dashboard/DashboardState/element/elementSlice';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';

interface TabNavigationEditorProps {
  element?: BaseElementType<TabNavigationComponentProps>;
}

const TabNavigationEditor: React.FC<TabNavigationEditorProps> = ({ element }) => {
  
  const defaultElement: BaseElementType<TabNavigationComponentProps> = {
    type: ComponentType.TabNavigation,
    version: 'v1',
    page: '',
    props: tabNavigationDefaultProps,
    position: undefined,
    isVisible: true,
  };

  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state:RootState)=>state.dashboard.elements.loading);
  const error = useSelector((state:RootState)=>state.dashboard.elements.error);

  const [currentElement, setCurrentElement] = useState<BaseElementType<TabNavigationComponentProps>>(element ? element : defaultElement);

  useEffect(() => {
    if (element) {
      setCurrentElement(element);
    } else {
      setCurrentElement(defaultElement);
    }
  }, [element]);
  

  const handleElementUpdate = (key: string, value: any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      [key]: value,
    }));
  };

  const handleElementVisibilityUpdate = () => {
    setCurrentElement((prevElement) => ({ ...prevElement, isVisible: !prevElement.isVisible }));
  };

  const handlePropsChange = (key:string, value:any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      props: {
        ...prevElement.props,
        [key]: value,
      }
    }));
  };

  const handleSaveElement = () => {
    if (currentElement?._id) {
      dispatch(fetchUpdateElement({id:currentElement._id, element:currentElement}))
    } else {
      dispatch(fetchCreateElement(currentElement))
    }
  };

  const SelectedComponent = TabNavigationMapping[currentElement.version as TabNavigationVersions] || TabNavigationMapping[TabNavigationVersions.V1];

  const elementsVersion = Object.keys(TabNavigationVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: TabNavigationVersions[key as keyof typeof TabNavigationVersions],
  }));

  return (
    <div className={styles.container}>
      { error && <ErrorBannerV1 message={error}/>}
      { loading && <LoadingIndicatorV1/>}
      <div className={styles.componentPreview}>
        <SelectedComponent {...currentElement.props} />
      </div>
      <div className={styles.componentEditor}>
        <UIElementsMainSettings
            element={currentElement}
            elementVersions={elementsVersion}
            onElementUpdate={handleElementUpdate}
            onVisibilitytUpdate={handleElementVisibilityUpdate}
        />
         <div className={styles.mainSettings}>

      <RadioGroup1
        name="showCart"
        options={['show', 'hide']}
        selectedValue={element?.props?.showCart ? 'show' : 'hide'}
        onChange={(value) => handlePropsChange('showCart', value === 'show')}
        title="Show Cart"
      />

      <RadioGroup1
        name="showUserIcon"
        options={['show', 'hide']}
        selectedValue={element?.props?.showUserIcon ? 'show' : 'hide'}
        onChange={(value) => handlePropsChange('showUserIcon', value === 'show')}
        title="Show User Icon"
      />

      <RadioGroup1
        name="headerStyle"
        options={['fixed', 'sticky', 'drawer']}
        selectedValue={element?.props?.headerStyle || 'fixed'}
        onChange={(value) => handlePropsChange('headerStyle', value)}
        title="Header Style"
      />

      <ColorPickerV1
        label="Background Color"
        color={element?.props?.backgroundColor}
        onChange={(color) => handlePropsChange('backgroundColor', color)}
      />

      <ColorPickerV1
        label="Text Color"
        color={element?.props?.textColor}
        onChange={(color) => handlePropsChange('textColor', color)}
      />

      <RadioGroup1
        name="fontStyle"
        options={['normal', 'italic', 'oblique']}
        selectedValue={element?.props?.fontStyle || 'normal'}
        onChange={(value) => handlePropsChange('fontStyle', value)}
        title="Font Style"
      />

      <SliderV1
        label="Header Height"
        min={50}
        max={200}
        value={element?.props?.headerHeight}
        onChange={(value) => handlePropsChange('headerHeight', value)}
      />

      <RadioGroup1
        name="borderStyle"
        options={['none', 'solid', 'dotted', 'dashed']}
        selectedValue={element?.props?.borderStyle || 'none'}
        onChange={(value) => handlePropsChange('borderStyle', value)}
        title="Border Style"
      />

      <RadioGroup1
        name="shadow"
        options={['show', 'hide']}
        selectedValue={element?.props?.shadow ? 'show' : 'hide'}
        onChange={(value) => handlePropsChange('shadow', value === 'show')}
        title="Header Shadow"
      />

      <RadioGroup1
        name="displayMode"
        options={['inline', 'flyout']}
        selectedValue={element?.props?.displayMode || 'inline'}
        onChange={(value) => handlePropsChange('displayMode', value)}
        title="Display Mode"
      />
       <TabNavigationCategoriesSelection
        items={element?.props?.items || []}
        onItemChange={(items)=>handlePropsChange('items', items)}
      />
      </div>
      </div>
      <div className={styles.buttonContainer}>
        <ButtonV1 label="Save Element" onClick={handleSaveElement} />
        <ButtonDelete/>
      </div>
    </div>
  );
};

export default TabNavigationEditor;
