import React from 'react';
import styles from './EmailCampaignStats.module.scss';

interface EmailCampaignStatsProps {
    sentCount?: number | null;
    openedCount?: number | null;
    clickedCount?: number | null;
    bounceCount?: number | null;
    unsubscribeCount?: number | null;
}

const EmailCampaignStats: React.FC<EmailCampaignStatsProps> = ({
    sentCount = 0,
    openedCount = 0,
    clickedCount = 0,
    bounceCount = 0,
    unsubscribeCount = 0,
}) => {
    return (
        <div className={styles.statsContainer}>
            <h2 className={styles.title}>Campaign Statistics</h2>
            <div className={styles.statRow}>
                <span className={styles.statLabel}>Emails Sent:</span>
                <span className={styles.statValue}>{sentCount}</span>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.statRow}>
                <span className={styles.statLabel}>Emails Opened:</span>
                <span className={styles.statValue}>{openedCount}</span>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.statRow}>
                <span className={styles.statLabel}>Clicks:</span>
                <span className={styles.statValue}>{clickedCount}</span>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.statRow}>
                <span className={styles.statLabel}>Bounces:</span>
                <span className={styles.statValue}>{bounceCount}</span>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.statRow}>
                <span className={styles.statLabel}>Unsubscribes:</span>
                <span className={styles.statValue}>{unsubscribeCount}</span>
            </div>
        </div>
    );
};

export default EmailCampaignStats;
