// src/components/ProductVariants.tsx
import React, { useState } from 'react';
import styles from './ProductAttributes.module.scss'
import { AttributeType } from '../../../../types/Data/ProductType';
import { IoTrash } from 'react-icons/io5';
import { FaSave, FaSyncAlt } from 'react-icons/fa';

interface ProductVariantsProps {
  attributes: AttributeType[];
  setAttributes: React.Dispatch<React.SetStateAction<AttributeType[]>>;
  variantsWrapper?: React.CSSProperties;
}

const ProductAttributes: React.FC<ProductVariantsProps> = ({ attributes = [], setAttributes, variantsWrapper }) => {
  const [newAttributeName, setNewAttributeName] = useState('');
  const [newValue, setNewValue] = useState('');
  const [selectedAttributeIndex, setSelectedAttributeIndex] = useState<number | null>(null);

  const handleAddAttribute = () => {
    if (newAttributeName && !attributes.some(attr => attr.name === newAttributeName)) {
      setAttributes([...attributes, { name: newAttributeName, value: '' }]);
      setNewAttributeName('');
    }
  };

  const handleDeleteAttribute = (index: number) => {
    setAttributes(attributes.filter((_, i) => i !== index));
  };

  const handleAddValue = (attributeIndex: number) => {
    if (newValue && selectedAttributeIndex === attributeIndex) {
      const updatedAttributes = attributes.map((attribute, index) =>
        index === attributeIndex
          ? { ...attribute, value: newValue }
          : attribute
      );
      setAttributes(updatedAttributes);
      setNewValue('');
      setSelectedAttributeIndex(null);
    }
  };

  return (
    <div className={styles.attributesWrapper} style={variantsWrapper}>
      <div className={styles.variantsContainer}>
        <div className={styles.addAttribute}>
          <input
            type="text"
            placeholder="Attribute Name"
            value={newAttributeName}
            onChange={(e) => setNewAttributeName(e.target.value)}
          />
          <button className={styles.addAttributeButton} onClick={handleAddAttribute}>Add Attribute</button>
        </div>
        {attributes.map((attribute, index) => (
          <div key={index} className={styles.attributes}>
            <div className={styles.attributeHeader}>
              <h3>{attribute.name}</h3>
            </div>
            <div className={styles.addValue}>
              <input
                type="text"
                placeholder={`Enter value for ${attribute.name}`}
                value={selectedAttributeIndex === index ? newValue : attribute.value}
                onChange={(e) => {
                  setSelectedAttributeIndex(index);
                  setNewValue(e.target.value);
                }}
              />
              <button 
                className={styles.update_button} 
                onClick={() => handleAddValue(index)}><FaSave/></button>
              <button 
                className={styles.delete_button} 
                onClick={() => handleDeleteAttribute(index)}>
                  <IoTrash/>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductAttributes;
