import TeamMemberCardV1 from "../../components/TeamMemberCard/TeamMemberCardV1/TeamMemberCardV1";
import TeamMemberCardV2 from "../../components/TeamMemberCard/TeamMemberCardV2/TeamMemberCardV2";
import TeamMemberCardV3 from "../../components/TeamMemberCard/TeamMemberCardV3/TeamMemberCardV3";

enum TeamMemberCardVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const TeamMemberCardMapping:Record<TeamMemberCardVersions, React.FC<any>> = {
    [TeamMemberCardVersions.V1]: TeamMemberCardV1,
    [TeamMemberCardVersions.V2]: TeamMemberCardV2,
    [TeamMemberCardVersions.V3]: TeamMemberCardV3,
};

interface TeamMemberComponentProps {
    name?: string;
    position?: string;
    photoUrl?: string;
    bio?: string;
    socialLinks?: {
      linkedin?: string;
      twitter?: string;
      github?: string;
    };
};

export {
  TeamMemberCardVersions,
  TeamMemberCardMapping
};

export type {
  TeamMemberComponentProps
};
  