import React, { useState } from 'react';
import styles from './CustomerNavigationV1.module.scss';
import { CustomerNavigationComponentProps } from '../CustomerNavigationComponentProps';
import customerNavigationDefaultProps from '../customerNavigationDefaultProps';

const CustomerNavigationV1: React.FC<CustomerNavigationComponentProps> = ({
    tabs=customerNavigationDefaultProps.tabs,
    onClick=customerNavigationDefaultProps.onClick,
    activeTab=customerNavigationDefaultProps.activeTab
}) => {

  const handleTabChange = (tab: string) => {
    onClick && onClick(tab)
  };

  return (
    <nav className={styles.customerNav}>
      <ul>
        {tabs?.map((tab) => {
            return (
                <li 
                className={activeTab === tab.name ? styles.active : ''}
                onClick={() => handleTabChange(tab.name)}
                >
                {tab.icon} {tab.name}
        </li>
            )
        })}
      </ul>
    </nav>
  );
};

export default CustomerNavigationV1;
