import React from 'react';
import styles from './ButtonGroupV1.module.scss';
import { ButtonGroupComponentProps } from '../ButtonGroupComponentProps';


const ButtonGroupV1 = <T extends string>({
  buttons = ["Button 1", "Button 2"] as T[], // Ensuring default values are treated as T[]
  onClick = () => null,
  buttonGroupeStyle,
  buttonStyle
  }: ButtonGroupComponentProps<T>) => {
  return (
    <div id={styles.buttonGroup1}>
      {buttons?.map((button, index) => (
        <button 
          key={index} 
          className={styles.button} 
          onClick={() => onClick(button)}
        >
          {button}
        </button>
      ))}
    </div>
  );
};

export default ButtonGroupV1;