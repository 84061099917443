import React from 'react';
import styles from './ProductRatingV1.module.scss';
import { IoStar, IoStarOutline, IoStarHalf } from 'react-icons/io5';
import { ProductRatingComponentProps } from '../ProductRatingComponentProps';

const ProductRatingV1:React.FC<ProductRatingComponentProps> = ({ rating = 2, maxStars = 5 }) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;
  const emptyStars = maxStars - fullStars - (hasHalfStar ? 1 : 0);

  return (
    <div className={styles.ratingContainer}>
      {Array(fullStars)
        .fill(0)
        .map((_, index) => (
          <IoStar key={`full-${index}`} className={styles.filledStar} />
        ))}
      {hasHalfStar && <IoStarHalf className={styles.halfStar} />}
      {Array(emptyStars)
        .fill(0)
        .map((_, index) => (
          <IoStarOutline key={`empty-${index}`} className={styles.outlineStar} />
        ))}
    </div>
  );
};

export default ProductRatingV1;
