import React from "react";
import styles from "./CategoryDisplayV1.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/store";
import { CategoryType } from "../../../types/Data/CategoryType";
import { CategoryDisplayComponentProps } from "../CategoryDisplayComponentProps";


const CategoryDisplayV1: React.FC<CategoryDisplayComponentProps> = ({selectedCategoriesId, onClick=()=>null}) => {
  
    const allCategories = useSelector<RootState>(state => state.dashboard.categories.categories) as CategoryType[];

    const categories = selectedCategoriesId
        ? allCategories.filter((category) => selectedCategoriesId.includes(category._id!))
        : allCategories.slice(0, 10); 

    return (
        <div className={styles.container}>
            <h3 className={styles.title}>Categories</h3>
            <div className={styles.categoryDisplayV1}>
                {categories.map((category) => (
                    <div key={category._id!} className={styles.categoryCard} onClick={()=>{onClick && onClick(category.name)}}>
                    <img src={category.image?.url} alt={category.name} className={styles.categoryImage} />
                    <div className={styles.categoryContent}>
                        <h5>{category.name}</h5>
                    </div>
                    </div>
                ))}
            </div>
        </div>
  );
};

export default CategoryDisplayV1;
