import React from 'react'
import styles from '../Component.module.scss';
import BillingInformationFormV1 from '../../UIElements/BillingInformationComponentForm/BillingInformationFormV1/BillingInformationFormV1';
import BillingInformationFormV3 from '../../UIElements/BillingInformationComponentForm/BillingInformationFormV3/BillingInformationFormV3';
import BillingInformationFormV2 from '../../UIElements/BillingInformationComponentForm/BillingInformationFormV2/BillingInformationFormV2';

const BillingInformationFormComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <BillingInformationFormV1/>
        </div>
        <div className={styles.element}>
            <BillingInformationFormV2/>
        </div>
        <div className={styles.element}>
            <BillingInformationFormV3/>
        </div>
    </div>
  )
}

export default BillingInformationFormComponentPage