import ButtonProceedToCheckoutV1 from "./ButtonProceedToCheckoutV1/ButtonProceedToCheckoutV1";
import ButtonProceedToCheckoutV2 from "./ButtonProceedToCheckoutV2/ButtonProceedToCheckoutV2";


enum ButtonProceedToCheckoutVersions {
    V1 = "v1",
    V2 = "v2",
};

const ButtonProceedToCheckoutMapping:Record<ButtonProceedToCheckoutVersions, React.FC<any>> = {
    [ButtonProceedToCheckoutVersions.V1]: ButtonProceedToCheckoutV1,
    [ButtonProceedToCheckoutVersions.V2]: ButtonProceedToCheckoutV2,
};

interface ButtonProceedToCheckoutProps {
    label?:string;
    onClick?:()=>void;
};

export {
    ButtonProceedToCheckoutVersions,
    ButtonProceedToCheckoutMapping
};

export type {

    ButtonProceedToCheckoutProps
};



