import React, { useEffect, useState } from 'react';
import PromotionList from './PromotionList/PromotionList';
import styles from './PromotionPage.module.scss';
import DashboardHeader from '../../components/Header/DashboardHeader/DashboardHeader';
import { useNavigate } from 'react-router-dom';
import ButtonV5 from '../../components/Button/ButtonV5/ButtonV5';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store/store';
import { PromotionType } from '../../types/Data/PromotionType';
import { activatePromotion, deactivatePromotion } from '../../api/promotion/promotionApi';
import { handleApiError } from '../../api/apiError';
import { fetchPromotions } from '../DashboardState/promotions/promotionsSlice';


const PromotionPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { error, loading, promotions}= useSelector((state:RootState)=>state.dashboard.promotions);

    /*
    useEffect(() => {
        // Fetch products when query changes
        dispatch(fetchPromotions({}));
    }, [dispatch]);
    */

    const navigate = useNavigate();

    const handleNavigateEditPromotion = () => {
        navigate('/dashboard/promotions/edit')
    };

    const handleActivatePromotion = async (promotionId: string) => {
        try {
            const response = await activatePromotion(promotionId)
            if (response && response.data) {
                alert('Promotion activate!')
            } else {
                alert('An error occured while activating pormotion')
            }
        } catch(error: any) {
            console.log(error)
            const handledError = handleApiError(error);
            alert(handledError.message)
        }
    };

    const handleDeactivatePromotion = async (promotionId: string) => {
        try {
            const response = await deactivatePromotion(promotionId)
            if (response && response.data) {
                alert('Promotion deactivate!')
            } else {
                alert('An error occured while deactivating pormotion')
            }
        } catch(error: any) {
            console.log(error)
            const handledError = handleApiError(error);
            alert(handledError.message)
        }
    }




    return (
        <div className={styles.promotion_dashboard}>
            <DashboardHeader title='Promotion Dashboard'/>
            <div className={styles.content}>
                    <ButtonV5
                    style={{marginTop: 0}}
                    title='Add Promotion'
                    onClick={handleNavigateEditPromotion}/>
                <div className={styles.promotion_list_section}>
                    <PromotionList
                        promotions={promotions}
                        onDelete={()=>null}
                        onToggleActive={(id: string, isActive:boolean)=> { isActive ? handleActivatePromotion(id) : handleDeactivatePromotion(id)}}
                    />
                </div>
            </div>
        </div>
    );
};

export default PromotionPage;
