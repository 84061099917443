import React from 'react';
import styles from './ShippingMethodsTable.module.scss';
import { ShippingMethodType, ShippingSettingsType } from '../../../../types/Data/ShippingSettingsType';

interface ShippingMethodsTableProps {
    shippingMethods: ShippingMethodType[];
  onEdit: (method: ShippingMethodType) => void;
  onToggleEnable: (method: ShippingMethodType) => void;
}

const ShippingMethodsTable: React.FC<ShippingMethodsTableProps> = ({
  shippingMethods,
  onEdit,
  onToggleEnable,
}) => {
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Logo</th>
            <th>Name</th>
            <th>Rate</th>
            <th>Avg Delivery Days</th>
            <th>Free Shipping Threshold</th>
            <th>Tracking URL</th>
            <th>International</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {shippingMethods.map((method) => (
            <tr key={method.name}>
              <td>
                {method.logoUrl && <img src={method.logoUrl} alt={method.name} style={{ width: '50px', height: '50px' }} />}
              </td>
              <td>{method.name}</td>
              <td>${method.rate.toFixed(2)}</td>
              <td>{method.averageDeliveryDays} days</td>
              <td>{method.freeShippingThreshold ? `$${method.freeShippingThreshold}` : 'N/A'}</td>
              <td>
                {method.trackingUrl ? (
                  <a href={method.trackingUrl} target="_blank" rel="noopener noreferrer">
                    Track
                  </a>
                ) : (
                  'N/A'
                )}
              </td>
              <td>{method.international ? 'Yes' : 'No'}</td>
              <td>
                <button
                  className={`${styles.actionButton}`}
                  onClick={() => onEdit(method)}
                >
                  Edit
                </button>
                <button
                  className={`${styles.actionButton} ${!method.isEnabled && styles.disabled}`}
                  onClick={() => onToggleEnable(method)}
                >
                  {method.isEnabled ? 'Disable' : 'Enable'}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ShippingMethodsTable;
