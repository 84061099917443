import React, { useState, useEffect } from 'react';
import styles from './AdminEditorPage.module.scss';
import InputV4 from '../../../components/Input/InputText/InputV4/InputV4';
import Select4 from '../../../components/Select/Select4/Select4';
import SaveButton from '../../../components/Button/SaveButton/SaveButton';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { AdminType, AdminRole, AdminPermissionsType } from '../../../types/Data/AdminType';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';
import SwitchV1 from '../../../components/Switch/SwitchV1/SwitchV1';
import { updateAdmin } from '../../../api/admin/adminAPi';
import { handleApiError } from '../../../api/apiError';

interface AdminDetailProps {
}

const AdminEditorPage: React.FC<AdminDetailProps> = ({  }) => {
  const { adminId } = useParams<{adminId: string}>() || undefined;

  const admin = useSelector((state: RootState) => 
    state.dashboard.admins.admins.find((el) => el._id === adminId)
  ) as AdminType | undefined;

  const defaultAdmin: AdminType = {
    _id: '',
    username: '',
    email: '',
    passwordHash: '',
    role: 'ADMIN', // Default to 'ADMIN' or other role
    isActive: true,
    permissions: {
        canManageProducts: false,
        canManageOrders: false,
        canManageUsers: false,
        canManageCategories: false,
        canManageDiscounts: false,
        canManageSettings: false,
        canAccessReports: false,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  };

  const [currentAdmin, setCurrentAdmin] = useState<AdminType>(admin || defaultAdmin);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordChange, setIsPasswordChange] = useState(false);

  useEffect(() => {
    if (admin) setCurrentAdmin(admin);
    else setCurrentAdmin(defaultAdmin);
  }, [admin]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setCurrentAdmin((prevAdmin) => ({ ...prevAdmin, [name]: value }));
  };

  const handleRoleChange = (value: AdminRole) => {
    setCurrentAdmin((prevAdmin) => ({ ...prevAdmin, role: value }));
  };

  const handlePermissionToggle = (key: keyof AdminPermissionsType) => {
    setCurrentAdmin((prevAdmin) => ({
      ...prevAdmin,
      permissions: {
        ...prevAdmin.permissions,
        [key]: !prevAdmin.permissions[key],
      },
    }));
  };

  const handleIsActiveChange = () => {
    setCurrentAdmin((prevAdmin) => ({ ...prevAdmin, isActive: !prevAdmin.isActive }));
  };

  const roleOptions = [
    { label: 'Super Admin', value: 'SUPER_ADMIN' },
    { label: 'Admin', value: 'ADMIN' },
    { label: 'Moderator', value: 'MODERATOR' },
    { label: 'Customer Support', value: 'CUSTOMER_SUPPORT' },
  ];

  const permissions = [
    { label: 'Manage Products', key: 'canManageProducts' },
    { label: 'Manage Orders', key: 'canManageOrders' },
    { label: 'Manage Users', key: 'canManageUsers' },
    { label: 'Manage Categories', key: 'canManageCategories' },
    { label: 'Manage Discounts', key: 'canManageDiscounts' },
    { label: 'Manage Settings', key: 'canManageSettings' },
    { label: 'Access Reports', key: 'canAccessReports' },
  ];

  const handleSave = async () => {
    if (isPasswordChange && newPassword !== confirmPassword) {
        alert('Passwords do not match');
        return;
    }
    try {
        const adminData = {
            ...currentAdmin,
            ...(isPasswordChange && { newPassword })  // Include newPassword only if isPasswordChange is true
        };
        
        const response = await updateAdmin(currentAdmin._id, adminData);
        
        if (response.status === 200) {
            alert('Admin successfully updated!');
            setCurrentAdmin(response.data.data);
            setIsPasswordChange(false);  // Reset password change toggle
            setNewPassword('');          // Clear password fields
            setConfirmPassword('');
        } else {
            throw new Error('Error while updating admin!');
        }
    } catch (error: any) {
        const handledError = handleApiError(error);
        alert(handledError.message);
    }
};
  return (
    <div className={styles.adminEditorPage}>
      <DashboardHeader title={adminId ? 'Edit Admin' : 'Add Admin'}/>
      <div className={styles.container}>
        <InputV4
          label="First Name"
          type="text"
          name="firstName"
          value={currentAdmin.firstName}
          onChange={handleChange}
          placeholder="Enter first name"
          required
        />
        <InputV4
          label="Last Name"
          type="text"
          name="lastName"
          value={currentAdmin.lastName}
          onChange={handleChange}
          placeholder="Enter last name"
          required
        />
        <InputV4
          label="Username"
          type="text"
          name="username"
          value={currentAdmin.username}
          onChange={handleChange}
          placeholder="Enter username"
          required
        />
        
        <InputV4
          label="Email"
          type="email"
          name="email"
          value={currentAdmin.email}
          onChange={handleChange}
          placeholder="Enter email"
          required
        />
        
        <Select4
          label="Role"
          options={roleOptions}
          value={currentAdmin.role}
          onChange={(value) => handleRoleChange(value as AdminRole)}
        />
        <div className={styles.permissions}>
          <h4 style={{marginBottom:10}}>Permissions</h4>
          {permissions.map((perm) => (
            <SwitchV1
              key={perm.key}
              label={perm.label}
              isChecked={currentAdmin.permissions[perm.key]}
              onToggle={() => handlePermissionToggle(perm.key)}
            />
          ))}
        </div>
        <SwitchV1 
          label='is Active'
          onToggle={handleIsActiveChange}
        />
        <div className={styles.passwordSection}>
          <SwitchV1
            label="Change Password"
            isChecked={isPasswordChange}
            onToggle={() => setIsPasswordChange(!isPasswordChange)}
          />
          {isPasswordChange && (
            <>
              <InputV4
                label="New Password"
                type="password"
                name="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter new password"
                required
              />
              <InputV4
                label="Confirm Password"
                type="password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm new password"
                required
              />
            </>
          )}
        </div>
      </div>
      <SaveButton 
        style={{marginTop: 20}}
        onClick={handleSave}
      />
    </div>
  );
};

export default AdminEditorPage;
