import React, { useEffect, useState } from 'react';
import styles from './ProductAnalyticsPage.module.scss';
import { Bar, Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';  // Import Chart.js

interface AnalyticsPageProps {}

const defaultAnalytics = {
    "totalProducts": 1000,
    "avgPrice": 55.78,
    "stockStatusDistribution": [
      { "_id": "In Stock", "count": 450 },
      { "_id": "Out of Stock", "count": 100 },
      { "_id": "Pre Order", "count": 50 },
      { "_id": "Back Order", "count": 25 }
    ],
    "topCategories": [
      { "_id": "Electronics", "count": 200 },
      { "_id": "Home Appliances", "count": 150 }
    ],
    "productNatureDistribution": [
      { "_id": "Physical", "count": 750 },
      { "_id": "Digital", "count": 200 },
      { "_id": "Service", "count": 30 },
      { "_id": "Subscription", "count": 20 }
    ],
    "topRatedProducts": [
      { "name": "Product 1", "rating": 4.8, "price": 100, "categories": ["Electronics"] },
      { "name": "Product 2", "rating": 4.7, "price": 120, "categories": ["Home Appliances"] }
    ],
    "lowStockProducts": [
      { "name": "Product 3", "stockLevel": 2, "lowStockThreshold": 5 },
      { "name": "Product 4", "stockLevel": 4, "lowStockThreshold": 5 }
    ],
    "discountedProducts": [
      { "name": "Product 5", "price": 200, "discountPrice": 150, "discountPercentage": 25 },
      { "name": "Product 6", "price": 300, "discountPrice": 225, "discountPercentage": 25 }
    ],
    "highStockProducts": [
      { "name": "Product 7", "stockLevel": 500 },
      { "name": "Product 8", "stockLevel": 600 }
    ]
  };

const AnalyticsPage: React.FC<AnalyticsPageProps> = () => {
    const [analytics, setAnalytics] = useState<any>(defaultAnalytics);

    /*
    useEffect(() => {
        const fetchAnalytics = async () => {
            const result = await getProductAnalytics();
            setAnalytics(result);
        };
        fetchAnalytics();
    }, []);
    */

    if (!analytics) {
        return <div className={styles.loading}>Loading...</div>;
    }

    // Chart Data for Stock Status Distribution
    const stockStatusData = {
        labels: analytics.stockStatusDistribution.map((status: any) => status._id),
        datasets: [
            {
                label: 'Stock Status',
                data: analytics.stockStatusDistribution.map((status: any) => status.count),
                backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0'],
                hoverBackgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0'],
            },
        ],
    };

    // Chart Data for Product Nature Distribution
    const productNatureData = {
        labels: analytics.productNatureDistribution.map((nature: any) => nature._id),
        datasets: [
            {
                label: 'Product Nature',
                data: analytics.productNatureDistribution.map((nature: any) => nature.count),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
            },
        ],
    };

    return (
        <div className={styles.analyticsPage}>
            <h1 className={styles.title}>Product Analytics Dashboard</h1>

            <div className={styles.gridContainer}>
                {/* Total Products */}
                <div className={styles.card}>
                    <h3>Total Products</h3>
                    <p className={styles.bigNumber}>{analytics.totalProducts}</p>
                </div>

                {/* Average Price */}
                <div className={styles.card}>
                    <h3>Average Price</h3>
                    <p className={styles.bigNumber}>${analytics.avgPrice.toFixed(2)}</p>
                </div>

                {/* Stock Status Distribution */}
                <div className={styles.card}>
                    <h3>Stock Status Distribution</h3>
                    <Doughnut data={stockStatusData} />
                </div>

                {/* Product Nature Distribution */}
                <div className={styles.card}>
                    <h3>Product Nature Distribution</h3>
                    <Doughnut data={productNatureData} />
                </div>

                {/* Top Categories */}
                <div className={styles.card}>
                    <h3>Top Categories</h3>
                    <ul className={styles.list}>
                        {analytics.topCategories.map((category: any) => (
                            <li key={category._id}>
                                {category._id}: {category.count} products
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Top Rated Products */}
                <div className={styles.card}>
                    <h3>Top Rated Products</h3>
                    <ul className={styles.list}>
                        {analytics.topRatedProducts.map((product: any) => (
                            <li key={product._id}>
                                {product.name} - {product.rating} ⭐ - ${product.price}
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Low Stock Products */}
                <div className={styles.card}>
                    <h3>Low Stock Products</h3>
                    <ul className={styles.list}>
                        {analytics.lowStockProducts.map((product: any) => (
                            <li key={product._id}>
                                {product.name} - {product.stockLevel} in stock
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Discounted Products */}
                <div className={styles.card}>
                    <h3>Top Discounted Products</h3>
                    <ul className={styles.list}>
                        {analytics.discountedProducts.map((product: any) => (
                            <li key={product._id}>
                                {product.name}: ${product.discountPrice} (Discount: {product.discountPercentage.toFixed(2)}%)
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AnalyticsPage;
