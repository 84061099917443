import React, { useState } from 'react';
import { IoHeartOutline, IoHeart } from 'react-icons/io5';
import styles from './ButtonWishListV1.module.scss';
import { ButtonComponentProps } from '../../../../components/Button/ButtonComponentProps';

interface ButtonWishListProps extends ButtonComponentProps {
    isInWishlist?: boolean;
    onToggleWishlist?: (value:boolean) => void
}

const ButtonWishListV1:React.FC<ButtonWishListProps> = ({ isInWishlist = false, onToggleWishlist }) => {
  const [inWishlist, setInWishlist] = useState<boolean>(isInWishlist);

  const handleClick = () => {
    if (onToggleWishlist) {
        onToggleWishlist(!inWishlist);
    }
    setInWishlist(!inWishlist);
  };

  return (
    <button className={styles.buttonWishList} onClick={handleClick}>
      {inWishlist ? <IoHeart className={styles.icon} /> : <IoHeartOutline className={styles.icon} />}
      <p className={styles.label}>{inWishlist ? 'In Wishlist' : 'Add to Wishlist'}</p>
    </button>
  );
};

export default ButtonWishListV1;
