import React from 'react';
import { EmailTemplateType } from '../../../../types/Data/EmailTemplateType';
import { RootState } from '../../../../Store/store';
import { useSelector } from 'react-redux';
import { CustomerType } from '../../../../types/Data/CustomerType';
import { OrderType } from '../../../../types/Data/OrderType';

const styles: { [key: string]: React.CSSProperties } = {
    emailContainer: {
        maxWidth: '100%',
        margin: '20px auto',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        animation: 'fadeIn 1s ease-in-out'
    },
    header: {
        backgroundColor: '#1a1a2e',
        color: '#fff',
        padding: '20px',
        textAlign: 'center',
        borderRadius: '10px 10px 0 0'
    },
    body: {
        padding: '20px'
    },
    h2: {
        color: '#4bc0c0'
    },
    p: {
        color: '#333',
        lineHeight: '1.5'
    },
    orderItems: {
        listStyle: 'none',
        padding: 0
    },
    orderItem: {
        backgroundColor: '#f4f4f4',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '5px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
    },
    ctaButton: {
        display: 'inline-block',
        backgroundColor: '#1a1a2e',
        color: '#fff',
        padding: '10px 20px',
        borderRadius: '5px',
        textDecoration: 'none',
        fontWeight: 'bold',
        textAlign: 'center',
        transition: 'background-color 0.3s',
        cursor: 'pointer'
    },
    footer: {
        backgroundColor: '#f4f4f4',
        color: '#333',
        textAlign: 'center',
        padding: '20px',
        borderRadius: '0 0 10px 10px'
    }
};


const FeedbackRequestEmail: React.FC<EmailTemplateType> = ({ data }) => {
    const customer = useSelector<RootState>((state)=>state.dashboard.customers.customers.find((customer)=>customer._id === data?.customerId)) as Partial<CustomerType>
    const order = useSelector<RootState>((state)=>state.dashboard.orders.orders.find((order)=>order._id === data?.orderId)) as Partial<OrderType>

    return (
        <div>
            <style>
                {`
                @keyframes fadeIn {
                    from {
                        opacity: 0;
                        transform: translateY(-10px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
                `}
            </style>
            <div style={styles.emailContainer}>
                <header style={styles.header}>
                    <h1>We Value Your Feedback!</h1>
                </header>
                <section style={styles.body}>
                    <h2 style={styles.h2}>Hi {customer?.name},</h2>
                    <p style={styles.p}>Thank you for your recent purchase (Order #{order?._id}). We hope you are enjoying your items!</p>
                    <p style={styles.p}>We would love to hear your thoughts on your shopping experience. Your feedback helps us improve our service.</p>
                    <h3>Your Order</h3>
                    <ul style={styles.orderItems}>
                        {order?.items?.map((item, index) => (
                            <li key={index} style={styles.orderItem}>
                                {item.quantity}x {item.name} - ${item.unitPrice.toFixed(2)}
                            </li>
                        ))}
                    </ul>
                    {data?.importantLinks && data.importantLinks.map((link, index) => (
                        <a key={index} href={link.url} style={styles.ctaButton}>
                            {link.label}
                        </a>
                    ))}
                </section>
                <footer style={styles.footer}>
                    <p>Thank you for shopping with us!</p>
                </footer>
            </div>
        </div>
    );
};

export default FeedbackRequestEmail;
