import React from 'react';
import styles from './ProductCardListV9.module.scss';
import { ProductCardListComponentProps } from '../ProductCardListComponentProps';

const ProductCardListV9: React.FC<ProductCardListComponentProps> = ({ product, onProductClick = () => null  }) => {
  return (
        <div key={product?._id} className={styles.masonryCard}  onClick={()=>onProductClick(product?._id!)}>
          <img src={product?.thumbnail} alt={product?.name} className={styles?.image} />
          <div className={styles.masonryContent}>
            <h3 className={styles.productName}>{product?.name}</h3>
            <p className={styles.productPrice}>{product?.price} {product?.currency}</p>
          </div>
        </div>
  );
};

export default ProductCardListV9;
