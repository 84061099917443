import React, { useState, useEffect } from 'react';
import styles from './TemplatePlaceholdersEditor.module.scss';
import { IoAdd, IoTrash } from 'react-icons/io5';
import { EmailTemplatePlaceholder } from '../../../../../types/Data/EmailTemplateType';

interface PlaceholdersEditorProps {
    initialPlaceholders?: EmailTemplatePlaceholder[];
    onPlaceholdersChange: (updatedPlaceholders: EmailTemplatePlaceholder[]) => void;
}

const TemplatePlaceholdersEditor: React.FC<PlaceholdersEditorProps> = ({ initialPlaceholders = [], onPlaceholdersChange }) => {
    const [placeholders, setPlaceholders] = useState<EmailTemplatePlaceholder[]>(initialPlaceholders);

    useEffect(() => {
        onPlaceholdersChange(placeholders);
    }, [placeholders]);

    const handleAddPlaceholder = () => {
        setPlaceholders([...placeholders, { key: '', description: '', defaultValue: '' }]);
    };

    const handleDeletePlaceholder = (index: number) => {
        setPlaceholders(placeholders.filter((_, i) => i !== index));
    };

    const handlePlaceholderChange = (index: number, key: keyof EmailTemplatePlaceholder, value: string) => {
        setPlaceholders(placeholders.map((placeholder, i) => i === index ? { ...placeholder, [key]: value } : placeholder));
    };

    return (
        <div className={styles.placeholdersEditor}>
            <h4 className={styles.title}>Placeholders</h4>
            {placeholders.map((placeholder, index) => (
                <div key={index} className={styles.placeholderRow}>
                    <input
                        type="text"
                        placeholder="Key"
                        value={placeholder.key}
                        onChange={(e) => handlePlaceholderChange(index, 'key', e.target.value)}
                        className={styles.input}
                    />
                    <input
                        type="text"
                        placeholder="Description"
                        value={placeholder.description}
                        onChange={(e) => handlePlaceholderChange(index, 'description', e.target.value)}
                        className={styles.input}
                    />
                    <input
                        type="text"
                        placeholder="Default Value"
                        value={placeholder.defaultValue || ''}
                        onChange={(e) => handlePlaceholderChange(index, 'defaultValue', e.target.value)}
                        className={styles.input}
                    />
                    <div className={styles.deleteButton} onClick={() => handleDeletePlaceholder(index)}>
                        <IoTrash />
                    </div>
                </div>
            ))}
            <div className={styles.addButton} onClick={handleAddPlaceholder}>
                <IoAdd /> Add Placeholder
            </div>
        </div>
    );
};

export default TemplatePlaceholdersEditor;
