import React from "react";
import styles from './ReviewAdminOperations.module.scss';
import { ReviewStatus, ReviewType } from "../../../../types/Data/ReviewType";
import CheckboxV1 from "../../../../components/Checkbox/CheckboxV1/CheckboxV1";
import Select4 from "../../../../components/Select/Select4/Select4";
import TextAreaV1 from "../../../../components/TextArea/TextAreaV1/TextAreaV1";

interface ReviewAdminOperationsProps {
    review: Partial<ReviewType>;
    onChange: (updatedReview: Partial<ReviewType>) => void; // Callback to update fields
}

const ReviewAdminOperations: React.FC<ReviewAdminOperationsProps> = ({ review, onChange }) => {
    const statusOptions = Object.values(ReviewStatus).map((value)=>({label:value, value}));

    return (
        <div className={styles.adminOperations}>
            <h2>Admin Operations</h2>

            <div className={styles.field}>
                <Select4
                    label="Review Status"
                    options={statusOptions}
                    value={review.status}
                    onChange={(value) => onChange({ status: value })}
                />
            </div>

            <div className={styles.field}>
                <label>Verified Purchase:</label>
                <CheckboxV1
                    checked={!!review.isVerifiedPurchase}
                    onChange={(checked) => onChange({ isVerifiedPurchase: checked })}
                />
            </div>

            <div className={styles.field}>
                <label>Flagged:</label>
                <CheckboxV1
                    checked={!!review.flagged}
                    onChange={(checked) => onChange({ flagged: checked })}
                />
            </div>

            {review.flagged && (
                <div className={styles.field}>
                    <TextAreaV1
                        label="Flag Reason"
                        value={review.flagReason || ''}
                        onChange={(e) => onChange({ flagReason: e.target.value })}
                        placeholder="Enter the reason for flagging this review"
                    />
                </div>
            )}

            <div className={styles.field}>
                <TextAreaV1
                    label="Admin Response"
                    value={review.adminResponse || ''}
                    onChange={(e) => onChange({ adminResponse: e.target.value })}
                    placeholder="Enter an optional response to the review"
                />
            </div>
        </div>
    );
};

export default ReviewAdminOperations;
