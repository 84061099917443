import React from 'react'
import styles from '../Component.module.scss';
import PaginationV1 from '../../UIElements/Pagination/PaginationV1/PaginationV1';
import PaginationV2 from '../../UIElements/Pagination/PaginationV2/PaginationV2';
import PaginationV3 from '../../UIElements/Pagination/PaginationV3/PaginationV3';

const PaginationComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <PaginationV1/>
        </div>
        <div className={styles.element}>
            <PaginationV2/>
        </div>
        <div className={styles.element}>
            <PaginationV3/>
        </div>
    </div>
  )
}

export default PaginationComponentPage