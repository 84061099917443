import ContactInfoV1 from "../../UIElements/ContactInfo/ContactInfoV1/ContactInfoV1";
import ContactInfoV2 from "../../UIElements/ContactInfo/ContactInfoV2/ContactInfoV2";
import ContactInfoV3 from "../../UIElements/ContactInfo/ContactInfoV3/ContactInfoV3";

enum ContactInfoVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const ContactInfoMapping:Record<ContactInfoVersions, React.FC<any>> = {
    [ContactInfoVersions.V1]: ContactInfoV1,
    [ContactInfoVersions.V2]: ContactInfoV2,
    [ContactInfoVersions.V3]: ContactInfoV3,
};

interface ContactInfoComponentProps {
    address?: string;
    phone?: string;
    email?: string;
    workingHours?: string;
};

export {
    ContactInfoVersions,
    ContactInfoMapping
};

export type {
    ContactInfoComponentProps
};