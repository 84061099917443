import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/store";

import styles from '../../Home/EcommerceHome.module.scss';
import { BaseElementType, ComponentType } from "../../../types/Data/BaseElementType";
import renderPageElement from "../../renderPageElement";
import { useNavigate } from "react-router-dom";


const CheckoutOrderReviewPage: React.FC = () => {
    const navigate = useNavigate();

    const orderReviewPageElements = useSelector((state:RootState)=> state.ecommerce.ui.elements).filter((el)=>el.page === 'orderReview');

    const handleNavigateBillingShipping = () => {
        return navigate('/ecommerce/checkout/billingShipping')
    }

    const handleStepIndicatorClick = (step:string) => {
        switch (step.toLowerCase()) {
        case 'cart':
            navigate('/ecommerce/checkout/cart')
            break;
        case 'shipping':
            navigate('/ecommerce/checkout/billingShipping')
            break;
        case 'payment':
            navigate('/ecommerce/checkout/payment')
            break;
        case 'review':
            navigate('/ecommerce/checkout/orderReview')
            break;
        default:
            break;
        }
    }

    const getComponentProps = (
        componentType: ComponentType,
      ) => {
        switch (componentType) {
          case ComponentType.ButtonCheckout:
            return {
              onClick: handleNavigateBillingShipping,
            };
            case ComponentType.StepIndicator:
                return {
                  onStepClick: handleStepIndicatorClick,
                  currentStep: 'Review'
                }
          default: 
            return {};
        }
    };

    return (
        <div className={styles.page}>
        <div className={styles.grid}>
            {orderReviewPageElements.map((element, index) => {
                const Component = renderPageElement(element);
                const { row, column, span, order, spanRows } = element.position || {};
                const props = {...getComponentProps(element.type), ...element.props}
                return (
                    <div
                        key={index}  // Add unique key to prevent warnings
                        className={styles.gridItem}  // Add a specific class for the grid items
                        style={{
                            gridColumn: column ? `${column} / span ${span || 1}` : '1 / span 12',  // Set column start and span
                            gridRow: row && spanRows ? `${row} / span ${spanRows}` : row || 'auto',  // Set row start and span
                            order: order || 'initial',  // Set the order in case it's specified
                        }}
                    >
                        <Component {...props}/>
                    </div>
                );
            })}
        </div>
    </div>
    );
};

export default CheckoutOrderReviewPage;
