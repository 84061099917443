import React, { useState } from 'react';
import styles from './ProductImageGalleryV2.module.scss';
import { ProductImageGalleryComponentProps } from '../ProductImageGalleryComponentProps';
import { defaultProductImageGalleryProps } from '../defaultProductImageGalleryProps';

const ProductImageGalleryV2 :React.FC<ProductImageGalleryComponentProps> = ({ images = defaultProductImageGalleryProps.images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  return (
    <div className={styles.galleryContainer}>
      <div className={styles.mainImage}>
        <img src={images[currentImageIndex].url} alt={images[currentImageIndex].alt} />
      </div>
      <div className={styles.thumbnails}>
        {images.map((image, index) => (
          <div
            key={index}
            className={`${styles.thumbnail} ${index === currentImageIndex ? styles.active : ''}`}
            onClick={() => setCurrentImageIndex(index)}
          >
            <img src={image.url} alt={image.alt} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductImageGalleryV2;
