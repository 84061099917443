import React, { useState } from "react";
import OrderSearchBar from "./OrderSearchBar/OrderSearchBar";
import DashboardHeader from "../../../components/Header/DashboardHeader/DashboardHeader";
import OrderItemSelection from "./OrderItemSelection/OrderItemSelection";
import { OrderType } from "../../../types/Data/OrderType";
import SaveButton from "../../../components/Button/SaveButton/SaveButton";
import { CancellationItem, CancellationReason, CancellationStatus, CancellationType } from "../../../types/Data/CancellationType";
import CancellationNotes from "./CancellationNotes/CancellationNotes";
import CancellationStatusEdit from "./CancellationStatusEdit/CancellationStatusEdit";
import { createCancellation } from "../../../api/cancellation/cancellationApi";
import { handleApiError } from "../../../api/apiError";

const CreateCencellationPage:React.FC = () => {
    // Define the default return request
    const defaultCancellation: CancellationType = {
        _id: '',
        order: '',
        customer:'',
        customerName: '',
        reason: '',
        adminNotes: '',
        items: [],
        refundAmount: 0,
        status: CancellationStatus.Pending,
    }

    // State for the current return request
    const [currentCancellation, setCurrentCancellation] = useState<CancellationType>(defaultCancellation);
    const [orderItems, setOrderItems] = useState<CancellationItem[]>([]);
    
    // Handle order selection to update return request
    const handleOrderSelection = (order: OrderType) => {
        const derivedItems: CancellationItem[] = order.items.map(item => ({
            product: item.productId,
            productName: item.name,
            quantity: item.quantity,
            price: item.unitPrice,
            cancellationReason: CancellationReason.Other
        }));

        setOrderItems(derivedItems);

        setCurrentCancellation({
            ...currentCancellation,
            order: order._id,
            customer: order.customer._id as string,
            customerName: order.customer.name || '',
        });
    };

    // Function to toggle item selection in the return request
    const handleItemSelect = (item: CancellationItem, isSelected: boolean) => {
        let updatedItems = [...currentCancellation.items];
        if (isSelected) {
            // Add item if selected and not already in return request
            if (!updatedItems.some(returnItem => returnItem.product === item.product)) {
                updatedItems.push(item);
            }
        } else {
            // Remove item if deselected
            updatedItems = updatedItems.filter(returnItem => returnItem.product !== item.product);
        }
        setCurrentCancellation({ ...currentCancellation, items: updatedItems });
    };

    // Helper function to update an item property by product ID
    const updateItemProperty = (productId: string, property: Partial<CancellationItem>) => {
        const updatedItems = currentCancellation.items.map(item => 
            item.product === productId ? { ...item, ...property } : item
        );
        setCurrentCancellation({ ...currentCancellation, items: updatedItems });
    };

    const handleNotesChange = (adminNotes?: string) => {
        setCurrentCancellation((prevReturnRequest) => ({
            ...prevReturnRequest,
            adminNotes: adminNotes,
        }));
    };

    const handleCancellationStatusChange = (status: CancellationStatus) => {
        setCurrentCancellation((prevReturnRequest) => ({
            ...prevReturnRequest,
            status: status,
        }));
    };

    const handleSaveCancellation = async () => {
        try {
            const response = await createCancellation(currentCancellation);
            if (response.status === 201) {
                alert('Cancellation Created')
            }
            else throw new Error('Error while creating return request')
        } catch (error: any) {
            const handledError = handleApiError(error)
            alert(handledError.message)
        }
    };

    return (
        <div>
            <DashboardHeader title="Create Cancellation" />
            
            {/* Search for and select an order */}
            <OrderSearchBar onOrderSelection={handleOrderSelection} />
            
            {/* Select items for return based on selected order */}
            <OrderItemSelection
                orderItems={orderItems}
                cancellationtItems={currentCancellation.items}
                onItemSelect={handleItemSelect}
                onReturnReasonChange={(item, reason) => updateItemProperty(item.product as string, { cancellationReason: reason })}
                onQuantityChange={(item, quantity) => updateItemProperty(item.product as string, { quantity })}
            />
            <CancellationNotes
                notes={currentCancellation.adminNotes}
                onNotesChange={handleNotesChange}
            />
            <CancellationStatusEdit
                cancellationStatus={currentCancellation.status}
                onCancellationStatusChange={handleCancellationStatusChange}
                
            />
            <SaveButton
                style={{marginTop:20}}
                onClick={handleSaveCancellation}
            />
        </div>
    );
};

export default CreateCencellationPage;
