import React, { useEffect, useState } from 'react';
import styles from '../../UIElementsMainSettings/UiElementEditor.module.scss';
import { IoRemove } from 'react-icons/io5';

import { BaseElementType, ComponentType } from '../../../types/Data/BaseElementType';
import InputV4 from '../../../components/Input/InputText/InputV4/InputV4';
import ButtonV1 from '../../../components/Button/ButtonV1/ButtonV1';
import { CustomerSupportComponentProps, CustomerSupportMapping, CustomerSupportVersions } from '../CustomerSupportComponentProps';
import { defaultCustomerSupportProps } from '../customerSuppoerDefaultProps';
import UIElementsMainSettings from '../../UIElementsMainSettings/UIElementsMainSettings';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../Store/store';
import { fetchCreateElement, fetchUpdateElement } from '../../../Dashboard/DashboardState/element/elementSlice';
import ButtonDelete from '../../../components/Button/ButtonDelete/ButtonDelete';

interface CustomerSupportEditorProps {
  element?: BaseElementType<CustomerSupportComponentProps>;
}

const CustomerSupportComponentEditor: React.FC<CustomerSupportEditorProps> = ({
  element,
}) => {
  
  const defaultElement: BaseElementType<CustomerSupportComponentProps> = {
    type: ComponentType.CustomerSupport,
    version: 'v1',
    page: 'home',
    props: defaultCustomerSupportProps,
    position: undefined,
    isVisible: true,
  };

  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state:RootState)=>state.dashboard.elements.loading);
  const error = useSelector((state:RootState)=>state.dashboard.elements.error);

  const [currentElement, setCurrentElement] = useState(element ? element : defaultElement);

  useEffect(() => {
    if (element) {
      setCurrentElement(element)
    } else {
      setCurrentElement(defaultElement)
    }
  }, [element])

  const handleElementUpdate = (key:string, value: any, ) => {
      setCurrentElement((prevElement) => ({
        ...prevElement,
        [key]: value,
      }));
  };

  const handlePropsChange = (key:string, value:any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      props: {
        ...prevElement.props,
        [key]: value,
      }
    }));
  }


  const handleElementVisibilityUpdate = () => {
    setCurrentElement(prev => ({ ...prev, isVisible: !prev.isVisible }));
  };

  const SelectedComponent = CustomerSupportMapping[currentElement.version as CustomerSupportVersions] || CustomerSupportMapping[CustomerSupportVersions.V1];
  
  const elementsVersion = Object.keys(CustomerSupportVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: CustomerSupportVersions[key as keyof typeof CustomerSupportVersions],
  }));


  const handleSaveElement = () => {
    if (currentElement?._id) {
      dispatch(fetchUpdateElement({id:currentElement._id, element:currentElement}))
    } else {
      dispatch(fetchCreateElement(currentElement))
    }
  };

  return (
    <div className={styles.container}>
      
      <div className={styles.componentPreview}>
        <SelectedComponent banner={currentElement?.props} />
      </div>

      <div className={styles.componentEditor}>
        <UIElementsMainSettings
            element={currentElement}
            elementVersions={elementsVersion}
            onElementUpdate={handleElementUpdate}
            onVisibilitytUpdate={handleElementVisibilityUpdate}
        />
        
        <div className={styles.mainSettings}>
          <InputV4
            label="Email"
            value={currentElement.props?.email || ''}
            onChange={(e) => handlePropsChange('email', e.target.value)}
          />
          <InputV4
            label="Phone Number"
            value={currentElement.props?.phoneNumber || ''}
            onChange={(e) => handlePropsChange('phoneNumber', e.target.value)}
          />
          <InputV4
            label="Working Hours"
            value={currentElement.props?.workingHours || ''}
            onChange={(e) => handlePropsChange('workingHours', e.target.value)}
          />
          <InputV4
            label="Support Text"
            value={currentElement.props?.supportText || ''}
            onChange={(e) => handlePropsChange('supportText', e.target.value)}
          />
          <InputV4
            label=" Live Chat URL"
            value={currentElement.props?.liveChatUrl || ''}
            onChange={(e) => handlePropsChange('liveChatUrl', e.target.value)}
          />
      </div>
      </div>
      <div className={styles.buttonContainer}>
        <ButtonV1 label="Save Element" onClick={handleSaveElement}/>
        <ButtonDelete/>
      </div>
    </div>
  );
};

export default CustomerSupportComponentEditor;
