import { AxiosResponse } from 'axios';
import { CancellationType } from '../../types/Data/CancellationType';
import { ApiResponse } from '../apiType';
import api from '../config';

interface CancellationQueryParams {
    search?: string;
    status?: string;
    page?: number;
    limit?: number;
    sortBy?: string;
    sortOrder?: 'asc' | 'desc';
}

// Create a New Cancellation
export const createCancellation = async (
    data: Partial<CancellationType>
): Promise<AxiosResponse<ApiResponse<CancellationType>>> => {
    try {
        const response = await api.post<ApiResponse<CancellationType>>('/cancellations', data);
        return response;
    } catch (error) {
        throw new Error(`Failed to create cancellation: ${error}`);
    }
};

// Get a Cancellation by ID
export const getCancellationById = async (
    id: string
): Promise<AxiosResponse<ApiResponse<CancellationType>>> => {
    try {
        const response = await api.get<ApiResponse<CancellationType>>(`/cancellations/${id}`);
        return response;
    } catch (error) {
        throw new Error(`Failed to retrieve cancellation with ID ${id}: ${error}`);
    }
};

// Get All Cancellations with Filters, Pagination, and Sorting
export const getAllCancellations = async (
    params: CancellationQueryParams
): Promise<AxiosResponse<ApiResponse<{ cancellations: CancellationType[]; total: number; page: number; pages: number }>>> => {
    try {
        const response = await api.get<ApiResponse<{ cancellations: CancellationType[]; total: number; page: number; pages: number }>>('/cancellations', { params });
        return response;
    } catch (error) {
        throw new Error(`Failed to retrieve cancellations: ${error}`);
    }
};

// Update a Cancellation by ID
export const updateCancellationById = async (
    id: string,
    data: Partial<CancellationType>
): Promise<AxiosResponse<ApiResponse<CancellationType>>> => {
    try {
        const response = await api.put<ApiResponse<CancellationType>>(`/cancellations/${id}`, data);
        return response;
    } catch (error) {
        throw new Error(`Failed to update cancellation with ID ${id}: ${error}`);
    }
};

// Delete a Cancellation by ID
export const deleteCancellationById = async (
    id: string
): Promise<AxiosResponse<ApiResponse<null>>> => {
    try {
        const response = await api.delete<ApiResponse<null>>(`/cancellations/${id}`);
        return response;
    } catch (error) {
        throw new Error(`Failed to delete cancellation with ID ${id}: ${error}`);
    }
};

// Update the Status of a Cancellation
export const updateCancellationStatus = async (
    id: string,
    status: string
): Promise<AxiosResponse<ApiResponse<CancellationType>>> => {
    try {
        const response = await api.patch<ApiResponse<CancellationType>>(`/cancellations/${id}/status`, { status });
        return response;
    } catch (error) {
        throw new Error(`Failed to update cancellation status for ID ${id}: ${error}`);
    }
};
