import React from 'react';
import style from './ProductCardListV10.module.scss';
import { ProductCardListComponentProps } from '../ProductCardListComponentProps';

const ProductCardListV10: React.FC<ProductCardListComponentProps> = ({ product, onProductClick = () => null  }) => {
    return (
        <div className={style.product_card1}  onClick={()=>onProductClick(product?._id!)}>
            <div className={style.image_container}>
                <img src={product?.thumbnail} alt={product?.name} />
            </div>
            <div className={style.content}>
                <h3 className={style.title}>{product?.name}</h3>
                <p className={style.description}>{product?.description}</p>
                <div className={style.price}>{product?.price}</div>
            </div>
        </div>
    );
};

export default ProductCardListV10;