import React from "react";
import DashboardHeader from "../../../components/Header/DashboardHeader/DashboardHeader";
import DragAndDrop1 from "../../../components/DragAndDrop/DragAndDropV1/DragAndDropV1";
import styles from './EditBanner.module.scss'
import TextArea1 from "../../../components/TextArea/TextAreaV1/TextAreaV1";
import Input4 from "../../../components/Input/InputText/InputV4/InputV4";
import Switch1 from "../../../components/Switch/SwitchV1/SwitchV1";


const EditBanner = () => {
    return (
        <div className={styles.edit_banner}>
            <DashboardHeader title='Edit Banner'/>
            <div className={styles.banner_form}>
                <DragAndDrop1 onFilesAdded={()=>null}/>
                <TextArea1 label="Banner Text"/>
                <Input4 type="url" label="Link Url"/>

                <label className={styles.checkbox}>
                    Active:
                    <Switch1
                        isChecked={false}
                        onToggle={() => null }
                    />
                </label>
                <button type="submit">Generate Banner</button>
            </div>
        </div>
    )
};

export default EditBanner