import React from 'react'
import styles from '../Component.module.scss';
import PaymentMethodSelectorV1 from '../../UIElements/PaymentMethodSelector/PaymentMethodSelectorV1/PaymentMethodSelectorV1';
import PaymentMethodSelectorV2 from '../../UIElements/PaymentMethodSelector/PaymentMethodSelectorV2/PaymentMethodSelectorV2';

const PaymentSelectorComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <PaymentMethodSelectorV1/>
        </div>
        <div className={styles.element}>
            <PaymentMethodSelectorV2/>
        </div>
    </div>
  )
}

export default PaymentSelectorComponentPage