import React, { useEffect, useState } from 'react';
import styles from './BadgeV1.module.scss';
import { BadgeComponentProps, BadgeType } from '../BadgeComponentProps';
import { defaultBadges } from '../defaultBadgeProps';
import { IoClose } from 'react-icons/io5';

const BadgeV1: React.FC<BadgeComponentProps> = ({ badges = defaultBadges, onBadgeChange = () => null }) => {
  const [badgeList, setBadgeList] = useState<BadgeType[]>(badges);

  const handleDelete = (index: number) => {
    const newBadgeList = badgeList.filter((_, i) => i !== index);
    setBadgeList(newBadgeList);
  };

  useEffect(() => {
    onBadgeChange(badgeList);
  }, [badgeList]);

  return (
    <div className={styles.badgesList}>
      {badgeList.map((badge, index) => (
        <div 
          key={index} 
          style={{ backgroundColor: styles.defaultColor }}
          className={`${styles.badge} ${styles.defaultColor}`}
        >
          {badge.label}
          <button className={styles.closeButton} onClick={() => handleDelete(index)}>
            <IoClose/>
          </button>
        </div>
      ))}
    </div>
  );
};

export default BadgeV1;
