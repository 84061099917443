import React from "react";
import styles from "./CategoryDisplayV3.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/store";
import { CategoryType } from "../../../types/Data/CategoryType";
import { CategoryDisplayComponentProps } from "../CategoryDisplayComponentProps";


interface ArrangedCategory extends Partial<CategoryType> {
  size: string;
}

const CategoryDisplayV3: React.FC<CategoryDisplayComponentProps> = ({selectedCategoriesId, onClick=()=>null }) => {
  const allCategories = useSelector<RootState>(state => state.dashboard.categories.categories) as CategoryType[];

    const categories = selectedCategoriesId
        ? allCategories.filter((category) => selectedCategoriesId.includes(category._id!))
        : allCategories.slice(0, 10); 


  // Define sizes for the mosaic layout
  const sizeOptions = ['large', 'medium', 'small'];
  const getRandomSize = () => sizeOptions[Math.floor(Math.random() * sizeOptions.length)];

  const arrangedCategories: ArrangedCategory[] = categories.map((category) => ({
    ...category,
    size: getRandomSize(),
  }));

  return (
    <div className={styles.grid_container}>
      {arrangedCategories.map((category, index) => (
        <div
          key={category._id}
          className={`${styles.item}`}
          onClick={()=>{onClick && onClick(category?.name!)}}
        >
          <img src={category.image?.url} alt={category.name} className={styles.image} />
          <div className={styles.content}>
            <h5>{category.name}</h5>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CategoryDisplayV3;
