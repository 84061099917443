import React from 'react'
import SectionHeader1 from '../../../components/Header/SectionHeader1/SectionHeader1';
import styles from './LogoCloudV1.module.scss';
import { LogoCloudComponentProps } from '../LogoCloudComponentProps';
import { logoCloudDefaultProps } from '../logoCloudDefaultProps';


const LogoCloudV1:React.FC<LogoCloudComponentProps> = ({ 
    title=logoCloudDefaultProps.title,
    logos=logoCloudDefaultProps.logos 
}) => {
    return (
        <div className={styles.logo_cloud}>
            <SectionHeader1 title={title}/>
            <div className={styles.logo_list} >
            {logos?.map((logo, index) => {
                return (
                    <div className={styles.logo_card}>
                         <img src={logo}/>
                    </div>
                )
            }) 
            }
            </div>
        </div>
  )
}



export default LogoCloudV1