import PaymentMethodSelectorV1 from "../../UIElements/PaymentMethodSelector/PaymentMethodSelectorV1/PaymentMethodSelectorV1";
import PaymentMethodSelectorV2 from "../../UIElements/PaymentMethodSelector/PaymentMethodSelectorV2/PaymentMethodSelectorV2";

enum PaymentMethodSelectorVersions {
    V1 = "v1",
    V2 = "v2",
};

const PaymentMethodSelectorMapping:Record<PaymentMethodSelectorVersions, React.FC<any>> = {
    [PaymentMethodSelectorVersions.V1]: PaymentMethodSelectorV1,
    [PaymentMethodSelectorVersions.V2]: PaymentMethodSelectorV2,
};

interface PaymentMethodSelectordProps {
    selectedMethod?: string;
    methods?: string[];
    onMethodChange?: (method: string) => void;
    onSubmit?: () => void;
};

export {
    PaymentMethodSelectorVersions,
    PaymentMethodSelectorMapping
};

export type {
    PaymentMethodSelectordProps
};
  