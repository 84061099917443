import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../Store/store";
import RichTextEditorV1 from "../../components/RichTextEditor/RichTextEditorV1/RichTextEditorV1";
import DashboardHeader from "../../components/Header/DashboardHeader/DashboardHeader";
import SaveButton from "../../components/Button/SaveButton/SaveButton";
import styles from './PrivatePolicySettingPage.module.scss';
import { fetchPrivatePolicy } from "../DashboardState/privatePolicy/privatePolicySlice";
import LoadingIndicatorV1 from "../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1";
import ErrorBannerV1 from "../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1";
import { savePrivatePolicy } from "../../api/privatePolicy/privatePolicyApi";
import { handleApiError } from "../../api/apiError";

const PrivatePolicySettingPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const privatePolicy = useSelector((state: RootState) => state.dashboard.privatePolicy.privatePolicy);
    const loading = useSelector((state: RootState) => state.dashboard.privatePolicy.loading);
    const error = useSelector((state: RootState) => state.dashboard.privatePolicy.error);

    // Local state to handle policy content and editability
    const [currentPolicy, setCurrentPolicy] = useState(privatePolicy || "");
    const [isEditing, setIsEditing] = useState(false);

    // Handle saving the policy
    const handleSave = async () => {
        try {
            const response = await savePrivatePolicy(currentPolicy)
            if (response.status === 200 || response.status === 201) {
                alert('Private policy succesfully saved!')
                setCurrentPolicy(response.data.data)
            } else {
                throw new Error('Error while saving private policy')
            }
        } catch (error: any) {
            let handledError = handleApiError(error);
            alert(handledError.message)
        } finally {
            setIsEditing(false);
        }
    };

    useEffect(() => {
        if (!privatePolicy) {
            dispatch(fetchPrivatePolicy())
        }
    }, [privatePolicy])

    return (
        <div className={styles.policySettingPage}>
            <DashboardHeader title="Privacy Policy Settings" />
            {/* Handle loading state */}
            {loading && <LoadingIndicatorV1 />}
                
            {/* Handle error state */}
            {error && <ErrorBannerV1 message={typeof error === 'string' ? error : 'An error occurred'} />}
            <div className={styles.editorContainer}>
                <RichTextEditorV1
                    style={{height: 300}}
                    wrapperStyle={{height: 350}}
                    value={currentPolicy.content}
                    onChange={(content) => setCurrentPolicy((prev)=> ({...prev, content}))}
                    readOnly={!isEditing}
                />
                <div className={styles.buttonGroup}>
                    {isEditing ? (
                        <SaveButton onClick={handleSave} />
                    ) : (
                        <button onClick={() => setIsEditing(true)} className={styles.editButton}>
                            Edit Policy
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PrivatePolicySettingPage;
