import React from 'react';
import styles from './SlideOverV1.module.scss';
import { SlideOverComponentProps } from '../SlideOverComponentProps';

const SlideOverV1: React.FC<SlideOverComponentProps> = ({ 
  isOpen = false, 
  onClose = ()=>null, 
  children = null,
   modalStyle = undefined 
  }) => {
    
    return (
    <>
      {isOpen && <div className={styles.slideover_overlay} onClick={onClose} />}
      <div className={`${styles.slideover_panel} ${isOpen ? styles.open : ''}`} style={{...modalStyle}}>
        <button className={styles.slideover_close_button} onClick={onClose}>×</button>
        {children}
      </div>
    </>
  );
};

export default SlideOverV1;