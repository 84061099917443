import { FooterComponentProps } from "../FooterComponentProps";

export const defaultFooterProps: FooterComponentProps = {
    showCompanyInfo : true,
    showSocialMedia : true,
    showPaymentMethods : true,
    showDeliveryMethods : true,
    showNewsletterSubscription : true,
    backgroundColor : '',
    textColor : 'string',
    items : [],
};
