import React from 'react';
import { useSelector } from 'react-redux';
import styles from './FooterContactInformation.module.scss';
import { RootState } from '../../../../Store/store';
import { FaEnvelope, FaLocationArrow, FaMapMarked, FaPhone, FaVoicemail } from 'react-icons/fa';

const FooterContactInformation: React.FC = () => {
  const shopSettings = useSelector((state: RootState) => state.dashboard.shopSettings.settings)

  return (
    <div className={styles.contactContainer}>
      {shopSettings.contactPhone &&
      <div className={styles.contactItem}>
        <FaPhone className={styles.contactIcon} />
        <p className={styles.contactText}>{shopSettings.contactPhone}</p>
      </div>
      }
      {shopSettings.contactEmail && 
      <div className={styles.contactItem}>
        <FaEnvelope className={styles.contactIcon} />
        <a href={`mailto:${shopSettings.contactEmail}`} className={`${styles.contactText} ${styles.contactLink}`}>
          {shopSettings.contactEmail}
        </a>
      </div>
    }
    {shopSettings.address &&
      <div className={styles.contactItem}>
        <FaMapMarked className={styles.contactIcon} />
        <p className={styles.contactText}>{shopSettings.address}</p>
      </div>
    }
    </div>
  );
};

export default FooterContactInformation;
