interface ApiError {
    message: string;
    status?: number;
}

const handleApiError = (error: any): ApiError => {
    let errorMessage = 'An unexpected error occurred';
    let errorStatus: number | undefined;

    if (error.response) {
        // Server responded with a status other than 2xx
        errorStatus = error.response.status;

        switch (errorStatus) {
            case 400:
                errorMessage = error.response.data.message || 'Bad Request. Please check your input.';
                break;
            case 401:
                errorMessage = 'Unauthorized. Please log in.';
                break;
            case 403:
                errorMessage = 'Forbidden. You do not have permission.';
                break;
            case 404:
                errorMessage = error.response.data.message || 'Resource not found.';
                break;
            case 500:
                errorMessage = 'Server error. Please try again later.';
                break;
            default:
                errorMessage = error.response.data.message || 'An error occurred. Please try again.';
                break;
        }
    } else if (error.request) {
        // No response received
        errorMessage = 'No response from the server. Please check your connection.';
    } else {
        // Something else happened
        errorMessage = error.message || 'Error occurred during the request.';
    }

    // Log the error for debugging purposes
    console.error('API Error:', error);

    return { message: errorMessage, status: errorStatus };
};

export {
    handleApiError
}