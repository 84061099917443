import React, { useState, InputHTMLAttributes } from 'react';
import styles from './InputV1.module.scss';
import { InputComponentProps } from '../../InputComponentProps';

const InputV1: React.FC<InputComponentProps> = ({ label, marginTop=0, wrapperStyle , ...props}) => {

    return (
        <div className={styles.input1} style={{...wrapperStyle}}>
            <input type="text" placeholder=" " required {...props}/>
            <label>{label ? label : props.placeholder}</label>
    </div>
    );
};

export default InputV1;