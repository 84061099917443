import React, { useState } from 'react';
import styles from './CreateAdminPage.module.scss';
import InputV4 from '../../../components/Input/InputText/InputV4/InputV4';
import Select4 from '../../../components/Select/Select4/Select4';
import SaveButton from '../../../components/Button/SaveButton/SaveButton';
import { AdminType, AdminRole, AdminPermissionsType } from '../../../types/Data/AdminType';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';
import SwitchV1 from '../../../components/Switch/SwitchV1/SwitchV1';
import { createAdmin } from '../../../api/admin/adminAPi';
import { handleApiError } from '../../../api/apiError';

const CreateAdminPage: React.FC = () => {
  const defaultAdmin: AdminType = {
    _id: '',
    username: '',
    email: '',
    passwordHash: '',
    role: 'ADMIN', // Default to 'ADMIN' or other role
    isActive: true,
    permissions: {
        canManageProducts: false,
        canManageOrders: false,
        canManageUsers: false,
        canManageCategories: false,
        canManageDiscounts: false,
        canManageSettings: false,
        canAccessReports: false,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  };

  const [currentAdmin, setCurrentAdmin] = useState<AdminType>(defaultAdmin);
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setCurrentAdmin((prevAdmin) => ({ ...prevAdmin, [name]: value }));
  };

  const handleRoleChange = (value: AdminRole) => {
    setCurrentAdmin((prevAdmin) => ({ ...prevAdmin, role: value }));
  };

  const handleIsActiveChange = () => {
    setCurrentAdmin((prevAdmin) => ({ ...prevAdmin, isActive: !prevAdmin.isActive }));
  };

  const roleOptions = [
    { label: 'Super Admin', value: 'SUPER_ADMIN' },
    { label: 'Admin', value: 'ADMIN' },
    { label: 'Moderator', value: 'MODERATOR' },
    { label: 'Customer Support', value: 'CUSTOMER_SUPPORT' },
  ];

  const permissions = [
    { label: 'Manage Products', key: 'canManageProducts' },
    { label: 'Manage Orders', key: 'canManageOrders' },
    { label: 'Manage Users', key: 'canManageUsers' },
    { label: 'Manage Categories', key: 'canManageCategories' },
    { label: 'Manage Discounts', key: 'canManageDiscounts' },
    { label: 'Manage Settings', key: 'canManageSettings' },
    { label: 'Access Reports', key: 'canAccessReports' },
  ];

  const handlePermissionToggle = (key: keyof AdminPermissionsType) => {
    setCurrentAdmin((prevAdmin) => ({
      ...prevAdmin,
      permissions: {
        ...prevAdmin.permissions,
        [key]: !prevAdmin.permissions[key],
      },
    }));
  };

  const handleSave = async () => {
    try {
        const response = await createAdmin(currentAdmin)
        if ( response.status === 201 ) {
            alert('Admin succesfully created!')
            setCurrentAdmin(response.data.data)
        } else {
            throw new Error('Error while creating admin!')
        }
    } catch (error:any) {
        const handledError = handleApiError(error);
        alert(handledError.message)
    }
  };

  return (
    <div className={styles.adminEditorPage}>
      <DashboardHeader title="Add Admin" />
      <div className={styles.container}>
        <InputV4
          label="First Name"
          type="text"
          name="firstName"
          value={currentAdmin.firstName || ''}
          onChange={handleChange}
          placeholder="Enter first name"
          required
        />
        <InputV4
          label="Last Name"
          type="text"
          name="lastName"
          value={currentAdmin.lastName || ''}
          onChange={handleChange}
          placeholder="Enter last name"
          required
        />
        <InputV4
          label="Username"
          type="text"
          name="username"
          value={currentAdmin.username}
          onChange={handleChange}
          placeholder="Enter username"
          required
        />
        <InputV4
          label="Email"
          type="email"
          name="email"
          value={currentAdmin.email}
          onChange={handleChange}
          placeholder="Enter email"
          required
        />
        <Select4
          label="Role"
          options={roleOptions}
          value={currentAdmin.role}
          onChange={(value) => handleRoleChange(value as AdminRole)}
        />
        <InputV4
          label="Password"
          type="password"
          name="passwordHash"
          value={currentAdmin.passwordHash}
          onChange={handleChange}
          placeholder="Enter password"
          required
        />
        <InputV4
          label="Confirm Password"
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm password"
          required
        />
        <div className={styles.permissions}>
            <h4>Permissions</h4>
          {permissions.map((perm) => (
            <SwitchV1
              key={perm.key}
              label={perm.label}
              isChecked={currentAdmin.permissions[perm.key]}
              onToggle={() => handlePermissionToggle(perm.key)}
            />
          ))}
        </div>
        <SwitchV1 label="Is Active" isChecked={currentAdmin.isActive} onToggle={handleIsActiveChange} />
      </div>
      <SaveButton style={{ marginTop: 20 }} onClick={handleSave} />
    </div>
  );
};

export default CreateAdminPage;
