// Header.tsx
import React from 'react';
import styles from './DashboardHeader.module.scss';

interface HeaderProps {
    title: string;
}

const DashboardHeader: React.FC<HeaderProps> = ({ title }) => {
    return (
        <header className={styles.header}>
            <h1 className={styles.title}>{title}</h1>
        </header>
    );
};

export default DashboardHeader;
