import { SocialMediaLinkType } from "../../../types/Data/SocialMediaSettingsType";

const exampledataSocialMediaLinks: SocialMediaLinkType[] = [
    {
      platform: 'Facebook',
      url: 'https://www.facebook.com/yourshop',
      iconUrl: '/social-media/facebook.png',
    },
    {
      platform: 'Twitter',
      url: 'https://www.twitter.com/yourshop',
      iconUrl: '/social-media/x.png',
    },
    {
      platform: 'Instagram',
      url: 'https://www.instagram.com/yourshop',
      iconUrl: '/social-media/instagram.png',
    },
    {
      platform: 'LinkedIn',
      url: 'https://www.linkedin.com/company/yourshop',
      iconUrl: '/social-media/linkedin.png',
    },
];

export default exampledataSocialMediaLinks
  