import React from "react";
import styles from './ButtonGroupV2.module.scss';
import { ButtonGroupComponentProps } from "../ButtonGroupComponentProps";

const ButtonGroupV2 = <T extends string>({ 
    buttons = ["Button 1", "Button 2"] as T[], // Ensuring default values are treated as T[]
    onClick = () => null,
    buttonGroupeStyle,
    buttonStyle
}: ButtonGroupComponentProps<T>)  => {
    return (
        <div id={styles.button_group2} style={{...buttonGroupeStyle}}>
            {buttons.map((button, index) => (
            <button 
            key={index} 
            className={styles.button} 
            style={{...buttonStyle}}
            onClick={() => onClick(button)}>
                {button}
            </button>
            ))}
        </div>
    )
}

export default ButtonGroupV2;