import React from 'react';
import styles from './CustomerWishListV1.module.scss';
import { FaHeartBroken, FaShoppingCart } from 'react-icons/fa';
import { ProductType } from '../../../types/Data/ProductType';
import customerWishListDefaultData from '../customerWishListDefaultData';
import { CustomerWhishListComponentProps } from '../CustomerWishListComponentProps';

function isPartialProduct(product: string | Partial<ProductType>): product is Partial<ProductType> {
  return typeof product !== 'string' && product !== null;
}

const CustomerWishListV1: React.FC<CustomerWhishListComponentProps> = ({
  wishlistItems = customerWishListDefaultData
}) => {
  return (
    <div className={styles.wishlist}>
      {wishlistItems && wishlistItems.length > 0 ? (
        <div className={styles.wishlistItems}>
          {wishlistItems.map((item) => {
            if (isPartialProduct(item.product)) {
              return (
                <div key={item.product._id} className={styles.wishlistItem}>
                  <img
                    src={item.product.thumbnail || item?.product?.images![0].url}
                    alt={item.product.name}
                    className={styles.productImage}
                  />
                  <div className={styles.productInfo}>
                    <h3>{item.product.name}</h3>
                    <p className={styles.price}>${item.product.price?.toFixed(2)}</p>
                    <div className={styles.actions}>
                      {item.product.stockStatus === 'In Stock' ? (
                        <button className={styles.addToCartButton}>
                          <FaShoppingCart className={styles.cartIcon} />
                          Add to Cart
                        </button>
                      ) : (
                        <span className={styles.outOfStock}>Out of Stock</span>
                      )}
                      <button className={styles.removeButton}>
                        <FaHeartBroken className={styles.removeIcon} />
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      ) : (
        <p className={styles.emptyMessage}>Your wishlist is empty. Start adding products!</p>
      )}
    </div>
  );
};

export default CustomerWishListV1;
