import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../Store/store";
import RichTextEditorV1 from "../../components/RichTextEditor/RichTextEditorV1/RichTextEditorV1";
import DashboardHeader from "../../components/Header/DashboardHeader/DashboardHeader";
import SaveButton from "../../components/Button/SaveButton/SaveButton";
import styles from './TermOfServiceSettingPage.module.scss';
import LoadingIndicatorV1 from "../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1";
import ErrorBannerV1 from "../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1";
import { handleApiError } from "../../api/apiError";
import { saveTermOfService } from "../../api/termOfService/termOfServiceApi";
import { fetchTermOfService } from "../DashboardState/termOfService/termOfServiceSlice";

const TermOfServiceSettingPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const termOfService = useSelector((state: RootState) => state.dashboard.termOfService.termOfService);
    const loading = useSelector((state: RootState) => state.dashboard.termOfService.loading);
    const error = useSelector((state: RootState) => state.dashboard.termOfService.error);

    // Local state to handle policy content and editability
    const [currentTermOfService, setcurrentTermOfService] = useState(termOfService || "");
    const [isEditing, setIsEditing] = useState(false);

    // Handle saving the policy
    const handleSave = async () => {
        try {
            const response = await saveTermOfService(currentTermOfService)
            if (response.status === 200 || response.status === 201) {
                alert('Term of service succesfully saved!')
                setcurrentTermOfService(response.data.data)
            } else {
                throw new Error('Error while saving term of service')
            }
        } catch (error: any) {
            let handledError = handleApiError(error);
            alert(handledError.message)
        } finally {
            setIsEditing(false);
        }
    };

    useEffect(() => {
        if (!termOfService) {
            dispatch(fetchTermOfService())
        }
    }, [termOfService])

    return (
        <div className={styles.policySettingPage}>
            <DashboardHeader title="Term of Service Settings" />
            {/* Handle loading state */}
            {loading && <LoadingIndicatorV1 />}
                
            {/* Handle error state */}
            {error && <ErrorBannerV1 message={typeof error === 'string' ? error : 'An error occurred'} />}
            <div className={styles.editorContainer}>
                <RichTextEditorV1
                    style={{height: 300}}
                    wrapperStyle={{height: 350}}
                    value={currentTermOfService.content}
                    onChange={(content) => setcurrentTermOfService((prev)=> ({...prev, content}))}
                    readOnly={!isEditing}
                />
                <div className={styles.buttonGroup}>
                    {isEditing ? (
                        <SaveButton onClick={handleSave} />
                    ) : (
                        <button onClick={() => setIsEditing(true)} className={styles.editButton}>
                            Edit Policy
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TermOfServiceSettingPage;
