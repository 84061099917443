import React, { useState, InputHTMLAttributes } from 'react';
import styles from './InputV4.module.scss';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    wrapperStyle?: React.CSSProperties;
}

const InputV4: React.FC<InputProps> = ({ label, wrapperStyle, ...props }) => {

    return (
        <div className={styles.input4} style={{...wrapperStyle}}>
            <p className={styles.label}>{label ? label : 'Your label' }</p>
            <input className={styles.input} type="text" placeholder=" " {...props}/>
        </div>
    );
};

export default InputV4;