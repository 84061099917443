import React from 'react';
import HeroBannerEditor from '../../../UIElements/HeorBanner/HeroBannerEditor/HeroBannerEditor';
import { BaseElementType } from '../../../types/Data/BaseElementType';
import IncentiveEditor from '../../../UIElements/Incentive/IncentiveEditor/IncentiveEditor';
import FeatureSectionEditor from '../../../UIElements/FeatureSection/FeatureSectionEditor/FeatureSectionEditor';
import FeaturedProductsEditor from '../../../UIElements/FeaturedProducts/FeaturedProductsEditor/FeaturedProductsEditor';
import CategoryDisplayEditor from '../../../UIElements/CategoryDisplay/CategoryDisplayEditor/CategoryDisplayEditor';
import NewsLetterComponentEditor from '../../../UIElements/NewsLetterSignup/NewsLetterComponentEditor/NewsLetterComponentEditor';
import TestimonialComponentEditor from '../../../UIElements/Testimonial/TestimonialComponentEditor/TestimonialComponentEditor';
import SocialMediaLinkComponentPage from '../../../ComponentPage/Pages/SocialMediaLinkComponentPage';
import SocialMediaComponentEditor from '../../../UIElements/SocialMediaLinks/SocialMediaLinksComponentEditor/SocialMedialLinksComponentEditor';
import BlogSectionComponentEditor from '../../../UIElements/BlogSection/BlogSectionEditor/BlogSectionComponentEditor';
import PromotionBannerEditor from '../../../UIElements/PromotionalBanner/PromotionalBannerEditor/PromotionalBannerEditor';
import CompanyHistoryEditor from '../../../UIElements/CompanyHistory/CompanyHistoryComponentEditor/CompanyHistoryComponentEditor';
import CompanyOverviewEditor from '../../../UIElements/CompanyOverview/CompanyOverviewComponentEditor/CompanyOverviewComponentEditor';
import ContactInfoComponentEditor from '../../../UIElements/ContactInfo/ContactInfoComponentEditor/ContactInfoComponentEditor';
import HeroSectionComponentEditor from '../../../UIElements/HeroSection/HeroSectionComponentEditor/HeroSectionComponentEditor';
import LogoCloudCompoentEditor from '../../../UIElements/LogoClouds/LogoCloudComponentEditor/LogoCloudComponentEditor';
import FilterModalEditor from '../../../UIElements/FilterModal/FilterModalEditor/FilterModalEditor';
import SortComponentEditor from '../../../UIElements/Sort/SortComponentEditor/SortComponentEditor';
import PaginationComponentEditor from '../../../UIElements/Pagination/PaginationComponentEditor/PaginationComponentEditor';
import ProductImageGalleryComponentEditor from '../../../UIElements/ProductImageGallery/ProductImageGalleryComponentEditor/ProductImageGalleryComponentEditor';
import TrustBadgeIconEditor from '../../../UIElements/TrustBadgeIcons/TrustBadgeIconEditor/TrustBadgeIconEditor';
import ProductDescriptionComponentEdit from '../../../UIElements/ProductDescription/ProductDescriptionComponentEditor/ProductDescriptionComponentEditor';
import ProductPriceComponentEditor from '../../../UIElements/ProductPrice/ProductPriceComponentEditor/ProductPriceComponentEditor';
import ProductNameComponentEditor from '../../../UIElements/ProductName/ProductNameComponentEditor/ProductNameComponentEditor';
import ButtonBuyComponentEditor from '../../../UIElements/Button/ButtonBuy/ButtonBuyComponentEditor/ButtonBuyComponentEditor';
import ButtonAddToCartComponentEditor from '../../../UIElements/Button/ButtonAddToCart/ButtonAddToCartComponentEditor/ButtonAddToCartComponentEditor';
import ButtonWishListComponentEditor from '../../../UIElements/Button/ButtonWishList/ButtonWishListComponentEditor/ButtonWishListComponentEditor';
import ProductReviewComponentEditor from '../../../UIElements/ProductReview/ProductReviewComponentEditor/ProductReviewComponentEditor';
import QuantitySelectorComponentEditor from '../../../UIElements/QuantitySelector/QuantitySelectorComponentProps/QuantitySelectorComponentEditor';
import SizeSelectorComponentEditor from '../../../UIElements/SizeSelector/SizeSelectorComponentEditor/SizeSelectorComponentEditor';
import VariantSelectorComponentEditor from '../../../UIElements/VariantSelector/VariantSelectorComponentEditor/VariantSelectorComponentEditor';
import CartListItemComponentEditor from '../../../UIElements/CartItemList/CartItemListComponentEdit/CartItemListComponentEditor';
import OrderSummaryComponentEditor from '../../../UIElements/OrderSummary/OrderSummaryComponentEditor/OrderSummaryComponentEditor';
import ButtonProceedCheckoutComponentEditor from '../../../UIElements/Button/ButtonProceedToCheckout/ButtonProceedCheckoutComponentEditor/ButtonProceedCheckoutComponentEditor';
import PaymentMethodSelectorComponentEditor from '../../../UIElements/PaymentMethodSelector/PaymentMethodSelectorComponentEditor/PaymentMethodSelectorComponentEditor';
import StepIndicatorComponentEditor from '../../../UIElements/StepIndicator/StepIndicatorComponentEditor/StepIndicatorComponentEditor';
import CustomerSupportComponentEditor from '../../../UIElements/CustomerSupport/CustomerSupportComponentEditor/CustomerSupportComponentEditor';
import OrderConfirmationComponentEditor from '../../../UIElements/OrderConfirmation/OrderConfirmationComponentEditor/OrderConfirmationComponentEditor';
import OrderErrorComponentEditor from '../../../UIElements/OrderError/OrderErrorComponentEditor/OrderErrorComponentEditor';
import CartSummarySidebarComponentEditor from '../../../UIElements/CartSummarySidebar/CartSummarySidebarComponentEditor/CartSummarySidebarComponentEditor';
import ShippingInformationComponentEditor from '../../../UIElements/ShippingInformationForm/ShippingInformationFormComponentProps/ShippingInformationFormComponentProps';
import CouponPomotionComponentEditor from '../../../UIElements/CouponPromotion/CouponPromotionComponentEditor/CouponPomotionComponentEditor';
import ContactFormComponentEditor from '../../../UIElements/ContactForm/ContactFormComponentEditor/ContactFormComponentEditor';
import TabNavigationEditor from '../../../UIElements/TabNavigation/TabNavigationEditor/TabNavigationEditor';
import FooterEditor from '../../../UIElements/Footer/FooterEditor/FooterEditor';
import ProductListEditor from '../../../UIElements/ProductList/ProductListEditor/ProductListEditor';
import BadgeComponentEditor from '../../../UIElements/Badge/BadgeEditor/BadgeComponentEditor';
import TrustSignalsComponentEditor from '../../../UIElements/TrustSignals/TrustSignalsComponentEditor/TrustSignalsComponentEditor';
import BillingInformationFormComponentEditor from '../../../UIElements/BillingInformationComponentForm/BillingInformationFormComponentEditor/BillingInformationFormComponentEditor';
import CustomerNavigationComponentEditor from '../../../UIElements/CustomerNavigation/CustomerNavigationComponentEditor/CustomerNavigationComponentEditor';
import CustomerOrderHistoryComponentEditor from '../../../UIElements/CustomerOrderHistory/CustomerOrderHistoryComponentEditor/CustomerOrderHistoryComponentEditor';
import CustomerWishListComponentEditor from '../../../UIElements/CustomerWishList/CustomerWishListComponentEditor/CustomerWishListComponentEditor';
import CustomerReturnRequestComponentEditor from '../../../UIElements/CustomerOrderReturnRequest/CustomerReturnRequestComponentEditor/CustomerReturnRequestComponentEditor';
import CustomerReturnHistoryComponentEditor from '../../../UIElements/CustomerReturnHistory/CustomerReturnHistoryComponentEditor/CustomerReturnHistoryComponentEditor';
import CustomerOrderDetailComponentEditor from '../../../UIElements/CustomerOrderDetail/CustomerOrderDetailComponentEditor/CustomerOrderDetailComponentEditor';
import CustomerOrderTrackingComponentEditor from '../../../UIElements/CustomerOrderTracking/CustomerOrderTrackingComponentEditor/CustomerOrderTrackingComponentEditor';
import CustomerProductReviewComponentEditor from '../../../UIElements/CustomerProductReview/CustomerProductReviewComponentEditor/CustomerProductReviewComponentEditor';
import { CustomerMessageHistoryMapping } from '../../../UIElements/CustomerMessageHistory/CustomerMessageHistoryType';
import CustomerMessageHistoryComponentEditor from '../../../UIElements/CustomerMessageHistory/CustomerMessageHistoryComponentEditor/CustomerMessageHistoryComponentEditor';
import CustomerChatRoomComponentEditor from '../../../UIElements/CustomerChatRoom/CustomerChatRoomComponentEditor/CustomerChatRoomComponentEditor';

interface ComponentEditorProps {
    element?: BaseElementType<any> | undefined | null;
}

const RenderComponentEditor: React.FC<ComponentEditorProps> = ({ element = null }) => {
    if (!element?.type) {
      alert('No component')
      return null;
    }
    switch (element?.type) {
        case 'heroBanner':
            return <HeroBannerEditor element={element}/>;
        case 'incentive':
            return <IncentiveEditor element={element}/>;
        case 'featureSection':
            return <FeatureSectionEditor element={element}/>;
        case 'featuredProducts':
            return <FeaturedProductsEditor element={element}/>;
        case 'categoryDisplay':
            return <CategoryDisplayEditor element={element}/>;
        case 'testimonial':
            return <TestimonialComponentEditor element={element}/>;
        case 'newsletterSignup':
            return <NewsLetterComponentEditor/>;
        case 'socialMedia':
            return <SocialMediaComponentEditor element={element}/>;
        case 'blogSection':
            return <BlogSectionComponentEditor element={element}/>;
        case 'promotionalBanner':
            return <PromotionBannerEditor element={element}/>;
        case 'companyHistory':
            return <CompanyHistoryEditor element={element}/>;
        case 'companyOverview':
            return <CompanyOverviewEditor element={element}/>;
        case 'contactInformation':
            return <ContactInfoComponentEditor element={element}/>;
        case 'heroSection':
            return <HeroSectionComponentEditor element={element}/>;
        case 'logoCloud':
            return <LogoCloudCompoentEditor element={element}/>;
        case 'trustBadgeIcon':
            return <TrustBadgeIconEditor element={element}/>;
        case 'filterModal':
            return <FilterModalEditor element={element}/>;
        case 'sort':
            return <SortComponentEditor element={element}/>;
        case 'pagination':
            return <PaginationComponentEditor element={element}/>;
        case 'productImageGallery':
            return <ProductImageGalleryComponentEditor element={element}/>;
        case 'productDescription':
            return <ProductDescriptionComponentEdit element={element}/>;
        case 'productPrice':
            return <ProductPriceComponentEditor element={element}/>;
        case 'productName':
            return <ProductNameComponentEditor element={element}/>;
        case 'productName':
            return <ProductNameComponentEditor element={element}/>;
        case 'buttonBuy':
            return <ButtonBuyComponentEditor element={element}/>;
        case 'buttonAddToCart':
            return <ButtonAddToCartComponentEditor element={element}/>;
        case 'buttonAddWishList':
            return <ButtonWishListComponentEditor element={element}/>;
        case 'buttonAddWishList':
            return <ButtonWishListComponentEditor element={element}/>;
        case 'badge':
            return <BadgeComponentEditor/>;
        case 'productReviews':
            return <ProductReviewComponentEditor element={element}/>;
        case 'quantitySelector':
            return <QuantitySelectorComponentEditor element={element}/>;
        case 'sizeSelector':
            return <SizeSelectorComponentEditor element={element}/>;
        case 'variantSelector':
            return <VariantSelectorComponentEditor element={element}/>;
        case 'cartItemList':
            return <CartListItemComponentEditor element={element}/>;
        case 'orderSummary':
            return <OrderSummaryComponentEditor element={element}/>;
        case 'buttonCheckout':
            return <ButtonProceedCheckoutComponentEditor element={element}/>;
        case 'paymentMethodSelector':
            return <PaymentMethodSelectorComponentEditor element={element}/>;
        case 'stepIndicator':
            return <StepIndicatorComponentEditor element={element}/>;
        case 'customerSupport':
            return <CustomerSupportComponentEditor element={element}/>;
        case 'orderConfirmation':
            return <OrderConfirmationComponentEditor element={element}/>;
        case 'orderError':
            return <OrderErrorComponentEditor element={element}/>;
        case 'cartSummarySidebar':
            return <CartSummarySidebarComponentEditor element={element}/>;
        case 'shippingInformation':
            return <ShippingInformationComponentEditor element={element}/>;
        case 'billingInformation':
            return <BillingInformationFormComponentEditor element={element}/>;
        case 'couponPromotion':
            return <CouponPomotionComponentEditor element={element}/>;
        case 'contactForm':
            return <ContactFormComponentEditor element={element}/>;
        case 'productList':
            return <ProductListEditor element={element}/>;
        case 'trustSignals':
            return <TrustSignalsComponentEditor element={element}/>;
        case 'customerNavigation':
            return <CustomerNavigationComponentEditor element={element}/>;
        case 'customerOrderHistory':
             return <CustomerOrderHistoryComponentEditor element={element}/>;
        case 'customerWishList':
            return <CustomerWishListComponentEditor element={element}/>;
        case 'customerReturnHistory':
            return <CustomerReturnHistoryComponentEditor element={element}/>;
        case 'customerReturnRequest':
            return <CustomerReturnRequestComponentEditor element={element}/>;
        case 'customerOrderDetail':
            return <CustomerOrderDetailComponentEditor element={element}/>;
        case 'customerOrderTracking':
            return <CustomerOrderTrackingComponentEditor element={element}/>;
        case 'customerProductReview':
            return <CustomerProductReviewComponentEditor element={element}/>;
        case 'customerMessageHistory':
            return <CustomerMessageHistoryComponentEditor element={element}/>;
        case 'customerChatRoom':
            return <CustomerChatRoomComponentEditor element={element}/>;
        case 'tabNavigation':
            return <TabNavigationEditor element={element}/>;
        case 'tabNavigation':
            return <TabNavigationEditor element={element}/>;
        case 'footer':
            return <FooterEditor element={element}/>;
        
    
        // Add other cases as necessary
        default:
            return <div>Select a component to edit</div>;
    }
};

export default RenderComponentEditor;
