import React from "react";
import styles from './OrdersList.module.scss';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/store";
import { OrderType } from "../../../types/Data/OrderType";

const OrdersList: React.FC = () => {
    const navigate = useNavigate();
    const orders = useSelector<RootState>((state) => state.dashboard.orders.orders) as OrderType[];

    const handleViewOrder = (id: string | number) => {
        navigate(`./${id}`);
    };

    return (
        <div className={styles.ordersList}>
            <table>
                <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>Customer</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Total</th>
                        <th>Payment</th>
                        <th>Shipping</th>
                        <th>Tracking</th>
                        <th>Delivery Date</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order) => (
                        <tr key={order._id} onClick={() => handleViewOrder(order._id)}>
                            <td>{order._id}</td>
                            <td>{order.customer?.name}</td>
                            <td>{new Date(order.timestamps.orderDate).toLocaleString()}</td>
                            <td className={`${styles.orderStatus} ${order.status.toLowerCase()}`}>{order.status}</td>
                            <td>${order.orderSummary?.totalAmount.toFixed(2)}</td>
                            <td className={`${styles.paymentStatus} ${order.payment.paymentStatus.toLowerCase()}`}>{order.payment.paymentStatus}</td>
                            <td>{order.shipping?.shippingMethod}</td>
                            <td>{order.shipping?.trackingNumber}</td>
                            <td>{new Date(order.shipping?.estimatedDeliveryDate).toLocaleDateString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default OrdersList;
