import { ImageType } from "../../../types/Data/ImageType";

const mockImages:ImageType[] = [
    {
      _id: '255aecc7-a1ef-4625-9261-96bce8cbcf26',
      url: 'https://dummyimage.com/144x449',
      alt: 'Stop Republican after chance choose one can accept.',
      title: 'class',
      createdAt: '2024-01-12T22:33:40Z',
      fileSize: 181594,
      dimensions: '306x454',
      category: 'Asset',
      tags: ['certainly', 'seat', 'job'],
      usage: ['af306191-b8ec-4570-bb70-7af818bbb7de', '522b6751-8afd-4964-b8f4-1bdde34d0fd3']
    },
    {
      _id: '8a13ebb0-c51e-49b2-924c-3087562344c2',
      url: 'https://dummyimage.com/7x361',
      alt: 'Onto beautiful civil give.',
      title: 'itself',
      createdAt: '2024-05-22T12:31:57Z',
      fileSize: 231312,
      dimensions: '842x747',
      category: 'Product',
      tags: ['beautiful', 'side', 'should'],
      usage: ['10a6fec9-6189-4d9c-ab3f-68bd91969b30', 'e4817079-da25-4e23-a41d-42828e897ac3']
    },
    {
      _id: 'e9fcdb47-37ba-4a45-b9ab-c3bc92beaf62',
      url: 'https://www.lorempixel.com/1014/699',
      alt: 'Small very discussion throw interview without.',
      title: 'south',
      createdAt: '2024-06-17T13:11:02Z',
      fileSize: 369638,
      dimensions: '1555x373',
      category: 'Product',
      tags: ['maybe', 'recently', 'they'],
      usage: ['162d60a2-80d6-48ac-9ad8-6b67394c091a']
    },
    {
      _id: 'ca3c0ab9-7c23-46dd-9ccc-3009478d039a',
      url: 'https://www.lorempixel.com/419/678',
      alt: 'Full hotel decade protect.',
      title: 'southern',
      createdAt: '2024-09-26T11:18:22Z',
      fileSize: 88680,
      dimensions: '526x970',
      category: 'Blog',
      tags: ['decade', 'firm', 'attorney'],
      usage: ['27c8d86b-500c-4ab8-9963-c53c7fa8baa0']
    },
    {
      _id: 'd6202587-e726-4ce3-9fe6-9069fdbee1b9',
      url: 'https://www.lorempixel.com/152/801',
      alt: 'Sister indicate film strong.',
      title: 'eat',
      createdAt: '2024-07-17T14:58:44Z',
      fileSize: 359263,
      dimensions: '1567x640',
      category: 'Asset',
      tags: ['service', 'room', 'feeling'],
      usage: ['13224406-8a35-4d5c-8ada-d561961b9f64']
    },
    {
      _id: 'ca1ebc30-2ca9-4a14-8af3-df6ac0a16679',
      url: 'https://placekitten.com/815/182',
      alt: 'Democratic age subject former special majority eight.',
      title: 'good',
      createdAt: '2024-02-03T00:12:30Z',
      fileSize: 303423,
      dimensions: '662x634',
      category: 'Blog',
      tags: ['listen', 'expert', 'energy'],
      usage: []
    },
    {
      _id: '1495a651-dfbb-4e77-bd65-e9acd2856419',
      url: 'https://placekitten.com/123/382',
      alt: 'Avoid them gas add.',
      title: 'decade',
      createdAt: '2024-03-20T19:11:39Z',
      fileSize: 430140,
      dimensions: '1155x734',
      category: 'Asset',
      tags: ['remain', 'hair', 'government'],
      usage: ['cc8f927b-f159-4afc-a444-7d362e093805', '7e1d4f4b-8aea-46c8-8e25-6082b596337b']
    }
];

export default mockImages
  