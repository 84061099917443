import ButtonBuyV1 from "./ButtonBuyV1/ButtonBuyV1";
import ButtonBuyV2 from "./ButtonBuyV2/ButtonBuyV2";
import ButtonBuyV3 from "./ButtonBuyV3/ButtonBuyV3";
import { ButtonComponentProps } from "../../../components/Button/ButtonComponentProps";

enum ButtonBuyVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const ButtonBuyMapping:Record<ButtonBuyVersions, React.FC<any>> = {
    [ButtonBuyVersions.V1]: ButtonBuyV1,
    [ButtonBuyVersions.V2]: ButtonBuyV2,
    [ButtonBuyVersions.V3]: ButtonBuyV3,

};

interface ButtonBuyComponentProps {
    label?:string;
    onClick?:()=>void;
};

export {
    ButtonBuyVersions,
    ButtonBuyMapping
};

export type {
    ButtonBuyComponentProps
};



