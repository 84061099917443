import React from 'react'
import styles from '../Component.module.scss';
import ButtonProceedToCheckoutV1 from '../../UIElements/Button/ButtonProceedToCheckout/ButtonProceedToCheckoutV1/ButtonProceedToCheckoutV1';
import ButtonProceedToCheckoutV2 from '../../UIElements/Button/ButtonProceedToCheckout/ButtonProceedToCheckoutV2/ButtonProceedToCheckoutV2';

const ButtonCheckoutComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <ButtonProceedToCheckoutV1/>
        </div>
        <div className={styles.element}>
            <ButtonProceedToCheckoutV2/>
        </div>
    </div>
  )
}

export default ButtonCheckoutComponentPage