import React from 'react';
import styles from './TrustBadgeIconsV2.module.scss';
import { TrustBadgeIconsComponentProps } from '../TrustBadgeIconsComponentProps';
import { defaultTrustBadgeItems } from '../defaultTrustBadgeData';
import { getIconComponent } from '../utils';

const TrustBadgeIconsV2:React.FC<TrustBadgeIconsComponentProps> = ({items:items = defaultTrustBadgeItems.items }) => {
  return (
    <div className={styles.ribbonContainer}>
      {items?.map((item, index) => {
        return (
          <div key={index} className={styles.ribbon}>
            <div className={styles.icon}>{getIconComponent(item.icon!)}</div>
            <span>{item.title}</span>
          </div>
        );
      })}
    </div>
  );
};

export default TrustBadgeIconsV2;
