import React from 'react';
import styles from './InputV3.module.scss';
import { FaUser } from 'react-icons/fa';
import { InputComponentProps } from '../../InputComponentProps';

const InputV3: React.FC<InputComponentProps> = ({ label = "Input 3", icon = <FaUser />, value, onChange, placeholder }) => {
  return (
    <div className={styles.input3}>
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={styles.input}
      />
      <label className={styles.label}>{label}</label>
      <div className={styles.border}></div>
    </div>
  );
};

export default InputV3;
