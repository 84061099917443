import React from "react";
import { ReturnStatus } from "../../../types/Data/ReturnRequestType";
import SearchBarV4 from "../../../components/SearchBar/SearchBarV4/SerachBarV4";
import Select4 from "../../../components/Select/Select4/Select4";
import styles from './ReturnProcessFilter.module.scss';

interface FiltersProps {
    query: {
        search: string;
        status: string;
    };
    onQueryChange: (key: string, value: any) => void;
}

const Filters: React.FC<FiltersProps> = ({ query, onQueryChange }) => {
    const statusOptions = Object.values(ReturnStatus).map(value => ({ label: value, value }));

    return (
        <div className={styles.filters}>
            <SearchBarV4
                placeholder="Search by customer name, email, or transaction ID..."
                value={query.search}
                onSearch={(search) => onQueryChange('search', search)}
            />
            <div className={styles.selectRow}>
                <Select4
                    options={statusOptions}
                    value={query.status}
                    onChange={(value) => onQueryChange('status', value as string)}
                    placeholder="Select status"
                />
            </div>
        </div>
    );
};

export default Filters;
