import React, { useState } from 'react';
import styles from './QAListV3.module.scss';
import { QAListComponentProps } from '../QAListComponentProps';
import { defaultQAListProps } from '../defaultQAListProps';

const QAListV3: React.FC<QAListComponentProps> = ({ items = defaultQAListProps.items }) => {
  const [selected, setSelected] = useState<number | null>(null);

  const toggleItem = (index: number) => {
    setSelected(selected === index ? null : index);
  };

  return (
    <div className={styles.qaList}>
      {items!.map((item, index) => (
        <div key={index} className={styles.qaItem}>
          <div className={styles.question} onClick={() => toggleItem(index)}>
            <h3>{item.question}</h3>
          </div>
          <div className={`${styles.answer} ${selected === index ? styles.show : ''}`}>
            {item.answer}
          </div>
        </div>
      ))}
    </div>
  );
};

export default QAListV3;
