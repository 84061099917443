import React, { useState } from "react";
import styles from "./ImagesList.module.scss";
import { ImageType } from "../../../types/Data/ImageType";

interface ImagesListType {
    images?: ImageType[],
    onImageClick?: (id:string) => void
};

const ImagesList: React.FC<ImagesListType> = ({images=[], onImageClick=()=>null}) => {

  if (!images || !Array.isArray(images) || images.length == 0) return <p>No images yet...</p>

  return (
    <div className={styles.imagesList}>
      <div className={styles.imageGrid}>
        {images.map((image) => (
          <div key={image._id} className={styles.imageCard} onClick={() => onImageClick(image._id!)}>
            <img src={image.url} alt={image.alt || image.title} className={styles.thumbnail} />
            <div className={styles.imageInfo}>
              <h4>{image.title || "Untitled"}</h4>
              <p>{image.alt|| "No Alt Text"}</p>
            </div>
          </div>
        ))}
      </div>

      
    </div>
  );
};

export default ImagesList;
