// CustomerWishlist.tsx

import React, { useEffect, useState } from 'react';
import styles from './CustomerWishlist.module.scss';
import axios from 'axios';
import { FaTrashAlt, FaPlusCircle, FaSearch } from 'react-icons/fa';
import { WishlistType, WishlistItemType } from '../../../../types/Data/WishlistType';
import { ProductType } from '../../../../types/Data/ProductType';

interface CustomerWishlistProps {
  customerId: string;
}

const CustomerWishlist: React.FC<CustomerWishlistProps> = ({ customerId }) => {
  const [wishlist, setWishlist] = useState<WishlistType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [productSearchResults, setProductSearchResults] = useState<ProductType[]>([]);
  const [isAddingItem, setIsAddingItem] = useState<boolean>(false);

  useEffect(() => {
    // Fetch the customer's wishlist
  }, [customerId]);

  const handleRemoveItem = (productId: string) => {
    // Remove item from wishlist
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    if (e.target.value.length > 2) {
      // Fetch products matching the search term
    }
  };

  const handleAddItem = (product: ProductType) => {
    // Add item to wishlist
  };

  if (loading) {
    return <div className={styles.loading}>Loading wishlist...</div>;
  }

  return (
    <div className={styles.customerWishlist}>
      <div className={styles.header}>
        <h2>Customer Wishlist</h2>
        <button onClick={() => setIsAddingItem(!isAddingItem)} className={styles.addButton}>
          {isAddingItem ? 'Cancel' : 'Add Item'} <FaPlusCircle />
        </button>
      </div>

      {isAddingItem && (
        <div className={styles.searchContainer}>
          <div className={styles.searchBox}>
            <FaSearch className={styles.searchIcon} />
            <input
              type="text"
              placeholder="Search products..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          {productSearchResults.length > 0 && (
            <div className={styles.searchResults}>
              {productSearchResults.map((product) => (
                <div key={product._id} className={styles.searchResultItem}>
                  <img src={product?.images![0].url} alt={product.name} />
                  <div className={styles.productInfo}>
                    <span className={styles.productName}>{product.name}</span>
                    <span className={styles.productPrice}>${product.price.toFixed(2)}</span>
                  </div>
                  <button
                    onClick={() => handleAddItem(product)}
                    className={styles.addProductButton}
                  >
                    Add
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {!wishlist || wishlist.items.length === 0 ? (
        <div className={styles.emptyWishlist}>No items in wishlist.</div>
      ) : (
        <div className={styles.wishlistItems}>
          {wishlist.items.map((item) => {
            const { product } = item;
            let productData: Partial<ProductType> | null = null;

            if (typeof product === 'string') {
              // If product is a string (product ID), fetch product details
              // For this example, we'll assume product details are populated
              return null; // Alternatively, implement fetching logic here
            } else {
              productData = product;
            }

            if (!productData) return null;

            return (
              <div key={productData._id} className={styles.wishlistItem}>
                <img src={productData?.images![0].url} alt={productData.name} />
                <div className={styles.itemInfo}>
                  <span className={styles.productName}>{productData.name}</span>
                  <span className={styles.productPrice}>${productData.price?.toFixed(2)}</span>
                  <span className={styles.addedAt}>
                    Added on {new Date(item.createdAt).toISOString().substring(0, 10)}
                  </span>
                </div>
                <button
                  onClick={() => handleRemoveItem(productData?._id || '')}
                  className={styles.removeButton}
                >
                  <FaTrashAlt />
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CustomerWishlist;
