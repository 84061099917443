import React, { useEffect, useState } from 'react';
import styles from './CancellationNotes.module.scss';

interface CancellationNotesProps {
    notes?: string | undefined,
    onNotesChange: (adminNotes?: string | undefined) => void;
}

const CancellationNotes: React.FC<CancellationNotesProps> = ({ notes, onNotesChange }) => {

    return (
        <div className={styles.cancellationtNotesForm}>
            <h4>Admin Notes</h4>

            <div className={styles.formGroup}>
                <textarea
                    id="cancellationNotes"
                    value={notes}
                    onChange={(e) => onNotesChange(e.target.value)}
                    placeholder="Add any notes that will be visible to the customer..."
                    rows={4}
                />
            </div>

        </div>
    );
};

export default CancellationNotes;
