import React, { useEffect, useState } from 'react';
import styles from '../../UIElementsMainSettings/UiElementEditor.module.scss';
import { FooterComponentProps, FooterItem, FooterMapping, FooterVersions } from '../FooterComponentProps';
import RadioGroup1 from '../../../components/RadioGroup/RadioGroup1/RadioGroup1';
import Select4 from '../../../components/Select/Select4/Select4';
import InputV4 from '../../../components/Input/InputText/InputV4/InputV4';
import ButtonV3 from '../../../components/Button/ButtonV3/ButtonV3';
import ButtonDelete from '../../../components/Button/ButtonDelete/ButtonDelete';
import ColorPickerV1 from '../../../components/ColorPicker/ColorPickerV1/ColorPickerV1';
import { BaseElementType, ComponentType } from '../../../types/Data/BaseElementType';
import { defaultFooterProps } from './defaultFooterProps';
import UIElementsMainSettings from '../../UIElementsMainSettings/UIElementsMainSettings';
import ButtonV1 from '../../../components/Button/ButtonV1/ButtonV1';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../Store/store';
import { fetchCreateElement, fetchUpdateElement } from '../../../Dashboard/DashboardState/element/elementSlice';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';


interface FooterEditorProps {
  element?: BaseElementType<FooterComponentProps>
}

const FooterEditor: React.FC<FooterEditorProps> = ({ element }) => {
  const initialItem: FooterItem = {
    type: 'link',
    content: '',
  };

  const defaultElement: BaseElementType<FooterComponentProps> = {
    type: ComponentType.Footer,
    version: 'v1',
    page: '',
    props: defaultFooterProps,
    position: undefined,
    isVisible: true,
  };

  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state:RootState)=>state.dashboard.elements.loading);
  const error = useSelector((state:RootState)=>state.dashboard.elements.error);

  const [currentElement, setCurrentElement] = useState<BaseElementType<FooterComponentProps>>(element ? element : defaultElement);

  useEffect(() => {
    if (element) {
      setCurrentElement(element);
    } else {
      setCurrentElement(defaultElement);
    }
  }, [element]);
  
  const handleElementUpdate = (key: string, value: any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      [key]: value,
    }));
  };

  const handleElementVisibilityUpdate = () => {
    setCurrentElement((prevElement) => ({ ...prevElement, isVisible: !prevElement.isVisible }));
  };

  const handlePropsChange = (key:string, value:any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      props: {
        ...prevElement.props,
        [key]: value,
      }
    }));
  }

  const [newItem, setNewItem] = useState<FooterItem>(initialItem);

  const itemOptions = [
    { label: 'Text', value: 'text' },
    { label: 'Link', value: 'link' },
  ];

  const handleAddItem = () => {
    if (newItem.content.trim()) {
      setNewItem(initialItem);
    } else {
      alert('Content cannot be empty');
    }
  };

  const handleUpdateNavigationItem = (action: 'add' | 'update' | 'delete', index?: number, key?: string, value?: any) => {
    const currentItems = currentElement.props?.items ?? [];
  
    if (action === 'add') {
      if (!newItem.content.trim()) {
        alert('Content cannot be empty');
        return;
      }
      handlePropsChange('items', [...currentItems, newItem]);
      setNewItem(initialItem); // Reset new item after adding
    }
  
    if (action === 'update' && index !== undefined) {
      const updatedItems = currentItems.map((item, i) =>
        i === index ? { ...item, [key!]: value } : item
      );
      handlePropsChange('items', updatedItems);
    }
  
    if (action === 'delete' && index !== undefined) {
      const filteredItems = currentItems.filter((_, i) => i !== index);
      handlePropsChange('items', filteredItems);
    }
  };

  const handleSaveElement = () => {
    if (currentElement?._id) {
      dispatch(fetchUpdateElement({id:currentElement._id, element:currentElement}))
    } else {
      dispatch(fetchCreateElement(currentElement))
    }
  };

  const SelectedComponent = FooterMapping[currentElement.version as FooterVersions] || FooterMapping[FooterVersions.V1];

  const elementsVersion = Object.keys(FooterVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: FooterVersions[key as keyof typeof FooterVersions],
  }));

  return (
    <div className={styles.footerEditor}>
      { error && <ErrorBannerV1 message={error}/>}
      { loading && <LoadingIndicatorV1/>}
      <h2>Footer Editor</h2>
      <div className={styles.componentPreview}>
        <SelectedComponent {...currentElement.props} />
      </div>
      <div className={styles.componentEditor}>
        <UIElementsMainSettings
            element={currentElement}
            elementVersions={elementsVersion}
            onElementUpdate={handleElementUpdate}
            onVisibilitytUpdate={handleElementVisibilityUpdate}
        />

      {/* Radio Groups */}
      <div className={styles.mainSettings}>
        <RadioGroup1
          name="showCompanyInfo"
          options={['show', 'hide']}
          selectedValue={element?.props?.showCompanyInfo ? 'show' : 'hide'}
          onChange={(value) => handlePropsChange('showCompanyInfo', value === 'show')}
          title="Show Company Information"
        />
        <RadioGroup1
          name="showSocialMedia"
          options={['show', 'hide']}
          selectedValue={element?.props?.showSocialMedia ? 'show' : 'hide'}
          onChange={(value) => handlePropsChange('showSocialMedia', value === 'show')}
          title="Show Social Media"
        />
        <RadioGroup1
          name="showPaymentMethods"
          options={['show', 'hide']}
          selectedValue={element?.props?.showPaymentMethods ? 'show' : 'hide'}
          onChange={(value) => handlePropsChange('showPaymentMethods', value === 'show')}
          title="Show Payment Methods"
        />
        <RadioGroup1
          name="showDeliveryMethods"
          options={['show', 'hide']}
          selectedValue={element?.props?.showDeliveryMethods ? 'show' : 'hide'}
          onChange={(value) => handlePropsChange('showDeliveryMethods', value === 'show')}
          title="Show Delivery Methods"
        />
        <RadioGroup1
          name="showNewsletterSubscription"
          options={['show', 'hide']}
          selectedValue={element?.props?.showNewsletterSubscription ? 'show' : 'hide'}
          onChange={(value) => handlePropsChange('showNewsletterSubscription', value === 'show')}
          title="Show Newsletter Subscription"
        />

      {/* Color Pickers */}
      <div className={styles.colorPickers}>
        <ColorPickerV1
          label="Footer Background Color"
          color={element?.props?.backgroundColor}
          onChange={(color) => handlePropsChange('backgroundColor', color)}
        />
        <ColorPickerV1
          label="Footer Text Color"
          color={element?.props?.textColor}
          onChange={(color) => handlePropsChange('textColor', color)}
        />
      </div>
       {/* Add New Item */}
       <div className={styles.addElement}>
        <h4>Items</h4>
        <Select4
          options={itemOptions}
          value={newItem.type}
          onChange={(value) => setNewItem({ ...newItem, type: value })}
        />
        <InputV4
          type="text"
          placeholder="Add Custom Link"
          value={newItem.content}
          onChange={(e) => setNewItem({ ...newItem, content: e.target.value })}
          wrapperStyle={{marginTop: 10}}
        />
        <ButtonV3
          title="Add Item"
          onClick={handleAddItem}
          style={{ height: 42 }}
        />
      </div>

      {/* Existing Items List */}
      <div>
        {(element?.props?.items ?? []).map((item, index) => (
          <div key={index} className={styles.element}>
            <Select4
              options={itemOptions}
              value={item.type}
              onChange={(value) => handleUpdateNavigationItem('update', index, 'type', value)}
              />
            <InputV4
              type="text"
              value={item.content}
              onChange={(e) => handleUpdateNavigationItem('update', index, 'content', e.target.value)}
              />
            <ButtonDelete onClick={() => handleUpdateNavigationItem('delete', index)} />
          </div>
        ))}
      </div>
    </div>
      </div>
      <div className={styles.buttonContainer}>
        <ButtonV1 label="Save Element" onClick={handleSaveElement} />
        <ButtonDelete/>
      </div>
    </div>
  );
};

export default FooterEditor;
