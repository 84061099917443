import React from 'react'
import styles from '../Component.module.scss';
import FileUpload1 from '../../components/FileUpload/FileUploadV1/FileUploadV1';

const FileUploadComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <FileUpload1/>
        </div>
    </div>
  )
}

export default FileUploadComponentPage