import React, { useState, KeyboardEvent } from 'react';
import styles from './InputTagsV1.module.scss';
import { InputTagsComponentProps } from '../../InputComponentProps';


const InputTagsV1: React.FC<InputTagsComponentProps> = ({ tags = [], onChange, placeholder = 'Add a tag', wrapperStyle, label="Tags" }) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [tagList, setTagList] = useState<string[]>(tags);

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && inputValue.trim()) {
            if (!tagList.includes(inputValue.trim())) {
                const newTags = [...tagList, inputValue.trim()];
                setTagList(newTags);
                onChange?.(newTags);
            }
            setInputValue('');
        } else if (e.key === 'Backspace' && !inputValue) {
            removeTag(tagList.length - 1);
        }
    };

    const removeTag = (index: number) => {
        const newTags = tagList.filter((_, i) => i !== index);
        setTagList(newTags);
        onChange?.(newTags);
    };

    return (
        <div>
            {label && <p className={styles.label}>{label}</p>}
        <div className={styles.tagInputContainer} style={{...wrapperStyle}}>
            <div className={styles.tags}>
                {tagList.map((tag, index) => (
                    <div key={index} className={styles.tag}>
                        {tag}
                        <span className={styles.tagClose} onClick={() => removeTag(index)}>&times;</span>
                    </div>
                ))}
            </div>
            <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                className={styles.input}
            />
        </div>
        </div>

    );
};

export default InputTagsV1;