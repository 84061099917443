import React, { useEffect, useState } from 'react';
import styles from '../../UIElementsMainSettings/UiElementEditor.module.scss';
import { IoRemove } from 'react-icons/io5';

import { BaseElementType, ComponentType } from '../../../types/Data/BaseElementType';
import Select4 from '../../../components/Select/Select4/Select4';
import ButtonV1 from '../../../components/Button/ButtonV1/ButtonV1';
import { VariantSelectorComponentProps, VariantSelectorMapping, VariantSelectorVersions } from '../VariantSelectorComponentProps';
import defaultVariantSelectorProps from '../variantSelectorDefaultProps';
import { SizeSelectorVersions } from '../../SizeSelector/SizeSelectorComponentProps';
import { ColorSelectorVersions } from '../../ColorSelector/ColorSelectorComponentProps';
import UIElementsMainSettings from '../../UIElementsMainSettings/UIElementsMainSettings';
import ButtonDelete from '../../../components/Button/ButtonDelete/ButtonDelete';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../Store/store';
import { useSelector } from 'react-redux';
import { fetchCreateElement, fetchUpdateElement } from '../../../Dashboard/DashboardState/element/elementSlice';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';

interface ProductVariantSelectorEditorProps {
  element?: BaseElementType<VariantSelectorComponentProps>;
}

const VariantSelectorComponentEditor: React.FC<ProductVariantSelectorEditorProps> = ({
  element,
}) => {
  
  const defaultElement: BaseElementType<VariantSelectorComponentProps> = {
    type: ComponentType.VariantSelector,
    version: 'v1',
    page: 'product',
    props: defaultVariantSelectorProps,
    position: undefined,
    isVisible: true,
  };

  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state:RootState)=>state.dashboard.elements.loading);
  const error = useSelector((state:RootState)=>state.dashboard.elements.error);

  const [currentElement, setCurrentElement] = useState(element ? element : defaultElement);

  useEffect(() => {
    if (element) {
      setCurrentElement(element)
    } else {
      setCurrentElement(defaultElement)
    }
  }, [element])

  const handleElementUpdate = (key:string, value: any, ) => {
      setCurrentElement((prevElement) => ({
        ...prevElement,
        [key]: value,
      }));
  };

  const handlePropsChange = (key:string, value:any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      props: {
        ...prevElement.props,
        [key]: value,
      }
    }));
  }


  const handleElementVisibilityUpdate = () => {
    setCurrentElement(prev => ({ ...prev, isVisible: !prev.isVisible }));
  };

  const handleSaveElement = () => {
    if (currentElement?._id) {
      dispatch(fetchUpdateElement({id:currentElement._id, element:currentElement}))
    } else {
      dispatch(fetchCreateElement(currentElement))
    }
  };

  const SelectedComponent = VariantSelectorMapping[currentElement.version as VariantSelectorVersions] || VariantSelectorMapping[VariantSelectorVersions.V1];
  
  const elementsVersion = Object.keys(VariantSelectorVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: VariantSelectorVersions[key as keyof typeof VariantSelectorVersions],
  }));

  const sizeSelectionsVersion = Object.keys(SizeSelectorVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: SizeSelectorVersions[key as keyof typeof SizeSelectorVersions],
  }));

  const colorSelectionsVersion = Object.keys(ColorSelectorVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: SizeSelectorVersions[key as keyof typeof SizeSelectorVersions],
  }));

  return (
    <div className={styles.container}>
      { error && <ErrorBannerV1 message={error}/>}
      { loading && <LoadingIndicatorV1/>}
      <div className={styles.componentPreview}>
        <SelectedComponent banner={currentElement?.props} />
      </div>

      <div className={styles.componentEditor}>
        <UIElementsMainSettings
            element={currentElement}
            elementVersions={elementsVersion}
            onElementUpdate={handleElementUpdate}
            onVisibilitytUpdate={handleElementVisibilityUpdate}
        />
        <div className={styles.slides}>
          <div className={styles.slide}>
              <Select4
                label='Color Selector Version'
                value={currentElement.props?.colorSelectorVersion}
                options={colorSelectionsVersion}
                onChange={(value) => handlePropsChange('colorSelectorVesrion', value as string)}
              />
          </div>
          <div className={styles.slide}>
              <Select4
                label='Size Selector Version'
                value={currentElement.props?.sizeSelectorVersion}
                options={sizeSelectionsVersion}
                onChange={(value) => handlePropsChange('sizeSelectorVersion', value as string)}
              />
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <ButtonV1 label="Save Element" onClick={handleSaveElement} />
        <ButtonDelete/>
      </div>
    </div>
  );
};

export default VariantSelectorComponentEditor;
