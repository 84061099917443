import React from "react";
import styles from './ReturnRequestList.module.scss';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/store";
import { OrderType } from "../../../types/Data/OrderType";
import { ReturnRequestType } from "../../../types/Data/ReturnRequestType";

const ReturnRequestList: React.FC = () => {
    const navigate = useNavigate();
    const returnRequests = useSelector<RootState>((state) => state.dashboard.returnRequest.returnRequests) as ReturnRequestType[];

    const handleViewReturnRequest = (id: string | number) => {
        navigate(`/dashboard/returns-request/detail/${id}`);
    };

    return (
        <div className={styles.returnRequestList}>
            <table>
                <thead>
                    <tr>
                        <th>Return ID</th>
                        <th>Customer</th>
                        <th>Requested Date</th>
                        <th>Status</th>
                        <th>Total Items</th>
                        <th>Refund ID</th>
                        <th>Shipment Status</th>
                        <th>Updated</th>
                    </tr>
                </thead>
                <tbody>
                    {returnRequests.map((request) => (
                        <tr key={request._id} onClick={() => handleViewReturnRequest(request._id)}>
                            <td>{request._id}</td>
                            <td>{request.customerName}</td>
                            <td>{new Date(request.createdAt).toLocaleString()}</td>
                            <td className={`${styles.returnStatus} ${styles[request.status.toLowerCase()]}`}>{request.status}</td>
                            <td>{request.items.length}</td>
                            <td>{request.refundRequestId || 'N/A'}</td>
                            <td>{request.shipmentInfo?.trackingNumber || 'Pending'}</td>
                            <td>{new Date(request.updatedAt).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ReturnRequestList;
