import React from 'react';
import styles from './ProductDescriptionV1.module.scss';
import { ProductDescriptionComponentProps } from '../ProducrDescriptionComponentProps';

const ProductDescriptionV1: React.FC<ProductDescriptionComponentProps> = ({
  product = {
    description: "This is the description of the component.",
    attributes:[],
  },
  textColor = '#333',
  fontSize = '1em',
}) => {
  return (
    <div className={styles.simpleDescription} style={{ color: textColor, fontSize }}>
      <p>{product?.description}</p>
      {product?.attributes && (
        <div className={styles.attributes}>
          {product?.attributes.map((attr, index) => (
            <div key={index} className={styles.attribute}>
              <strong>{attr.name}:</strong> {attr.value}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductDescriptionV1;
