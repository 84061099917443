import React, { useState, useEffect } from 'react';
import styles from './ReviewDetailPage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { ReviewType } from '../../../types/Data/ReviewType';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';
import { useParams } from 'react-router-dom';
import ReviewInfo from './ReviewInfo/ReviewInfo';
import ReviewAdminOperations from './ReviewAdminOperation/ReviewAdminOperations';
import { getReviewById, updateReview } from '../../../api/review/reviewApi';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import { handleApiError } from '../../../api/apiError';
import SaveButton from '../../../components/Button/SaveButton/SaveButton';
import ButtonCancel from '../../../components/Button/ButtonCancel/ButtonCancel';

interface ReviewDetailPageProps {}

const ReviewDetailPage: React.FC<ReviewDetailPageProps> = () => {
  const { reviewId } = useParams<{ reviewId: string }>();
  const dispatch = useDispatch();
  
  const review = useSelector<RootState>((state) => 
    state.dashboard.reviews.reviews.find(r => r._id === reviewId)
  ) as ReviewType;

  // State variables for original and current review
  const [originalReview, setOriginalReview] = useState<Partial<ReviewType>>(review || {});
  const [currentReview, setCurrentReview] = useState<Partial<ReviewType>>(review || {});

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<{message:string, status:number | undefined} | null>()
  const [isSaving, setIsSaving] = useState<boolean>(false);


  // Sync the currentReview state when the review is updated
  useEffect(() => {
    if (review) {
      setCurrentReview(review);
    }
  }, [review]);

  /*
  useEffect(() => {  
    if (reviewId) {
      getReview(reviewId)
        .then(data => {
          if (data) {
            setOriginalReview(data);  // Set the original review
            setCurrentReview(data);   // Set the current review
          }
        })
        .catch(error => {
          let handledError = handleApiError(error);
          setError({ message: handledError.message, status: handledError.status });
        });
    }
  
    // Cleanup on component unmount or page change
    return () => {

    };
  }, [reviewId]);
  */

  const getReview = async (reviewId: string) => {
    setIsLoading(true); // Set loading to true before request
    try {
      let data = await getReviewById(reviewId);
      return data;
    } catch (error: any) {
      if (error.name === 'AbortError') {
        console.log('Request cancelled');
      } else {
        let handledError = handleApiError(error);
        setError({ message: handledError.message, status: handledError.status });
      }
    } finally {
      setIsLoading(false); // Ensure loading is turned off
    }
  };

  const handleSave = async () => {
    setIsSaving(true);  // To handle button loading state
    try {
      const response = await updateReview(currentReview._id!, currentReview);  // Call the API to save the review
      
      if (response && response.status === 200) {
        // Assuming 200 is the success status code
        alert('Review updated successfully!');  // Success message
        
        // Optionally reset form or state if needed
      } else {
        // Handle unexpected success cases
        alert('Something went wrong while updating the review!');
      }
    } catch (error: any) {
      const handledError = handleApiError(error);  // This function should parse and return user-friendly error messages
      alert(`Failed to save review: ${handledError}`);
    } finally {
      setIsSaving(false);  // Stop loading state
    }
   };

  // Revert changes by setting currentReview back to originalReview
  const handleCancel = () => {
    setCurrentReview(originalReview);
  };

  // Handle change from child component
  const handleReviewChange = (updatedReview: Partial<ReviewType>) => {
    setCurrentReview(prevReview => ({
      ...prevReview,
      ...updatedReview
    }));
  };

  if (!review) {
    return <div>Loading...</div>; // Handle the case where the review is not found
  }

  return (
    <div>
      <DashboardHeader title="Edit Review" />
      {isLoading && <LoadingIndicatorV1 />}
      {error && <ErrorBannerV1 message={error.message} status={error.status} />}
      <div className={styles.reviewEditPage}>
        { currentReview && <ReviewInfo review={currentReview as ReviewType} /> }
        <ReviewAdminOperations 
          review={currentReview} 
          onChange={handleReviewChange} 
        />
      </div>
      <SaveButton onClick={handleSave} isSaving={isSaving}/>
      <ButtonCancel onClick={handleCancel} title="Cancel" style={{marginLeft: 20}} />
    </div>
  );
};

export default ReviewDetailPage;
