import { ProductType } from "../../types/Data/ProductType";
import ProductDescriptionV1 from "../../UIElements/ProductDescription/ProductDescriptionV1/ProductDescriptionV1";
import ProductDescriptionV2 from "../../UIElements/ProductDescription/ProductDescriptionV2/ProductDescriptionV2";
import ProductDescriptionV3 from "../../UIElements/ProductDescription/ProductDescriptionV3/ProductDescriptionV3";

enum ProductDescriptionVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const ProductDescriptionMapping:Record<ProductDescriptionVersions, React.FC<any>> = {
    [ProductDescriptionVersions.V1]: ProductDescriptionV1,
    [ProductDescriptionVersions.V2]: ProductDescriptionV2,
    [ProductDescriptionVersions.V3]: ProductDescriptionV3,
};

interface ProductDescriptionComponentProps {
    product?: Partial<ProductType>
    textColor?: string;
    fontSize?: string;
    titleColor?: string; // For AccordionDescription
    activeColor?: string; // For TabbedDescription
    inactiveColor?: string; // For TabbedDescription
}

export {
    ProductDescriptionVersions,
    ProductDescriptionMapping,
};

export type {
    ProductDescriptionComponentProps
};