import React, { useEffect, useState } from "react";
import styles from './TicketDetailPage.module.scss';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../Store/store";
import { SupportTicketType, SupportTicketStatus } from "../../../../types/Data/SupportTicketType";
import { dispatchUpdateTicket } from "../../../DashboardState/tickets/ticketsSlice";
import DashboardHeader from "../../../../components/Header/DashboardHeader/DashboardHeader";
import TicketMainInfo from "./TicketMainInfo/TicketMainInfo";
import ChatRoomV1 from "../../../../components/ChatRoom/ChatRoomV1/ChatRoomV1";
import EmailEditorV2 from "../../../../components/EmailEditor/EmailEditorV2/EmailEditorV2";
import TicketStatus from "./TicketStatus/TicketStatus";
import CommentBox from "../../../../components/TextArea/CommentBox/CommentBox";
import SaveButton from "../../../../components/Button/SaveButton/SaveButton";
import { getTicketById, updateTicket } from "../../../../api/ticket/ticketApi";
import { handleApiError } from "../../../../api/apiError";
import LoadingIndicatorV1 from "../../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1";
import ButtonCancel from "../../../../components/Button/ButtonCancel/ButtonCancel";

const TicketDetailPage: React.FC = () => {
    const dispatch = useDispatch();
    const { ticketId } = useParams<{ ticketId: string }>();
    
    const ticket = useSelector<RootState, SupportTicketType | undefined>(state => 
        state.dashboard.tickets.tickets.find((ticket) => ticket._id === ticketId)
    );

    // state store current ticket
    const [originalTicket, setOriginalTicket] = useState<SupportTicketType | undefined>(ticket);
    const [currentTicket, setCurrentTicket] = useState<SupportTicketType | undefined>(ticket);


    // state to store api call status
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false)

    const getTicket = async () => {
        setIsLoading(true);
        try {
            const response = await getTicketById(ticketId!)
            if (response.status === 200 && response.data) {
                setOriginalTicket(response.data.data)
                setCurrentTicket(response.data.data)
            } else {
                alert('An error occured while retrieving support ticket')
            }
        } catch (error: any) {
            const handledError = handleApiError(error)
            alert(handledError.message)
        } finally {
            setIsLoading(false)
        }
    }

    /*
    useEffect(()=>{
        if (ticketId) {
            getTicket()
        }  
    }, [ticketId])
    */


    const handleChange = (key: string, value: any) => {
        setCurrentTicket((prev) => (
            {...prev!, [key]:value}
        ))
    };

    const handleCancel = () => {
        setCurrentTicket(originalTicket)
    }

    const handleUpdateTicket = async () => {
        setIsSaving(true);
        try {
            const response = await updateTicket(currentTicket?._id!, currentTicket!);
            if (response.status === 200 && response.data) {
                setOriginalTicket(response.data.data)
                setCurrentTicket(response.data.data)
            } else {
                alert('An error occured while updating support ticket')
            }
        } catch(error: any) {
            const handledError = handleApiError(error);
            alert(handledError.message)
        } finally {
            setIsSaving(false)
        }
    };

    if (isLoading) return <LoadingIndicatorV1/>

    if (!currentTicket) return <div className={styles.notFound}>Ticket not found</div>;
    
    return (
        <div className={styles.container}>
            <DashboardHeader title="Ticket Detail" />
            <TicketMainInfo ticket={originalTicket!}/>
            <TicketStatus 
                status={currentTicket.status} 
                onStatusChange={(value)=>handleChange('status', value) }
            />
            <ChatRoomV1 
                messages={[]} 
                onSendMessage={()=>null}
            />
            <EmailEditorV2 
                onSend={()=>null}
            />
            <CommentBox 
                comment={currentTicket.notes}
                onChange={(comment)=>handleChange('notes', comment)}
                
            />
            <SaveButton onClick={handleUpdateTicket}/>
            <ButtonCancel onClick={handleCancel} style={{marginLeft:12}}/>
        </div>
    );
};

export default TicketDetailPage;
