import React from 'react';
import styles from './ProductListV2.module.scss';
import { ProductListComponentProps } from '../ProductListComponentProps';
import exampleProducts from '../../../Dashboard/DashboardState/products/dataProducts';
import { ProductCardListMapping, ProductCardListVersions } from '../../ProductCardList/ProductCardListComponentProps';

const ProductListV2: React.FC<ProductListComponentProps> = ({ products = exampleProducts, cardVersion=ProductCardListVersions.V1, onProductClick = (id: string) => null }) => {
  const CardComponent = ProductCardListMapping[cardVersion];

  return (
    <div className={styles.carouselContainer}>
      <div className={styles.carousel}>
        {products.map((product) => (
          <CardComponent product={product} onProductClick={onProductClick}/>
        ))}
      </div>
    </div>
  );
};

export default ProductListV2;
