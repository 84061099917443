import React from 'react';
import { FaChartLine, FaUsers, FaDollarSign } from 'react-icons/fa';
import { StatComponentProps, StatItem } from './StatsComponentProps';

const defaultStats: StatItem[] = [
    {
        title: 'Total Sales',
        value: '$50,000',
        icon: <FaDollarSign />,
        comparaison: '+10% from last month',
        status: 'confirmed',
    },
    {
        title: 'New Users',
        value: 1200,
        icon: <FaUsers />,
        comparaison: '+20% from last month',
        status: 'confirmed',
    },
    {
        title: 'Pending Orders',
        value: 35,
        icon: <FaChartLine />,
        comparaison: '-5% from last month',
        status: 'pending',
    },
    {
        title: 'Cancelled Orders',
        value: 10,
        icon: <FaChartLine />,
        comparaison: '+2% from last month',
        status: 'cancelled',
    },
];

const defaultStatComponentProps: StatComponentProps = {
    title: 'Monthly Overview',
    description: 'A summary of key performance indicators for the current month.',
    img: 'https://via.placeholder.com/400x200', // Placeholder image
    stats: defaultStats,
};

export { defaultStatComponentProps };
