import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import styles from './ChartCancellationByItem.module.scss';

const data = [
    { item: "Item A", count: 30 },
    { item: "Item B", count: 50 },
    { item: "Item C", count: 20 },
    { item: "Item D", count: 10 },
];

const ChartCancellationByItem: React.FC = () => {
    const [chartData, setChartData] = useState<ChartData<'bar'> | null>(null);

    useEffect(() => {
        const labels = data.map((entry: any) => entry.item);
        const values = data.map((entry: any) => entry.count);
        setChartData({
            labels: labels,
            datasets: [
                {
                    label: 'Cancellations per Item',
                    data: values,
                    backgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#FFCE56',
                        '#4BC0C0',
                    ],
                    hoverBackgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#FFCE56',
                        '#4BC0C0',
                    ],
                    borderColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#FFCE56',
                        '#4BC0C0',
                    ],
                    borderWidth: 1,
                },
            ],
        });
    }, []);

    const options: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    color: '#ffffff', // Legend text color
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Items',
                    color: '#ffffff',
                },
                ticks: {
                    color: '#ffffff',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Cancellation Count',
                    color: '#ffffff',
                },
                ticks: {
                    color: '#ffffff',
                },
            },
        },
        animation: {
            duration: 1500,
            easing: 'easeInOutQuart',
        },
    };

    return (
        <div className={styles.container}>
            <h3>Concellation By Items</h3>
            <div className={styles.chart_container}>
                {chartData && <Bar data={chartData} options={options} />}
            </div>
        </div>
        
    );
};

export default ChartCancellationByItem;
