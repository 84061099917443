import React from 'react'
import styles from '../Component.module.scss';
import StepIndicatorV1 from '../../UIElements/StepIndicator/StepIndicatorV1/StepIndicatorV1';
import StepIndicatorV2 from '../../UIElements/StepIndicator/StepIndicatorV2/StepIndicatorV2';
import StepIndicatorV3 from '../../UIElements/StepIndicator/StepIndicatorV3/StepIndicatorV3';

const StepIndicatorComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <StepIndicatorV1/>
        </div>
        <div className={styles.element}>
            <StepIndicatorV2/>
        </div>
        <div className={styles.element}>
            <StepIndicatorV3/>
        </div>
    </div>
  )
}

export default StepIndicatorComponentPage