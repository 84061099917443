import React from 'react';
import styles from './ButtonV10.module.scss';
import { IoAdd } from 'react-icons/io5';
import { ButtonComponentProps } from '../ButtonComponentProps';

const ButtonV10: React.FC<ButtonComponentProps> = ({ title="Button 2", onClick }) => {
  return (
    <button id={styles.button_10} onClick={onClick}>
        <IoAdd className={styles.icon}/>
    </button>
  )
};

export default ButtonV10;