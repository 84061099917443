import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./user/userSlice";
import cartReducer from "./cart/cartSlice";
import uiReducer from "./ui/uiSlice";
import checkoutReducer from "./checkout/checkoutSlice";
import productReducer from "./product/productSlice";

const eccomerceReducer = combineReducers({
    user: userReducer,
    cart: cartReducer,
    ui: uiReducer,
    checkout: checkoutReducer,
    product: productReducer
});

export default eccomerceReducer