import React, { useState } from 'react';
import styles from './HeroBannerV1.module.scss';
import defaultHeroBanner from '../defaultHeroBanner';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { BannerSlideType } from '../../../types/Data/BannerSlideType';
import { HeroBannerComponentProps } from '../HeroBannerComponentProps';

const HeroBannerV1: React.FC<HeroBannerComponentProps> = ({ slides = defaultHeroBanner.slides, layout = defaultHeroBanner.layout }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const hasMultipleSlides = slides && slides.length > 1;  
  const renderMedia = () => {
    if (slides && slides.length > 0) {
      return (
        <div className={styles.carousel}>
          {slides.map((slide: BannerSlideType, index: number) => (
            <div key={index} className={styles.carouselItem} style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
              {slide.videoUrl ? (
                <video className={styles.video} autoPlay loop muted>
                  <source src={slide.videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={slide.imageUrl} alt={slide.title} className={styles.image} />
              )}
              
            </div>
          ))}
        </div>
      );
    }
    return null;
  };
  
  return (
    <div className={`${styles.text_image_banner} ${styles[layout!]} ${styles.fade}`}>
       {hasMultipleSlides &&
        <button className={styles.button} onClick={() => setCurrentSlide((prev) => (prev > 0 ? prev - 1 : slides!.length - 1))}>
          <IoChevronBack/>
        </button>
      }
      {['left', 'top'].includes(layout!) && renderMedia()}
      <div className={styles.description}>
        <h3>{slides![currentSlide]?.title!}</h3>
        <p>{slides![currentSlide]?.text!}</p>
      </div>
      {['right', 'bottom'].includes(layout!) && renderMedia()}
      {hasMultipleSlides &&
        <button className={styles.button} onClick={() => setCurrentSlide((prev) => (prev < slides!.length - 1 ? prev + 1 : 0))}>
          <IoChevronForward/>
        </button>
      }
    </div>
  );
};

export default HeroBannerV1;
