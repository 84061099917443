import React from 'react'
import styles from '../Component.module.scss';
import CustomerSupportV1 from '../../UIElements/CustomerSupport/CustomerSupportV1/CustomerSupportV1';
import CustomerSupportV2 from '../../UIElements/CustomerSupport/CustomerSupportV2/CustomerSupportV2';
import CustomerSupportV3 from '../../UIElements/CustomerSupport/CustomerSupportV3/CustomerSupportV3';

const CustomerSupportComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <CustomerSupportV1/>
        </div>
        <div className={styles.element}>
            <CustomerSupportV2/>
        </div>
        <div className={styles.element}>
            <CustomerSupportV3/>
        </div>
    </div>
  )
}

export default CustomerSupportComponentPage