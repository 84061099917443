import React from 'react'
import styles from '../Component.module.scss';
import ButtonBuyV1 from '../../UIElements/Button/ButtonBuy/ButtonBuyV1/ButtonBuyV1';
import ButtonBuyV2 from '../../UIElements/Button/ButtonBuy/ButtonBuyV2/ButtonBuyV2';
import ButtonBuyV3 from '../../UIElements/Button/ButtonBuy/ButtonBuyV3/ButtonBuyV3';

const ButtonBuyComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <ButtonBuyV1/>
        </div>
        <div className={styles.element}>
            <ButtonBuyV2/>
        </div>
        <div className={styles.element}>
            <ButtonBuyV3/>
        </div>
    </div>
  )
}

export default ButtonBuyComponentPage