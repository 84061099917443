import { OrderItemType } from "../../types/Data/OrderType";
import { CartItemListComponentProps } from "./CartItemListComponentProps";

export const cartItemListdefaultProps:CartItemListComponentProps = {
    items: [
      {
        productId: '1',
        name: 'Default Product 1',
        unitPrice: 19.99,
        quantity: 1,
        imageUrl: 'https://via.placeholder.com/100',
        totalPrice: 19.99,
      },
      {
        productId: '2',
        name: 'Default Product 2',
        unitPrice: 29.99,
        quantity: 2,
        imageUrl: 'https://via.placeholder.com/100',
        totalPrice: 29.99 * 2,
      },
    ],
    onQuantityChange: (id: string, quantity: number) => console.log(`Quantity changed for ${id}: ${quantity}`),
    onRemove: (id: string) => console.log(`Remove item with id: ${id}`),
};