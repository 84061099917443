import React, { useState } from 'react';
import { IoCartOutline } from 'react-icons/io5';
import styles from './ButtonAddToCartV1.module.scss';
import { ButtonAddToCartComponentProps } from '../ButtonAddToCartComponentProps';

const ButtonAddToCartV1:React.FC<ButtonAddToCartComponentProps> = ({ onClick=()=>null }) => {
  const [added, setAdded] = useState(false);

  const handleClick = () => {
    if (onClick) {
        onClick();
    }
    setAdded(true);
    setTimeout(() => setAdded(false), 2000);
  };

  return (
    <button className={`${styles.buttonAddToCart} ${added ? styles.added : ''}`} onClick={handleClick}>
      <IoCartOutline className={styles.icon} />
      <p className={styles.text}>{added ? 'Added!' : 'Add to Cart'}</p>
    </button>
  );
};

export default ButtonAddToCartV1;
