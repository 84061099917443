import React from 'react'
import styles from '../Component.module.scss';
import CalendarV1 from '../../components/Calendar/CalendarV1/CalendarV1';
import CalendarV2 from '../../components/Calendar/CalendarV2/CalendarV2';

const CalendarComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <CalendarV1/>
        </div>
        <div className={styles.element}>
            <CalendarV2/>
        </div>
    </div>
  )
}

export default CalendarComponentPage