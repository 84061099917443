import { OrderStatus, OrderType } from "../../types/Data/OrderType";

export const customerOrderHistoryDefaultProps : Partial<OrderType> [] = [
    {
      _id: "12345",
      createdAt: new Date("2024-08-12T12:34:56Z"),
      orderSummary: {
        shippingCost: 10,
        discounts: 0,
        tax: 10,
        totalAmount: 105.5,
        subtotal: 105.5,
      },
      status: OrderStatus.DELIVERED,
      items: [
        { productId: "1", name: "Smartphone", quantity: 1, unitPrice: 100, totalPrice: 100 },
        { productId: "2", name: "Charger", quantity: 1, unitPrice: 50.5, totalPrice: 50.5 },
      ],
    },
    {
      _id: "67890",
      createdAt: new Date("2024-07-10T09:30:00Z"),
      orderSummary: {
        shippingCost: 10,
        discounts: 0,
        tax: 10,
        totalAmount: 75,
        subtotal: 75,
      },
      status: OrderStatus.SHIPPED,
      items: [{ productId: "3", name: "Laptop Case", quantity: 1, unitPrice: 75, totalPrice: 75 }],
    },
];
  