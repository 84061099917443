import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import exampleDiscountCodes from './exampleDiscountCodes';
import { DiscountCodeType } from '../../../types/Data/DiscountCodeType';
import { getAllDiscountCodes } from '../../../api/discountCode/discountCodeApi';

// Fetch all discount codes
export const fetchAllDiscountCodes = createAsyncThunk(
    'discountCodes/fetchAll',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getAllDiscountCodes({});
            return response.data.discountCodes
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to load discount codes');
        }
    }
);

interface DiscountCodesState {
    discountCodes: DiscountCodeType[];
    loading: boolean;
    error: string | null;
}

const initialState: DiscountCodesState = {
    discountCodes: exampleDiscountCodes,
    loading: false,
    error: null,
};


const discountCodesSlice = createSlice({
    name: 'discountCodes',
    initialState,
    reducers: {
        dispatchAddDiscountCode(state, action: PayloadAction<DiscountCodeType>) {
            state.discountCodes.push(action.payload);
        },
        dispatchUpdateDiscountCode(state, action: PayloadAction<DiscountCodeType>) {
            const index = state.discountCodes.findIndex(discountCode => discountCode._id === action.payload._id);
            if (index !== -1) {
                state.discountCodes[index] = action.payload;
            }
        },
       dispatchDeleteDiscountCode(state, action: PayloadAction<string>) {
            state.discountCodes = state.discountCodes.filter(discountCode => discountCode._id !== action.payload);
        },
        dispatchLoadDiscountCodes(state, action: PayloadAction<DiscountCodeType[]>) {
            state.discountCodes = action.payload;
        },
        dispatchToggleDiscountCodeStatus(state, action: PayloadAction<string>) {
            const index = state.discountCodes.findIndex(discountCode => discountCode._id === action.payload);
            if (index !== -1) {
                state.discountCodes[index].isActive = !state.discountCodes[index].isActive;
            }
        }
    },
    extraReducers: (builder) => {
        // Fetch all discount codes
        builder.addCase(fetchAllDiscountCodes.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchAllDiscountCodes.fulfilled, (state, action: PayloadAction<DiscountCodeType[]>) => {
            state.discountCodes = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchAllDiscountCodes.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
    }
});

export const { dispatchAddDiscountCode, dispatchUpdateDiscountCode, dispatchLoadDiscountCodes, dispatchDeleteDiscountCode, dispatchToggleDiscountCodeStatus } = discountCodesSlice.actions;
export default discountCodesSlice.reducer;
