import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IoRadioButtonOff, IoRadioButtonOn } from 'react-icons/io5';
import styles from './CategoryPickerV2.module.scss';
import { RootState } from '../../../Store/store';
import { CategoryType } from '../../../types/Data/CategoryType';

interface ProductPickerProps {
  selectedProductsId?: string[]; // Array of selected product IDs
  onSelectCategory?: (productId: string) => void;
}

const CategoryPickerV2: React.FC<ProductPickerProps> = ({ selectedProductsId, onSelectCategory=()=>null }) => {
  const categories = useSelector((state: RootState) => state.dashboard.categories.categories) as CategoryType[];
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredCategories = categories.filter((cat) => 
    cat.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    cat?.description?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={styles.categoryPickerComponent}>
      <input 
        type="text"
        placeholder="Search category..."
        value={searchTerm}
        onChange={handleSearch}
        className={styles.searchBar}
      />
      <div className={styles.categoryList}>
        {filteredCategories.map((category) => (
          <div key={category._id!} className={styles.categoryCard} onClick={() => onSelectCategory(category._id!)}>
            <img src={category?.image?.url} alt={category.name} className={styles.categoryImage} />
            <div className={styles.categoryInfo}>
              <h3>{category.name}</h3>
              <p>{category.description}</p>
            </div>
            {selectedProductsId?.includes(category._id!) ? (
              <IoRadioButtonOn className={styles.selectedIcon} />
            ) : (
              <IoRadioButtonOff className={styles.unselectedIcon} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryPickerV2;
