import React from 'react';
import styles from './OrderStatusHistory.module.scss';

interface StatusHistory {
    status: string;
    changedAt: Date;
}

interface OrderStatusHistoryProps {
    statusHistory: StatusHistory[];
}

const OrderStatusHistory: React.FC<OrderStatusHistoryProps> = ({ statusHistory }) => {
    return (
        <div className={styles.statusHistory}>
            <h2>Order Status History</h2>
            <div className={styles.timeline}>
                {statusHistory.map((history, index) => (
                    <div key={index} className={styles.timelineItem}>
                        <div className={styles.statusCircle} />
                        <div className={styles.timelineContent}>
                            <span className={styles.status}>{history.status}</span>
                            <span className={styles.date}>
                                {new Date(history.changedAt).toLocaleDateString()}{' '}
                                {new Date(history.changedAt).toLocaleTimeString()}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OrderStatusHistory;
