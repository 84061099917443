import { PrivatePolicyType } from "../../../types/Data/PrivatePolicyType"

const examplePrivatePolicy: PrivatePolicyType = {
    content: `<div>
        <h1>Privacy Policy</h1>
        <p>Effective Date: January 1, 2023</p>

        <h2>1. Introduction</h2>
        <p>We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you visit our website.</p>

        <h2>2. Information We Collect</h2>
        <ul>
            <li><strong>Personal Information:</strong> When you register on our site, we may collect personal details such as your name, email address, and phone number.</li>
            <li><strong>Usage Data:</strong> We collect information about your interactions with our website, such as pages visited, time spent on each page, and the resources you access.</li>
        </ul>

        <h2>3. How We Use Your Information</h2>
        <p>Your information helps us provide and improve our services. We may use your data to:</p>
        <ul>
            <li>Provide, operate, and maintain our website</li>
            <li>Improve, personalize, and expand our services</li>
            <li>Understand and analyze how you use our website</li>
            <li>Develop new products, services, and features</li>
            <li>Send you emails and updates related to our services</li>
        </ul>

        <h2>4. Sharing Your Information</h2>
        <p>We do not share your personal information with third parties without your consent, except:</p>
        <ul>
            <li>To comply with applicable laws or respond to legal processes</li>
            <li>To protect the rights and safety of our users and company</li>
            <li>With service providers who help us operate our website</li>
        </ul>

        <h2>5. Security of Your Information</h2>
        <p>We implement industry-standard security measures to protect your information. However, no method of transmission over the internet is entirely secure. We strive to use commercially acceptable means to protect your data, but we cannot guarantee absolute security.</p>

        <h2>6. Changes to This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this policy periodically for any updates.</p>

        <h2>7. Contact Us</h2>
        <p>If you have questions about this Privacy Policy, please contact us at:</p>
        <p><strong>Email:</strong> privacy@example.com</p>
        <p><strong>Phone:</strong> +1 (555) 123-4567</p>
        </div>`
}

export { examplePrivatePolicy }
