import axios, { AxiosResponse } from 'axios';
import { ProductType } from '../../types/Data/ProductType';
import { ApiResponse } from '../apiType';
import api from '../config';

interface GetProductsParams {
  search?: string;
  category?: string;
  sortBy?: string;
  order?: 'asc' | 'desc';
  page?: number;
  limit?: number;
};

// Fetch all products with optional query parameters
export const getAllProducts = async (
  params: GetProductsParams = {}
): Promise<AxiosResponse<ApiResponse<{ products: Partial<ProductType>[]; total: number; page: number; pages: number }>>> => {
  try {
    const response = await api.get<ApiResponse<{ products: Partial<ProductType>[]; total: number; page: number; pages: number }>>('/products', { params });
    return response;
  } catch (error) {
    console.error('Error fetching all products:', error);
    throw new Error('Failed to fetch products');
  }
};

// Fetch a product by ID
export const getProductById = async (id: string): Promise<AxiosResponse<ApiResponse<ProductType>>> => {
  try {
    const response = await api.get<ApiResponse<ProductType>>(`/products/${id}`);
    return response;
  } catch (error) {
    console.error(`Error fetching product with ID ${id}:`, error);
    throw new Error('Failed to fetch product');
  }
};

// Add a new product
export const addProduct = async (data: ProductType): Promise<AxiosResponse<ApiResponse<ProductType>>> => {
  try {
    const response = await api.post<ApiResponse<ProductType>>('/products', data);
    return response;
  } catch (error) {
    console.error('Error adding product:', error);
    throw new Error('Failed to add product');
  }
};

// Edit a product
export const editProduct = async (id: string, data: Partial<ProductType>): Promise<AxiosResponse<ApiResponse<ProductType>>> => {
  try {
    const response = await api.put<ApiResponse<ProductType>>(`/products/${id}`, data);
    return response;
  } catch (error) {
    console.error(`Error editing product with ID ${id}:`, error);
    throw new Error('Failed to edit product');
  }
};

// Delete a product
export const deleteProduct = async (id: string): Promise<AxiosResponse<ApiResponse<null>>> => {
  try {
    const response = await api.delete<ApiResponse<null>>(`/products/${id}`);
    return response;
  } catch (error) {
    console.error(`Error deleting product with ID ${id}:`, error);
    throw new Error('Failed to delete product');
  }
};

// Remove a product picture
export const removeProductPicture = async (id: string): Promise<AxiosResponse<ApiResponse<null>>> => {
  try {
    const response = await api.delete<ApiResponse<null>>(`/products/${id}/pictures`);
    return response;
  } catch (error) {
    console.error(`Error removing picture for product with ID ${id}:`, error);
    throw new Error('Failed to remove product picture');
  }
};

// Get products by user (admin)
export const getProductsByUser = async (userId: string): Promise<AxiosResponse<ApiResponse<ProductType[]>>> => {
  try {
    const response = await api.get<ApiResponse<ProductType[]>>(`/api/admin/products/user/${userId}`);
    return response;
  } catch (error) {
    console.error(`Error fetching products for user with ID ${userId}:`, error);
    throw new Error('Failed to fetch products for user');
  }
};

// Check if a product exists
export const productExists = async (id: string): Promise<AxiosResponse<ApiResponse<boolean>>> => {
  try {
    const response = await api.get<ApiResponse<boolean>>(`/admin/products/exists/${id}`);
    return response;
  } catch (error) {
    console.error(`Error checking existence of product with ID ${id}:`, error);
    throw new Error('Failed to check if product exists');
  }
};
