import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import styles from './SubscriberForm.module.scss';
import { addSubscriber, updateSubscriber } from '../../DashboardState/subscribers/subscribersSlice';
import { SubscriberType } from '../../../types/Data/SubscriberType';
import Input4 from '../../../components/Input/InputText/InputV4/InputV4';
import Button1 from '../../../components/Button/ButtonV1/ButtonV1';

interface SubscriberFormProps {
  initialSubscriber?: SubscriberType | null;
  onClose: () => void;
}

const SubscriberForm: React.FC<SubscriberFormProps> = ({ initialSubscriber, onClose }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState<string>(initialSubscriber?.name || '');
  const [email, setEmail] = useState<string>(initialSubscriber?.email || '');

  useEffect(() => {
    if (initialSubscriber) {
      setName(initialSubscriber.name);
      setEmail(initialSubscriber.email);
    }
  }, [initialSubscriber]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const dateSubscribed = new Date().toISOString().split('T')[0];
    const newSubscriber: SubscriberType = {
      id: initialSubscriber ? initialSubscriber.id : new Date().getTime().toString(),
      name,
      email,
      dateSubscribed: initialSubscriber ? initialSubscriber.dateSubscribed : dateSubscribed,
    };
    if (initialSubscriber) {
      dispatch(updateSubscriber(newSubscriber));
    } else {
      dispatch(addSubscriber(newSubscriber));
    }
    onClose();
  };

  return (
    <form className={styles.subscriber_form} onSubmit={handleSubmit}>
        <Input4
         type="text"
         id="name"
         value={name}
         onChange={(e) => setName(e.target.value)}
         required
        />
        <Input4
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Button1
        style={{marginTop: 20}}
        title={initialSubscriber ? 'Update Subscriber' : 'Add Subscriber'}
        type="submit" />
    </form>
  );
};

export default SubscriberForm;
