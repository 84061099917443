import React, { useState } from 'react';
import styles from './ProductInfoCardV2.module.scss';
import { ProductInfoCardComponentProps } from '../ProductInfoCardComponentProps';
import { defaultProductInfoCardProps } from '../defaultProductInfoCardProps';


const ProductInfoCardV2:React.FC<ProductInfoCardComponentProps> = ({ product = defaultProductInfoCardProps })=> {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={styles.card}>
      <div className={styles.header} onClick={() => setIsExpanded(!isExpanded)}>
        <h2>{product.name}</h2>
        <p className={styles.price}>${product.price}</p>
        <span className={styles.toggleButton}>{isExpanded ? '-' : '+'}</span>
      </div>
      {isExpanded && (
        <div className={styles.content}>
          <p>{product.description}</p>
          <ul className={styles.features}>
            {product?.attributes!.map((attribute, index) => (
              <li key={index}>{attribute.value}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProductInfoCardV2;
