import React, { useEffect, useState } from 'react';
import { IoRemove } from 'react-icons/io5';
import styles from '../../UIElementsMainSettings/UiElementEditor.module.scss';
import Input4 from '../../../components/Input/InputText/InputV4/InputV4';
import { BaseElementType, ComponentType } from '../../../types/Data/BaseElementType';
import ButtonV3 from '../../../components/Button/ButtonV3/ButtonV3';
import ButtonV1 from '../../../components/Button/ButtonV1/ButtonV1';
import { logoCloudDefaultProps } from '../logoCloudDefaultProps';
import { LogoCloudComponentProps, LogoCloudMapping, LogoCloudVersions } from '../LogoCloudComponentProps';
import UIElementsMainSettings from '../../UIElementsMainSettings/UIElementsMainSettings';
import ButtonDelete from '../../../components/Button/ButtonDelete/ButtonDelete';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../Store/store';
import { fetchCreateElement, fetchUpdateElement } from '../../../Dashboard/DashboardState/element/elementSlice';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';


interface LogoEditorProps {
  element?: BaseElementType<LogoCloudComponentProps>;
}

const LogoCloudComponentEditor: React.FC<LogoEditorProps> = ({ element }) => {
  
  const defaultElement: BaseElementType<LogoCloudComponentProps> = {
    type: ComponentType.LogoCloud,
    version: 'v1',
    page: 'home',
    props: logoCloudDefaultProps,
    position: undefined,
    isVisible: true,
  };

  const newLogo = '';

  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state:RootState)=>state.dashboard.elements.loading);
  const error = useSelector((state:RootState)=>state.dashboard.elements.error);
  
  const [currentElement, setCurrentElement] = useState<BaseElementType<LogoCloudComponentProps>>(element ? element : defaultElement);

  useEffect(() => {
    if (element) {
      setCurrentElement(element);
    } else {
      setCurrentElement(defaultElement);
    }
  }, [element]);

  const handleAddItem = () => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      props: {
        ...prevElement.props,
        logos: [...(prevElement?.props?.logos ?? []), newLogo],
      },
    }));
  };

  const handleRemoveItem = (itemIndex: number) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      props: {
        ...prevElement.props,
        items: (prevElement?.props?.logos ?? []).filter((_, index: number) => index !== itemIndex),
      },
    }));
  };

  const handleItemChange = (itemIndex: number, value: any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      props: {
        ...prevElement.props,
        items: (prevElement?.props?.logos ?? []).map((item, index) =>
          index === itemIndex ?  value  : item
        ),
      },
    }));
  };

  const handleElementUpdate = (key: string, value: any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      [key]: value,
    }));
  };

  const handleElementVisibilityUpdate = () => {
    setCurrentElement((prevElement) => ({ ...prevElement, isVisible: !prevElement.isVisible }));
  };

  const handlePropsChange = (key: string, value: any) => {
    setCurrentElement((prevElement) => ({
        ...prevElement,
        props: {
          ...prevElement.props,
          [key]: value
        },
    }));
  };

  const handleSaveElement = () => {
    if (currentElement?._id) {
      dispatch(fetchUpdateElement({id:currentElement._id, element:currentElement}))
    } else {
      dispatch(fetchCreateElement(currentElement))
    }
  };

  const SelectedComponent = LogoCloudMapping[currentElement.version as LogoCloudVersions] || LogoCloudMapping[LogoCloudVersions.V1];

  const elementsVersion = Object.keys(LogoCloudVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: LogoCloudVersions[key as keyof typeof LogoCloudVersions],
  }));

  return (
    <div className={styles.container}>
      { error && <ErrorBannerV1 message={error}/>}
      { loading && <LoadingIndicatorV1/>}
      <div className={styles.componentPreview}>
        <SelectedComponent {...currentElement.props} />
      </div>
      <div className={styles.componentEditor}>
        <UIElementsMainSettings
            element={currentElement}
            elementVersions={elementsVersion}
            onElementUpdate={handleElementUpdate}
            onVisibilitytUpdate={handleElementVisibilityUpdate}
        />
        <div className={styles.mainSettings}>
            <Input4
                label="Title"
                value={currentElement.props?.title}
                onChange={(e) => handlePropsChange('title', e.target.value)}
              />
          </div>
            {/* Items Section */}
            <div className={styles.slides}>
            {currentElement?.props?.logos?.map((item, index) => (
                <div key={index} className={styles.slide}>
                <div className={styles.slideHeader}>
                    <h4>Item {index + 1}</h4>
                    <div className={styles.buttonRemove} onClick={() => handleRemoveItem(index)}>
                    <IoRemove className={styles.removeButton} />
                    </div>
                </div>
                <Input4
                    label="Logo URL"
                    value={item}
                    onChange={(e) => handleItemChange(index, e.target.value)}
                />
                </div>
            ))}
            <ButtonV3 title="Add Item" onClick={handleAddItem} />
            </div>
        </div>
      <div className={styles.buttonContainer}>
        <ButtonV1 label="Save Element" onClick={handleSaveElement} />
        <ButtonDelete/>
      </div>
    </div>
  );
};

export default LogoCloudComponentEditor;
