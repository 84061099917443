import React from 'react';
import styles from './TicketList.module.scss';
import { FaPaperclip, FaUser, FaCalendarAlt, FaExclamationCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Store/store';
import { SupportTicketType } from '../../../../types/Data/SupportTicketType';
import { useNavigate } from 'react-router-dom';

interface SupportTicketTableProps {
    
}

const SupportTicketList: React.FC<SupportTicketTableProps> = ({  }) => {
    const navigate = useNavigate();
    const tickets = useSelector((state:RootState) => state.dashboard.tickets.tickets) as SupportTicketType[];

    const handleViewTicket = (id: string) => {
        navigate(`/dashboard/support/tickets/${id}`);
    };

    return (
        <div className={styles.ticketTableContainer}>
            <table className={styles.ticketTable}>
                <thead>
                    <tr>
                        <th>Subject</th>
                        <th>Status</th>
                        <th>Priority</th>
                        <th>Category</th>
                        <th>Created At</th>
                        <th>Assigned To</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {tickets.map(ticket => (
                        <tr key={ticket._id}>
                            <td>
                                {ticket.subject}
                                {ticket.attachments && ticket.attachments.length > 0 && (
                                    <FaPaperclip className={styles.attachmentIcon} />
                                )}
                            </td>
                            <td className={`${styles.status} ${styles[ticket.status.toLowerCase()]}`}>
                                {ticket.status}
                            </td>
                            <td className={`${styles.priority} ${styles[ticket.priority ? ticket.priority?.toLowerCase() : '']}`}>
                                {ticket.priority}
                            </td>
                            <td>{ticket.category}</td>
                            <td>{ticket.createdAt ? new Date(ticket.createdAt).toLocaleDateString() : 'N/A'}</td>
                            <td>{ticket.assignedTo ? ticket.assignedTo : <FaUser className={styles.unassignedIcon} />}</td>
                            <td>
                                <button className={styles.viewButton} onClick={() => handleViewTicket(ticket._id!)}>
                                    View Details
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SupportTicketList;
