import React from 'react';
import styles from './ProductListV3.module.scss';
import { ProductListComponentProps } from '../ProductListComponentProps';
import exampleProducts from '../../../Dashboard/DashboardState/products/dataProducts';
import { ProductCardListMapping, ProductCardListVersions } from '../../ProductCardList/ProductCardListComponentProps';


const ProductListV3: React.FC<ProductListComponentProps> = ({ products = exampleProducts, cardVersion=ProductCardListVersions.V1, onProductClick = (id: string) => null  }) => {
  const CardComponent = ProductCardListMapping[cardVersion];

  return (
    <div className={styles.masonryContainer}>
      {products.map((product) => (
        <CardComponent product={product} onProductClick={onProductClick}/>
      ))}
    </div>
  );
};

export default ProductListV3;
