import { AlertComponentProps } from "../Type/AlertType";

const alertDefaultProps:AlertComponentProps = {
    title: "Alert",
    message: "This is an alert",
    onClose: () => null,
};

export {
    alertDefaultProps  
}