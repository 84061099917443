import React from 'react';
import { EmailTemplateType } from '../../../../types/Data/EmailTemplateType';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Store/store';
import { OrderType } from '../../../../types/Data/OrderType';

const styles: { [key: string]: React.CSSProperties } = {
    emailSkeleton: {
        width: '100%',
        margin: '0 auto',
        border: '1px solid #ddd',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
        color: '#333',
        backgroundColor: '#f9f9f9',
        animation: 'fadeIn 0.5s ease-in-out'
    },
    emailHeader: {
        backgroundColor: '#2ecc71',
        color: '#fff',
        padding: '20px',
        textAlign: 'center'
    },
    emailHeaderH1: {
        margin: 0,
        fontSize: '24px',
        animation: 'slideIn 0.5s ease-in-out'
    },
    emailBody: {
        padding: '20px'
    },
    emailBodyP: {
        lineHeight: '1.5',
        fontSize: '16px',
        animation: 'fadeIn 0.5s ease-in-out'
    },
    orderInfo: {
        marginTop: '10px',
        padding: '10px',
        backgroundColor: '#ecf0f1',
        borderRadius: '5px',
        animation: 'fadeIn 0.5s ease-in-out'
    },
    orderInfoP: {
        margin: '5px 0'
    },
    emailBodyH2: {
        marginTop: '20px',
        fontSize: '20px',
        borderBottom: '2px solid #ddd',
        paddingBottom: '10px',
        marginBottom: '20px',
        animation: 'fadeIn 0.5s ease-in-out'
    },
    orderItems: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    orderItem: {
        display: 'flex',
        gap: '20px',
        background: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '10px',
        transition: 'transform 0.3s ease, boxShadow 0.3s ease',
        animation: 'fadeIn 0.5s ease-in-out',
        cursor: 'pointer'
    },
    orderItemHover: {
        transform: 'translateY(-5px)',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)'
    },
    orderItemImg: {
        width: '100px',
        borderRadius: '5px'
    },
    itemDetails: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    itemDetailsH3: {
        margin: '0 0 10px',
        fontSize: '16px',
        color: '#333'
    },
    itemDetailsP: {
        margin: '5px 0',
        fontSize: '14px',
        color: '#666'
    },
    emailFooter: {
        backgroundColor: '#f1f1f1',
        padding: '20px',
        textAlign: 'center',
        animation: 'fadeIn 0.5s ease-in-out'
    },
    emailFooterP: {
        margin: '5px 0',
        fontSize: '14px',
        color: '#666'
    },
};

const OrderConfirmationEmailSkeleton: React.FC<EmailTemplateType> = ({ data }) => {
    
    const order = useSelector<RootState>((state)=>state.dashboard.orders.orders.find((order)=>order._id === data?.orderId)) as Partial<OrderType>

    return (
        <div style={styles.emailSkeleton}>
            <style>
            {`@keyframes fadeIn: {
                from: {
                    opacity: 0,
                    transform: 'translateY(-10px)'
                },
                to: {
                    opacity: 1,
                    transform: 'translateY(0)'
                }
            },
            @keyframes slideIn: {
                from: {
                    opacity: 0,
                    transform: 'translateX(-100%)'
                },
                to: {
                    opacity: 1,
                    transform: 'translateX(0)'
                }
            }`}
            </style>
            <header style={styles.emailHeader}>
                <h1 style={styles.emailHeaderH1}>{data?.title}</h1>
            </header>
            <main style={styles.emailBody}>
                <p style={styles.emailBodyP}>Thank you for your order! Here are your order details:</p>
                <div style={styles.orderInfo}>
                    <p style={styles.orderInfoP}><strong>Order Number:</strong> {order?._id}</p>
                    <p style={styles.orderInfoP}><strong>Order Date:</strong> {order?.timestamps?.orderDate.toDateString()}</p>
                    <p style={styles.orderInfoP}><strong>Total Amount:</strong> ${order?.orderSummary?.totalAmount?.toFixed(2)}</p>
                </div>
                <h2 style={styles.emailBodyH2}>Order Items</h2>
                <div style={styles.orderItems}>
                    { order && order?.items!.map(item => (
                        <div key={item?.productId} style={styles.orderItem}>
                            <img src={item?.imageUrl} alt={item?.name} style={styles.orderItemImg} />
                            <div style={styles.itemDetails}>
                                <h3 style={styles.itemDetailsH3}>{item?.name}</h3>
                                <p style={styles.itemDetailsP}>Quantity: {item?.quantity}</p>
                                <p style={styles.itemDetailsP}>Price: ${item?.totalPrice.toFixed(2)}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </main>
            <footer style={styles.emailFooter}>
                <p style={styles.emailFooterP}>Thank you for shopping with us!</p>
                <p style={styles.emailFooterP}>Unsubscribe | Contact Us</p>
            </footer>
        </div>
    );
};

export default OrderConfirmationEmailSkeleton;
