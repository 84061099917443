import React, { useState } from 'react';
import styles from './SizeSelectorV1.module.scss';
import { defaultSizeSelectorProps } from '../defaultSizeSelectorProps';
import { SizeSelectorComponentProps } from '../SizeSelectorComponentProps';

const SizeSelectorV1: React.FC<SizeSelectorComponentProps> = ({ sizes = defaultSizeSelectorProps.sizes }) => {
  const [selectedSize, setSelectedSize] = useState(sizes![0]);
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className={styles.selectorContainer}>
      <h3>Select Size</h3>
      <div className={styles.sizeDropdown} onClick={toggleDropdown}>
        <p className={styles.selectedSize}>{selectedSize}</p>
        <div className={styles.dropdownIcon}>&#9662;</div>
      </div>
      {showDropdown && (
        <div className={styles.dropdownList}>
          {sizes?.map((size) => (
            <div key={size} className={styles.dropdownItem} onClick={() => { setSelectedSize(size); setShowDropdown(false); }}>
              {size}
            </div>
          ))}
        </div>
      )}
      <p className={styles.sizeGuide}>View Size Guide</p>
    </div>
  );
};

export default SizeSelectorV1;
