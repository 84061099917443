import React, { ButtonHTMLAttributes, ReactElement } from 'react'
import styles from './ButtonCancel.module.scss';

interface buttonProps extends ButtonHTMLAttributes <HTMLButtonElement> {
    title?:string;
    style?:object;
    icon?: ReactElement;
}

const ButtonCancel:React.FC<buttonProps> = ({title = "Cancel", style={}, icon, ...props}) => {
  return (
    <button style={{...style}} id={styles.buttonCancel} {...props}>
        <div className={styles.content}>
          {icon && <div className={styles.icon}>{icon}</div>}
          {title}
        </div>
    </button>
  )
}

export default ButtonCancel