import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { CartType, initializeCart } from '../../../types/Data/CartType';
import { OrderItemType, Shipping } from '../../../types/Data/OrderType';

interface CartState {
    cart: CartType;
    loading: boolean;
    error: string | null;
}

// Initialize the state with a default cart
const initialState: CartState = {
    cart: initializeCart(new Date().toISOString()),
    loading: false,
    error: null,
};

// Cart slice
const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addItem(state, action: PayloadAction<OrderItemType>) {
            const existingItem = state.cart.items.find(item => item.productId === action.payload.productId);

            if (existingItem) {
                // Increase quantity if item already in cart
                existingItem.quantity += action.payload.quantity;
                existingItem.totalPrice = existingItem.quantity * existingItem.unitPrice;
            } else {
                // Add new item
                state.cart.items.push({ ...action.payload });
            }
            updateCartSummary(state);
        },
        updateItemQuantity(state, action: PayloadAction<{ productId: string; quantity: number }>) {
            const item = state.cart.items.find(item => item.productId === action.payload.productId);
            if (item && action.payload.quantity > 0) {
                item.quantity = action.payload.quantity;
                item.totalPrice = item.unitPrice * action.payload.quantity;
                updateCartSummary(state);
            }
        },
        removeItem(state, action: PayloadAction<string>) {
            state.cart.items = state.cart.items.filter(item => item.productId !== action.payload);
            updateCartSummary(state);
        },
        applyDiscount(state, action: PayloadAction<number>) {
            state.cart.cartSummary.discounts = action.payload;
            updateCartSummary(state);
        },
        setShipping(state, action: PayloadAction<Shipping>) {
            state.cart.shipping = action.payload;
            updateCartSummary(state);
        },
        clearCart(state) {
            state.cart = initializeCart(new Date().toISOString());
        },
    },
    extraReducers: (builder) => {
        // Add async cases here if you need to fetch cart data from a backend
    },
});

// Update the order summary based on items in the cart
function updateCartSummary(state: CartState) {
    const subtotal = state.cart.items.reduce((sum, item) => sum + item.totalPrice, 0);
    state.cart.cartSummary.subtotal = subtotal;
    state.cart.cartSummary.totalAmount = subtotal + (state.cart.cartSummary.shippingCost || 0) - (state.cart.cartSummary.discounts || 0);
}

// Export actions and reducer
export const { addItem, updateItemQuantity, removeItem, applyDiscount, setShipping, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
