import { CustomerType } from "./CustomerType";
import { OrderType } from "./OrderType";
import { ProductType } from "./ProductType";
import { ReturnRequestType } from "./ReturnRequestType";

// Enum for Status
enum RefundStatus {
    Pending = 'Pending',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Refunded = 'Refunded',
    PartiallyRefunded = 'PartiallyRefunded', // Adjusted to remove space for consistency
}

// Enum for Payment Method
enum RefundPaymentMethod {
    CreditCard = 'Credit Card',
    PayPal = 'PayPal',
    BankTransfer = 'Bank Transfer',
    Stripe = 'Stripe',
    Other = 'Other',
}

// Enum for Item Condition
enum RefundItemCondition {
    NotReturned = 'Not Returned',
    New = 'New',
    LikeNew = 'Like New',
    Used = 'Used',
    Damaged = 'Damaged',
    Defective = 'Defective',
}

interface RefundReturnedProduct {
    product: string | Partial<ProductType>;
    productName: string;
    quantity: number;
    condition: RefundItemCondition; // Use enum instead of string literals
    price: number;
    refundAmount: number; // Amount you choose to refund for this item
}

interface PaymentInfoType {
    paymentMethod: string;
    paymentProvider?: string; // e.g., 'Stripe', 'PayPal'
    paymentStatus: 'Pending' | 'Completed' | 'Failed';
    transactionId: string;
    paymentFees?: number; // New: Fees associated with the payment method
}

// Interface for the refund process
interface RefundProcessType {
    _id?: string; // Unique identifier for the refund process
    customer: string | Partial<CustomerType>; // Identifier for the customer
    customerName: string;
    order:string | Partial<OrderType>; // The order from which the return was made
    returnRequest: string | Partial<ReturnRequestType>;
    returnedProducts: RefundReturnedProduct[]; // List of returned items
    totalRefundAmount: number; // Total amount to refund
    refundStatus: RefundStatus; // Status of the refund process
    refundDate?: Date; // Date when the refund was processed
    paymentInfo?: PaymentInfoType; // Payment details for the refund
    notes?: string; // Any additional notes regarding the refund
    createdAt?: Date;
    updatedAt?: Date;
}

export type {
    RefundProcessType,
    PaymentInfoType,
    RefundReturnedProduct,
};

export {
    RefundPaymentMethod,
    RefundStatus,
    RefundItemCondition,
};
