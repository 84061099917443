import React from 'react';
import { EmailTemplateType } from '../../../../types/Data/EmailTemplateType';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Store/store';
import { OrderType } from '../../../../types/Data/OrderType';
import { CustomerType } from '../../../../types/Data/CustomerType';

const OrderDeliveredEmail:React.FC<EmailTemplateType> = ({ data }) => {
    const styles:{ [key: string]: React.CSSProperties } = {
        emailContainer: {
            maxWidth: '100%',
            margin: '20px auto',
            padding: '20px',
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            animation: 'fadeIn 1s ease-in-out'
        },
        header: {
            backgroundColor: '#1a1a2e',
            color: '#fff',
            padding: '20px',
            textAlign: 'center',
            borderRadius: '10px 10px 0 0'
        },
        headerH1: {
            margin: '0',
            fontSize: '1.8em'
        },
        body: {
            padding: '20px'
        },
        bodyH2: {
            color: '#4bc0c0'
        },
        bodyP: {
            color: '#333',
            lineHeight: '1.5'
        },
        bodyA: {
            color: '#1a1a2e',
            textDecoration: 'none',
            fontWeight: 'bold'
        },
        bodyAHover: {
            textDecoration: 'underline'
        },
        ul: {
            listStyle: 'none',
            padding: '0'
        },
        li: {
            backgroundColor: '#f4f4f4',
            padding: '10px',
            margin: '10px 0',
            borderRadius: '5px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
        },
        footer: {
            backgroundColor: '#f4f4f4',
            color: '#333',
            textAlign: 'center',
            padding: '20px',
            borderRadius: '0 0 10px 10px'
        },
        footerP: {
            margin: '0',
            fontSize: '0.9em'
        },
    };

    const order = useSelector<RootState>((state)=>state.dashboard.orders.orders.find((order)=>order._id === data?.orderId)) as Partial<OrderType>
    const customer = useSelector<RootState>((state)=>state.dashboard.customers.customers.find((customer)=>customer._id === data?.customerId)) as Partial<CustomerType>

    return ( 
        <div style={styles.emailContainer}>
            <style>
            {`@keyframes fadeIn {
                from {
                    opacity: 0;
                    transform: translateY(-10px);
                }
                to {
                    opacity: 1;
                    transform: translateY(0);
                }
            }`}
            </style>
            <header style={styles.header}>
                <h1 style={styles.headerH1}>Order Delivered</h1>
            </header>
            <section style={styles.body}>
                <h2 style={styles.bodyH2}>Hi {customer?.name},</h2>
                <p style={styles.bodyP}>
                    Your order #{order?._id} was delivered on {order?.shipping?.estimatedDeliveryDate?.toDateString()}.
                </p>
                <p style={styles.bodyP}>
                    Tracking Number: <strong>{order?.shipping?.trackingNumber}</strong>
                </p>
                <p style={styles.bodyP}>
                    Track your shipment <a href={order?.shipping?.trackingNumber} style={styles.bodyA}>here</a>.
                </p>
                <h3>Order Summary</h3>
                <ul style={styles.ul}>
                    {order?.items!.map((item, index) => (
                        <li key={index} style={styles.li}>
                            {item?.quantity}x {item?.name} - ${item?.unitPrice.toFixed(2)}
                        </li>
                    ))}
                </ul>
                <p style={styles.bodyP}>
                    We hope you enjoy your purchase! If you have any questions or concerns, please contact our support team.
                </p>
            </section>
            <footer style={styles.footer}>
                <p style={styles.footerP}>Thank you for shopping with us!</p>
            </footer>
        </div>
    );
};

export default OrderDeliveredEmail;
