import React, { useState } from "react";
import styles from "./TestimonialV2.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/store";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { TestimonialType } from "../../../types/Data/TestimonialType";


const TestimonialV2: React.FC = () => {
  const testimonials = useSelector<RootState>((state)=>state.dashboard.testimonials.testimonials) as TestimonialType[];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  return (
    <div className={styles.testimonialV2}>
      <div className={styles.testimonialCarousel} style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className={styles.testimonialCard}>
            <img src={testimonial.avatar} alt={testimonial.name} className={styles.avatar} />
            <div className={styles.name}>{testimonial.name}</div>
            <div className={styles.role}>{testimonial.role}</div>
            <div className={styles.text}>{testimonial.text}</div>
          </div>
        ))}
      </div>
      <div className={styles.carouselControls}>
        <button className={styles.controlButton} onClick={handlePrev}>
          <IoChevronBack/>
        </button>
        <button className={styles.controlButton} onClick={handleNext}>
            <IoChevronForward/>
        </button>
      </div>
    </div>
  );
};

export default TestimonialV2;
