// src/components/SalesByCategoryPieChart.tsx
import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartData, ChartOptions } from 'chart.js';
import styles from './ChartSalesByCategories.module.scss'; // Import the SCSS file for styling

const ChartSalesByCategories: React.FC = () => {
  const data: ChartData<'pie'> = {
    labels: ['Electronics', 'Fashion', 'Home & Garden', 'Health & Beauty', 'Sports'],
    datasets: [
      {
        label: 'Sales by Category',
        data: [300, 500, 200, 400, 100],
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)',
          'rgba(153, 102, 255, 0.6)',
          'rgba(255, 159, 64, 0.6)',
          'rgba(255, 205, 86, 0.6)',
          'rgba(54, 162, 235, 0.6)',
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(255, 205, 86, 1)',
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1,
        hoverBackgroundColor: [
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(54, 162, 235, 0.8)',
        ],
      },
    ],
  };

  const options: ChartOptions<'pie'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          color: '#ffffff', // Legend text color
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || '';
            if (label) {
              label += ': ';
            }
            if (context.raw !== null) {
              label += new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(context.raw as number);
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <div className={styles.container}>
      <h3>Sales by Categories</h3>
      <div className={styles.chart_container}>
        <Pie data={data} options={options} />
      </div>
    </div>
    
  );
};

export default ChartSalesByCategories;
