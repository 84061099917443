import React, { useState } from "react";
import styles from "./ImageUploadComponent.module.scss";

interface ImageUploadComponentProps {
  onUpload?: (files: File[]) => void;
  onCancel?: () => void;
}

const ImageUploadComponent: React.FC<ImageUploadComponentProps> = ({ onUpload=()=>null, onCancel=()=>null }) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [error, setError] = useState<string | null>(null);

  const handleFiles = (files: FileList) => {
    const validFiles: File[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // Validate file type
      if (!file.type.startsWith("image/")) {
        setError("Only image files are allowed.");
        return;
      }
      // Validate file size (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setError("File size must be under 5MB.");
        return;
      }
      validFiles.push(file);
    }
    setError(null);
    setSelectedFiles(validFiles);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    handleFiles(event.dataTransfer.files);
  };

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      handleFiles(event.target.files);
    }
  };

  const handleUpload = () => {
    onUpload(selectedFiles);
  };

  const handleCancel = () => {
    setSelectedFiles([])
    onCancel && onCancel()
  };

  return (
      <div className={styles.modalContent}>
        <h3>Upload Image</h3>
        <div
          className={styles.dropZone}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          <p>Drag and drop your images here, or <span>browse</span> to select files.</p>
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={handleFileInput}
            className={styles.fileInput}
          />
        </div>

        {/* Display selected image previews */}
        {selectedFiles.length > 0 && (
          <div className={styles.previewContainer}>
            {selectedFiles.map((file, index) => (
              <div key={index} className={styles.preview}>
                <img
                  src={URL.createObjectURL(file)}
                  alt="Preview"
                  className={styles.previewImage}
                />
                <p>{file.name}</p>
              </div>
            ))}
          </div>
        )}

        {error && <p className={styles.error}>{error}</p>}

        <div className={styles.actions}>
          <button onClick={handleUpload} className={styles.uploadButton}>Upload</button>
          <button onClick={handleCancel} className={styles.cancelButton}>Cancel</button>
        </div>
      </div>
  );
};

export default ImageUploadComponent;
