import { ChatRoomType } from "../../types/Data/ChatRoomType";
import ContactInfoV1 from "../../UIElements/ContactInfo/ContactInfoV1/ContactInfoV1";
import ContactInfoV2 from "../../UIElements/ContactInfo/ContactInfoV2/ContactInfoV2";
import ContactInfoV3 from "../../UIElements/ContactInfo/ContactInfoV3/ContactInfoV3";
import CustomerChatRoomV1 from "./CustomerChatRoomV1/CustomerChatRoomV1";

enum CustomerChatRoomVersions {
    V1 = "v1",
};

const CustomerChatRoomMapping:Record<CustomerChatRoomVersions, React.FC<any>> = {
    [CustomerChatRoomVersions.V1]: CustomerChatRoomV1,
};

interface CustomerChatRoomComponentProps {
    chatRoom?: ChatRoomType;
    onSendMessage?: (content: string) => void;
};

export {
    CustomerChatRoomVersions,
    CustomerChatRoomMapping
};

export type {
    CustomerChatRoomComponentProps
};