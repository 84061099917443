import React, { useState } from "react";
import OrderSearchBar from "./OrderSearchBar/OrderSearchBar";
import DashboardHeader from "../../../components/Header/DashboardHeader/DashboardHeader";
import OrderItemSelection from "./OrderItemSelection/OrderItemSelection";
import { 
    ReturnRequestType, 
    ReturnOrderItemType, 
    ReturnStatus, 
    RefundEligibility, 
    ReturnAdminApprovalStatus, 
    ReturnReason, 
    ReturnItemCondition 
} from "../../../types/Data/ReturnRequestType";
import { OrderType } from "../../../types/Data/OrderType";
import ReturnItemDetails from "./ReturnItemDetails/ReturnItemDetails";
import ReturnRequestNotes from "./ReturnRequestNotes/ReturnRequestNotes";
import SaveButton from "../../../components/Button/SaveButton/SaveButton";
import ReturnRequestStatus from "./ReturnRequestStatus/ReturnRequestStatus";
import { createReturnRequest } from "../../../api/returnRequest/returnRequestApi";
import { handleApiError } from "../../../api/apiError";

const CreateReturnPage = () => {
    // Define the default return request
    const defaultReturnRequest: ReturnRequestType = {
        _id: '',  // Assuming this would be generated upon save to the database
        order: '',
        customer: '',
        customerName: '',
        items: [],
        status: ReturnStatus.Pending,
        adminNotes: undefined,
        createdAt: new Date(),
        updatedAt: new Date(),
    };

    // State for the current return request
    const [currentReturnRequest, setCurrentReturnRequest] = useState<ReturnRequestType>(defaultReturnRequest);
    const [orderItems, setOrderItems] = useState<ReturnOrderItemType[]>([]);

    // Handle order selection to update return request
    const handleOrderSelection = (order: OrderType) => {
        const derivedItems: ReturnOrderItemType[] = order.items.map(item => ({
            product: item.productId,
            productName: item.name,
            quantity: item.quantity,
            price: item.unitPrice,
            isReturnable: true,
            refundRequested: false,
            refundEligibility: RefundEligibility.Eligible,
            exchangeEligible: false,
            adminApprovalStatus: ReturnAdminApprovalStatus.Pending,
        }));

        setOrderItems(derivedItems);

        setCurrentReturnRequest({
            ...currentReturnRequest,
            order: order._id,
            customer: order.customer._id as string,
            customerName: order.customer.name || '',
        });
    };

    // Function to toggle item selection in the return request
    const handleItemSelect = (item: ReturnOrderItemType, isSelected: boolean) => {
        let updatedItems = [...currentReturnRequest.items];
        if (isSelected) {
            // Add item if selected and not already in return request
            if (!updatedItems.some(returnItem => returnItem.product === item.product)) {
                updatedItems.push(item);
            }
        } else {
            // Remove item if deselected
            updatedItems = updatedItems.filter(returnItem => returnItem.product !== item.product);
        }
        setCurrentReturnRequest({ ...currentReturnRequest, items: updatedItems });
    };

    // Helper function to update an item property by product ID
    const updateItemProperty = (productId: string, property: Partial<ReturnOrderItemType>) => {
        const updatedItems = currentReturnRequest.items.map(item => 
            item.product === productId ? { ...item, ...property } : item
        );
        setCurrentReturnRequest({ ...currentReturnRequest, items: updatedItems });
    };

    const handleNotesChange = (adminNotes?: string) => {
        setCurrentReturnRequest((prevReturnRequest) => ({
            ...prevReturnRequest,
            adminNotes: adminNotes,
        }));
    };

    const handleReturnRequestStatusChange = (status: ReturnStatus) => {
        setCurrentReturnRequest((prevReturnRequest) => ({
            ...prevReturnRequest,
            status: status,
        }));
    };

    const handleSaveReturnRequest = async () => {
        try {
            const response = await createReturnRequest(currentReturnRequest);
            if (response.status === 201) {
                alert('Return Request Created')
            }
            else throw new Error('Error while creating return request')
        } catch (error: any) {
            const handledError = handleApiError(error)
            alert(handledError.message)
        }
    };

    return (
        <div>
            <DashboardHeader title="Create Return Request" />
            
            {/* Search for and select an order */}
            <OrderSearchBar onOrderSelection={handleOrderSelection} />
            
            {/* Select items for return based on selected order */}
            <OrderItemSelection
                orderItems={orderItems}
                returnRequestItems={currentReturnRequest.items}
                onItemSelect={handleItemSelect}
                onReturnReasonChange={(item, reason) => updateItemProperty(item.product as string, { returnReason: reason })}
                onQuantityChange={(item, quantity) => updateItemProperty(item.product as string, { quantity })}
            />

            {/* Display additional details for each selected return item */}
            <ReturnItemDetails
                items={currentReturnRequest.items}
                onConditionChange={(index, condition) => updateItemProperty(currentReturnRequest.items[index].product as string, { conditionAfterReturn: condition })}
                onRefundEligibilityChange={(index, eligibility) => updateItemProperty(currentReturnRequest.items[index].product as string, { refundEligibility: eligibility })}
                onAdminApprovalChange={(index, status) => updateItemProperty(currentReturnRequest.items[index].product as string, { adminApprovalStatus: status })}
            />
            <ReturnRequestNotes 
                notes={currentReturnRequest.adminNotes}
                onNotesChange={handleNotesChange}
            />
            <ReturnRequestStatus 
                returnRequestStatus={currentReturnRequest.status} 
                onReturnRequestStatusChange={handleReturnRequestStatusChange}
            />
            <SaveButton
                style={{marginTop:20}}
                onClick={handleSaveReturnRequest}
            />
        </div>
    );
};

export default CreateReturnPage;
