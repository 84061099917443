import { CustomerType } from "./CustomerType";
import { OrderType } from "./OrderType";
import { ProductType } from "./ProductType";


interface PaymentInfoType {
    paymentMethod: string;
    paymentProvider?: string; // e.g., 'Stripe', 'PayPal'
    paymentStatus: 'Pending' | 'Completed' | 'Failed';
    transactionId: string;
    paymentFees?: number; // New: Fees associated with the payment method
}

// Enum for Cancellation Reasons
enum CancellationReason {
    OutOfStock = 'Out of Stock',
    CustomerRequested = 'Customer Requested',
    DefectiveItem = 'Defective Item',
    LateShipment = 'Late Shipment',
    Other = 'Other'
}
// Enum for Return Status
enum CancellationStatus {
    Pending = 'Pending',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Returned = 'Returned',
}

interface CancellationItem {
    product: string| Partial<ProductType>;
    productName: string;
    price: number;
    quantity: number;
    cancellationReason?: CancellationReason; // Optional, specific reason for each item cancellation
}

interface CancellationType {
    _id: string;
    order: string | Partial<OrderType>;
    customer?: string | Partial<CustomerType>;
    customerName: string;
    reason: string;
    adminNotes?: string;
    items: CancellationItem[];
    refundAmount: number;
    paymentInfo?: PaymentInfoType; // e.g., Stripe ID or other payment details
    status: CancellationStatus;
    createdAt?: Date,
    updatedAt?: Date,
}

export type {
    CancellationType, 
    CancellationItem,
};

export {
    CancellationReason,
    CancellationStatus,
}
