import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ReturnRequestType, ReturnStatus } from '../../../types/Data/ReturnRequestType';
import { exampleReturnRequests } from './exampleReturns';
import { createReturnRequest, deleteReturnRequestById, getAllReturnRequests, updateReturnRequestById } from '../../../api/returnRequest/returnRequestApi';

interface ReturnState {
  returnRequests: ReturnRequestType[];
  loading: boolean;
  error: string | null;
}

const initialState: ReturnState = {
  returnRequests: [...exampleReturnRequests],
  loading: false,
  error: null,
};

// Async Thunks
export const fetchReturnRequestsThunk = createAsyncThunk(
  'returns/fetchReturnRequests',
  async (params:{}, { rejectWithValue }) => {
    try {
      const response = await getAllReturnRequests(params);
      return response.data.data;
    } catch (error) {
      const axiosError = error as any;
      const errorMessage = axiosError.response?.data?.message || 'Failed to fetch return requests';
      return rejectWithValue(errorMessage);
    }
  }
);


const returnRequestSlice = createSlice({
  name: 'returnRequests',
  initialState,
  reducers: {
    dispatchAddReturnRequest(state, action: PayloadAction<ReturnRequestType>) {
      state.returnRequests.push(action.payload);
    },
    dispatchUpdateReturnRequest(state, action: PayloadAction<ReturnRequestType>) {
      const index = state.returnRequests.findIndex((returnRequest) => returnRequest._id === action.payload._id);
      if (index !== -1) {
        state.returnRequests[index] = action.payload;
      }
    },
    dispatchDeleteReturnRequest(state, action: PayloadAction<string>) {
      state.returnRequests = state.returnRequests.filter((returnRequest) => returnRequest._id !== action.payload);
    },
    dispatchLoadReturnRequest(state, action: PayloadAction<ReturnRequestType[]>) {
      state.returnRequests = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle fetchReturnRequests
    builder.addCase(fetchReturnRequestsThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchReturnRequestsThunk.fulfilled, (state, action: PayloadAction<{ returnRequests: Partial<ReturnRequestType>[]; total: number; page: number; pages: number }>) => {
      state.loading = false;
      state.returnRequests = action.payload.returnRequests as ReturnRequestType[];
    });
    builder.addCase(fetchReturnRequestsThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Failed to fetch return requests';
    });

  }
});

export const {
  dispatchAddReturnRequest,
  dispatchUpdateReturnRequest,
  dispatchDeleteReturnRequest,
  dispatchLoadReturnRequest,
} = returnRequestSlice.actions;

export default returnRequestSlice.reducer;
