import { AxiosResponse } from "axios";
import { GeneralSettingsType } from "../../types/Data/ShopSettingsType";
import { ApiResponse } from "../apiType";
import api from "../config";

// Upsert (Create or Update) Shop Settings
export const upsertShopSettings = async (settingsData: GeneralSettingsType): Promise<AxiosResponse<ApiResponse<GeneralSettingsType>>> => {
    try {
        const response = await api.post('/shop-settings', settingsData);
        return response; // Return the data from the response
    } catch (error) {
        console.error('Error in upserting shop settings:', error);
        throw error
    }
};

// Get Shop Settings
export const getShopSettings = async ():Promise<ApiResponse<GeneralSettingsType>>=> {
    try {
        const response = await api.get('/shop-settings');
        return response.data; // Return the data from the response
    } catch (error) {
        console.error('Error in getting shop settings:', error);
        throw error
    }
};

// Delete Shop Settings
export const deleteShopSettings = async ():Promise<AxiosResponse<ApiResponse<GeneralSettingsType>>> => {
    try {
        const response = await api.delete('/shop-settings');
        return response; // Return the data from the response
    } catch (error) {
        console.error('Error in deleting shop settings:', error);
        throw error
    }
};
