import React, { useEffect, useState } from "react";
import styles from "./CalendarV1.module.scss";
import { CalendarComponentProps } from "../CalendarComponentProps";

const CalendarV1:React.FC<CalendarComponentProps> = ({ initialDate = new Date(), onClick, selectedDate = new Date() }) => {
    const [currentDate, setCurrentDate] = useState<Date>(new Date());

    const [calendar, setCalendar] = useState<(number | string)[][]>([]);

    useEffect(() => {
        const generateCalendar = (): (number | string)[][] => {
          const currentCalendar: (number | string)[][] = [];
          const n_days_in_rows = 7;
          const n_days = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
          const first_day = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
          const n_rows = Math.ceil((n_days + first_day) / n_days_in_rows);
    
          let day = 1;
          for (let i = 0; i < n_rows; i++) {
            currentCalendar[i] = [];
            for (let j = 0; j < n_days_in_rows; j++) {
              if (i === 0 && j < first_day) {
                currentCalendar[i][j] = '';
              } else if (day <= n_days) {
                currentCalendar[i][j] = day;
                day++;
              } else {
                currentCalendar[i][j] = '';
              }
            }
          }
          return currentCalendar;
        };
    
        setCalendar(generateCalendar());
    }, [currentDate]);


    const handlePreviousMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };

    const handleClick = (day:string | number) => {
        if (day && onClick) {
            let num:number = typeof day == 'number' ? day : parseInt(day, 10);
            return onClick( new Date(currentDate.getFullYear(), currentDate.getMonth(), num) );
        }
    }

    const isSelected = (day: number | string): boolean => {
        if (!day) return false;
        let num: number = typeof day === 'number' ? day : parseInt(day, 10);
        let date = new Date(currentDate.getFullYear(), currentDate.getMonth(), num);
        if (selectedDate) {
            return (
                date.getFullYear() === selectedDate.getFullYear() &&
                date.getMonth() === selectedDate.getMonth() &&
                date.getDate() === selectedDate.getDate()
            );
        }
        return false;
    };


    return (
        <div className={styles.calendarContainer}>
            <div className={styles.header}>
                <button onClick={handlePreviousMonth} className={styles.navButton}>Previous</button>
                <h2>{currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}</h2>
                <button onClick={handleNextMonth} className={styles.navButton}>Next</button>
            </div>
        {calendar.map((week, weekIndex) => (
            <div key={weekIndex} className={styles.weekRow}>
            {week.map((day, dayIndex) => (
                <div onClick={()=>handleClick(day)} key={dayIndex} className={`${styles.dayCell} ${day === '' ? styles.emptyCell : ''} ${isSelected(day) ? styles.selectedCell : ''}`}>
                 {day}
                </div>
            ))}
            </div>
        ))}
        </div>
    )
}

export default CalendarV1