import { PromotionBannerComponentProps } from "./PromotionalBannerComponentProps";

const defaultPromotionBannerProps:PromotionBannerComponentProps  = {
    banner: {
      title: 'Limited Time Offer!',
      text: 'Don’t miss out on our exclusive promotion. Hurry up before it ends!',
      imageUrl: '/images/promotional-banner-default.jpg',  // Replace with a valid image URL
      buttonText: 'Shop Now',
      buttonUrl: '/shop',
    },
  };
  
  export default defaultPromotionBannerProps;