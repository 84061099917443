import ProductCardListV1 from "./ProductCardListV1/ProductCardListV1";
import ProductCardListV10 from "./ProductCardListV10/ProductCardListV10";
import ProductCardListV2 from "./ProductCardListV2/ProductCardListV2";
import ProductCardListV3 from "./ProductCardListV3/ProductCardListV3";
import ProductCardListV4 from "./ProductCardListV4/ProductCardListV4";
import ProductCardListV5 from "./ProductCardListV5/ProductCardListV5";
import ProductCardListV6 from "./ProductCardListV6/ProductCardListV6";
import ProductCardListV7 from "./ProductCardListV7/ProductCardListV7";
import ProductCardListV8 from "./ProductCardListV8/ProductCardListV8";
import ProductCardListV9 from "./ProductCardListV9/ProductCardListV9";
import { ProductType } from "../../types/Data/ProductType";

enum ProductCardListVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
    V4 = "v4",
    V5 = "v5",
    V6 = "v6",
    V7 = "V7",
    V8 = "V8",
    V9 = "V9",
    V10 = "V10",
};

const ProductCardListMapping:Record<ProductCardListVersions, React.FC<any>> = {
    [ProductCardListVersions.V1]: ProductCardListV1,
    [ProductCardListVersions.V2]: ProductCardListV2,
    [ProductCardListVersions.V3]: ProductCardListV3,
    [ProductCardListVersions.V4]: ProductCardListV4,
    [ProductCardListVersions.V5]: ProductCardListV5,
    [ProductCardListVersions.V6]: ProductCardListV6,
    [ProductCardListVersions.V7]: ProductCardListV7,
    [ProductCardListVersions.V8]: ProductCardListV8,
    [ProductCardListVersions.V9]: ProductCardListV9,
    [ProductCardListVersions.V10]: ProductCardListV10,
};

interface ProductCardListComponentProps {
    product?: Partial<ProductType>;
    onProductClick?: (productId:string) => void;
};

export {
    ProductCardListMapping,
    ProductCardListVersions,
};

export type {
    ProductCardListComponentProps,
};