import TrustBadgeIconsV1 from "../../UIElements/TrustBadgeIcons/TrustBadgeIconsV1/TrustBadgeIconsV1";
import TrustBadgeIconsV2 from "../../UIElements/TrustBadgeIcons/TrustBadgeIconsV2/TrustBadgeIconsV2";
import TrustBadgeIconsV3 from "../../UIElements/TrustBadgeIcons/TrustBadgeIconsV3/TrustBadgeIconsV3";

enum TrustBadgeIconVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const TrustBadgeIconMapping:Record<TrustBadgeIconVersions, React.FC<any>> = {
    [TrustBadgeIconVersions.V1]: TrustBadgeIconsV1,
    [TrustBadgeIconVersions.V2]: TrustBadgeIconsV2,
    [TrustBadgeIconVersions.V3]: TrustBadgeIconsV3,
};

interface TrustBadgeCard {
    title?: string;
    description?: string;
    icon?: string;
}

interface TrustBadgeIconsComponentProps {
    items?: TrustBadgeCard[]
}

export {
    TrustBadgeIconVersions,
    TrustBadgeIconMapping
}

export type {
    TrustBadgeCard,
    TrustBadgeIconsComponentProps
}