import React from 'react'
import styles from '../Component.module.scss';
import SlideOverV1 from '../../components/SlideOver/SlideOverV1/SlideOverV1';
import SlideOverV3 from '../../components/SlideOver/SlideOverV3/SliderOverV3';
import SlideOverV2 from '../../components/SlideOver/SlideOverV2/SlideOverV2';

const SlideOverComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <SlideOverV1/>
        </div>
        <div className={styles.element}>
            <SlideOverV2/>
        </div>
        <div className={styles.element}>
            <SlideOverV3/>
        </div>
    </div>
  )
}

export default SlideOverComponentPage