import React, { ReactNode, useEffect, useState } from 'react';
import styles from './ReturnAdminNote.module.scss';


interface ReturnAdminNoteProps {
  adminNotes?: string;
  onNoteChange?: (note: string) => void;
  children?: ReactNode;
}

const ReturnAdminNote: React.FC<ReturnAdminNoteProps> = ({
  adminNotes,
  onNoteChange=()=>null,
  children
}) => {

  return (
    <div className={styles.returnAdminNote}>
      <h2>Admin Note</h2>
      <textarea
        className={styles.adminNoteTextarea}
        value={adminNotes}
        onChange={(e) => onNoteChange(e.target.value)}
        placeholder="Enter admin notes here..."
      />   
       {children && children}
    </div>
  );
};

export default ReturnAdminNote;
