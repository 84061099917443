import React, { useState, useEffect } from 'react';
import styles from './DiscountComponent.module.scss';

interface DiscountComponentProps {
  originalPrice: number;
  discountPrice?: number;
  discountPercentage?: number;
  onDiscountChange: (discountPrice: number, discountPercentage: number) => void;
}

const DiscountComponent: React.FC<DiscountComponentProps> = ({ originalPrice, discountPrice = 0, discountPercentage = 0, onDiscountChange }) => {
  const [price, setPrice] = useState(discountPrice);
  const [percentage, setPercentage] = useState(discountPercentage);

  useEffect(() => {
    if (percentage) {
      const calculatedPrice = originalPrice * (1 - percentage / 100);
      setPrice(calculatedPrice);
    }
  }, [percentage, originalPrice]);

  useEffect(() => {
    if (price) {
      const calculatedPercentage = ((originalPrice - price) / originalPrice) * 100;
      setPercentage(calculatedPercentage);
    }
  }, [price, originalPrice]);

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPrice = parseFloat(e.target.value);
    setPrice(newPrice);
    onDiscountChange(newPrice, ((originalPrice - newPrice) / originalPrice) * 100);
  };

  const handlePercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPercentage = parseFloat(e.target.value);
    setPercentage(newPercentage);
    onDiscountChange(originalPrice * (1 - newPercentage / 100), newPercentage);
  };

  return (
    <div className={styles.container}>
      <div className={styles.inline}>
        <label className={styles.label}>Original Price:</label>
        <span>{originalPrice.toFixed(2)} EUR</span>
      </div>
      <div className={styles.inputGroup}>
        <label className={styles.label}>Discount Price:</label>
        <input
          type="number"
          className={styles.inputField}
          value={price.toFixed(2)}
          onChange={handlePriceChange}
          min="0"
          max={originalPrice.toFixed(2)}
          step="0.01"
        />
      </div>
      <div className={styles.inputGroup}>
        <label className={styles.label}>Discount Percentage:</label>
        <input
          type="number"
          className={styles.inputField}
          value={percentage.toFixed(2)}
          onChange={handlePercentageChange}
          min="0"
          max="100"
          step="0.01"
        />
      </div>
    </div>
  );
};

export default DiscountComponent;
