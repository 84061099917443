import React, { useEffect, useState } from 'react';
import styles from '../../UIElementsMainSettings/UiElementEditor.module.scss';
import { IoRemove } from 'react-icons/io5';

import { BaseElementType, ComponentType } from '../../../types/Data/BaseElementType';
import InputV4 from '../../../components/Input/InputText/InputV4/InputV4';
import ButtonV1 from '../../../components/Button/ButtonV1/ButtonV1';
import TextAreaV1 from '../../../components/TextArea/TextAreaV1/TextAreaV1';
import { CompanyHistoryComponentProps, CompanyHistoryMapping, CompanyHistoryVersions } from '../CompanyHistoryComponentProps';
import { defaultCompanyHistoryProps } from '../companyHistoryDefaultProps';
import UIElementsMainSettings from '../../UIElementsMainSettings/UIElementsMainSettings';
import ButtonDelete from '../../../components/Button/ButtonDelete/ButtonDelete';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../Store/store';
import { useSelector } from 'react-redux';
import { fetchCreateElement, fetchUpdateElement } from '../../../Dashboard/DashboardState/element/elementSlice';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ButtonV8 from '../../../components/Button/ButtonV8/ButtonV8';

interface CompanyHistoryEditorProps {
  element?: BaseElementType<CompanyHistoryComponentProps>;
}

const CompanyHistoryEditor: React.FC<CompanyHistoryEditorProps> = ({
  element,
}) => {
  
  const defaultElement: BaseElementType<CompanyHistoryComponentProps> = {
    type: ComponentType.CompanyHistory,
    version: 'v1',
    page: 'home',
    props: defaultCompanyHistoryProps,
    position: undefined,
    isVisible: true,
  };

  const newMilestone= {
    date: '',
    event: '',
    description: '',
    imageUrl: ''
  };

  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state:RootState)=>state.dashboard.elements.loading);
  const error = useSelector((state:RootState)=>state.dashboard.elements.error);

  const [currentElement, setCurrentElement] = useState(element ? element : defaultElement);

  useEffect(() => {
    if (element) {
      setCurrentElement(element)
    } else {
      setCurrentElement(defaultElement)
    }
  }, [element])


  const handleAddMillestone = () => {
      setCurrentElement((prevElement) => ({
        ...prevElement,
        props: {
          ...prevElement.props,
          milestones: [...(prevElement?.props?.milestones ?? []), newMilestone],
        },
      }));
  }
  

  const handleRemoveSlide = (slideIndex: number) => {
    alert('remove')
      setCurrentElement((prevElement) => ({
        ...prevElement,
        props: {
          ...prevElement.props,
          slides: (prevElement?.props?.milestones ?? []).filter((_, index:number) => index !== slideIndex),
        },
      }));
  };

  const handleMilestoneChange = (milestoneIndex: number, key: string, value: any) => {
    setCurrentElement((prevElement) => ({
        ...prevElement,
        props: {
          ...prevElement.props,
          milestones: (prevElement?.props?.milestones ?? []).map((milestone, index) =>
            index === milestoneIndex ? { ...milestone, [key]: value } : milestone
          ),
        },
    }));
  }

  const handleElementUpdate = (key:string, value: any, ) => {
      setCurrentElement((prevElement) => ({
        ...prevElement,
        [key]: value,
      }));
  };

  const handlePropsChange = (key:string, value:any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      props: {
        ...prevElement.props,
        [key]: value,
      }
    }));
  }


  const handleElementVisibilityUpdate = () => {
    setCurrentElement(prev => ({ ...prev, isVisible: !prev.isVisible }));
  };

  const handleSaveElement = () => {
    if (currentElement?._id) {
      dispatch(fetchUpdateElement({id:currentElement._id, element:currentElement}))
    } else {
      dispatch(fetchCreateElement(currentElement))
    }
  };

  const SelectedComponent = CompanyHistoryMapping[currentElement.version as CompanyHistoryVersions] || CompanyHistoryMapping[CompanyHistoryVersions.V1];
  
  const elementsVersion = Object.keys(CompanyHistoryVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: CompanyHistoryVersions[key as keyof typeof CompanyHistoryVersions],
  }));


  return (
    <div className={styles.container}>
      { error && <ErrorBannerV1 message={error}/>}
      { loading && <LoadingIndicatorV1/>}
      <div className={styles.componentPreview}>
        <SelectedComponent banner={currentElement?.props} />
      </div>

      <div className={styles.componentEditor}>
      <UIElementsMainSettings
            element={currentElement}
            elementVersions={elementsVersion}
            onElementUpdate={handleElementUpdate}
            onVisibilitytUpdate={handleElementVisibilityUpdate}
        />
        <div className={styles.mainSettings}>
          <InputV4
            label=" Title"
            value={currentElement.props?.title || ''}
            onChange={(e) => handlePropsChange('title', e.target.value)}
          />
        </div>
        <div className={styles.slides}>
        {currentElement?.props?.milestones?.map((milestone, index) => (
          <div key={index} className={styles.slide}>
            <div className={styles.slideHeader}>
                <h4>Millestone {index + 1}</h4>
                <div className={styles.buttonRemove} onClick={() => handleRemoveSlide(index)} >
                    <IoRemove className={styles.removeButton} />
                </div>
            </div>
            <InputV4
              label="Milestone Date"
              value={milestone.date || ''}
              onChange={(e) => handleMilestoneChange(index, 'date', e.target.value)}
            />
            <TextAreaV1
              label="Milestone Event"
              value={milestone.event || ''}
              onChange={(e) => handleMilestoneChange(index, 'event', e.target.value)}
            />
            <InputV4
              label="Milestone Descirpiton"
              value={milestone.description || ''}
              onChange={(e) => handleMilestoneChange(index, 'description', e.target.value)}
            />
            <InputV4
              label="Image URL"
              value={milestone.imageUrl || ''}
              onChange={(e) => handleMilestoneChange(index, 'imageUrl', e.target.value)}
            />
          </div>
        ))}
      </div>
      </div>
      
      <ButtonV8 title="Add Slide" onClick={handleAddMillestone} />
      
      <div className={styles.buttonContainer}>
        <ButtonV1 label="Save Element" onClick={handleSaveElement} />
        <ButtonDelete/>
      </div>
    </div>
  );
};

export default CompanyHistoryEditor;
