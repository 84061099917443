import React, { useState, useRef, useEffect } from 'react';
import styles from './SliderV1.module.scss';
import { SliderComponentProps } from '../SliderComponentProps';

const SliderV1: React.FC<SliderComponentProps> = ({ min = 0, max = 50, value = 20, onChange = ()=>null, label="" }) => {
    const [dragging, setDragging] = useState(false);
    const sliderRef = useRef<HTMLDivElement>(null);

    const handleMouseMove = (e: MouseEvent) => {
        if (dragging && sliderRef.current) {
            const rect = sliderRef.current.getBoundingClientRect();
            const newValue = Math.min(max, Math.max(min, min + ((max - min) * (e.clientX - rect.left)) / rect.width));
            onChange(newValue);
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        if (sliderRef.current) {
            const rect = sliderRef.current.getBoundingClientRect();
            const newValue = Math.min(max, Math.max(min, min + ((max - min) * (e.clientX - rect.left)) / rect.width));
            onChange(newValue);
            setDragging(true);
        }
    };

    useEffect(() => {
        if (dragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragging]);

    return (
        <div>   
            {label && <label className={styles.label}>{label}</label>}
            <div className={styles.sliderContainer} ref={sliderRef} onMouseDown={handleMouseDown}>
                <div className={styles.sliderTrack}></div>
                <div
                    className={styles.sliderThumb}
                    style={{ left: `${((value - min) / (max - min)) * 100}%` }}
                ></div>
            </div>
        </div>

    );
};

export default SliderV1;
