// defaultProps.ts

import { ImageType } from "../../types/Data/ImageType";

// Default images for the gallery
export const defaultProductImageGalleryProps = {
  images: [
    {
      url: 'https://images.unsplash.com/photo-1504893524553-b855bce32c67?q=80&w=2187&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      alt: 'Nature Image'
    },
    {
      url: 'https://plus.unsplash.com/premium_photo-1674309438579-587b58d8486e?q=80&w=2225&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      alt: 'City Image'
    },
    {
      url: 'https://plus.unsplash.com/premium_photo-1683120974913-1ef17fdec2a8?q=80&w=2163&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      alt: 'Technology Image'
    },
    {
      url: 'https://plus.unsplash.com/premium_photo-1679526019856-72bd880632e0?q=80&w=2231&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      alt: 'Space Image'
    },
    {
      url: 'https://images.unsplash.com/photo-1505142468610-359e7d316be0?q=80&w=2126&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      alt: 'Ocean Image'
    }
  ] as ImageType[]
};
