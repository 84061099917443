import React from 'react'
import styles from  './ProductReviewV5.module.scss';
import { ProductReviewComponentProps } from '../ProductReviewComponentProps';
import { defaultProductReviewsProps } from '../defaultProductReviewProps';
import ReviewStars from '../ReviewStars';


const ProductReviewV5:React.FC<ProductReviewComponentProps> = ({reviews = defaultProductReviewsProps}) => {
    return (
    <div className={styles.review}>
        <h4>Recent Review</h4>
        {reviews?.map((review, index) => {
            return (
                <div className={styles.reviewCard}>
                <div className="header">
                    <img src={'https://via.placeholder.com/50'} alt={`${review.userName}'s avatar`} className={styles.avatar} />
                    <div className={styles.userInfo}>
                    <h4 className={styles.username}>{review.userName}</h4>
                    <ReviewStars rating={review.rating!}/>
                  </div>
                </div>
                <div className={styles.body}>
                  <p className={styles.reviewText}>{review.content}</p>
                </div>
                <div className={styles.footer}>
                  <span className={styles.date}>{review?.reviewDate ? new Date(review.reviewDate).toLocaleDateString() : 'No date available'}</span>
                </div>
              </div>
            )
        })}
    </div>
  )
  
}

export default ProductReviewV5