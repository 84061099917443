import React, { ReactNode } from 'react';
import styles from './ReturnShipment.module.scss';
import Select4 from '../../../../components/Select/Select4/Select4';
import { ReturnRequestType } from '../../../../types/Data/ReturnRequestType';
import InputV4 from '../../../../components/Input/InputText/InputV4/InputV4';

interface ReturnShipmentProps {
  returnRequestShippment?: ReturnRequestType['shipmentInfo'] | null;
  onChange: (updatedRequest: ReturnRequestType['shipmentInfo']) => void;
  onGenerateLabel?: () => void;
  children?: ReactNode;
}

const ReturnShipment: React.FC<ReturnShipmentProps> = ({
  returnRequestShippment,
  onChange,
  onGenerateLabel,
  children
}) => {
  // Default shipment info
  const defaultShippmentInfo: ReturnRequestType['shipmentInfo'] = {
    shippingCarrier: '',
    trackingNumber: '',
    shippingMethod: '',
    shippingDate: new Date(), // Default to current date
  };

  // Use the passed shipment info or fall back to default
  const shippmentInfo = returnRequestShippment || defaultShippmentInfo;

  // Handle changes in the shipment info and propagate it upwards
  const handleShippmentInfoChange = (
    key: keyof ReturnRequestType['shipmentInfo'],
    value: any
  ) => {
    const updatedShippmentInfo = { ...shippmentInfo, [key]: value };
    onChange(updatedShippmentInfo);
  };

  return (
    <div className={styles.returnShipment}>
      <h2>Return Shipping Information</h2>
      <div className={styles.formGroup}>
        <Select4
          label="Shipping Carrier"
          placeholder="Select Carrier"
          options={[
            { label: 'UPS', value: 'UPS' },
            { label: 'FedEx', value: 'FedEx' },
            { label: 'DHL', value: 'DHL' },
            { label: 'USPS', value: 'USPS' },
          ]}
          value={shippmentInfo.shippingCarrier}
          onChange={(value) => handleShippmentInfoChange('shippingCarrier' as keyof ReturnRequestType['shipmentInfo'], value as string)}
        />
      </div>

      <div className={styles.formGroup}>
        <InputV4
          label="Tracking Number"
          type="text"
          value={shippmentInfo.trackingNumber}
          onChange={(e) => handleShippmentInfoChange('trackingNumber' as keyof ReturnRequestType['shipmentInfo'], e.target.value)}
          placeholder="Enter Tracking Number"
        />
      </div>

      <div className={styles.formGroup}>
        <Select4
          label="Shipping Method"
          placeholder="Select Shipping Method"
          options={[
            { label: 'Standard', value: 'Standard' },
            { label: 'Expedited', value: 'Expedited' },
            { label: 'Overnight', value: 'Overnight' },
          ]}
          value={shippmentInfo.shippingMethod}
          onChange={(value) => handleShippmentInfoChange('shippingMethod' as keyof ReturnRequestType['shipmentInfo'], value)}
        />
      </div>

      <div className={styles.formGroup}>
        <InputV4
          label="Shipping Date"
          type="date"
          value={shippmentInfo.shippingDate instanceof Date && !isNaN(shippmentInfo.shippingDate.getTime()) 
            ? shippmentInfo.shippingDate.toISOString().split('T')[0] 
            : ''}          
           onChange={(e) => handleShippmentInfoChange('shippingDate' as keyof ReturnRequestType['shipmentInfo'], e.target.value)}
          placeholder="Select Shipping Date"
        />
      </div>
      {children && children}
    </div>
  );
};

export default ReturnShipment;
