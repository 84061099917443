import React from 'react';
import { EmailTemplateType } from '../../../../types/Data/EmailTemplateType';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Store/store';
import { ProductType } from '../../../../types/Data/ProductType';

const WelcomeEmailSkeleton:React.FC<EmailTemplateType> = ({ data }) => {
    const styles: {[key:string]: React.CSSProperties} = {
        emailSkeleton: {
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            margin: '0 auto',
            border: '1px solid #ddd',
            borderRadius: '8px',
            overflow: 'hidden',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Arial, sans-serif',
            color: '#333',
            backgroundColor: '#f9f9f9',
            animation: 'fadeIn 0.5s ease-in-out'
        },
        emailHeader: {
            backgroundColor: '#3498db',
            color: '#fff',
            padding: '20px',
            textAlign: 'center'
        },
        emailHeaderH1: {
            margin: '0',
            fontSize: '24px',
            animation: 'slideIn 0.5s ease-in-out'
        },
        emailBody: {
            padding: '20px'
        },
        emailBodyP: {
            lineHeight: '1.5',
            fontSize: '16px',
            animation: 'fadeIn 0.5s ease-in-out'
        },
        section: {
            marginTop: '20px',
            animation: 'fadeIn 0.5s ease-in-out'
        },
        sectionH2: {
            fontSize: '20px',
            borderBottom: '2px solid #ddd',
            paddingBottom: '10px',
            marginBottom: '20px'
        },
        productList: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '20px'
        },
        productItem: {
            width: 'calc(50% - 10px)',
            background: '#fff',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '10px',
            textAlign: 'center',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease'
        },
        productItemHover: {
            transform: 'translateY(-5px)',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)'
        },
        productItemImg: {
            width: '100%',
            borderRadius: '5px',
            marginBottom: '10px'
        },
        productItemH3: {
            margin: '10px 0 5px',
            fontSize: '16px',
            color: '#333'
        },
        productItemP: {
            margin: '5px 0',
            fontSize: '14px',
            color: '#666'
        },
        linksList: {
            listStyle: 'none',
            padding: '0'
        },
        linksListItem: {
            margin: '10px 0',
            fontSize: '16px'
        },
        linksListItemA: {
            color: '#3498db',
            textDecoration: 'none',
            transition: 'color 0.3s ease'
        },
        linksListItemAHover: {
            color: '#2980b9'
        },
        emailFooter: {
            backgroundColor: '#f1f1f1',
            padding: '20px',
            textAlign: 'center',
            animation: 'fadeIn 0.5s ease-in-out'
        },
        emailFooterP: {
            margin: '5px 0',
            fontSize: '14px',
            color: '#666'
        }
    };

    const products = useSelector<RootState>((state) => 
        state.dashboard.products.products.filter((product) => 
            data?.productIds?.includes(product._id as string) ?? false
        )
    ) as Partial<ProductType>[]
    
    return (
        <div style={styles.emailSkeleton}>
            <style>{
            `@keyframes fadeIn {
                from {
                    opacity: 0;
                    transform: translateY(-10px);
                }
                to {
                    opacity: 1;
                    transform: translateY(0);
                }
            }`}
            </style>
            <style>{
                `@keyframes slideIn {
                    from {
                        opacity: 0;
                        transform: translateX(-100%);
                    }
                    to {
                        opacity: 1;
                        transform: translateX(0);
                    }
            }`}</style>
            <header style={styles.emailHeader}>
                <h1 style={styles.emailHeaderH1}>{data?.title}</h1>
            </header>
            <main style={styles.emailBody}>
                { data?.body && <div dangerouslySetInnerHTML={{ __html: data.body }} /> }
                {products && products.length > 0 && (
                    <div style={styles.section}>
                        <h2 style={styles.sectionH2}>Featured Products</h2>
                        <div style={styles.productList}>
                            {products!.map(product => (
                                <div 
                                    key={product._id!} 
                                    style={styles.productItem}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.transform = styles?.productItemHover?.transform!;
                                        e.currentTarget.style.boxShadow = styles?.productItemHover?.boxShadow!;
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.transform = '';
                                        e.currentTarget.style.boxShadow = '';
                                    }}
                                >
                                    <img src={product?.images![0].url} alt={product.name} style={styles.productItemImg} />
                                    <h3 style={styles.productItemH3}>{product.name}</h3>
                                    {product.categories && <p style={styles.productItemP}>{product.categories[0]}</p>}
                                    <p style={styles.productItemP}>${product.price}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {data?.importantLinks && data.importantLinks.length > 0 && (
                    <div style={styles.section}>
                        <h2 style={styles.sectionH2}>Important Links</h2>
                        <ul style={styles.linksList}>
                            {data.importantLinks!.map((link, index) => (
                                <li key={index} style={styles.linksListItem}>
                                    <a 
                                        href={link.url} 
                                        style={styles.linksListItemA}
                                        onMouseEnter={(e) => {
                                            e.currentTarget.style.color = styles?.linksListItemAHover?.color!;
                                        }}
                                        onMouseLeave={(e) => {
                                            e.currentTarget.style.color = styles?.linksListItemA?.color!;
                                        }}
                                    >
                                        {link.label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </main>
            <footer style={styles.emailFooter}>
                <p style={styles.emailFooterP}>Thank you for joining us!</p>
                <p style={styles.emailFooterP}>Unsubscribe | Contact Us</p>
            </footer>
        </div>
    );
};

export default WelcomeEmailSkeleton;
