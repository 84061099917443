import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Input4 from '../../../../components/Input/InputText/InputV4/InputV4';
import Button1 from '../../../../components/Button/ButtonV1/ButtonV1';
import styles from './SocialMediaEditPage.module.scss';
import { SocialMediaLinkType } from '../../../../types/Data/SocialMediaSettingsType';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../../Store/store';
import { createSocialMediaLink, getSocialMediaLinkByPlatform, updateSocialMediaLink } from '../../../../api/socialMedia/socialMediaApi';
import { handleApiError } from '../../../../api/apiError';
import SaveButton from '../../../../components/Button/SaveButton/SaveButton';
import ButtonCancel from '../../../../components/Button/ButtonCancel/ButtonCancel';
import DashboardHeader from '../../../../components/Header/DashboardHeader/DashboardHeader';

interface SocialMediaEditProps {
  socialMediaLink?: SocialMediaLinkType;
};

const SocialMediaEditPage: React.FC<SocialMediaEditProps> = ({ socialMediaLink }) => {
  const location = useLocation();
  const socialMediaPlateform = new URLSearchParams(location.search).get('socialMediaPlateform');
  
  const dispatch = useDispatch();

  const defaultSocialMediaLink: SocialMediaLinkType = {
    platform: '',
    url: '',
    iconUrl: '',
  };

  const socialMediaLinkToEdit = useSelector((state:RootState)=>state.dashboard?.socialMedia?.socialMediaLinks.find((el)=>el.platform === socialMediaPlateform ))

  const [originalMediaLink, setOriginalMediaLink] = useState<SocialMediaLinkType>(socialMediaLinkToEdit || defaultSocialMediaLink);
  const [currentSocialMediaLink, setCurrentSocialMediaLink] = useState<SocialMediaLinkType>(socialMediaLinkToEdit || defaultSocialMediaLink);

  const [loading, setLoading] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);


  const getSocialMediaLink = async () => {
    setLoading(true);
    try {
      const response = await getSocialMediaLinkByPlatform(socialMediaPlateform!);
      if (response.status === 200 && response.data) {
        setOriginalMediaLink(response.data.data);
        setCurrentSocialMediaLink(response.data.data);
      } else {
        alert('An error occured while retrieving social media')
      }
    } catch (error:any) {
      const handledError = handleApiError(error);
      alert(handledError.message)
    } finally {
      setLoading(false)
    }
  }

  const handleSaveSocialMediaLink = async () => {
    setIsSaving(true);
    try {
      const response = await createSocialMediaLink(currentSocialMediaLink!);
      if (response.status === 201 && response.data) {
        setOriginalMediaLink(response.data.data);
        setCurrentSocialMediaLink(response.data.data);
      } else {
        alert('An error occured while creating social media')
      }
    } catch (error:any) {
      const handledError = handleApiError(error);
      alert(handledError.message)
    } finally {
      setLoading(false)
    }
  };

  const handleUpdateSocialMediaLink = async () => {
    setIsSaving(true);
    try {
      const response = await updateSocialMediaLink(currentSocialMediaLink.platform, currentSocialMediaLink!);
      if (response.status === 200 && response.data) {
        setOriginalMediaLink(response.data.data);
        setCurrentSocialMediaLink(response.data.data);
      } else {
        alert('An error occured while updating social media')
      }
    } catch (error:any) {
      const handledError = handleApiError(error);
      alert(handledError.message)
    } finally {
      setLoading(false)
    }
  };

  /*
  useEffect(() => {
    if (socialMediaPlateform) {
      getSocialMediaLink()
    } else {
      setOriginalMediaLink(defaultSocialMediaLink)
      setCurrentSocialMediaLink(defaultSocialMediaLink)
    }
  }, [socialMediaPlateform]);
  */

  const handleCancel = () => {
    setCurrentSocialMediaLink(originalMediaLink)
  };

  const handleChange = (key: string, value: any) => {
    setCurrentSocialMediaLink({ ...currentSocialMediaLink, [key]: value });
  };


  return (
    <div>
      <DashboardHeader title={socialMediaPlateform ? "Edit Social Media Link" : "Add Social Media Link"}/>
      <div className={styles.social_media_edit}>
        <Input4
          wrapperStyle={{ marginTop: 20 }}
          label="Platform"
          type="text"
          id="platform"
          name="platform"
          value={currentSocialMediaLink.platform}
          onChange={(e) => handleChange('platform', e.target.value)}
          required
          readOnly={!!socialMediaPlateform}
        />

        <Input4
          wrapperStyle={{ marginTop: 20 }}
          label="URL"
          type="text"
          id="url"
          name="url"
          value={currentSocialMediaLink.url}
          onChange={(e) => handleChange('url', e.target.value)}
          required
        />

        <Input4
          wrapperStyle={{ marginTop: 20 }}
          label="Icon URL"
          type="text"
          id="iconUrl"
          name="iconUrl"
          value={currentSocialMediaLink.iconUrl}
          onChange={(e) => handleChange('iconUrl', e.target.value)}
          required
        />
      </div>

      <div className={styles.button_container}>
        <SaveButton
          style={{marginTop: 15}}
          onClick={socialMediaPlateform ? handleUpdateSocialMediaLink : handleSaveSocialMediaLink}
          isSaving
        />
        <ButtonCancel 
          style={{marginTop: 15, marginLeft: 15}}
          onClick={handleCancel}/>

      </div>
    </div>
  );
};

export default SocialMediaEditPage;
