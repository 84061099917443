import React from 'react';
import styles from './ProductInfoCardV4.module.scss';
import { ProductInfoCardComponentProps } from '../ProductInfoCardComponentProps';
import { defaultProductInfoCardProps } from '../defaultProductInfoCardProps';


const ProductInfoCardV4:React.FC<ProductInfoCardComponentProps> = ({ product = defaultProductInfoCardProps }) => {
  return (
    <div className={styles.card}>
      <div className={styles.overlay}>
        <div className={styles.content}>
          <h2>{product.name}</h2>
          <p className={styles.price}>${product.price}</p>
          <p>{product.description}</p>
          <ul className={styles.features}>
            {product.attributes!.map((attribute, index) => (
              <li key={index}>{attribute.value}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProductInfoCardV4;
