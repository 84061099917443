import React, { useEffect } from "react";
import styles from './RecentCustomer.module.scss';
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../Store/store";
import LoadingIndicatorV1 from "../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1";
import ErrorBannerV1 from "../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1";
import { CustomerType } from "../../../types/Data/CustomerType";
import { fetchAllCustomers } from "../../DashboardState/customers/customersSlice";
import { useNavigate } from "react-router-dom";

interface RecentOrderProps {
    title?: string;
}

const RecentCustomer: React.FC<RecentOrderProps> = ({ title = "Recent Customer" }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const customers = useSelector((state: RootState) => state.dashboard.customers.customers) as CustomerType[];
    const loading = useSelector((state: RootState) => state.dashboard.orders.loading) as boolean;
    const error = useSelector((state: RootState) => state.dashboard.orders.error);

    useEffect(() => {
        if (customers.length === 0) {
            dispatch(fetchAllCustomers({ limit: 10, page: 1 }));
        }
    }, [dispatch, customers.length]);

    const handleClick = (customerId: string) => {
        navigate(`/dashboard/customers/${customerId}`)
    };

    return (
        <div className={styles.recentActivity}>
            <h3>{title}</h3>
            {loading && <LoadingIndicatorV1 />}
            {error && <ErrorBannerV1 message={error} />}
            <ul className={styles.list}>
                {customers.slice(0, 4).map((customer) => (
                    <li className={styles.item} key={customer._id} onClick={()=>handleClick(customer._id)}>
                        {`CustomerId: ${customer._id} - Email: ${customer.email} - Name: ${customer.name}`}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RecentCustomer;
