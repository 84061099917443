import React from "react";
import styles from './FooterSocialMedia.module.scss';
import { IoShareSocial } from "react-icons/io5";
import { SocialMediaLinkType } from "../../../../types/Data/SocialMediaSettingsType";

interface FooterSocialMediaProps {
    socialMediaLinks: SocialMediaLinkType[];
}

const FooterSocialMedia: React.FC<FooterSocialMediaProps> = ({ socialMediaLinks }) => {
    return (
        <div className={styles.footerContainer}>
            <div className={styles.label}> <IoShareSocial/>Social Media</div>
            <div className={styles.socialMediaLinks}>
                {socialMediaLinks.map((socialMedia, index: number) => (
                    <img
                        key={index}
                        src={socialMedia.iconUrl}
                        alt={socialMedia.platform}
                        className={styles.socialMediaIcon}
                    />
                ))}
            </div>
        </div>
    );
}

export default FooterSocialMedia;
