import SizeSelectorV1 from "../../UIElements/SizeSelector/SizeSelectorV1/SizeSelectorV1";

enum SizeSelectorVersions {
    V1 = "v1",
};

const SizeSelectorMapping:Record<SizeSelectorVersions, React.FC<any>> = {
    [SizeSelectorVersions.V1]: SizeSelectorV1,
};

interface SizeSelectorComponentProps {
    sizes?: string[]
}

export {
    SizeSelectorMapping,
    SizeSelectorVersions
}

export type {
    SizeSelectorComponentProps
}