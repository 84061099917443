import React from 'react'
import styles from '../Component.module.scss';
import StatsV2 from '../../components/Stats/StatCard/StatCardV1/StatCardV1';
import { defaultStatComponentProps } from '../../components/Stats/statsDefaultProps';
import StatsV3 from '../../components/Stats/StatCard/StatCardV2/StatsV2';
import StatsV1 from '../../components/Stats/StatsSection/StatsSectionV1/StatsSectionV1';

const StatsComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <StatsV1 {...defaultStatComponentProps.stats![0]}/>
        </div>
        <div className={styles.element}>
            <StatsV2 {...defaultStatComponentProps.stats![0]}/>
        </div>
        <div className={styles.element}>
            <StatsV3 {...defaultStatComponentProps.stats![0]}/>
        </div>
    </div>
  )
}

export default StatsComponentPage