import React, { useEffect, useState } from 'react';
import styles from './ReturnRequestDetailPage.module.scss';
import { useParams } from 'react-router-dom';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../Store/store';
import ReturnRequestInfo from './ReturnRequestInfo/ReturnRequestInfo';
import { ReturnItemCondition, ReturnOrderItemType, ReturnReason, ReturnRequestType, ReturnStatus } from '../../../types/Data/ReturnRequestType';
import { dispatchUpdateReturnRequest } from '../../DashboardState/returnRequest/returnRequestSlice';
import MessagingV1 from '../../../components/Messaging/MessagingV1/MessagingV1';
import ReturnHistoryReview from './ReturnHistoryReview/ReturnHistoryReview';
import { MessageType } from '../../../types/Data/MessageType';
import ReturnShipment from './ReturnShipment/ReturnShipment';
import ReturnedItemsTable from './ReturnItems/ReturnedItemsTable';
import ReturnRequestStatus from './ReturnStatus/ReturnRequestStatus';
import ReturnAdminNote from './ReturnAdminNote/ReturnAdminNote';
import SaveButton from '../../../components/Button/SaveButton/SaveButton';
import { getReturnRequestById, patchReturnRequest, updateReturnRequestById } from '../../../api/returnRequest/returnRequestApi';
import { handleApiError } from '../../../api/apiError';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import ButtonCancel from '../../../components/Button/ButtonCancel/ButtonCancel';
import { RefundProcessType, RefundReturnedProduct, RefundStatus } from '../../../types/Data/RefundProcessType';
import EligibleItemsTable from './EligibleItemsLabel/EligibleItemsTables';
import ValidateReturnedItemsTable from './ValidateReturnedItemsTable/ValidateReturnedItemsTable';
import FinalizeRefundTable from './FinalizeRefundTable/FinalizeRefundTable';
import { createRefundProcess } from '../../../api/refundProcess/refundProcessApi';

const ReturnRequestDetailPage: React.FC = () => {
    const { returnRequestId } = useParams<{ returnRequestId: string }>();
    const dispatch = useDispatch();
    
    const returnRequest = useSelector<RootState>((state) => 
        state.dashboard.returnRequest.returnRequests.find(ret => ret._id === returnRequestId)
    ) as ReturnRequestType;

    // State variables for original and current review
    const [originalReturnRequest, setOriginalRequest] = useState<ReturnRequestType>(returnRequest || {});
    const [currentReturnRequest, setCurrentReturnRequest] = useState<ReturnRequestType>(returnRequest || {});

    // State variables handling fetch status
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<{message:string, status:number | undefined} | null>()
    const [isSaving, setIsSaving] = useState<boolean>(false);

    // Handle items to be returned
    const [eligibleItems, setEligibleItems] = useState<ReturnOrderItemType[]>([]);
    const [validatedItems, setValidatedItems] = useState<RefundReturnedProduct[]>([]);
  
    const getReturnRequest = async (reviewId: string) => {
        setIsLoading(true); // Set loading to true before request
        try {
          let data = await getReturnRequestById(reviewId);
          return data.data;
        } catch (error: any) {
          if (error.name === 'AbortError') {
            console.log('Request cancelled');
          } else {
            let handledError = handleApiError(error);
            setError({ message: handledError.message, status: handledError.status });
          }
        } finally {
          setIsLoading(false); // Ensure loading is turned off
        }
    };

    useEffect(()=>{
        setCurrentReturnRequest(originalReturnRequest)
    }, [originalReturnRequest]);

    /*
    useEffect(() => {  
        if (returnRequestId) {
          getReturnRequest(returnRequestId)
            .then(data => {
              if (data) {
                setOriginalRequest(data);  // Set the original review
                setCurrentReturnRequest(data);   // Set the current review
              }
            })
        }
    }, [returnRequestId]);
    */

    // Function to handle updates to specific sections of the order
    const handleUpdateRequestSegment = (segment: Partial<ReturnRequestType>) => {
        const updatedRequest = { ...currentReturnRequest, ...segment };
        setCurrentReturnRequest(updatedRequest);
    };

    const handleSendMessage = (content: string) => {
        const newMessage:MessageType = {
            _id: `msg-${Date.now()}`,
            sender: 'Admin',
            chatRoom: '1',
            senderModel: 'admin',
            content,
            createdAt: new Date(),
        }; 
    };

    const handlePatch = async (data:Partial<ReturnRequestType>) => {
        setIsSaving(true);  // To handle button loading state
        try {
          const response = await patchReturnRequest(currentReturnRequest._id!, data);  // Call the API to save the review
          
          if (response && response.data) {
            // Assuming 200 is the success status code
            setOriginalRequest(response.data)
            alert('Return request updated successfully!');  // Success message
            
            // Optionally reset form or state if needed
          } else {
            // Handle unexpected success cases
            alert('Something went wrong while updating the return request!');
          }
        } catch (error: any) {
          const handledError = handleApiError(error);  // This function should parse and return user-friendly error messages
          alert(`Failed to save return request: ${handledError}`);
        } finally {
          setIsSaving(false);  // Stop loading state
        }
    };

    const handleUpdate = async () => {
        setIsSaving(true);  // To handle button loading state
        try {
          const response = await updateReturnRequestById(currentReturnRequest._id!, currentReturnRequest);  // Call the API to save the review
          
          if (response.status === 200) {
            // Assuming 200 is the success status code
            setOriginalRequest(response.data.data)
            alert('Return request updated successfully!');  // Success message
            
            // Optionally reset form or state if needed
          } else {
            // Handle unexpected success cases
            alert('Something went wrong while updating the return request!');
          }
        } catch (error: any) {
          const handledError = handleApiError(error);  // This function should parse and return user-friendly error messages
          alert(`Failed to save return request: ${handledError}`);
        } finally {
          setIsSaving(false);  // Stop loading state
        }
    };

    const handleCancel = () => {
        setCurrentReturnRequest(originalReturnRequest)
    };

    const handleItemSelection = (selectedItems: ReturnOrderItemType[]) => {
        setEligibleItems(selectedItems);
      };
    
      const handleItemValidation = (validatedItems: RefundReturnedProduct[]) => {
        setValidatedItems(validatedItems);
      };

    const handleRefundCreation = async () => {
        setIsSaving(true);  // To handle button loading state
        try {
            const newRefund:RefundProcessType = {
                returnedProducts: validatedItems,
                refundStatus: RefundStatus.Pending,
                returnRequest: originalReturnRequest._id,
                customer: originalReturnRequest.customer,
                order: originalReturnRequest.order,
                customerName: originalReturnRequest.customerName,
                totalRefundAmount: calculateTotalRefundAmound()
            };

            const response = await createRefundProcess(newRefund)

            if (response.status === 200) {
                // Assuming 200 is the success status code
                setOriginalRequest({...originalReturnRequest, refundRequestId: response.data.data._id})
                alert('Refund created successfully!');  // Success message
                
                // Optionally reset form or state if needed
              } else {
                // Handle unexpected success cases
                alert('Something went wrong while creating the refund!');
              }
        } catch (error:any) {
            const handledError = handleApiError(error)
            alert(`Failed to create the refund: ${handledError}`);
        } finally {
            setIsLoading(false)
        }
    };
    
    function  calculateTotalRefundAmound () {
        return validatedItems.reduce((total, item) => total + item?.refundAmount!, 0);
    }

    if (isLoading) return <LoadingIndicatorV1 />;
    if (error) return <ErrorBannerV1 message={error.message || 'Error'} status={error.status} />;
    
    return (
        <div className={styles.cancellationDetails}>
            <DashboardHeader title='Return Request Details' />  
            <ReturnRequestInfo returnRequest={originalReturnRequest} />
                    
            <ReturnRequestStatus 
                returnRequestStatus={currentReturnRequest.status}
                onStatusChange={(status)=> handleUpdateRequestSegment({status:status})}
            >
                <SaveButton style={{marginTop:20}} onClick={()=>handlePatch({status:currentReturnRequest.status})}/>
            </ReturnRequestStatus>
            <ReturnShipment 
                returnRequestShippment={currentReturnRequest.shipmentInfo}
                onChange={(returnShipmentInfo:ReturnRequestType['shipmentInfo'])=>handleUpdateRequestSegment({shipmentInfo:returnShipmentInfo})}
            >
                <SaveButton style={{marginTop:10}} onClick={()=>handlePatch({shipmentInfo:currentReturnRequest.shipmentInfo})}/>
            </ReturnShipment>
            <EligibleItemsTable items={currentReturnRequest.items} onItemSelect={handleItemSelection} />
      
            <ValidateReturnedItemsTable items={eligibleItems} onValidateItems={handleItemValidation} />
            
            <FinalizeRefundTable validatedItems={validatedItems} onCreateRefund={handleRefundCreation}/>
            
            <ReturnAdminNote
                adminNotes={returnRequest?.adminNotes}
                onNoteChange={(note:string)=>handleUpdateRequestSegment({adminNotes:note})}
            >
                <SaveButton style={{marginTop:10}} onClick={()=>handlePatch({adminNotes:currentReturnRequest.adminNotes})}/>
            </ReturnAdminNote>
            <MessagingV1
                title='Chat'
                wrapperStyle={{marginTop:20}}
                chatHistory={[]} 
                onSendMessage={()=>null} 
            />
           <ReturnHistoryReview customerId={ typeof originalReturnRequest.customer == "string" ? originalReturnRequest.customer : originalReturnRequest.customer._id as string} />
           <div className={styles.buttonContainer}>
                <SaveButton onClick={handleUpdate}/>
                <ButtonCancel style={{marginLeft:10}} onClick={()=>handleCancel()}/>
            </div>
        </div>
    );
};

export default ReturnRequestDetailPage;
