import React from 'react';
import styles from './OrderErrorV1.module.scss';
import { FaExclamationTriangle } from 'react-icons/fa';
import { OrderErrorComponentProps } from '../OrderErrorComponentProps';


const OrderErrorV1: React.FC<OrderErrorComponentProps> = ({ message = "Error", onRetry }) => {
  return (
    <div className={styles.orderError}>
      <div className={styles.iconContainer}>
        <FaExclamationTriangle className={styles.icon} />
      </div>
      <h2>Order Error</h2>
      <p>{message}</p>
      {onRetry && <button onClick={onRetry}>Try Again</button>}
    </div>
  );
};

export default OrderErrorV1;
