import axios, { AxiosResponse } from 'axios';
import { OrderType } from '../../types/Data/OrderType';
import { ApiResponse } from '../apiType';
import api from '../config';

interface GetOrdersParams {
    search?: string;
    status?: string;
    paymentStatus?: string;
    startDate?: string;
    endDate?: string;
    sortOrder?: 'asc' | 'desc';
    page?: number;
    limit?: number;
};

// Get all orders
export const getAllOrders = async (
  params: GetOrdersParams = {}
): Promise<AxiosResponse<ApiResponse<{ orders: Partial<OrderType>[]; total: number; page: number; pages: number }>>> => {
  try {
    const response = await api.get('/api/orders', { params });
    return response;
  } catch (error) {
    console.error("Error fetching all orders:", error);
    throw error;
  }
};

// Get orders by User ID
export const getOrderByUserId = async (userId: string): Promise<AxiosResponse<ApiResponse<OrderType[]>>> => {
  try {
    const response = await api.get(`/user/orders/${userId}`);
    return response;
  } catch (error) {
    console.error(`Error fetching orders for user ID ${userId}:`, error);
    throw error;
  }
};

// Get order by ID (Admin only)
export const getOrderById = async (orderId: string): Promise<AxiosResponse<ApiResponse<OrderType>>> => {
  try {
    const response = await api.get(`/orders/${orderId}`);
    return response;
  } catch (error) {
    console.error(`Error fetching order ID ${orderId}:`, error);
    throw error;
  }
};

// Create a new order (Admin only)
export const createOrder = async (orderData: Partial<OrderType>): Promise<AxiosResponse<ApiResponse<OrderType>>> => {
  try {
    const response = await api.post(`/orders`, orderData);
    return response;
  } catch (error) {
    console.error("Error creating order:", error);
    throw error;
  }
};

// Update an existing order (Admin only)
export const updateOrder = async (orderId: string, updateData: Partial<OrderType>): Promise<AxiosResponse<ApiResponse<OrderType>>> => {
  try {
    const response = await api.put(`/orders/${orderId}`, updateData);
    return response;
  } catch (error) {
    console.error(`Error updating order ID ${orderId}:`, error);
    throw error;
  }
};

// Partially update an order (Admin only)
export const patchOrder = async (orderId: string, updateData: Partial<OrderType>): Promise<AxiosResponse<ApiResponse<OrderType>>> => {
  try {
    const response = await api.patch(`/orders/${orderId}`, updateData);
    return response;
  } catch (error) {
    console.error(`Error patching order ID ${orderId}:`, error);
    throw error;
  }
};

// Update order shipping status (Admin only)
export const updateOrderShippingStatus = async (orderId: string, shippingStatus: string): Promise<AxiosResponse<ApiResponse<OrderType>>> => {
  try {
    const response = await api.put(`/orders/${orderId}`, { shippingStatus });
    return response;
  } catch (error) {
    console.error(`Error updating shipping status for order ID ${orderId}:`, error);
    throw error;
  }
};
