import { HeroSectionComponentProps } from "./HeroSectionComponentProps";

const defaultHeroSectionProps: HeroSectionComponentProps = {
    title: 'Discover the Future of Tech',
    text: 'Upgrade your life with the latest gadgets and cutting-edge technology. Shop now for exclusive deals on smartphones, laptops, smart home devices, and more.',
    imageUrl: '/images/hero-tech-banner.jpg',  // Replace with a valid image URL
    videoUrl: '',  // Optionally add a video URL if available
    buttonText: 'Shop Now',
    buttonUrl: '/shop/tech',
    layout: 'left',  // Default layout with content on the left and image on the right
  };
  
  export default defaultHeroSectionProps;