import React from 'react'
import styles from '../Component.module.scss';
import BlogSectionV1 from '../../UIElements/BlogSection/BlogSectionV1/BlogSectionV1';
import BlogSectionV2 from '../../UIElements/BlogSection/BlogSectionV2/BlogSectionV2';
import BlogSectionV3 from '../../UIElements/BlogSection/BlogSectionV3/BlogSectionV3';
import BlogSectionV4 from '../../UIElements/BlogSection/BlogSectionV4/BlogSectionV4';

const BlogSectionComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <BlogSectionV1/>
        </div>
        <div className={styles.element}>
            <BlogSectionV2/>
        </div>
        <div className={styles.element}>
            <BlogSectionV3/>
        </div>
        <div className={styles.element}>
            <BlogSectionV4/>
        </div>
    </div>
  )
}

export default BlogSectionComponentPage