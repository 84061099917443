import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { CancellationType } from '../../../types/Data/CancellationType';
import exempleCancellations from './dataCancellations';
import {
    createCancellation,
    getAllCancellations,
    getCancellationById,
    updateCancellationById,
    deleteCancellationById,
    updateCancellationStatus,
} from '../../../api/cancellation/cancellationApi';;  // Adjust the path as necessary

interface CancellationsState {
    cancellations: Partial<CancellationType>[];
    loading: boolean;
    error: string | null;
}

const initialState: CancellationsState = {
    cancellations: exempleCancellations,
    loading: false,
    error: null,
};

// Thunks for async operations
export const fetchAllCancellations = createAsyncThunk(
    'cancellations/fetchAllCancellations',
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await getAllCancellations(params);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchCancellationById = createAsyncThunk(
    'cancellations/fetchCancellationById',
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await getCancellationById(id);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const createNewCancellation = createAsyncThunk(
    'cancellations/createNewCancellation',
    async (newCancellation: Partial<CancellationType>, { rejectWithValue }) => {
        try {
            const response = await createCancellation(newCancellation);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateCancellationThunk = createAsyncThunk(
    'cancellations/updateCancellation',
    async ({ id, updateData }: { id: string, updateData: Partial<CancellationType> }, { rejectWithValue }) => {
        try {
            const response = await updateCancellationById(id, updateData);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const deleteCancellationThunk = createAsyncThunk(
    'cancellations/deleteCancellation',
    async (id: string, { rejectWithValue }) => {
        try {
            await deleteCancellationById(id);
            return id;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateCancellationStatusThunk = createAsyncThunk(
    'cancellations/updateCancellationStatus',
    async ({ id, status }: { id: string, status: string }, { rejectWithValue }) => {
        try {
            const response = await updateCancellationStatus(id, status);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

const cancellationsSlice = createSlice({
    name: 'cancellation',
    initialState,
    reducers: {
        addCancellation(state, action: PayloadAction<CancellationType>) {
            state.cancellations.push(action.payload);
        },
        updateCancellation(state, action: PayloadAction<CancellationType>) {
            const index = state.cancellations.findIndex(cancellation => cancellation._id === action.payload._id);
            if (index !== -1) {
                state.cancellations[index] = action.payload;
            }
        },
        deleteCancellation(state, action: PayloadAction<string>) {
            state.cancellations = state.cancellations.filter(cancellation => cancellation._id !== action.payload);
        },
        loadCancellation(state, action: PayloadAction<CancellationType[]>) {
            state.cancellations = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCancellations.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllCancellations.fulfilled, (state, action: PayloadAction<{ cancellations: Partial<CancellationType>[]; total: number; page: number; pages: number }>) => {
                state.loading = false;
                state.cancellations = action.payload.cancellations as CancellationType[];
            })
            .addCase(fetchAllCancellations.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })

            .addCase(fetchCancellationById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCancellationById.fulfilled, (state, action: PayloadAction<CancellationType>) => {
                state.loading = false;
                const index = state.cancellations.findIndex(cancellation => cancellation._id === action.payload._id);
                if (index !== -1) {
                    state.cancellations[index] = action.payload;
                } else {
                    state.cancellations.push(action.payload);
                }
            })
            .addCase(fetchCancellationById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })

            .addCase(createNewCancellation.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createNewCancellation.fulfilled, (state, action: PayloadAction<CancellationType>) => {
                state.loading = false;
                state.cancellations.push(action.payload);
            })
            .addCase(createNewCancellation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })

            .addCase(updateCancellationThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateCancellationThunk.fulfilled, (state, action: PayloadAction<CancellationType>) => {
                state.loading = false;
                const index = state.cancellations.findIndex(cancellation => cancellation._id === action.payload._id);
                if (index !== -1) {
                    state.cancellations[index] = action.payload;
                }
            })
            .addCase(updateCancellationThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })

            .addCase(deleteCancellationThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteCancellationThunk.fulfilled, (state, action: PayloadAction<string>) => {
                state.loading = false;
                state.cancellations = state.cancellations.filter(cancellation => cancellation._id !== action.payload);
            })
            .addCase(deleteCancellationThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })

            .addCase(updateCancellationStatusThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateCancellationStatusThunk.fulfilled, (state, action: PayloadAction<CancellationType>) => {
                state.loading = false;
                const index = state.cancellations.findIndex(cancellation => cancellation._id === action.payload._id);
                if (index !== -1) {
                    state.cancellations[index].status = action.payload.status;
                }
            })
            .addCase(updateCancellationStatusThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const {
    addCancellation,
    updateCancellation,
    deleteCancellation,
    loadCancellation,
} = cancellationsSlice.actions;

export default cancellationsSlice.reducer;
