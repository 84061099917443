import { AxiosResponse } from "axios";
import { SupportTicketType } from "../../types/Data/SupportTicketType";
import api from "../config";
import { ApiResponse } from "../apiType";

export async function createTicket(ticketData:SupportTicketType):Promise<AxiosResponse<ApiResponse<SupportTicketType>>> {
    try {
        const response = await api.post('/tickets', ticketData);
        return response.data;
    } catch (error) {
        console.error('Error creating ticket:', error);
        throw error;
    }
};

export async function updateTicket(id:string, updateData:Partial<SupportTicketType>):Promise<AxiosResponse<ApiResponse<SupportTicketType>>> {
    try {
        const response = await api.put(`/tickets/${id}`, updateData);
        return response.data;
    } catch (error) {
        console.error(`Error updating ticket with ID: ${id}`, error);
        throw error;
    }
};

export async function getTicketById(id:string):Promise<AxiosResponse<ApiResponse<SupportTicketType>>> {
    try {
        const response = await api.get(`/tickets/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching ticket with ID: ${id}`, error);
        throw error;
    }
};

export async function deleteTicket(id:string):Promise<AxiosResponse<ApiResponse<null>>> {
    try {
        const response = await api.delete(`/tickets/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error deleting ticket with ID: ${id}`, error);
        throw error;
    }
};

export async function getAllTickets(queryParams = {}):Promise<AxiosResponse<ApiResponse<SupportTicketType[]>>> {
    try {
        const response = await api.get('/tickets', { params: queryParams });
        return response.data;
    } catch (error) {
        console.error('Error fetching tickets:', error);
        throw error;
    }
};




