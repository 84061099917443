import React, { InputHTMLAttributes, useState } from 'react';
import styles from './SearchBarV1.module.scss';
import { SearchBarComponentProps } from '../SearchBarComponentProps';

const SearchBarV1: React.FC<SearchBarComponentProps> = ({ onSearch, ...props }) => {
  const [query, setQuery] = useState<string>('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    onSearch && onSearch(query);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className={styles.search_bar}>
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        className={styles.search_input}
        {...props}
      />
      <button onClick={handleSearch} className={styles.search_button}>Search</button>
    </div>
  );
};

export default SearchBarV1;