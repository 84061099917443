import SocialMediaLinksV1 from "../../UIElements/SocialMediaLinks/SocialMediaLinksV1/SocialMediaLinksV1";
import SocialMediaLinksV2 from "../../UIElements/SocialMediaLinks/SocialMediaLinksV2/SocialMediaLinksV2";
import SocialMediaLinksV3 from "../../UIElements/SocialMediaLinks/SocialMediaLinksV3/SocialMediaLinksV3";

enum SocialMediaLinksVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const SocialMediaLinksMapping:Record<SocialMediaLinksVersions, React.FC<any>> = {
    [SocialMediaLinksVersions.V1]: SocialMediaLinksV1,
    [SocialMediaLinksVersions.V2]: SocialMediaLinksV2,
    [SocialMediaLinksVersions.V3]: SocialMediaLinksV3,
};

interface SocialMediaLinksComponentProps {
    
};

export {
    SocialMediaLinksMapping,
    SocialMediaLinksVersions,
};

export type {
    SocialMediaLinksComponentProps,
};