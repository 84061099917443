import React from 'react'
import styles from '../Component.module.scss';
import ButtonAddToCartV1 from '../../UIElements/Button/ButtonAddToCart/ButtonAddToCartV1/ButtonAddToCartV1';
import ButtonAddToCartV2 from '../../UIElements/Button/ButtonAddToCart/ButtonAddToCartV2/ButtonAddToCartV2';

const ButtonAddToCartComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <ButtonAddToCartV1/>
        </div>
        <div className={styles.element}>
            <ButtonAddToCartV2/>
        </div>
    </div>
  )
}

export default ButtonAddToCartComponentPage