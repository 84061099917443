import axios, { AxiosResponse } from "axios";
import { ReturnRequestType } from "../../types/Data/ReturnRequestType";
import { ApiResponse } from "../apiType";
import api from "../config";

interface ReturnRequestQueryParams {
    search?: string;
    status?: string;
    page?: number;
    limit?: number;
    sortBy?: string;
    sortOrder?: 'asc' | 'desc';
    customer?: string;
}

// Create a New Return Request
export const createReturnRequest = async (
    payload: Partial<ReturnRequestType>
): Promise<AxiosResponse<ApiResponse<ReturnRequestType>>> => {
    try {
        const response = await api.post<ApiResponse<ReturnRequestType>>('/return-requests', payload);
        return response;
    } catch (error) {
        console.error("Error creating return request:", error);
        throw error;
    }
};

// Get a Return Request by ID
export const getReturnRequestById = async (
    id: string
): Promise<AxiosResponse<ApiResponse<ReturnRequestType>>> => {
    try {
        const response = await api.get<ApiResponse<ReturnRequestType>>(`/return-requests/${id}`);
        return response;
    } catch (error) {
        console.error(`Error fetching return request with ID ${id}:`, error);
        throw error;
    }
};

// Get All Return Requests with Optional Filters, Pagination, and Sorting
export const getAllReturnRequests = async (
    params: ReturnRequestQueryParams
): Promise<AxiosResponse<ApiResponse<{ returnRequests: ReturnRequestType[]; total: number; pages: number; page: number }>>> => {
    try {
        const response = await api.get<ApiResponse<{ returnRequests: ReturnRequestType[]; total: number; pages: number; page: number }>>('/return-requests', { params });
        return response;
    } catch (error) {
        console.error("Error fetching all return requests:", error);
        throw error;
    }
};

// Update a Return Request by ID
export const updateReturnRequestById = async (
    id: string,
    payload: Partial<ReturnRequestType>
): Promise<AxiosResponse<ApiResponse<ReturnRequestType>>> => {
    try {
        const response = await api.put<ApiResponse<ReturnRequestType>>(`/return-requests/${id}`, payload);
        return response;
    } catch (error) {
        console.error(`Error updating return request with ID ${id}:`, error);
        throw error;
    }
};

// Delete a Return Request by ID
export const deleteReturnRequestById = async (
    id: string
): Promise<AxiosResponse<ApiResponse<null>>> => {
    try {
        const response = await api.delete<ApiResponse<null>>(`/return-requests/${id}`);
        return response;
    } catch (error) {
        console.error(`Error deleting return request with ID ${id}:`, error);
        throw error;
    }
};

// Add a Chat Message to a Return Request
export const addChatToReturnRequest = async (
    id: string,
    chatId: string
): Promise<AxiosResponse<ApiResponse<ReturnRequestType>>> => {
    try {
        const response = await api.patch<ApiResponse<ReturnRequestType>>(`/return-requests/${id}/chats`, { chatId });
        return response;
    } catch (error) {
        console.error(`Error adding chat to return request with ID ${id}:`, error);
        throw error;
    }
};

// Update the Status of a Return Request
export const updateReturnRequestStatus = async (
    id: string,
    status: string
): Promise<AxiosResponse<ApiResponse<ReturnRequestType>>> => {
    try {
        const response = await api.patch<ApiResponse<ReturnRequestType>>(`/return-requests/${id}/status`, { status });
        return response;
    } catch (error) {
        console.error(`Error updating status of return request with ID ${id}:`, error);
        throw error;
    }
};

// Partially Update a Return Request
export const patchReturnRequest = async (
    returnRequestId: string,
    updateData: Partial<ReturnRequestType>
): Promise<ApiResponse<ReturnRequestType>> => {
    try {
        const response = await api.patch<ApiResponse<ReturnRequestType>>(`/return-requests/${returnRequestId}`, updateData);
        return response.data;
    } catch (error) {
        console.error(`Error patching return request with ID ${returnRequestId}:`, error);
        throw error;
    }
};
