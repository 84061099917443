import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { CustomerFeedbackType } from '../../../types/Data/feedback';
import exampleFeedbacks from './exempleFeedbacks';
import {
    getAllFeedbacks,
    createFeedback,
    updateFeedbackById,
    deleteFeedbackById,
} from '../../../api/customerFeedback/customerFeedbackApi';

interface CustomerFeedbackState {
    customerFeedbacks: CustomerFeedbackType[];
    loading: boolean;
    error: string | null;
}

const initialState: CustomerFeedbackState = {
    customerFeedbacks: exampleFeedbacks,
    loading: false,
    error: null,
};

// Thunk to fetch all feedbacks
export const fetchAllFeedbacks = createAsyncThunk(
    'feedback/fetchAll',
    async (params: any, { rejectWithValue }) => {
        try {
            const data = await getAllFeedbacks(params);
            return data.feedbacks;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to load feedbacks');
        }
    }
);

// Thunk to create a new feedback
export const createNewFeedback = createAsyncThunk(
    'feedback/create',
    async (feedbackData: Partial<CustomerFeedbackType>, { rejectWithValue }) => {
        try {
            const data = await createFeedback(feedbackData);
            return data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to create feedback');
        }
    }
);

// Thunk to update feedback by ID
export const updateExistingFeedback = createAsyncThunk(
    'feedback/update',
    async ({ feedbackId, feedbackData }: { feedbackId: string, feedbackData: Partial<CustomerFeedbackType> }, { rejectWithValue }) => {
        try {
            const data = await updateFeedbackById(feedbackId, feedbackData);
            return data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to update feedback');
        }
    }
);

// Thunk to delete feedback by ID
export const deleteExistingFeedback = createAsyncThunk(
    'feedback/delete',
    async (feedbackId: string, { rejectWithValue }) => {
        try {
            await deleteFeedbackById(feedbackId);
            return feedbackId;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to delete feedback');
        }
    }
);

const feedbackSlice = createSlice({
    name: 'feedback',
    initialState,
    reducers: {
        addFeedback(state, action: PayloadAction<CustomerFeedbackType>) {
            state.customerFeedbacks.push(action.payload);
        },
        updateFeedback(state, action: PayloadAction<CustomerFeedbackType>) {
            const index = state.customerFeedbacks.findIndex(feedback => feedback.id === action.payload.id);
            if (index !== -1) {
                state.customerFeedbacks[index] = action.payload;
            }
        },
        deleteFeedback(state, action: PayloadAction<string>) {
            state.customerFeedbacks = state.customerFeedbacks.filter(feedback => feedback.id !== action.payload);
        },
        loadFeedbacks(state, action: PayloadAction<CustomerFeedbackType[]>) {
            state.customerFeedbacks = action.payload;
        }
    },
    extraReducers: (builder) => {
        // Fetch all feedbacks
        builder.addCase(fetchAllFeedbacks.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchAllFeedbacks.fulfilled, (state, action: PayloadAction<CustomerFeedbackType[]>) => {
            state.customerFeedbacks = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchAllFeedbacks.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });

        // Create new feedback
        builder.addCase(createNewFeedback.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(createNewFeedback.fulfilled, (state, action: PayloadAction<CustomerFeedbackType>) => {
            state.customerFeedbacks.push(action.payload);
            state.loading = false;
        });
        builder.addCase(createNewFeedback.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });

        // Update existing feedback
        builder.addCase(updateExistingFeedback.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateExistingFeedback.fulfilled, (state, action: PayloadAction<CustomerFeedbackType>) => {
            const index = state.customerFeedbacks.findIndex(feedback => feedback.id === action.payload.id);
            if (index !== -1) {
                state.customerFeedbacks[index] = action.payload;
            }
            state.loading = false;
        });
        builder.addCase(updateExistingFeedback.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });

        // Delete feedback
        builder.addCase(deleteExistingFeedback.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteExistingFeedback.fulfilled, (state, action: PayloadAction<string>) => {
            state.customerFeedbacks = state.customerFeedbacks.filter(feedback => feedback.id !== action.payload);
            state.loading = false;
        });
        builder.addCase(deleteExistingFeedback.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
    }
});

export const { addFeedback, updateFeedback, deleteFeedback, loadFeedbacks } = feedbackSlice.actions;
export default feedbackSlice.reducer;
