import { ChartType } from "chart.js";
import { CustomerType } from "./CustomerType";
import { OrderType } from "./OrderType";
import { ProductType } from "./ProductType";
import { MessageType } from "./MessageType";

// Enum for Return Status
enum ReturnStatus {
    Pending = 'Pending',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Returned = 'Returned',
}

// Enum for Return Reason
enum ReturnReason {
    DefectiveProduct = 'Defective Product',
    IncorrectProduct = 'Incorrect Product',
    NoLongerNeeded = 'No Longer Needed',
    Other = 'Other',
}

// Enum for Refund Eligibility
enum RefundEligibility {
    Eligible = 'Eligible',
    NotEligible = 'Not Eligible',
}

// Enum for Item Condition
enum ReturnItemCondition {
    NotReturned = 'Not Returned',
    New = 'New',
    LikeNew = 'Like New',
    Used = 'Used',
    Damaged = 'Damaged',
    Defective = 'Defective',
}

// Enum for Admin Approval Status
enum ReturnAdminApprovalStatus {
    Pending = 'Pending',
    Approved = 'Approved',
    Rejected = 'Rejected',
}

// Interface for Shipment Information
interface ReturnShipmentInfoType {
    shippingCarrier: string;
    trackingNumber: string;
    shippingMethod: string;
    shippingDate?: Date; // Optional since shipment may not have occurred yet
}

// Interface for Product Item in the Order
interface ReturnOrderItemType {
    product: string| Partial<ProductType>;
    productName: string;
    quantity: number;
    price: number;
    isReturnable: boolean; // Indicates if the item can be returned
    refundRequested: boolean; // Indicates if a refund is requested
    refundEligibility: RefundEligibility; // Indicates if a refund is possible
    exchangeEligible: boolean; // Indicates if the item is eligible for exchange
    refundAmount?: number;
    conditionAfterReturn?: ReturnItemCondition; // Condition of the item after return
    returnReason?: ReturnReason; // Reason for the return, per item
    adminApprovalStatus: ReturnAdminApprovalStatus; // Approval status for the return
}

// Interface for Return Request with Additional Fields
interface ReturnRequestType {
    _id:  string;
    order: string | Partial<OrderType>;
    customer:  string | Partial<CustomerType>;
    customerName: string;
    items: ReturnOrderItemType[]; // Array of items with return details
    status: ReturnStatus; // Overall status of the return request
    shipmentInfo?: ReturnShipmentInfoType; // Optional shipment information
    refundRequestId?: string; // Link to a Refund Request, if it exists
    lastUpdatedByAdminId?: string; // ID of the admin who last updated the status
    adminNotes?: string; // Any notes from the admin regarding the return
    chats?: string | MessageType[];
    createdAt: Date;
    updatedAt: Date;    
}

export {
    ReturnStatus,
    ReturnReason,
    RefundEligibility,
    ReturnItemCondition,
    ReturnAdminApprovalStatus
};

export type {
    ReturnShipmentInfoType,
    ReturnOrderItemType,
    ReturnRequestType
};
