import React, { useEffect, useState } from 'react';
import styles from './CustomerInfoForm.module.scss';
import { CustomerType } from '../../../../types/Data/CustomerType';
import InputV4 from '../../../../components/Input/InputText/InputV4/InputV4';

interface CustomerInfoFormProps {
    onCustomerInfoChange: (customerInfo: Partial<CustomerType>) => void;
}

const CustomerInfoForm: React.FC<CustomerInfoFormProps> = ({ onCustomerInfoChange = () => null }) => {
    const [customerInfo, setCustomerInfo] = useState<Partial<CustomerType>>({
        name: '',
        email: '',
        phone: '',
    });

    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCustomerInfo(prev => ({ ...prev, [name]: value }));
    };

    const validate = () => {
        const newErrors: { [key: string]: string } = {};
        if (!customerInfo.name) newErrors.firstName = 'Name is required';
        if (!customerInfo.email) newErrors.email = 'Email is required';
        if (!customerInfo.phone) newErrors.phone = 'Phone number is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    useEffect(() => {
        if (validate()) {
            onCustomerInfoChange(customerInfo)
        };
    }, [customerInfo, onCustomerInfoChange]);

    return (
        <div className={styles.customerInfoForm}>
            <h4>Customer Information</h4>
            
            <div className={styles.formGroup}>
                <InputV4
                    label='Name'
                    type="text"
                    id="name"
                    name="name"
                    value={customerInfo.name || ''}
                    onChange={handleChange}
                />
                {errors.lastName && <span className={styles.error}>{errors.lastName}</span>}
            </div>
            <div className={styles.formGroup}>
                <InputV4
                    label="Email"
                    type="email"
                    id="email"
                    name="email"
                    value={customerInfo.email || ''}
                    onChange={handleChange}
                />
                {errors.email && <span className={styles.error}>{errors.email}</span>}
            </div>
            <div className={styles.formGroup}>
                <InputV4
                    label="Phone"
                    type="tel"
                    id="phone"
                    name="phone"
                    value={customerInfo.phone || ''}
                    onChange={handleChange}
                />
                {errors.phone && <span className={styles.error}>{errors.phone}</span>}
            </div>
        </div>
    );
};

export default CustomerInfoForm;
