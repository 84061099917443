import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../Store/store';

import Input4 from '../../../components/Input/InputText/InputV4/InputV4';
import TextArea1 from '../../../components/TextArea/TextAreaV1/TextAreaV1';
import Button1 from '../../../components/Button/ButtonV1/ButtonV1';
import styles from './ShopSettings.module.scss';
import { GeneralSettingsType } from '../../../types/Data/ShopSettingsType';
import { dispatchLoadSettings, dispatchUpdateSettings, fetchShopSettings } from '../../DashboardState/shopSettings/shopSettingsSlice';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import SaveButton from '../../../components/Button/SaveButton/SaveButton';
import ButtonCancel from '../../../components/Button/ButtonCancel/ButtonCancel';
import { upsertShopSettings } from '../../../api/generalSettings/generalSettingsApi';
import { handleApiError } from '../../../api/apiError';
import exampleDataShopSettings from '../../DashboardState/shopSettings/exampleDataShopSettings';

const ShopSettings: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { settings, loading, error } = useSelector((state: RootState) => state.dashboard.shopSettings ) ;
  const [currentSettings, setCurrentSettings] = useState<GeneralSettingsType>(settings);


  const [isSaving, setIsSaving] = useState<boolean>(false);
  /*
  useEffect(()=>{
    dispatch(fetchShopSettings())
  }, [])
  */

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setCurrentSettings({
      ...currentSettings,
      [name]: value,
    });
  };

  const handleArrayChange = (name: string, value: string) => {
    setCurrentSettings({
      ...currentSettings,
      [name]: value.split(',').map(item => item.trim()),
    });
  };

  const handleFileSelect = (name: string, file: File) => {
    // Assume FileUpload1 returns the file URL
    const fileUrl = URL.createObjectURL(file);
    setCurrentSettings({
      ...currentSettings,
      [name]: fileUrl,
    });
  };


  const handleUpdateShopSettings = async () => {
    setIsSaving(true);
    try {
      const response = await upsertShopSettings(currentSettings);
      if (response.status === 201 || response.status === 200) {
        dispatch(dispatchUpdateSettings(response.data.data))
        alert('Shop settings updated!')
      } else {
        alert('Error occured while updating shop settings')
      }
    } catch(error:any) {
      const handledError = handleApiError(error);
      alert(handledError.message)
    } finally {
      setIsSaving(false)
    }
  }

  const handleCancel = () => {
    setCurrentSettings(settings)
  };

  if (loading) return <LoadingIndicatorV1/>
  
  if (error) return <ErrorBannerV1 message={error}/>
 
  return (
    <div className={styles.settings_form}>
      <h2>General Settings</h2>

      <div className={styles.form_group}>
        <Input4
          type="text"
          id="shopName"
          name="shopName"
          value={currentSettings?.shopName}
          onChange={handleChange}
          required
          label='Shop Name'
        />
      </div>

      <div className={styles.form_group}>
        <TextArea1
          id="shopDescription"
          name="shopDescription"
          value={currentSettings?.shopDescription}
          onChange={handleChange}
          label='Shop Description'
        />
      </div>

      <div className={styles.form_group}>
      <Input4
          type="url"
          id="shopLogo"
          name="shopLogo"
          value={currentSettings?.shopLogo}
          onChange={handleChange}
          required
          label='Shop Logo URL'
        />
      </div>

      <div className={styles.form_group}>
        <Input4
          type="email"
          id="contactEmail"
          name="contactEmail"
          value={currentSettings?.contactEmail}
          onChange={handleChange}
          required
          label='Contact Email'
        />
      </div>

      <div className={styles.form_group}>
        <Input4
          type="tel"
          id="contactPhone"
          name="contactPhone"
          value={currentSettings?.contactPhone}
          onChange={handleChange}
          label="Contact Phone Number"
        />
      </div>

      <div className={styles.form_group}>
        <TextArea1
          id="address"
          name="address"
          value={currentSettings?.address}
          onChange={handleChange}
          label='Address'
        />
      </div>

      <div className={styles.form_group}>
        <Input4
          type="text"
          id="defaultCurrency"
          name="defaultCurrency"
          value={currentSettings?.defaultCurrency}
          onChange={handleChange}
          label='Default Currency'
        />
      </div>

      <div className={styles.form_group}>
        <Input4
          type="text"
          id="supportedCurrencies"
          name="supportedCurrencies"
          value={currentSettings?.supportedCurrencies.join(', ')}
          onChange={(e) => handleArrayChange('supportedCurrencies', e.target.value)}
          label='Supported Currencies (comma separated)'
        />
      </div>

      <div className={styles.form_group}>
        <Input4
          type="text"
          id="timeZone"
          name="timeZone"
          value={currentSettings?.timeZone}
          onChange={handleChange}
          label='Time Zone'
        />
      </div>

      <div className={styles.form_group}>
        <Input4
          type="text"
          id="language"
          name="language"
          value={currentSettings?.language}
          onChange={handleChange}
          label='Language'
        />
      </div>

      <div className={styles.form_group}>
        <Input4
          type="email"
          id="emailFromAddress"
          name="emailFromAddress"
          value={currentSettings?.emailFromAddress}
          onChange={handleChange}
          label='Email From Address'
        />
      </div>

      <div className={styles.form_group}>
        <Input4
          type="text"
          id="metaTitle"
          name="metaTitle"
          value={currentSettings?.metaTitle}
          onChange={handleChange}
          label='Meta Title'
        />
      </div>

      <div className={styles.form_group}>
        <TextArea1
          id="metaDescription"
          name="metaDescription"
          value={currentSettings?.metaDescription}
          onChange={handleChange}
          label='Meta Description'
        />
      </div>

      <div className={styles.form_group}>
        <Input4
          type="text"
          id="metaKeywords"
          name="metaKeywords"
          value={currentSettings?.metaKeywords.join(', ')}
          onChange={(e) => handleArrayChange('metaKeywords', e.target.value)}
          label='Meta Keywords (comma separated)'
        />
      </div>

      <div className={styles.form_group}>
        <Input4
          type="text"
          id="analyticsTrackingId"
          name="analyticsTrackingId"
          value={currentSettings?.metaKeywords.join(', ')}
          onChange={(e) => handleArrayChange('analyticsTrackingId', e.target.value)}
          label='Analytics Tracking Id'
        />
      </div>
      <SaveButton onClick={handleUpdateShopSettings} isSaving/>
      <ButtonCancel onClick={handleCancel} style={{marginLeft: 15}}/>
    </div>
  );
};

export default ShopSettings;
