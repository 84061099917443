import React from 'react';
import styles from './BillingInformationFormV1.module.scss';
import { BillingInformationFormComponentProps } from '../BillingInformationFormComponentProps';

const BillingInformationFormV1: React.FC<BillingInformationFormComponentProps> = ({
  fullName = "",
  address = "",
  city = "",
  state = "",
  zipCode = "",
  country = "",
  onInputChange = () => null,
  onFormSubmit = () => null,
  sameAsShipping = false,
  onSameAsShippingChange = () => null
}) => {
  return (
    <div className={styles.billingForm}>
      <h2>Billing Information</h2>
      <form onSubmit={onFormSubmit}>
        <label>
          <input
            type="checkbox"
            checked={sameAsShipping}
            onChange={(e) => onSameAsShippingChange?.(e.target.checked)}
          />
          Same as Shipping Address
        </label>
        {!sameAsShipping && (
          <>
            <input
              type="text"
              placeholder="Full Name"
              value={fullName}
              onChange={(e) => onInputChange('fullName', e.target.value)}
            />
            <input
              type="text"
              placeholder="Address"
              value={address}
              onChange={(e) => onInputChange('address', e.target.value)}
            />
            <input
              type="text"
              placeholder="City"
              value={city}
              onChange={(e) => onInputChange('city', e.target.value)}
            />
            <input
              type="text"
              placeholder="State"
              value={state}
              onChange={(e) => onInputChange('state', e.target.value)}
            />
            <input
              type="text"
              placeholder="ZIP Code"
              value={zipCode}
              onChange={(e) => onInputChange('zipCode', e.target.value)}
            />
            <input
              type="text"
              placeholder="Country"
              value={country}
              onChange={(e) => onInputChange('country', e.target.value)}
            />
          </>
        )}
        <button type="submit">Continue</button>
      </form>
    </div>
  );
};

export default BillingInformationFormV1;
