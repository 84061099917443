import React from 'react';
import styles from './DefaultFilterV1.module.scss';
import { FilterComponentProps } from '../FilterComponentProps';

const DefaultFilterV1: React.FC<FilterComponentProps<string>> = ({
    label = 'Filter', 
    options = [ "gold", "silver"], 
    selectedOptions = [], onSelect = () => null 
}) => {
  return (
    <div className={styles.filterContainer}>
      <h3>Filter by {label}</h3>
      <div className={styles.list}>
        {options.map((option, index) => (
          <div key={index} className={styles.item}>
            <input
              type="checkbox"
              id={`option-${index}`}
              name="optio,"
              value={option}
              checked={selectedOptions.includes(option)}
              onChange={() => onSelect(option)}
            />
            <label htmlFor={`option-${index}`}>{option}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DefaultFilterV1;
