import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './EmailCampaignDetailPage.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';
import EmailCampaignInfo from './EmailCampaingInfo/EmailCampaignInfo';
import EmailCampaignStats from './EmailCampaignStats/EmailCampaignStats';
import EmailCampaingHTMLContent from './EmailCampaignContent/EmailCampaingHTMLContent';
import { EmailCampaignType } from '../../../types/Data/EmailCampaignType';
import { RootState } from '../../../Store/store';

const EmailCampaignDetailPage: React.FC = () => {

  const location = useLocation();
  const campaignId = new URLSearchParams(location.search).get('campaignId');
  const campaign = useSelector<RootState>((state) => state.dashboard.emailCampaigns.campaigns.find((campaing) => campaing._id === campaignId )) as EmailCampaignType;
  
  return (
    <div className={styles.content}>
      <DashboardHeader title="Email Campaigns" />
      <div className={styles.tab}>
        <EmailCampaignInfo campaign={campaign}/>
        <EmailCampaignStats  openedCount={campaign?.openedCount} bounceCount={campaign?.bounceCount} clickedCount={campaign?.clickedCount} sentCount={campaign?.sentCount} unsubscribeCount={campaign?.unsubscribeCount}  />
        <EmailCampaingHTMLContent title={campaign?.template?.data?.title} htmlContent={campaign?.htmlContent}/>
      </div>
    </div>
  );
};

export default EmailCampaignDetailPage;
