import CustomerOrderTrackingV1 from "./CustomerOrderTrackingV1/CustomerOrderTrackingV1";

enum CustomerOrderTrackingComponentVersions {
    V1 = "v1",
};

const CustomerOrderTrackingComponentMapping:Record<CustomerOrderTrackingComponentVersions, React.FC<any>> = {
    [CustomerOrderTrackingComponentVersions.V1]: CustomerOrderTrackingV1,  
};

interface CustomerOrderTrackingComponentProps {
    currentStep?: number; // Current order status step (0 - 3)
    trackingUrl?: string; // Current url
};

export {
    CustomerOrderTrackingComponentVersions,
    CustomerOrderTrackingComponentMapping
}
export type {
    CustomerOrderTrackingComponentProps
}