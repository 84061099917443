import React from 'react';
import { EmailTemplateType } from '../../../../types/Data/EmailTemplateType';

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    animation: 'slideIn 1s ease-in-out'
  },
  header: {
    backgroundColor: '#4bc0c0',
    color: '#ffffff',
    textAlign: 'center',
    padding: '20px',
    fontSize: '24px',
    fontWeight: 'bold'
  },
  body: {
    padding: '20px',
    textAlign: 'center'
  },
  bodyH2: {
    color: '#333333',
    fontSize: '22px',
    marginBottom: '20px'
  },
  bodyP: {
    color: '#666666',
    fontSize: '16px',
    marginBottom: '30px'
  },
  ctaButton: {
    display: 'inline-block',
    padding: '10px 20px',
    backgroundColor: '#333',
    color: '#ffffff',
    textDecoration: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease, transform 0.2s ease'
  },
  ctaButtonHover: {
    backgroundColor: 'darken(#333, 10%)',
    transform: 'translateY(-2px)'
  },
  footer: {
    backgroundColor: '#f8f9fa',
    color: '#666666',
    textAlign: 'center',
    padding: '10px',
    fontSize: '14px'
  }
};

const EventInvitationEmail: React.FC<EmailTemplateType> = ({ data }) => {
  return (
    <div style={styles.container} className="slide-in-animation">
      <style>
        { `
          @keyframes slideIn {
            from { transform: translateX(-100%); }
            to { transform: translateX(0); }
        ` }
      </style>
      <div style={styles.header}>
        You're Invited!
      </div>
      <div style={styles.body}>
        { data?.body && <div dangerouslySetInnerHTML={{ __html: data?.body }} /> }
        {data?.importantLinks && data.importantLinks.map((link, index) => (
          <a key={index} href={link.url} style={styles.ctaButton}>
            {link.label}
          </a>
        ))}
      </div>
      <div style={styles.footer}>
        <p>Join us for the launch!</p>
        <p>Unsubscribe | Contact Us</p>
      </div>
    </div>
  );
};

export default EventInvitationEmail;
