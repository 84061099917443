import React, { useEffect, useState } from 'react';
import styles from '../../UIElementsMainSettings/UiElementEditor.module.scss';
import { BaseElementType, ComponentType } from '../../../types/Data/BaseElementType';
import ButtonV1 from '../../../components/Button/ButtonV1/ButtonV1';
import UIElementsMainSettings from '../../UIElementsMainSettings/UIElementsMainSettings';
import { StepIndicatorComponentProps, StepIndicatorMapping, StepIndicatorVersions } from '../StepIndicatorComponentProps';
import ButtonDelete from '../../../components/Button/ButtonDelete/ButtonDelete';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../Store/store';
import { fetchCreateElement, fetchUpdateElement } from '../../../Dashboard/DashboardState/element/elementSlice';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';

interface StepIndicatorEditorProps {
  element?: BaseElementType<StepIndicatorComponentProps>;
}

const StepIndicatorComponentEditor: React.FC<StepIndicatorEditorProps> = ({ element }) => {
  
  const defaultElement: BaseElementType<StepIndicatorComponentProps> = {
    type: ComponentType.StepIndicator,
    version: 'v1',
    page: 'shippingBilling',
    props: {},
    position: undefined,
    isVisible: true,
  };

  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state:RootState)=>state.dashboard.elements.loading);
  const error = useSelector((state:RootState)=>state.dashboard.elements.error);

  const [currentElement, setCurrentElement] = useState<BaseElementType<StepIndicatorComponentProps>>(element ? element : defaultElement);

  useEffect(() => {
    if (element) {
      setCurrentElement(element);
    } else {
      setCurrentElement(defaultElement);
    }
  }, [element]);
  
  const handleElementUpdate = (key: string, value: any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      [key]: value,
    }));
  };

  const handleElementVisibilityUpdate = () => {
    setCurrentElement((prevElement) => ({ ...prevElement, isVisible: !prevElement.isVisible }));
  };

  const handlePropsChange = (key:string, value:any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      props: {
        ...prevElement.props,
        [key]: value,
      }
    }));
  };

  const handleSaveElement = () => {
    if (currentElement?._id) {
      dispatch(fetchUpdateElement({id:currentElement._id, element:currentElement}))
    } else {
      dispatch(fetchCreateElement(currentElement))
    }
  };

  const SelectedComponent = StepIndicatorMapping[currentElement.version as StepIndicatorVersions] || StepIndicatorMapping[StepIndicatorVersions.V1];

  const elementsVersion = Object.keys(StepIndicatorVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: StepIndicatorVersions[key as keyof typeof StepIndicatorVersions],
  }));

  return (
    <div className={styles.container}> 
    { error && <ErrorBannerV1 message={error}/>}
    { loading && <LoadingIndicatorV1/>}
      <div className={styles.componentPreview}>
        <SelectedComponent {...currentElement.props}/>
      </div>    
      <div className={styles.componentEditor}>
        <UIElementsMainSettings
            element={currentElement}
            elementVersions={elementsVersion}
            onElementUpdate={handleElementUpdate}
            onVisibilitytUpdate={handleElementVisibilityUpdate}
        />
        </div>
        <div className={styles.buttonContainer}>
        <ButtonV1 label="Save Element" onClick={handleSaveElement} />
        <ButtonDelete/>
      </div>
    </div>
  );
};

export default StepIndicatorComponentEditor;
