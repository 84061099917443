import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupportTicketType } from '../../../types/Data/SupportTicketType';
import { exempleDataTickets } from './exempleDataTickets';
import { getAllTickets } from '../../../api/ticket/ticketApi';

// Async Thunks
export const fetchAllSupportTickets = createAsyncThunk(
  'refund/fetchAllSupportTickets',
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await getAllTickets(params);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

interface TicketState {
    tickets: SupportTicketType[];
    loading: boolean,
    error: string | null;
}

const initialState: TicketState = {
    tickets: [...exempleDataTickets],
    loading: false,
    error: null,
};

const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    dispatchAddTicket: (state, action: PayloadAction<SupportTicketType>) => {
      state.tickets.push(action.payload);
    },
    dispatchUpdateTicket: (state, action: PayloadAction<SupportTicketType>) => {
      const index = state.tickets.findIndex(t => t._id === action.payload._id);
      if (index !== -1) {
        state.tickets[index] = action.payload;
      }
    },
    dispatchRemoveTicket: (state, action: PayloadAction<string>) => {
      state.tickets = state.tickets.filter(t => t._id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSupportTickets.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllSupportTickets.fulfilled, (state, action: PayloadAction<SupportTicketType[]>) => {
        state.loading = false;
        state.tickets = action.payload;
      })
      .addCase(fetchAllSupportTickets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
    }
});

export const { dispatchAddTicket, dispatchUpdateTicket, dispatchRemoveTicket } = ticketsSlice.actions;
export default ticketsSlice.reducer;
