// src/App.tsx
import React from 'react';
import FeaturesSection1 from '../UIElements/FeatureSection/FeatureSectionV1/FeatureSectionV1';

import HeroSectionV1 from '../UIElements/HeroSection/HeroSectionV1/HeroSectionV1';
import { BannerSlideType } from '../types/Data/BannerSlideType';
import { IncentiveComponentProps } from '../UIElements/Incentive/IncentiveComponentProps';
import IncentiveV5 from '../UIElements/Incentive/IncentiveV5/IncentiveV5';
import PricingV1 from '../components/Pricing/PricingV1/PricingV1';
import { PricingCardProps } from '../components/Pricing/PricingCardProps';
import { FaBolt, FaCode, FaCogs, FaDatabase, FaEnvelope, FaKey, FaLayerGroup, FaLock, FaPaintBrush, FaServer, FaShieldAlt } from 'react-icons/fa';
import FeaturesSectionV2 from '../UIElements/FeatureSection/FeatureSectionV2/FeatureSectionV2';
import Component from '../ComponentPage/Component';
import HeroBannerV6 from '../UIElements/HeorBanner/HeroBannerV6/HeroBannerV6';
import { HeroBannerComponentProps } from '../UIElements/HeorBanner/HeroBannerComponentProps';


const banner: HeroBannerComponentProps = {
  slides: [
    { 
      imageUrl: 'https://cdn.dribbble.com/userupload/11673563/file/original-0737b99a1ceba360f120123a92dce4ad.png?resize=2048x1536', 
      title: 'Transform Your Vision into a Powerful Online Store', 
      text: "Bring your e-commerce dream to life with our fully customizable and responsive solutions. Whether you need a sleek storefront, seamless integrations, or unique features, we deliver tailored websites that enhance your brand and drive sales. Let's create a shopping experience your customers will love."
    },
    { 
      imageUrl: 'https://cdn.dribbble.com/userupload/16039320/file/original-7a9fbf0ad9c753724c441dd192158bb9.png?resize=2048x1536', 
      title: 'Empower Your Development with Our E-Commerce Library', 
      text: "Why start from scratch? Our robust e-commerce library provides a reusable Node.js server, pre-built components, and seamless integrations that allow developers to build modern, feature-rich online stores quickly. Save time, reduce errors, and focus on delivering a polished product that exceeds your expectations."
    },
    { 
      imageUrl: 'https://cdn.dribbble.com/userupload/14628741/file/original-10ce19ad0527352fdf551cbc2ab042ef.jpg?resize=2048x1540', 
      title: 'Your Partner in Building Scalable E-Commerce Solutions', 
      text: "From concept to launch, our library provides the foundation for scalable, high-performance e-commerce platforms. With a focus on modularity and flexibility, our tools ensure that your online store grows effortlessly with your business."
    },
    { 
      imageUrl: 'https://cdn.dribbble.com/userupload/15511990/file/original-4908814bb9d9bcb3056eac56f1ce17c3.png?resize=1504x1128',
      title: 'Accelerate Your E-Commerce Setup with Our Dashboard', 
      text: "Our intuitive dashboard, included in the library, empowers you to configure your e-commerce store effortlessly. With real-time analytics, inventory management, and seamless integration capabilities, you can focus on growing your business while leveraging our cutting-edge tools."
    }
  ]
}


const heroSection1: BannerSlideType = {
  title: "Master Your E-Commerce Operations with Our Admin Dashboard",
  text: "Our powerful and intuitive admin dashboard allows you to handle all major operations in your e-commerce website with ease. From managing inventory and processing orders to analyzing sales data and optimizing customer experiences, our dashboard provides everything you need to run a successful online store.",
  imageUrl: "./dashboard-preview.png",
  buttonText: "Discover the Dashboard",
  buttonUrl: './dashboard',
}

const heroSection2: BannerSlideType = {
  title: "Create Your Custom E-Commerce Store with Ease",
  text: "Leverage our powerful admin dashboard to generate a fully customizable e-commerce store by selecting the components you need. Integrate your own components seamlessly, and benefit from strong TypeScript interfaces that ensure type-safe, modular development. Build faster and smarter with complete control over your store’s design and functionality.",
  imageUrl: "./ecommerce-preview.png",
  buttonText: "Discover the Library",
  buttonUrl: './ecommerce'
}



const incentive2:IncentiveComponentProps = {
   title: "Supercharge your app instantly, launch faster, make $",
  description: "Login users, process payments and send emails at lightspeed. Spend your time building your startup, not integrating APIs. ShipFast provides you with the boilerplate code you need to launch, FAST.",
  items: [
    { title:"Email", description:'', icon:"FaEnvelope" },
    { title: "Payement", description: '', icon:"FaCreditCard"},
    { title: "Authentication", description:'', icon: "FaUser"},
    { title: "Database", description:'', icon:"FaDatabase"},
    { title: "Style", description:'', icon: 'FaPaintBrush'},
    { title: "SEO", description:'', icon: 'FaFile'}
  ]
}


const pricingItems: PricingCardProps[] = [
  {
    title: "E-Commerce Development Library",
    price: "$200",
    features: [
      "Extensive visual component library",
      "Performance-optimized dashboard",
      "Pre-built server with Node.js functionality",
      "Accelerate your development process",
      "Detailed documentation and support",
      "Flexible and scalable architecture",
    ],
    buttonText: "Buy Now",
    buttonUrl: "/purchase-library", // Link to your purchase page for the library
  },
];


const features1 = {
  title: 'Introducing Our Cutting-Edge Front-End Library',
  description: 'Leverage modern technology and a robust, modular design to build state-of-the-art e-commerce websites. Our library offers everything you need to create a fast, secure, and highly customizable online store.',
  features: [
    {
      title: 'Modern Technologies',
      description: 'Built with React, TypeScript, and SCSS, our library ensures high performance, type safety, and maintainability.',
      icon: 'FaCode',
    },
    {
      title: 'Comprehensive Component Library',
      description: 'Access a wide range of customizable components designed to cover every aspect of an e-commerce website.',
      icon: 'FaLayerGroup',
    },
    {
      title: 'State Management with Redux',
      description: 'Handle complex state management seamlessly with built-in Redux support, ensuring your app scales effortlessly.',
      icon: 'FaCogs',
    },
    {
      title: 'Modular Architecture',
      description: 'Our modular design allows you to integrate only the components you need, making your project lightweight and efficient.',
      icon: 'FaPaintBrush',
    },
    {
      title: 'Security Focused',
      description: 'Security is at the core of our library, with features like secure authentication and data protection baked in.',
      icon: 'FaShieldAlt',
    },
    {
      title: 'Lightning Fast',
      description: 'Optimized for speed, our library ensures quick load times and a smooth user experience across all devices.',
      icon: 'FaBolt',
    },
  ],
};

const features2 = {
  title: 'Powerful Server Capabilities',
  description: 'Explore the robust features of our server built with Node.js, Express, and MongoDB, designed to scale and integrate seamlessly.',
  features: [
    {
      title: 'Node.js & Express Server',
      description: 'Efficient and fast server-side execution with Node.js and Express, ensuring seamless request handling and routing.',
      icon: 'FaServer',
    },
    {
      title: 'MongoDB Integration',
      description: 'Robust database management with MongoDB, providing high availability and scalability for your application.',
      icon: 'FaDatabase',
    },
    {
      title: 'Multiple Authentication Mechanisms',
      description: 'Secure your application with Passport.js, offering various authentication strategies including OAuth, JWT, and more.',
      icon: 'FaLock',
    },
    {
      title: 'Email Notifications',
      description: 'Send automated emails with NodeMailer, perfect for notifications, account verification, and more.',
      icon: 'FaEnvelope',
    },
    {
      title: 'Scalable Server Architecture',
      description: 'Design your server to scale with the growing needs of your application, adding new functionalities with ease.',
      icon: 'FaCogs',
    },
    {
      title: 'Token-Based Authentication',
      description: 'Secure API requests with token-based authentication, ensuring data integrity and secure communication.',
      icon: 'FaKey',
    },
  ],
};


const App: React.FC = () => {
  return (
    <div>
      <main>
        <HeroBannerV6 slides={banner.slides}/>
        <Component/>
        <HeroSectionV1 {...heroSection1} />
        <HeroSectionV1 {...heroSection2} layout='right'/>
        <IncentiveV5 {...incentive2}/>
        <PricingV1 items={pricingItems}/>
        <FeaturesSection1 {...features1}/>
        <FeaturesSectionV2 {...features2}/>
      </main>
    </div>
  );
};

export default App;
