import React, { useState, useEffect, useRef } from 'react';
import ImageSelectionModal from './ImageSelectionModal';
import { ImageType } from '../../../types/Data/ImageType';
import { IoImages } from 'react-icons/io5';

const SelectImageButton: React.FC<{ styles?: React.CSSProperties, onImageSelect: (image: ImageType) => void }> = ({ styles, onImageSelect }) => {
    const [isModalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);

    return (
        <>
            <button style={{...styles}} onClick={handleOpenModal}><IoImages/> Select Image</button>
            {isModalOpen && <ImageSelectionModal onClose={handleCloseModal} onImageSelect={onImageSelect} />}
        </>
    );
};

export default SelectImageButton;
