import React, { useState } from 'react';
import styles from './PromotionConditionForm.module.scss'; // Assuming you're using CSS Modules
import { CategoryType } from '../../../../types/Data/CategoryType';
import { PromotionCondition, PromotionType } from '../../../../types/Data/PromotionType';
import InputV4 from '../../../../components/Input/InputText/InputV4/InputV4';
import InputCalendarV1 from '../../../../components/Input/InputCalendar/InputCalendarV1/InputCalendarV1';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Store/store';
import Select4 from '../../../../components/Select/Select4/Select4';

interface PromotionConditionFormProps {
  promotion?: Partial<PromotionType>;
  onConditionChange: (key:string, value:any) => void;
}

const PromotionConditionForm: React.FC<PromotionConditionFormProps> = ({promotion, onConditionChange=()=>null}) => {
   const categories = useSelector<RootState>((state)=>state.dashboard.categories.categories) as CategoryType[];

   const categoriesOptions = categories.map((category)=>({label:category.name, value:category._id}));
  
   console.log(promotion?.conditions?.applicableCategories)
  return (
    <section className={styles.formContainer}>
      <h2>Conditions</h2>
      <div className={styles.formGroup}>
        <InputV4
          label='Minimum Purchase Amount'
          type="number"
          value={promotion?.conditions?.minPurchaseAmount || ''}
          onChange={e => onConditionChange('minPurchaseAmount', parseFloat(e.target.value))}
          placeholder="Enter minimum purchase amount"
          className={styles.input}
        />
      </div>
      
      <div className={styles.formGroup}>
        <InputV4
          label="Minimum Quantity"
          type="number"
          value={promotion?.conditions?.minQuantity || ''}
          onChange={e => onConditionChange('minQuantity', parseFloat(e.target.value))}
          placeholder="Enter minimum quantity"
          className={styles.input}
        />
      </div>
      
      <div className={styles.formGroup}>
        <InputV4
          label="Is the Customer a First-Time Buyer?"
          type="checkbox"
          checked={promotion?.conditions?.customerIsFirstTimeBuyer || false}
          onChange={() => onConditionChange('customerIsFirstTimeBuyer', !promotion?.conditions?.customerIsFirstTimeBuyer || false)}
          className={styles.checkbox}
        />
      </div>

      <div className={styles.formGroup}>
        <label>Applicable Categories</label>
        <Select4
          isMulti
          options={categoriesOptions}
          value={promotion?.conditions?.applicableCategories}
          onChange={values => onConditionChange('applicableCategories', Array.from( values))}
        />
      </div>

      <div className={styles.formGroup}>
        <InputV4
          type="text"
          label='Valid Customer Groups'
          value={promotion?.conditions?.validCustomerGroups?.join(', ') || ''}
          onChange={e => onConditionChange('validCustomerGroups', e.target.value.split(',').map(group => group.trim()))}
          placeholder="Enter customer groups (comma-separated)"
          className={styles.input}
        />
      </div>

      <div className={styles.formGroup}>
        <InputCalendarV1
          label='Promotion Start Time'
          value={promotion?.conditions?.startTime ? new Date(promotion?.conditions?.startTime).toISOString().slice(0, -1) : ''}
          onChange={e => onConditionChange('startTime', new Date(e.target.value))}
          className={styles.input}
        />
      </div>

      <div className={styles.formGroup}>
          <InputCalendarV1
          label='Promotion End Time'
          value={promotion?.conditions?.endTime ? new Date(promotion?.conditions?.endTime).toISOString().slice(0, -1) : ''}
          onChange={e => onConditionChange('endTime', new Date(e.target.value))}
          className={styles.input}
        />
      </div>

      <div className={styles.formGroup}>
        <label>Custom Condition</label>
        <textarea
          value={promotion?.conditions?.customCondition || ''}
          onChange={e => onConditionChange('customCondition', e.target.value)}
          placeholder="Enter custom condition"
          className={styles.textarea}
        />
      </div>
    </section>
  );
};

export default PromotionConditionForm;
