import { AxiosResponse } from "axios";
import { BlogPostType } from "../../types/Data/BlogPostType";
import api from "../config";
import { ApiResponse } from "../apiType";


export async function createBlogPost(postData: BlogPostType):Promise<AxiosResponse<ApiResponse<BlogPostType>>> {
    try {
        const response = await api.post('/blog-posts', postData);
        return response.data;
    } catch (error) {
        console.error('Error creating blog post:', error);
        throw error;
    }
};

export async function getAllBlogPosts(filters = {}):Promise<AxiosResponse<ApiResponse<BlogPostType[]>>> {
    try {
        const response = await api.get('/blog-posts', { params: filters });
        return response.data;
    } catch (error) {
        console.error('Error fetching blog posts:', error);
        throw error;
    }
};

export async function getBlogPostById(id:string):Promise<AxiosResponse<ApiResponse<BlogPostType>>> {
    try {
        const response = await api.get(`/blog-posts/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching blog post with ID: ${id}`, error);
        throw error;
    }
};

export async function getBlogPostsByTags(tags:string[]):Promise<AxiosResponse<ApiResponse<BlogPostType[]>>> {
    try {
        const response = await api.get('/blog-posts/tags', { params: { tags } });
        return response.data;
    } catch (error) {
        console.error('Error fetching blog posts by tags:', error);
        throw error;
    }
};

export async function updateBlogPost(id:string, updateData:Partial<BlogPostType>):Promise<AxiosResponse<ApiResponse<BlogPostType>>> {
    try {
        const response = await api.put(`/blog-posts/${id}`, updateData);
        return response.data;
    } catch (error) {
        console.error(`Error updating blog post with ID: ${id}`, error);
        throw error;
    }
};

export async function deleteBlogPost(id:string):Promise<AxiosResponse<ApiResponse<null>>> {
    try {
        const response = await api.delete(`/blog-posts/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error deleting blog post with ID: ${id}`, error);
        throw error;
    }
};

export async function togglePublishStatus(id:string):Promise<AxiosResponse<ApiResponse<BlogPostType>>>  {
    try {
        const response = await api.patch(`/blog-posts/${id}/publish`);
        return response.data;
    } catch (error) {
        console.error(`Error toggling publish status for blog post with ID: ${id}`, error);
        throw error;
    }
};


