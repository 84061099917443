import api from "../config";
import { AxiosResponse } from "axios";
import { ApiResponse } from "../apiType";
import { PaymentMethodType } from "../../types/Data/PayementSettingsType";

export async function getAllPaymentMethods(): Promise<AxiosResponse<ApiResponse<PaymentMethodType[]>>> {
    try {
        const response = await api.get('/paymentMethods');
        return response.data;
    } catch (error) {
        console.error('Error fetching all payment methods:', error);
        throw error;
    }
}

export async function getPaymentMethodByName(name: string): Promise<AxiosResponse<ApiResponse<PaymentMethodType>>> {
    try {
        const response = await api.get(`/paymentMethods/${name}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching payment method with name: ${name}`, error);
        throw error;
    }
}

export async function createPaymentMethod(paymentMethodData: PaymentMethodType): Promise<AxiosResponse<ApiResponse<PaymentMethodType>>> {
    try {
        const response = await api.post('/paymentMethods', paymentMethodData);
        return response.data;
    } catch (error) {
        console.error('Error creating payment method:', error);
        throw error;
    }
}

export async function updatePaymentMethod(name: string, updateData: Partial<PaymentMethodType>): Promise<AxiosResponse<ApiResponse<PaymentMethodType>>> {
    try {
        const response = await api.put(`/paymentMethods/${name}`, updateData);
        return response.data;
    } catch (error) {
        console.error(`Error updating payment method with name: ${name}`, error);
        throw error;
    }
}

export async function deletePaymentMethod(name: string): Promise<AxiosResponse<ApiResponse<void>>> {
    try {
        const response = await api.delete(`/paymentMethods/${name}`);
        return response.data;
    } catch (error) {
        console.error(`Error deleting payment method with name: ${name}`, error);
        throw error;
    }
}

export async function togglePaymentMethodStatus(name: string): Promise<AxiosResponse<ApiResponse<PaymentMethodType>>> {
    try {
        const response = await api.put(`/paymentMethods/${name}/toggleStatus`);
        return response.data;
    } catch (error) {
        console.error(`Error toggling status of payment method with name: ${name}`, error);
        throw error;
    }
}
