import React from 'react';
import styles from './VariantCombinationEditor.module.scss';
import { Variant, VariantCombinationType } from '../../../../types/Data/ProductType';
import InputV4 from '../../../../components/Input/InputText/InputV4/InputV4';
import CheckboxV1 from '../../../../components/Checkbox/CheckboxV1/CheckboxV1';
import ImageUploadComponent from '../../../Images/ImageUploadComponent/ImageUploadComponent';
import FileList from '../../../../components/DragAndDrop/DragAndDropV1/FileList';
import { ImageType } from '../../../../types/Data/ImageType';
import { uploadMultipleImages } from '../../../../api/image/imageApi';
import { IoTrash } from 'react-icons/io5';
import SelectImageButton from '../../../Images/ImageSelector/SelectImageButton';

interface VariantCombinationEditorProps {
  variants: Variant[]; // Variants like Color, Size
  variantCombinations: VariantCombinationType[]; // Combinations managed by the parent
  setVariantCombinations: (combinations: VariantCombinationType[]) => void; // Function from parent to update combinations
}

const VariantCombinationEditor: React.FC<VariantCombinationEditorProps> = ({ variants=[], variantCombinations, setVariantCombinations }) => {
  
  // Function to generate combinations from variant options
  const generateCombinations = () => {
    const newCombinations: VariantCombinationType[] = [];
    
    // Helper to check if a combination already exists
    const doesCombinationExist = (options: { [key: string]: string }) => {
      return variantCombinations.some(combination =>
        Object.entries(options).every(([key, value]) => combination.options[key] === value)
      );
    };

    // Logic to create new combinations that don't already exist
    const generate = (variantOptions: any[], current: any = {}, index: number = 0) => {
      if (variants.length === 0) {
        return alert('Please first create variant name and options!');
      }
      if (index === variants.length) {
        // If this combination doesn't already exist, add it
        if (!doesCombinationExist(current)) {
          newCombinations.push({ options: { ...current }, price: undefined, stockLevel: undefined, sku: '', images: [], isActive: true });
        }
        return;
      }
      variants[index].options.forEach(option => {
        current[variants[index].name] = option;
        generate(variantOptions, current, index + 1);
      });
    };

    generate(variants, {});
    setVariantCombinations([...variantCombinations, ...newCombinations]);  // Update the parent state
  };

  const handleInputChange = <K extends keyof VariantCombinationType>(index: number, key: K, value: VariantCombinationType[K]) => {
    const updatedCombinations = [...variantCombinations];
    updatedCombinations[index] = {
      ...updatedCombinations[index],
      [key]: value,
    };
    setVariantCombinations(updatedCombinations);  // Directly update the parent state
  };

  const handlePicturesUpload = async (index: number, key: string, files: File[]) => {
    const formData = new FormData();

    files.forEach((file, idx) => {
      formData.append('images', file);
    });

    formData.append('title', '');
    formData.append('alt', '');
    formData.append('category', 'product');
    formData.append('usage', JSON.stringify(['product']));
    formData.append('tags', JSON.stringify(['product']));

    try {
      const response = await uploadMultipleImages(formData);

      if (response.status === 201 && response.data) {
        const updatedCombinations = [...variantCombinations];
        updatedCombinations[index] = {
          ...updatedCombinations[index],
          images: response.data.data.map((item: any) => ({
            url: item.url,
            _id: item._id,
          })),
        };
        setVariantCombinations(updatedCombinations);
      }
    } catch (error: any) {
      console.log(error);
      alert(`An error occurred while uploading images: ${error.message}`);
    }
  };

  const handleSelectImages = (variantIndex:number, image:ImageType) => {
    let newVarians = variantCombinations.map((variant, index) => {
      if (index === variantIndex) {
        return ({
          ...variant,
          images: [...(variant.images ?? []), image]

        })
      } else return variant
    })
    setVariantCombinations(newVarians);
  };

  // Function to handle deletion of variant combination
  const handleDeleteVariant = (index: number) => {
    const updatedCombinations = [...variantCombinations];
    updatedCombinations.splice(index, 1);  // Remove the combination at the specific index
    setVariantCombinations(updatedCombinations);
  };

  return (
    <div className={styles.combinationEditor}>
      <h3>Create and Edit Variant Combinations</h3>
      <button onClick={generateCombinations} className={styles.generateButton}>
        Generate Variant Combinations
      </button>

      {variantCombinations.length > 0 &&
        variantCombinations.map((combination, index) => (
          <div key={index} className={styles.combination}>
            <div className={styles.combinationTitle}>
              {Object.entries(combination.options).map(([key, value]) => (
                <h5 key={key} className={styles.option}>
                  {key}: {value}
                </h5>
              ))}
            </div>
            <div onClick={() => handleDeleteVariant(index)}>
              <IoTrash />
            </div>
            <div className={styles.combinationRow}>
              <InputV4
                label="Price"
                type="number"
                value={combination.price?.toString() || ''}
                onChange={(e) => handleInputChange(index, 'price', parseFloat(e.target.value))}
                wrapperStyle={{ marginTop: '10px' }}
              />
              <InputV4
                label="Stock Level"
                type="number"
                value={combination.stockLevel?.toString() || ''}
                onChange={(e) => handleInputChange(index, 'stockLevel', parseInt(e.target.value))}
                wrapperStyle={{ marginTop: '10px' }}
              />
              <InputV4
                label="SKU"
                value={combination.sku || ''}
                onChange={(e) => handleInputChange(index, 'sku', e.target.value)}
                wrapperStyle={{ marginTop: '10px' }}
              />
              <CheckboxV1
                label="Active"
                checked={combination.isActive}
                onChange={(value) => handleInputChange(index, 'isActive', value)}
                wrapperStyle={{ marginTop: '10px' }}
              />
              <FileList
                files={combination?.images ?? []}
                setFiles={(images) => handleInputChange(index, 'images', images as ImageType[])}
              />
              <ImageUploadComponent onUpload={(files: File[]) => handlePicturesUpload(index, 'images', files)} />
              <SelectImageButton
                styles={{marginTop: 20}}
                onImageSelect={(image)=>handleSelectImages(index, image )}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default VariantCombinationEditor;
