import { OrderType } from "../../types/Data/OrderType";
import CustomerOrderHistoryV1 from "./CustomerOrderHistoryV1/CustomerOrderHistoryV1";


enum CustomerOrderHistoryComponentVersions {
    V1 = "v1",
};

const CustomerOrderHistoryComponentMapping:Record<CustomerOrderHistoryComponentVersions, React.FC<any>> = {
    [CustomerOrderHistoryComponentVersions.V1]: CustomerOrderHistoryV1,  
};

interface CustomerOrderHistoryComponentProps {
    orders?: Partial<OrderType>[]
};

export {
    CustomerOrderHistoryComponentVersions,
    CustomerOrderHistoryComponentMapping
}

export type {
    CustomerOrderHistoryComponentProps
}