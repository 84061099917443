// adminReducer.ts
import ordersReducer from '../DashboardState/orders/ordersSlice';
import productsReducer from '../DashboardState/products/productsSlice';
import cancellationsReducer from '../DashboardState/cancellations/cancellationsSlice';
import customersReducer from '../DashboardState/customers/customersSlice';
import feedbackReducer from '../DashboardState/customerFeedbacks/customerFeedbacksSlice';
import discountCodesReducer from '../DashboardState/discountCodes/discountCodesSlice';
import promotionsReducer from '../DashboardState/promotions/promotionsSlice';
import emailCampaingsReducer from '../DashboardState/emailCampaings/emailCampaingsSlice';
import subscribersReducer from '../DashboardState/subscribers/subscribersSlice';
import shopSettingsReducer from '../DashboardState/shopSettings/shopSettingsSlice';
import paymentMethodsReducer from './paymentMethods/paymentMethodsSlice';
import shippingMethodsReducer from './shippingMethods/shippingMethodsSlice';
import taxSettingsReducer from '../DashboardState/taxSettings/taxSettingsSlice';
import blogPostReducer from '../DashboardState/blogPost/blogPostSlice';
import categoriesReducer from '../DashboardState/categories/categoriesSlice';
import socialMediaReducer from './socialMedia/socialMediaSlice';
import testimonialsReducer from '../DashboardState/testimonials/testimonialsSlice';
import reviewReducer from '../DashboardState/reviews/reviewSlice';
import notificationReducer from '../DashboardState/notification/notificationSlice';
import ticketsReducer from '../DashboardState/tickets/ticketsSlice';
import faqReducer from '../DashboardState/faq/faqSlice';
import returnRequestReducer from '../DashboardState/returnRequest/returnRequestSlice';
import refundProcessReducer from '../DashboardState/refundProcess/refundProcessSlice';
import accountReducer from '../DashboardState/account/accountSlice';
import elementReducer from '../DashboardState/element/elementSlice';
import privatePolicyReducer from '../DashboardState/privatePolicy/privatePolicySlice';
import termOfServiceReducer from './termOfService/termOfServiceSlice';
import { combineReducers } from '@reduxjs/toolkit';
import imagesReducer from './images/imagesSlice';
import adminReducer from './admin/adminSlice';

// Combine multiple admin reducers into one
const dashboardReducer = combineReducers({
   // data
   accounts: accountReducer,
   orders: ordersReducer,
   products: productsReducer,
   cancellations: cancellationsReducer,
   customers: customersReducer,
   customerFeedback: feedbackReducer,
   discountCodes: discountCodesReducer,
   promotions: promotionsReducer,
   emailCampaigns: emailCampaingsReducer,
   subscribers: subscribersReducer,
   blogPosts: blogPostReducer,
   categories: categoriesReducer,
   testimonials: testimonialsReducer,
   reviews: reviewReducer,
   admins: adminReducer,
   notifications: notificationReducer,
   tickets: ticketsReducer,
   faq: faqReducer,
   returnRequest: returnRequestReducer,
   refundProcess: refundProcessReducer,
   privatePolicy: privatePolicyReducer,
   termOfService: termOfServiceReducer,
 
   // settings
   shopSettings: shopSettingsReducer,
   paymentMethods: paymentMethodsReducer,
   shippingMethods: shippingMethodsReducer,
   socialMedia: socialMediaReducer,
   taxSettings: taxSettingsReducer,

   // elements
   elements: elementReducer,
   images: imagesReducer
});

export default dashboardReducer ;


