import { StockStatus } from "../../types/Data/ProductType";
import { CustomerWhishListComponentProps } from "./CustomerWishListComponentProps";

// Example mock product data
const customerWishListDefaultData : CustomerWhishListComponentProps['wishlistItems'] = [
  {
    product: {
      _id: "p1",
      name: "Smartphone X12",
      price: 999.99,
      thumbnail: "/images/smartphone-x12.jpg",
      description: "A cutting-edge smartphone with a stunning OLED display.",
      categories: ["Phones"],
      stockStatus: StockStatus.IN_STOCK,
      rating: 4.8,
      brand: "BrandX",
    },
    createdAt: new Date("2024-09-28T14:45:30Z"),
    updatedAt: new Date("2024-09-28T14:45:30Z"),
  },
  {
    product: {
      _id: "p2",
      name: "Wireless Headphones Z",
      price: 199.99,
      thumbnail: "/images/headphones-z.jpg",
      description: "Noise-cancelling wireless headphones with immersive sound.",
      categories: ["Accessories"],
      stockStatus: StockStatus.IN_STOCK,
      rating: 4.5,
      brand: "BrandZ",
    },
    createdAt: new Date("2024-09-15T11:20:00Z"),
    updatedAt: new Date("2024-09-15T11:20:00Z"),

  },
  {
    product: {
      _id: "p3",
      name: "Laptop Pro 15",
      price: 1499.99,
      thumbnail: "/images/laptop-pro-15.jpg",
      description: "A high-performance laptop for professionals.",
      categories: ["Laptops"],
      stockStatus: StockStatus.IN_STOCK,
      rating: 4.9,
      brand: "BrandY",
    },
    createdAt: new Date("2024-09-12T08:30:00Z"),
    updatedAt: new Date("2024-09-12T08:30:00Z"),
  },
  {
    product: {
      _id: "p4",
      name: "Smartwatch Series 5",
      price: 399.99,
      thumbnail: "/images/smartwatch-series-5.jpg",
      description: "Stay connected and track your fitness goals with this smartwatch.",
      categories: ["Wearables"],
      stockStatus: StockStatus.IN_STOCK,
      rating: 4.6,
      brand: "BrandW",
    },
    createdAt: new Date("2024-10-01T17:15:00Z"),
    updatedAt: new Date("2024-10-01T17:15:00Z"),
  },
];

export default customerWishListDefaultData;
