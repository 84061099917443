import React, { useEffect, useRef, useState } from 'react';
import styles from './FeaturedProductsV2.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { ProductType } from '../../../types/Data/ProductType';
import { FeaturedProductsComponentProps } from '../FeaturedProductsComponentProps';

const FeaturedProductsV2: React.FC<FeaturedProductsComponentProps> = ({ productsId,  onClick=()=>null }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);

  const allProducts = useSelector<RootState>(state => state.dashboard.products.products) as ProductType[];

  const products = productsId
    ? allProducts.filter((product) => productsId.includes(product._id!))
    : allProducts.slice(0, 10); 

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + products.length) % products.length);
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length);
  };

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.style.setProperty('--current-slide', currentIndex.toString());
    }
  }, [currentIndex]);

  return (
    <div className={styles.carouselContainer}>
      <button className={styles.prevButton} onClick={prevSlide}>❮</button>
      <div className={styles.carousel} ref={carouselRef}>
        {products?.map((product, index) => {
            return (
                <div
                    key={product._id!}
                    className={`${styles.slide} ${index === currentIndex ? styles.active : ''}`}
                    onClick={()=>onClick(product._id!)}
                >
                    {product.images && <img src={product.images[0].url} alt={product.name} className={styles.productImage} />}
                    <div className={styles.productInfo}>
                    <h3>{product.name}</h3>
                    <p>{product.description}</p>
                    <span>{product.price}</span>
                    </div>
                </div>
                )})}
        </div>
      <button className={styles.nextButton} onClick={nextSlide}>❯</button>
    </div>
  );
};

export default FeaturedProductsV2;
