import { OrderItemType } from "../../types/Data/OrderType";
import CustomerProductReviewV1 from "./CustomerProductReviewV1/CustomerProductReviewV1";

enum CustomerProductReviewComponentVersions {
    V1 = "v1",
};

const CustomerProductReviewComponentMapping:Record<CustomerProductReviewComponentVersions, React.FC<any>> = {
    [CustomerProductReviewComponentVersions.V1]: CustomerProductReviewV1,  
};

interface CustomerProductReviewComponentProps {
    product?: OrderItemType;
    onSubmitReview?: (productId: string, rating: number, reviewText: string) => void;
};

export {
    CustomerProductReviewComponentVersions,
    CustomerProductReviewComponentMapping,
};

export type {
    CustomerProductReviewComponentProps
};