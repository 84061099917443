import { CompanyHistoryComponentProps } from "./CompanyHistoryComponentProps";

export const defaultCompanyHistoryProps: CompanyHistoryComponentProps = {
    title: 'Our Journey',
    milestones: [
      {
        date: '2000',
        event: 'Company Founded',
        description: 'Our company was founded in the year 2000, with a mission to provide the best services.',
        imageUrl: 'https://via.placeholder.com/150'
      },
      {
        date: '2005',
        event: 'First Major Milestone',
        description: 'We reached our first major milestone, expanding our services globally.',
        imageUrl: 'https://via.placeholder.com/150'
      },
      {
        date: '2010',
        event: 'Awarded for Excellence',
        description: 'Our company was awarded for excellence in service and innovation.',
        imageUrl: 'https://via.placeholder.com/150'
      },
      {
        date: '2020',
        event: '20 Years of Success',
        description: 'Celebrating 20 years of delivering exceptional quality and service.',
        imageUrl: 'https://via.placeholder.com/150'
      }
    ]
  };
  
  