import React from 'react';
import styles from './Filters.module.scss';
import SearchBar4 from '../../../components/SearchBar/SearchBarV4/SerachBarV4';
import Select4 from '../../../components/Select/Select4/Select4';
import InputCalendar from '../../../components/Input/InputCalendar/InputCalendarV1/InputCalendarV1';

interface FiltersProps {
    query: {
        search: string;
        status: string;
        paymentStatus: string;
        startDate: string;
        endDate: string;
    };
    onQueryChange: (key: string, value: any) => void;
}

const Filters: React.FC<FiltersProps> = ({ query, onQueryChange }) => {
    const statusOptions = [
        { value: '', label: 'All Statuses' },
        { value: 'Pending', label: 'Pending' },
        { value: 'Confirmed', label: 'Confirmed' },
        { value: 'Completed', label: 'Completed' },
        { value: 'Shipped', label: 'Shipped' },
        { value: 'Cancelled', label: 'Cancelled' },
    ];

    const paymentStatusOptions = [
        { value: '', label: 'All Payment Statuses' },
        { value: 'Pending', label: 'Pending' },
        { value: 'Completed', label: 'Completed' },
        { value: 'Failed', label: 'Failed' },
    ];

    const onDateStartChange = (date: Date) => {
        onQueryChange('startDate', date.toDateString());
    };

    const onDateEndChange = (date: Date) => {
        onQueryChange('endDate', date.toDateString());
    };

    return (
        <div className={styles.filters}>
            <SearchBar4
                placeholder="Search by customer name, email, or transaction ID..."
                value={query.search}
                onSearch={(search) => onQueryChange('search', search)}
            />
            <div className={styles.selectRow}>
                <Select4
                    options={statusOptions}
                    value={query.status}
                    onChange={(value) => onQueryChange('status', value as string)}
                    placeholder="Select status"
                />
                <Select4
                    options={paymentStatusOptions}
                    value={query.paymentStatus}
                    onChange={(value) => onQueryChange('paymentStatus', value as string)}
                    placeholder="Select payment status"
                />
                <div className={styles.datePicker}>
                    <InputCalendar
                        value={query.startDate}
                        onDateChange={onDateStartChange}
                    />
                </div>
                <div className={styles.datePicker}>
                    <InputCalendar
                        value={query.endDate}
                        onDateChange={onDateEndChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default Filters;
