// BlogPostEditor.tsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input4 from '../../../components/Input/InputText/InputV4/InputV4';
import Button1 from '../../../components/Button/ButtonV1/ButtonV1';
import styles from './BlogPostEditorPage.module.scss';
import { useParams, useSearchParams } from 'react-router-dom';
import { RootState } from '../../../Store/store';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';
import TextEditor from '../../../components/RichTextEditor/RichTextEditorV1/RichTextEditorV1';
import { BlogPostType } from '../../../types/Data/BlogPostType';
import { createBlogPost, getBlogPostById, updateBlogPost } from '../../../api/blogPost/blogPostApi';
import { handleApiError } from '../../../api/apiError';
import SaveButton from '../../../components/Button/SaveButton/SaveButton';


interface BlogPostEditorProps {
  onSave?: () => void;
}

const BlogPostEditorPage: React.FC<BlogPostEditorProps> = ({ onSave }) => {
  const dispatch = useDispatch();
  
  const [searchParams] = useSearchParams();
  const blogPostId = searchParams.get('blogPostId') || undefined;

  const blogPosts = useSelector<RootState>( (state) => state.dashboard.blogPosts.blogPosts) as BlogPostType[];
  
  const blogPost = blogPosts!.filter(blog => blog._id === blogPostId!)[0] as BlogPostType | undefined

  const defaultBlogPost: BlogPostType = {
    title: '',
    content: '',
    imageUrl: '',
    createdAt: new Date(),
    isPublished: false,
    tags: [],
  };

  const blogPostToEdit = blogPost || defaultBlogPost;

  // State variables for original and current review
  const [originalBlogPost, setOriginalBlogPost] = useState<BlogPostType>(blogPostToEdit);
  const [currentBlogPost, setCurrentBlogPost] = useState<BlogPostType>(blogPostToEdit);

  // State variables handling fetch status
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<{message:string, status:number | undefined} | null>()
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const getBlogPost = async () => {
    setIsLoading(true);
    try {
      const response = await getBlogPostById(blogPostId!);
      if (response.status === 200 && response.data) {
        setOriginalBlogPost(response.data.data)
        setCurrentBlogPost(response.data.data)
      } else {
        alert('An error occured while retrieving the blog post')
      }
    } catch (error: any) {
      const handledError = handleApiError(error)
      setError({message: handledError.message, status: handledError.status})
    } finally {
      setIsLoading(false)
    }
  };

  /*
  useEffect(()=>{
    if (blogPostId) {
      getBlogPost()
    } else {
      setOriginalBlogPost(defaultBlogPost)
      setCurrentBlogPost(defaultBlogPost)
    }
  }, [blogPostId])
  */

  const handleUpdateBlogPost = async () => {
    setIsSaving(true);
    try {
      const response = await updateBlogPost(currentBlogPost._id!, currentBlogPost);
      if (response.status === 200 && response.data) {
        alert('Blog post updated!')
        setOriginalBlogPost(response.data.data)
        setCurrentBlogPost(response.data.data)
      } else {
        alert('An error occured while updating the blog post')
      }
    } catch (error:any) {
      const handledError = handleApiError(error);
      alert(handledError.message)
    }
  };

  const handleCreateBlogPost = async () => {
    setIsSaving(true);
    try {
      const response = await createBlogPost(currentBlogPost);
      if (response.status === 200 && response.data) {
        alert('Blog post created!')
        setOriginalBlogPost(response.data.data)
        setCurrentBlogPost(response.data.data)  
      } else {
        alert('An error occured while creating the blog post')
      }
    } catch (error:any) {
      const handledError = handleApiError(error);
      alert(handledError.message)
    }
  };

  const [currentPost, setCurrentPost] = useState<Partial<BlogPostType>>({
    title: blogPost?.title || '',
    content: blogPost?.content || '',
    imageUrl: blogPost?.imageUrl || '',
    createdAt: blogPost?.createdAt || new Date(),
  });


  const handleChange = (field: keyof BlogPostType, value: any) => {
    setCurrentPost({ ...currentPost, [field]: value });
  };

  const handleSave = () => {
    if (currentBlogPost._id) {
      return handleUpdateBlogPost()
    } else {
      return handleUpdateBlogPost()
    }
  };

  return (
    <div className={styles.blog_post_editor}>
      <DashboardHeader title={blogPost ? 'Edit Blog Post' : 'Add Blog Post'}/>
      <div className={styles.form}>
        <Input4
            label="Title"
            type="text"
            id="title"
            name="title"
            value={currentPost.title || ''}
            onChange={(e) => handleChange('title', e.target.value)}
            required
        />
        <TextEditor
        label="content"
        wrapperStyle={{marginTop:25}}
        id="content"
        value={currentPost.content || ''}
        onChange={(content:string) => handleChange('content', content)}
        />
        
        <Input4
            label="Image URL"
            type="text"
            id="imageUrl"
            name="imageUrl"
            value={currentPost.imageUrl || ''}
            onChange={(e) => handleChange('imageUrl', e.target.value)}
            wrapperStyle={{marginTop:25}}
        />
        <SaveButton onClick={handleSave} isSaving={isSaving} style={{marginTop: 20, minWidth: '40%'}} />
      </div>
    </div>
  );
};

export default BlogPostEditorPage;
