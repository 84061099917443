import React from 'react';
import styles from './FinalizeRefundTable.module.scss';
import { ReturnOrderItemType } from '../../../../types/Data/ReturnRequestType';
import { RefundReturnedProduct } from '../../../../types/Data/RefundProcessType';

interface FinalizeRefundTableProps {
  validatedItems: RefundReturnedProduct[];
  onCreateRefund: () => void;
}

const FinalizeRefundTable: React.FC<FinalizeRefundTableProps> = ({ validatedItems, onCreateRefund }) => {
  const totalRefundAmount = validatedItems.reduce((total, item) => total + item?.refundAmount!, 0);

  return (
    <div className={styles.finalizeRefundTable}>
      <h2>Finalize Refund</h2>
      <table>
        <thead>
          <tr>
            <th>Item</th>
            <th>Quantity</th>
            <th>Refund Amount</th>
          </tr>
        </thead>
        <tbody>
          {validatedItems.map(item => (
            <tr key={item.product as string}>
              <td>{item.productName}</td>
              <td>{item.quantity}</td>
              <td>${item?.refundAmount!.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className={styles.totalRefund}>
        <label>Total Refund:</label>
        <span>${totalRefundAmount.toFixed(2)}</span>
      </div>

      <button onClick={onCreateRefund}>Create Refund</button>
    </div>
  );
};

export default FinalizeRefundTable;
