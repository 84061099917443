import React from 'react';
import styles from './FeedbackDetails.module.scss';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { CustomerFeedbackType } from '../../../types/Data/feedback';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';
import { FaUser, FaEnvelope, FaComment, FaCalendarAlt, FaStar, FaInfoCircle } from 'react-icons/fa';
import FeedbackInfo from './FeedbackInfo/FeedbackInfo';
import FeedbackStatusUpdate from './FeedbackStatusUpdate/FeedbackStatusUpdate';

interface FeedbackDetailsProps {}

const FeedbackDetails: React.FC<FeedbackDetailsProps> = () => {
  const { feedbackId } = useParams<{feedbackId: string}>();
  const feedback = useSelector<RootState>((state) => state.dashboard.customerFeedback.customerFeedbacks.find(el => el.id === feedbackId)) as CustomerFeedbackType;

  return (
    <div className={styles.feedbackDetailPage}>
      <DashboardHeader title="Feedback Details" />
      <FeedbackInfo feedback={feedback}/>
      <FeedbackStatusUpdate feedback={feedback}/>
    </div>
  );
};

export default FeedbackDetails;
