import React from 'react'
import styles from '../Component.module.scss';
import CompanyHistoryV1 from '../../UIElements/CompanyHistory/CompanyHistoryV1/CompanyHistoryV1';
import CompanyHistoryV2 from '../../UIElements/CompanyHistory/CompanyHistoryV2/CompanyHistoryV2';
import CompanyHistoryV3 from '../../UIElements/CompanyHistory/CompanyHistoryV3/CompanyHistoryV3';

const CompanyHistoryComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <CompanyHistoryV1/>
        </div>
        <div className={styles.element}>
            <CompanyHistoryV2/>
        </div>
        <div className={styles.element}>
            <CompanyHistoryV3/>
        </div>
    </div>
  )
}

export default CompanyHistoryComponentPage