// store/paymentSettingsSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPrivatePolicy } from '../../../api/privatePolicy/privatePolicyApi';
import { TermOfServiceType } from '../../../types/Data/TermOfServiceType';
import { exampleTermOfService } from './exampleTermOfService';

export const fetchTermOfService = createAsyncThunk(
  'termOfService/fetchTermOfService',
  async (_, { rejectWithValue }) => {
      try {
          const response = await getPrivatePolicy();
          return {
              termOfService: response.data.data as TermOfServiceType,
          };
          
      } catch (err: any) {
          return rejectWithValue('Failed to load orders');
      }
  }
);

interface TermOfServiceyState {
  termOfService: TermOfServiceType;
  loading: boolean;
  error: string | null;
}

const initialState: TermOfServiceyState = {
  termOfService: exampleTermOfService,
  loading: false,
  error: null,
};

const termOfServiceSlice = createSlice({
  name: 'termOfService',
  initialState,
  reducers: {
    dispatchUpdateTermOfService(state, action: PayloadAction<TermOfServiceType>) {
      state.termOfService = action.payload;
    },
    dispatchRemoveTermOfService(state, action: PayloadAction<null>) {
      state.termOfService.content = ''
    },
    dispatchLoadTermOfService(state, action:PayloadAction<string>) {
      state.termOfService.content = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchTermOfService.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchTermOfService.fulfilled, (state, action: PayloadAction<{termOfService:TermOfServiceType}>) => {
            state.termOfService = action.payload.termOfService;
        })
        .addCase(fetchTermOfService.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
},
});

export const { dispatchUpdateTermOfService, dispatchRemoveTermOfService, dispatchLoadTermOfService, } = termOfServiceSlice.actions;
export default termOfServiceSlice.reducer;
