import React from 'react';
import styles from './SlideOverV2.module.scss';
import { SlideOverComponentProps } from '../SlideOverComponentProps';

const SlideOverV2: React.FC<SlideOverComponentProps> = ({ 
  isOpen = false, 
  onClose = ()=>null, 
  children = null,
  modalStyle = undefined 
 }) => {
    
    return (
    <>
      {isOpen && <div className={styles.slideover_overlay} onClick={onClose} />}
      <div className={`${styles.slideover_panel} ${isOpen ? styles.open : ''}`}>
        <button className={styles.slideover_close_button} onClick={onClose}>×</button>
        {children}
      </div>
    </>
  );
};

export default SlideOverV2;