import React from 'react'
import styles from '../Component.module.scss';
import OrderConfirmationV1 from '../../UIElements/OrderConfirmation/OrderConfirmationV1/OrderConfirmationV1';
import OrderConfirmationV2 from '../../UIElements/OrderConfirmation/OrderConfirmationV2/OrderConfirmationV2';
import OrderConfirmationV3 from '../../UIElements/OrderConfirmation/OrderConfirmationV3/OrderConfirmationV3';

const OrderConfirmationComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <OrderConfirmationV1/>
        </div>
        <div className={styles.element}>
            <OrderConfirmationV2/>
        </div>
        <div className={styles.element}>
            <OrderConfirmationV3/>
        </div>
    </div>
  )
}

export default OrderConfirmationComponentPage