import { FeaturedProductsComponentProps } from "../../UIElements/FeaturedProducts/FeaturedProductsComponentProps";

enum ComponentType {
  // Hero Banner
  HeroBanner = "heroBanner",

  // Incentives
  Incentive = "incentive",

  // Feature Section
  FeatureSection = "featureSection",

  // Featured Products
  FeaturedProducts = "featuredProducts",

  // Category Display
  CategoryDisplay = "categoryDisplay",

  // Newsletter Signup
  NewsletterSignup = "newsletterSignup",

  // Blog Section
  BlogSection = "blogSection",

  // Social Media Links
  SocialMedia = "socialMedia",

  // Promotional Banner
  PromotionalBanner = "promotionalBanner",

  // Testimonials
  Testimonial = "testimonial",

  // Company History
  CompanyHistory = "companyHistory",

  // Company Overview
  CompanyOverview = "companyOverview",

  // Customer Support
  CustomerSupport = "customerSupport",

  // Contact Information
  ContactInformation = "contactInformation",

  // Hero Section
  HeroSection = "heroSection",

  // Logo Cloud
  LogoCloud = "logoCloud",

  // Trust Badge Icons
  TrustBadgeIcon = "trustBadgeIcon",

  // Product List
  ProductList = "productList",

  // Sort
  Sort = "sort",

  // Filter Modal
  FilterModal = "filterModal",

  // Pagination
  Pagination = "pagination",

  // Active Filter (Badge)
  ActiveFilter = "activeFilter",

  // Product Image Gallery
  ProductImageGallery = "productImageGallery",

  // Product Name
  ProductName = "productName",

  // Product Description
  ProductDescription = "productDescription",

  // Variant Selector
  VariantSelector = "variantSelector",

  // Quantity Selector
  QuantitySelector = "quantitySelector",

  // Button Add To Cart
  ButtonAddToCart = "buttonAddToCart",

  // Button Add To Wishlist
  ButtonAddWishList = "buttonAddWishList",

  // Button Buy
  ButtonBuy = "buttonBuy",

  // Product Reviews
  ProductReviews = "productReviews",

  // Cart Item List
  CartItemList = "cartItemList",

  // Order Summary
  OrderSummary = "orderSummary",

  // Button Proceed To Checkout
  ButtonCheckout = "buttonCheckout",

  // Coupon Promotion
  CouponPromotion = "couponPromotion",

  // Step Indicator
  StepIndicator = "stepIndicator",

  // Shipping Information
  ShippingInformation = "shippingInformation",

  // Billing Information
  BillingInformation = "billingInformation",

  // Payment Method Selector
  PaymentMethodSelector = "paymentMethodSelector",

  // Trust Signals
  TrustSignals = "trustSignals",

  // Order Confirmation
  OrderConfirmation = "orderConfirmation",

  // Order Error
  OrderError = "orderError",

  // Contact Form
  ContactForm = "contactForm",

  // Customer Edit Profile Form
  CustomerEditProfilForm = "customerEditProfilForm",

  // Customer Order History
  CustomerOrderHistory = "customerOrderHistory",

  // Customer Wishlist
  CustomerWishList = "customerWishList",

  // Customer Order Detail
  CustomerOrderDetail = "customerOrderDetail",

  // Customer Order Tracking
  CustomerOrderTracking = "customerOrderTracking",

  // Customer Product Review
  CustomerProductReview = "customerProductReview",

  // Customer Return Request
  CustomerReturnRequest = "customerReturnRequest",

  // Customer Return History
  CustomerReturnHistory = "customerReturnHistory",
  
  // Customer Message History
  CustomerMessageHistory = "customerMessageHistory",

  // Customer Chat Room
  CustomerChatRoom = "customerChatRoom",
  
  // Breadcrumb
  Breadcrumb = "breadcrumb",

  // Cart summary Sidebar
  CartSummarySidebar = "cartSummarySidebar",

  // Size selector
  SizeSelector = "sizeSelector",

  // Product rating
  ProductRating = "productRating",

  // Product rating
  ProductPrice= "productPrice",

  // QA Card
  QACard = "qaCard",

  // Team member card
  TeamMemberCard = "teamMemberCard",

  // Tab navigation
  TabNavigation = "tabNavigation",

  // Footer
  Footer = "footer",

  // Customer Navigation
  CustomerNavigation = "customerNavigation",

  // Badge
  Badge = "badge",
}

// Component prop mappings
interface ComponentPropsMapping {
  [ComponentType.FeaturedProducts]: FeaturedProductsComponentProps;

}


interface BaseElementType<T> {
    _id?: string;
    page: string;    // References the unique page identifier
    type: ComponentType;      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: string;
    isVisible: boolean;
    position?: {
      row?: number;      // The row number in the grid
      column?: number;   // The column number in the grid
      span?: number;     // The number of columns the element spans
      order?: number;    // Optional order for flex/grid layouts
      spanRows?: number
    };
    style?: {
      theme?: string;
      customClassName?: string;
    };
    props?: T;      // Component-specific configuration (e.g., props and styles)
};

export {
    ComponentType,
};

export type {
    BaseElementType,
    ComponentPropsMapping,
};