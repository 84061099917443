import React, { useEffect, useState } from 'react';
import { IoRemove } from 'react-icons/io5';
import styles from '../../UIElementsMainSettings/UiElementEditor.module.scss';

import Input4 from '../../../components/Input/InputText/InputV4/InputV4';
import Select4 from '../../../components/Select/Select4/Select4';
import ButtonV3 from '../../../components/Button/ButtonV3/ButtonV3';
import { FeatureSectionMapping, FeatureSectionVersions, FeaturesSectionComponentProps } from '../FeatureSectionComponentProps';
import { defaultFeaturesSectionProps } from '../featureSectionDefaultProps';
import { BaseElementType, ComponentType } from '../../../types/Data/BaseElementType';
import ButtonV1 from '../../../components/Button/ButtonV1/ButtonV1';
import UIElementsMainSettings from '../../UIElementsMainSettings/UIElementsMainSettings';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../Store/store';
import { fetchCreateElement, fetchUpdateElement } from '../../../Dashboard/DashboardState/element/elementSlice';
import { useSelector } from 'react-redux';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import ButtonDelete from '../../../components/Button/ButtonDelete/ButtonDelete';

const iconOptions = [
    { label: 'Checkmark', value: 'IoCheckmarkCircleOutline' },
    { label: 'Alert', value: 'IoAlertCircleOutline' },
    { label: 'Information', value: 'IoInformationCircleOutline' },
    { label: 'Close', value: 'IoCloseCircleOutline' },
    { label: 'Heart', value: 'IoHeartOutline' },
    { label: 'Star', value: 'IoStarOutline' },
    { label: 'Thumbs Up', value: 'IoThumbsUpOutline' },
    { label: 'Thumbs Down', value: 'IoThumbsDownOutline' },
    { label: 'Sunny', value: 'IoSunnyOutline' },
    { label: 'Moon', value: 'IoMoonOutline' },
    { label: 'Happy', value: 'IoHappyOutline' },
    { label: 'Sad', value: 'IoSadOutline' },
    { label: 'Camera', value: 'IoCameraOutline' },
    { label: 'Cart', value: 'IoCartOutline' },
    { label: 'Envelope', value:'FaEnvelope' },
    { label: 'CreditCard', value: 'FaCreditCard' },
    { label: 'FaUser', value: 'FaUser' },
    { label: 'Database', value: 'FaDatabase' },
    { label: 'Design', value: 'FaPaintBrush' },
    { label: 'File', value: 'FaFile' },
    { label: 'Shipping', value: 'FaShippingFast' },
    { label: 'Gift', value: 'FaGifth' },
    { label: 'Info', value: 'FaInfoCircle' }

];

interface FeatureSectionEditorProps {
  element?: BaseElementType<FeaturesSectionComponentProps>;
}

const FeatureSectionEditor: React.FC<FeatureSectionEditorProps> = ({ element }) => {
   
  const defaultElement: BaseElementType<FeaturesSectionComponentProps> = {
      type: ComponentType.FeatureSection,
      version: 'v1',
      page: 'home',
      props: defaultFeaturesSectionProps,
      position: undefined,
      isVisible: true,
    };

  const newFeature = {
    title: '',
    description: '',
    icon: '',
  };

  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state:RootState)=>state.dashboard.elements.loading);
  const error = useSelector((state:RootState)=>state.dashboard.elements.error);

  const [currentElement, setCurrentElement] = useState<BaseElementType<FeaturesSectionComponentProps>>(element ? element : defaultElement);
  

  useEffect(() => {
    if (element) {
      setCurrentElement(element);
    } else {
      setCurrentElement(defaultElement);
    }
  }, [element]);

  const handleAddItem = () => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      props: {
        ...prevElement.props,
        features: [...(prevElement?.props?.features ?? []), newFeature],
      },
    }));
  };

  const handleRemoveItem = (itemIndex: number) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      props: {
        ...prevElement.props,
        features: (prevElement?.props?.features ?? []).filter((_, index: number) => index !== itemIndex),
      },
    }));
  };

  const handleItemChange = (itemIndex: number, key: string, value: any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      props: {
        ...prevElement.props,
        features: (prevElement?.props?.features ?? []).map((item, index) =>
          index === itemIndex ? { ...item, [key]: value } : item
        ),
      },
    }));
  };

  const handleElementUpdate = (key: string, value: any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      [key]: value,
    }));
  };

  const handleElementVisibilityUpdate = () => {
    setCurrentElement((prevElement) => ({ ...prevElement, isVisible: !prevElement.isVisible }));
  };

  const handleSaveElement = () => {
    if (currentElement?._id) {
      dispatch(fetchUpdateElement({id:currentElement._id, element:currentElement}))
    } else {
      dispatch(fetchCreateElement(currentElement))
    }
  };

  const SelectedComponent = FeatureSectionMapping[currentElement.version as FeatureSectionVersions] || FeatureSectionMapping[FeatureSectionVersions.V1];

  const elementsVersion = Object.keys(FeatureSectionVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: FeatureSectionVersions[key as keyof typeof FeatureSectionVersions],
  }));

  return (
    <div className={styles.container}>
      { error && <ErrorBannerV1 message={error}/>}
      { loading && <LoadingIndicatorV1/>}
      <div className={styles.componentPreview}>
        <SelectedComponent {...currentElement.props} />
      </div>
      <div className={styles.componentEditor}>
        <UIElementsMainSettings
            element={currentElement}
            elementVersions={elementsVersion}
            onElementUpdate={handleElementUpdate}
            onVisibilitytUpdate={handleElementVisibilityUpdate}
        />

        {/* Items Section */}
        <div className={styles.slides}>
          {currentElement?.props?.features?.map((item, index) => (
            <div key={index} className={styles.slide}>
              <div className={styles.slideHeader}>
                <h4>Item {index + 1}</h4>
                <div className={styles.buttonRemove} onClick={() => handleRemoveItem(index)}>
                  <IoRemove className={styles.removeButton} />
                </div>
              </div>
              <Select4
                label="Icon"
                value={item.icon}
                options={iconOptions}
                onChange={(value: string) => handleItemChange(index, 'icon', value)}
              />
              <Input4
                label="Title"
                value={item.title}
                onChange={(e) => handleItemChange(index, 'title', e.target.value)}
              />
              <Input4
                label="Description"
                value={item.description}
                onChange={(e) => handleItemChange(index, 'description', e.target.value)}
              />
            </div>
          ))}
          <ButtonV3 title="Add Item" onClick={handleAddItem} />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <ButtonV1 label="Save Element" onClick={handleSaveElement} />
        <ButtonDelete/>
      </div>
    </div>
  );


}

export default FeatureSectionEditor;
