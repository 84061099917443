import React, { ReactNode, useState } from 'react';
import { RefundProcessType, RefundPaymentMethod } from '../../../../types/Data/RefundProcessType';
import styles from './RefundValidation.module.scss';
import axios from 'axios';

interface RefundValidationProps {
  refundProcess: RefundProcessType;
  children?: ReactNode;
}

const RefundValidation: React.FC<RefundValidationProps> = ({ refundProcess, children }) => {
  const [refundAmount, setRefundAmount] = useState(refundProcess.totalRefundAmount);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Get payment info from refundProcess or associated order
  const paymentMethod = refundProcess.paymentInfo?.paymentMethod;
  const transactionId = refundProcess.paymentInfo?.transactionId;

  const handleConfirm = async () => {
    setIsProcessing(true);
    setError(null); 
  };

  return (
    <div className={styles.validation}>
      <h2>Validate Refund</h2>

      <div className={styles.formGroup}>
        <label>Refund Amount</label>
        <input
          type="number"
          value={refundAmount}
          min="0"
          max={refundProcess.totalRefundAmount}
          step="0.01"
          onChange={(e) => setRefundAmount(parseFloat(e.target.value))}
        />
      </div>

      <div className={styles.formGroup}>
        <label>Payment Method</label>
        <input type="text" value={paymentMethod} readOnly />
      </div>

      <div className={styles.formGroup}>
        <label>Transaction ID</label>
        <input type="text" value={transactionId} readOnly />
      </div>

      {error && <div className={styles.error}>{error}</div>}

      {children && children}
    </div>
  );
};

export default RefundValidation;
