import React from 'react';
import styles from './StepIndicatorV3.module.scss';
import { StepIndicatorComponentProps } from '../StepIndicatorComponentProps';
import { defaultStepIndicatorProps } from '../stepIndicatorDefaultProps';

const StepIndicatorV3: React.FC<StepIndicatorComponentProps> = ({ 
  currentStep = defaultStepIndicatorProps.currentStep,
  steps = defaultStepIndicatorProps.steps,
  onStepClick = defaultStepIndicatorProps.onStepClick,
}) => {
  const currentIndex = steps?.indexOf(currentStep!)

  return (
    <div className={styles.stepIndicator}>
      {steps!.map((step, index) => (
        <div
          key={index}
          className={`${styles.step} ${index <= currentIndex! ? styles.active : ''}`}
          onClick={() => onStepClick && onStepClick(step)}
        >
          <div className={styles.number}>{index + 1}</div>
          <div className={styles.label}>{step}</div>
          {index < steps!.length - 1 && (
            <div className={styles.line}>
              <div className={styles.progress} style={{ width: index < currentIndex! ? '100%' : '0' }} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default StepIndicatorV3;
