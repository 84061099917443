import React from 'react'
import styles from '../Component.module.scss';
import TextAreaV1 from '../../components/TextArea/TextAreaV1/TextAreaV1';

const TextAreaComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <TextAreaV1/>
        </div>
    </div>
  )
}

export default TextAreaComponentPage