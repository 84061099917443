import React from 'react'
import styles from '../Component.module.scss';
import SortV1 from '../../UIElements/Sort/SortV1/SortV1';
import SortV2 from '../../UIElements/Sort/SortV2/SortV2';

const SortComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <SortV1/>
        </div>
        <div className={styles.element}>
            <SortV2/>
        </div>
    </div>
  )
}

export default SortComponentPage