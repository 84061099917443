import React from 'react';
import styles from './BreadcrumbV3.module.scss';
import { BreadcrumbComponentProps } from '../BreadcrumbComponentProps';
import { defaultBreadcrumbs } from '../breadcrumbDefaultProps';

const BreadcrumbV3: React.FC<BreadcrumbComponentProps> = ({ breadcrumbs = defaultBreadcrumbs.breadcrumbs }) => {
  return (
    <nav className={styles.breadcrumbContainer}>
      {breadcrumbs?.map((breadcrumb, index) => (
        <span key={index} className={styles.breadcrumbItem}>
          <a href={breadcrumb.href}>{breadcrumb.label}</a>
          {index < breadcrumbs.length - 1 && <span className={styles.separator}>/</span>}
        </span>
      ))}
    </nav>
  );
};

export default BreadcrumbV3;
