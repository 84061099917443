import React from 'react'
import styles from '../Component.module.scss';
import AvatarV1 from '../../components/Avatar/AvatarV1/AvatarV1';
import AvatarV2 from '../../components/Avatar/AvatarV2/AvatarV2';
import AvatarV3 from '../../components/Avatar/AvatarV3/AvatarV3';
import { avatarDefaultProps } from '../../components/Avatar/avatarDefaultProps/avatarDefaultProps';

const AvatarComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <AvatarV1 avatars={[{...avatarDefaultProps}]}/>
        </div>
        <div className={styles.element}>
            <AvatarV2 {...avatarDefaultProps}/>
        </div>
        <div className={styles.element}>
            <AvatarV3 {...avatarDefaultProps}/>
        </div>
    
    </div>
  )
}

export default AvatarComponentPage