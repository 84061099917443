import api from "../config";
import { ShippingMethodType } from "../../types/Data/ShippingSettingsType";
import { AxiosResponse } from "axios";
import { ApiResponse } from "../apiType";

// Create a new shipping method
export async function createShippingMethod(shippingMethodData: ShippingMethodType): Promise<AxiosResponse<ApiResponse<ShippingMethodType>>> {
    try {
        const response = await api.post('/shipping-methods', shippingMethodData);
        return response.data;
    } catch (error) {
        console.error('Error creating shipping method:', error);
        throw error;
    }
}

// Get a shipping method by name
export async function getShippingMethodByName(name: string): Promise<AxiosResponse<ApiResponse<ShippingMethodType>>> {
    try {
        const response = await api.get(`/shipping-methods/${name}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching shipping method with name: ${name}`, error);
        throw error;
    }
}

// Get all shipping methods
export async function getAllShippingMethods(): Promise<AxiosResponse<ApiResponse<ShippingMethodType[]>>> {
    try {
        const response = await api.get('/shipping-methods');
        return response.data;
    } catch (error) {
        console.error('Error fetching all shipping methods:', error);
        throw error;
    }
}

// Update a shipping method by name
export async function updateShippingMethod(name: string, updateData: Partial<ShippingMethodType>): Promise<AxiosResponse<ApiResponse<ShippingMethodType>>> {
    try {
        const response = await api.put(`/shipping-methods/${name}`, updateData);
        return response.data;
    } catch (error) {
        console.error(`Error updating shipping method with name: ${name}`, error);
        throw error;
    }
}

// Disable a shipping method by name
export async function disableShippingMethod(name: string): Promise<AxiosResponse<ApiResponse<ShippingMethodType>>> {
    try {
        const response = await api.patch(`/shipping-methods/${name}/disable`);
        return response.data;
    } catch (error) {
        console.error(`Error disabling shipping method with name: ${name}`, error);
        throw error;
    }
}

// Enable a shipping method by name
export async function enableShippingMethod(name: string): Promise<AxiosResponse<ApiResponse<ShippingMethodType>>> {
    try {
        const response = await api.patch(`/shipping-methods/${name}/enable`);
        return response.data;
    } catch (error) {
        console.error(`Error enabling shipping method with name: ${name}`, error);
        throw error;
    }
}
