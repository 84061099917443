import React from 'react';
import styles from './SearchBarV4.module.scss';
import { SearchBarComponentProps } from '../SearchBarComponentProps';

const SearchBarV4: React.FC<SearchBarComponentProps> = ({ placeholder, value, onSearch }) => {
    return (
        <div className={styles.searchContainer}>
            <input
                className={styles.searchInput}
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={(e) => onSearch && onSearch(e.target.value)}
            />
        </div>
    );
};

export default SearchBarV4;
