import React from "react";
import styles from './RefundProcessList.module.scss';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/store";
import { RefundProcessType } from "../../../types/Data/RefundProcessType";

const RefundProcessList: React.FC = () => {
    const navigate = useNavigate();
    const refundProcess = useSelector<RootState>((state) => state.dashboard.refundProcess.refundProcess) as RefundProcessType[];

    const handleViewRefundProcess = (id: string | number) => {
        navigate(`/dashboard/refund-process/detail/${id}`);
    };

    return (
        <div className={styles.returnRequestList}>
            <table>
                <thead>
                    <tr>
                        <th>Refund ID</th>
                        <th>Customer</th>
                        <th>Order</th>
                        <th>Return Request</th>
                        <th>Total Refund</th>
                        <th>Status</th>
                        <th>Created</th>
                        <th>Updated</th>
                    </tr>
                </thead>
                <tbody>
                    {refundProcess.map((refund) => (
                        <tr key={refund._id} onClick={() => handleViewRefundProcess(refund._id!)}>
                            <td>{refund._id}</td>
                            <td>{refund.customerName}</td>
                            <td>{typeof refund.order === 'string' ? refund.order : refund.order._id}</td>
                            <td>{typeof refund.returnRequest === 'string' ? refund.returnRequest : refund.returnRequest._id}</td>
                            <td>{refund.totalRefundAmount}</td>
                            <td className={`${styles.refundStatus} ${styles[refund.refundStatus.toLowerCase()]}`}>{refund.refundStatus}</td> 
                            <td>{new Date(refund.createdAt!).toLocaleString()}</td>
                            <td>{new Date(refund.updatedAt!).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default RefundProcessList;
