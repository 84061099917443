import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import dashboardReducer from '../Dashboard/DashboardState/DashboardReducer';
import eccomerceReducer from '../Ecommerce/eccomerceState/eccomerceReducer';

// Combine all reducers
const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  ecommerce: eccomerceReducer,
});

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['dashboard', 'ecommerce'], // Exclude dashboard from persistence
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store with persisted reducer
const store = configureStore({
  reducer: persistedReducer,
});

// Create a persistor
const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
