import React from 'react'
import styles from '../Component.module.scss';
import SearchBarV1 from '../../components/SearchBar/SearchBarV1/SearchBarV1';
import SearchBarV2 from '../../components/SearchBar/SearchBarV2/SearchBarV2';
import SearchBarV3 from '../../components/SearchBar/SearchBarV3/SearchBarV3';
import SearchBarV4 from '../../components/SearchBar/SearchBarV4/SerachBarV4';
import SearchBarV5 from '../../components/SearchBar/SearchBarV5/SearchBarV5';

const SearchBarComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <SearchBarV1/>
        </div>
        <div className={styles.element}>
            <SearchBarV2/>
        </div>
        <div className={styles.element}>
            <SearchBarV3/>
        </div>
        <div className={styles.element}>
            <SearchBarV4/>
        </div>
        <div className={styles.element}>
            <SearchBarV5/>
        </div>
    </div>
  )
}

export default SearchBarComponentPage