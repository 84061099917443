import React from "react";
import styles from './FooterPaymentMethod.module.scss';
import { FaCcVisa } from "react-icons/fa";
import { PaymentMethodType } from "../../../../types/Data/PayementSettingsType";

interface FooterPaymentMethodProps {
    payments: PaymentMethodType[];
}

const FooterPaymentMethod: React.FC<FooterPaymentMethodProps> = ({ payments }) => {
    return (
        <div className={styles.footerContainer}>
            <div className={styles.label}> <FaCcVisa/> Accepted Payment Methods</div>
            <div className={styles.paymentMethods}>
                {payments.map((method, index: number) => (
                    <div className={styles.paymentIconWrapper}>
                    <img
                        key={index}
                        src={`/payment-method/${method.name.toLowerCase()}.png`}
                        alt={method.name}
                        className={styles.paymentIcon}
                    />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FooterPaymentMethod;
