import React, { useState } from 'react';
import styles from './ChatRoomV1.module.scss';
import { FiSend, FiUser, FiUserCheck } from 'react-icons/fi';
import { MessageType } from '../../../types/Data/MessageType';
import { ChatRoomProps } from '../ChatRoomType';

const ChatRoomV1: React.FC<ChatRoomProps> = ({ messages, onSendMessage, adminTyping, customerTyping }) => {
    const [newMessage, setNewMessage] = useState('');

    const handleSendMessage = () => {
        if (newMessage.trim()) {
            onSendMessage(newMessage.trim());
            setNewMessage('');
        }
    };

    return (
        <div className={styles.chatRoom}>
            <div className={styles.messages}>
                {messages.map((message: MessageType) => (
                    <div
                        key={message._id}
                        className={`${styles.message} ${message.senderModel === 'admin' ? styles.adminMessage : styles.customerMessage}`}
                    >
                        <div className={styles.sender}>
                            {message.senderModel === 'admin' ? <FiUserCheck /> : <FiUser />} {message.sender}
                        </div>
                        <div className={styles.content}>
                            {message.content}
                        </div>
                        <div className={styles.timestamp}>
                            {new Date(message.createdAt).toLocaleTimeString()}
                        </div>
                    </div>
                ))}
                {adminTyping && <div className={styles.typingIndicator}>Admin is typing...</div>}
                {customerTyping && <div className={styles.typingIndicator}>Customer is typing...</div>}
            </div>
            <div className={styles.inputArea}>
                <input
                    type="text"
                    placeholder="Type a message..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                />
                <button onClick={handleSendMessage} className={styles.sendButton}>
                    <FiSend />
                </button>
            </div>
        </div>
    );
};

export default ChatRoomV1;
