import { BannerSlideType } from "../../types/Data/BannerSlideType";
import PromotionalBannerV1 from "../../UIElements/PromotionalBanner/PromotionalBannerV1/PromotionalBannerV1";
import PromotionalBannerV2 from "../../UIElements/PromotionalBanner/PromotionalBannerV2/PromotionalBannerV2";
import PromotionalBannerV3 from "../../UIElements/PromotionalBanner/PromotionalBannerV3/PromotionalBannerV3";
import PromotionalBannerV4 from "../../UIElements/PromotionalBanner/PromotionalBannerV4/PromotionalBannerV4";

enum PromotionalBannerVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
    V4 = "v4",
};

const PromotionalBannerMapping:Record<PromotionalBannerVersions, React.FC<any>> = {
    [PromotionalBannerVersions.V1]: PromotionalBannerV1,
    [PromotionalBannerVersions.V2]: PromotionalBannerV2,
    [PromotionalBannerVersions.V3]: PromotionalBannerV3,
    [PromotionalBannerVersions.V4]: PromotionalBannerV4,

};

interface PromotionBannerComponentProps {
    banner?: {
        title?: string,
        text?: string,
        imageUrl?: string,
        buttonText?: string,
        buttonUrl?: string,
    }
};

export {
    PromotionalBannerMapping,
    PromotionalBannerVersions,
};

export type {
    PromotionBannerComponentProps,
};