import React from 'react';
import styles from './FeedbackFilters.module.scss';
import Select4 from '../../../components/Select/Select4/Select4';
import SearchBarV4 from '../../../components/SearchBar/SearchBarV4/SerachBarV4';

interface FeedbackFiltersProps {
  query: {
    search: string | undefined;
    status: string | undefined;
    rating?: number | undefined;
  };
  onQueryChange: (key: string, value: any) => void;
}

const Filters: React.FC<FeedbackFiltersProps> = ({ query, onQueryChange = () => null }) => {
  const statusOptions = [
    { label: "All", value: "" },
    { label: "New", value: "new" },
    { label: "Reviewed", value: "reviewed" },
    { label: "Resolved", value: "resolved" }
  ];

  const ratingOptions = [
    { label: "All Ratings", value: "" },
    { label: "1 Star", value: 1 },
    { label: "2 Stars", value: 2 },
    { label: "3 Stars", value: 3 },
    { label: "4 Stars", value: 4 },
    { label: "5 Stars", value: 5 }
  ];

  return (
    <div className={styles.filters}>
      <SearchBarV4
        placeholder="Search by customer name, email, or transaction ID..."
        value={query.search}
        onSearch={(search) => onQueryChange('search', search)}
      />
      <div className={styles.row}>
        <Select4 
          value={query.status} 
          options={statusOptions} 
          onChange={(status) => onQueryChange('status', status)} 
        />
        <Select4
          value={query.rating}
          options={ratingOptions}
          onChange={(rating) => onQueryChange('rating', rating)}
        />
      </div>
    </div>
  );
};

export default Filters;
