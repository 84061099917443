import axios, { AxiosResponse } from 'axios';
import api from '../config';
import { ApiResponse } from '../apiType';
import { NotificationType } from '../../types/Data/NotificationType';

interface GetNotificationParams {
  search?: string;
  category?: string;
  sortBy?: string;
  order?: 'asc' | 'desc';
  page?: number;
  limit?: number;
}


// Get all notifications with optional filters
export const getAllNotifications = async (
  params: GetNotificationParams = {}
): Promise<{ notifications: NotificationType[]; total: number; page: number; pages: number }> => {
  try {
    const response: AxiosResponse<ApiResponse<{ notifications: NotificationType[]; total: number; page: number; pages: number }>> = await api.get('/notifications', {
      params,
    });
    return response.data.data;
  } catch (error: any) {
    console.error('Error fetching notifications:', error);
    throw new Error(error.response?.data?.message || 'Failed to fetch notifications');
  }
};

// Get a single notification by ID
export const getNotificationById = async (id: string): Promise<NotificationType> => {
  try {
    const response: AxiosResponse<ApiResponse<NotificationType>> = await api.get(`/notifications/${id}`, {});
    return response.data.data;
  } catch (error: any) {
    console.error(`Error fetching notification with ID ${id}:`, error);
    throw new Error(error.response?.data?.message || 'Failed to fetch notification');
  }
};

// Create a new notification
export const createNotification = async (notificationData: Partial<NotificationType>): Promise<NotificationType> => {
  try {
    const response: AxiosResponse<ApiResponse<NotificationType>> = await api.post('/notifications', notificationData, {});
    return response.data.data;
  } catch (error: any) {
    console.error('Error creating notification:', error);
    throw new Error(error.response?.data?.message || 'Failed to create notification');
  }
};

// Update a notification by ID
export const updateNotification = async (id: string, notificationData: Partial<NotificationType>): Promise<NotificationType> => {
  try {
    const response: AxiosResponse<ApiResponse<NotificationType>> = await api.put(`/notifications/${id}`, notificationData, {});
    return response.data.data;
  } catch (error: any) {
    console.error(`Error updating notification with ID ${id}:`, error);
    throw new Error(error.response?.data?.message || 'Failed to update notification');
  }
};

// Mark a notification as read by ID
export const markNotificationAsRead = async (id: string): Promise<NotificationType> => {
  try {
    const response: AxiosResponse<ApiResponse<NotificationType>> = await api.patch(`/notifications/${id}/read`, null, {});
    return response.data.data;
  } catch (error: any) {
    console.error(`Error marking notification with ID ${id} as read:`, error);
    throw new Error(error.response?.data?.message || 'Failed to mark notification as read');
  }
};

// Delete a notification by ID
export const deleteNotification = async (id: string): Promise<void> => {
  try {
    await api.delete(`/notifications/${id}`, {});
  } catch (error: any) {
    console.error(`Error deleting notification with ID ${id}:`, error);
    throw new Error(error.response?.data?.message || 'Failed to delete notification');
  }
};

// Mark all notifications as read
export const markAllNotificationsAsRead = async (): Promise<{ modifiedCount: number }> => {
  try {
    const response: AxiosResponse<ApiResponse<{ modifiedCount: number }>> = await api.patch('/notifications/markAllRead', null, {});
    return response.data.data;
  } catch (error: any) {
    console.error('Error marking all notifications as read:', error);
    throw new Error(error.response?.data?.message || 'Failed to mark all notifications as read');
  }
};

// Delete all notifications
export const deleteAllNotifications = async (): Promise<{ deletedCount: number }> => {
  try {
    const response: AxiosResponse<ApiResponse<{ deletedCount: number }>> = await api.delete('/notifications', {});
    return response.data.data;
  } catch (error: any) {
    console.error('Error deleting all notifications:', error);
    throw new Error(error.response?.data?.message || 'Failed to delete all notifications');
  }
};
