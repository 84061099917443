import ProductPriceV1 from "./ProductPriceV1/ProductPriceV1";

enum ProductPriceVersions {
    V1 = "v1",
};

const ProductPriceMapping:Record<ProductPriceVersions, React.FC<any>> = {
    [ProductPriceVersions.V1]: ProductPriceV1,
};

interface ProductPriceComponentProps {
    price?: number;
    promoPrice?: number;
    currency?: string;
    showPromoBadge?: boolean;
    promoBadgeText?: string;
    textColor?: string;
    promoColor?: string;
    fontSize?: string;
};

export {
    ProductPriceVersions,
    ProductPriceMapping
};

export type {
    ProductPriceComponentProps
};