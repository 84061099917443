import { NotificationType } from "../../../types/Data/NotificationType";

const notificationsExample: NotificationType[] = [
  {
    _id: '1',
    type: 'Order',
    title: 'New Order Received',
    description: 'You have received a new order.',
    createdAt: new Date('2024-08-03'),
    isRead: false,
    priority: 'high',
    userId: 'customer123', // ID of the user/customer related to this notification
    relatedId: 'order5678', // Related order ID
    link: '/orders/5678', // Link to view the order
    iconUrl: '/icons/order.png', // Icon representing an order
    extraData: { orderAmount: '$150.00', itemCount: 3 }, // Optional extra data
  },
  {
    _id: '2',
    type: 'Message',
    title: 'New Message',
    description: 'You have received a new message.',
    createdAt: new Date('2024-08-03'),
    isRead: true,
    priority: 'medium',
    userId: 'customer456', // ID of the sender
    relatedId: 'message1234', // Related message ID
    link: '/messages/1234', // Link to view the message
    iconUrl: '/icons/message.png', // Icon representing a message
    extraData: { senderName: 'John Doe', messageSnippet: 'Hello, I need help with...' },
  },
  {
    _id: '3',
    type: 'Alert',
    title: 'Stock Alert',
    description: 'Stock is running low on some items.',
    createdAt: new Date('2024-08-03'),
    isRead: false,
    priority: 'high',
    relatedId: 'product5678', // Related product ID
    link: '/products/5678', // Link to view the product
    iconUrl: '/icons/alert.png', // Icon for alerts
    extraData: { productName: 'Wireless Headphones', currentStock: 5 },
  },
  {
    _id: '4',
    type: 'Promotion',
    title: 'Special Promotion',
    description: 'A new promotion has been launched.',
    createdAt: new Date('2024-08-03'),
    isRead: false,
    priority: 'low',
    link: '/promotions', // Link to view promotions
    iconUrl: '/icons/promotion.png', // Icon for promotions
    extraData: { discount: '20%', validUntil: '2024-08-10' },
  },
];

export default notificationsExample;
