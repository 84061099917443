import React from 'react';
import styles from './TeamMemberCardV3.module.scss';
import { FaLinkedin, FaTwitter, FaGithub } from 'react-icons/fa';
import { TeamMemberComponentProps } from '../TeamMemberComponentProps';
import { defaultTeamMemberProps } from '../teamMemebrCardDefaultProps';

const TeamMemberCardV3: React.FC<TeamMemberComponentProps> = ({
    name = defaultTeamMemberProps.name,
    position = defaultTeamMemberProps.position,
    photoUrl = defaultTeamMemberProps.photoUrl,
    bio = defaultTeamMemberProps.bio,
    socialLinks = defaultTeamMemberProps.socialLinks
}) => {
  return (
    <div className={styles.card}>
      <img src={photoUrl} alt={name} className={styles.photo} />
      <div className={styles.overlay}>
        <h2>{name}</h2>
        <p className={styles.position}>{position}</p>
        <p className={styles.bio}>{bio}</p>
        <div className={styles.socialLinks}>
          {socialLinks!.linkedin && <a href={socialLinks!.linkedin}><FaLinkedin /></a>}
          {socialLinks!.twitter && <a href={socialLinks!.twitter}><FaTwitter /></a>}
          {socialLinks!.github && <a href={socialLinks!.github}><FaGithub /></a>}
        </div>
      </div>
    </div>
  );
};

export default TeamMemberCardV3;
