import React, { useState } from 'react';
import ProductCard from './ProductCard';
import styles from './ProductPickerV2.module.scss';
import { ProductType } from '../../../types/Data/ProductType';
import exampleProducts from '../../../Dashboard/DashboardState/products/dataProducts';

interface ProductListProps {
    selectedProductIds: string[];
    onSelectProduct: (id: string) => void;
}

const ProductPickerV2: React.FC<ProductListProps> = ({ selectedProductIds, onSelectProduct }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const products = [...exampleProducts] as Partial<ProductType[]>

    const filteredProducts = products.filter(product =>
        product?.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={styles.product_list}>
            <h4 className={styles.title}>Select Product</h4>
            <input
                type="text"
                placeholder="Search products..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={styles.search_bar}
            />
            <div className={styles.product_list_container}>
                {filteredProducts.map(product => (
                    <ProductCard
                        key={product?._id!}
                        product={product!}
                        isSelected={selectedProductIds.includes(product?._id!)}
                        onSelect={onSelectProduct}
                    />
                ))}
            </div>
        </div>
    );
};

export default ProductPickerV2;
