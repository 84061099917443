import React from 'react';
import styles from './PromotionCard.module.scss';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import SwitchV1 from '../../../../components/Switch/SwitchV1/SwitchV1';
import { PromotionType } from '../../../../types/Data/PromotionType';

interface PromotionCardProps {
  promotion: Partial<PromotionType>;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onToggleActive: (id: string, isActive: boolean) => void;
}

const PromotionCard: React.FC<PromotionCardProps> = ({
  promotion,
  onEdit,
  onDelete,
  onToggleActive,
}) => {
  const handleToggle = () => {
    onToggleActive(promotion._id!, !promotion.isActive);
  };

  return (
    <div className={styles.promotionCard}>
      <div className={styles.cardHeader}>
        <h3>{promotion.name}</h3>
        <SwitchV1 onToggle={() => handleToggle()} isChecked={promotion.isActive} />
      </div>
      <p className={styles.description}>{promotion.description}</p>
      <div className={styles.promotionDetails}>
        <span className={styles.promotionType}>{promotion.promotionType}</span>
        <span className={styles.promotionDate}>Start: {new Date(promotion.startDate!).toLocaleDateString()}</span>
        <span className={styles.promotionDate}>End: {new Date(promotion.endDate!).toLocaleDateString()}</span>
      </div>
      <div className={styles.cardFooter}>
        <button className={styles.editButton} onClick={() => onEdit(promotion._id!)}>
          <FiEdit2 /> Edit
        </button>
        <button className={styles.deleteButton} onClick={() => onDelete(promotion._id!)}>
          <FiTrash2 /> Delete
        </button>
      </div>
    </div>
  );
};

export default PromotionCard;
