import React from 'react';
import styles from './CustomerList.module.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { CustomerType } from '../../../types/Data/CustomerType';

interface CustomerListProps {
    onCustomerDetailView?: (customerId: string) => void;
}

const CustomerList: React.FC<CustomerListProps> = ({ onCustomerDetailView }) => {
    const navigate = useNavigate();
    
    const customers = useSelector((state: RootState) => state.dashboard.customers.customers) as CustomerType[];

    const handleCustomerDetailView = (id: string) => {
        if (onCustomerDetailView) {
            onCustomerDetailView(id);
        } else {
            navigate(`./${id}`);
        }
    }

    return (
        <div className={styles.customerList}>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Shipping Address</th>
                        <th>Total Spend</th>
                        <th>Number of Orders</th>
                        <th>Last Order Date</th>
                        <th>Loyalty Level</th>
                    </tr>
                </thead>
                <tbody>
                    {customers.map(customer => (
                        <tr key={customer._id} onClick={() => handleCustomerDetailView(customer._id)}>
                            <td>{customer.name}</td>
                            <td>{customer.email}</td>
                            <td>{customer.phone || 'N/A'}</td>
                            <td>{customer.shippingAddress?.street ? `${customer.shippingAddress.street}, ${customer.shippingAddress.city}` : 'N/A'}</td>
                            <td>${customer.totalSpend.toFixed(2)}</td>
                            <td>{customer.numOrders}</td>
                            <td>{new Date(customer.lastOrderDate).toLocaleDateString()}</td>
                            <td>{customer.loyaltyLevel || 'N/A'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CustomerList;
