// src/components/Filters.tsx
import React, { useState } from 'react';
import styles from './Filters.module.scss';
import InputPriceRange from '../../../components/Input/InputPriceRange/InputPriceRangeV1/InputPriceRangeV1';
import Checkbox1 from '../../../components/Checkbox/CheckboxV1/CheckboxV1';
import SearchBar4 from '../../../components/SearchBar/SearchBarV4/SerachBarV4';
import Select4 from '../../../components/Select/Select4/Select4';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { CategoryType } from '../../../types/Data/CategoryType';

interface QueryType {
    search?: string;
    category?: string;
    sortBy?: string;
    rating?: number;
    minPrice?: number;
    maxPrice?: number;
    inStock?: boolean;
}

interface FiltersProps {
    query: QueryType;
    onQueryChange: (key: keyof QueryType, value: any) => void;
}
const Filters: React.FC<FiltersProps> = ({
    query={},
    onQueryChange=()=>null
}) => {

    const categories = useSelector<RootState>((state)=>state.dashboard.categories.categories) as CategoryType[];
    const categoriesOptions = categories.map(({name}) => ({label:name, value:name}))
    const options = [ {label: "Sort By", value: ""}, {label: "Name", value: "name"}, {label: "Price", value: "price"}, {label: "Rating", value: "rating"} ];
    const ratings = [ { label: "All Ratings", value:0 }, { label: "1 Star & Up", value:1 }, { label: "2 Stars & Up", value:2 }, { label: "3 Stars & Up", value:3 }, { label:"4 Stars & Up", value:4 }, { label:"5 Stars", value:5 }  ];

    
    return (
        <div className={styles.filters} >
             <SearchBar4
                placeholder="Search products..."
                value={query.search}
                onSearch={(value)=>onQueryChange('search', value)}
            />
            <div className={styles.selectRow}>
                <Select4
                    options={categoriesOptions}
                    placeholder='Categories'
                    onChange={(value)=>onQueryChange('category', value as string)}
                    value={query.category}
                />
                <Select4
                    options={options}
                    placeholder='Sort By'
                    onChange={(value)=> onQueryChange('sortBy', value as string)}
                    value={query.sortBy}
                />
                <Select4
                    options={ratings}
                    placeholder='Rating'
                    onChange={(value) => onQueryChange('rating', value as number)}
                    value={query.rating}
                />
                <InputPriceRange 
                    minValue={query.minPrice} 
                    maxValue={query.maxPrice}
                    onChange={(min, max) => {
                        onQueryChange('minPrice', min)
                        onQueryChange('maxPrice', max)
                    }}
                    
                    />
                <Checkbox1 
                    label='In Stock' 
                    checked={query.inStock} 
                    onChange={()=>onQueryChange('inStock', !query.inStock)}/>
            </div>
        </div>
    );
};

export default Filters;
