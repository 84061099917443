import React from 'react';
import styles from './FeedbackStats.module.scss';
import { FaComment, FaStar } from 'react-icons/fa';

interface FeedbackStatsProps {
  feedbackData: any[];
}

const FeedbackStats: React.FC<FeedbackStatsProps> = ({ feedbackData }) => {
  const totalFeedback = feedbackData.length;
  const averageRating = (feedbackData.reduce((sum, feedback) => sum + feedback.rating, 0) / totalFeedback).toFixed(2);

  return (
    <div className={styles.feedback_stats}>
      <div className={styles.cards}>
        <div className={styles.card}>
          <FaComment className={styles.icon} />
          <div className={styles.card_content}>
            <p>Total Feedback</p>
            <h3>{totalFeedback}</h3>
          </div>
        </div>
        <div className={styles.card}>
          <FaStar className={styles.icon} />
          <div className={styles.card_content}>
            <p>Average Rating</p>
            <h3>{averageRating}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackStats;
