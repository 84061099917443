import React from 'react';
import styles from './CartSummarySidebarV1.module.scss';
import { defaultCartSummaryProps } from '../cartSummaryDefaultPorps';
import { CartSummarySidebarComponentProps } from '../CartSummarySidebarComponentProps';

const CartSummarySidebarV1: React.FC<CartSummarySidebarComponentProps> = ({ 
  items = defaultCartSummaryProps.items, 
  discount = defaultCartSummaryProps.discount,
  tax = defaultCartSummaryProps.tax, 
  deliveryPrice = defaultCartSummaryProps.deliveryPrice,
  onCheckout = () => null
 }) => {
  const subtotal = items!.reduce((acc, item) => acc + item.unitPrice * item.quantity, 0);
  const discountAmount = discount ? (subtotal * discount) / 100 : 0;
  const taxAmount = (subtotal * tax!) / 100;
  const total = subtotal - discountAmount + taxAmount + deliveryPrice!;

  return (
    <div className={styles.cartSummary}>
      <h2 className={styles.title}>Your Order</h2>
      <div className={styles.itemsList}>
        {items!.map(item => (
          <div key={item.productId} className={styles.item}>
            <img src={item.imageUrl} alt={item.name} className={styles.itemImage} />
            <div className={styles.itemDetails}>
              <h4 className={styles.itemName}>{item.name}</h4>
              <p className={styles.itemQuantity}>Quantity: {item.quantity}</p>
              <p className={styles.itemPrice}>${item.unitPrice.toFixed(2)}</p>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.priceDetails}>
        <p>Subtotal: ${subtotal.toFixed(2)}</p>
        {discount && discount > 0 && <p>Discount: -${discountAmount.toFixed(2)}</p>}
        <p>Tax: ${taxAmount.toFixed(2)}</p>
        <p>Delivery: ${deliveryPrice!.toFixed(2)}</p>
        <h3 className={styles.total}>Total: ${total.toFixed(2)}</h3>
      </div>
      <button className={styles.checkoutButton} onClick={onCheckout}>
        Proceed to Checkout
      </button>
    </div>
  );
};

export default CartSummarySidebarV1;
