import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import exampleSubscribers from './exampleSubscribers';
import { SubscriberType } from '../../../types/Data/SubscriberType';
import { getAllSubscribers } from '../../../api/Subscriber/subscriberApi';

// Create the async thunk for fetching products
export const fetchSubscribers = createAsyncThunk(
  'subscriber/fetchSubscribers',
  async (params: { page?: number; [key: string]: any } = {}, { rejectWithValue }) => {
    try {
      // Pass the params to getAllPromotions
      const response = await getAllSubscribers();
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch promotions');
    }
  }
);

interface SubscribersState {
  subscribers: SubscriberType[];
  loading: boolean;
  error: string | null | {}
}

const initialState: SubscribersState = {
  subscribers: exampleSubscribers,
  loading: false,
  error: null,
};

const subscribersSlice = createSlice({
  name: 'subscribers',
  initialState,
  reducers: {
    addSubscriber(state, action: PayloadAction<SubscriberType>) {
      state.subscribers.push(action.payload);
    },
    updateSubscriber(state, action: PayloadAction<SubscriberType>) {
      const index = state.subscribers.findIndex(sub => sub.id === action.payload.id);
      if (index !== -1) {
        state.subscribers[index] = action.payload;
      }
    },
    deleteSubscriber(state, action: PayloadAction<string>) {
      state.subscribers = state.subscribers.filter(sub => sub.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchSubscribers.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchSubscribers.fulfilled, (state, action: PayloadAction<{ subscribers: SubscriberType[] }>) => {
            state.loading = false;
            state.subscribers = action.payload.subscribers;
        })
        .addCase(fetchSubscribers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload || action.error.message || 'Unknown error';
        });
    }
});

export const { addSubscriber, updateSubscriber, deleteSubscriber } = subscribersSlice.actions;
export default subscribersSlice.reducer;
