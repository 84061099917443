import React from 'react';
import styles from './OrderTimestamps.module.scss';

interface TimestampsProps {
    timestamps: {
        orderDate: Date;
        paymentDate?: Date | null;
        shippingDate?: Date | null;
        deliveryDate?: Date | null;
    };
}

const OrderTimestamps: React.FC<TimestampsProps> = ({ timestamps }) => {
    const { orderDate, paymentDate, shippingDate, deliveryDate } = timestamps;

    // Utility function to format the date or display 'N/A'
    const formatDate = (date?: Date | null) => {
        return date ? new Date(date).toLocaleDateString() : 'N/A';
    };

    return (
        <div className={styles.orderTimestamps}>
            <h2>Order Timeline</h2>
            <div className={styles.timestampRow}>
                <strong>Order Date:</strong>
                <p>{formatDate(orderDate)}</p>
            </div>
            <div className={styles.timestampRow}>
                <strong>Payment Date:</strong>
                <p>{formatDate(paymentDate)}</p>
            </div>
            <div className={styles.timestampRow}>
                <strong>Shipping Date:</strong>
                <p>{formatDate(shippingDate)}</p>
            </div>
            <div className={styles.timestampRow}>
                <strong>Delivery Date:</strong>
                <p>{formatDate(deliveryDate)}</p>
            </div>
        </div>
    );
};

export default OrderTimestamps;
