import React from 'react';
import { EmailTemplateType } from '../../../../types/Data/EmailTemplateType';
import { ProductType } from '../../../../types/Data/ProductType';
import { RootState } from '../../../../Store/store';
import { useSelector } from 'react-redux';

const styles: { [key: string]: React.CSSProperties } = {
    emailSkeleton: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        maxWidth: '100%',
        height: '100%',
        margin: '0 auto',
        border: '1px solid #ddd',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
        color: '#333',
        backgroundColor: '#fff'
    },
    emailHeader: {
        backgroundColor: '#2ecc71',
        color: '#fff',
        padding: '20px',
        textAlign: 'center'
    },
    emailHeaderH1: {
        margin: 0,
        fontSize: '24px'
    },
    emailBody: {
        padding: '20px'
    },
    emailBodyP: {
        lineHeight: '1.5',
        fontSize: '16px'
    },
    productSection: {
        marginTop: '20px'
    },
    productSectionH2: {
        fontSize: '20px',
        borderBottom: '2px solid #ddd',
        paddingBottom: '10px',
        marginBottom: '20px'
    },
    productList: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px'
    },
    productItem: {
        width: 'calc(50% - 10px)',
        background: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '10px',
        textAlign: 'center',
        transition: 'transform 0.3s ease, boxShadow 0.3s ease',
        cursor: 'pointer'
    },
    productItemHover: {
        transform: 'translateY(-5px)',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)'
    },
    productItemImg: {
        width: '100%',
        borderRadius: '5px',
        marginBottom: '10px'
    },
    productItemH3: {
        margin: '10px 0 5px',
        fontSize: '16px',
        color: '#333'
    },
    productItemP: {
        margin: '5px 0',
        fontSize: '14px',
        color: '#666'
    },
    emailFooter: {
        backgroundColor: '#f1f1f1',
        padding: '20px',
        textAlign: 'center'
    },
    emailFooterP: {
        margin: '5px 0',
        fontSize: '14px',
        color: '#666'
    },
};

const NewProductEmailSkeleton: React.FC<EmailTemplateType> = ({ data }) => {
    
    const products = useSelector<RootState>((state) => 
        state.dashboard.products.products.filter((product) => 
            data?.productIds?.includes(product._id as string) ?? false
        )
    ) as Partial<ProductType>[]
    
    return (
        <div style={styles.emailSkeleton}>
            <style>
            {`@keyframes fadeIn: {
                from: {
                    opacity: 0,
                    transform: 'translateY(-10px)'
                },
                to: {
                    opacity: 1,
                    transform: 'translateY(0)'
                }
            },
            @keyframes slideIn: {
                from: {
                    opacity: 0,
                    transform: 'translateX(-100%)'
                },
                to: {
                    opacity: 1,
                    transform: 'translateX(0)'
                }
            }`}
            </style>
            <header style={styles.emailHeader}>
                <h1 style={styles.emailHeaderH1}>{data?.title}</h1>
            </header>
            <main style={styles.emailBody}>
                { data?.body && <div dangerouslySetInnerHTML={{ __html: data?.body }} /> }
                {products && products.length > 0 && (
                    <div style={styles.productSection}>
                        <h2 style={styles.productSectionH2}>New Arrivals</h2>
                        <div style={styles.productList}>
                            {products && products.length > 0 && products!.map(product => (
                                <div key={product._id!} style={styles.productItem}>
                                    {product.images && <img src={product?.images![0].url} alt={product?.name} style={styles.productItemImg} />}
                                    <h3 style={styles.productItemH3}>{product?.name}</h3>
                                    {product?.categories && <p style={styles.productItemP}>{product?.categories[0]}</p>}
                                    <p style={styles.productItemP}>${product?.price}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </main>
            <footer style={styles.emailFooter}>
                <p style={styles.emailFooterP}>Don't miss out on our new products!</p>
                <p style={styles.emailFooterP}>Unsubscribe | Contact Us</p>
            </footer>
        </div>
    );
};

export default NewProductEmailSkeleton;
