import React, { useState } from 'react';
import styles from './ProductInfoCardV3.module.scss';
import { ProductInfoCardComponentProps } from '../ProductInfoCardComponentProps';
import { defaultProductInfoCardProps } from '../defaultProductInfoCardProps';



const ProductInfoCardV3:React.FC<ProductInfoCardComponentProps> = ({ product = defaultProductInfoCardProps }) => {
  const [activeTab, setActiveTab] = useState('details');

  return (
    <div className={styles.card}>
      <h2>{product.name}</h2>
      <div className={styles.tabs}>
        <button onClick={() => setActiveTab('details')} className={activeTab === 'details' ? styles.active : ''}>
          Details
        </button>
        <button onClick={() => setActiveTab('specs')} className={activeTab === 'specs' ? styles.active : ''}>
          Specs
        </button>
      </div>
      <div className={styles.tabContent}>
        {activeTab === 'details' ? (
          <>
            <p>{product.description}</p>
            <ul className={styles.features}>
              {product?.attributes!.map((attribute, index) => (
                <li key={index}>{attribute.value}</li>
              ))}
            </ul>
          </>
        ) : (
          <ul className={styles.specs}>
            {product.specs && Object.entries(product.specs!).map(([key, value], index) => (
              <li key={index}>{key}: {value}</li>
            ))}
          </ul>
        )}
      </div>
      <p className={styles.price}>${product.price}</p>
    </div>
  );
};

export default ProductInfoCardV3;
