import FeaturesSectionV1 from "./FeatureSectionV1/FeatureSectionV1";
import FeaturesSectionV2 from "./FeatureSectionV2/FeatureSectionV2";




enum FeatureSectionVersions {
    V1 = "v1",
    V2 = "v2",
};

const FeatureSectionMapping:Record<FeatureSectionVersions, React.FC<any>> = {
    [FeatureSectionVersions.V1]: FeaturesSectionV1,
    [FeatureSectionVersions.V2]: FeaturesSectionV2,
    

};

interface FeatureProps {
    title: string;
    description: string;
    icon: string;
};
  
interface FeaturesSectionComponentProps {
    title?: string;
    description?: string;
    features?: FeatureProps[];
};

export {
    FeatureSectionVersions,
    FeatureSectionMapping
};

export type {
    FeatureProps,
    FeaturesSectionComponentProps
};