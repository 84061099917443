import React from 'react';
import styles from './CancellationList.module.scss';
import { useNavigate } from 'react-router-dom';
import { CancellationType } from '../../../types/Data/CancellationType';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';


interface CancellationTableProps {
    onCheckRefund?: (orderId: number | string) => void;
}

const CancellationList: React.FC<CancellationTableProps> = ({ onCheckRefund}) => {
    const navigate = useNavigate();

    const cancellations = useSelector((state: RootState) => state.dashboard.cancellations.cancellations) as CancellationType[]
    
    const handleCancelDetailView = (id: number | string) => {
        navigate(`./${id}`);
    }
    
    return (
        <div className={styles.cancellationtList}>
            <table>
                <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>Customer</th>
                        <th>Cancellation Date</th>
                        <th>Reason</th>
                        <th>Status</th>
                        <th>Payment Status</th>
                    </tr>
                </thead>
                <tbody>
                    {cancellations.map(cancellation => (
                        <tr key={cancellation._id} onClick={()=>handleCancelDetailView(cancellation._id)}>
                            <td>{cancellation._id}</td>
                            <td>{cancellation?.customerName}</td>
                            <td>{cancellation.createdAt!.toDateString()}</td>
                            <td>{cancellation.reason}</td>
                            <td className={`order_status ${cancellation?.status!.toLowerCase()}`}>{cancellation.status}</td>
                            <td className={`payment-status ${cancellation?.paymentInfo?.paymentStatus?.toLowerCase()}`}>{cancellation?.paymentInfo?.paymentStatus}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CancellationList;
