import React, { useEffect, useState } from 'react';
import styles from './OrderDetail.module.scss';
import { useParams } from 'react-router-dom';
import OrderInfo from './OrderInfo/OrderInfo';
import OrderShipping from './OrderShipping/OrderShipping';
import EmailSender from '../../../components/EmailEditor/EmailEditorV2/EmailEditorV2';
import CommentBox from '../../../components/TextArea/CommentBox/CommentBox';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { OrderStatus as OrderStatusType, OrderType } from '../../../types/Data/OrderType';
import OrderPayment from './OrderPayment/OrderPayment';
import OrderItems from './OrderItems/OrderItems';
import OrderCustomerInfo from './OrderCustomerInfo/OrderCustomerInfo';
import OrderSummary from './OrderSummary/OrderSummary';
import OrderTimestamps from './OrderTimestamps/OrderTimestamps';
import OrderStatusHistory from './OrderStatusHistory/OrderStatusHistory';
import OrderCoupon from './OrderCoupon/OrderCoupon';
import OrderNotes from './OrderNotes/OrderNotes';
import OrderStatus from './OrderStatus/OrderStatus';
import OrderAdress from './OrderAdress/OrderAdress';
import { AddressType } from '../../../types/Data/AdressType';
import SaveButton from '../../../components/Button/SaveButton/SaveButton';
import { getOrderById, patchOrder, updateOrder } from '../../../api/order/orderApi';
import { handleApiError } from '../../../api/apiError';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';


const OrderDetail: React.FC = () => {
    const dispatch = useDispatch();
    const { orderId } = useParams<{ orderId: string }>();
    const orders = useSelector<RootState>(state=>state.dashboard.orders.orders) as OrderType[];

    const order = useSelector<RootState>(state=>state.dashboard.orders.orders.find(el=>el._id === orderId)) as OrderType
    const [emailMessage, setEmailMessage] = useState('');

    // State variables for original and current review
    const [originalOrder, setOriginalOrder] = useState<OrderType>(order || {});
    const [currentOrder, setCurrentOrder] = useState<OrderType>(order || {});

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<{message:string, status:number | undefined} | null>()
    const [isSaving, setIsSaving] = useState<boolean>(false);

    // Function to handle updates to specific sections of the order
    const handleUpdateOrderSegment = (segment: Partial<OrderType>) => {
        const updatedOrder = { ...currentOrder, ...segment };
        setCurrentOrder(updatedOrder);
        if (onUpdateOrder) {
            onUpdateOrder(updatedOrder); // Callback to update the order in the backend
        }
    };

    const handleSendEmail = () => {
        alert('Email sent')
    };

    const onUpdateOrder = (order: OrderType) => {
        alert('Order updated')
    };

    const getOrder = async (reviewId: string) => {
        setIsLoading(true); // Set loading to true before request
        try {
          const response = await getOrderById(reviewId);
          if (response.status === 200) return response.data.data;
        } catch (error: any) {
          if (error.name === 'AbortError') {
            console.log('Request cancelled');
          } else {
            let handledError = handleApiError(error);
            setError({ message: handledError.message, status: handledError.status });
          }
        } finally {
          setIsLoading(false); // Ensure loading is turned off
        }
    };

      /*
      useEffect(() => {  
        if (orderId) {
          getOrder(orderId)
            .then(data => {
              if (data) {
                setOriginalOrder(data);  // Set the original review
                setCurrentOrder(data);   // Set the current review
              }
            })
            .catch(error => {
              let handledError = handleApiError(error);
              setError({ message: handledError.message, status: handledError.status });
            });
        }
      
        // Cleanup on component unmount or page change
        return () => {
    
        };
      }, [orderId]);
      */

    
    const handleSave = async () => {
        setIsSaving(true);  // To handle button loading state
        try {
          const response = await updateOrder(currentOrder._id!, currentOrder);  // Call the API to save the review
          
          if (response.status === 200) {
            // Assuming 200 is the success status code
            setOriginalOrder(response.data.data)
            alert('Order updated successfully!');  // Success message
            
            // Optionally reset form or state if needed
          } else {
            // Handle unexpected success cases
            alert('Something went wrong while updating the order!');
          }
        } catch (error: any) {
          const handledError = handleApiError(error);  // This function should parse and return user-friendly error messages
          alert(`Failed to save order: ${handledError}`);
        } finally {
          setIsSaving(false);  // Stop loading state
        }
    };

    const handlePatch = async (data:Partial<OrderType>) => {
        setIsSaving(true);  // To handle button loading state
        try {
          const response = await patchOrder(currentOrder._id!, data);  // Call the API to save the review
          
          if (response.status === 200) {
            // Assuming 200 is the success status code
            setOriginalOrder(response.data.data)
            alert('Order updated successfully!');  // Success message
            
            // Optionally reset form or state if needed
          } else {
            // Handle unexpected success cases
            alert('Something went wrong while updating the order!');
          }
        } catch (error: any) {
          const handledError = handleApiError(error);  // This function should parse and return user-friendly error messages
          alert(`Failed to save order: ${handledError}`);
        } finally {
          setIsSaving(false);  // Stop loading state
        }
    };

    if (!order) {
        return (
            <p>No order found...</p>
        )
    };

    return (
        <div className={styles.orderDetailPage}>
            <DashboardHeader title='Order Detail'/>
            {isLoading && <LoadingIndicatorV1 />}
            {error && <ErrorBannerV1 message={error.message} status={error.status} />}
            <div className={styles.content}>
                <OrderInfo order={currentOrder}/>
                <OrderCustomerInfo customer={order?.customer} />
                <OrderSummary summary={order?.orderSummary}/>
                <OrderTimestamps timestamps={order?.timestamps}/>
                <OrderStatusHistory statusHistory={order?.statusHistory!}/>
                <OrderCoupon coupon={order?.coupons!}/>
                <OrderStatus 
                    order={currentOrder}
                    onOrderStatusChange={(status)=>handleUpdateOrderSegment({status:status as OrderStatusType})}
                >
                    <SaveButton style={{marginTop: 20}}/>
                </OrderStatus>
                <OrderItems 
                    items={currentOrder?.items!} 
                    onItemUpdate={(updatedItems)=>handleUpdateOrderSegment({items:updatedItems})}
                >
                    <SaveButton
                     onClick={()=>handlePatch({items:currentOrder.items})}

                    /> 
                </OrderItems>
                <OrderShipping 
                    order={currentOrder}
                    onUpdate={(updatedShipping)=>handleUpdateOrderSegment({shipping:updatedShipping})}
                >
                    <SaveButton 
                        style={{marginTop: 20}}
                        onClick={()=>handlePatch({shipping:currentOrder.shipping})}
                    />
                </OrderShipping>
                <OrderPayment 
                    order={currentOrder}
                    onUpdate={(updatedPayment)=>handleUpdateOrderSegment({payment:updatedPayment})}
                >
                    <SaveButton 
                        style={{marginTop: 20}}
                        onClick={()=>handlePatch({payment:currentOrder.payment})}
                    />
                </OrderPayment>
                <OrderAdress 
                    shippingAddress={currentOrder.shippingAddress} 
                    billingAddress={currentOrder.billingAddress}
                    onSaveBillingAddress={(updatedAdress)=>handleUpdateOrderSegment({billingAddress:updatedAdress as AddressType})}
                    onSaveShippingAddress={(updatedAdress)=>handleUpdateOrderSegment({shippingAddress:updatedAdress as AddressType})}
                />
                <OrderNotes 
                    notes={currentOrder?.notes!} 
                    allowAdminEdit 
                    onUpdateNotes={(updatedNotes)=>handleUpdateOrderSegment({notes:updatedNotes})}
                >
                    <SaveButton                          
                        onClick={()=>handlePatch({notes:currentOrder.notes})}
                    />  
                </OrderNotes>
                <CommentBox
                    comment={currentOrder?.comments!}
                    onSubmit={()=> handleUpdateOrderSegment({comments:currentOrder?.comments})}
                />
                <EmailSender 
                    onSend={handleSendEmail}
                />
            </div>
            <SaveButton 
                style={{width:'100%'}} 
                onClick={()=>handleSave()}/>
        </div>
    );
};

export default OrderDetail;
