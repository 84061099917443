import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ComponentList from '../ComponentList/ComponentList';
import renderPageElement from '../../../Ecommerce/renderPageElement';
import { BaseElementType, ComponentType } from '../../../types/Data/BaseElementType';
import styles from './PageEdition.module.scss';
import RenderComponentEditor from '../RenderComponentEditor/RenderComponentEditor';
import SlideOverV3 from '../../../components/SlideOver/SlideOverV3/SliderOverV3';
import { TabNavigationComponentProps } from '../../../UIElements/TabNavigation/TabNavigationComponentProps';
import { FooterComponentProps } from '../../../UIElements/Footer/FooterComponentProps';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';

const PageEdition: React.FC = () => {
    const { page } = useParams<string>() || 'home';
    const elements = useSelector((state:RootState)=> state.dashboard.elements.elements).filter((el) => el.page === page) as BaseElementType<any>[];
    const tabNavigation = useSelector((state:RootState)=> state.dashboard.elements.elements).find((el)=>el.type==='tabNavigation') as BaseElementType<TabNavigationComponentProps>;
    const footer = useSelector((state:RootState)=> state.dashboard.elements.elements).find((el)=>el.type==='footer') as BaseElementType<FooterComponentProps>;

    const [currentElements, setCurrentElements] = useState(elements);  // Manage added elements
    const [selectedElement, setSelectedElement] = useState<BaseElementType<any> | null>(null);  // Manage selected element for modal

    console.log(tabNavigation)
    // Handle drag and drop
    const onDragEnd = (result: any) => {
        const { destination, source } = result;
        if (!destination) return;

        const updatedElements = Array.from(currentElements);
        const [movedElement] = updatedElements.splice(source.index, 1);
        
        // Update the position with new order based on destination index
        updatedElements.splice(destination.index, 0, movedElement);

        // Update the position property for each element after dragging
        updatedElements.forEach((el, index) => {
            el.position = {
                ...el.position,
                order: index + 1,  // Update order based on the new index
            };
        });

        setCurrentElements(updatedElements);
    };

    const openEditModal = (element: BaseElementType<any>) => {
        setSelectedElement(element);
    };

    if (page === "tabNavigation" && tabNavigation) {
        return (
            <RenderComponentEditor element={tabNavigation}/>
        )
    }

    if (page === "footer" && footer) {
        return (
            <RenderComponentEditor element={footer}/>
        )
    }
    else return (
        <div className={styles.pageEditor}>
            {/* Component list on the left */}
            <aside className={styles.componentList}>
                <ComponentList onElementClick={(type)=>openEditModal({type:type as ComponentType, version:"v1", page:page ?? 'home', isVisible:true})}/>
            </aside>

            {/* Editable components area */}
            <div className={styles.editorArea}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="editorComponents">
                        {(provided) => (
                            <div
                                className={styles.droppableArea}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {currentElements.map((element, index) => {
                            const Component = renderPageElement(element);
                            const { row, column, span, order, spanRows } = element.position || {};

                            return (
                                <Draggable key={element._id || index} draggableId={element._id || String(index)} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                ...provided.draggableProps.style,
                                                gridColumn: column ? `${column} / span ${span || 1}` : '1 / span 12',  // Start at column 1 and span all 12 columns by default
                                                gridRow: row && spanRows ? `${row} / span ${spanRows}` : row || 'auto',  // Handle grid row placement dynamically
                                                order: order || 'initial', // Handle default order
                                            }}
                                        >
                                            <div className={styles.componentWrapper} onClick={() => openEditModal(element)}>
                                                <Component {...element.props} />
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            );
                        })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>

            {/* Edit modal */}
            {selectedElement && (
                <SlideOverV3 isOpen={selectedElement ? true : false} onClose={() => setSelectedElement(null)}>
                    {/* Render the edit form for the selected element */}
                    <h2 className={styles.modalTitle}>Edit {selectedElement.type.split(/(?=[A-Z])/).join(' ')}</h2>
                    <div className={styles.editForm}>
                        <RenderComponentEditor element={selectedElement}/>
                    </div>
                </SlideOverV3>
            )}
        </div>
    );
};

export default PageEdition;
