// promotionService.js

import { AxiosPromise } from 'axios';
import { PromotionType } from '../../types/Data/PromotionType';
import api from '../config';
import { ApiResponse } from '../apiType';

// Create a new promotion (admin route)
export const createPromotion = async (promotionData:PromotionType) => {
    return api.post('/promotions', promotionData);
};

// Update an existing promotion (admin route)
export const updatePromotion = async (promotionId:string, promotionData:PromotionType) => {
    return api.put(`/promotions/${promotionId}`, promotionData);
};

// Delete a promotion (admin route)
export const deletePromotion = async (promotionId:string) => {
    return api.delete(`/promotions/${promotionId}`);
};

// Activate a promotion (admin route)
export const activatePromotion = async (promotionId:string) => {
    return api.post(`/promotions/${promotionId}/activate`);
};

// Deactivate a promotion (admin route)
export const deactivatePromotion = async (promotionId:string) => {
    return api.post(`/promotions/${promotionId}/deactivate`);
};

// Get all promotions (public route)
export const getAllPromotions = async ():Promise<AxiosPromise<ApiResponse<{promotions: PromotionType[]}>>> => {
    return api.get('/promotions');
};

// Get a promotion by id (public route)
export const getPromotionById = async (promotionId: string) => {
    return api.get(`/promotions/${promotionId}`);
};

// Check if a promotion is active (public route)
export const isPromotionActive = async (promotionId:string) => {
    return api.get(`/promotions/${promotionId}/is-active`);
};

// Validate promotion conditions (public route)
export const alidatePromotionConditions = async (promotionId: string, conditions: PromotionType['conditions']) => {
    return api.post(`/promotions/${promotionId}/validate`, conditions);
};

// Apply promotions to a cart (public route)
export const applyPromotionsToCart = async (cartData: any) => {
    return api.post('/promotions/apply-to-cart', cartData);
};

// Get effective prices with promotions applied (public route)
export const getEffectiveProductPrices = async (priceData: any) => {
    return api.post('/promotions/get-effective-prices', priceData);
};

// Deactivate expired promotions (optional admin route)
export const deactivateExpiredPromotions = async () => {
    return api.post('/promotions/deactivate-expired');
};

