import React, { useEffect, useState, useRef } from 'react';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import styles from './FeaturedProductsV6.module.scss';
import exampleProducts from '../../../Dashboard/DashboardState/products/dataProducts';
import { FeaturedProductsComponentProps } from '../FeaturedProductsComponentProps';

const FeaturedProductsV6: React.FC<FeaturedProductsComponentProps> = ({ products = exampleProducts.slice(0, 5) }) => {
  const scrollContainer = useRef<HTMLDivElement>(null);
  const scrollIndicator = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    scrollContainer?.current?.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    scrollContainer?.current?.scrollBy({ left: 300, behavior: 'smooth' });
  };

  // Update scroll progress by directly manipulating the DOM element
  const updateScrollProgress = () => {
    if (scrollContainer.current && scrollIndicator.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.current;
      const maxScrollLeft = scrollWidth - clientWidth;
      const progress = (scrollLeft / maxScrollLeft) * 100;

      // Update the width of the scroll indicator directly
      scrollIndicator.current.style.width = `${progress}%`;
    }
  };

  useEffect(() => {
    const container = scrollContainer.current;
    if (container) {
      container.addEventListener('scroll', updateScrollProgress);
      return () => {
        container.removeEventListener('scroll', updateScrollProgress);
      };
    }
  }, []);

  return (
    <div className={styles.carouselContainer}>
      <button className={styles.prevButton} onClick={scrollLeft}>
        <IoChevronBackOutline />
      </button>
      <div className={styles.carousel} ref={scrollContainer}>
        {products.map((product, index) => (
          <div key={index} className={styles.productCard}>
            <img src={product?.images![0].url} alt={product.name} className={styles.productImage} />
            <div className={styles.productInfo}>
              <h3 className={styles.productName}>{product.name}</h3>
              <p className={styles.productPrice}>${product.price}</p>
            </div>
          </div>
        ))}
      </div>
      <button className={styles.nextButton} onClick={scrollRight}>
        <IoChevronForwardOutline />
      </button>

      {/* Scroll Indicator */}
      <div className={styles.scrollIndicatorContainer}>
        <div ref={scrollIndicator} className={styles.scrollIndicator} />
      </div>
    </div>
  );
};

export default FeaturedProductsV6;
