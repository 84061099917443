import React from 'react';
import styles from './PromotionalBannerV2.module.scss';
import { PromotionBannerComponentProps } from '../PromotionalBannerComponentProps';

const PromotionalBannerV2: React.FC<PromotionBannerComponentProps> = ({ banner}) => {
  return (
    <div className={styles.bannerContainer}>
      <div className={styles.bannerImage} style={{ backgroundImage: `url(${banner?.imageUrl!})` }} />
      <div className={styles.bannerContent}>
        <h1>{banner?.title!}</h1>
        {banner?.text && <h2>{banner?.text!}</h2>}
        <a href={banner?.buttonUrl!} className={styles.callToAction}>{banner?.buttonText}</a>
      </div>
    </div>
  );
};

export default PromotionalBannerV2;
