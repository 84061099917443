// PaymentMethodForm.tsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input4 from '../../../../components/Input/InputText/InputV4/InputV4';
import Button1 from '../../../../components/Button/ButtonV1/ButtonV1';
import styles from './PaymentMethodEditPage.module.scss';
import Switch1 from '../../../../components/Switch/SwitchV1/SwitchV1';
import { dispatchAddPayementMethod, dispatchUpdatePayementMethod } from '../../../DashboardState/paymentMethods/paymentMethodsSlice';
import { PaymentMethodType } from '../../../../types/Data/PayementSettingsType';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../../Store/store';
import { createPaymentMethod, getPaymentMethodByName } from '../../../../api/paymentMethod/paymentMethodApi';
import { handleApiError } from '../../../../api/apiError';
import { FaHandHoldingMedical } from 'react-icons/fa';
import DashboardHeader from '../../../../components/Header/DashboardHeader/DashboardHeader';
import SaveButton from '../../../../components/Button/SaveButton/SaveButton';
import ButtonCancel from '../../../../components/Button/ButtonCancel/ButtonCancel';

const PaymentMethodEditPage: React.FC = ({ }) => {
  const dispatch = useDispatch();
  
  const location = useLocation();
  const paymentMethodName = new URLSearchParams(location.search).get('paymentMethodName');
  
  const paymentMethodToEdit = useSelector((state: RootState) => state.dashboard.paymentMethods.methods.find((el)=>el.name===paymentMethodName)) as PaymentMethodType

  const defaultPaymentMethod: PaymentMethodType = {
    name: '',
    publicKey: '',
    privateKey: '',
    accessToken: '',
    clientID: '',
    clientSecret: '',
    apiKey: '',
    enabled: false,
  };

  const [originalPaymentMethod, setOriginalPaymentMethod] = useState<PaymentMethodType>(paymentMethodToEdit || defaultPaymentMethod);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<PaymentMethodType>(paymentMethodToEdit || defaultPaymentMethod);
  
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const getPaymentMethod = async () => {
    setIsLoading(true);
    try {
      const response = await getPaymentMethodByName(paymentMethodName!);
      if (response.status === 200 && response.data) {
        setOriginalPaymentMethod(response.data.data);
        setCurrentPaymentMethod(response.data.data);
      } else {
        alert('Error while retrieving payment method!')
      }
    } catch (error:any) {
      const handledError = handleApiError(error);
      setError({message: handledError.message, status: handledError.status})
    } finally {
      setIsLoading(false)
    }
  };

  /*
  useEffect(() => {
    if (paymentMethodName) {
      getPaymentMethod()
    } else {
      setOriginalPaymentMethod(defaultPaymentMethod)
      setCurrentPaymentMethod(defaultPaymentMethod)
    }
  }, [paymentMethodName]);
  */

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setCurrentPaymentMethod({
      ...currentPaymentMethod,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSavePaymentMethod = async () => {
    setIsSaving(true);
    try {
      const response = await createPaymentMethod(currentPaymentMethod);
      if (response.status === 201 && response.data) {
        setOriginalPaymentMethod(response.data.data)
        setCurrentPaymentMethod(response.data.data)
        alert('Payment method created!')
      } else {
        alert('An error occured while creating payment method!')
      }
    } catch (error:any) {
      const handledError = handleApiError(error);
      alert(handledError.message)
    } finally {
      setIsSaving(false)
    }
  };

  const handleUpdatePaymentMethod = async () => {
    setIsSaving(true);
    try {
      const response = await createPaymentMethod(currentPaymentMethod);
      if (response.status === 201 && response.data) {
        setOriginalPaymentMethod(response.data.data)
        setCurrentPaymentMethod(response.data.data)
        alert('Payment method updated!')
      } else {
        alert('An error occured while updating payment method!')
      }
    } catch (error:any) {
      const handledError = handleApiError(error);
      alert(handledError.message)
    } finally {
      setIsSaving(false)
    }
  };

  const handleCancel = () => {
    setCurrentPaymentMethod(originalPaymentMethod)
  };

  return (
    <div>
      <DashboardHeader title={paymentMethodName ? 'Edit Payment Method' : 'Add Payment Method'}/>
      <div className={styles.paymentMethod_form}>
        <Input4
          label="Payment Method Name"
          type="text"
          id="paymentMethodName"
          name="name"
          value={currentPaymentMethod.name}
          onChange={handleChange}
          required
        />
      
      {currentPaymentMethod.publicKey !== undefined && (
        <div className={styles.form_group}>
          <Input4
            label="Public Key"
            type="text"
            id="publicKey"
            name="publicKey"
            value={currentPaymentMethod.publicKey}
            onChange={handleChange}
          />
        </div>
      )}
      {currentPaymentMethod.privateKey !== undefined && (
        <div className={styles.form_group}>
          <Input4
            label="Private Key"
            type="text"
            id="privateKey"
            name="privateKey"
            value={currentPaymentMethod.privateKey}
            onChange={handleChange}
          />
        </div>
      )}
      {currentPaymentMethod.accessToken !== undefined && (
        <div className={styles.form_group}>
          <Input4
            label="Access Token"
            type="text"
            id="accessToken"
            name="accessToken"
            value={currentPaymentMethod.accessToken}
            onChange={handleChange}
          />
        </div>
      )}
      {currentPaymentMethod.clientID !== undefined && (
        <div className={styles.form_group}>
          <Input4
            label="Client Id"
            type="text"
            id="clientId"
            name="clientId"
            value={currentPaymentMethod.clientID}
            onChange={handleChange}
          />
        </div>
      )}
      {currentPaymentMethod.clientSecret !== undefined && (
        <div className={styles.form_group}>
          <Input4
            label="Client Secret"
            type="text"
            id="clientSecret"
            name="clientSecret"
            value={currentPaymentMethod.clientSecret}
            onChange={handleChange}
          />
        </div>
      )}
      {currentPaymentMethod.apiKey !== undefined && (
        <div className={styles.form_group}>
          <Input4
            label="Api Key"
            type="text"
            id="apiKey"
            name="apiKey"
            value={currentPaymentMethod.privateKey}
            onChange={handleChange}
          />
        </div>
      )}
      <div className={styles.toggle_switch}>
        <label htmlFor="enabled">Enable {currentPaymentMethod.name}</label>
        <Switch1 isChecked={currentPaymentMethod.enabled} onToggle={() => handleChange({ target: { name: 'enabled', value: !currentPaymentMethod.enabled, type: 'checkbox' } } as any)} />
      </div>
      </div>
      <div className={styles.button_container}>
        <SaveButton onClick={paymentMethodName ? handleSavePaymentMethod : handleUpdatePaymentMethod} isSaving/>
        <ButtonCancel onClick={handleCancel} style={{marginLeft: 15}}/>
      </div>
    </div>
  );
};

export default PaymentMethodEditPage;
