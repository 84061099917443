import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './HomePage/HomePage';
import './App.scss';
import Component from './ComponentPage/Component';
import Dashboard from './Dashboard/Dashboard';
import DashboardHome from './Dashboard/Home/DashboardHome';
import CategoryDetail from './Dashboard/Categories/CategoryDetail/CategoryDetail';
import OrderDetail from './Dashboard/Orders/OrderDetail/OrderDetailPage';
import CancellationsPage from './Dashboard/Cancellelations/CancellationsPage';
import CancellationDetails from './Dashboard/Cancellelations/CancellationDetails/CancellationDetails';
import CustomersList from './Dashboard/Customers/CustomersPage';
import CustomerDetails from './Dashboard/Customers/CustomerDetails/CustomerDetails';
import CustomersFeedback from './Dashboard/CustomersFeedback/CustomersFeedbackPage';
import DiscountCodePage from './Dashboard/DiscountCode/DiscountCodePage';
import PromotionPage from './Dashboard/Promotion/PromotionPage';
import EditPromotion from './Dashboard/Promotion/EditPromotion/EditPromotion';
import EditBanner from './Dashboard/Promotion/EditBanner/EditBanner';
import { store, persistor } from './Store/store';
import { Provider } from 'react-redux';
import ProductEditPage from './Dashboard/Products/ProductAdd/ProductEditPage';
import CategoriesPage from './Dashboard/Categories/CategoriesPage';
import OrdersPage from './Dashboard/Orders/OrdersPage';
import EmailcampaignsPage from './Dashboard/EmailCampaigns/EmailCampaignsPage';
import SubscriberPage from './Dashboard/Subscribers/SubscribersPage';
import SettingsPage from './Dashboard/Settings/SettingsPage';
import PaymentSettingsPage from './Dashboard/Settings/PaymentMethods/PaymentMethodsPage';
import ShippingSettingsPage from './Dashboard/Settings/ShippingMethods/ShippingMethodsPage';
import TaxSettingsPage from './Dashboard/Settings/TaxSettings/TaxSettingsPage';
import BlogPostListPage from './Dashboard/BlogPost/BlogPostListPage';
import BlogPostEditorPage from './Dashboard/BlogPost/BlogPostEditorPage/BlogPostEditorPage';
import { PersistGate } from 'redux-persist/integration/react';

import ReviewListPage from './Dashboard/Reviews/ReviewListPage';
import ReviewEditPage from './Dashboard/Reviews/ReviewDetail/ReviewDetailPage';
import ProductListPage from './Dashboard/Products/ProductListPage';
import FeedbackDetails from './Dashboard/CustomersFeedback/FeedbackDetail/FeedbackDetails';
import EditDiscountCodePage from './Dashboard/DiscountCode/EditDiscountCode/EditDiscountCodePage';
import EmailCampaignsEditor from './Dashboard/EmailCampaigns/Editor/EmailCampaignsEditor';
import EmailTemplateListPage from './Dashboard/MarketingEmails/EmailTemplates/EmailTemplatesListPage';
import SalesReportPage from './Dashboard/Analytics/Orders/SalesAnalyticsPage';
import CustomerAnalyticsPage from './Dashboard/Analytics/Customers/CustomerAnalyticsPage.module';
import AdminUserPage from './Dashboard/Admins/AdminPage';
import NotificationsListPage from './Dashboard/Notification/NotificationListPage';
import EcommerceHome from './Ecommerce/Home/EcommerceHome';
import EcommerceShop from './Ecommerce/Shop/EcommerceShop';
import EcommerceProduct from './Ecommerce/Product/EcommerceProduct';
import EcommerceCart from './Ecommerce/CheckoutSection/CheckoutCartPage/EcommerceCartPage';
import EcommerceLogin from './Ecommerce/Login/EcommerceLogin';
import EcommerceRegister from './Ecommerce/Register/EcommerceRegister';
import EcommerceAboutUs from './Ecommerce/AboutUs/EcommerceAboutUs';
import EcommerceContactUs from './Ecommerce/ContactUs/EcommerceContactUs';
import EcommerceFAQ from './Ecommerce/FAQ/EcommerceFAQ';
import EcommercePrivatePolicy from './Ecommerce/PrivatePolicy/EcommercePrivatePolicy';
import EcommerceTermAndCondition from './Ecommerce/TermsOfService/EcommerceTermAndCondition';
import AlertComponentPage from './ComponentPage/Pages/AlertComponentPage';
import BadgeComponentPage from './ComponentPage/Pages/BadgeComponentPage';
import AvatarComponentPage from './ComponentPage/Pages/AvatarComponentPage';
import BillingInformationFormComponentPage from './ComponentPage/Pages/BillingInformationFormPage';
import BlogSectionComponentPage from './ComponentPage/Pages/BlogSectionComponentPage';
import BreadcrumbComponentPage from './ComponentPage/Pages/BreadcrumbComponentPage';
import ButtonComponentPage from './ComponentPage/Pages/ButtonComponentPage';
import ButtonAddToCartComponentPage from './ComponentPage/Pages/ButtonAddToCartPage';
import ButtonBuyComponentPage from './ComponentPage/Pages/ButtonBuyComponentPage';
import ButtonCheckoutComponentPage from './ComponentPage/Pages/ButtonCheckoutComponentPage';
import ButtonWishlistComponentPage from './ComponentPage/Pages/ButtonWishlistComponentPage';
import CartSummarySidebarComponentPage from './ComponentPage/Pages/CartSummarySidebarComponentPage';
import CategoryDisplayComponentPage from './ComponentPage/Pages/CategoryDisplayComponentPage';
import CartListItemComponentPage from './ComponentPage/Pages/CartListItemComponentPage';
import ColorSelectorComponentPage from './ComponentPage/Pages/ColorSelectorComponentPage';
import CompanyHistoryComponentPage from './ComponentPage/Pages/CompanyHistoryComponentPage';
import CompanyOverviewComponentPage from './ComponentPage/Pages/CompanyOverviewComponentPage';
import ContactFormComponentPage from './ComponentPage/Pages/ContactFormComponentPage';
import ContactInfoComponentPage from './ComponentPage/Pages/ContactInfoComponentPage';
import CouponPromotionComponentPage from './ComponentPage/Pages/CouponPromotionComponentPage';
import CustomerSupportComponentPage from './ComponentPage/Pages/CustomerSupportComponentPage';
import FeaturedProductComponentPage from './ComponentPage/Pages/FeaturedProductComponentPage';
import HeroBannerComponentPage from './ComponentPage/Pages/HeroBannerComponentPage';
import IncentiveComponentPage from './ComponentPage/Pages/IncentiveComponentPage';
import NewsLetterSignupComponentPage from './ComponentPage/Pages/NewsLetterSignupComponentPage';
import OrderConfirmationComponentPage from './ComponentPage/Pages/OrderConfirmationComponentPage';
import OrderErrorComponentPage from './ComponentPage/Pages/OrderErrorComponentPage';
import OrderSummaryComponentPage from './ComponentPage/Pages/OrderSummaryComponentPage';
import PaginationComponentPage from './ComponentPage/Pages/PaginationComponentPage';
import PricingCardComponentPage from './ComponentPage/Pages/PricingCardComponentPage';
import PaymentSelectorComponentPage from './ComponentPage/Pages/PaymentSelectorComponentPage';
import ProductDescriptionComponentPage from './ComponentPage/Pages/ProductDescriptionComponentPage';
import ProductCardListComponentPage from './ComponentPage//Pages/ProductCardListComponentPage';
import ProductImageGalleryComponentPage from './ComponentPage/Pages/ProductImageGalleryComponentPage';
import TrustSignalsComponentPage from './ComponentPage/Pages/TrustSignalsComponentPage';
import TrustBadgeIconComponentPage from './ComponentPage/Pages/TrustBadgeIconComponentPage';
import TestimonialComponentPage from './ComponentPage/Pages/TestimonialComponentPage';
import TeamMemberComponentPage from './ComponentPage/Pages/TeamMemberComponentPage';
import StepIndicatorComponentPage from './ComponentPage/Pages/StepIndicatorComponentPage';
import ShippingInformationFormComponentPage from './ComponentPage/Pages/ShippingInformationFormComponentPage';
import SocialMediaLinkComponentPage from './ComponentPage/Pages/SocialMediaLinkComponentPage';
import PromotionBannerComponentPage from './ComponentPage/Pages/PromotionBannerComponentPage';
import SortComponentPage from './ComponentPage/Pages/SortComponentPage';
import QAComponentPage from './ComponentPage/Pages/QAComponentPage';
import SizeSelectorComponentPage from './ComponentPage/Pages/SizeSelectorComponentPage';
import QuantitySelectorComponentPage from './ComponentPage/Pages/QuantitySelectorComponentPage';
import ProductReviewComponentPage from './ComponentPage/Pages/ProductReviewComponentPage';
import ProductListComponentPage from './ComponentPage/Pages/ProductListComponentPage';
import ProductInfoCardComponentPage from './ComponentPage/Pages/ProductInforCardComponentPage';
import SliderComponentPage from './ComponentPage/Pages/SliderComponentPage';
import SlideOverComponentPage from './ComponentPage/Pages/SlideOverComponentPage';
import FilterComponentPage from './ComponentPage/Pages/FilterComponentPage';
import DialogComponentPage from './ComponentPage/Pages/DialogComponentPage';
import InputComponentPage from './ComponentPage/Pages/InputComponentPage';
import TabComponentPage from './ComponentPage/Pages/TabComponentPage';
import SwitchComponentPage from './ComponentPage/Pages/SwitchComponentPage';
import StatsComponentPage from './ComponentPage/Pages/StatsComponentPage';
import TextAreaComponentPage from './ComponentPage/Pages/TextAreaComponentPage';
import RichTextEditorComponentPage from './ComponentPage/Pages/RichTextEditorComponentPage';
import EmailEditorComponentPage from './ComponentPage/Pages/EmailEditorComponentPage';
import SearchBarComponentPage from './ComponentPage/Pages/SearchBarComponentPage';
import ReviewSubmissionComponentPage from './ComponentPage/Pages/ReviewSubmissionComponentPage';
import FileUploadComponentPage from './ComponentPage/Pages/FileUploadComponentPage';
import DragAndDropComponentPage from './ComponentPage/Pages/DragAndDropComponentPage';
import ButtonGroupComponentPage from './ComponentPage/Pages/ButtonGroupComponentPage';
import CalendarComponentPage from './ComponentPage/Pages/CalendarComponentPage';
import CheckboxComponentPage from './ComponentPage/Pages/CheckboxComponentPage';
import ColorPickerComponentPage from './ComponentPage/Pages/ColorPickerComponentPage';
import ProductVariantEditorComponentPage from './ComponentPage/Pages/ProductVariantEditorComponentPage';
import FeatureSectionComponentPage from './ComponentPage/Pages/FeatureSectionComponentPage';
import RegistrationFormComponentPage from './ComponentPage/Pages/RegistrationFormComponentPage';
import ProductAnalyticsPage from './Dashboard/Analytics/Products/ProductAnalyticsPage';
import FAQsPage from './Dashboard/Support/faqs/FAQsPage';
import TicketsPage from './Dashboard/Support/tickets/TicketsPage';
import TicketDetailPage from './Dashboard/Support/tickets/ticketDetail/TicketDetailPage';
import EcommerceLayout from './Ecommerce/EcommerLayout';

import ReturnRequestPage from './Dashboard/ReturnRequest/ReturnRequestPage';
import ReturnRequestDetailPage from './Dashboard/ReturnRequest/ReturnRequestDetail/ReturnRequestDetailPage';
import RefundProcessPage from './Dashboard/RefundProcess/RefundProcessPage';
import RefundProcessDetailPage from './Dashboard/RefundProcess/RefundProcessDetail/RefundProcessDetailPage';
import EmailCampaignDetailPage from './Dashboard/EmailCampaigns/EmailCampaignsDetails/EmailCampaignDetailPage';
import ShippingMethodEditPage from './Dashboard/Settings/ShippingMethods/ShippingMethodEdit/ShippingMethodEditPage';
import PaymentMethodEditPage from './Dashboard/Settings/PaymentMethods/EditPaymentMethod/PaymentMethodEditPage';
import SocialMediaEditPage from './Dashboard/Settings/SocialMediaLink/SocialMediaEdit/SocialMediaEditPage';
import CustomerOrderHistoryPage from './Ecommerce/CustomerSection/CustomerOrderHistoryPage/CustomerOrderHistoryPage';
import CustomerWishListPage from './Ecommerce/CustomerSection/CustomerWishListPage/CustomerWishListPage';
import CustomerOrderDetailPage from './Ecommerce/CustomerSection/CustomerOrderDetailPage/CustomerOrderdDetailPage';
import CustomerReturnPage from './Ecommerce/CustomerSection/CustomerReturnRequesttPage/CustomerReturnRequesttPage';
import BlogPage from './Ecommerce/Blog/BlogPage';
import ScrollToTop from './utils/hooks/ScrollToTop';
import InventoryPage from './Dashboard/Inventory/InventoryPage';
import ImagesPage from './Dashboard/Images/ImagesPage';
import ImageEditPage from './Dashboard/Images/ImagesEdit/ImageEditPage';
import EditCategoryPage from './Dashboard/Categories/EditCategoryPage/EditCategoryPage';
import PageList from './Dashboard/ContentManagement/PageList/PageList';
import PageEdition from './Dashboard/ContentManagement/PageEdition/PageEdition';
import SocialMediaLinksPage from './Dashboard/Settings/SocialMediaLink/SocialMediaLinksPage';
import CustomerProfilePage from './Ecommerce/CustomerSection/CustomerProfilPage/CustomerProfilPage';
import CheckoutCartPage from './Ecommerce/CheckoutSection/CheckoutCartPage/EcommerceCartPage';
import CheckoutBillingShippingPage from './Ecommerce/CheckoutSection/CheckoutBillingShippingPage/BillingShippingPage';
import CheckoutPaymentPage from './Ecommerce/CheckoutSection/CheckoutPaymentPage/CheckoutPaymentPage';
import CheckoutOrderReviewPage from './Ecommerce/CheckoutSection/CheckoutOrderReviewPage/CheckoutOrderReviewPage';
import CustomerMessageHistoryPage from './Ecommerce/CustomerSection/CustomerMessageHistoryPage/CustomerMessageHistoryPage';
import CustomerChatRoomPage from './Ecommerce/CustomerSection/CustomerChatRoomPage/CustomerChatRoomPage';
import CreateOrderPage from './Dashboard/Orders/CreateOrder/CreateOrderPage';
import CreateReturnPage from './Dashboard/ReturnRequest/CreateReturn/CreateReturnPage';
import CreateCencellationPage from './Dashboard/Cancellelations/CreateCancellation/CreateCancellationPage';
import AdminEditorPage from './Dashboard/Admins/AdminEditor/AdminEditorPage';
import CreateAdminPage from './Dashboard/Admins/CreateAdmin/CreateAdminPage';
import PrivatePolicySettingPage from './Dashboard/PrivatePolicy/PrivatePolicySettingPage';
import TermOfServiceSettingPage from './Dashboard/termOfService/TermOfServiceSettingPage';
import ImageUploadPage from './Dashboard/Images/ImageUploadPage/ImageUploadPage';


const App: React.FC = () => {
  const links = [
    { text: 'Home', href: '/' },
    { text: 'Component', href: '/component'},
    { text: 'Dashboard', href: '/dashboard' },
    { text: 'ECommerce', href: '/ecommerce' },

  ];

  const user_options = [
    { text: 'Login', href: '/' },
    { text: 'Sign Up', href: '/about' },
  ];


  return (
    <Router>
      <AppContent links={links} user_options={user_options} />
    </Router>
  );
};

const AppContent: React.FC<{ links: any[], user_options: any[] }> = ({ links, user_options }) => {
  const location = useLocation();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <section style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
          <main style={{ overflowY: 'scroll' }}>
          <ScrollToTop/>
            <Routes>
              <Route path="/" element={<HomePage />} />
              
              <Route path="/component" element={<Component />} />
              <Route path="/component/alert" element={<AlertComponentPage />} />
              <Route path="/component/badge" element={<BadgeComponentPage />} />
              <Route path="/component/avatar" element={<AvatarComponentPage />} />
              <Route path="/component/billingInformationForm" element={<BillingInformationFormComponentPage />} />
              <Route path="/component/blogSection" element={<BlogSectionComponentPage />} />
              <Route path="/component/breadcrumb" element={<BreadcrumbComponentPage />} />
              <Route path="/component/button" element={<ButtonComponentPage />} />
              <Route path="/component/buttonAddToCart" element={<ButtonAddToCartComponentPage />} />
              <Route path="/component/buttonBuy" element={<ButtonBuyComponentPage />} />
              <Route path="/component/buttonCheckout" element={<ButtonCheckoutComponentPage />} />
              <Route path="/component/buttonWishlist" element={<ButtonWishlistComponentPage />} />
              <Route path="/component/cartSummarySidebar" element={<CartSummarySidebarComponentPage />} />
              <Route path="/component/categoryDisplay" element={<CategoryDisplayComponentPage />} />
              <Route path="/component/cartListItem" element={<CartListItemComponentPage />} />
              <Route path="/component/colorSelector" element={<ColorSelectorComponentPage />} />
              <Route path="/component/companyHistory" element={<CompanyHistoryComponentPage />} />
              <Route path="/component/companyOverview" element={<CompanyOverviewComponentPage />} />
              <Route path="/component/contactForm" element={<ContactFormComponentPage />} />
              <Route path="/component/contactInfo" element={<ContactInfoComponentPage />} />
              <Route path="/component/couponPromotion" element={<CouponPromotionComponentPage />} />
              <Route path="/component/customerSupport" element={<CustomerSupportComponentPage />} />
              <Route path="/component/featuredProduct" element={<FeaturedProductComponentPage />} />
              <Route path="/component/heroBanner" element={<HeroBannerComponentPage />} />
              <Route path="/component/incentive" element={<IncentiveComponentPage />} />
              <Route path="/component/newsLetterSignup" element={<NewsLetterSignupComponentPage />} />
              <Route path="/component/orderConfirmation" element={<OrderConfirmationComponentPage />} />
              <Route path="/component/orderError" element={<OrderErrorComponentPage />} />
              <Route path="/component/orderSummary" element={<OrderSummaryComponentPage />} />
              <Route path="/component/pagination" element={<PaginationComponentPage />} />
              <Route path="/component/pricingCard" element={<PricingCardComponentPage />} />
              <Route path="/component/paymentMethodSelector" element={<PaymentSelectorComponentPage />} />
              <Route path="/component/productDescription" element={<ProductDescriptionComponentPage />} />
              <Route path="/component/productCardList" element={<ProductCardListComponentPage />} />
              <Route path="/component/productImageGallery" element={<ProductImageGalleryComponentPage />} />
              <Route path="/component/trustSignals" element={<TrustSignalsComponentPage />} />
              <Route path="/component/trustBadgeIcon" element={<TrustBadgeIconComponentPage />} />
              <Route path="/component/testimonial" element={<TestimonialComponentPage />} />
              <Route path="/component/teamMember" element={<TeamMemberComponentPage />} />
              <Route path="/component/stepIndicator" element={<StepIndicatorComponentPage />} />
              <Route path="/component/shippingInfoForm" element={<ShippingInformationFormComponentPage />} />
              <Route path="/component/socialMediaLink" element={<SocialMediaLinkComponentPage />} />
              <Route path="/component/promotionBanner" element={<PromotionBannerComponentPage />} />
              <Route path="/component/sort" element={<SortComponentPage />} />
              <Route path="/component/qa" element={<QAComponentPage />} />
              <Route path="/component/sizeSelector" element={<SizeSelectorComponentPage />} />
              <Route path="/component/quantitySelector" element={<QuantitySelectorComponentPage />} />
              <Route path="/component/productReview" element={<ProductReviewComponentPage />} />
              <Route path="/component/productList" element={<ProductListComponentPage />} />
              <Route path="/component/productInfoCard" element={<ProductInfoCardComponentPage />} />
              <Route path="/component/slider" element={<SliderComponentPage />} />
              <Route path="/component/slideOver" element={<SlideOverComponentPage />} />
              <Route path="/component/filter" element={<FilterComponentPage />} />
              <Route path="/component/dialog" element={<DialogComponentPage />} />
              <Route path="/component/input" element={<InputComponentPage />} />
              <Route path="/component/tab" element={<TabComponentPage />} />
              <Route path="/component/switch" element={<SwitchComponentPage />} />
              <Route path="/component/stats" element={<StatsComponentPage />} />
              <Route path="/component/textArea" element={<TextAreaComponentPage />} />
              <Route path="/component/richTextEditor" element={<RichTextEditorComponentPage />} />
              <Route path="/component/emailEditor" element={<EmailEditorComponentPage />} />
              <Route path="/component/searchBar" element={<SearchBarComponentPage />} />
              <Route path="/component/reviewSubmission" element={<ReviewSubmissionComponentPage />} />
              <Route path="/component/fileUpload" element={<FileUploadComponentPage />} />
              <Route path="/component/dragAndDrop" element={<DragAndDropComponentPage />} />
              <Route path="/component/buttonGroup" element={<ButtonGroupComponentPage />} />
              <Route path="/component/calendar" element={<CalendarComponentPage />} />
              <Route path="/component/checkbox" element={<CheckboxComponentPage />} />
              <Route path="/component/colorPicker" element={<ColorPickerComponentPage />} />
              <Route path="/component/productVariantEditor" element={<ProductVariantEditorComponentPage />} />
              <Route path="/component/featureSection" element={<FeatureSectionComponentPage />} />
              <Route path="/component/registrationForm" element={<RegistrationFormComponentPage />} />

              <Route path="/dashboard" element={<Dashboard />}>
                <Route path="home" element={<DashboardHome />}/>
                
                <Route path="products" element={<ProductListPage />}/>
                <Route path="products/edit" element={<ProductEditPage/>}/>
                
                <Route path='inventory' element={<InventoryPage/>}/>

                <Route path="reviews" element={<ReviewListPage/>}/>
                <Route path="reviews/edit/:reviewId" element={<ReviewEditPage/>}/>
                
                <Route path="categories" element={<CategoriesPage />}/>
                <Route path="categories/edit" element={<EditCategoryPage />}/>
                
                <Route path="orders" element={<OrdersPage/>} />
                <Route path="orders/:orderId" element={<OrderDetail/>} />
                <Route path="orders/create" element={<CreateOrderPage/>} />


                <Route path="cancellations" element={<CancellationsPage/>} />
                <Route path="cancellations/:cancellationId" element={<CancellationDetails/>} />
                <Route path="cancellations/create" element={<CreateCencellationPage/>} />

                
                <Route path="returns-request/all" element={<ReturnRequestPage/>} />
                <Route path="returns-request/detail/:returnRequestId" element={<ReturnRequestDetailPage/>} />
                <Route path="returns-request/create" element={<CreateReturnPage/>} />

                <Route path="refund-process/all" element={<RefundProcessPage/>} />
                <Route path="refund-process/detail/:refundProcessId" element={<RefundProcessDetailPage/>} />
                
                <Route path="customers" element={<CustomersList/>} />
                <Route path="customers/:customerId" element={<CustomerDetails/>} />
                <Route path="customers/feedback" element={<CustomersFeedback/>} />
                <Route path="customers/feedback/:feedbackId" element={<FeedbackDetails/>} />
               
                <Route path="discounts" element={<DiscountCodePage/>} />
                <Route path="discounts/edit" element={<EditDiscountCodePage/>} />
                
                <Route path="promotions" element={<PromotionPage/>} />
                <Route path="promotions/edit" element={<EditPromotion/>} />
                
                
                <Route path="banner/edit" element={<EditBanner/>} />
                
                <Route path="emails/campaigns" element={<EmailcampaignsPage/>} />
                <Route path="emails/campaigns/edit" element={<EmailCampaignsEditor/>} />
                <Route path="emails/campaigns/detail" element={<EmailCampaignDetailPage/>} />
                <Route path="emails/templates" element={<EmailTemplateListPage/>} />
                <Route path="emails/subscribers" element={<SubscriberPage/>} />
                
                
                <Route path="analytics/salesReport" element={<SalesReportPage/>} />
                <Route path="analytics/customers" element={<CustomerAnalyticsPage/>} />
                <Route path="analytics/products" element={<ProductAnalyticsPage/>} />
                
                <Route path="admins" element={<AdminUserPage/>} />
                <Route path="admins/edit/:adminId" element={<AdminEditorPage/>} />
                <Route path="admins/create" element={<CreateAdminPage/>} />

               
                <Route path="images" element={<ImagesPage/>} />
                <Route path="images/edit/:imageId" element={<ImageEditPage/>} />
                <Route path="images/upload" element={<ImageUploadPage/>} />


                <Route path="notifications" element={<NotificationsListPage/>}/>
                
                <Route path="settings/general" element={<SettingsPage/>} />
                
                <Route path="settings/paymentMethod" element={<PaymentSettingsPage/>} />
                <Route path="settings/paymentMethod/edit" element={<PaymentMethodEditPage/>} />

                <Route path="settings/shipping" element={<ShippingSettingsPage/>} />
                <Route path="settings/shipping/edit" element={<ShippingMethodEditPage/>} />
                
                <Route path="settings/socialMedia" element={<SocialMediaLinksPage/>} />
                <Route path="settings/socialMedia/edit" element={<SocialMediaEditPage/>} />
                
                <Route path="settings/tax" element={<TaxSettingsPage/>} />
                
                <Route path="privatePolicy" element={<PrivatePolicySettingPage/>} />
                <Route path="termOfService" element={<TermOfServiceSettingPage/>} />


                <Route path="blogPost" element={<BlogPostListPage/>} />
                <Route path="blogPost/edit" element={<BlogPostEditorPage/>} />
             
              
                <Route path="support/faq" element={<FAQsPage/>}/>
                <Route path="support/tickets" element={<TicketsPage/>}/>
                <Route path="support/tickets/:ticketId" element={<TicketDetailPage/>}/>

                <Route path="content-management" element={<PageList/>}/>
                <Route path="page-edition/:page" element={<PageEdition/>}/>



              </Route>

              {/* Nest all e-commerce routes under the EcommerceLayout */}
            <Route path="ecommerce" element={<EcommerceLayout />}>
              <Route path="" element={<EcommerceHome />} />
              <Route path="shop" element={<EcommerceShop />} />
              <Route path="product/:productId" element={<EcommerceProduct />} />
              <Route path="cart" element={<EcommerceCart />} />
              <Route path="login" element={<EcommerceLogin />} />
              <Route path="register" element={<EcommerceRegister />} />
              <Route path="aboutUs" element={<EcommerceAboutUs />} />
              <Route path="contactUs" element={<EcommerceContactUs />} />
              <Route path="faq" element={<EcommerceFAQ />} />
              <Route path="private-policy" element={<EcommercePrivatePolicy />} />
              <Route path="termAndCondition" element={<EcommerceTermAndCondition />} />
              <Route path="customer" element={<CustomerProfilePage/>}/>
              <Route path="customer/orders" element={<CustomerOrderHistoryPage/>}/>
              <Route path="customer/wishList" element={<CustomerWishListPage/>}/>
              <Route path="customer/order_detail" element={<CustomerOrderDetailPage/>}/>
              <Route path="customer/returns" element={<CustomerReturnPage/>}/>
              <Route path="customer/messages" element={<CustomerMessageHistoryPage/>}/>
              <Route path="customer/chatRoom/:chatRoomId" element={<CustomerChatRoomPage/>}/>
              <Route path="checkout/cart" element={<CheckoutCartPage/>}/>
              <Route path="checkout/billingShipping" element={<CheckoutBillingShippingPage/>}/>
              <Route path="checkout/payment" element={<CheckoutPaymentPage/>}/>
              <Route path="checkout/orderReview" element={<CheckoutOrderReviewPage/>}/>
              <Route path="blog/:blogId" element={<BlogPage/>}/>
            </Route>





            </Routes>
          </main>
        </section>
      </PersistGate>
    </Provider>
  );
};

export default App;
