import { FaBoxOpen, FaExchangeAlt, FaHeart, FaSignOutAlt, FaUserCircle } from "react-icons/fa";
import { CustomerNavigationComponentProps } from "./CustomerNavigationComponentProps";
import { IoChatbox, IoChatbubbles } from "react-icons/io5";



const customerNavigationDefaultProps:CustomerNavigationComponentProps = {
    tabs: [ {name: 'profile', icon: <FaUserCircle />}, {name: 'orders', icon:<FaBoxOpen/>}, {name: 'wishlist', icon:<FaHeart/>}, {name: 'returns', icon:<FaExchangeAlt />}, {name: 'messages', icon:<IoChatbubbles />}, {name:'logout', icon:<FaSignOutAlt /> }],
    onClick: ()=>null,
    activeTab:'profile',
};
export default customerNavigationDefaultProps
