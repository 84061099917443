import React, { useRef, useState, useEffect } from 'react';
import styles from './TabV2.module.scss';
import { TabComponentProps } from '../TabComponentProps';

const TabV2: React.FC<TabComponentProps> = ({ 
  tabs = [ "Tab 1", "Tab 2"], 
  wrapperStyle = undefined, 
  activeTab = "Tab 1", 
  onTabChange 
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const [overlayPosition, setOverlayPosition] = useState(0);

  useEffect(() => {
    const activeIndex = tabs.indexOf(activeTab);
    if (activeIndex !== -1) {
      setOverlayPosition(activeIndex * (100));
    }
  }, [activeTab, tabs]);

  const handleTabClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, tab: string) => {
    onTabChange && onTabChange(tab);

    let div = event.currentTarget;
    const parentDiv = div.parentElement as HTMLElement;
    const xPos = div.getBoundingClientRect().left - parentDiv.getBoundingClientRect().left;

    // Directly update the overlay position using the ref
    if (overlayRef.current) {
      overlayRef.current.style.transform = `translateX(${xPos}px)`;
    }
  };

  return (
    <div className={styles.tabContainer} style={{ ...wrapperStyle }}>
      <div className={styles.tabList}>
        <div
          ref={overlayRef}
          className={styles.overlay}
          style={{ width: `calc(100% / ${tabs.length})`, transform: `translateX(${overlayPosition}%)` }}
        />
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`${styles.tab} ${tab === activeTab ? styles.active : ''}`}
            onClick={(event) => handleTabClick(event, tab)}
          >
            {tab}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabV2;
