import React, { useState } from 'react';
import styles from './SaveButton.module.scss';  // Import the SCSS module

interface SaveButtonProps {
  onClick?: () => Promise<void>;
  isSaving?: boolean;
  style?: React.CSSProperties;
}

const SaveButton: React.FC<SaveButtonProps> = ({ onClick = ()=>null, style={} }) => {
  const [isSaving, setIsSaving] = useState(false);

  return (
    <button
      className={`${styles.saveButton} ${isSaving ? styles.loading : ''}`}
      onClick={onClick}
      disabled={isSaving}
      style={style}
    >
      {isSaving ? <span className={styles.loader}></span> : 'Save'}
    </button>
  );
};

export default SaveButton;
