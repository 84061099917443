import React, { useState, ChangeEvent, FormEvent } from 'react';
import styles from '../../Home/EcommerceHome.module.scss';
import { CustomerType } from '../../../types/Data/CustomerType';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import renderPageElement from '../../renderPageElement';
import { ComponentType } from '../../../types/Data/BaseElementType';
import { useNavigate } from 'react-router-dom';

const CustomerChatRoomPage: React.FC = () => {
  const navigate = useNavigate();
  // Assuming customer is fetched asynchronously, initially it might be undefined

  const customerChatRoomPPageElements = useSelector((state:RootState)=> state.ecommerce.ui.elements).filter((el) => el.page === 'customerChatRoom');

  const getComponentProps = (
    componentType: ComponentType,
  ) => {
    switch (componentType) {
      case ComponentType.CustomerMessageHistory:
        return {
         
        };
      default:
        return {};
    }
  };

  return (
    <div className={styles.page}>
        <div className={styles.grid}>
            {customerChatRoomPPageElements.map((element, index) => {
                const Component = renderPageElement(element);
                const { row, column, span, order, spanRows } = element.position || {};
                const props = {...getComponentProps(element.type), ...element.props}
                return (
                    <div
                        key={index}  // Add unique key to prevent warnings
                        className={styles.gridItem}  // Add a specific class for the grid items
                        style={{
                            gridColumn: column ? `${column} / span ${span || 1}` : '1 / span 12',  // Set column start and span
                            gridRow: row && spanRows ? `${row} / span ${spanRows}` : row || 'auto',  // Set row start and span
                            order: order || 'initial',  // Set the order in case it's specified
                        }}
                    >
                        <Component {...props}/>
                    </div>
                );
            })}
        </div>
    </div>
    );
};

export default CustomerChatRoomPage;
