import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartData, ChartOptions } from 'chart.js';
import styles from './ChartTopRevenueByProduct.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Store/store';
import { OrderType } from '../../../../types/Data/OrderType';

interface ChartTopRevenueByProductProps {}

const ChartTopRevenueByProduct: React.FC<ChartTopRevenueByProductProps> = () => {
    const orders = useSelector((state: RootState) => state.dashboard.orders.orders) as OrderType[];

    // Assuming we have a function to get the top products by revenue
    const getTopRevenueByProduct = () => {
        const productRevenueMap: { [key: string]: { name: string, revenue: number, quantity: number } } = {};
        orders.forEach(order => {
            order.items.forEach(item => {
                if (!productRevenueMap[item.productId]) {
                    productRevenueMap[item.productId] = {
                        name: item.name,
                        revenue: 0,
                        quantity: 0
                    };
                }
                productRevenueMap[item.productId].revenue += item.totalPrice;
                productRevenueMap[item.productId].quantity += item.quantity;
            });
        });

        return Object.values(productRevenueMap)
            .sort((a, b) => b.revenue - a.revenue)
            .slice(0, 10); // Top 10 products by revenue
    };

    const topProducts = getTopRevenueByProduct();
    const productNames = topProducts.map(p => p.name);
    const productRevenue = topProducts.map(p => p.revenue);
    const productQuantity = topProducts.map(p => p.quantity);

    const data: ChartData<'bar'> = {
        labels: productNames,
        datasets: [
            {
                label: 'Revenue',
                data: productRevenue,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
            {
                label: 'Quantity Sold',
                data: productQuantity,
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: 'white', // X-axis labels color
                },
            },
            y: {
                stacked: true,
                ticks: {
                    color: 'white', // Y-axis labels color
                },
            },
        },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: 'white', // Legend text color
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.dataset.label}: ${context.raw}`;
                    },
                },
            },
        },
    };

    return (
        <div className={styles.container}>
            <h3>Top 10 Products by Revenue and Quantity</h3>
            <div className={styles.chart_container}>
                <Bar data={data} options={options} />
            </div>
        </div>
    );
};

export default ChartTopRevenueByProduct;
