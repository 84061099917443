import React from 'react';
import { EmailTemplateType } from '../../../../types/Data/EmailTemplateType';

const StoreLaunchEmailSkeleton:React.FC<EmailTemplateType> = ({ data }) => {
    const styles: {[key:string]: React.CSSProperties} = {
        emailSkeleton: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            maxWidth: '100%',
            margin: '0 auto',
            border: '1px solid #ddd',
            borderRadius: '8px',
            overflow: 'hidden',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Arial, sans-serif',
            color: '#333',
            backgroundColor: '#f9f9f9'
        },
        emailHeader: {
            backgroundColor: '#e74c3c',
            color: '#fff',
            padding: '20px',
            textAlign: 'center'
        },
        emailHeaderH1: {
            margin: '0',
            fontSize: '24px'
        },
        emailBody: {
            padding: '20px'
        },
        emailBodyP: {
            lineHeight: '1.5',
            fontSize: '16px'
        },
        emailFooter: {
            backgroundColor: '#f1f1f1',
            padding: '20px',
            textAlign: 'center'
        },
        emailFooterP: {
            margin: '5px 0',
            fontSize: '14px',
            color: '#666'
        }
    };

    return (
        <div style={styles.emailSkeleton}>
            <header style={styles.emailHeader}>
                <h1 style={styles.emailHeaderH1}>{data?.title}</h1>
            </header>
            { data?.body &&
            <main style={styles.emailBody}
                dangerouslySetInnerHTML={{ __html: data.body }} 
            > 
            </main> }
            <footer style={styles.emailFooter}>
                <p style={styles.emailFooterP}>Join us for the launch!</p>
                <p style={styles.emailFooterP}>Unsubscribe | Contact Us</p>
            </footer>
        </div>
    );
};

export default StoreLaunchEmailSkeleton;
