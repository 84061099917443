import React, { ReactNode, useState } from 'react';
import styles from './OrderItems.module.scss';
import OrderItemCard from './OrderItemCard/OrderItemCard';
import { OrderItemType } from '../../../../types/Data/OrderType';

interface OrderItemsProps {
    items: OrderItemType[];
    onItemUpdate: (updatedItems: OrderItemType[]) => void; // Callback to update items in the parent component
    children?: ReactNode;
}

const OrderItems: React.FC<OrderItemsProps> = ({ items, onItemUpdate, children }) => {
    const [orderItems, setOrderItems] = useState<OrderItemType[]>(items);

    // Handle changes to individual items
    const handleItemChange = <K extends keyof OrderItemType>(index: number, key: K, value: OrderItemType[K]) => {
        const updatedItems = [...orderItems];
        updatedItems[index][key] = value;

        // Automatically calculate total price when quantity or unit price changes
        if (key === 'quantity' || key === 'unitPrice') {
            updatedItems[index].totalPrice = updatedItems[index].quantity * updatedItems[index].unitPrice;
        }

        setOrderItems(updatedItems);
        onItemUpdate(updatedItems);
    };

    // Handle item removal
    const handleRemoveItem = (index: number) => {
        const updatedItems = orderItems.filter((_, i) => i !== index);
        setOrderItems(updatedItems);
        onItemUpdate(updatedItems);
    };

    return (
        <div className={styles.orderItemsContainer}>
            <h2>Order Items</h2>
            {orderItems.map((item, index) => (
                <OrderItemCard
                    key={item.productId}
                    item={item}
                    onItemChange={(key, value) => handleItemChange(index, key as keyof OrderItemType, value)}
                    onRemove={() => handleRemoveItem(index)}
                />
            ))}
            { children && children}
        </div>
    );
};

export default OrderItems;
