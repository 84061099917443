import React, { useEffect, useRef } from 'react';
import { IoSearch } from 'react-icons/io5';
import styles from './SearchBarV2.module.scss';
import { SearchBarComponentProps } from '../SearchBarComponentProps';

const SearchBarV2:React.FC<SearchBarComponentProps> = ({value=undefined, onSearch, style={}}) => {
  const ref = useRef<HTMLInputElement>(null);
  const ref_icon = useRef<HTMLDivElement>(null);

  const handleTextChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target; 
    let input = ref?.current;
    let icon = ref_icon?.current;

    if (!!value && input && icon) {
      input.className +=  ` ${styles.open}`;
      icon.className = styles.fa_search_close;
    }
    else if (input && icon ) {
      input.className = styles.input;
      icon.className = styles.fa_search;
    }
    onSearch && onSearch(value)
  };

  return (
    <div className={styles.box} style={{...style}}>
        <div className={styles.search}>
            <input ref={ref} type="text" className={styles.input} name="text" onMouseOut={()=>ref.current ? ref.current.blur() : null} value={value} onChange={handleTextChange}/>
        </div>
        <div ref={ref_icon} className={styles.fa_search}> 
            <IoSearch />
        </div>
    </div>

  )
}

export default SearchBarV2