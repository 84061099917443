import React from 'react';
import styles from './OrderItemSelection.module.scss';
import { ReturnOrderItemType, ReturnReason } from '../../../../types/Data/ReturnRequestType';

interface OrderItemSelectionProps {
    orderItems: ReturnOrderItemType[];  // All items in the order
    returnRequestItems: ReturnOrderItemType[];  // Items selected for return
    onItemSelect: (item: ReturnOrderItemType, isSelected: boolean) => void;
    onReturnReasonChange: (item: ReturnOrderItemType, reason: ReturnReason) => void;
    onQuantityChange: (item: ReturnOrderItemType, quantity: number) => void;
}

const OrderItemSelection: React.FC<OrderItemSelectionProps> = ({
    orderItems,
    returnRequestItems,
    onItemSelect,
    onReturnReasonChange,
    onQuantityChange
}) => {
    // Check if an item is already selected for return
    const isItemSelected = (item: ReturnOrderItemType) =>
        returnRequestItems.some(returnItem => returnItem.product === item.product);

    return (
        <div className={styles.orderItemSelection}>
            <h4>Select Items for Return</h4>
            <ul>
                {orderItems.map((item, index) => (
                    <li key={item.product.toString()} className={styles.item}>
                        <label>
                            <input
                                type="checkbox"
                                checked={isItemSelected(item)}
                                onChange={(e) => onItemSelect(item, e.target.checked)}
                            />
                            {item.productName} (Qty: {item.quantity}) - ${item.price}
                        </label>
                        
                        {isItemSelected(item) && (
                            <>
                                <div>
                                    <label>Reason for Return:</label>
                                    <select
                                        onChange={(e) =>
                                            onReturnReasonChange(item, e.target.value as ReturnReason)
                                        }
                                        value={
                                            returnRequestItems.find(
                                                returnItem => returnItem.product === item.product
                                            )?.returnReason || ''
                                        }
                                    >
                                        <option value="">Select Reason</option>
                                        {Object.values(ReturnReason).map(reason => (
                                            <option key={reason} value={reason}>
                                                {reason}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label>Quantity to Return:</label>
                                    <input
                                        type="number"
                                        min="1"
                                        max={item.quantity}
                                        value={
                                            returnRequestItems.find(
                                                returnItem => returnItem.product === item.product
                                            )?.quantity || 1
                                        }
                                        onChange={(e) =>
                                            onQuantityChange(item, parseInt(e.target.value))
                                        }
                                    />
                                </div>
                            </>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default OrderItemSelection;
