import React from 'react'
import styles from '../Component.module.scss';
import AlertV1 from '../../components/Alert/AlertV1/AlertV1';
import AlertV2 from '../../components/Alert/AlertV2/AlertV2';
import { alertDefaultProps } from '../../components/Alert/alertDefaultProps/alertDefaultProps';

const AlertComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <AlertV1 {...alertDefaultProps}/>
        </div>
        <div className={styles.element}>
            <AlertV2 {...alertDefaultProps}/>
        </div>
       
       
    </div>
  )
}

export default AlertComponentPage