import React, { useEffect, useState } from 'react';
import styles from '../../UIElementsMainSettings/UiElementEditor.module.scss';
import { IoRemove } from 'react-icons/io5';

import { HeroBannerComponentProps, HeroBannerMapping, HeroBannerVersions } from '../HeroBannerComponentProps';
import { BaseElementType, ComponentType } from '../../../types/Data/BaseElementType';
import defaultHeroBanner from '../defaultHeroBanner';
import { BannerSlideType } from '../../../types/Data/BannerSlideType';
import InputV4 from '../../../components/Input/InputText/InputV4/InputV4';
import ButtonV1 from '../../../components/Button/ButtonV1/ButtonV1';
import TextAreaV1 from '../../../components/TextArea/TextAreaV1/TextAreaV1';
import UIElementsMainSettings from '../../UIElementsMainSettings/UIElementsMainSettings';
import { useDispatch } from 'react-redux';
import { fetchCreateElement, fetchUpdateElement } from '../../../Dashboard/DashboardState/element/elementSlice';
import { AppDispatch, RootState } from '../../../Store/store';
import { useSelector } from 'react-redux';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';

interface BannerEditorProps {
  element?: BaseElementType<HeroBannerComponentProps>;
}

const HeroBannerEditor: React.FC<BannerEditorProps> = ({
  element,
}) => {
  
  const defaultElement: BaseElementType<HeroBannerComponentProps> = {
    type: ComponentType.HeroBanner,
    version: 'v1',
    page: 'home',
    props: defaultHeroBanner,
    position: undefined,
    isVisible: true,
  };

  const newSlide: BannerSlideType = {
    title: '',
    text: '',
    imageUrl: '',
    videoUrl: '',
    buttonText: '',
    buttonUrl: '',
  };

  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state:RootState)=>state.dashboard.elements.loading);
  const error = useSelector((state:RootState)=>state.dashboard.elements.error);

  const [currentElement, setCurrentElement] = useState(element ? element : defaultElement);

  useEffect(() => {
    if (element) {
      setCurrentElement(element)
    } else {
      setCurrentElement(defaultElement)
    }
  }, [element])


  const handleAddSlide = () => {
      setCurrentElement((prevElement) => ({
        ...prevElement,
        props: {
          ...prevElement.props,
          slides: [...(prevElement?.props?.slides ?? []), newSlide],
        },
      }));
  }
  

  const handleRemoveSlide = (slideIndex: number) => {
    alert('remove')
      setCurrentElement((prevElement) => ({
        ...prevElement,
        props: {
          ...prevElement.props,
          slides: (prevElement?.props?.slides ?? []).filter((_, index:number) => index !== slideIndex),
        },
      }));
  };

  const handleSlideChange = (slideIndex: number, key: string, value: any) => {
    setCurrentElement((prevElement) => ({
        ...prevElement,
        props: {
          ...prevElement.props,
          slides: (prevElement?.props?.slides ?? []).map((slide, index) =>
            index === slideIndex ? { ...slide, [key]: value } : slide
          ),
        },
    }));
  }

  const handleElementUpdate = (key:string, value: any, ) => {
      setCurrentElement((prevElement) => ({
        ...prevElement,
        [key]: value,
      }));
  };

  const handleElementVisibilityUpdate = () => {
    setCurrentElement(prev => ({ ...prev, isVisible: !prev.isVisible }));
  };

  const handleSaveElement = () => {
    if (currentElement?._id) {
      dispatch(fetchUpdateElement({id:currentElement._id, element:currentElement}))
    } else {
      dispatch(fetchCreateElement(currentElement))
    }
  };

  const SelectedComponent = HeroBannerMapping[currentElement.version as HeroBannerVersions] || HeroBannerMapping[HeroBannerVersions.V1];
  
  const elementsVersion = Object.keys(HeroBannerVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: HeroBannerVersions[key as keyof typeof HeroBannerVersions],
  }));


  return (
    <div className={styles.container}>
      { error && <ErrorBannerV1 message={error}/>}
      { loading && <LoadingIndicatorV1/>}
      <div className={styles.componentPreview}>
        <SelectedComponent banner={currentElement?.props} />
      </div>

      <div className={styles.componentEditor}>
        <UIElementsMainSettings
            element={currentElement}
            elementVersions={elementsVersion}
            onElementUpdate={handleElementUpdate}
            onVisibilitytUpdate={handleElementVisibilityUpdate}
        />
        <div className={styles.slides}>
        {currentElement?.props?.slides?.map((slide, index) => (
          <div key={index} className={styles.slide}>
            <div className={styles.slideHeader}>
                <h4>Slide {index + 1}</h4>
                <div className={styles.buttonRemove} onClick={() => handleRemoveSlide(index)} >
                    <IoRemove className={styles.removeButton} />
                </div>
            </div>
            <InputV4
              label="Slide Title"
              value={slide.title || ''}
              onChange={(e) => handleSlideChange(index, 'title', e.target.value)}
            />
            <TextAreaV1
              label="Slide Text"
              value={slide.text || ''}
              onChange={(e) => handleSlideChange(index, 'text', e.target.value)}
            />
            <InputV4
              label="Image URL"
              value={slide.imageUrl || ''}
              onChange={(e) => handleSlideChange(index, 'imageUrl', e.target.value)}
            />
            <InputV4
              label="Video URL"
              value={slide.videoUrl || ''}
              onChange={(e) => handleSlideChange(index, 'videoUrl', e.target.value)}
            />
            <InputV4
              label="Button Text"
              value={slide.buttonText || ''}
              onChange={(e) => handleSlideChange(index, 'buttonText', e.target.value)}
            />
            <InputV4
              label="Button URL"
              value={slide.buttonUrl || ''}
              onChange={(e) => handleSlideChange(index, 'buttonUrl', e.target.value)}
            />
          </div>
        ))}
      </div>
      </div>
      <ButtonV1 label="Add Slide" onClick={handleAddSlide} />
      <ButtonV1 label="Save Element" onClick={handleSaveElement}/>
    </div>
  );
};

export default HeroBannerEditor;
