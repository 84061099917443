// NotificationCard.tsx
import React from 'react';
import styles from './NotificationCard.module.scss';
import { NotificationType } from '../../../types/Data/NotificationType';

interface NotificationCardProps {
  notification: NotificationType;
}

const NotificationCard: React.FC<NotificationCardProps> = ({ notification }) => {
  return (
    <div className={`${styles.card} ${styles[notification.type.toLowerCase()]}`}>
      <h2>{notification.title}</h2>
      <p>{notification.description}</p>
      <span>{notification.createdAt.toDateString()}</span>
    </div>
  );
};

export default NotificationCard;
