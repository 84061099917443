import React, { useState, useEffect } from 'react';
import styles from './ProductEditPage.module.scss';
import TextEditor from '../../../components/RichTextEditor/RichTextEditorV1/RichTextEditorV1';
import InputTags from '../../../components/Input/InputTags/InputTagsV1/InputTagsV1';
import TextArea1 from '../../../components/TextArea/TextAreaV1/TextAreaV1';
import DragAndDrop1 from '../../../components/DragAndDrop/DragAndDropV1/DragAndDropV1';
import FileList from '../../../components/DragAndDrop/DragAndDropV1/FileList';
import ProductVariants from './ProductVariantEditor/ProductVariantEditorV1/ProductVariantEditorV1';
import Checkbox1 from '../../../components/Checkbox/CheckboxV1/CheckboxV1';
import Button1 from '../../../components/Button/ButtonV1/ButtonV1';
import ButtonCancel from '../../../components/Button/ButtonCancel/ButtonCancel';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';
import { useLocation } from 'react-router-dom';
import { ProductNature, ProductType, StockStatus, VariantCombinationType } from '../../../types/Data/ProductType';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import Input4 from '../../../components/Input/InputText/InputV4/InputV4';
import Select4 from '../../../components/Select/Select4/Select4';
import ProductAttributes from './ProductAttributes/ProductAttributes';
import DiscountComponent from './DiscountComponent/DiscountComponent';
import { addProduct, editProduct, getProductById } from '../../../api/product/productApi';
import { dispatchAddProduct, dispatchUpdateProduct } from '../../DashboardState/products/productsSlice';
import { handleApiError } from '../../../api/apiError';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import SaveButton from '../../../components/Button/SaveButton/SaveButton';
import VariantCombinationEditor from './VariantCombinationEditor/VariantCombinationEditor';
import ImageUploadComponent from '../../Images/ImageUploadComponent/ImageUploadComponent';
import { uploadMultipleImages } from '../../../api/image/imageApi';
import { ImageType } from '../../../types/Data/ImageType';
import SelectImageButton from '../../Images/ImageSelector/SelectImageButton';
import ProductPickerV2 from '../../../components/ProductPicker/ProductPickerV2/ProductPickerV2';

const ProductEditPage: React.FC = () => {
  const location = useLocation();
  const productId = new URLSearchParams(location.search).get('productId');
  
  const dispatch = useDispatch();
  
  const categoriesOptions = useSelector<RootState>((state) =>
    state.dashboard.categories.categories.map((category) => ({ label: category.name, value: category.name }))
  ) as { label: string; value: string }[];

  const productToEdit = useSelector<RootState>((state) =>
    state.dashboard.products.products.find((el) => el._id === productId)
  ) as ProductType | undefined;

  const [files, setFiles] = useState<File[]>([]);

  const initialProduct: ProductType = productToEdit || {
    name: '',
    description: '',
    brand: '',
    productNature: ProductNature.Physical,
    categories: [],
    manageStock: false,
    stockLevel:undefined,
    lowStockThreshold:undefined,
    tags: [],
    price: 0,
    purchasePrice: 0,
    discountPrice: 0,
    currency: 'USD',
    images: [],
    video:undefined,
    thumbnail: '',
    relatedProducts:[],
    stockStatus: StockStatus.IN_STOCK,
    sku: '',
    rating: 0,
    attributes: [],
    createdAt: new Date(),
    updatedAt: new Date(),
    weight: '',
    dimensions: '',
    shippingCost: 0,
    warrantyPeriod: undefined,
    isActive: true,
    supplierLink: '',
    seoDescription: '',
    seoTitle: '',
    variants: [],
  };

  const [product, setProduct] = useState<ProductType>(initialProduct);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<{message:string, status:number | undefined} | null>()


  useEffect(() => {
    if (productToEdit) {
      setProduct(productToEdit);
    }
  }, [productToEdit]);

  /*
  useEffect(() => {  
    if (productId) {
      getProduct(productId)
        .then(data => {
          if (data) setProduct(data);
        })
        .catch(error => {
          let handledError = handleApiError(error);
          setError({ message: handledError.message, status: handledError.status });
        });
    }
  
    // Cleanup on component unmount or page change
    return () => {

    };
  }, [productId]);
  */

  const getProduct = async (productId: string) => {
    setIsLoading(true); // Set loading to true before request
    try {
      let data = await getProductById(productId);
      return data;
    } catch (error: any) {
      if (error.name === 'AbortError') {
        console.log('Request cancelled');
      } else {
        let handledError = handleApiError(error);
        setError({ message: handledError.message, status: handledError.status });
      }
    } finally {
      setIsLoading(false); // Ensure loading is turned off
    }
  };


  const handleInputChange = (key: keyof ProductType, value: any) => {
    setProduct((prev) => ({ ...prev, [key]: value }));
  };

  const prepareFormData = (product: any, files: File[]) => {
    const formData = new FormData();
    formData.append('productData', JSON.stringify(product));
    files.forEach((file) => {
        formData.append('images', file); // Use 'files' as the key for all files
    });
    return formData;
};

const handleSave = async () => {
    let productToSave = {
      ...product, 
      images:product.images?.map((item)=>(item._id))
    };
    try {
        const response = product._id
            ? await editProduct(product._id, product)
            : await addProduct(product);
        alert(response.data.message);
        console.log(response.data)
    } catch (error: any) {
        const apiError = handleApiError(error);
        alert(apiError.message);
    }
  };
  const handleCancel = () => {
    setProduct(initialProduct);
  };

  const handleSelectRelatedProducts = (id:string) => {
    const updatedSelection = product.relatedProducts?.includes(id) ? product.relatedProducts.filter(el=>el !== id) : product.relatedProducts ? [...product.relatedProducts, id]  : [id];
    setProduct((prev) => ({
      ...prev,
      relatedProducts: updatedSelection
    }))
  };

  const handleImagesUpload = async (files: File[]) => {
    const formData = new FormData();

    // Append each file individually to the formData
    files.forEach((file, idx) => {
        formData.append('images', file); // 'images' key for each file
    });

    // Add additional metadata (title, alt, category, etc.)
    formData.append('title', '');
    formData.append('alt', '');
    formData.append('category', 'product');
    formData.append('usage', JSON.stringify(['product']));
    formData.append('tags', JSON.stringify(['product']));  // Ensure array fields are stringified

    try {
        const response = await uploadMultipleImages(formData);

        if (response.status === 201 && response.data) {
            let newImages = [...(product.images ?? []), response.data.data]
            setProduct((prev) => ({
              ...prev,
              ['images']: newImages as ImageType[]
            }))
          }
    } catch (error: any) {
        console.log(error);
        alert(`An error occurred while uploading images: ${error.message}`);
    }
};

  const handleDeleteImages = (images: ImageType[]) => {
    setProduct((prev) => ({
      ...prev,
      ['images']: images
    })
  )}

  const handleSelectImages = (image: ImageType) => {
    setProduct((prev) => ({
      ...prev,
      ['images']: prev?.images ? [...prev?.images, image] : [image]
    })
  )}

  // Handle variant combination updates
  const handleVariantCombinationsUpdate = (updatedCombinations: VariantCombinationType[]) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      variantCombinations: updatedCombinations,
    }));
  };

  if (!isLoading && !product) {
    return (
        <p>No product found...</p>
    )
  };
  return (
    <div className={styles.productEditPage}>
      <DashboardHeader title={productId ? 'Edit Product' : 'Add New Product'} />
      {isLoading && <LoadingIndicatorV1 />}
      {error && <ErrorBannerV1 message={error.message} status={error.status} />}
      
      <div className={styles.content}>
        {/* 1. Basic Information */}
        <section className={styles.section}>
          <h2>Basic Information</h2>
          <Input4
            label="Product Name"
            value={product.name}
            onChange={(e) => handleInputChange('name', e.target.value)}
            required
          />
          <TextEditor
            value={product.description}
            onChange={(content) => handleInputChange('description', content)}
            wrapperStyle={{ marginTop: 20 }}
          />
          <Select4
            label="Category"
            options={categoriesOptions}
            value={product.categories[0] || ''}
            onChange={(value) => handleInputChange('categories', [value])}
          />
          <Input4
            label="Brand"
            value={product.brand || ''}
            onChange={(e) => handleInputChange('brand', e.target.value)}
            wrapperStyle={{ marginTop: 20 }}
          />
          <Select4
            label="Product Nature"
            options={Object.values(ProductNature).map((item) => ({
              label: item,
              value: item,
            }))}
            value={product.productNature || ProductNature.Physical}
            onChange={(value) => handleInputChange('productNature', value as ProductNature)}
          />
          <Input4
            label="SKU"
            value={product.sku}
            onChange={(e) => handleInputChange('sku', e.target.value)}
            required
            wrapperStyle={{ marginTop: 20 }}
          />
        </section>

        {/* 2. Pricing and Discounts */}
        <section className={styles.section}>
          <h2>Pricing and Discounts</h2>
          <Input4
            type="number"
            label="Price"
            value={product.price.toString()}
            onChange={(e) => handleInputChange('price', parseFloat(e.target.value))}
            required
          />
          <Input4
            type="number"
            label="Purchase Price"
            value={product.price.toString()}
            onChange={(e) => handleInputChange('purchasePrice', parseFloat(e.target.value))}
            required
            wrapperStyle={{ marginTop: 20 }}
          />
          <div className={styles.inline}>
            <label>Profit Margin:</label>
            <span>
              {product.price && product.purchasePrice
                ? `${(((product.price - product.purchasePrice) / product.purchasePrice) * 100).toFixed(2)}%`
                : 'Enter prices to calculate profit margin'}
            </span>
          </div>
          <DiscountComponent
            originalPrice={product.price}
            discountPrice={product.discountPrice}
            discountPercentage={
              product.discountPrice ? ((product.price - product.discountPrice) / product.price) * 100 : 0
            }
            onDiscountChange={(newDiscountPrice, newDiscountPercentage) =>
              handleInputChange('discountPrice', newDiscountPrice)
            }
          />
        </section>

        {/* 3. Stock and Inventory */}
        <section className={styles.section}>
          <h2>Stock and Inventory</h2>
          <Checkbox1
            label="Manage Stock"
            checked={product.manageStock}
            onChange={(value) => handleInputChange('manageStock', value)}
          />
          {product.manageStock && (
            <>
              <Input4
                type="number"
                label="Stock Level"
                value={product.stockLevel || ''}
                onChange={(e) => handleInputChange('stockLevel', parseInt(e.target.value))}
              />
              <Input4
                type="number"
                label="Low Stock Threshold"
                value={product.lowStockThreshold || ''}
                onChange={(e) => handleInputChange('lowStockThreshold', parseInt(e.target.value))}
              />
            </>
          )}
        </section>

        {/* 4. Media */}
        <section className={styles.section}>
          <h2>Media</h2>
          <ImageUploadComponent 
            onUpload={handleImagesUpload}
          />
          <SelectImageButton
            styles={{marginTop: 20}}
            onImageSelect={handleSelectImages}
          />
          <FileList title='Images' files={product.images ?? []} setFiles={(images:ImageType[])=>handleDeleteImages(images)} />
          <Input4
            label="Product Video URL"
            value={product.video || ''}
            onChange={(e) => handleInputChange('video', e.target.value)}
          />
        </section>

        {/* 5. Attributes */}
        <section className={styles.section}>
          <h2>Attributes</h2>
          <ProductAttributes
            attributes={product.attributes!}
            setAttributes={(attributes) => handleInputChange('attributes', attributes)}
          />
        </section>

        {/* 6. Supplier and Related Products */}
        <section className={styles.section}>
          <h2>Supplier and Related Products</h2>
          <Input4
            label="Supplier Link"
            value={product.supplierLink || ''}
            onChange={(e) => handleInputChange('supplierLink', e.target.value)}
          />
          <ProductPickerV2
            selectedProductIds={product.relatedProducts ?? []}
            onSelectProduct={handleSelectRelatedProducts}
          />
        </section>

        {/* 7. SEO and Tags */}
        <section className={styles.section}>
          <h2>SEO and Tags</h2>
          <InputTags
            tags={product.tags}
            onChange={(tags) => handleInputChange('tags', tags)}
          />
          <Input4
            label="SEO Title"
            value={product.seoTitle || ''}
            onChange={(e) => handleInputChange('seoTitle', e.target.value)}
            required
          />
          <TextArea1
            placeholder="SEO Description"
            value={product.seoDescription || ''}
            onChange={(e) => handleInputChange('seoDescription', e.target.value)}
          />
        </section>

        {/* 8. Shipping */}
        <section className={styles.section}>
          <h2>Shipping</h2>
          <Input4
            type="number"
            label="Weight"
            value={product.weight || ''}
            onChange={(e) => handleInputChange('weight', e.target.value)}
            required
          />
          <Input4
            type="number"
            label="Shipping Cost"
            value={product.shippingCost || ''}
            onChange={(e) => handleInputChange('shippingCost', parseFloat(e.target.value))}
            required
          />
          <Input4
            type="text"
            label="Dimensions (L x W x H)"
            value={product.dimensions || ''}
            onChange={(e) => handleInputChange('dimensions', e.target.value)}
            required
          />
        </section>

        {/* 9. Variants */}
        <section className={styles.section}>
          <h2>Variants</h2>
          <ProductVariants
            variants={product.variants!}
            setVariants={(variants) => handleInputChange('variants', variants)}
          />
        </section>

          {/* 9. Variants Combinaisons */}

          <VariantCombinationEditor
            variants={product?.variants ?? []}
            variantCombinations={product?.variantComination ?? []}
            setVariantCombinations={handleVariantCombinationsUpdate}
          />

        {/* 10. Visibility */}
        <section className={styles.section}>
          <h2>Visibility</h2>
          <Checkbox1
            label="Active"
            checked={product.isActive}
            onChange={(value) => handleInputChange('isActive', value)}
          />
        </section>
      </div>

      <div className={styles.buttonContainer}>
        <SaveButton onClick={handleSave} />
        <ButtonCancel onClick={handleCancel} title="Cancel" style={{ marginLeft: 20 }} />
      </div>
    </div>
  );
};

export default ProductEditPage;
