import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import exampleDataTestimonial from './exampleDataTestimonial';
import { TestimonialType } from '../../../types/Data/TestimonialType';

interface TestimonialState {
    testimonials: TestimonialType[];
}

const initialState: TestimonialState = {
  testimonials: [...exampleDataTestimonial],
};

const testimonialSlice = createSlice({
  name: 'testimonial',
  initialState,
  reducers: {
    addTestimonial: (state, action: PayloadAction<TestimonialType>) => {
      state.testimonials.push(action.payload);
    },
    updateTestimonial: (state, action: PayloadAction<TestimonialType>) => {
      const index = state.testimonials.findIndex(t => t.id === action.payload.id);
      if (index !== -1) {
        state.testimonials[index] = action.payload;
      }
    },
    removeTestimonial: (state, action: PayloadAction<string>) => {
      state.testimonials = state.testimonials.filter(t => t.id !== action.payload);
    },
  },
});

export const { addTestimonial, updateTestimonial, removeTestimonial } = testimonialSlice.actions;
export default testimonialSlice.reducer;
