import CategoryDisplayV1 from "../../UIElements/CategoryDisplay/CategoryDisplayV1/CategoryDisplayV1";
import CategoryDisplayV2 from "../../UIElements/CategoryDisplay/CategoryDisplayV2/CategoryDisplayV2";
import CategoryDisplayV3 from "../../UIElements/CategoryDisplay/CategoryDisplayV3/CategoryDisplayV3";

enum CategoryDisplayVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const CategoryDisplayMapping:Record<CategoryDisplayVersions, React.FC<any>> = {
    [CategoryDisplayVersions.V1]: CategoryDisplayV1,
    [CategoryDisplayVersions.V2]: CategoryDisplayV2,
    [CategoryDisplayVersions.V3]: CategoryDisplayV3,
};

interface CategoryDisplayComponentProps {
    title?:string;
    selectedCategoriesId?: string[];
    onClick?:(category:string)=>void
};

export {
    CategoryDisplayMapping,
    CategoryDisplayVersions,
};

export type {
    CategoryDisplayComponentProps,
};
