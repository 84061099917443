import { CategoryType } from "../../../types/Data/CategoryType";

const exampleCategories: CategoryType[] = [
  {
      _id: '1',
      name: 'Mobile Phones',
      description: 'Latest mobile phones and accessories',
      seoTitle: 'Smartphones & Accessories',
      seoDescription: 'Discover cutting-edge mobile phones, foldables, and accessories.',
      seoKeywords: ['smartphones', 'foldable phones', 'mobile accessories'],
      isActive: true,
      parentCategoryId: null,
      image:{
        url: 'https://storage.googleapis.com/cdn.a1comms.com/themes/affordable/img/a-NewStyling-2022/Web%20Images/Mobile%20Phone%20Deals%20Page/AFM_WebImages_LP_MobilePhoneDeals-BackToTop--1.png',
      }
  },
  {
      _id: '1',
      name: 'Laptops',
      description: 'Wide range of laptops from gaming to ultrabooks',
      seoTitle: 'Modern Laptops',
      seoDescription: 'Browse top-tier gaming laptops, workstations, and ultrabooks.',
      seoKeywords: ['laptops', 'gaming laptops', 'ultrabooks'],
      isActive: true,
      parentCategoryId: null,
      image: {
        url: 'https://www.thx.com/wp-content/uploads/2022/06/kv2win.png',
      },
    },
  {
      _id: '3',
      name: 'Accessories',
      description: 'Accessories for mobile phones, laptops, and other gadgets',
      seoTitle: 'Tech Accessories',
      seoDescription: 'Shop chargers, cases, and tech accessories for your devices.',
      seoKeywords: ['accessories', 'chargers', 'tech accessories'],
      isActive: true,
      parentCategoryId: null,
      image: {
        url: 'https://spare-parts-mobile.com/image/catalog/Blog%20/Images/unnamed.png',
      }    
  },
  {
      _id: '4',
      name: 'Home Tech',
      description: 'Smart home devices and connected home technology',
      seoTitle: 'Smart Home Tech',
      seoDescription: 'Explore smart home devices, connected home hubs, and automation.',
      seoKeywords: ['smart home', 'home automation', 'smart devices'],
      isActive: true,
      parentCategoryId: null,
      image: 
      {
        url: 'https://www.casasmart.es/wp-content/uploads/2018/10/monitoriza-y-controla-hogar-inteligente-dispositivos-moviles-casasmart2-e1563970384733.png',
      }
  },
  {
      _id: '5',
      name: 'Gaming',
      description: 'Gaming consoles, laptops, and accessories',
      seoTitle: 'Gaming Gear',
      seoDescription: 'Get the latest gaming consoles, laptops, and accessories.',
      seoKeywords: ['gaming consoles', 'gaming laptops', 'gaming accessories'],
      isActive: true,
      parentCategoryId: null,
      image: {
        url: 'https://i.dell.com/is/image/DellContent/content/dam/ss2/page-specific/category-pages/alienware-desktop-aurora-r16-notebook-m18-800x620-image-v2.png',
      }
  },
  {
      _id: '6',
      name: 'Audio & Wearables',
      description: 'Earbuds, headphones, smartwatches, and wearable tech',
      seoTitle: 'Audio & Wearable Tech',
      seoDescription: 'Shop premium headphones, earbuds, and wearable tech like smartwatches.',
      seoKeywords: ['wearables', 'smartwatches', 'earbuds'],
      isActive: true,
      parentCategoryId: null,
      image: {
        url: 'https://assets.bose.com/content/dam/cloudassets/Bose_DAM/Web/consumer_electronics/global/products/headphones/qc45/product_silo_images/QC45_PDP_Ecom-Gallery-W02.png/jcr:content/renditions/cq5dam.web.1920.1920.png',
      }
  },
  {
      _id: '7',
      name: 'Tablets & 2-in-1 Devices',
      description: 'Tablets and hybrid 2-in-1 devices',
      seoTitle: 'Tablets & Hybrid Devices',
      seoDescription: 'Find the latest tablets, 2-in-1 devices, and accessories.',
      seoKeywords: ['tablets', '2-in-1', 'convertible laptops'],
      isActive: true,
      parentCategoryId: null,
      image: {
        url: 'https://www.o2.co.uk/documents/456036/456101/android-tablets-foreground-270121.png/982e99a1-ee83-1c52-3fb7-b83d9ae071c7?t=1615795505763',
      }
  },
  {
      _id: '8',
      name: 'Drones & Cameras',
      description: 'Drones, cameras, and photography gear',
      seoTitle: 'Drones & Camera Tech',
      seoDescription: 'Discover drones, cameras, and high-end photography equipment.',
      seoKeywords: ['drones', 'cameras', 'photography'],
      isActive: true,
      parentCategoryId: null,
      image: {
        url: 'https://rotorsdrone.com/cdn/shop/products/RAPTORF10_BLACK-removebg-preview.png?v=1665190049',
      }
  },
  {
      _id: '9',
      name: 'Fitness & Health Tech',
      description: 'Fitness gadgets and health tracking devices',
      seoTitle: 'Fitness & Health Tech',
      seoDescription: 'Shop fitness trackers, smart scales, and other health tech gadgets.',
      seoKeywords: ['fitness trackers', 'smart scales', 'health gadgets'],
      isActive: true,
      parentCategoryId: null,
      image: {
        url: 'https://store.storeimages.cdn-apple.com/4668/as-images.apple.com/is/MXM23ref_FV99_VW_34FR+watch-case-46-aluminum-jetblack-cell-s10_VW_34FR+watch-face-46-aluminum-jetblack-s10_VW_34FR?wid=2000&hei=2000&fmt=png-alpha&.v=1725645481882',
      }
  }
];

export default exampleCategories;
