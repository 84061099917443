import React, { useEffect, useState } from 'react';
import styles from './DiscountCodePage.module.scss';
import DashboardHeader from '../../components/Header/DashboardHeader/DashboardHeader';
import TableInteractive from '../../components/Tableau/TableInteractive/TableInteractive';
import { useNavigate } from 'react-router-dom';
import DiscountStatsOverview from './DiscountCodeStats/DiscountStatsOverview';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store/store';
import { DiscountCodeType } from '../../types/Data/DiscountCodeType';
import ButtonV5 from '../../components/Button/ButtonV5/ButtonV5';
import ErrorBannerV1 from '../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import LoadingIndicatorV1 from '../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import { dispatchLoadDiscountCodes, fetchAllDiscountCodes } from '../DashboardState/discountCodes/discountCodesSlice';
import exampleDiscountCodes from '../DashboardState/discountCodes/exampleDiscountCodes';
import DiscountCodeList from './DiscountCodeList/DiscountCodeList';

const DiscountCodePage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  const handleNavigateEditDiscount = () => {
    navigate('/dashboard/discounts/edit')
  };

  const discountCodes = useSelector<RootState>((state) => state.dashboard.discountCodes.discountCodes) as DiscountCodeType[];
  const loading = useSelector<RootState>((state) => state.dashboard.discountCodes.loading) as Boolean
  const error = useSelector<RootState>((state) => state.dashboard.discountCodes.error) as string | null

  const handleViewDiscountCode = (discountCodeId:string) => {
    navigate(`./edit/?discountCodeId=${discountCodeId}`)
  }

  /*
  useEffect(() => {
    dispatch(fetchAllDiscountCodes());
  }, [ dispatch]);
  */  

  return (
    <div className={styles.discount_code_page}>
      <DashboardHeader title='Discount Codes'/>
      <div className={styles.content}>
        <DiscountStatsOverview/>
          <ButtonV5
            style={{marginTop:10}}
            title='Add Discount Code'
            onClick={handleNavigateEditDiscount}
          />

        <div className={styles.orders_array}>
          {/* Handle loading state */}
          {loading && <LoadingIndicatorV1 />}
                
          {/* Handle error state */}
          {error && <ErrorBannerV1 message={typeof error === 'string' ? error : 'An error occurred'} />}      
          
          {/* Display discount code list if not loading and no error */}
           <DiscountCodeList/>
        </div>
      </div>
    </div>
  );
};

export default DiscountCodePage;
