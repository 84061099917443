import React from 'react';
import styles from './ProductCard.module.scss';
import { FaCheckCircle, FaCircle } from 'react-icons/fa';
import { ProductType } from '../../../../types/Data/ProductType';

interface ProductCardProps {
    product: Partial<ProductType>;
    isSelected: boolean;
    onSelect: (id: string) => void;
}

const ProductCard: React.FC<ProductCardProps> = ({ product, isSelected, onSelect }) => {
    return (
        <div className={`${styles.product_card} ${isSelected ? styles.selected : ''}`} onClick={() => onSelect(product._id!!)}>
            <div className={styles.radio_button}>
                {isSelected ? <FaCheckCircle /> : <FaCircle />}
            </div>
            <img src={product.images![0].url} alt={product.name} />
            <div className={styles.product_info}>
                <h3>{product.name}</h3>
                <p>{product.categories!.join('')}</p>
                <p>{product.price}</p>
            </div>
        </div>
    );
};

export default ProductCard;
