import React, { useState } from 'react';
import styles from './FeedbackStatusUpdate.module.scss';
import { useDispatch } from 'react-redux';

import { FaInfoCircle, FaCheck, FaTimes } from 'react-icons/fa';
import { CustomerFeedbackType } from '../../../../types/Data/feedback';

interface FeedbackStatusUpdateProps {
    feedback: CustomerFeedbackType;
}

const FeedbackStatusUpdate: React.FC<FeedbackStatusUpdateProps> = ({ feedback }) => {
    const [status, setStatus] = useState(feedback.status);
    const dispatch = useDispatch();

    const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setStatus(e.target.value);
    };

    const handleSubmit = () => {
       
    };

    return (
        <div className={styles.feedbackStatusUpdate}>
            <div className={styles.statusContainer}>
                <FaInfoCircle className={styles.icon} />
                <p><strong>Current Status:</strong> {feedback.status}</p>
            </div>
            <div className={styles.updateForm}>
                <label htmlFor="statusSelect"><strong>Update Status:</strong></label>
                <select id="statusSelect" value={status} onChange={handleStatusChange}>
                    <option value="pending">Pending</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                </select>
                <button className={styles.submitButton} onClick={handleSubmit}>
                    <FaCheck /> Update Status
                </button>
                <button className={styles.cancelButton} onClick={() => setStatus(feedback.status)}>
                    <FaTimes /> Cancel
                </button>
            </div>
        </div>
    );
};

export default FeedbackStatusUpdate;
