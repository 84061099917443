import { ReviewType } from "../../types/Data/ReviewType";

import ProductReviewV1 from "./ProductReviewV1/ProductReviewV1";
import ProductReviewV2 from "./ProductReviewV2/ProductReviewV2";
import ProductReviewV3 from "./ProductReviewV3/ProductReviewV3";


enum ProductReviewVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const ProductReviewMapping:Record<ProductReviewVersions, React.FC<any>> = {
    [ProductReviewVersions.V1]: ProductReviewV1,
    [ProductReviewVersions.V2]: ProductReviewV2,
    [ProductReviewVersions.V3]: ProductReviewV3,
};

interface ProductReviewComponentProps {
    reviews?: Partial<ReviewType>[]
};

export {
    ProductReviewMapping,
    ProductReviewVersions,
};

export type {
    ProductReviewComponentProps
};