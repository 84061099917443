import React, { useEffect, useState } from 'react';
import styles from './CreateRefund.module.scss';
import { CancellationType } from '../../../../types/Data/CancellationType';
import { RefundProcessType } from '../../../../types/Data/RefundProcessType';
import Button1 from '../../../../components/Button/ButtonV1/ButtonV1';

interface CreateRefundProps {
    cancellation: CancellationType;
    onValidate: (refundAmount: number, additionalInfo: string) => void;
}

const CreateRefund: React.FC<CreateRefundProps> = ({ cancellation, onValidate }) => {
    const [refundAmount, setRefundAmount] = useState<number>(0);
    const [additionalInfo, setAdditionalInfo] = useState<string>('');
    const [maxRefundAmount, setMaxRefundAmount] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState<string>('');

    // Calculate the maximum refundable amount based on cancellation items
    useEffect(() => {
        const totalRefundable = (cancellation?.items ?? []).reduce((total, item) => {
            return total + item.price * item.quantity;
        }, 0);
        setMaxRefundAmount(totalRefundable);
    }, [cancellation]);

    const handleValidate = () => {
        if (refundAmount > maxRefundAmount) {
            setErrorMessage(`Refund amount cannot exceed $${maxRefundAmount.toFixed(2)}.`);
            return;
        }
        if (refundAmount <= 0) {
            setErrorMessage('Refund amount must be greater than 0.');
            return;
        }
        onValidate(refundAmount, additionalInfo);
        setRefundAmount(0);
        setAdditionalInfo('');
    };

    return (
        <div className={styles.validateRefundContainer}>
            <h2>Process Refund</h2>
            <div className={styles.inputGroup}>
                <label>Refund Amount (max ${maxRefundAmount.toFixed(2)}):</label>
                <input
                    type="number"
                    value={refundAmount}
                    onChange={(e) => setRefundAmount(Number(e.target.value))}
                    max={maxRefundAmount}
                    className={styles.refundInput}
                />
            </div>

            {errorMessage && <p className={styles.error}>{errorMessage}</p>}

            <div className={styles.inputGroup}>
                <label>Additional Information:</label>
                <textarea
                    value={additionalInfo}
                    onChange={(e) => setAdditionalInfo(e.target.value)}
                    placeholder="Provide any additional information"
                    className={styles.infoTextarea}
                />
            </div>

            <Button1 label='Validate Refund' onClick={handleValidate} className={styles.validateButton} />
        </div>
    );
};

export default CreateRefund;
