// SendEmailToCustomer.tsx

import React, { useState, useEffect } from 'react';
import styles from './SendEmailToCustomer.module.scss';
import axios from 'axios';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

interface EmailTemplate {
  id: string;
  label: string;
  subject: string;
  body: string;
}

interface SendEmailToCustomerProps {
  customerId: string;
  customerEmail: string;
}

const SendEmailToCustomer: React.FC<SendEmailToCustomerProps> = ({
  customerId,
  customerEmail,
}) => {
  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [errors, setErrors] = useState<{ subject?: string; body?: string }>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const validateForm = (): boolean => {
    const newErrors: { subject?: string; body?: string } = {};
    if (!subject.trim()) {
      newErrors.subject = 'Subject is required';
    }
    if (!body.trim()) {
      newErrors.body = 'Message body is required';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const confirmation = window.confirm('Are you sure you want to send this email?');

    if (confirmation) {
      setIsSubmitting(true);
      //send email
    }
  };

  return (
    <div className={styles.sendEmailToCustomer}>
      <h2>Send Email to Customer</h2>
      <form onSubmit={handleSubmit} className={styles.emailForm}>
        <div className={styles.formGroup}>
          <label>To:</label>
          <input type="email" value={customerEmail} disabled />
        </div>

        <div className={styles.formGroup}>
          <label>Subject:</label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className={errors.subject ? styles.invalid : ''}
          />
          {errors.subject && <span className={styles.error}>{errors.subject}</span>}
        </div>

        <div className={styles.formGroup}>
          <label>Message:</label>
          <ReactQuill
            theme="snow"
            value={body}
            onChange={setBody}
            className={errors.body ? styles.invalid : ''}
          />
          {errors.body && <span className={styles.error}>{errors.body}</span>}
        </div>

        <div className={styles.buttonContainer}>
          <button type="submit" disabled={isSubmitting} className={styles.sendButton}>
            {isSubmitting ? 'Sending...' : 'Send Email'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SendEmailToCustomer;
