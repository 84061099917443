import React from 'react'
import styles from './ButtonV1.module.scss';
import { ButtonComponentProps } from '../ButtonComponentProps';

const ButtonV1:React.FC<ButtonComponentProps> = ({label = "Button1", style={}, icon, ...props}) => {
  return (
    <button style={{...style}} id={styles.button1} {...props}>
        <div className={styles.content}>
          {icon && <div className={styles.icon}>{icon}</div>}
          {label}
        </div>
    </button>
  )
}

export default ButtonV1