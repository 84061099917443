import React from 'react'
import styles from '../Component.module.scss';
import CompanyOverviewV1 from '../../UIElements/CompanyOverview/CompanyOverviewV1/CompanyOverviewV1';
import CompanyOverviewV2 from '../../UIElements/CompanyOverview/CompanyOverviewV2/CompanyOverviewV2';
import CompanyOverviewV3 from '../../UIElements/CompanyOverview/CompanyOverviewV3/CompanyOverviewV3';

const CompanyOverviewComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <CompanyOverviewV1/>
        </div>
        <div className={styles.element}>
            <CompanyOverviewV2/>
        </div>
        <div className={styles.element}>
            <CompanyOverviewV3/>
        </div>
    </div>
  )
}

export default CompanyOverviewComponentPage