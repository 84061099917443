import { OrderConfirmationComponentProps } from "./OrderConfirmationComponentProps";

export const defaultOrderConfirmationProps: OrderConfirmationComponentProps = {
    orderNumber: '000000',
    orderDate: 'YYYY-MM-DD',
    totalAmount: '$0.00',
    items: [
      {
        name: 'Sample Item 1',
        quantity: 1,
        price: '$0.00'
      },
      {
        name: 'Sample Item 2',
        quantity: 2,
        price: '$0.00'
      }
    ]
};