import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faUserCircle, faCreditCard, faBlog, faList, faPalette, faHistory, faInfoCircle, faGift, faEnvelope, faCheckCircle, faTimesCircle, faShoppingCart, faTags, faCreditCard as faCreditCardIcon, faStar, faSearch, faUpload, faCalendarAlt, faSquareCheck, faSort, faQuestionCircle, faEnvelopeOpen, faComments, faUsers, faStepForward, faTruck, faShareAlt, faThumbsUp, faFilter, faKeyboard, faToggleOn, faChartLine, faAlignLeft, faFont, faArrowsAlt, faLayerGroup, faCheckSquare, faEdit, faListAlt, faUserPlus, faPhotoVideo, faIdBadge } from '@fortawesome/free-solid-svg-icons';
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import styles from './Component.module.scss';
import { Link } from 'react-router-dom';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';

const Component = () => {
  const components = [
    { path: "/component/alert", label: "Alert", imageUrl: "https://img.icons8.com/color/48/000000/alarm.png" },
    { path: "/component/badge", label: "Badge", icon: faIdBadge },
    { path: "/component/avatar", label: "Avatar", icon: faUserCircle },
    { path: "/component/billingInformationForm", label: "Billing Info Form", icon: faCreditCard },
    { path: "/component/blogSection", label: "Blog Section", icon: faBlog },
    { path: "/component/breadcrumb", label: "Breadcrumb", imageUrl: "https://img.icons8.com/color/48/000000/breadcrumbs.png" }, // No Font Awesome or Ionicon available
    { path: "/component/button", label: "Button", imageUrl: "https://img.icons8.com/color/48/000000/button.png" }, // No Font Awesome or Ionicon available
    { path: "/component/buttonAddToCart", label: "Button Add to Cart", icon: faShoppingCart },
    { path: "/component/buttonBuy", label: "Button Buy", icon: faShoppingBag },
    { path: "/component/buttonCheckout", label: "Button Checkout", icon: faCreditCardIcon },
    { path: "/component/buttonWishlist", label: "Button Wishlist", icon: faGift },
    { path: "/component/cartSummarySidebar", label: "Cart Summary", icon: faShoppingCart },
    { path: "/component/categoryDisplay", label: "Category Display", icon: faProductHunt },
    { path: "/component/cartListItem", label: "Cart List Item", icon: faList },
    { path: "/component/colorSelector", label: "Color Selector", icon: faPalette },
    { path: "/component/companyHistory", label: "Company History", icon: faHistory },
    { path: "/component/companyOverview", label: "Company Overview", icon: faInfoCircle },
    { path: "/component/contactForm", label: "Contact Form", icon: faEnvelope },
    { path: "/component/contactInfo", label: "Contact Info", icon: faInfoCircle },
    { path: "/component/couponPromotion", label: "Coupon Promotion", icon: faTags },
    { path: "/component/customerSupport", label: "Customer Support", icon: faQuestionCircle },
    { path: "/component/featuredProduct", label: "Featured Product", icon: faStar },
    { path: "/component/heroBanner", label: "Hero Banner", imageUrl: "https://img.icons8.com/color/48/000000/banner.png" }, // No Font Awesome or Ionicon available
    { path: "/component/incentive", label: "Incentive", icon: faGift },
    { path: "/component/newsLetterSignup", label: "Newsletter Signup", icon: faEnvelope },
    { path: "/component/orderConfirmation", label: "Order Confirmation", icon: faCheckCircle },
    { path: "/component/orderError", label: "Order Error", icon: faTimesCircle },
    { path: "/component/orderSummary", label: "Order Summary", icon: faList },
    { path: "/component/pagination", label: "Pagination", imageUrl: "https://img.icons8.com/color/48/000000/pagination.png" }, // No Font Awesome or Ionicon available
    { path: "/component/pricingCard", label: "Pricing Card", icon: faTags },
    { path: "/component/paymentMethodSelector", label: "Payment Method Selector", icon: faCreditCardIcon },
    { path: "/component/productDescription", label: "Product Description", icon: faInfoCircle },
    { path: "/component/productCardList", label: "Product Card for List", icon: faList },
    { path: "/component/productImageGallery", label: "Product Image Gallery", icon: faPhotoVideo },
    { path: "/component/trustSignals", label: "Trust Signals", icon: faStar },
    { path: "/component/trustBadgeIcon", label: "Trust Badge Icon", icon: faStar },
    { path: "/component/testimonial", label: "Testimonial", icon: faComments },
    { path: "/component/teamMember", label: "Team Member", icon: faUsers },
    { path: "/component/stepIndicator", label: "Step Indicator", icon: faStepForward },
    { path: "/component/shippingInfoForm", label: "Shipping Info Form", icon: faTruck },
    { path: "/component/socialMediaLink", label: "Social Media Link", icon: faShareAlt },
    { path: "/component/promotionBanner", label: "Promotion Banner", imageUrl: "https://img.icons8.com/color/48/000000/promotion.png" }, // No Font Awesome or Ionicon available
    { path: "/component/sort", label: "Sort", icon: faSort },
    { path: "/component/qa", label: "QA", icon: faQuestionCircle },
    { path: "/component/sizeSelector", label: "Size Selector", imageUrl: "https://img.icons8.com/color/48/000000/size.png" }, // No Font Awesome or Ionicon available
    { path: "/component/quantitySelector", label: "Quantity Selector", imageUrl: "https://img.icons8.com/color/48/000000/numbers.png" },
    { path: "/component/productReview", label: "Product Review", icon: faStar },
    { path: "/component/productList", label: "Product List", icon: faList },
    { path: "/component/productReccomendation", label: "Product Recommendation", icon: faThumbsUp },
    { path: "/component/productInfoCard", label: "Product Info Card", icon: faInfoCircle },
    { path: "/component/slider", label: "Slider", imageUrl: "https://img.icons8.com/color/48/000000/slider.png" }, // No Font Awesome or Ionicon available
    { path: "/component/slideOver", label: "Slide Over", imageUrl: "https://img.icons8.com/color/48/000000/sliding-door.png" },
    { path: "/component/filter", label: "Filter", icon: faFilter },
    { path: "/component/dialog", label: "Dialog", icon: faComments },
    { path: "/component/input", label: "Input", icon: faKeyboard },
    { path: "/component/tab", label: "Tab", imageUrl: "https://img.icons8.com/color/48/000000/tab.png"  },
    { path: "/component/switch", label: "Switch", icon: faToggleOn },
    { path: "/component/stats", label: "Stats", icon: faChartLine },
    { path: "/component/textArea", label: "Text Area", icon: faAlignLeft },
    { path: "/component/richTextEditor", label: "Rich Text Editor", icon: faFont },
    { path: "/component/emailEditor", label: "Email Editor", icon: faEnvelopeOpen },
    { path: "/component/searchBar", label: "Search Bar", icon: faSearch },
    { path: "/component/reviewSubmission", label: "Review Submission", icon: faStar },
    { path: "/component/fileUpload", label: "File Upload", icon: faUpload },
    { path: "/component/dragAndDrop", label: "Drag and Drop", icon: faArrowsAlt },
    { path: "/component/buttonGroup", label: "Button Group", icon: faLayerGroup },
    { path: "/component/calendar", label: "Calendar", icon: faCalendarAlt },
    { path: "/component/checkbox", label: "Checkbox", icon: faCheckSquare },
    { path: "/component/colorPicker", label: "Color Picker", icon: faPalette },
    { path: "/component/productVariantEditor", label: "Product Variant Editor", icon: faEdit },
    { path: "/component/featureSection", label: "Feature Section", icon: faListAlt },
    { path: "/component/registrationForm", label: "Registration Form", icon: faUserPlus },
  ];

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <h1>Explore Our Component Library</h1>
        <p>
          Discover a wide range of beautifully crafted and highly customizable components 
          designed to help you build stunning and modern e-commerce websites. Click on any component 
          below to see its variations and styles.
        </p>
      </div>

      <div className={styles.componentWrapper}>
        <div className={styles.list}>
          {components.map((component) => (
            <Link to={component.path} className={styles.card} key={component.path}>
              <div className={styles.cardContent}>
                {component.icon ? (
                  <FontAwesomeIcon icon={component.icon} className={styles.icon} />
                ) : (
                  <img src={component.imageUrl} alt={component.label} className={styles.icon} />
                )}
                <h5>{component.label}</h5>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Component;
