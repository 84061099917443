// src/components/AnimatedButton.tsx
import React from 'react';
import styles from './ButtonV2.module.scss';
import { ButtonComponentProps } from '../ButtonComponentProps';


const ButtonV2: React.FC<ButtonComponentProps> = ({ title="Button 2", onClick }) => {
  return <button id={styles.button_2} onClick={onClick}>{title}</button>;
};

export default ButtonV2;