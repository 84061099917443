import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import styles from './SocialMediaLinksV3.module.scss'
import { SocialMediaLinkType } from '../../../types/Data/SocialMediaSettingsType';
import { SocialMediaLinksComponentProps } from '../SocialMediaLnksComponentProps';

const SocialMediaLinksV3: React.FC<SocialMediaLinksComponentProps> = () => {
  const socialMediaLinks = useSelector((state: RootState) => state.dashboard?.socialMedia?.socialMediaLinks) as SocialMediaLinkType[];

  return (
    <div className={styles.socialMediaContainerV3}>
      {socialMediaLinks.map((socialMediaLink: SocialMediaLinkType, index:number) => (
        <a key={socialMediaLink.platform} href={socialMediaLink.url as string} target="_blank" rel="noopener noreferrer" className={styles.socialMediaLinkV3}>
          <img src={socialMediaLink.iconUrl} alt={`${socialMediaLink.platform} icon`} className={styles.socialMediaIconV3} />
        </a>
      ))}
    </div>
  );
};

export default SocialMediaLinksV3;
