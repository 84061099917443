import React, { useState } from 'react';
import styles from './ContactFormV1.module.scss';
import { ContactFormComponentProps, ContactUsSubject } from '../ContactFormComponentProps';

const ContactFormV1: React.FC<ContactFormComponentProps> = ({ onSubmit = () => null, initialData = {}, submitButtonText = "Send" }) => {
  const [formData, setFormData] = useState({
    name: initialData.name || '',
    email: initialData.email || '',
    subject: initialData.subject || '',
    message: initialData.message || '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const subjectOptions = Object.values(ContactUsSubject).map((value) => ({ label: value, value }));

  return (
    <form className={styles.contactForm} onSubmit={handleSubmit}>
      <div className={styles.formGroup}>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          className={`${styles.input} ${formData.name ? styles.filled  : ''}`}
          placeholder=''
        />
        <label className={styles.label}>Name</label>
      </div>
      <div className={styles.formGroup}>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          className={`${styles.input} ${formData.email ? styles.filled : ''}`}
          placeholder=''
        />
        <label className={styles.label}>Email</label>
      </div>
      <div className={styles.formGroup}>
        <select
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          required
          className={`${styles.select} ${formData.email ? styles.filled : ''}`}
        >
          <option value="" disabled>Select a Subject</option>
          {subjectOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.formGroup}>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
          className={`${styles.textarea} ${formData.message ? styles.filled : ''}`}
          placeholder=''
        />
        <label className={styles.label}>Message</label>
      </div>
      <button type="submit" className={styles.submitButton}>{submitButtonText}</button>
    </form>
  );
};

export default ContactFormV1;
