import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import exampleBlogPost from './exampleBlogPost';
import { BlogPostType } from '../../../types/Data/BlogPostType';
import { getAllBlogPosts } from '../../../api/blogPost/blogPostApi';

// Async Thunks
export const fetchAllBlogPosts = createAsyncThunk(
  'blogPost/fetchAllBlogPosts',
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await getAllBlogPosts(params);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

interface BlogPostState {
  blogPosts: BlogPostType[],
  loading: boolean,
  error: string | null;
}

const initialState: BlogPostState =  {
  blogPosts: [...exampleBlogPost],
  loading: false,
  error: null,
}

const blogPostSlice = createSlice({
  name: 'blogPosts',
  initialState,
  reducers: {
    dispatchAddBlogPost(state, action: PayloadAction<BlogPostType>) {
      state.blogPosts.push(action.payload);
    },
    dispatchUpdateBlogPost(state, action: PayloadAction<BlogPostType>) {
      const index = state.blogPosts.findIndex(post => post._id === action.payload._id);
      if (index !== -1) {
        state.blogPosts[index] = action.payload;
      }
    },
    dispatchDeleteBlogPost(state, action: PayloadAction<string>) {
      state.blogPosts = state.blogPosts.filter(post => post._id !== action.payload);
    },
    dispatchLoadBlogPost(state, action: PayloadAction<BlogPostType[]>) {
      return {
        ...state,
        blogPosts: action.payload
        }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBlogPosts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllBlogPosts.fulfilled, (state, action: PayloadAction<BlogPostType[]>) => {
        state.loading = false;
        state.blogPosts = action.payload;
      })
      .addCase(fetchAllBlogPosts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
    }
});

export const { dispatchAddBlogPost, dispatchUpdateBlogPost, dispatchDeleteBlogPost, dispatchLoadBlogPost } = blogPostSlice.actions;
export default blogPostSlice.reducer;
