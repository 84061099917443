import React from 'react';
import styles from './ButtonProceedToCheckoutV2.module.scss';
import { ButtonProceedToCheckoutProps } from '../ButtonCheckoutComponent';

const ButtonProceedToCheckoutV2: React.FC<ButtonProceedToCheckoutProps> = ({
  label = 'Proceed to Checkout',
  onClick = ()=>null
}) => {
  return (
    <button className={styles.glassButton} onClick={onClick}>
      {label}
    </button>
  );
};

export default ButtonProceedToCheckoutV2;
