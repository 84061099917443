import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartData, ChartOptions } from 'chart.js';
import styles from './ChartCustomerAgeDistribution.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Store/store';
import { CustomerType } from '../../../../../types/Data/CustomerType';

interface ChartCustomerAgeDistributionProps {}

const ChartCustomerAgeDistribution: React.FC<ChartCustomerAgeDistributionProps> = () => {
    const customers = useSelector((state: RootState) => state.dashboard.customers.customers) as CustomerType[];

    // Function to calculate age distribution
    const getAgeDistribution = () => {
        const ageDistribution: { [key: string]: number } = {};
        
        customers.forEach((customer) => {
            const ageGroup = getAgeGroup(customer.age);  // Get age group label
            if (ageGroup) {
                ageDistribution[ageGroup] = (ageDistribution[ageGroup] || 0) + 1;
            }
        });

        return ageDistribution;
    };

    // Helper function to group ages into buckets
    const getAgeGroup = (age?: number): string | null => {
        if (age === undefined || age === null) return 'Unknown';
        if (age < 18) return '<18';
        if (age >= 18 && age <= 24) return '18-24';
        if (age >= 25 && age <= 34) return '25-34';
        if (age >= 35 && age <= 44) return '35-44';
        if (age >= 45 && age <= 54) return '45-54';
        if (age >= 55 && age <= 64) return '55-64';
        if (age >= 65) return '65+';
        return null;
    };

    const ageDistribution = getAgeDistribution();
    const ageLabels = Object.keys(ageDistribution);
    const ageCounts = Object.values(ageDistribution);

    const data: ChartData<'bar'> = {
        labels: ageLabels,
        datasets: [
            {
                label: 'Number of Customers',
                data: ageCounts,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                ticks: {
                    color: 'white', // X-axis labels color
                },
            },
            y: {
                beginAtZero: true,
                ticks: {
                    color: 'white', // Y-axis labels color
                },
            },
        },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: 'white', // Legend text color
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.dataset.label}: ${context.raw}`;
                    },
                },
            },
        },
    };

    return (
        <div className={styles.container}>
            <h3>Customer Age Distribution</h3>
            <div className={styles.chartContainer}>
                <Bar data={data} options={options} />
            </div>
        </div>
    );
};

export default ChartCustomerAgeDistribution;

