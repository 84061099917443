import * as FaIcons from 'react-icons/fa';
import * as IoIcons from 'react-icons/io';

const getIconComponent = (iconName: string) => {
    const IconComponentFa = FaIcons[iconName as keyof typeof FaIcons];
    const IconComponentIo = IoIcons[iconName as keyof typeof IoIcons];

    // Check if the icon exists in FaIcons first, then in IoIcons
    if (IconComponentFa) {
        return <IconComponentFa />;
    } else if (IconComponentIo) {
        return <IconComponentIo />;
    }

    return null;  // Return null if the icon is not found in both sets
};

export {
    getIconComponent
};
