import { WishlistType } from "../../types/Data/WishlistType";
import CustomerWishListV1 from "./CustomerWishListV1/CustomerWishListV1";

enum CustomerWishListComponentVersions {
    V1 = "v1",
};

const CustomerWishListComponentMapping:Record<CustomerWishListComponentVersions, React.FC<any>> = {
    [CustomerWishListComponentVersions.V1]: CustomerWishListV1,  
};

interface CustomerWhishListComponentProps {
    wishlistItems?: WishlistType['items'];
};

export {
    CustomerWishListComponentVersions,
    CustomerWishListComponentMapping
};

export type {
    CustomerWhishListComponentProps
}