import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dataOrders from './exmpleData';
import { OrderType, OrderStatus } from '../../../types/Data/OrderType';
import { getAllOrders } from '../../../api/order/orderApi';

export const fetchOrders = createAsyncThunk(
    'orders/fetchOrders',
    async (params: { page: number; [key: string]: any }, { rejectWithValue }) => {
        try {
            const response = await getAllOrders(params);
            return {
                orders: response.data.data.orders as Partial<OrderType>[],
                total: response.data.data.total,
                page: response.data.data.page,
                pages: response.data.data.pages,
            };
            
        } catch (err: any) {
            return rejectWithValue('Failed to load orders');
        }
    }
);

interface OrdersState {
    orders: Partial<OrderType>[];
    loading: boolean;
    error: string | null;
    page: number;
    totalPages: number;
}

const initialState: OrdersState = {
    orders: dataOrders,
    loading: false,
    error: null,
    page: 1,
    totalPages: 1,
};

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        dispatchAddOrder(state, action: PayloadAction<OrderType>) {
            state.orders.push(action.payload);
        },
        dispatchUpdateOrder(state, action: PayloadAction<OrderType>) {
            const index = state.orders.findIndex(order => order._id === action.payload._id);
            if (index !== -1) {
                state.orders[index] = action.payload;
            }
        },
        dispatchDeleteOrder(state, action: PayloadAction<string>) {
            state.orders = state.orders.filter(order => order._id !== action.payload);
        },
        dispatchChangeOrderStatus(state, action: PayloadAction<{ id: string, status: OrderStatus }>) {
            const index = state.orders.findIndex(order => order._id === action.payload.id);
            if (index !== -1) {
                state.orders[index].status = action.payload.status;
            }
        },
        dispatchLoadOrders(state, action: PayloadAction<OrderType[]>) {
            state.orders = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrders.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchOrders.fulfilled, (state, action: PayloadAction<{ orders: Partial<OrderType>[]; total: number; page: number; pages: number }>) => {
                state.loading = false;
                state.orders = action.payload.orders;
                state.page = action.payload.page;
                state.totalPages = action.payload.pages;
            })
            .addCase(fetchOrders.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const { dispatchAddOrder, dispatchUpdateOrder, dispatchDeleteOrder, dispatchChangeOrderStatus, dispatchLoadOrders } = ordersSlice.actions;
export default ordersSlice.reducer;
