import React from 'react'
import styles from '../Component.module.scss';
import TrustSignalsV1 from '../../UIElements/TrustSignals/TrustSignalsV1/TrustSignalsV1';
import TrustSignalsV2 from '../../UIElements/TrustSignals/TrustSignalsV2/TrustSignalsV2';
import TrustSignalsV3 from '../../UIElements/TrustSignals/TrustSignalsV3/TrustSignalsV3';

const TrustSignalsComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <TrustSignalsV1/>
        </div>
        <div className={styles.element}>
            <TrustSignalsV2/>
        </div>
        <div className={styles.element}>
            <TrustSignalsV3/>
        </div>
    </div>
  )
}

export default TrustSignalsComponentPage