import React from 'react';
import styles from './EmailCampaingHTMLContent.module.scss';

interface HTMLContentViewerProps {
    htmlContent?: string;
    title?: string;
}

const EmailCampaingHTMLContent: React.FC<HTMLContentViewerProps> = ({ 
    htmlContent = `
    <h1>Welcome to Our Summer Sale!</h1>
    <p>Don't miss out on our exclusive offers.</p>
    <img src="https://example.com/summer-sale.jpg" alt="Summer Sale" />
    <p><a href="https://example.com">Shop Now</a></p>
`,
    title = "Defaut Template"
}) => {
    return (
        <div className={styles.htmlContentViewer}>
            {title && <h2 className={styles.title}>{title}</h2>}
            <div className={styles.contentContainer} dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </div>
    );
};

export default EmailCampaingHTMLContent;
