import React from 'react'
import styles from '../Component.module.scss';
import BreadcrumbV1 from '../../UIElements/Breadcrumb/BreadcrumbV1/BreadcrumbV1';
import BreadcrumbV2 from '../../UIElements/Breadcrumb/BreadcrumbV2/BreadcrumbV2';
import BreadcrumbV3 from '../../UIElements/Breadcrumb/BreadcrumbV3/BreadcrumbV3';

const BreadcrumbComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <BreadcrumbV1/>
        </div>
        <div className={styles.element}>
            <BreadcrumbV2/>
        </div>
        <div className={styles.element}>
            <BreadcrumbV3/>
        </div>
    </div>
  )
}

export default BreadcrumbComponentPage