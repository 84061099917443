import React, { useState } from 'react';
import styles from './CustomerChatRoomV1.module.scss';
import { CustomerChatRoomComponentProps } from '../CustomerChatRoomProps';
import { chatRoomMock } from '../customerChatRoomDefaultProps';
import { isMessageArray, MessageType } from '../../../types/Data/MessageType';

const CustomerChatRoomV1: React.FC<CustomerChatRoomComponentProps> = ({ 
  chatRoom = chatRoomMock , 
  onSendMessage = () => null,
}) => {
  const [newMessage, setNewMessage] = useState('');

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      onSendMessage && onSendMessage(newMessage);
      setNewMessage('');
    }
  };

  return (
    <div className={styles.chatRoom}>
      <div className={styles.messageList}>
        {chatRoom?.messages && isMessageArray(chatRoom?.messages) && chatRoom?.messages.map((message) => (
          <div
            key={message._id}
            className={`${styles.messageItem} ${message.senderModel === 'admin' ? styles.adminMessage : styles.customerMessage}`}
          >
            <div className={styles.messageHeader}>
              <span className={styles.sender}>{message.senderModel === 'admin' ? 'Admin' : 'Customer'}</span>
              <span className={styles.timestamp}>{message.createdAt.toDateString()}</span>
            </div>
            <div className={styles.messageContent}>{message.content}</div>
          </div>
        ))}
      </div>
      <div className={styles.replyBox}>
        <input
          type="text"
          placeholder="Type your message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          className={styles.replyInput}
        />
        <button onClick={handleSendMessage} className={styles.sendButton}>
          Send
        </button>
      </div>
    </div>
  );
};

export default CustomerChatRoomV1;
