import { ProductType } from "../../types/Data/ProductType";
import FeaturedProductsV1 from "./FeaturedProductsV1/FeaturedProductsV1";
import FeaturedProductsV2 from "./FeaturedProductsV2/FeaturedProductsV2";
import FeaturedProductsV3 from "./FeaturedProductsV3/FeaturedProductsV3";
import FeaturedProductsV4 from "./FeaturedProductsV4/FeaturedProductsV4";
import FeaturedProductsV5 from "./FeaturedProductsV5/FeaturedProductsV5";
import FeaturedProductsV6 from "./FeaturedProductsV6/FeaturedProductsV6";
import FeaturedProductsV7 from "./FeaturedProductsV7/FeaturedProductsV7";
import FeaturedProductsV8 from "./FeaturedProductsV8/FeaturedProductsV8";

enum FeaturedProductsVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
    V4 = "v4",
    V5 = "v5", 
    V6 = "v6",
    V7 = "v7",
    V8 = "v8",
};

const FeaturedProductsMapping:Record<FeaturedProductsVersions, React.FC<any>> = {
    [FeaturedProductsVersions.V1]: FeaturedProductsV1,
    [FeaturedProductsVersions.V2]: FeaturedProductsV2,
    [FeaturedProductsVersions.V3]: FeaturedProductsV3,
    [FeaturedProductsVersions.V4]: FeaturedProductsV4,
    [FeaturedProductsVersions.V5]: FeaturedProductsV5,
    [FeaturedProductsVersions.V6]: FeaturedProductsV6,
    [FeaturedProductsVersions.V7]: FeaturedProductsV7,
    [FeaturedProductsVersions.V8]: FeaturedProductsV8,
};

interface FeaturedProductsComponentProps {
    title?:string;
    productsId?: string[];
    products?: Partial<ProductType>[];
    onClick?: (id:string) => void;
};

export {
    FeaturedProductsMapping,
    FeaturedProductsVersions
};

export type {
    FeaturedProductsComponentProps,
};