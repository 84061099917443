import React from 'react';
import styles from './TrustSignalsV2.module.scss';
import { FaCcVisa, FaCcMastercard, FaCcPaypal, FaShieldAlt } from 'react-icons/fa';
import { TrustSignalsComponentProps } from '../TrustSignalsComponentProps';
import { trustSignalsDefaultProps } from '../trustSignalsDefaultProps';

const TrustSignalsV2: React.FC<TrustSignalsComponentProps> = ({
  paymentMethods = trustSignalsDefaultProps.paymentMethods,
  securePaymentText = trustSignalsDefaultProps.securePaymentText,
  guaranteeText = trustSignalsDefaultProps.guaranteeText,
  satisfactionText = trustSignalsDefaultProps.satisfactionText
}) => {
  return (
    <div className={styles.trustSignals}>
      <div className={styles.trustItem}>
        <FaShieldAlt className={styles.icon} />
        <p>{guaranteeText}</p>
      </div>
      <div className={styles.paymentMethods}>
        {paymentMethods?.includes("Visa") && <FaCcVisa className={styles.icon} />}
        {paymentMethods?.includes("Mastercard") && <FaCcMastercard className={styles.icon} />}
        {paymentMethods?.includes("PayPal") && <FaCcPaypal className={styles.icon} />}
      </div>
    </div>
  );
};

export default TrustSignalsV2;
