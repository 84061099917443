import React from "react";
import { useParams } from "react-router-dom";
import DashboardHeader from "../../../components/Header/DashboardHeader/DashboardHeader";
import CustomerInfo from "./CustomerInfo/CustomerInfo";
import styles from './CustomerDetail.module.scss'
import CustomerOrderHistory from "./CustomerOrderHistory/CustomerOrderHistory";
import CustomerSecurity from "./CustomerSecurity/CustomerSecurity";
import { CustomerType } from "../../../types/Data/CustomerType";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Store/store";
import { OrderType } from "../../../types/Data/OrderType";
import CustomerWishlist from "./CustomerWishlist/CustomerWishList";
import CustomerSupportTickets from "./CustomerSupportTickets/CustomerSupportTickets";
import SendEmailToCustomer from "./SendEmailToCustomer/SendEmailToCustomer";

const CustomerDetails:React.FC = () => {
    const { customerId } = useParams<{customerId: string}>();

    const customers = useSelector<RootState>(state=>state.dashboard.customers.customers) as CustomerType[];
    const customer:CustomerType = customers.filter(customer => customer._id === customerId)[0]
    
    const orders = useSelector<RootState>((state)=>state.dashboard.orders.orders.filter(el => el.customer?._id === customer._id)) as OrderType[]
    return (
        <div className={styles.customer_detail}>
            <DashboardHeader title="Customer Info"/>
            <CustomerInfo customer={customer} onCustomerUpdate={()=>null}/>
            <CustomerSecurity customer={customer}/>
            <CustomerOrderHistory customerId={customer._id}/>
            <CustomerWishlist customerId={customer._id}/>
            <CustomerSupportTickets customerId={customer._id}/>
            <SendEmailToCustomer customerId={customer._id} customerEmail={customer.email}/>
        </div>
    )
}


export default CustomerDetails