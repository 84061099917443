import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import notificationsExample from './exampleNotification';
import { NotificationType } from '../../../types/Data/NotificationType';
import { getAllNotifications } from '../../../api/notification/notificationApi';

// Thunk for fetching all notifications
export const fetchAllNotifications = createAsyncThunk(
    'notifications/fetchAllNotifications',
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await getAllNotifications(params);
            return response.notifications;
        } catch (error: any) {
            console.error('Error fetching notifications:', error);
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch notifications');
        }
    }
);

interface NotificationState {
    notifications: NotificationType[];
    loading: boolean;
    error: string | null;
}

const initialState: NotificationState = {
    notifications: [...notificationsExample],
    loading: false,
    error: null,
};

const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addNotification(state, action: PayloadAction<NotificationType>) {
            state.notifications.push(action.payload);
        },
        updateNotification(state, action: PayloadAction<NotificationType>) {
            const index = state.notifications.findIndex(notification => notification._id === action.payload._id);
            if (index !== -1) {
                state.notifications[index] = action.payload;
            }
        },
        deleteNotification(state, action: PayloadAction<string>) {
            state.notifications = state.notifications.filter(notification => notification._id !== action.payload);
        },
        loadNotifications(state, action: PayloadAction<NotificationType[]>) {
            state.notifications = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllNotifications.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllNotifications.fulfilled, (state, action: PayloadAction<NotificationType[]>) => {
                state.loading = false;
                state.notifications = action.payload;
            })
            .addCase(fetchAllNotifications.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const { addNotification, updateNotification, deleteNotification, loadNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;
