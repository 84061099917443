import React from 'react'
import styles from '../Component.module.scss';

import DragAndDrop1 from '../../components/DragAndDrop/DragAndDropV1/DragAndDropV1';

const DragAndDropComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <DragAndDrop1/>
        </div>
    </div>
  )
}

export default DragAndDropComponentPage