import React from "react";
import styles from './ReturnRequestInfo.module.scss';
import { ReturnRequestType } from "../../../../types/Data/ReturnRequestType";

interface ReturnRequestInfoProps {
    returnRequest: ReturnRequestType;
}

const ReturnRequestInfo: React.FC<ReturnRequestInfoProps> = ({ returnRequest }) => {
    return (
        <div className={styles.returnRequestInfo}>
            <h2>Return Request Information</h2>
            <div className={styles.section}>
                <p className={styles.label}><strong>Order:</strong> {typeof returnRequest.order === 'string' ? returnRequest.order : returnRequest?.order?._id} </p>
            </div>
            <div className={styles.section}>
                <p className={styles.label}><strong>Customer:</strong> {returnRequest.customerName}</p>
            </div>
            <hr className={styles.separator} />
            <div className={styles.section}>
                <p className={styles.label}><strong>Return Date:</strong> {returnRequest.createdAt && returnRequest.createdAt.toString()}</p>
            </div>
            <hr className={styles.separator} />
            <div className={styles.section}>
                <p className={styles.label}><strong>Status:</strong> {returnRequest.status}</p>
            </div>
            {returnRequest.refundRequestId && (
                <>
                    <div className={styles.section}>
                        <p className={styles.label}><strong>Refund Request ID:</strong> {returnRequest.refundRequestId}</p>
                    </div>
                    <hr className={styles.separator} />
                </>
            )}
        </div>
    );
};

export default ReturnRequestInfo;
