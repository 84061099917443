import React, { useState } from 'react';
import styles from './Filters.module.scss';
import SearchBar4 from '../../../components/SearchBar/SearchBarV4/SerachBarV4';

interface FiltersProps {
    query: {
        search: string;
    };
    onQueryChange?: (key:string, value: any) => void;
    
}

const Filters: React.FC<FiltersProps> = ({ query, onQueryChange = () => null }) => {
    return (
        <div className={styles.filters}>
            <SearchBar4
                placeholder="Search customer..."
                value={query.search}
                onSearch={(search) => onQueryChange('search', search)}
            />
        </div>
    );
};

export default Filters;
