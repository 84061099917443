import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import exampleCampaigns from './dataCampaigns';
import { EmailCampaignType } from '../../../types/Data/EmailCampaignType';
import { getAllEmailCampaigns } from '../../../api/emailCampaing/emailCampaingApi';

// Create the async thunk for fetching products
export const fetchEmailCampaings = createAsyncThunk(
  'emailCampaigns/fetchEmailCampaigns',
  async (params: { page?: number; [key: string]: any } = {}, { rejectWithValue }) => {
    try {
      // Pass the params to getAllPromotions
      const response = await getAllEmailCampaigns();
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch promotions');
    }
  }
);

interface CampaignsState {
  campaigns: Partial<EmailCampaignType>[];
  loading: boolean;
  error: string | null | {};
}

const initialState: CampaignsState = {
  campaigns: exampleCampaigns,
  loading: false,
  error: null,
};

const emailCampaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    addCampaign(state, action: PayloadAction<EmailCampaignType>) {
      state.campaigns.push(action.payload);
    },
    updateCampaign(state, action: PayloadAction<EmailCampaignType>) {
      const index = state.campaigns.findIndex(campaign => campaign._id === action.payload._id);
      if (index !== -1) {
        state.campaigns[index] = action.payload;
      }
    },
    deleteCampaign(state, action: PayloadAction<string>) {
      state.campaigns = state.campaigns.filter(campaign => campaign._id !== action.payload);
    },
    toggleCampaignStatus(state, action: PayloadAction<string>) {
      const campaign = state.campaigns.find(campaign => campaign._id === action.payload);
      if (campaign) {
        campaign.status = campaign.status;
      }
    },
    loadCampaign(state, action: PayloadAction<EmailCampaignType[]>) {
      state.campaigns = action.payload;
  }
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchEmailCampaings.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchEmailCampaings.fulfilled, (state, action: PayloadAction<{ campaings: Partial<EmailCampaignType>[] }>) => {
            state.loading = false;
            state.campaigns = action.payload.campaings;
        })
        .addCase(fetchEmailCampaings.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload || action.error.message || 'Unknown error';
        });
    }
});

export const { addCampaign, updateCampaign, deleteCampaign, toggleCampaignStatus, loadCampaign } = emailCampaignsSlice.actions;
export default emailCampaignsSlice.reducer;
