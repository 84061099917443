import React, { useState, useEffect } from 'react';
import styles from './HeroBannerV6.module.scss';
import { HeroBannerComponentProps } from '../HeroBannerComponentProps';
import defaultHeroBanner from '../defaultHeroBanner';


const HeroBannerV6: React.FC<HeroBannerComponentProps> = ({ slides = defaultHeroBanner.slides, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [fade, setFade] = useState<Boolean>(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slides!.length);
        setFade(true);
      }, 500); // match the duration of the fade-out animation
    }, interval);

    return () => clearInterval(timer);
  }, [slides!.length, interval]);

  return (
    <div className={styles.carousel}>
      <div className={`${styles.carousel_slide} ${fade ? styles.fade_in : styles.fade_out}`}>
        <img src={slides![currentIndex].imageUrl} alt={slides![currentIndex].title} />
        <div className={styles.carousel_overlay}>
          <div className={styles.carousel_content}>
            <h2>{slides![currentIndex].title}</h2>
            <p>{slides![currentIndex].text}</p>
            {slides![currentIndex].buttonUrl && (
              <a href={slides![currentIndex].buttonUrl} className={styles.carousel_button}>
                {slides![currentIndex].buttonText}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBannerV6;
