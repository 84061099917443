import React, { useEffect, useState } from 'react';
import styles from './RefundProcessDetailPage.module.scss';
import { useParams } from 'react-router-dom';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../Store/store';
import { RefundProcessType } from '../../../types/Data/RefundProcessType';
import RefundProcessInfo from './RefundProcessInfo/RefundProcessInfo';
import RefundValidation from './RefundValidation/RefundValidation';
import RefundNoteSection from './RefundNoteSection/RefundNoteSection';
import { getRefundProcessById, patchRefundProcess, processRefund, updateRefundProcessById } from '../../../api/refundProcess/refundProcessApi';
import { handleApiError } from '../../../api/apiError';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import SaveButton from '../../../components/Button/SaveButton/SaveButton';
import ButtonCancel from '../../../components/Button/ButtonCancel/ButtonCancel';

const RefundProcessDetailPage: React.FC = () => {
    const { refundProcessId } = useParams<{ refundProcessId: string }>();
    const dispatch = useDispatch();
    
    const refundProcess = useSelector<RootState>((state) => 
        state.dashboard.refundProcess.refundProcess.find(ret => ret._id === refundProcessId)
    ) as RefundProcessType;

    // State variables for original and current review
    const [originalRefundProcess, setOriginalRefundProcess] = useState<RefundProcessType>(refundProcess || {});
    const [currentRefundProcess, setCurrentRefundProcess] = useState<RefundProcessType>(refundProcess || {});

    // State variables handling fetch status
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<{message:string, status:number | undefined} | null>()
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const getRefundProcess= async (refundProcessId: string) => {
        setIsLoading(true); // Set loading to true before request
        try {
          let data = await getRefundProcessById(refundProcessId);
          return data.data;
        } catch (error: any) {
          if (error.name === 'AbortError') {
            console.log('Request cancelled');
          } else {
            let handledError = handleApiError(error);
            setError({ message: handledError.message, status: handledError.status });
          }
        } finally {
          setIsLoading(false); // Ensure loading is turned off
        }
    };

    /*
    useEffect(() => {  
        if (refundProcessId) {
            getRefundProcess(refundProcessId)
            .then(data => {
              if (data) {
                setOriginalRefundProcess(data);  // Set the original review
                setCurrentRefundProcess(data);   // Set the current review
              }
            })
            .catch(error => {
              let handledError = handleApiError(error);
              setError({ message: handledError.message, status: handledError.status });
            });
        }
    }, [refundProcessId]);
    */


    // Function to handle updates to specific sections of the order
    const handleUpdateRefundtSegment = (segment: Partial<RefundProcessType>) => {
        const updatedRequest = { ...currentRefundProcess, ...segment };
        setCurrentRefundProcess(updatedRequest);
    };

    const handlePatch = async (data:Partial<RefundProcessType>) => {
        setIsSaving(true);  // To handle button loading state
        try {
          const response = await patchRefundProcess(currentRefundProcess._id!, data);  // Call the API to save the review
          
          if (response.status === 200) {
            // Assuming 200 is the success status code
            setOriginalRefundProcess(response.data.data)
            alert('Refund process updated successfully!');  // Success message
            
            // Optionally reset form or state if needed
          } else {
            // Handle unexpected success cases
            alert('Something went wrong while updating the refund process!');
          }
        } catch (error: any) {
          const handledError = handleApiError(error);  // This function should parse and return user-friendly error messages
          alert(`Failed to save refund process: ${handledError}`);
        } finally {
          setIsSaving(false);  // Stop loading state
        }
    };

    const handleUpdate = async () => {
        setIsSaving(true);  // To handle button loading state
        try {
          const response = await updateRefundProcessById(currentRefundProcess._id!, currentRefundProcess);  // Call the API to save the review
          
          if (response.status === 200) {
            // Assuming 200 is the success status code
            setOriginalRefundProcess(response.data.data)
            alert('Refund process updated successfully!');  // Success message
            
            // Optionally reset form or state if needed
          } else {
            // Handle unexpected success cases
            alert('Something went wrong while updating the refund process!');
          }
        } catch (error: any) {
          const handledError = handleApiError(error);  // This function should parse and return user-friendly error messages
          alert(`Failed to save return refund process: ${handledError}`);
        } finally {
          setIsSaving(false);  // Stop loading state
        }
    };

    const handleCancel = () => {
        setCurrentRefundProcess(originalRefundProcess)
    }

    const handleProcessRefund = async () => {
        setIsSaving(true);
        try {
            const response = await processRefund(refundProcess._id!)
            if (response.status === 200) {
                alert(response.data.message)
            }
        } catch (error: any) {
            const handledError = handleApiError(error);
            alert(handledError)
        } finally {
            setIsLoading(false)
        };
    };



    if (isLoading) return <LoadingIndicatorV1 />;
    if (error) return <ErrorBannerV1 message={error.message || 'Error'} status={error.status} />;
    
    return (
        <div className={styles.cancellationDetails}>
            <DashboardHeader title='Refund Process Details' />
            
            <RefundProcessInfo refundProcess={currentRefundProcess}/>
            <RefundValidation refundProcess={currentRefundProcess}>
                <SaveButton onClick={handleProcessRefund}/>
            </RefundValidation>
            <RefundNoteSection notes={currentRefundProcess.notes || ''} onNoteChange={(notes)=>handleUpdateRefundtSegment({notes:notes})}>
                <SaveButton style={{marginTop: 20}} onClick={()=>handlePatch({notes:currentRefundProcess.notes})}/>
            </RefundNoteSection>

            <div className={styles.buttonContainer}>
                <SaveButton onClick={handleUpdate}/>
                <ButtonCancel style={{marginLeft:10}} onClick={handleCancel}/>
            </div>
        </div>
    );
};

export default RefundProcessDetailPage;
