import React from "react";
import styles from "./NewsletterSignupV1.module.scss";
import { NewsLetterSignupComponentProps } from "../NewsLetterSignupProps";

const NewsletterSignupV1: React.FC<NewsLetterSignupComponentProps> = () => {
  return (
    <div className={styles.newsletterV1}>
      <div className={styles.newsletterContent}>
        <h2>Stay Updated!</h2>
        <p>Subscribe to our newsletter and never miss an update.</p>
        <input type="email" placeholder="Enter your email" />
        <button>Subscribe</button>
      </div>
    </div>
  );
};

export default NewsletterSignupV1;
