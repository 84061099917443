import StepIndicatorV1 from "./StepIndicatorV1/StepIndicatorV1";
import StepIndicatorV2 from "./StepIndicatorV2/StepIndicatorV2";
import StepIndicatorV3 from "./StepIndicatorV3/StepIndicatorV3";

enum StepIndicatorVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const StepIndicatorMapping:Record<StepIndicatorVersions, React.FC<any>> = {
    [StepIndicatorVersions.V1]: StepIndicatorV1,
    [StepIndicatorVersions.V2]: StepIndicatorV2,
    [StepIndicatorVersions.V3]: StepIndicatorV3,
};

interface StepIndicatorComponentProps {
    currentStep?: string;
    steps?: string[];
    onStepClick?: (stepIndex: string) => void;
};

export {
    StepIndicatorVersions,
    StepIndicatorMapping,
};

export type {
    StepIndicatorComponentProps
};