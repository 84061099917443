import { OrderItemType } from "../../types/Data/OrderType";
import CartSummarySidebarV1 from "../../UIElements/CartSummarySidebar/CartSummarySidebarV1/CartSummarySidebarV1";
import CartSummarySidebarV2 from "../../UIElements/CartSummarySidebar/CartSummarySidebarV2/CartSummarySidebarV2";
import CartSummarySidebarV3 from "../../UIElements/CartSummarySidebar/CartSummarySidebarV3/CartSummarySidebarV3";

enum CartSummarySidebarVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const CartSummarySidebarMapping:Record<CartSummarySidebarVersions, React.FC<any>> = {
    [CartSummarySidebarVersions.V1]: CartSummarySidebarV1,
    [CartSummarySidebarVersions.V2]: CartSummarySidebarV2,
    [CartSummarySidebarVersions.V3]: CartSummarySidebarV3,
};


interface CartSummarySidebarComponentProps {
    items?: OrderItemType[]; 
    discount?: number; 
    tax?: number; 
    deliveryPrice?: number; 
    onCheckout?: () => void;
};

export {
    CartSummarySidebarVersions,
    CartSummarySidebarMapping,
};

export type {
    CartSummarySidebarComponentProps
};