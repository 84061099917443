import React from 'react'
import styles from '../Component.module.scss';
import ProductCardListV2 from '../../UIElements/ProductCardList/ProductCardListV2/ProductCardListV2';
import ProductCardListV1 from '../../UIElements/ProductCardList/ProductCardListV1/ProductCardListV1';
import ProductCardListV3 from '../../UIElements/ProductCardList/ProductCardListV3/ProductCardListV3';
import ProductCardListV4 from '../../UIElements/ProductCardList/ProductCardListV4/ProductCardListV4';
import ProductCardListV5 from '../../UIElements/ProductCardList/ProductCardListV5/ProductCardListV5';
import ProductCardListV6 from '../../UIElements/ProductCardList/ProductCardListV6/ProductCardListV6';
import ProductCardListV7 from '../../UIElements/ProductCardList/ProductCardListV7/ProductCardListV7';
import ProductCardListV8 from '../../UIElements/ProductCardList/ProductCardListV8/ProductCardListV8';
import ProductCardListV9 from '../../UIElements/ProductCardList/ProductCardListV9/ProductCardListV9';
import ProductCardListV10 from '../../UIElements/ProductCardList/ProductCardListV10/ProductCardListV10';
import { productCardListDefaultProps } from '../../UIElements/ProductCardList/productCardListDefaultProps';

const ProductCardListComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <ProductCardListV1 product={productCardListDefaultProps}/>
        </div>
        <div className={styles.element}>
            <ProductCardListV2 product={productCardListDefaultProps}/>
        </div>
        <div className={styles.element}>
            <ProductCardListV3 product={productCardListDefaultProps}/>
        </div>
        <div className={styles.element}>
            <ProductCardListV4 product={productCardListDefaultProps}/>
        </div>
        <div className={styles.element}>
            <ProductCardListV5 product={productCardListDefaultProps}/>
        </div>
        <div className={styles.element}>
            <ProductCardListV6 product={productCardListDefaultProps}/>
        </div>
        <div className={styles.element}>
            <ProductCardListV7 product={productCardListDefaultProps}/>
        </div>
        <div className={styles.element}>
            <ProductCardListV8 product={productCardListDefaultProps}/>
        </div>
        <div className={styles.element}>
            <ProductCardListV9 product={productCardListDefaultProps}/>
        </div>
        <div className={styles.element}>
            <ProductCardListV10 product={productCardListDefaultProps}/>
        </div>
    </div>
  )
}

export default ProductCardListComponentPage