import { DiscountCodeType } from "../../../types/Data/DiscountCodeType";

const exampleDiscountCodes: DiscountCodeType[] = [
    {
        _id: '1',
        name: 'Summer Sale',
        description: 'Get 20% off on summer items',
        code: 'SUMMER20',
        type: 'percentage',
        value: 20,
        startDate: '2023-06-01',
        endDate: '2023-06-30',
        isActive: true,
        productIds: ['A1', 'B2', 'C3'],
        conditions: {
            minOrderValue: 50,
            customerSegment: 'VIP',
            productCategories: ['summer', 'clothing'],
        },
    },
    {
        _id: '2',
        name: 'Free Shipping',
        description: 'Free shipping on orders over $100',
        code: 'FREESHIP',
        type: 'fixed',
        value: 0,
        startDate: '2023-07-01',
        endDate: '2023-07-31',
        isActive: true,
        productIds: [],
        conditions: {
            minOrderValue: 100,
        },
    },
    {
        _id: '3',
        name: 'Winter Discount',
        description: '15% off on winter collection',
        code: 'WINTER15',
        type: 'percentage',
        value: 15,
        startDate: '2023-12-01',
        endDate: '2023-12-31',
        isActive: false,
        productIds: ['D4', 'E5'],
        conditions: {
            productCategories: ['winter', 'clothing'],
        },
    },
];

export default exampleDiscountCodes;
