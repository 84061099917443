import React from 'react';
import styles from './DiscountStatsCard.module.scss';

interface DiscountStatsCardProps {
    icon: React.ReactNode;
    title: string;
    value: string | number;
}

const DiscountStatsCard: React.FC<DiscountStatsCardProps> = ({ icon, title, value }) => {
    return (
        <div className={styles.discount_stats_card}>
            <div className={styles.icon}>
                {icon}
            </div>
            <div className={styles.details}>
                <h3>{title}</h3>
                <p>{value}</p>
            </div>
        </div>
    );
};

export default DiscountStatsCard;
