import React, { useState } from 'react';
import ProductCard from './ProductCard';
import styles from './ProductList.module.scss';
import { ProductType } from '../../../../types/Data/ProductType';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Store/store';

interface ProductListProps {
    selectedProductIds: string[];
    onProductsChange: (ids: string[]) => void;
}

const DiscountCodeProduct: React.FC<ProductListProps> = ({ selectedProductIds, onProductsChange }) => {
    const products = useSelector<RootState>((state) => state.dashboard.products.products) as ProductType[];

    const [searchTerm, setSearchTerm] = useState('');

    const filteredProducts = products.filter(product =>
        product?.name!.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleProductSelect = (id: string) => {
        let newProductsId = selectedProductIds?.includes(id)
            ? selectedProductIds.filter(productId => productId! !== id)
            : [...(selectedProductIds ?? []), id];

        onProductsChange(newProductsId)
    };
    
    return (
        <div className={styles.product_list}>
            <h2>Products</h2>
            <input
                type="text"
                placeholder="Search products..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={styles.search_bar}
            />
            <div className={styles.product_list_container}>
                {filteredProducts.map(product => (
                    <ProductCard
                        key={product._id!}
                        product={product}
                        isSelected={selectedProductIds.includes(product._id!!)}
                        onSelect={handleProductSelect}
                    />
                ))}
            </div>
        </div>
    );
};

export default DiscountCodeProduct;
