import { HeroBannerComponentProps } from './HeroBannerComponentProps';

const defaultHeroBanner: HeroBannerComponentProps = {
  slides: [
    {
      title: 'Slide 1',
      text: 'This is the first slide',
      imageUrl: 'https://images.unsplash.com/photo-1519389950473-47ba0277781c?q=80&w=2340&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      title: 'Slide 2',
      text: 'This is the second slide',
      videoUrl: 'https://videos.pexels.com/video-files/4828605/4828605-uhd_2732_1440_25fps.mp4',
    },
    {
      title: 'Slide 3',
      text: 'This is the third slide',
      imageUrl: 'https://images.unsplash.com/photo-1453928582365-b6ad33cbcf64?q=80&w=2346&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
  ],
  layout: 'left',
};

export default defaultHeroBanner;
