import React, { useState, useEffect } from "react";
import styles from './Orders.module.scss';
import Filters from "./OrderFilters/Filters";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store/store";
import TableInteractive from "../../components/Tableau/TableInteractive/TableInteractive";
import DashboardHeader from "../../components/Header/DashboardHeader/DashboardHeader";
import { OrderType } from "../../types/Data/OrderType";
import { dispatchLoadOrders, fetchOrders } from "../DashboardState/orders/ordersSlice"; // Assuming you have a fetchOrders action
import { useNavigate } from "react-router-dom";
import LoadingIndicatorV1 from "../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1";
import ErrorBannerV1 from "../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1";
import dataOrders from "../DashboardState/orders/exmpleData";
import OrdersList from "./OrdersList/OrdersList";

const OrdersPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const [query, setQuery] = useState({
        search:"",
        status: "",
        paymentStatus: "",
        customerName: "",
        startDate: "",
        endDate: "",
    });

    const [page, setPage] = useState(1); // Separate page state
    
    const orders = useSelector<RootState>((state) => state.dashboard.orders.orders) as Partial<OrderType>;
    const loading = useSelector<RootState, boolean>((state) => state.dashboard.orders.loading);
    const error = useSelector<RootState, string | null>((state) => state.dashboard.orders.error);

    /*
    useEffect(() => {
        dispatch(fetchOrders({ ...query, page }));
    }, [query, dispatch]);
    */

    const handleFilterChange = (key: string, value: any) => {
        setQuery((prevQuery) => ({ ...prevQuery, [key]: value }));
        setPage(1); // Reset page to 1 when query changes
    };

    const handleViewOrder = (id: string | number) => {
        navigate(`./${id}`);
    };

    
    return (
        <div className={styles.dashboard}>
            <DashboardHeader title="All Orders" />
            <Filters 
                query={query}
                onQueryChange={handleFilterChange}
            />
            <div className={styles.orders_array}>
                {/* Handle loading state */}
                {loading && <LoadingIndicatorV1 />}
                
                {/* Handle error state */}
                {error && <ErrorBannerV1 message={typeof error === 'string' ? error : 'An error occurred'} />}
                
                {/* Display product list if not loading and no error */}
                {
                    <OrdersList/>
                }
            </div>
        </div>
    );
}

export default OrdersPage;
