import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartData, ChartOptions } from 'chart.js';
import styles from './ChartCustomerRegionDistribution.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Store/store';
import { CustomerType } from '../../../../../types/Data/CustomerType';

interface OrderSByRegionistributionProps {
}

const ChartCustomerRegionDistribution: React.FC<OrderSByRegionistributionProps> = ({ }) => {
    const customers = useSelector((state:RootState)=>state.dashboard.customers.customers) as CustomerType[]
    
    const getCustomerountByRegion = () => {
        const counts: { [key: string]: number } = {};
        customers.forEach(customer => {
            counts[customer.shippingAddress?.country || 'unkown'] = (counts[customer.shippingAddress?.country || 'unkown'] || 0) + 1;
        });
        return counts;
    };

    const customerCounts = getCustomerountByRegion();
    const regionLabels = Object.keys(customerCounts);
    const regionData = Object.values(customerCounts);

    const data: ChartData<'pie'> = {
        labels: regionLabels,
        datasets: [
            {
                label: 'Order Status',
                data: regionData,
                backgroundColor: [
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                    'rgba(255, 159, 64, 0.6)',
                    'rgba(255, 205, 86, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(255, 205, 86, 1)',
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
                hoverBackgroundColor: [
                    'rgba(75, 192, 192, 0.8)',
                    'rgba(153, 102, 255, 0.8)',
                    'rgba(255, 159, 64, 0.8)',
                    'rgba(255, 205, 86, 0.8)',
                    'rgba(54, 162, 235, 0.8)',
                ],
            },
        ],
    };

    const options: ChartOptions<'pie'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    color: 'white', // Legend text color
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.raw !== null) {
                            label += context.raw as number;
                        }
                        return label;
                    },
                },
            },
        },
    };

    return (
        <div className={styles.container}>
            <h3>Customer By Region Breakdown</h3>
            <div className={styles.chart_container}>
                <Pie data={data} options={options} />
            </div>
        </div>
        
    );
};

export default ChartCustomerRegionDistribution;
