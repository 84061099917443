import { AvailabilityFilterVersions, CategoryFilterVersions, ColorFilterVersions, DefaultFilterVersions, PriceRangeFilterVersions, RatingFilterVersions } from "../Filter/FilterComponentProps";
import { FilterModalComponentProps } from "./FilterModalComponentProps";

export const defaultFilterModalProps: FilterModalComponentProps = {
    isOpen: true,
    onClose: () => null,
    options: [ 
        { label: 'size', options: ['s', 'm', 'l', 'xl'] },  
        { label: 'material', options: [ "gold", "silver"] },
        { label: 'brand', options:[ "samsung", "apple" ] },
    ],
    showCategoryFilter: true,
    categoryFilterVersion: CategoryFilterVersions.V1,
    showColorFilter: true,
    colorFilterVersion: ColorFilterVersions.V1,
    showRatingFilter: true,
    ratingFilterVersion: RatingFilterVersions.V1,
    showAvailabilityFilter: true,
    availabilityFilterVersion: AvailabilityFilterVersions.V1,
    showPriceRangeFilter: true,
    priceRangeFilterVersion: PriceRangeFilterVersions.V1,
    defaultFilterVersion: DefaultFilterVersions.V1
};