import React from 'react'
import styles from '../Component.module.scss';
import ButtonWishListV1 from '../../UIElements/Button/ButtonWishList/ButtonWishListV1/ButtonWishListV1';

const ButtonWishlistComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <ButtonWishListV1/>
        </div>
    </div>
  )
}

export default ButtonWishlistComponentPage