import React from 'react'
import styles from '../Component.module.scss';
import TeamMemberCardV1 from '../../components/TeamMemberCard/TeamMemberCardV1/TeamMemberCardV1';
import TeamMemberCardV2 from '../../components/TeamMemberCard/TeamMemberCardV2/TeamMemberCardV2';
import TeamMemberCardV3 from '../../components/TeamMemberCard/TeamMemberCardV3/TeamMemberCardV3';

const TeamMemberComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <TeamMemberCardV1/>
        </div>
        <div className={styles.element}>
            <TeamMemberCardV2/>
        </div>
        <div className={styles.element}>
            <TeamMemberCardV3/>
        </div>

    </div>
  )
}

export default TeamMemberComponentPage