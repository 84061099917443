import React from 'react'
import styles from '../Component.module.scss';
import FeaturesSectionV1 from '../../UIElements/FeatureSection/FeatureSectionV1/FeatureSectionV1';
import FeaturesSectionV2 from '../../UIElements/FeatureSection/FeatureSectionV2/FeatureSectionV2';
import { defaultFeaturesSectionProps } from '../../UIElements/FeatureSection/featureSectionDefaultProps';

const FeatureSectionComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <FeaturesSectionV1 {...defaultFeaturesSectionProps}/>
        </div>
        <div className={styles.element}>
            <FeaturesSectionV2 {...defaultFeaturesSectionProps}/>
        </div>
    </div>
  )
}

export default FeatureSectionComponentPage