import SortV1 from "./SortV1/SortV1";
import SortV2 from "./SortV2/SortV2";

enum SortVersions {
    V1 = "v1",
    V2 = "v2",
};

const SortMapping:Record<SortVersions, React.FC<any>> = {
    [SortVersions.V1]: SortV1,
    [SortVersions.V2]: SortV2,
};

interface SortComponentProps {
    options?: string[];
    selectedOption?: string;
    onOptionSelect?: (option: string) => void;
}

export {
    SortMapping,
    SortVersions,
}

export type {
    SortComponentProps
}
  