import { ImageType } from "../../types/Data/ImageType";

import ProductImageGalleryV1 from "./ProductImageGalleryV1/ProductImageGalleryV1";
import ProductImageGalleryV2 from "./ProductImageGalleryV2/ProductImageGalleryV2";
import ProductImageGalleryV3 from "./ProductImageGalleryV3/ProductImageGalleryV3";
import ProductImageGalleryV4 from "./ProductImageGalleryV4/ProductImageGalleryV4";

enum ProductImageGalleryVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
    V4 = "v4",
};

const ProductImageGalleryMapping:Record<ProductImageGalleryVersions, React.FC<any>> = {
    [ProductImageGalleryVersions.V1]: ProductImageGalleryV1,
    [ProductImageGalleryVersions.V2]: ProductImageGalleryV2,
    [ProductImageGalleryVersions.V3]: ProductImageGalleryV3,
    [ProductImageGalleryVersions.V4]: ProductImageGalleryV4,

};

interface ProductImageGalleryComponentProps {
    images?: ImageType[]
};

export {
    ProductImageGalleryMapping,
    ProductImageGalleryVersions
};

export type {
    ProductImageGalleryComponentProps
};