import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseElementType } from '../../../types/Data/BaseElementType';
import elementsExample from './elementExample';
import { createElement, deleteElement, getAllElements, updateElement } from '../../../api/element/elementApi';

// Fetch all elements
export const fetchAllElement = createAsyncThunk(
    'elements/fetchAll',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getAllElements();
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to load elements');
        }
    }
);

// Update an element
export const fetchUpdateElement = createAsyncThunk(
    'elements/updateElement',
    async ({ id, element }: { id: string; element: BaseElementType<any> }, { rejectWithValue }) => {
        try {
            const response = await updateElement(id, element);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to update element');
        }
    }
);

// Add a new element
export const fetchCreateElement = createAsyncThunk(
    'elements/addElement',
    async (element: BaseElementType<any>, { rejectWithValue }) => {
        try {
            const response = await createElement(element);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to create element');
        }
    }
);

// Delete an element
export const fetchDeleteElement = createAsyncThunk(
    'elements/deleteElement',
    async (id: string, { rejectWithValue }) => {
        try {
            await deleteElement(id);
            return id; // Returning the ID for easy removal from state
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to delete element');
        }
    }
);

interface ElementState {
    elements: BaseElementType<any>[];
    loading: boolean;
    error: string | null;
}

const initialState: ElementState = {
    elements: [...elementsExample],
    loading: false,
    error: null,
};

const elementsSlice = createSlice({
    name: 'elements',
    initialState,
    reducers: {
        dispatchToggleElementStatus(state, action: PayloadAction<string>) {
            const index = state.elements.findIndex(element => element._id === action.payload);
            if (index !== -1) {
                state.elements[index].isVisible = !state.elements[index].isVisible;
            }
        },
    },
    extraReducers: (builder) => {
        // Handle fetchAllElement
        builder
            .addCase(fetchAllElement.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllElement.fulfilled, (state, action) => {
                state.loading = false;
                state.elements = action.payload;
            })
            .addCase(fetchAllElement.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });

        // Handle fetchAddElement
        builder
            .addCase(fetchCreateElement.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCreateElement.fulfilled, (state, action) => {
                state.loading = false;
                state.elements.push(action.payload);
            })
            .addCase(fetchCreateElement.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });

        // Handle fetchUpdateElement
        builder
            .addCase(fetchUpdateElement.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUpdateElement.fulfilled, (state, action) => {
                state.loading = false;
                const index = state.elements.findIndex(element => element._id === action.payload._id);
                if (index !== -1) {
                    state.elements[index] = action.payload;
                }
            })
            .addCase(fetchUpdateElement.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });

        // Handle fetchDeleteElement
        builder
            .addCase(fetchDeleteElement.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDeleteElement.fulfilled, (state, action) => {
                state.loading = false;
                state.elements = state.elements.filter(element => element._id !== action.payload);
            })
            .addCase(fetchDeleteElement.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const { dispatchToggleElementStatus } = elementsSlice.actions;
export default elementsSlice.reducer;
