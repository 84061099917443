import React from 'react';
import styles from './OrderSummaryV2.module.scss';
import { OrderSummaryComponentProps } from '../OrderSummaryComponentProps';


const OrderSummaryV2: React.FC<OrderSummaryComponentProps> = ({ subtotal = 0, shipping = 0, discount = 0, total  = 0}) => {
    return (
    <div className={styles.card}>
      <h2 className={styles.title}>Your Order</h2>
      <div className={styles.lineItem}>
        <span>Subtotal</span>
        <span>${subtotal.toFixed(2)}</span>
      </div>
      <div className={styles.lineItem}>
        <span>Shipping</span>
        <span>${shipping.toFixed(2)}</span>
      </div>
      <div className={styles.lineItem}>
        <span>Discount</span>
        <span>-${discount.toFixed(2)}</span>
      </div>
      <div className={styles.total}>
        <span>Total</span>
        <span>${total.toFixed(2)}</span>
      </div>
    </div>
  );
};

export default OrderSummaryV2;
