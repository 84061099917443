import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ImageType } from '../../../types/Data/ImageType';
import mockImages from './imagesMockData';


interface ImagesState {
    images: ImageType[];
    loading: boolean;
    error: string | null;
}

const initialState: ImagesState = {
    images: [...mockImages],
    loading: false,
    error: null
};

const imagesSlice = createSlice({
    name: 'images',
    initialState,
    reducers: {
        dispatchAddImages(state, action: PayloadAction<ImageType>) {
            state.images.push(action.payload);
        },
        dispatchUpdateImage(state, action: PayloadAction<ImageType>) {
            const index = state.images.findIndex(order => order._id === action.payload._id);
            if (index !== -1) {
                state.images[index] = action.payload;
            }
        },
        dispatchDeleteImages(state, action: PayloadAction<string>) {
            state.images = state.images.filter(image => image._id !== action.payload);
        },
        dispatchLoadImages(state, action: PayloadAction<ImageType[]>) {
            state.images = action.payload;
        }
    }
});

export const { dispatchAddImages, dispatchUpdateImage, dispatchDeleteImages, dispatchLoadImages } = imagesSlice.actions;
export default imagesSlice.reducer;
