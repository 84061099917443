import React, { useEffect, useState } from 'react';
import DashboardHeader from '../../components/Header/DashboardHeader/DashboardHeader';

import styles from './SubscriberPage.module.scss';
import SubscriberList from './SubscribersList/SubscriberList';
import { SubscriberType } from '../../types/Data/SubscriberType';
import SubscriberForm from './SubscriberEdit/SubscriberForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store/store';
import LoadingIndicatorV1 from '../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import { fetchSubscribers } from '../DashboardState/subscribers/subscribersSlice';

const SubscriberPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const {subscribers, loading, error} = useSelector((state:RootState)=>state.dashboard.subscribers);
  
  const [editingSubscriber, setEditingSubscriber] = useState<SubscriberType | null>(null);

  /*
  useEffect(()=>{
    dispatch(fetchSubscribers({}))
  }, [])
  */

  const handleEdit = (subscriber: SubscriberType) => {
    setEditingSubscriber(subscriber);
  };

  const handleCloseForm = () => {
    setEditingSubscriber(null);
  };

  if (loading) return <LoadingIndicatorV1/>
  if (error) return <ErrorBannerV1 message={error as string}/>

  return (
    <div className={styles.subscriber_page}>
      <DashboardHeader title="Subscribers" />
      <div className={styles.content}>
        <SubscriberList subscribers={subscribers} onEdit={handleEdit} />
        <SubscriberForm initialSubscriber={editingSubscriber} onClose={handleCloseForm} />
      </div>
    </div>
  );
};

export default SubscriberPage;
