import React from 'react';
import { FaEnvelope, FaCalendarAlt, FaCheckCircle, FaTimesCircle, FaTags, FaClock } from 'react-icons/fa';
import styles from './EmailCampaignInfo.module.scss';
import { EmailCampaignStatus, EmailCampaignType } from '../../../../types/Data/EmailCampaignType';

interface EmailCampaignInfoProps {
    campaign: EmailCampaignType;
}

const EmailCampaignInfo: React.FC<EmailCampaignInfoProps> = ({ campaign }) => {
    return (
        <div className={styles.campaignInfoContainer}>
            <h2 className={styles.title}>Campaign Information</h2>
            <div className={styles.infoRow}>
                <FaEnvelope className={styles.icon} />
                <span className={styles.label}>Name:</span>
                <span className={styles.value}>{campaign?.name}</span>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.infoRow}>
                <FaTags className={styles.icon} />
                <span className={styles.label}>Subject:</span>
                <span className={styles.value}>{campaign?.subject}</span>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.infoRow}>
                <FaClock className={styles.icon} />
                <span className={styles.label}>Scheduled Date:</span>
                { campaign?.scheduledDate && <span className={styles.value}>{campaign?.scheduledDate ? new Date(campaign?.scheduledDate).toLocaleDateString() : 'N/A'}</span> }
            </div>
            <div className={styles.divider}></div>
            <div className={styles.infoRow}>
                <FaCheckCircle className={styles.icon} />
                <span className={styles.label}>Sent Date:</span>
                { campaign?.sentDate && <span className={styles.value}>{campaign.sentDate ? new Date(campaign?.sentDate).toLocaleDateString() : 'N/A'}</span> }
            </div>
            <div className={styles.divider}></div>
            <div className={styles.infoRow}>
                {campaign?.status === EmailCampaignStatus.Sent ? (
                    <FaCheckCircle className={`${styles.icon} ${styles.sent}`} />
                ) : (
                    <FaTimesCircle className={`${styles.icon} ${styles.draft}`} />
                )}
                <span className={styles.label}>Status:</span>
                <span className={`${styles.value} ${campaign?.status === EmailCampaignStatus.Sent ? styles.sent : styles.draft}`}>
                    {campaign?.status}
                </span>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.infoRow}>
                <FaTags className={styles.icon} />
                <span className={styles.label}>Segment:</span>
                <span className={styles.value}>{campaign?.segment || 'N/A'}</span>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.infoRow}>
                <FaCalendarAlt className={styles.icon} />
                <span className={styles.label}>Created At:</span>
                { campaign?.createdAt && <span className={styles.value}>{new Date(campaign?.createdAt!).toLocaleDateString()}</span> }
            </div>
            <div className={styles.divider}></div>
            <div className={styles.infoRow}>
                <FaCalendarAlt className={styles.icon} />
                <span className={styles.label}>Updated At:</span>
                { campaign?.scheduledDate && <span className={styles.value}>{new Date(campaign?.updatedAt!).toLocaleDateString()}</span> }
            </div>
        </div>
    );
};

export default EmailCampaignInfo;
