import React, { useEffect, useState } from 'react';
import { IoRemove } from 'react-icons/io5';
import styles from '../../UIElementsMainSettings/UiElementEditor.module.scss';
import { IncentiveComponentProps, IncentiveMapping, IncentiveVersions } from '../IncentiveComponentProps';
import Input4 from '../../../components/Input/InputText/InputV4/InputV4';
import Select4 from '../../../components/Select/Select4/Select4';
import SwitchV1 from '../../../components/Switch/SwitchV1/SwitchV1';
import { BaseElementType, ComponentType } from '../../../types/Data/BaseElementType';
import { defaultIncentiveProps } from '../IncentiveDefaultProps';
import ButtonV3 from '../../../components/Button/ButtonV3/ButtonV3';
import ButtonV1 from '../../../components/Button/ButtonV1/ButtonV1';
import UIElementsMainSettings from '../../UIElementsMainSettings/UIElementsMainSettings';
import ButtonDelete from '../../../components/Button/ButtonDelete/ButtonDelete';

const iconOptions = [
  { label: 'Checkmark', value: 'IoCheckmarkCircleOutline' },
  { label: 'Alert', value: 'IoAlertCircleOutline' },
  { label: 'Information', value: 'IoInformationCircleOutline' },
  { label: 'Close', value: 'IoCloseCircleOutline' },
  { label: 'Heart', value: 'IoHeartOutline' },
  { label: 'Star', value: 'IoStarOutline' },
  { label: 'Thumbs Up', value: 'IoThumbsUpOutline' },
  { label: 'Thumbs Down', value: 'IoThumbsDownOutline' },
  { label: 'Sunny', value: 'IoSunnyOutline' },
  { label: 'Moon', value: 'IoMoonOutline' },
  { label: 'Happy', value: 'IoHappyOutline' },
  { label: 'Sad', value: 'IoSadOutline' },
  { label: 'Camera', value: 'IoCameraOutline' },
  { label: 'Cart', value: 'IoCartOutline' },
  { label: 'Envelope', value: 'FaEnvelope' },
  { label: 'CreditCard', value: 'FaCreditCard' },
  { label: 'FaUser', value: 'FaUser' },
  { label: 'Database', value: 'FaDatabase' },
  { label: 'Design', value: 'FaPaintBrush' },
  { label: 'File', value: 'FaFile' },
  { label: 'Shipping', value: 'FaShippingFast' },
  { label: 'Gift', value: 'FaGift' },
  { label: 'Info', value: 'FaInfoCircle' },
  { label: 'Shield', value: 'FaShieldAlt' },
  { label: 'Recycle', value: 'FaRecycle' },
  { label: 'Lock', value: 'FaLock' },
  { label: 'Check', value: 'FaRegCheckCircle' },
];

interface IncentiveEditorProps {
  element?: BaseElementType<IncentiveComponentProps>;
}

const IncentiveEditor: React.FC<IncentiveEditorProps> = ({ element }) => {
  
  const defaultElement: BaseElementType<IncentiveComponentProps> = {
    type: ComponentType.Incentive,
    version: 'v1',
    page: 'home',
    props: defaultIncentiveProps,
    position: undefined,
    isVisible: true,
  };

  const newItem = {
    title: '',
    description: '',
    icon: '',
  };

  const [currentElement, setCurrentElement] = useState<BaseElementType<IncentiveComponentProps>>(element ? element : defaultElement);

  useEffect(() => {
    if (element) {
      setCurrentElement(element);
    } else {
      setCurrentElement(defaultElement);
    }
  }, [element]);

  const handleAddItem = () => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      props: {
        ...prevElement.props,
        items: [...(prevElement?.props?.items ?? []), newItem],
      },
    }));
  };

  const handleRemoveItem = (itemIndex: number) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      props: {
        ...prevElement.props,
        items: (prevElement?.props?.items ?? []).filter((_, index: number) => index !== itemIndex),
      },
    }));
  };

  const handleItemChange = (itemIndex: number, key: string, value: any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      props: {
        ...prevElement.props,
        items: (prevElement?.props?.items ?? []).map((item, index) =>
          index === itemIndex ? { ...item, [key]: value } : item
        ),
      },
    }));
  };

  const handleElementUpdate = (key: string, value: any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      [key]: value,
    }));
  };

  const handleElementVisibilityUpdate = () => {
    setCurrentElement((prevElement) => ({ ...prevElement, isVisible: !prevElement.isVisible }));
  };

  const handlePropsChange = (key: string, value: any) => {
    setCurrentElement((prevElement) => ({
        ...prevElement,
        props: {
          ...prevElement.props,
          [key]: value
        },
    }));
  };

  const SelectedComponent = IncentiveMapping[currentElement.version as IncentiveVersions] || IncentiveMapping[IncentiveVersions.V1];

  const elementsVersion = Object.keys(IncentiveVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: IncentiveVersions[key as keyof typeof IncentiveVersions],
  }));

  return (
    <div className={styles.container}>
      <div className={styles.componentPreview}>
        <SelectedComponent {...currentElement.props} />
      </div>
      <div className={styles.componentEditor}>
        <UIElementsMainSettings
            element={currentElement}
            elementVersions={elementsVersion}
            onElementUpdate={handleElementUpdate}
            onVisibilitytUpdate={handleElementVisibilityUpdate}
        />

        {/* Items Section */}
        <div className={styles.slides}>
          {currentElement?.props?.items?.map((item, index) => (
            <div key={index} className={styles.slide}>
              <div className={styles.slideHeader}>
                <h4>Item {index + 1}</h4>
                <div className={styles.buttonRemove} onClick={() => handleRemoveItem(index)}>
                  <IoRemove className={styles.removeButton} />
                </div>
              </div>
              <Select4
                label="Icon"
                value={item.icon}
                options={iconOptions}
                onChange={(value: string) => handleItemChange(index, 'icon', value)}
              />
              <Input4
                label="Title"
                value={item.title}
                onChange={(e) => handleItemChange(index, 'title', e.target.value)}
              />
              <Input4
                label="Description"
                value={item.description}
                onChange={(e) => handleItemChange(index, 'description', e.target.value)}
              />
            </div>
          ))}
          <ButtonV3 title="Add Item" onClick={handleAddItem} />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <ButtonV1 label="Save Element" onClick={()=>null} />
        <ButtonDelete/>
      </div>
    </div>
  );
};

export default IncentiveEditor;
