
import React, { useEffect, useState } from 'react';
import styles from './CustomerOrderHistory.module.scss';
import { OrderType } from '../../../../types/Data/OrderType';

interface OrderHistoryProps {
  customerId: string;
}

const CustomerOrderHistory: React.FC<OrderHistoryProps> = ({ customerId }) => {
  const [orders, setOrders] = useState<OrderType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  if (loading) {
    return <div>Loading order history...</div>;
  }

  return (
    <div className={styles.orderHistory}>
      <h2>Order History</h2>
      {orders.length === 0 ? (
        <p>No orders found for this customer.</p>
      ) : (
        <table className={styles.ordersTable}>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Date</th>
              <th>Status</th>
              <th>Total Amount</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {orders.map(order => (
              <tr key={order._id}>
                <td>{order._id}</td>
                <td>{new Date(order.timestamps.orderDate).toLocaleDateString()}</td>
                <td>{order.status}</td>
                <td>${order.orderSummary.totalAmount.toFixed(2)}</td>
                <td>
                  {/* Link to Order Details Page */}
                  <a href={`/admin/orders/${order._id}`}>View Details</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CustomerOrderHistory;
