import React from "react";
import Select4 from "../../../../components/Select/Select4/Select4";
import styles from "./OrderInfo.module.scss"; // Assuming you use CSS Modules for styling
import { OrderType } from "../../../../types/Data/OrderType";

interface OrderInfoProps {
    order: OrderType;
    onOrderStatusChange?: (status: string) => void;
}

const OrderInfo: React.FC<OrderInfoProps> = ({ order, onOrderStatusChange }) => {

    const orderStatus = [
        { label: 'Pending', value: 'pending' },
        { label: 'Confirmed', value: 'confirmed' },
        { label: 'Completed', value: 'completed' },
        { label: 'Shipped', value: 'shipped' },
        { label: 'Cancelled', value: 'cancelled' }
    ];

    const handleOrderStatusChange = (status: string) => {
        if (onOrderStatusChange) {
            onOrderStatusChange(status);
        }
    };

    return (
        <div className={styles.orderInfo}>
            <h2>Order Info</h2>
            <div className={styles.section}>
                <p className={styles.label}><strong>Order ID:</strong> {order._id}</p>
                <p className={styles.label}><strong>Order Date:</strong> {new Date(order.timestamps.orderDate).toLocaleString()}</p>
                <p className={styles.label}><strong>Customer:</strong> {order.customer?.name}</p>
                <p className={styles.label}><strong>Total Amount:</strong> ${order?.orderSummary!.totalAmount.toFixed(2)}</p>
            </div>
            <hr className={styles.separator} />
            <div className={styles.section}>
                <p className={styles.label}><strong>Order Status:</strong> {order.status}</p>
            </div>
            <hr className={styles.separator} />
            <div className={styles.section}>
                <p className={styles.label}><strong>Payment Status:</strong> {order.payment.paymentStatus}</p>
            </div>
            <hr className={styles.separator} />
            <div className={styles.section}>
                <p className={styles.label}><strong>Shipping Status:</strong> {order.shipping.shippingStatus}</p>
            </div>
        </div>
    );
};

export default OrderInfo;
