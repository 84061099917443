import React from 'react';
import styles from './EcommercePrivatePolicy.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store/store';

const EcommercePrivatePolicy: React.FC = () => {
    const privatePolicy = useSelector<RootState>((state) => state.dashboard.privatePolicy.privatePolicy) as string;

    return (
        <div 
            className={styles.privatePolicyPageContainer} 
            dangerouslySetInnerHTML={{ __html: privatePolicy }}
        />
    );
}

export default EcommercePrivatePolicy;
