import React, { useEffect, useState } from 'react';
import styles from './ReturnRequestNotesProps.module.scss';

interface ReturnRequestNotesProps {
    notes?: string | undefined,
    onNotesChange: (adminNotes?: string | undefined) => void;
}

const ReturnRequestNotes: React.FC<ReturnRequestNotesProps> = ({ notes, onNotesChange }) => {

    return (
        <div className={styles.returnRequestNotesForm}>
            <h4>Admin Notes</h4>

            <div className={styles.formGroup}>
                <textarea
                    id="adminNotes"
                    value={notes}
                    onChange={(e) => onNotesChange(e.target.value)}
                    placeholder="Add any notes that will be visible to the customer..."
                    rows={4}
                />
            </div>

        </div>
    );
};

export default ReturnRequestNotes;
