import React from 'react';
import styles from './TestimonialV4.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { TestimonialType } from '../../../types/Data/TestimonialType';

const TestimonialV4: React.FC = () => {
  const testimonials = useSelector<RootState>((state) => state.dashboard.testimonials.testimonials) as TestimonialType[];
  const sizes = ['small', 'medium', 'large'];

  const getRandomSize = () => {
    return sizes[Math.floor(Math.random() * sizes.length)];
  };

  return (
    <div className={styles.testimonialContainer}>
      {testimonials?.map((testimonial, index) => {
        const randomSize = getRandomSize();
        return (
          <div key={index} className={`${styles.testimonialCard} ${styles[randomSize]}`}>
            <div className={styles.authorContainer}>
              <img src={testimonial.avatar} alt={`${testimonial.name}'s avatar`} />
              <h3>{testimonial.name}</h3>
            </div>
            <p>{testimonial.text}</p>
          </div>
        );
      })}
    </div>
  );
};

export default TestimonialV4;
