import React from 'react';
import styles from './ColorFilterV1.module.scss';
import { ColorFilterComponentProps } from '../../FilterComponentProps';


const ColorFilterV1: React.FC<ColorFilterComponentProps> = ({ options =  ['#FF5733', '#33FF57', '#3357FF', '#FF33A6', '#FFD700'], selectedOptions = [], onSelect = () => null }) => {
  return (
    <div className={styles.colorFilterContainer}>
      <h3>Filter by Color</h3>
      <div className={styles.colorSwatches}>
        {options.map((color, index) => (
          <div
            key={index}
            className={`${styles.colorSwatch} ${selectedOptions.includes(color) ? styles.selected : ''}`}
            style={{ backgroundColor: color }}
            onClick={() => onSelect(color)}
          />
        ))}
      </div>
    </div>
  );
};

export default ColorFilterV1;
