import CustomerSupportV1 from "../../UIElements/CustomerSupport/CustomerSupportV1/CustomerSupportV1";
import CustomerSupportV2 from "../../UIElements/CustomerSupport/CustomerSupportV2/CustomerSupportV2";
import CustomerSupportV3 from "../../UIElements/CustomerSupport/CustomerSupportV3/CustomerSupportV3";

enum CustomerSupportVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const CustomerSupportMapping:Record<CustomerSupportVersions, React.FC<any>> = {
    [CustomerSupportVersions.V1]: CustomerSupportV1,
    [CustomerSupportVersions.V2]: CustomerSupportV2,
    [CustomerSupportVersions.V3]: CustomerSupportV3,
};

interface CustomerSupportComponentProps {
    phoneNumber?: string;
    email?: string;
    liveChatUrl?: string;
    workingHours?: string;
    supportText?: string;
};

export {
    CustomerSupportVersions,
    CustomerSupportMapping
};

export type {
    CustomerSupportComponentProps
};