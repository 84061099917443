import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Store/store";

import styles from '../Home/EcommerceHome.module.scss';

import { useParams } from "react-router-dom";
import { ProductType } from "../../types/Data/ProductType";
import renderPageElement from "../renderPageElement";
import { ComponentType } from "../../types/Data/BaseElementType";

const InvalidComponent: React.FC = () => <div>Invalid Component</div>;

const EcommerceProduct: React.FC = () => {
    const productPageElements = useSelector((state:RootState)=> state.ecommerce.ui.elements).filter((el)=>el.page === 'product');
    
    const { productId } = useParams<{productId: string}>();

    const storeProduct = useSelector((state:RootState)=>state.dashboard.products.products.find((el)=>el._id === productId)) as ProductType;
    const [product, setProduct] = useState<ProductType>(storeProduct || {})
 
    const getComponentProps = (
        componentType: ComponentType,
      ) => {
        switch (componentType) {
          case ComponentType.ProductDescription:
            return {
              product:product,
            };
          case ComponentType.ProductName:
            return {
                product:product,
             };
          case ComponentType.ProductImageGallery:
            return {
                images: product.images,
            };
          case ComponentType.VariantSelector:
            return {
                variants: product.variants,
            };
          default:
            return {};
        }
      };
    return (
        <div className={styles.page}>
            <div className={styles.grid}>
                {productPageElements.map((element, index) => {
                    const Component = renderPageElement(element);
                    const { row, column, span, order, spanRows } = element.position || {};
                    const props = {...getComponentProps(element.type), ...element.props}
                    return (
                        <div
                            key={index}  // Add unique key to prevent warnings
                            className={styles.gridItem}  // Add a specific class for the grid items
                            style={{
                                gridColumn: column ? `${column} / span ${span || 1}` : '1 / span 12',  // Set column start and span
                                gridRow: row && spanRows ? `${row} / span ${spanRows}` : row || 'auto',  // Set row start and span
                                order: order || 'initial',  // Set the order in case it's specified
                            }}
                        >
                            <Component {...props} />
                        </div>
                    );
                })}
            </div>
        </div>

    );
}

export default EcommerceProduct;
