import React from 'react'
import styles from '../Component.module.scss';
import SliderV1 from '../../components/Slider/SliderV1/SliderV1';

const SliderComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <SliderV1/>
        </div>
    </div>
  )
}

export default SliderComponentPage