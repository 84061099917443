import { AddressType } from "./AdressType";
import { CustomerType } from "./CustomerType";

export enum OrderStatus {
    PENDING = "Pending",
    PAID = "Paid",
    PROCESSING = "Processing",
    SHIPPED = "Shipped",
    DELIVERED = "Delivered",
    RETURNED = "Returned",
    CANCELLED = "Cancelled"
}

export enum PaymentStatus {
    PENDING = "Pending",
    COMPLETED = "Completed",
    FAILED = "Failed",
};

interface OrderItemType {
    productId: string; // ObjectId reference to Product
    name: string;
    quantity: number;
    unitPrice: number;
    totalPrice: number;
    imageUrl?: string;
    description?: string;
    size?: string;
    color?: string;
    variant?: string;
    additionalNotes?: string;
    discount?: number; // New: Discount applied to this specific item
}

interface OrderSummary {
    subtotal?: number;
    shippingCost?: number;
    discounts?: number;
    tax?: number;
    totalAmount: number;
}

interface Payment {
    paymentMethod: string;
    paymentProvider?: string; // e.g., 'Stripe', 'PayPal'
    paymentStatus: PaymentStatus;
    transactionId?: string;
    paymentFees?: number; // New: Fees associated with the payment method
}

interface Shipping {
    shippingMethod: string;
    trackingNumber: string;
    shippingCarrier: string;
    estimatedDeliveryDate: Date;
    shippingStatus: OrderStatus;
    fulfilledBy?: string; // New: Who fulfilled the order (warehouse/store personnel)
}

interface Timestamps {
    orderDate: Date;
    paymentDate?: Date | null;
    shippingDate?: Date | null;
    deliveryDate?: Date | null;
}

interface StatusHistory {
    status: OrderStatus;
    changedAt: Date;
}

interface Notes {
    customerNotes?: string;
    internalNotes?: string;
}

interface Coupon {
    couponCode: string;
    discountAmount: number;
}

interface OrderType {
    _id: string;
    customer: Partial<CustomerType>; // Now stores a reference to the customer
    shippingAddress: AddressType; // Shipping address for the specific order
    billingAddress: AddressType;  // Billing address for the specific order
    items: OrderItemType[];
    orderSummary: OrderSummary;
    payment: Payment;
    shipping: Shipping;
    status: OrderStatus;
    timestamps: Timestamps;
    statusHistory?: StatusHistory[]; // New: Track order status changes
    notes?: Notes;
    coupons?: Coupon | null;
    comments?: string | null;
    createdAt?: Date;
    updatedAt?: Date;
}

export type {
    OrderType,
    OrderItemType,
    OrderSummary,
    Payment,
    Shipping,
    Timestamps,
    Notes,
    Coupon,
    StatusHistory
};
