import React, { FC } from 'react';
import { EmailCampaignStatus, EmailCampaignType } from '../../../../types/Data/EmailCampaignType';
import Input4 from '../../../../components/Input/InputText/InputV4/InputV4';
import InputCalendar from '../../../../components/Input/InputCalendar/InputCalendarV1/InputCalendarV1';
import Select4 from '../../../../components/Select/Select4/Select4';
import styles from './CampaignInfoForm.module.scss';

interface CampaignInfoFormProps {
  campaign: Partial<EmailCampaignType>;  // Allow partial properties for campaign
  onCampaignInfoChange: (key: string, value: any) => void;  // Corrected parameter types
}

const CampaignInfoForm: FC<CampaignInfoFormProps> = ({ campaign = {}, onCampaignInfoChange = () => null }) => {

  const statusOptions = Object.values(EmailCampaignStatus).map((status) => ({ label: status, value: status }));

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    onCampaignInfoChange(name, value);
  };

  const handleDateChange = (date: Date) => {
    onCampaignInfoChange('scheduledDate', date.toISOString().split('T')[0]);
  };

  return (
    <div className={styles.content}>
      <h2>Campaign Info</h2>
      <Input4
        label="Name"
        name="name"
        type="text"
        value={campaign.name || ''}
        onChange={handleInputChange}
      />
      <Input4
        label="Subject"
        name="subject"
        type="text"
        value={campaign.subject || ''}
        onChange={handleInputChange}
      />
      <InputCalendar
        label="Scheduled Date"
        name="scheduledDate"
        value={ campaign?.scheduledDate ? new Date(campaign?.scheduledDate).toDateString() : ''}
        onDateChange={handleDateChange}
      />
      <Select4
        label="Status"
        options={statusOptions}
        value={campaign.status || EmailCampaignStatus.Draft}
        onChange={(value) => onCampaignInfoChange('status', value)}
      />
    </div>
  );
};

export default CampaignInfoForm;
