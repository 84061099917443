// customerApi.ts
import { AxiosResponse } from 'axios';
import { ApiResponse } from '../apiType';
import api from '../config';
import { CustomerType } from '../../types/Data/CustomerType';
import { OrderType } from '../../types/Data/OrderType';
import { SupportTicketType } from '../../types/Data/SupportTicketType';
import { WishlistType } from '../../types/Data/WishlistType';

// Define interfaces for the customer data
interface Customer {
  _id: string;
  name: string;
  email: string;
  // Add other customer fields as needed
}

interface CreateCustomerData {
  name: string;
  email: string;
  // Add other fields required for creating a customer
}

interface UpdateCustomerData {
  name?: string;
  email?: string;
  // Add other fields that can be updated
}

interface PaginationParams {
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  [key: string]: any; // For additional query parameters
}


// API functions
  // Create a new customer
  export const createCustomer = async (data: Partial<CustomerType>): Promise<ApiResponse<Customer>> => {
    try {
      const response: AxiosResponse<ApiResponse<Customer>> = await api.post('/customers', data);
      return response.data;
    } catch (error) {
      // Handle error as needed
      throw error;
    }
  };

  // Get a customer by ID
  export const getCustomerById = async (customerId: string): Promise<ApiResponse<Customer>> => {
    try {
      const response: AxiosResponse<ApiResponse<Customer>> = await api.get(`/customers/${customerId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Update a customer by ID
   export const updateCustomer = async (customerId: string, data: UpdateCustomerData): Promise<ApiResponse<CustomerType>> => {
    try {
      const response: AxiosResponse<ApiResponse<CustomerType>> = await api.put(`/customers/${customerId}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Delete a customer by ID
  export const deleteCustomer = async (customerId: string): Promise<ApiResponse<null>> => {
    try {
      const response: AxiosResponse<ApiResponse<null>> = await api.delete(`/customers/${customerId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Get all customers with optional filters, pagination, and sorting
  export const getAllCustomers = async (params: PaginationParams): Promise<ApiResponse<{customers: CustomerType[], total:number, pages:number}>> => {
    try {
      const response: AxiosResponse<ApiResponse<{customers: CustomerType[], total:number, pages:number}>> = await api.get('/customers', {
        params,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Get all customers with aggregated stats
  export const getAllCustomersWithStats = async (params: PaginationParams): Promise<ApiResponse<{customers: CustomerType[], total?:number, pages?:number}>> => {
    try {
      const response: AxiosResponse<ApiResponse<{customers: CustomerType[], total?:number, pages?:number}>> = await api.get('/customers/stats', {
        params,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Search customers
  export const searchCustomers = async (searchTerm: string, page: number = 1, limit: number = 10): Promise<ApiResponse<{customers: CustomerType[], total?:number, pages?:number}>> => {
    try {
      const response: AxiosResponse<ApiResponse<{customers: CustomerType[], total?:number, pages?:number}>> = await api.get('/customers/search', {
        params: {
          searchTerm,
          page,
          limit,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Get orders for a specific customer
  export const getCustomerOrders = async (customerId: string): Promise<ApiResponse<OrderType[]>> => {
    try {
      const response: AxiosResponse<ApiResponse<OrderType[]>> = await api.get(`/customers/${customerId}/orders`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Count the number of orders for a specific customer
  export const countCustomerOrders = async (customerId: string): Promise<ApiResponse<number>> => {
    try {
      const response: AxiosResponse<ApiResponse<number>> = await api.get(`/customers/${customerId}/orders/count`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Get customer's wishlist
  export const getCustomerWishlist = async (customerId: string): Promise<ApiResponse<WishlistType>> => {
    try {
      const response: AxiosResponse<ApiResponse<WishlistType>> = await api.get(`/customers/${customerId}/wishlist`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Add item to customer's wishlist
  export const addItemToWishlist = async (customerId: string, productId: string): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await api.post(`/customers/${customerId}/wishlist`, {
        productId,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Remove item from customer's wishlist
  export const removeItemFromWishlist = async (customerId: string, productId: string): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await api.delete(`/customers/${customerId}/wishlist/item`, {
        data: {
          productId,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Delete customer's wishlist
  export const deleteCustomerWishlist = async (customerId: string): Promise<ApiResponse<null>> => {
    try {
      const response: AxiosResponse<ApiResponse<null>> = await api.delete(`/customers/${customerId}/wishlist`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Get customer's support tickets
  export const getCustomerSupportTickets = async (customerId: string): Promise<ApiResponse<SupportTicketType[]>> => {
    try {
      const response: AxiosResponse<ApiResponse<SupportTicketType[]>> = await api.get(`/customers/${customerId}/support-tickets`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Send email to customer
  export const sendCustomerEmail = async (customerId: string, subject: string, body: string): Promise<{ message: string }> => {
    try {
      const response: AxiosResponse<{ message: string }> = await api.post(`/customers/${customerId}/send-email`, {
        subject,
        body,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

