import React from 'react';
import styles from './SalesAnalyticsPage.module.scss';
import ChartCancellationOverTime from '../Cancellation/ChartCancellationOverTime/ChartCancellationOverTime';
import ChartCancellationMainReasons from '../Cancellation/ChartCancellationMainReasons/ChartCancellationMainReasons';
import ChartCancellationByItem from '../Cancellation/ChartCancellationByProduct/ChartCancellationByItem';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';
import ChartOrderStatusDistribution from './ChartOrderStatusBreakdown/ChartOrderStatusBreakdown';
import ChartSalesEvolutionOverTime from './ChartSalesEvolutionOverTime/ChartSalesEvolutionOverTime';
import ChartOrderEvolutionOverTime from './ChartOrderEvolutionOverTime/ChartOrderEvolutionOverTime';
import ChartPaymentMethodBreakdown from './ChartPaymentMethodBreakdown/ChartPaymentMethodBreakdown';
import ChartOrderByRegionBreakdown from './ChartOrderByRegionBreakdown/ChartOrderByRegionBreakdown';
import ChartTopRevenueByProduct from './ChartTopRevenueByProduct/ChartTopRevenueByProduct';
import ChartTopProductsSales from './ChartTopProductSales/ChartTopProductSales';

const SalesAnalyticsPage: React.FC = () => {
    return (
        <div className={styles.analyticsPage}>
            <DashboardHeader title="Sales & Analytics Report" />
            <div className={styles.gridContainer}>
                {/* Sales Evolution */}
                <div className={styles.card}>
                    <ChartSalesEvolutionOverTime />
                </div>

                <div className={styles.card}>
                    <ChartOrderEvolutionOverTime />
                </div>

                {/* Order & Payment Breakdown */}
                <div className={styles.card}>
                    <ChartOrderStatusDistribution />
                </div>

                <div className={styles.card}>
                    <ChartPaymentMethodBreakdown />
                </div>

                <div className={styles.card}>
                    <ChartOrderByRegionBreakdown />
                </div>

                <div className={styles.card}>
                    <ChartTopRevenueByProduct />
                </div>

                <div className={styles.card}>
                    <ChartTopProductsSales />
                </div>

                {/* Cancellations */}
                <div className={styles.card}>
                    <ChartCancellationOverTime />
                </div>

                <div className={styles.card}>
                    <ChartCancellationMainReasons />
                </div>

                <div className={styles.card}>
                    <ChartCancellationByItem />
                </div>
            </div>
        </div>
    );
};

export default SalesAnalyticsPage;
