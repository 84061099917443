import React from 'react';
import styles from './OrderSummary.module.scss';
import { OrderType } from '../../../../types/Data/OrderType';

interface OrderSummaryProps {
    summary: OrderType['orderSummary']
}

const OrderSummary: React.FC<OrderSummaryProps> = ({ summary }) => {
    const { subtotal, shippingCost, discounts, tax, totalAmount } = summary;

    return (
        <div className={styles.orderSummary}>
            <h2>Order Summary</h2>
            <div className={styles.summaryRow}>
                <strong>Subtotal:</strong>
                <p>${subtotal!.toFixed(2)}</p>
            </div>
            <div className={styles.summaryRow}>
                <strong>Shipping Cost:</strong>
                <p>${shippingCost!.toFixed(2)}</p>
            </div>
            <div className={styles.summaryRow}>
                <strong>Discounts:</strong>
                <p>-${discounts!.toFixed(2)}</p>
            </div>
            <div className={styles.summaryRow}>
                <strong>Tax:</strong>
                <p>${tax!.toFixed(2)}</p>
            </div>
            <hr className={styles.separator} />
            <div className={`${styles.summaryRow} ${styles.totalRow}`}>
                <p>Total:</p>
                <p>${totalAmount.toFixed(2)}</p>
            </div>
        </div>
    );
};

export default OrderSummary;
