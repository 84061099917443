import React from 'react';
import styles from './CustomerSupportV2.module.scss';
import { FaPhone, FaEnvelopeOpenText, FaCommentDots } from 'react-icons/fa';
import { defaultCustomerSupportProps } from '../customerSuppoerDefaultProps';
import { CustomerSupportComponentProps } from '../CustomerSupportComponentProps';

const CustomerSupportV2: React.FC<CustomerSupportComponentProps> = ({
    phoneNumber = defaultCustomerSupportProps.phoneNumber,
    email = defaultCustomerSupportProps.email,
    liveChatUrl = defaultCustomerSupportProps.liveChatUrl,
    workingHours = defaultCustomerSupportProps.workingHours,
    supportText = defaultCustomerSupportProps.supportText,
}) => {
  return (
    <div className={styles.customerSupport}>
      <h2>Support Center</h2>
      <p>{supportText}</p>
      <div className={styles.contactMethods}>
        <div className={styles.method}>
          <FaPhone className={styles.icon} />
          <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        </div>
        <div className={styles.method}>
          <FaEnvelopeOpenText className={styles.icon} />
          <a href={`mailto:${email}`}>{email}</a>
        </div>
        {liveChatUrl && (
          <div className={styles.method}>
            <FaCommentDots className={styles.icon} />
            <a href={liveChatUrl} target="_blank" rel="noopener noreferrer">
              Chat Now
            </a>
          </div>
        )}
      </div>
      <p className={styles.workingHours}>Available: {workingHours}</p>
    </div>
  );
};

export default CustomerSupportV2;
