import TrustSignalsV1 from "./TrustSignalsV1/TrustSignalsV1";
import TrustSignalsV2 from "./TrustSignalsV2/TrustSignalsV2";
import TrustSignalsV3 from "./TrustSignalsV3/TrustSignalsV3";

enum TrustSignalsVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const TrustSignalsMapping:Record<TrustSignalsVersions, React.FC<any>> = {
    [TrustSignalsVersions.V1]: TrustSignalsV1,
    [TrustSignalsVersions.V2]: TrustSignalsV2,
    [TrustSignalsVersions.V3]: TrustSignalsV3,
};

interface TrustSignalsComponentProps {
    securePaymentText?: string;
    guaranteeText?: string;
    satisfactionText?: string;
    customerSupportText?:string;
    paymentMethods?:string[];
    qualityGuaranteeText?:string;
    secureCheckoutText?:string;
};

export {
    TrustSignalsVersions,
    TrustSignalsMapping
};

export type {
    TrustSignalsComponentProps
};