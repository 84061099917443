import { LogoCloudComponentProps } from "./LogoCloudComponentProps";

export const logoCloudDefaultProps: LogoCloudComponentProps = {
    title: 'Trusted by leading tech innovators',
    logos: [
        "https://upload.wikimedia.org/wikipedia/commons/4/4f/Logo_2013_Google.png",  // Google
        "https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg",       // Amazon
        "https://upload.wikimedia.org/wikipedia/commons/8/8e/Apple_Logo.png",        // Apple
        "https://upload.wikimedia.org/wikipedia/commons/1/18/Logitech_logo.svg",     // Logitech
        "https://upload.wikimedia.org/wikipedia/commons/2/2f/Microsoft_logo.svg",    // Microsoft
    ]
};