import React from 'react';
import styles from './CustomerOrderDetailPage.module.scss';
import CustomerOrderDetailV1 from '../../../UIElements/CustomerOrderDetail/CustomerOrderDetailV1/CustomerOrderDetailV1';
import CustomerProductReviewV1 from '../../../UIElements/CustomerProductReview/CustomerProductReviewV1/CustomerProductReviewV1';
import CustomerOrderReturnRequestV1 from '../../../UIElements/CustomerOrderReturnRequest/CustomerOrderReturnRequestV1/CustomerOrderReturnRequestV1';
import CustomerOrderTrackingV1 from '../../../UIElements/CustomerOrderTracking/CustomerOrderTrackingV1/CustomerOrderTrackingV1';

const CustomerOrderDetailPage: React.FC = () => {
  return (
    <div className={styles.page}>
      <CustomerOrderDetailV1/>
      <CustomerOrderTrackingV1/>
      <CustomerProductReviewV1/>
      <CustomerOrderReturnRequestV1/>
    </div>
  );
};

export default CustomerOrderDetailPage;
