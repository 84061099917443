import React from 'react';
import styles from './OrderItemCard.module.scss';
import { OrderItemType } from '../../../../../types/Data/OrderType';
import { IoTrash } from 'react-icons/io5';

interface OrderItemCardProps {
    item: OrderItemType;
    onItemChange: <K extends keyof OrderItemType>(key: K, value: OrderItemType[K]) => void;
    onRemove: () => void;
}

const OrderItemCard: React.FC<OrderItemCardProps> = ({ item, onItemChange, onRemove }) => {
    return (
        <div className={styles.orderItemCard}>
            <div className={styles.itemContent}>
                <div className={styles.imageWrapper}>
                    <img src={item.imageUrl} alt={item.name} className={styles.itemImage} />
                </div>

                <div className={styles.detailsWrapper}>
                    <h4 className={styles.itemName}>{item.name}</h4>
                    <div className={styles.optionsWrapper}>
                        <div className={styles.dropdown}>
                            <label>Size:</label>
                            <select
                                value={item.size}
                                onChange={(e) => onItemChange('size', e.target.value)}
                            >
                                <option value="S">S</option>
                                <option value="M">M</option>
                                <option value="L">L</option>
                                <option value="XL">XL</option>
                            </select>
                        </div>

                        <div className={styles.dropdown}>
                            <label>Variant:</label>
                            <select
                                value={item.variant}
                                onChange={(e) => onItemChange('variant', e.target.value)}
                            >
                                <option value="Red">Red</option>
                                <option value="Blue">Blue</option>
                                <option value="Green">Green</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.itemActions}>
                {/* Quantity with floating label */}
                <div className={styles.quantityWrapper}>
                    <input
                        type="number"
                        id="quantity"
                        className={styles.inputField}
                        value={item.quantity}
                        min="1"
                        placeholder=" "
                        onChange={(e) => onItemChange('quantity', Number(e.target.value))}
                    />
                    <label htmlFor="quantity" className={styles.label}>Quantity</label>
                </div>

                {/* Unit Price with floating label */}
                <div className={styles.unitPriceWrapper}>
                    <input
                        type="number"
                        id="unitPrice"
                        className={styles.inputField}
                        value={item.unitPrice}
                        step="0.01"
                        placeholder=" "
                        onChange={(e) => onItemChange('unitPrice', Number(e.target.value))}
                    />
                    <label htmlFor="unitPrice" className={styles.label}>Unit Price</label>
                </div>

                <p className={styles.totalPrice}>Total: ${item.totalPrice.toFixed(2)}</p>

                <button
                    onClick={onRemove}
                    className={styles.removeItemButton}
                ><IoTrash/></button>
            </div>
        </div>
    );
};

export default OrderItemCard;
