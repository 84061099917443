import React from 'react';
import styles from './ProductPriceV1.module.scss';
import { ProductPriceComponentProps } from '../ProductPricingComponentProps';

const ProductPriceV1: React.FC<ProductPriceComponentProps> = ({
  price = 20.00,
  promoPrice,
  currency = '$',
  showPromoBadge = true,
  promoBadgeText = 'Promo',
  textColor = '#333',
  promoColor = '#ff4d4f',
  fontSize = '1.5em',
}) => {
  const finalPrice = promoPrice || price;

  return (
    <div className={styles.productPriceContainer}>
      <div className={styles.priceInfo}>
        {promoPrice && showPromoBadge && (
          <div className={styles.promoBadge} style={{ backgroundColor: promoColor }}>
            {promoBadgeText}
          </div>
        )}
        <div className={styles.price} style={{ color: promoPrice ? promoColor : textColor, fontSize }}>
          {currency}{finalPrice.toFixed(2)}
        </div>
        {promoPrice && (
          <div className={styles.originalPrice} style={{ fontSize }}>
            {currency}{price.toFixed(2)}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductPriceV1;
