import { ShippingMethodType } from "../../../types/Data/ShippingSettingsType";

const exampleDataShippingMethod: ShippingMethodType[] = [
    {
        name: 'UPS Standard',
        rate: 10.00,
        isEnabled: true,
        averageDeliveryDays: 5,
        freeShippingThreshold: 100,
        international: false,
        maxWeight: 20,
        insuranceAvailable: true,
        deliveryOptions: ['Standard'],
        trackingUrl: 'https://www.ups.com/track?loc=en_US&tracknum=',
        description: 'UPS Standard shipping with tracking available.',
        logoUrl: '/shipping-method/ups.png',
        dimensionsLimit: {
          length: 100,
          width: 50,
          height: 50,
        },
      },
      {
        name: 'FedEx Express',
        rate: 20.00,
        isEnabled: true,
        averageDeliveryDays: 2,
        freeShippingThreshold: 200,
        international: true,
        maxWeight: 10,
        insuranceAvailable: true,
        deliveryOptions: ['Express'],
        trackingUrl: 'https://www.fedex.com/apps/fedextrack/?tracknumbers=',
        description: 'FedEx Express shipping with tracking available.',
        logoUrl: '/shipping-method/fedex.png',
        dimensionsLimit: {
          length: 80,
          width: 40,
          height: 40,
        },
      },
      {
        name: 'DHL International',
        rate: 30.00,
        isEnabled: true,
        averageDeliveryDays: 7,
        freeShippingThreshold: 300,
        international: true,
        maxWeight: 15,
        insuranceAvailable: true,
        deliveryOptions: ['Standard', 'Express'],
        trackingUrl: 'https://www.dhl.com/en/express/tracking.html?AWB=',
        description: 'DHL International shipping with tracking available.',
        logoUrl: '/shipping-method/dhl.png',
        dimensionsLimit: {
          length: 120,
          width: 60,
          height: 60,
        },
    }
];

export default exampleDataShippingMethod;
  