import React, { ReactNode, useState } from 'react';
import styles from './OrderNotes.module.scss';

interface Notes {
    customerNotes?: string;
    internalNotes?: string;
}

interface NotesDisplayProps {
    notes: Notes;
    allowAdminEdit?: boolean; // Optional: If true, allows the admin to add internal notes
    onUpdateNotes?: (updatedNotes: Notes) => void; // Callback to save updated notes
    children?: ReactNode;
}

const OrderNotes: React.FC<NotesDisplayProps> = ({ notes, allowAdminEdit = false, onUpdateNotes, children }) => {
    const [adminNotes, setAdminNotes] = useState(notes.internalNotes || '');

    const handleAdminNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAdminNotes(e.target.value);
    };

    const handleSaveNotes = () => {
        if (onUpdateNotes) {
            onUpdateNotes({ ...notes, internalNotes: adminNotes });
        }
    };

    return (
        <div className={styles.notesContainer}>
            <div className={styles.notesSection}>
                <h3>Customer Notes</h3>
                <p>{notes.customerNotes || 'No customer notes available.'}</p>
            </div>

            <div className={styles.notesSection}>
                <h3>Internal Notes</h3>
                {allowAdminEdit ? (
                    <>
                        <textarea
                            value={adminNotes}
                            onChange={handleAdminNotesChange}
                            placeholder="Add internal notes here..."
                            className={styles.notesTextarea}
                        />
                        { children && children }
                    </>
                ) : (
                    <p>{notes.internalNotes || 'No internal notes available.'}</p>
                )}
            </div>
        </div>
    );
};

export default OrderNotes;
