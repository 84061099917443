import React from 'react';
import styles from './CustomerFeedbackList.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { CustomerFeedbackType } from '../../../types/Data/feedback';
import { useNavigate } from 'react-router-dom';

interface CustomerFeedbackListProps {
}

const CustomerFeedbackList: React.FC<CustomerFeedbackListProps> = ({  }) => {
    const navigate = useNavigate();
    const feedbacks = useSelector((state: RootState) => state.dashboard.customerFeedback.customerFeedbacks) as CustomerFeedbackType[];

    const handleFeedbackDetailView = (id: string) => {
        navigate(`./${id}`)
    };

    return (
        <div className={styles.customerFeedbackList}>
            <table>
                <thead>
                    <tr>
                        <th>Customer Name</th>
                        <th>Email</th>
                        <th>Message</th>
                        <th>Date</th>
                        <th>Rating</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {feedbacks.map(feedback => (
                        <tr key={feedback.id} onClick={() => handleFeedbackDetailView(feedback.id)}>
                            <td>{feedback.customer_name}</td>
                            <td>{feedback.email}</td>
                            <td>{feedback.message}</td>
                            <td>{new Date(feedback.date).toLocaleDateString()}</td>
                            <td>{feedback.rating} / 5</td>
                            <td className={`feedback-status ${feedback.status.toLowerCase()}`}>{feedback.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CustomerFeedbackList;
