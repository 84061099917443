import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Categories.module.scss';
import CategoryCard from './CategoryCard/CategoryCard';
import DashboardHeader from '../../components/Header/DashboardHeader/DashboardHeader';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../Store/store';
import { dispatchDeleteCategory, dispatchLoadCategories } from '../DashboardState/categories/categoriesSlice';
import { CategoryType } from '../../types/Data/CategoryType';
import Button1 from '../../components/Button/ButtonV1/ButtonV1';
import exampleCategories from '../DashboardState/categories/exampleCategories';
import { deleteCategory } from '../../api/category/categoryApi';
import { handleApiError } from '../../api/apiError';

const CategoriesPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const categories = useSelector<RootState, CategoryType[]>((state) => state.dashboard.categories.categories);
  const navigate = useNavigate();
  
  const [, setIsModalOpen] = useState<boolean>(false);
  const [categoryToDelete, setCategoryToDelete] = useState<string | null>(null);
  
  const handleAddCategory = () => {
    navigate('./edit')
  };

  const handleEditCategory = (id: string) => {
    navigate(`./edit/categoryId=${id}`)

  };

  const handleDeleteCategory = (id: string) => {
    setCategoryToDelete(id);
    setIsModalOpen(true); // Open the modal to select a new parent category
};

  const handleViewCategory = (id: string) => {
    navigate(`./edit/?categoryId=${id}`)
  };

  // Handle the new parent category selection
  const handleSelectNewParent = async (newParentId: string | null) => {
    if (categoryToDelete) {
      /*try {
        const response = await deleteCategory(categoryToDelete)
      } catch(error:any) {
        console.log(error)
      }*/
    }
    setIsModalOpen(false); // Close the modal after selection
};

 //dispatch(dispatchLoadCategories(exampleCategories))

  return (
    <div className={styles.categoriesPage}>
      <DashboardHeader title="Product Categories"/>
      <Button1 label='Add Category' style={{marginTop: 20}} onClick={()=>handleAddCategory()}/>
      <div className={styles.categoryList}>
        {categories.map((category) => (
          <CategoryCard
            key={category._id}
            category={category}
            onEdit={handleEditCategory}
            onDelete={handleDeleteCategory}
            onView={handleViewCategory}
          />
        ))}
      </div>

    </div>
  );
};

export default CategoriesPage;
