import { TermOfServiceType } from "../../../types/Data/TermOfServiceType";

const exampleTermOfService: TermOfServiceType = {
    content: `
    <div>
        <h1>Terms of Service</h1>
        <p>Welcome to <strong>Your E-commerce Website</strong>. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions of use. Please review these terms carefully. If you do not agree with these terms, you should not use our site.</p>

        <h2>1. Acceptance of Terms</h2>
        <p>By using our website, you agree to be legally bound by these terms, which shall take effect immediately on your first use of our site. If you do not agree to be legally bound by all the following terms, please do not access and/or use <strong>Your E-commerce Website</strong>.</p>

        <h2>2. Changes to Terms</h2>
        <p><strong>Your E-commerce Website</strong> reserves the right to change, modify, add, or remove portions of these terms at any time. Please check these terms periodically for changes. Your continued use of the site following the posting of changes to these terms means you accept those changes.</p>

        <h2>3. Use of the Site</h2>
        <p>You agree to use the site only for lawful purposes. You must not use our site in any way that breaches any applicable local, national, or international law or regulation.</p>

        <h2>4. Account Registration</h2>
        <p>To access certain services on our website, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information as needed.</p>

        <h2>5. Orders and Payment</h2>
        <p>All orders placed through our site are subject to acceptance and availability. We reserve the right to refuse or cancel orders at our discretion. Payment must be made at the time of placing an order, and we accept various payment methods as specified on our site.</p>

        <h2>6. Returns and Refunds</h2>
        <p>Please review our <a href="/returns">Returns and Refunds Policy</a> for information on the conditions and procedures for returning products.</p>

        <h2>7. Limitation of Liability</h2>
        <p>To the fullest extent permitted by law, <strong>Your E-commerce Website</strong> excludes all liability for any loss or damage arising out of or in connection with the use of this website.</p>

        <h2>8. Intellectual Property</h2>
        <p>All content on this site, including text, graphics, logos, images, and software, is the property of <strong>Your E-commerce Website</strong> and is protected by copyright laws. You may not reproduce, distribute, or create derivative works from our content without our written permission.</p>

        <h2>9. Privacy Policy</h2>
        <p>Your use of our website is also governed by our <a href="/privacy-policy">Privacy Policy</a>, which you can find on our website. Please read our Privacy Policy to understand how we collect and use information about you.</p>

        <h2>10. Governing Law</h2>
        <p>These terms shall be governed by and construed in accordance with the laws of [Your Country/State]. Any disputes arising from these terms shall be subject to the exclusive jurisdiction of the courts in [Your Country/State].</p>

        <h2>11. Contact Us</h2>
        <p>If you have any questions about these Terms of Service, please contact us at <a href="mailto:support@yourecommercewebsite.com">support@yourecommercewebsite.com</a>.</p>

        <p><em>Last Updated: [Insert Date]</em></p>
    </div>
`
};
export { exampleTermOfService}