import NewsletterSignupV1 from "./NewsLetterSignupV1/NewsletterSignupV1";
import NewsletterSignupV2 from "./NewsLetterSignupV2/NewsLetterSignupV2";
import NewsletterSignupV3 from "./NewsLetterSignupV3/NewsLetterSignupV3";

enum NewsLetterSignupVersions {
    V1 = 'v1',
    V2 = 'v2',
    V3 = 'v3',
}

const NewsLetterSignupMapping: Record<NewsLetterSignupVersions, React.FC<any>> = {
    [NewsLetterSignupVersions.V1]: NewsletterSignupV1,
    [NewsLetterSignupVersions.V2]: NewsletterSignupV2,
    [NewsLetterSignupVersions.V3]: NewsletterSignupV3,
}

interface NewsLetterSignupComponentProps {

}

export {
    NewsLetterSignupMapping,
    NewsLetterSignupVersions,
}

export type {
    NewsLetterSignupComponentProps
}