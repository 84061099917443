import React from 'react';
import styles from './BlogAnalyticsSection.module.scss';
import { IoDocumentText, IoEye, IoChatbubbles, IoCalendar } from 'react-icons/io5';
import { BlogPostType } from '../../../types/Data/BlogPostType';

interface BlogAnalyticsProps {
  totalBlogs: number;
  mostRecentPost: Partial<BlogPostType>;
  mostViewedPost?: Partial<BlogPostType>;
  totalComments?: number;
}

const BlogAnalyticsSection: React.FC<BlogAnalyticsProps> = ({
  totalBlogs,
  mostRecentPost,
  mostViewedPost,
  totalComments,
}) => {
  return (
    <div className={styles.blogAnalyticsContainer}>
      <div className={styles.analyticsCard}>
        <div className={styles.iconContainer}>
          <IoDocumentText className={styles.icon} />
        </div>
        <div className={styles.metricValue}>{totalBlogs}</div>
        <div className={styles.metricLabel}>Total Blogs</div>
      </div>

      {mostViewedPost && (
        <div className={styles.analyticsCard}>
          <div className={styles.iconContainer}>
            <IoEye className={styles.icon} />
          </div>
          <div className={styles.metricValue}>{mostViewedPost.views}</div>
          <div className={styles.metricLabel}>Most Viewed Post</div>
          <div className={styles.recentPost}>
            <h4>{mostViewedPost.title}</h4>
          </div>
        </div>
      )}

      <div className={styles.analyticsCard}>
        <div className={styles.iconContainer}>
          <IoCalendar className={styles.icon} />
        </div>
        <div className={styles.metricLabel}>Most Recent Post</div>
        <div className={styles.recentPost}>
          <h4>{mostRecentPost.title}</h4>
          <p>{mostRecentPost?.createdAt ? new Date(mostRecentPost.createdAt).toDateString() : ''}</p>
        </div>
      </div>

      {totalComments && (
        <div className={styles.analyticsCard}>
          <div className={styles.iconContainer}>
            <IoChatbubbles className={styles.icon} />
          </div>
          <div className={styles.metricValue}>{totalComments}</div>
          <div className={styles.metricLabel}>Total Comments</div>
        </div>
      )}
    </div>
  );
};

export default BlogAnalyticsSection;
