import React from 'react';
import { useSelector } from 'react-redux';
import { BlogPostType } from '../../../types/Data/BlogPostType';
import { RootState } from '../../../Store/store';
import HtmlSanitizer from '../../../utils/sanitizeHtml/sanitizeHtml';
import { BlogSectionComponentProps } from "../BlogSectionComponentProps";
import styles from './BlogSectionV4.module.scss';

const BlogSectionV4:React.FC<BlogSectionComponentProps> = ({onClick=()=>null}) => {
  
  const blogPosts = useSelector((state: RootState) => state.dashboard.blogPosts.blogPosts) as BlogPostType[];

  const createExcerpt = (html: string, length: number) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    const text = div.textContent || div.innerText || '';
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

  const sanitizeHtml = new HtmlSanitizer();

  return (
    <div className={styles.blog_container}>
        {blogPosts?.map((blog, index) => {
            const excerpt = sanitizeHtml.sanitize(createExcerpt(blog.content, 100))
            return (
              <div className={styles.blog_card}  onClick={()=>onClick && onClick(blog._id!)}>
              <img className={styles.image} src={blog.imageUrl} alt={blog.title} />
              <div className={styles.content}>
                <h2 className={styles.title}>{blog.title}</h2>
                <p className={styles.summary}>{excerpt}</p>
                <div className={styles.meta}>
                  <span>{blog.author}</span>
                  <span>{blog.createdAt?.toISOString()}</span>
                </div>
              </div>
          </div>
            )
        })}
    </div>
  )
}

export default BlogSectionV4