import React from 'react'
import styles from '../Component.module.scss';
import QAListV1 from '../../components/QAList/QAListV1/QAListV1';
import QAListV2 from '../../components/QAList/QAListV2/QAListV2';
import QAListV3 from '../../components/QAList/QAListV3/QAListV3';
import QAListV4 from '../../components/QAList/QAListV4/QAListV4';

const QAComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <QAListV1/>
        </div>
        <div className={styles.element}>
            <QAListV2/>
        </div>
        <div className={styles.element}>
            <QAListV3/>
        </div>
        <div className={styles.element}>
            <QAListV4/>
        </div>
    </div>
  )
}

export default QAComponentPage