import React, { useEffect, useState } from "react";
import { IoAdd, IoTrash } from "react-icons/io5";
import styles from "./ImportantLinksEditor.module.scss";

interface ImportantLinksEditorProps {
    links: { label: string, url: string }[];
    onLinksChange: (links: { label: string, url: string }[]) => void;
};

const ImportantLinksEditor: React.FC<ImportantLinksEditorProps> = ({ links, onLinksChange }) => {
    const defaultLink = { label: '', url: '' };
    
    const [newLink, setNewLink] = useState(defaultLink);
    const [importantLinks, setImportantLinks] = useState<{ label: string, url: string }[]>(links || []);
    const [error, setError] = useState<string | null>(null);

    const handleAddLink = () => {
        if (!newLink.label || !newLink.url) {
            setError("Both label and URL are required.");
            return;
        }

        setImportantLinks((prev) => [...prev, newLink]);
        setNewLink(defaultLink);
        setError(null);
    };

    const handleDeleteLink = (index: number) => {
        setImportantLinks((prev) => prev.filter((_, i) => i !== index));
    };

    const handleModifyLink = (index: number, key: keyof typeof defaultLink, value: string) => {
        setImportantLinks((prev) =>
            prev.map((link, i) => (i === index ? { ...link, [key]: value } : link))
        );
    };

    useEffect(() => {
        onLinksChange(importantLinks);
    }, [importantLinks]);

    return (
        <section className={styles.editorContainer}>
            <h4 className={styles.title}>Important Links</h4>
            <div className={styles.newLinkSection}>
                <h4 className={styles.subTitle}>Add New Link</h4>
                <div className={styles.newLinkInput}>
                    <input
                        placeholder="Label"
                        value={newLink.label}
                        name="label"
                        onChange={(e) => setNewLink((prev) => ({ ...prev, label: e.target.value }))}
                        className={styles.input}
                    />
                    <input
                        placeholder="URL"
                        value={newLink.url}
                        name="url"
                        onChange={(e) => setNewLink((prev) => ({ ...prev, url: e.target.value }))}
                        className={styles.input}
                    />
                    <div onClick={handleAddLink} className={styles.addButton}>
                        <IoAdd />
                    </div>
                </div>
                {error && <div className={styles.errorText}>{error}</div>}
            </div>
            <div className={styles.linksList}>
                <h4 className={styles.subTitle}>Links</h4>
                {importantLinks.map((link, index) => (
                    <div key={index} className={styles.linkItem}>
                        <input
                            placeholder="Label"
                            value={link.label}
                            onChange={(e) => handleModifyLink(index, 'label', e.target.value)}
                            className={styles.input}
                        />
                        <input
                            placeholder="URL"
                            value={link.url}
                            onChange={(e) => handleModifyLink(index, 'url', e.target.value)}
                            className={styles.input}
                        />
                        <div onClick={() => handleDeleteLink(index)} className={styles.deleteButton}>
                            <IoTrash />
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default ImportantLinksEditor;
