import React, { FC } from 'react';
import styles from './TextAreaV1.module.scss';
import { TextAreaComponentProps } from '../TextAeraComponentProps';

const TextAreaV1: FC<TextAreaComponentProps> = ({ label, error, className, wrapperStyle, ...props }) => {
  return (
    <div className={styles.textAreaWrapper} style={{ ...wrapperStyle }}>
      {label && <label className={styles.label}>{label}</label>}
      <textarea
        className={`${styles.textArea} ${error ? styles.error : ''} ${className ? className : ''}`}
        {...props}
      />
      {error && <span className={styles.errorText}>{error}</span>}
    </div>
  );
};

export default TextAreaV1;
