import React from 'react';
import styles from './RadioGroup1.module.scss';

interface RadioGroupProps {
  name: string;
  options: string[];
  selectedValue: string;
  onChange: (value: string) => void;
  title: string;
}

const RadioGroup1: React.FC<RadioGroupProps> = ({ name, options, selectedValue, onChange, title }) => {
  return (
    <div className={styles.option_group}>
      <h4>{title}</h4>
      <div className={styles.radio_group}>
        {options.map((option) => (
          <label key={option} className={styles.radio_label}>
            <input
              type="radio"
              name={name}
              value={option}
              checked={selectedValue === option}
              onChange={() => onChange(option)}
              className={styles.radio_input}
            />
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </label>
        ))}
      </div>
    </div>
  );
};

export default RadioGroup1;
