import React from 'react';
import styles from './AvatarV1.module.scss';
import { AvatarComponentProps } from '../AvatarComponentProps';

interface AvatarGroupProps {
  avatars: AvatarComponentProps[];
  maxDisplayed?: number;
  size?: number; // size in pixels
}

const AvatarV1: React.FC<AvatarGroupProps> = ({
  avatars,
  maxDisplayed = 3,
  size = 40,
}) => {
  const displayedAvatars = avatars.slice(0, maxDisplayed);
  const remainingCount = avatars.length - maxDisplayed;

  return (
    <div className={styles.avatar_group}>
      {displayedAvatars.map((avatar, index) => (
        <img
          key={index}
          src={avatar.imageUrl}
          alt={avatar.imageUrl}
          className={styles.avatar}
          style={{ width: size, height: size, zIndex: maxDisplayed - index }}
        />
      ))}
      {remainingCount > 0 && (
        <div
          className={styles.avatar_more}
          style={{ width: size, height: size, lineHeight: `${size}px` }}
        >
          +{remainingCount}
        </div>
      )}
    </div>
  );
};

export default AvatarV1;