import React, { useState } from 'react';
import styles from './CouponPromotionV1.module.scss';
import { CouponPromotionComponentCustomizationProps, CouponPromotionComponentProps } from '../CouponPromotionComponentProps';

const CouponPromotionV1: React.FC<CouponPromotionComponentProps & CouponPromotionComponentCustomizationProps> = ({
  couponCode = "12345678",
  onCouponCodeChange = () => null,
  onApplyCoupon = () => null,
  isCouponApplied = false,
  successMessage = "Coupon Applied!",
  placeholderText = "Enter your coupon code",
  buttonText = "Apply"
}) => {

  return (
    <div className={styles.couponContainer}>
      <input
        type="text"
        value={couponCode}
        onChange={(e) => onCouponCodeChange}
        placeholder={placeholderText}
        className={styles.couponInput}
      />
      <button onClick={onApplyCoupon} className={styles.applyButton}>
        {buttonText}
      </button>
      {isCouponApplied && <p className={styles.successMessage}>{successMessage}</p>}
    </div>
  );
};

export default CouponPromotionV1;
