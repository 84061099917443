import React from 'react';
import styles from './FeaturedProductsV1.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { ProductType } from '../../../types/Data/ProductType';
import { FeaturedProductsComponentProps } from '../FeaturedProductsComponentProps';

const FeaturedProductsV1: React.FC<FeaturedProductsComponentProps> = ({ productsId, onClick=()=>null }) => {
    
    const allProducts = useSelector<RootState>(state => state.dashboard.products.products) as ProductType[];

    const products = productsId
    ? allProducts.filter((product) => productsId.includes(product._id!))
    : allProducts.slice(0, 10); 


    return (
    <div className={styles.container}>
      <h3 className={styles.title}>Featured Products</h3>
      <div className={styles.cardFlipContainer}>
        {products?.map((product) => (
          <div key={product._id!} className={styles.card} onClick={()=>onClick(product._id!)}>
            <div className={styles.cardInner}>
              <div className={styles.cardFront}>
                {product.images && <img src={product.images[0].url} alt={product.name} className={styles.productImage} /> }
                <h3>{product.name}</h3>
              </div>
              <div className={styles.cardBack}>
                <h3>{product.name}</h3>
                <p>{product.description}</p>
                <span>{product.price}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>

  );
};

export default FeaturedProductsV1;
