import React from 'react';
import styles from './ValidateReturnedItemsTable.module.scss';
import { ReturnOrderItemType, ReturnItemCondition } from '../../../../types/Data/ReturnRequestType';
import { RefundItemCondition, RefundReturnedProduct } from '../../../../types/Data/RefundProcessType';

interface ValidateReturnedItemsTableProps {
  items: ReturnOrderItemType[];
  onValidateItems: (validatedItems: RefundReturnedProduct[]) => void;
}

const ValidateReturnedItemsTable: React.FC<ValidateReturnedItemsTableProps> = ({ items, onValidateItems }) => {
  const [validatedItems, setValidatedItems] = React.useState<RefundReturnedProduct[]>([]);

  const handleConditionChange = (updatedItem: RefundReturnedProduct) => {
    // Check if the item already exists in the validatedItems array
    const updatedItems = validatedItems.map((item) =>
      item.product === updatedItem.product ? updatedItem : item
    );

    // If item does not exist, add it
    if (!validatedItems.find((item) => item.product === updatedItem.product)) {
      updatedItems.push(updatedItem);
    }

    setValidatedItems(updatedItems);
    onValidateItems(updatedItems);
  };

  // Convert enum to array of label-value pairs
  const returnedConditions = Object.keys(ReturnItemCondition).map((key) => ({
    label: key.replace(/_/g, ' '), // Replace underscores with spaces for cleaner labels
    value: ReturnItemCondition[key as keyof typeof ReturnItemCondition],
  }));

  return (
    <div className={styles.validateReturnedItemsTable}>
      <h2>Validate Returned Items and Set Refund</h2>
      <table>
        <thead>
          <tr>
            <th>Item</th>
            <th>Quantity</th>
            <th>Condition</th>
            <th>Max Refund</th>
            <th>Refund Amount</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <tr key={item.productName}>
              <td>{item.productName}</td>
              <td>{item.quantity}</td>
              <td>
                <select
                  onChange={(e) =>
                    handleConditionChange({
                      ...item,
                      condition: e.target.value  as RefundItemCondition,
                      price: item.price * item.quantity,
                      refundAmount: item.refundAmount || (item.price * item.quantity)
                    })
                  }
                >
                  {returnedConditions.map((condition) => (
                    <option key={condition.value} value={condition.value}>
                      {condition.label}
                    </option>
                  ))}
                </select>
              </td>
              <td>${(item.price * item.quantity).toFixed(2)}</td>
              <td>
                <input
                  type="number"
                  onChange={(e) =>
                    handleConditionChange({
                      ...item,
                      refundAmount: parseFloat(e.target.value),
                      condition: item.conditionAfterReturn as unknown as RefundItemCondition,
                    })
                  }
                  min="0"
                  max={(item.price * item.quantity)}
                  step="0.01"
                  defaultValue={(item.price * item.quantity).toFixed(2)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ValidateReturnedItemsTable;
