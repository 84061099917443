// store/paymentSettingsSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dataExamplePaymentSettings from './dataExamplePaymentSettings';
import { PaymentMethodType, PaymentSettingsType } from '../../../types/Data/PayementSettingsType';
import { getAllPaymentMethods } from '../../../api/paymentMethod/paymentMethodApi';

// Fetch all payment methods
export const fetchAllPaymentMethods = createAsyncThunk(
  'paymentMethods/fetchAll',
  async (_, { rejectWithValue }) => {
      try {
          const response = await getAllPaymentMethods();
          return response.data.data
      } catch (error: any) {
          return rejectWithValue(error.response?.data?.message || 'Failed to load discount codes');
      }
  }
);

interface PaymentMethosState {
  methods: PaymentMethodType[],
  loading: boolean,
  error: string | null
}

const initialState: PaymentMethosState = {
  methods: dataExamplePaymentSettings,
  loading: false,
  error: null
}

const paymentMethodsSlice = createSlice({
  name: 'paymentSettings',
  initialState,
  reducers: {
    dispatchUpdatePaymentSettings(state, action: PayloadAction<PaymentSettingsType>) {
      return { ...state, ...action.payload };
    },
    dispatchAddPayementMethod(state, action:PayloadAction<PaymentMethodType>) {
      state.methods.push(action.payload);
    },
    dispatchUpdatePayementMethod(state, action:PayloadAction<PaymentMethodType>) {
      const index = state.methods.findIndex((method) => method.name === action.payload.name);
      if (index !== -1) {
        state.methods[index] = action.payload;
      }
    },
    dispatchRemovePaymentMethod(state, action: PayloadAction<string>) {
      state.methods = state.methods.filter((method) => method.name !== action.payload);
    },
    dispatchLoadPaymentMethod(state, action:PayloadAction<PaymentMethodType[]>) {
      state.methods = action.payload
    }
  },
  extraReducers: (builder) => {
    // Fetch all discount codes
    builder.addCase(fetchAllPaymentMethods.pending, (state) => {
        state.loading = true;
        state.error = null;
    });
    builder.addCase(fetchAllPaymentMethods.fulfilled, (state, action: PayloadAction<PaymentMethodType[]>) => {
        state.methods = action.payload;
        state.loading = false;
    });
    builder.addCase(fetchAllPaymentMethods.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
    });
}
});

export const { dispatchAddPayementMethod, dispatchUpdatePayementMethod, dispatchRemovePaymentMethod, dispatchUpdatePaymentSettings, dispatchLoadPaymentMethod } = paymentMethodsSlice.actions;
export default paymentMethodsSlice.reducer;
