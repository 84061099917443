import OrderConfirmationV1 from "./OrderConfirmationV1/OrderConfirmationV1";
import OrderConfirmationV2 from "./OrderConfirmationV2/OrderConfirmationV2";
import OrderConfirmationV3 from "./OrderConfirmationV3/OrderConfirmationV3";

enum OrderConfirmationVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const OrderConfirmationMapping:Record<OrderConfirmationVersions, React.FC<any>> = {
    [OrderConfirmationVersions.V1]: OrderConfirmationV1,
    [OrderConfirmationVersions.V2]: OrderConfirmationV2,
    [OrderConfirmationVersions.V3]: OrderConfirmationV3,
};

interface OrderConfirmationComponentProps {
    orderNumber?: string;
    orderDate?: string;
    totalAmount?: string;
    items?: { name: string; quantity: number; price: string }[];
};

export {
    OrderConfirmationMapping,
    OrderConfirmationVersions
}

export type {
    OrderConfirmationComponentProps
}