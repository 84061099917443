import { RefundEligibility, ReturnAdminApprovalStatus, ReturnItemCondition, ReturnReason, ReturnRequestType, ReturnStatus } from "../../types/Data/ReturnRequestType";
import { CustomerReturnHistoryComponentProps } from "./CustomerReturnHistoryComponentProps";

export const customerReturnHistoryDefaultProps: CustomerReturnHistoryComponentProps = {
    returnRequest: {
        _id: "12345",
        order: "Order123",
        customer: "Customer123",
        customerName: "John Doe",
        items: [
        {
            product: '1',
            productName: "Wireless Headphones",
            quantity: 1,
            price: 199.99,
            isReturnable: true,
            refundRequested: true,
            refundEligibility: RefundEligibility.Eligible,
            exchangeEligible: true,
            returnReason: ReturnReason.DefectiveProduct,
            adminApprovalStatus: ReturnAdminApprovalStatus.Approved,
            conditionAfterReturn: ReturnItemCondition.Defective,
        },
        {
            product: '2',
            productName: "Smartwatch",
            quantity: 1,
            price: 299.99,
            isReturnable: true,
            refundRequested: true,
            refundEligibility: RefundEligibility.NotEligible,
            exchangeEligible: false,
            returnReason: ReturnReason.IncorrectProduct,
            adminApprovalStatus: ReturnAdminApprovalStatus.Rejected,
        },
        ],
        status: ReturnStatus.Pending,
        shipmentInfo: {
        shippingCarrier: "DHL",
        trackingNumber: "DHL123456",
        shippingMethod: "Standard",
        shippingDate: new Date(),
        },
        adminNotes: "Please inspect the headphones for defects before returning.",
        createdAt: new Date(),
        updatedAt: new Date(),
    }
}
  