import { AccountType } from "../../../types/Data/AccountType";

const exampleAccounts: AccountType[] = [
    {
        _id: '64b0e69f0b8b19aabb1b9f2a',
        email: 'john.doe@example.com',
        passwordHash: '$2b$10$k5c1Q4G.Q1pF5fjkXY/4E.YuTfhYy8GQ5k/F4cMZPvT2oWqD5XCeK', // Example hashed password
        resetPasswordToken: 'exampleToken123',
        resetPasswordExpires: new Date(Date.now() + 3600000),  // Expires in 1 hour
        role: 'Customer',  // Role can be 'Admin', 'Customer', etc.
        lastPasswordReset: '2023-01-01T10:00:00Z',
        isVerified: true,
        lastLoginDate: '2023-09-01T12:00:00Z',
        isTwoFactorEnabled: true,
        commonDevices: ['iPhone 13', 'MacBook Pro'],
        ipAddressHistory: ['192.168.0.1', '192.168.0.2'],
        googleId: '1234567890-google-id',  // Optional Google login
        facebookId: undefined,  // Optional Facebook login
    },
];

export default exampleAccounts;
