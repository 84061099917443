import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Store/store";
import styles from './EcommerceAboutUs.module.scss';
import { CompanyHistoryMapping, CompanyHistoryVersions } from "../../UIElements/CompanyHistory/CompanyHistoryComponentProps";
import { CompanyOverviewMapping, CompanyOverviewVersions } from "../../UIElements/CompanyOverview/CompanyOverviewComponentProps";
import { TeamMemberCardMapping, TeamMemberCardVersions } from "../../components/TeamMemberCard/TeamMemberComponentProps";
import { TestimonialMapping, TestimonialVersions } from "../../UIElements/Testimonial/TestimonialComponentProps";
import { BaseElementType } from "../../types/Data/BaseElementType";

const InvalidComponent: React.FC = () => <div>Invalid Component</div>;

const EcommerceAboutUs: React.FC = () => {
    const aboutUsPageElements = useSelector<RootState>((state) => state.dashboard.elements.elements.filter((el)=>el.page==='aboutUs')) as BaseElementType<any>[];

    const getAboutUsPageElement = (element: BaseElementType<any>): React.FC => {
        switch (element.type) {
            case 'companyHistory':
                return CompanyHistoryMapping[element.version as CompanyHistoryVersions];
            case 'companyOverview':
                return CompanyOverviewMapping[element.version as CompanyOverviewVersions];
            case 'teamMemberCard':
                return TeamMemberCardMapping[element.version as TeamMemberCardVersions];
            case 'testimonial':
                return TestimonialMapping[element.version as TestimonialVersions];
            default:
                return InvalidComponent;
        }
    };

    return (
            <div className={styles.aboutUsPageContainer}>
                {aboutUsPageElements.map((element, index) => {
                    const Component = getAboutUsPageElement(element);
                    return (
                        <div className={styles.animatedSection} key={index}>
                            <Component />
                        </div>
                    );
                })}
            </div>
    );
};

export default EcommerceAboutUs;
