import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar2 from "./DashboardMenu/DashboardMenu";
import styles from './Dashboard.module.scss';
import DashboardHome from "./Home/DashboardHome";

const Dashboard = () => {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const location = useLocation();

    const isRootPath = location.pathname === '/dashboard';

    return (
        <div className={styles.dashboard}>
            <Sidebar2 isOpen={isSidebarCollapsed} onToggle={setIsSidebarCollapsed} />
            <div 
                className={`${styles.content} ${isSidebarCollapsed ? styles.collapsedContent : ''}`}
                style={{ width: `calc(100%  - ${isSidebarCollapsed ? '60px' : '250px'})` }}
            >
                {isRootPath ? <DashboardHome /> : <Outlet />}
            </div>
        </div>
    );
};

export default Dashboard;
