import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Store/store';
import { OrderType } from '../../../../types/Data/OrderType';
import styles from './ChartTopProductSales.module.scss';

const ChartTopProductsSales: React.FC = () => {
    const orders = useSelector((state: RootState) => state.dashboard.orders.orders) as OrderType[];

    // Function to flatten and aggregate product quantities from orders
    const getTopProductsSold = () => {
        const productSalesMap: { [key: string]: { productName: string; totalQuantity: number } } = {};

        orders.forEach(order => {
            order.items.forEach(item => {
                if (!productSalesMap[item.productId]) {
                    productSalesMap[item.productId] = {
                        productName: item.name,
                        totalQuantity: 0,
                    };
                }
                productSalesMap[item.productId].totalQuantity += item.quantity;
            });
        });

        return Object.values(productSalesMap)
            .sort((a, b) => b.totalQuantity - a.totalQuantity)
            .slice(0, 10); // Get the top 10 products by quantity sold
    };

    const topProducts = useMemo(getTopProductsSold, [orders]); // Memoize the top products to avoid recalculation

    // Prepare data for chart
    const productNames = topProducts.map(p => p.productName);
    const productQuantities = topProducts.map(p => p.totalQuantity);

    const data: ChartData<'bar'> = {
        labels: productNames,
        datasets: [
            {
                label: 'Units Sold',
                data: productQuantities,
                backgroundColor: 'rgba(54, 162, 235, 0.6)', // Customize color
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: false, // Allow the chart to resize dynamically with the container
        scales: {
            x: {
                ticks: {
                    color: 'white', // Change the color of the axis labels if needed
                },
                grid: {
                    display: false, // Remove grid lines to reduce clutter
                },
            },
            y: {
                ticks: {
                    color: 'white',
                },
                beginAtZero: true,
                grace: '5%', // Adds a little bit of space above the highest bar
            },
        },
        plugins: {
            legend: {
                display: false,
                labels: {
                    color: 'white', // Legend text color
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.dataset.label}: ${context.raw}`;
                    },
                },
            },
        },
        layout: {
            padding: {
                top: 10, // Add space for the chart title
                bottom: 10, // Add space below the chart
            },
        },
    };

    return (
        <div className={styles.container}>
            <h3>Top 10 Products Sold</h3>
            <div className={styles.chart_container}>
                <Bar data={data} options={options} />
            </div>
        </div>
    );
};

export default ChartTopProductsSales;
