import React from 'react';
import  styles from './SlideOverV3.module.scss';
import { SlideOverComponentProps } from '../SlideOverComponentProps';

const SlideOverV3: React.FC<SlideOverComponentProps> = ({ 
  isOpen = false, 
  onClose = ()=>null, 
  children = null,
  modalStyle = undefined 
 }) => {
  return (
    <div className={`${styles.slide_over} ${isOpen ? styles.open : ''}`}>
      <div className={styles.slide_over_content}>
        <button className={styles.close_button} onClick={onClose}>×</button>
        {children}
      </div>
    </div>
  );
};

export default SlideOverV3;