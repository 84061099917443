import React, { useState } from 'react';
import styles from './DialogV1.module.scss'
import { FaExclamationTriangle } from 'react-icons/fa';
import { DialogComponentProps } from '../DialogComponentProps';


const DialogV1: React.FC<DialogComponentProps> = ({ 
  title = "Dialog", 
  message = "This is a dialog window", 
  options = [ { label:'Validate', onClick:()=>null }, { label:'Cancel', onClick:()=>null }, ] 

}) => {
  
    return (
        <div>
          <div className={styles.alert_dialog}>
            <div className={styles.alert_dialog_title}>
                <div className={styles.alert_icon}>
                    <FaExclamationTriangle />
                </div>
              <h2>{title}</h2>
            </div>
            <div className={styles.alert_dialog_content}>
              <p>{message}</p>
            </div>
            <div className={styles.alert_dialog_actions}>
              {options.map((option, index) => (
                <button
                  key={index}
                  className={styles.button}
                  onClick={() => {
                    option.onClick();
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>
        </div>
    )
};

export default DialogV1;