import React from 'react';
import { FaRocket, FaShieldAlt, FaCogs } from 'react-icons/fa';
import { FeatureProps, FeaturesSectionComponentProps } from './FeatureSectionComponentProps';

const defaultFeatures: FeatureProps[] = [
    {
        title: 'Fast Performance',
        description: 'Experience blazing fast speeds with our optimized platform.',
        icon: 'FaRocket',
    },
    {
        title: 'Top Security',
        description: 'We ensure top-notch security for all your data and transactions.',
        icon: 'FaShieldAlt'
    },
    {
        title: 'Customizable',
        description: 'Our solutions are fully customizable to meet your needs.',
        icon: 'FaCogs',
    },
];

const defaultFeaturesSectionProps: FeaturesSectionComponentProps = {
    title: 'Our Key Features',
    description: 'Discover the advantages of using our platform, designed to deliver the best experience possible.',
    features: defaultFeatures,
};

export { defaultFeaturesSectionProps };
