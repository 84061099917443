import React from 'react';
import styles from './ProductNameV1.module.scss';
import { ProductTitleComponentProps } from '../ProductTitleComponentProps';
import { ProductPriceMapping, ProductPriceVersions } from '../../ProductPrice/ProductPricingComponentProps';
import { ProductRatingMapping, ProductRattingVersions } from '../../ProductRating/ProductRatingComponentProps';

const ProductNameV1: React.FC<ProductTitleComponentProps> = ({ 
  product = {name: "Product Name", rating: 5, price: 20}, 
  subtitle, 
  color, 
  fontSize, 
  fontWeight, 
  underline, 
  icon, 
  productPriceVersion = ProductPriceVersions.V1, 
  productRatingVersion = ProductRattingVersions.V1,
}) => {

  const ProductPrice = ProductPriceMapping[productPriceVersion];
  const ProductRating = ProductRatingMapping[productRatingVersion];

  return (
    <div className={styles.productTitleContainer}>
      <div className={styles.productHeader}>
        <h1
          className={styles.title}
          style={{
            color: color || undefined,
            fontSize: fontSize || undefined,
            fontWeight: fontWeight || undefined,
          }}
        >
          {icon && <span className={styles.icon}>{icon}</span>}
          {product?.name}
        </h1>
        <div className={styles.priceAndRating}>
          <ProductPrice product={product} />
          <ProductRating product={product} />
        </div>
      </div>
      {subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
      {underline && <div className={styles.underline}></div>}
    </div>
  );
};

export default ProductNameV1;
