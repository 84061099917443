// CustomerInfo.tsx

import React, { useState } from 'react';
import styles from './CustomerInfo.module.scss';
import { CustomerType } from '../../../../types/Data/CustomerType';
import { FaEdit, FaSave, FaTimes } from 'react-icons/fa';
import { updateCustomer } from '../../../../api/customer/customerApi';
import { handleApiError } from '../../../../api/apiError';

interface CustomerInfoProps {
  customer: CustomerType;
  onCustomerUpdate: (updatedCustomer: CustomerType) => void;
}

const CustomerInfo: React.FC<CustomerInfoProps> = ({ customer, onCustomerUpdate }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [formData, setFormData] = useState<CustomerType>({ ...customer });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    // Handle nested fields like shippingAddress
    if (name.startsWith('shippingAddress.')) {
      const addressField = name.split('.')[1];
      setFormData({
        ...formData,
        shippingAddress: {
          ...formData.shippingAddress,
          [addressField]: value,
        },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    setFormData({ ...customer });
    setErrors({});
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.name.trim()) newErrors.name = 'Name is required.';
    if (!formData.email.trim()) newErrors.email = 'Email is required.';
    // Add more validation rules as needed
    return newErrors;
  };

  const handleSave = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsSaving(true);
    try {
      // Send updated data to the backend API
      const response = await updateCustomer(customer._id, formData);
      if (response && response.data) {
        onCustomerUpdate(response.data);
        alert('Customer updated successfully!');  // Success message
      } else {
        // Handle unexpected success cases
        alert('Something went wrong while updating the return request!');
      }
    } catch (error) {
      console.error('Error updating customer:', error);
      const handledError = handleApiError(error)
      alert(handledError.message)
    } finally {
      setIsSaving(false);
      setIsEditing(false);
    }
  };

  return (
    <div className={styles.customerInfo}>
      <div className={styles.header}>
        <h2>Customer Information</h2>
        <button onClick={handleEditToggle} className={styles.editButton}>
          {isEditing ? <FaTimes /> : <FaEdit />} {isEditing ? 'Cancel' : 'Edit'}
        </button>
      </div>

      <div className={styles.infoContainer}>
        {/* Personal Information */}
        <div className={styles.infoSection}>
          <h3>Personal Info</h3>
          <div className={styles.infoRow}>
            <label htmlFor="name">Name:</label>
            {isEditing ? (
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            ) : (
              <span>{customer.name}</span>
            )}
            {errors.name && <span className={styles.error}>{errors.name}</span>}
          </div>
          <div className={styles.infoRow}>
            <label htmlFor="email">Email:</label>
            {isEditing ? (
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            ) : (
              <span>{customer.email}</span>
            )}
            {errors.email && <span className={styles.error}>{errors.email}</span>}
          </div>
          <div className={styles.infoRow}>
            <label htmlFor="preferredLanguage">Preferred Language:</label>
            {isEditing ? (
              <select
                id="preferredLanguage"
                name="preferredLanguage"
                value={formData.preferredLanguage || ''}
                onChange={handleInputChange}
              >
                <option value="">Select Language</option>
                <option value="English">English</option>
                <option value="French">French</option>
                {/* Add more language options */}
              </select>
            ) : (
              <span>{customer.preferredLanguage || 'N/A'}</span>
            )}
          </div>
          <div className={styles.infoRow}>
            <label htmlFor="communicationPreference">Communication Preference:</label>
            {isEditing ? (
              <select
                id="communicationPreference"
                name="communicationPreference"
                value={formData.communicationPreference || ''}
                onChange={handleInputChange}
              >
                <option value="">Select Preference</option>
                <option value="Email">Email</option>
                <option value="Phone">Phone</option>
                {/* Add more options */}
              </select>
            ) : (
              <span>{customer.communicationPreference || 'N/A'}</span>
            )}
          </div>
          <div className={styles.infoRow}>
            <label htmlFor="loyaltyLevel">Loyalty Level:</label>
            {isEditing ? (
              <select
                id="loyaltyLevel"
                name="loyaltyLevel"
                value={formData.loyaltyLevel || ''}
                onChange={handleInputChange}
              >
                <option value="">Select Level</option>
                <option value="Bronze">Bronze</option>
                <option value="Silver">Silver</option>
                <option value="Gold">Gold</option>
                {/* Add more levels */}
              </select>
            ) : (
              <span>{customer.loyaltyLevel || 'N/A'}</span>
            )}
          </div>
        </div>

        {/* Contact Information */}
        <div className={styles.infoSection}>
          <h3>Contact</h3>
          <div className={styles.infoRow}>
            <label htmlFor="phone">Phone:</label>
            {isEditing ? (
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone || ''}
                onChange={handleInputChange}
              />
            ) : (
              <span>{customer.phone || 'N/A'}</span>
            )}
          </div>
          {/* Address Fields */}
          <div className={styles.infoRow}>
            <label htmlFor="street">Street:</label>
            {isEditing ? (
              <input
                type="text"
                id="street"
                name="shippingAddress.street"
                value={formData.shippingAddress?.street || ''}
                onChange={handleInputChange}
              />
            ) : (
              <span>{customer.shippingAddress?.street || 'N/A'}</span>
            )}
          </div>
          <div className={styles.infoRow}>
            <label htmlFor="city">City:</label>
            {isEditing ? (
              <input
                type="text"
                id="city"
                name="shippingAddress.city"
                value={formData.shippingAddress?.city || ''}
                onChange={handleInputChange}
              />
            ) : (
              <span>{customer.shippingAddress?.city || 'N/A'}</span>
            )}
          </div>
          <div className={styles.infoRow}>
            <label htmlFor="postalCode">Postal Code:</label>
            {isEditing ? (
              <input
                type="text"
                id="postalCode"
                name="shippingAddress.postalCode"
                value={formData.shippingAddress?.postalCode || ''}
                onChange={handleInputChange}
              />
            ) : (
              <span>{customer.shippingAddress?.postalCode || 'N/A'}</span>
            )}
          </div>
        </div>

        {/* Order Details */}
        <div className={styles.infoSection}>
          <h3>Order Details</h3>
          <div className={styles.infoRow}>
            <label>Total Spend:</label>
            <span>${customer.totalSpend?.toFixed(2)}</span>
          </div>
          <div className={styles.infoRow}>
            <label>Number of Orders:</label>
            <span>{customer.numOrders}</span>
          </div>
          <div className={styles.infoRow}>
            <label>Last Order Date:</label>
            <span>
              {customer.lastOrderDate
                ? new Date(customer.lastOrderDate).toLocaleDateString()
                : 'N/A'}
            </span>
          </div>
        </div>

        {/* Additional Information */}
        <div className={styles.infoSection}>
          <h3>Additional Info</h3>
          <div className={styles.infoRow}>
            <label>Customer ID:</label>
            <span>{customer._id}</span>
          </div>
          <div className={styles.infoRow}>
            <label>Registration Date:</label>
            <span>
              {customer.createdAt
                ? new Date(customer.createdAt).toLocaleDateString()
                : 'N/A'}
            </span>
          </div>
          {/* Add more fields as needed */}
        </div>

        {/* Save Button */}
        {isEditing && (
          <div className={styles.buttonContainer}>
            <button onClick={handleSave} className={styles.saveButton} disabled={isSaving}>
              {isSaving ? 'Saving...' : <><FaSave /> Save Changes</>}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerInfo;
