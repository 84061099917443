import React, { useState } from 'react';
import styles from './ColorSelectorV1.module.scss';
import { ColorSelectorComponentProps } from '../ColorSelectorComponentProps';
import { defaultColorSelectorProps } from './defaultColorSelectorProps';

const ColorSelectorV1:React.FC<ColorSelectorComponentProps> = ({ colors = defaultColorSelectorProps }) => {
  const [selectedColor, setSelectedColor] = useState(colors[0]);

  return (
    <div className={styles.selectorContainer}>
      <h3>Choose Color</h3>
      <div className={styles.swatches}>
        {colors.map((color) => (
          <div
            key={color.name}
            className={`${styles.swatch} ${color.name === selectedColor.name ? styles.selected : ''}`}
            style={{ backgroundColor: color.hex }}
            onClick={() => setSelectedColor(color)}
            title={color.name}
          >
            {color.name === selectedColor.name && <div className={styles.checkmark}>✔</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColorSelectorV1;
