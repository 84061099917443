import ContactFormV1 from "../../UIElements/ContactForm/ContactFormV1/ContactFormV1";
import ContactFormV2 from "../../UIElements/ContactForm/ContactFormV2/ContactFormV2";
import ContactFormV3 from "../../UIElements/ContactForm/ContactFormV3/ContactFormV3";


enum ContactFormVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const ContactFormMapping:Record<ContactFormVersions, React.FC<any>> = {
    [ContactFormVersions.V1]: ContactFormV1,
    [ContactFormVersions.V2]: ContactFormV2,
    [ContactFormVersions.V3]: ContactFormV3,
};


export enum ContactUsSubject {
    OrderIssue = 'Order Issue',
    ProductInquiry = 'Product Inquiry',
    ShippingIssue = 'Shipping Issue',
    PaymentIssue = 'Payment Issue',
    RefundRequest = 'Refund Request',
    AccountIssue = 'Account Issue',
    TechnicalSupport = 'Technical Support',
    Other = 'Other'
}



interface ContactFormComponentProps {
    onSubmit?: (data: { name: string; email: string; subject: string; message: string }) => void;
    initialData?: { name?: string; email?: string; subject?: string; message?: string };
    submitButtonText?: string;
};

export {
    ContactFormVersions,
    ContactFormMapping
};

export type {
    ContactFormComponentProps
};
  