import React from 'react'
import styles from '../Component.module.scss';
import CartSummarySidebarV1 from '../../UIElements/CartSummarySidebar/CartSummarySidebarV1/CartSummarySidebarV1';
import CartSummarySidebarV2 from '../../UIElements/CartSummarySidebar/CartSummarySidebarV2/CartSummarySidebarV2';
import CartSummarySidebarV3 from '../../UIElements/CartSummarySidebar/CartSummarySidebarV3/CartSummarySidebarV3';

const CartSummarySidebarComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <CartSummarySidebarV1/>
        </div>
        <div className={styles.element}>
            <CartSummarySidebarV2/>
        </div>
        <div className={styles.element}>
            <CartSummarySidebarV3/>
        </div>

    </div>
  )
}

export default CartSummarySidebarComponentPage