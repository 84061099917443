import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IoCart, IoPerson, IoMenu, IoChevronForward, IoHome } from 'react-icons/io5';
import styles from './TabNavigationV1.module.scss';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../Store/store';
import { NavigationItem, TabNavigationComponentProps } from '../TabNavigationComponentProps';

const TabNavigationV1: React.FC<TabNavigationComponentProps> = ({
    items = [],
    showCart = true,
    showUserIcon = true,
    headerStyle = 'sticky',
    backgroundColor = '',
    textColor = undefined,
    fontStyle = 'normal',
    headerHeight = undefined,
    borderStyle = 'none',
    shadow = true,
    displayMode = 'inline',
    onCartClick = () => null,
    onShopClick = () => null,
    onUserClick = () => null,
}) => {
  const navigate = useNavigate();

  const shopName = useSelector<RootState>((state) => state.dashboard.shopSettings.settings.shopName) as string;
  const shopLogo = useSelector<RootState>((state) => state.dashboard.shopSettings.settings.shopLogo) as string;

  const [selectedCategory, setSelectedCategory] = useState<NavigationItem | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showDrawer, setShowDrawer] = useState(false);
  const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());

  const logoContainerRef = useRef<HTMLDivElement>(null);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const toggleItem = (itemId: string) => {
    setExpandedItems(prevState => {
      const newExpandedItems = new Set(prevState);
      if (newExpandedItems.has(itemId)) {
        newExpandedItems.delete(itemId);
      } else {
        newExpandedItems.add(itemId);
      }
      return newExpandedItems;
    });
  };

  const headerStyleClass = () => {
    switch (headerStyle) {
      case 'fixed':
        return styles.headerFixed;
      case 'sticky':
        return styles.headerSticky;
      case 'drawer':
        return styles.headerDrawer;
      default:
        return '';
    }
  };

  const renderInlineNavigation = () => (
    <nav className={styles.navItems}>
      {items.map((item: NavigationItem) => (
        <a key={item.id} href={item.link} className={styles.navItem}>
          {item.name}
        </a>
      ))}
    </nav>
  );

  const renderFlyoutNavigation = () => (
    <nav className={styles.navItems} style={{marginRight: logoContainerRef.current?.getBoundingClientRect().width}}>
      {items.map((item: NavigationItem) => (
        <div
          key={item.id}
          className={styles.navItemWithFlyout}
          onMouseEnter={() => setSelectedCategory(item)}
          onMouseLeave={() => setSelectedCategory(null)}
        >
          <a href={item.link} className={styles.navItem}>
            {item.name}
          </a>
        </div>
      ))}
    </nav>
  );

  const renderFlyoutMenu = () => (
    <div className={`${styles.flyoutMenu} ${selectedCategory ? styles.flyoutMenuVisible : ''}`}>
      {selectedCategory &&
        selectedCategory.subcategories &&
        selectedCategory.subcategories.map((item) => (
          <a key={item.id} href={item.link} className={styles.flyoutItem}>
            {item.name}
          </a>
        ))}
    </div>
  );

  return (
    <header
      className={`${styles.customizableTabHeader} ${headerStyleClass()}`}
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
        fontStyle: fontStyle,
        height: headerHeight,
        borderBottom: borderStyle !== 'none' ? `1px ${borderStyle} ${textColor}` : 'none',
        boxShadow: shadow ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
      }}
    >
      <section className={styles.row}>
        <div className={`${styles.logoContainer} `}>
          {shopLogo ? <img src={shopLogo} alt="Logo" className={styles.logo} onClick={onShopClick}  /> : <IoHome/>}
          {isMobile && <IoMenu className={styles.menuIcon} onClick={toggleDrawer} />}
        </div>
        {shopName && 
          <div className={styles.shopNameContainer} onClick={onShopClick}>
            <span className={`${styles.shopName}`}>{shopName}</span>
          </div>
        }
        {!isMobile && displayMode === 'inline' && renderInlineNavigation()}
        <div ref={logoContainerRef} className={styles.iconContainer}>
          {showCart && <IoCart className={styles.icon} onClick={onCartClick} />}
          {showUserIcon && <IoPerson className={styles.icon} onClick={onUserClick}/>}
        </div>
      </section>
      
      <section className={styles.row}>
        {!isMobile && displayMode === 'flyout' && renderFlyoutNavigation()}
      </section>
      {!isMobile && renderFlyoutMenu()}

      {/* Drawer and Backdrop */}
      {isMobile && (
        <>
          <div className={`${styles.backdrop} ${showDrawer ? styles.open : ''}`} onClick={toggleDrawer}></div>
          <div className={`${styles.drawer} ${showDrawer ? styles.open : ''}`}>
            <div className={styles.drawerHeader}>
              <span>Navigation</span>
              <IoMenu className={styles.menuIcon} onClick={toggleDrawer} />
            </div>
            <div className={styles.drawerContent}>
              {items.map((item: NavigationItem) => (
                <div key={item.id} className={`${styles.navItem} ${expandedItems.has(item.id) ? styles.open : ''}`} onClick={() => toggleItem(item.id)}>
                  <span>{item.name}</span>
                  {item.subcategories && item.subcategories.length > 0 && <IoChevronForward className={styles.chevron} />}
                  {expandedItems.has(item.id) && (
                    <div className={styles.subNav}>
                      {item?.subcategories!.map((subItem) => (
                        <div key={subItem.id} className={styles.subNavItem}>
                          <a href={subItem.link}>{subItem.name}</a>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </header>
  );
};

export default TabNavigationV1;
