import React, { useState } from 'react';
import styles from './ProductDescriptionV2.module.scss';
import { ProductDescriptionComponentProps } from '../ProducrDescriptionComponentProps';



const ProductDescriptionV2: React.FC<ProductDescriptionComponentProps> = ({
  product = {
    description: "This is the description of the component.",
    attributes:[],
  },
  textColor = '#555',
  titleColor = '#333',
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <div className={styles.accordionContainer}>
      <div className={styles.accordionTitle} style={{ color: titleColor }} onClick={toggleAccordion}>
        Product Detail
        <span className={styles.accordionIcon}>{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && (
        <div className={styles.accordionContent} style={{ color: textColor }}>
          <p>{product?.description}</p>
          {product?.attributes && (
            <div className={styles.attributes}>
              {product?.attributes.map((attr, index) => (
                <div key={index} className={styles.attribute}>
                  <strong>{attr.name}:</strong> {attr.value}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductDescriptionV2;
