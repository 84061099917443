import React, { InputHTMLAttributes, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import styles from './SearchBarV3.module.scss';
import { SearchBarComponentProps } from '../SearchBarComponentProps';

const SearchBarV3: React.FC<SearchBarComponentProps> = ({ onSearch, placeholder, className, wrapperStyle, ...props }) => {
  const [query, setQuery] = useState<string>('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    onSearch && onSearch(e.target.value); // Trigger search on input change
  };

  return (
    <div className={styles.search_bar} style={{...wrapperStyle}}>
      <FaSearch className={styles.search_icon} />
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        className={styles.search_input}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SearchBarV3;