import React from 'react';
import styles from './FeatureSectionV1.module.scss';
import { FaBolt, FaCloud, FaLock } from 'react-icons/fa';
import { FeaturesSectionComponentProps } from '../FeatureSectionComponentProps';
import { getIconComponent } from '../../Incentive/IncentiveEditor/util';
import { defaultFeaturesSectionProps } from '../featureSectionDefaultProps';

const FeaturesSectionV1: React.FC<FeaturesSectionComponentProps> = ({ 
  features = defaultFeaturesSectionProps.features, 
  title = defaultFeaturesSectionProps.title, 
  description = defaultFeaturesSectionProps.description 
}) => {
  return (
    <div className={styles.featureSection}>
      <div className={styles.textContainer}>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className={styles.featuresGrid}>
        {features?.map((feature, index) => (
          <div key={index} className={styles.featureCard}>
            <div className={styles.featureIcon}>{getIconComponent(feature.icon)}</div>
            <h3 className={styles.featureTitle}>{feature.title}</h3>
            <p className={styles.featureDescription}>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturesSectionV1;
