import React, { useMemo, useRef, useState } from "react";
import styles from './TableInteractive.module.scss';
import { FaCheckSquare, FaFilter, FaSquare } from "react-icons/fa";

interface TableInteractiveProps {
    data?: any[];
    labelsFromProps?: string[];
    wrapperStyle?: React.CSSProperties;
    bulkAction?: boolean;
    onSelectItem?: (id: string) => void;
    selectedItems?: Set<string>;
    onActionButtonClick?: (item: any) => void;
    onItemClick?: (id: string) => void;
    actionButton?: { label: string, type: string}
}

const getNestedValue = (obj: any, path: string): any => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

const TableInteractive: React.FC<TableInteractiveProps> = ({ data = [], labelsFromProps, wrapperStyle, bulkAction = false, onSelectItem, onItemClick, selectedItems, onActionButtonClick, actionButton }) => {
    const [sortedData, setSortedData] = useState(data);
    const [selectedLabel, setSelectedLabel] = useState<string | null>(null);
    const counter = useRef(0);

    const labelsFromData = useMemo(() => {
        let labels: string[] = [];
        for (let i = 0; i < data.length; i++) {
            let object = data[i];
            let keys = Object.keys(object);
            for (let j = 0; j < keys.length; j++) {
                if ((!labels.includes(keys[j])) && (typeof object[keys[j]] === "string" || typeof object[keys[j]] === "number")) {
                    labels.push(keys[j]);
                }
            }
        }
        return labels;
    }, [data]);

    const splitStringBySeparator = (str: string): string => {
        let mainLabel = '';
        let separateByPoint = str.split('.');
        if (separateByPoint.length > 1) {
            mainLabel = separateByPoint.slice(1).join(' ');
        } else {
            mainLabel = str;
        }
    
        // Split by underscores and join with spaces
        let words = mainLabel.split('_').join(' ');
    
        // Convert camelCase to separate words
        words = words.replace(/([a-z])([A-Z])/g, '$1 $2');
    
        return words;
    };

    const normalizeLabelsFromProp = (labels: string[]): string[] => {
        return labels.map(label => (label));
    };

    const labels = Array.isArray(labelsFromProps) && labelsFromProps.length > 0
        ? normalizeLabelsFromProp(labelsFromProps).filter(label => !/action|actions/i.test(label))
        : labelsFromData;

    const labelsWithBulkAction = ["select", ...labels];

    const handleItemClick = (label: string) => {
        sortData(label);
    }

    const sortData = (label: string) => {
        setSelectedLabel(label);
        let dataCopy = [...sortedData];
        counter.current++;
        dataCopy.sort((a, b) => {
            if (getNestedValue(a, label) < getNestedValue(b, label)) return counter.current % 2 === 0 ? -1 : 1;
            if (getNestedValue(a, label) > getNestedValue(b, label)) return counter.current % 2 === 0 ? 1 : -1;
            return 0;
        });
        setSortedData(dataCopy);
    }

    const handleSelect = (id: string) => {
        if (onSelectItem) {
            onSelectItem(id);
        }
    };

    const handleActionButtonClick = (item: any) => {
        if (onActionButtonClick) {
            onActionButtonClick(item);
        }
    };

    const getClassNameForValue = (value: string | number | boolean, label: string): string => {
        if (/id$/i.test(label.toLowerCase())) return styles.bold;
        if (typeof value !== "string") return '';
        if (/pending/i.test(value.toLowerCase())) return styles.pending;
        if (/verified|approved|returned/i.test(value.toLowerCase())) return styles.verified;
        if (/canceled|rejected/i.test(value.toLowerCase())) return styles.canceled;
        return '';
    };

    return (
        <div className={styles.table_interactive} style={{ ...wrapperStyle }}>
            <table>
                <thead>
                    <tr>
                        {bulkAction ? labelsWithBulkAction.map((label, index) => (
                            <th key={index} onClick={() => handleItemClick(label)}>
                                <div>{label !== "select" ? <><span>{splitStringBySeparator(label)}</span> <FaFilter className={styles.filter_icon} /></> : null}</div>
                            </th>
                        ))
                            : labels.map((label, index) => (
                                <th key={index} onClick={() => handleItemClick(label)}>
                                    <div>{splitStringBySeparator(label)} <FaFilter className={styles.filter_icon} /></div>
                                </th>
                            ))
                        }
                        { actionButton && <th>Actions</th> }
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((item, index) => (
                        <tr key={index} onClick={()=> onItemClick ? onItemClick(item._id) : null}>
                            {bulkAction && (
                                <td onClick={() => handleSelect(item.id)}>
                                    {selectedItems?.has(item.id) ? <FaCheckSquare /> : <FaSquare />}
                                </td>
                            )}
                            {labels.map((label: string, labelIndex) => {
                                let entry = getNestedValue(item, label.split(' ').join('.'));
                                if (entry !== undefined) {
                                    return (
                                        <td key={labelIndex} className={getClassNameForValue(entry, label)}>{typeof entry === 'object' && entry instanceof Date 
                                            ? entry.toLocaleString().substring(0, 10) // Convert Date object to string (localized)
                                            : String(entry) // Convert other non-string values to string
                                          }</td>
                                    );
                                } else {
                                    return (
                                        <td key={labelIndex}></td>
                                    );
                                }
                            })}
                            {actionButton &&
                                <td>
                                    <button onClick={() => handleActionButtonClick(item)}>{actionButton?.label ? actionButton?.label : 'label'}</button>
                                </td>
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default TableInteractive;
