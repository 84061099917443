import React from 'react'
import styles from '../Component.module.scss';
import CartItemListV1 from '../../UIElements/CartItemList/CartItemListV1/CartItemListV1';
import CartItemListV2 from '../../UIElements/CartItemList/CartItemListV2/CartItemListV2';
import CartItemListV3 from '../../UIElements/CartItemList/CartItemListV3/CartItemListV3';

const CartListItemComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <CartItemListV1/>
        </div>
        <div className={styles.element}>
            <CartItemListV2/>
        </div>
        <div className={styles.element}>
            <CartItemListV3/>
        </div>
    </div>
  )
}

export default CartListItemComponentPage