import React from 'react'
import styles from '../Component.module.scss';
import IncentiveV1 from '../../UIElements/Incentive/IncentiveV1/IncentiveV1';
import IncentiveV2 from '../../UIElements/Incentive/IncentiveV2/IncentiveV2';
import IncentiveV3 from '../../UIElements/Incentive/IncentiveV3/IncentiveV3';
import IncentiveV4 from '../../UIElements/Incentive/IncentiveV4/IncentiveV4';
import IncentiveV5 from '../../UIElements/Incentive/IncentiveV5/IncentiveV5';
import IncentiveV6 from '../../UIElements/Incentive/IncentiveV6/IncentiveV6';
import { defaultIncentiveProps } from '../../UIElements/Incentive/IncentiveDefaultProps';

const IncentiveComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <IncentiveV1 {...defaultIncentiveProps}/>
        </div>
        <div className={styles.element}>
            <IncentiveV2 {...defaultIncentiveProps}/>
        </div>
        <div className={styles.element}>
            <IncentiveV3 {...defaultIncentiveProps}/>
        </div>
        <div className={styles.element}>
            <IncentiveV4 {...defaultIncentiveProps}/>
        </div>
        <div className={styles.element}>
            <IncentiveV5 {...defaultIncentiveProps}/>
        </div>
        <div className={styles.element}>
            <IncentiveV6 {...defaultIncentiveProps}/>
        </div>
    </div>
  )
}

export default IncentiveComponentPage