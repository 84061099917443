import React from 'react';
import styles from './EcommerceTermAndCondition.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store/store';
import { TermOfServiceType } from '../../types/Data/TermOfServiceType';

const EcommerceTermAndCondition: React.FC = () => {
    const termAndCondition = useSelector<RootState>((state) => state.dashboard.termOfService.termOfService) as TermOfServiceType;
    //'test'
    return (

            <div 
                className={styles.termAndConditionPageContainer} 
                dangerouslySetInnerHTML={{ __html: termAndCondition.content }}
            />

    );
}

export default EcommerceTermAndCondition;
