import api from "../config"; // Adjust the path to your API configuration
import { AxiosResponse } from "axios";
import { SocialMediaLinkType } from "../../types/Data/SocialMediaSettingsType";
import { ApiResponse } from "../apiType";

// Create a new social media link
export async function createSocialMediaLink(data: Partial<SocialMediaLinkType>): Promise<AxiosResponse<ApiResponse<SocialMediaLinkType>>> {
    try {
        const response = await api.post('/social-media-links', data);
        return response.data;
    } catch (error) {
        console.error('Error creating social media link:', error);
        throw error;
    }
}

// Get all social media links
export async function getAllSocialMediaLinks(): Promise<AxiosResponse<ApiResponse<SocialMediaLinkType[]>>> {
    try {
        const response = await api.get('/social-media-links');
        return response.data;
    } catch (error) {
        console.error('Error fetching social media links:', error);
        throw error;
    }
}

// Get a social media link by platform
export async function getSocialMediaLinkByPlatform(platform: string): Promise<AxiosResponse<ApiResponse<SocialMediaLinkType>>> {
    try {
        const response = await api.get(`/social-media-links/${platform}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching social media link for platform "${platform}":`, error);
        throw error;
    }
}

// Update a social media link by platform
export async function updateSocialMediaLink(platform: string, data: Partial<SocialMediaLinkType>): Promise<AxiosResponse<ApiResponse<SocialMediaLinkType>>> {
    try {
        const response = await api.put(`/social-media-links/${platform}`, data);
        return response.data;
    } catch (error) {
        console.error(`Error updating social media link for platform "${platform}":`, error);
        throw error;
    }
}

// Delete a social media link by platform
export async function deleteSocialMediaLink(platform: string): Promise<AxiosResponse<ApiResponse<SocialMediaLinkType>>> {
    try {
        const response = await api.delete(`/social-media-links/${platform}`);
        return response.data;
    } catch (error) {
        console.error(`Error deleting social media link for platform "${platform}":`, error);
        throw error;
    }
}
