import React from 'react'
import styles from '../Component.module.scss';
import ProductInfoCardV1 from '../../components/ProductInfoCard/ProductInfoCardV1/ProductInfoCardV1';
import ProductInfoCardV2 from '../../components/ProductInfoCard/ProductInfoCardV2/ProductInfoCardV2';
import ProductInfoCardV3 from '../../components/ProductInfoCard/ProductInfoCardV3/ProductInfoCardV3';
import ProductInfoCardV4 from '../../components/ProductInfoCard/ProductInfoCardV4/ProductInfoCard';

const ProductInfoCardComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <ProductInfoCardV1/>
        </div>
        <div className={styles.element}>
            <ProductInfoCardV2/>
        </div>
        <div className={styles.element}>
            <ProductInfoCardV3/>
        </div>
        <div className={styles.element}>
            <ProductInfoCardV4/>
        </div>
    </div>
  )
}

export default ProductInfoCardComponentPage