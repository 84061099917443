import React from 'react'
import styles from '../Component.module.scss';
import ContactFormV1 from '../../UIElements/ContactForm/ContactFormV1/ContactFormV1';
import ContactFormV2 from '../../UIElements/ContactForm/ContactFormV2/ContactFormV2';
import ContactFormV3 from '../../UIElements/ContactForm/ContactFormV3/ContactFormV3';

const ContactFormComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <ContactFormV1/>
        </div>
        <div className={styles.element}>
            <ContactFormV2/>
        </div>
        <div className={styles.element}>
            <ContactFormV3/>
        </div>
    </div>
  )
}

export default ContactFormComponentPage