import CategoryFilterV1 from "./CategoryFilter/CategoryFilterV1/CategoryFilterV1";
import ColorFilterV1 from "./ColorFilter/ColorFilterV1/ColorFilterV1";
import AvailabilityFilterV1 from "./AvailabilityFilter/AvailabilityFilterV1/AvailabilityFilterV1";
import RatingFilterV1 from "./RatingFilter/RatingFilterV1/RatingFilterV1";
import PriceRangeFilterV1 from "./PriceRangeFilter/PriceRangeFilterV1/PriceRangeFilterV1";
import DefaultFilterV1 from "./DefaultFilter/DefaultFilterV1";


interface FilterComponentProps<T> {
    label?:string;
    options?: T[];
    selectedOptions?: T[];
    onSelect?: (option: T) => void;
}

// Specific filter component interfaces extending the base interface
interface BrandFilterComponentProps extends FilterComponentProps<string> {}

interface ColorFilterComponentProps extends FilterComponentProps<string> {} // Hex color codes or color names

interface MaterialFilterComponentProps extends FilterComponentProps<string> {}

interface SizeFilterComponentProps extends FilterComponentProps<string> {}

interface CategoryComponentFilterProps extends FilterComponentProps<string> {}

interface AvailabilityComponentFilterProps extends FilterComponentProps<string> {}

// For PriceRange, we need a slightly different structure as it deals with numeric ranges
interface PriceRangeFilterComponentProps {
    minPrice?: number;
    maxPrice?: number;
    onPriceChange?: (min: number, max: number) => void;
}

// For Rating, we need a specific structure as it deals with numbers
interface RatingFilterComponentProps {
    maxRating?: number;
    selectedRating?: number;
    onRatingSelect?: (rating: number) => void;
}


export type {
    FilterComponentProps,
    BrandFilterComponentProps,
    ColorFilterComponentProps,
    MaterialFilterComponentProps,
    SizeFilterComponentProps,
    PriceRangeFilterComponentProps,
    RatingFilterComponentProps,
    CategoryComponentFilterProps,
    AvailabilityComponentFilterProps
}

enum DefaultFilterVersions {
    V1 = "v1"
}

const DefaultFilterMapping:Record<DefaultFilterVersions, React.FC<any>> = {
    [DefaultFilterVersions.V1]: DefaultFilterV1, 
};


enum CategoryFilterVersions {
    V1 = "v1",
};

const CategoryFilterMapping:Record<CategoryFilterVersions, React.FC<any>> = {
    [CategoryFilterVersions.V1]: CategoryFilterV1, 
};

enum BrandFilterVersions {
    V1 = "v1",
};

enum ColorFilterVersions {
    V1 = "v1",
};

const ColorFilterMapping:Record<ColorFilterVersions, React.FC<any>> = {
    [ColorFilterVersions.V1]: ColorFilterV1, 
};

enum MaterialFilterVersions {
    V1 = "v1",
};

enum SizeFilterVersions {
    V1 = "v1",
};

enum AvailabilityFilterVersions {
    V1 = "v1",
};

const AvailabilityFilterMapping:Record<AvailabilityFilterVersions, React.FC<any>> = {
    [AvailabilityFilterVersions.V1]: AvailabilityFilterV1, 
};

enum RatingFilterVersions {
    V1 = "v1",
};

const RatingFilterMapping:Record<RatingFilterVersions, React.FC<any>> = {
    [RatingFilterVersions.V1]: RatingFilterV1, 
};

enum PriceRangeFilterVersions {
    V1 = "v1",
}

const PriceRangeFilterMapping:Record<PriceRangeFilterVersions, React.FC<any>> = {
    [PriceRangeFilterVersions.V1]: PriceRangeFilterV1, 
};

export {
    CategoryFilterVersions,
    CategoryFilterMapping,
    BrandFilterVersions,
    ColorFilterVersions,
    ColorFilterMapping,
    MaterialFilterVersions,
    SizeFilterVersions,
    AvailabilityFilterVersions,
    AvailabilityFilterMapping,
    RatingFilterVersions,
    RatingFilterMapping,
    PriceRangeFilterVersions,
    PriceRangeFilterMapping,
    DefaultFilterMapping,
    DefaultFilterVersions
}