import React from 'react';
import styles from './TicketMainInfo.module.scss';
import { FiTag, FiMail, FiClock, FiAlertTriangle, FiUser } from 'react-icons/fi'; // Icons for a modern look
import { SupportTicketType } from '../../../../../types/Data/SupportTicketType';

interface TicketCardProps {
    ticket: SupportTicketType;
}

const TicketMainInfo: React.FC<TicketCardProps> = ({ ticket }) => {
    return (
        <div className={styles.ticketCard}>
            <div className={styles.ticketHeader}>
                <h3>{ticket.subject}</h3>
                <span className={styles.status}>{ticket.status}</span>
            </div>
            <div className={styles.ticketBody}>
                <p><FiMail /> {ticket.email}</p>
                <p><FiClock /> Created: {ticket.createdAt ? new Date(ticket.createdAt).toLocaleDateString() : 'N/A'}</p>
                {ticket.updatedAt && <p>Last Updated: {new Date(ticket.updatedAt).toLocaleDateString()}</p>}
                <p><FiTag /> Category: {ticket.category || 'N/A'}</p>
                <p><FiAlertTriangle /> Priority: <span className={styles[ticket?.priority?.toLowerCase() || 'low']}>{ticket.priority}</span></p>
            </div>
            <div className={styles.ticketFooter}>
                {ticket.assignedTo && <p><FiUser /> Assigned to: {ticket.assignedTo}</p>}
                {ticket.tags && ticket.tags.length > 0 && <div className={styles.tags}>
                    {ticket.tags.map((tag, index) => (
                        <span key={index} className={styles.tag}>{tag}</span>
                    ))}
                </div>}
            </div>
        </div>
    );
};

export default TicketMainInfo;
