import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './BlogPostListPage.module.scss';
import { AppDispatch, RootState } from '../../Store/store';
import DashboardHeader from '../../components/Header/DashboardHeader/DashboardHeader';
import Button5 from '../../components/Button/ButtonV5/ButtonV5';
import { useNavigate } from 'react-router-dom';
import BlogPostCard from './BlogPostCard/BlogPostCard';
import BlogAnalyticsSection from './BlogPostAnalytics/BlogAnalyticsSection';
import { BlogPostType } from '../../types/Data/BlogPostType';
import { dispatchLoadBlogPost, fetchAllBlogPosts } from '../DashboardState/blogPost/blogPostSlice';
import LoadingIndicatorV1 from '../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';
import exampleBlogPost from '../DashboardState/blogPost/exampleBlogPost';

const BlogPostListPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  const { blogPosts, loading, error } = useSelector((state: RootState) => state.dashboard.blogPosts);

  /*
  useEffect(() => {
    dispatch(fetchAllBlogPosts({}))
  }, [])
  */

  const handleDelete = (id: string) => {
    console.log(`Delete post ${id}`);
  };

  const handleAdd = () => {
    navigate(`/dashboard/blogPost/edit`)
  };

  if (loading) return <LoadingIndicatorV1/>
  if (error) return <ErrorBannerV1 message={error}/>
  return (
    <div className={styles.blog_post_page}>
        <DashboardHeader title="Blog Posts"/>
        <BlogAnalyticsSection totalBlogs={(blogPosts ?? []).length} mostRecentPost={blogPosts ? blogPosts[0] : {} }/>
        <Button5 title='Add Blog' style={{minWidth:'40%', marginTop: 15, marginBottom: 15}} onClick={handleAdd}/>
        <div className={styles.blog_post_list}>
            {(blogPosts ?? []).map(post => (
            <BlogPostCard 
              key={post._id} 
              post={post} 
              onDelete={handleDelete} 
              />
            ))}
        </div>
    </div>
  );
};

export default BlogPostListPage;
