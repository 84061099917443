import React from 'react';
import styles from './FooterLinks.module.scss';
import { FooterLink } from '../../FooterComponentProps';


interface FooterLinksPorps {
    footerLinks: FooterLink[]
}

const FooterLinks: React.FC<FooterLinksPorps> = ({footerLinks}) => {

    return (
        <div className={styles.footerLinksContainer}>
            <div className={styles.footerLinksList}>
            {footerLinks.map((footerLink: FooterLink, index: number) => {
                return (
                    <a key={index} href={footerLink.url} className={styles.footerLink}>{footerLink.title}</a>
                )
            })}
            </div>
        </div>
    )
}

export default FooterLinks