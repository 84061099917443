import React from 'react';
import styles from './CompanyOverviewV1.module.scss';
import { defaultCompanyOverviewProps } from '../compagnieOverviewDefaultProps';
import { CompanyOverviewProps } from '../CompanyOverviewComponentProps';

const CompanyOverviewV1: React.FC<CompanyOverviewProps> = ({
  title = defaultCompanyOverviewProps.title,
  description = defaultCompanyOverviewProps.description,
  imageUrl = defaultCompanyOverviewProps.imageUrl,
  missionStatement = defaultCompanyOverviewProps.missionStatement
}) => {
  return (
    <div className={styles.companyOverview}>
      <img src={imageUrl} alt={title} className={styles.image} />
      <div className={styles.content}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.description}>{description}</p>
        {missionStatement && <blockquote className={styles.mission}>{missionStatement}</blockquote>}
      </div>
    </div>
  );
};

export default CompanyOverviewV1;
