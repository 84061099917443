import React from 'react';
import styles from './ProductReviewV4.module.scss';
import { ProductReviewComponentProps } from '../ProductReviewComponentProps';
import { defaultProductReviewsProps } from '../defaultProductReviewProps';
import { FaStar } from 'react-icons/fa';

const ProductReviewV4: React.FC<ProductReviewComponentProps> = ({ reviews = defaultProductReviewsProps }) => {
  return (
    <div className={styles.reviewContainer}>
      <h2 className={styles.title}>Reviews</h2>
      <p className={styles.description}>Some of our customer reviews</p>
      <div className={styles.cardList}>
        {reviews.map((review, index) => {
          return (
            <div key={index} className={styles.reviewCard}>
              <div className={styles.reviewCardHeader}>
                <img 
                  src={'https://via.placeholder.com/50'}
                  alt={`${review.userName}'s avatar`} 
                  className={styles.reviewCardAvatar} 
                />
                <div>
                  <h3 className={styles.reviewCardUser}>{review.userName}</h3>
                  <div className={styles.reviewCardRating}>
                    {[...Array(5)].map((_, i) => (
                      <FaStar 
                        key={i} 
                        color={i < review.rating! ? '#ffc107' : '#e4e5e9'} 
                      />
                    ))}
                  </div>
                </div>
              </div>
              <p className={styles.reviewCardText}>{review.content}</p>
              <span className={styles.date}>{review?.reviewDate ? new Date(review.reviewDate).toLocaleDateString() : 'No date available'}</span>
              </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductReviewV4;
