import React from 'react';
import styles from './UiElementEditor.module.scss';
import { BaseElementType } from '../../types/Data/BaseElementType';
import Select4 from '../../components/Select/Select4/Select4';
import SwitchV1 from '../../components/Switch/SwitchV1/SwitchV1';
import InputV4 from '../../components/Input/InputText/InputV4/InputV4';


interface SocialMediaEditorProps {
  element?: BaseElementType<any>;
  elementVersions?: {label:string, value:string}[];
  onElementUpdate?: (key: string, value:any) => void;
  onVisibilitytUpdate?: () => void;
}

const UIElementsMainSettings: React.FC<SocialMediaEditorProps> = ({ element, elementVersions=[], onElementUpdate=()=>null, onVisibilitytUpdate=()=>null }) => {
  return (
        <div className={styles.mainSettings}>
          <Select4
            label="Element version"
            value={element?.version}
            options={elementVersions}
            onChange={(value) => onElementUpdate('version', value as string)}
          />
          
          
          {/* Position and Span Modifiers */}
          <InputV4
            label="Row"
            type="number"
            value={element?.position?.row || 1}
            onChange={(e) => onElementUpdate('position', { ...element?.position, row: Number(e.target.value) })}
          />
          <InputV4
            label="Column"
            type="number"
            value={element?.position?.column || 1}
            onChange={(e) => onElementUpdate('position', { ...element?.position, column: Number(e.target.value) })}
          />
          <InputV4
            label="Span"
            type="number"
            value={element?.position?.span || 12}  // Default to 12-column span
            onChange={(e) => onElementUpdate('position', { ...element?.position, span: Number(e.target.value) })}
          />
          <SwitchV1
            label="Element visibility"
            wrapperStyle={{ marginTop: 16 }}
            isChecked={element?.isVisible}
            onToggle={onVisibilitytUpdate}
          />
        </div>
  );
};

export default UIElementsMainSettings;






