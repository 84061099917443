import React, { useState } from "react";
import styles from './CancelledItemsTable.module.scss';
import { CancellationItem } from "../../../../types/Data/CancellationType";

interface CancelledItemsTableProps {
  items: CancellationItem[];
  onRefundSelectionChange: (selectedItems: { id: string; quantityToRefund: number }[]) => void; // Callback to handle refund selections
}

const CancelledItemsTable: React.FC<CancelledItemsTableProps> = ({ items, onRefundSelectionChange }) => {
  const [selectedItems, setSelectedItems] = useState<{ id: string; quantityToRefund: number }[]>([]);

  // Handle item selection
  const handleItemSelection = (itemId: string, isSelected: boolean, quantityToRefund: number) => {
    let updatedSelectedItems = [...selectedItems];
    
    if (isSelected) {
      updatedSelectedItems = [...updatedSelectedItems, { id: itemId, quantityToRefund }];
    } else {
      updatedSelectedItems = updatedSelectedItems.filter((item) => item.id !== itemId);
    }

    setSelectedItems(updatedSelectedItems);
    onRefundSelectionChange(updatedSelectedItems);
  };

  // Handle quantity change for a selected item
  const handleQuantityChange = (itemId: string, quantity: number) => {
    const updatedSelectedItems = selectedItems.map(item =>
      item.id === itemId ? { ...item, quantityToRefund: quantity } : item
    );
    
    setSelectedItems(updatedSelectedItems);
    onRefundSelectionChange(updatedSelectedItems);
  };

  return (
    <div className={styles.items}>
      <h2>Items to be Refunded</h2>
      <table>
        <thead>
          <tr>
            <th>Select</th>
            <th>Item</th>
            <th>Quantity</th>
            <th>Quantity to Refund</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => {
            const isSelected = selectedItems.some(selected => selected.id === item.product);
            const selectedQuantity = selectedItems.find(selected => selected.id === item.product)?.quantityToRefund || 1;

            return (
              <tr key={item.product as string}>
                <td>
                  <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={(e) => handleItemSelection(item.product as string, e.target.checked, selectedQuantity)}
                  />
                </td>
                <td>{item.productName}</td>
                <td>{item.quantity}</td>
                <td>
                  <select
                    value={selectedQuantity}
                    onChange={(e) => handleQuantityChange(item.product as string, parseInt(e.target.value))}
                    disabled={!isSelected}
                  >
                    {Array.from({ length: item.quantity }, (_, i) => i + 1).map((q) => (
                      <option key={q} value={q}>{q}</option>
                    ))}
                  </select>
                </td>
                <td>${item.price.toFixed(2)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CancelledItemsTable;
