import React from 'react';
import styles from './AvatarV3.module.scss';
import { AvatarComponentProps } from '../AvatarComponentProps';

const AvatarV3:React.FC<AvatarComponentProps> = ({ name, imageUrl, description, status }) => {
  return (
    <div className={styles.avatar_container}>
      <div className={styles.avatar_image_container}>
        <img src={imageUrl} alt={`${name}'s avatar`} className={styles.avatar_image} />
        { status && <span className={`${styles.status_icon} ${styles[status]}`}></span> }
      </div>
      <div className={styles.avatar_info}>
        <div className={styles.avatar_name}>{name}</div>
        <div className={styles.avatar_description}>{description}</div>
      </div>
    </div>
  );
};

export default AvatarV3;