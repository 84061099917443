import { QAListComponentProps } from './QAListComponentProps';

export const defaultQAListProps: QAListComponentProps = {
    items: [
      {
        question: "What is the return policy?",
        answer: "Our return policy allows you to return items within 30 days of purchase. Please make sure the items are in their original condition."
      },
      {
        question: "How can I track my order?",
        answer: "Once your order has shipped, you will receive a confirmation email with a tracking number. You can use this number to track your order on our website."
      },
      {
        question: "Do you offer international shipping?",
        answer: "Yes, we offer international shipping to many countries. Please check our shipping policy for more details."
      }
    ]
  };