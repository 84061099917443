import React from 'react';
import styles from './ShippingInformationFormV2.module.scss';
import { ShippingInformationComponentProps } from '../ShippingInformationFormComponentProps';

const ShippingInformationFormV2: React.FC<ShippingInformationComponentProps> = ({
  fullName = "",
  address = "",
  city = "",
  state = "",
  zipCode = "",
  country = "",
  onInputChange = () => null,
  onFormSubmit = () => null,
}) => {
  return (
    <div className={styles.shippingCard}>
      <h2>Shipping Details</h2>
      <form onSubmit={onFormSubmit}>
        <div className={styles.inputGroup}>
          <input
            type="text"
            placeholder="Full Name"
            value={fullName}
            onChange={(e) => onInputChange('fullName', e.target.value)}
          />
          <input
            type="text"
            placeholder="Address"
            value={address}
            onChange={(e) => onInputChange('address', e.target.value)}
          />
        </div>
        <div className={styles.inputGroup}>
          <input
            type="text"
            placeholder="City"
            value={city}
            onChange={(e) => onInputChange('city', e.target.value)}
          />
          <input
            type="text"
            placeholder="State"
            value={state}
            onChange={(e) => onInputChange('state', e.target.value)}
          />
        </div>
        <div className={styles.inputGroup}>
          <input
            type="text"
            placeholder="ZIP Code"
            value={zipCode}
            onChange={(e) => onInputChange('zipCode', e.target.value)}
          />
          <input
            type="text"
            placeholder="Country"
            value={country}
            onChange={(e) => onInputChange('country', e.target.value)}
          />
        </div>
        <button type="submit">Proceed</button>
      </form>
    </div>
  );
};

export default ShippingInformationFormV2;
