import { TrustBadgeIconsComponentProps } from './TrustBadgeIconsComponentProps';

const defaultTrustBadgeItems: TrustBadgeIconsComponentProps = {
    items:[
    {
      title: 'Fast Shipping',
      description: 'We offer fast and reliable shipping.',
      icon: 'FaShippingFast',
    },
    {
      title: 'Secure Payment',
      description: 'Your payments are secured with SSL encryption.',
      icon: 'FaLock',
    },
    {
      title: 'Quality Guarantee',
      description: 'We guarantee the best quality products.',
      icon: 'FaRegCheckCircle',
    },
  ]
};

export {
    defaultTrustBadgeItems
}