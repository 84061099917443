import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RefundProcessType } from '../../../types/Data/RefundProcessType';
import { exampleRefunds } from './exampleRefund';
import {
    createRefundProcess,
    getAllRefundProcesses,
    getRefundProcessById,
    updateRefundProcessById,
    deleteRefundProcessById,
    updateRefundProcessStatus
} from '../../../api/refundProcess/refundProcessApi';

interface RefundState {
  refundProcess: RefundProcessType[];
  loading: boolean;
  error: string | null;
}

const initialState: RefundState = {
  refundProcess: [...exampleRefunds],
  loading: false,
  error: null,
};

// Async Thunks
export const fetchAllRefundProcesses = createAsyncThunk(
  'refund/fetchAllRefundProcesses',
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await getAllRefundProcesses(params);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchRefundProcessById = createAsyncThunk(
  'refund/fetchRefundProcessById',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getRefundProcessById(id);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const createNewRefundProcess = createAsyncThunk(
  'refund/createNewRefundProcess',
  async (newRefundProcess: Partial<RefundProcessType>, { rejectWithValue }) => {
    try {
      const response = await createRefundProcess(newRefundProcess);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateExistingRefundProcess = createAsyncThunk(
  'refund/updateRefundProcess',
  async ({ id, updateData }: { id: string, updateData: Partial<RefundProcessType> }, { rejectWithValue }) => {
    try {
      const response = await updateRefundProcessById(id, updateData);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteRefundProcess = createAsyncThunk(
  'refund/deleteRefundProcess',
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteRefundProcessById(id);
      return id;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateRefundProcessStatusThunk = createAsyncThunk(
  'refund/updateRefundProcessStatus',
  async ({ id, status }: { id: string, status: string }, { rejectWithValue }) => {
    try {
      const response = await updateRefundProcessStatus(id, status);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const refundProcessSlice = createSlice({
  name: 'refund',
  initialState,
  reducers: {
    dispatchAddRefundProcess(state, action: PayloadAction<RefundProcessType>) {
      state.refundProcess.push(action.payload);
    },
    dispatchUpdateRefundProcess(state, action: PayloadAction<RefundProcessType>) {
      const index = state.refundProcess.findIndex((refund) => refund._id === action.payload._id);
      if (index !== -1) {
        state.refundProcess[index] = action.payload;
      }
    },
    dispatchDeleteRefundProcesst(state, action: PayloadAction<string>) {
      state.refundProcess = state.refundProcess.filter((refund) => refund._id !== action.payload);
    },
    dispatchLoadRefundProcess(state, action: PayloadAction<RefundProcessType[]>) {
      state.refundProcess = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRefundProcesses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllRefundProcesses.fulfilled, (state, action: PayloadAction<{refundProcesses:RefundProcessType[], total: number, page: number, pages: number}>) => {
        state.loading = false;
        state.refundProcess = action.payload.refundProcesses;
      })
      .addCase(fetchAllRefundProcesses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchRefundProcessById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRefundProcessById.fulfilled, (state, action: PayloadAction<RefundProcessType>) => {
        state.loading = false;
        const index = state.refundProcess.findIndex((refund) => refund._id === action.payload._id);
        if (index !== -1) {
          state.refundProcess[index] = action.payload;
        } else {
          state.refundProcess.push(action.payload);
        }
      })
      .addCase(fetchRefundProcessById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createNewRefundProcess.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewRefundProcess.fulfilled, (state, action: PayloadAction<RefundProcessType>) => {
        state.loading = false;
        state.refundProcess.push(action.payload);
      })
      .addCase(createNewRefundProcess.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateExistingRefundProcess.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateExistingRefundProcess.fulfilled, (state, action: PayloadAction<RefundProcessType>) => {
        state.loading = false;
        const index = state.refundProcess.findIndex((refund) => refund._id === action.payload._id);
        if (index !== -1) {
          state.refundProcess[index] = action.payload;
        }
      })
      .addCase(updateExistingRefundProcess.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteRefundProcess.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteRefundProcess.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.refundProcess = state.refundProcess.filter((refund) => refund._id !== action.payload);
      })
      .addCase(deleteRefundProcess.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateRefundProcessStatusThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateRefundProcessStatusThunk.fulfilled, (state, action: PayloadAction<RefundProcessType>) => {
        state.loading = false;
        const index = state.refundProcess.findIndex((refund) => refund._id === action.payload._id);
        if (index !== -1) {
          state.refundProcess[index].refundStatus = action.payload.refundStatus;
        }
      })
      .addCase(updateRefundProcessStatusThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const {
  dispatchAddRefundProcess,
  dispatchUpdateRefundProcess,
  dispatchDeleteRefundProcesst,
  dispatchLoadRefundProcess,
} = refundProcessSlice.actions;

export default refundProcessSlice.reducer;
