import { ReviewType, ReviewStatus } from "../../../types/Data/ReviewType";

const reviewsData: ReviewType[] = [
  {
    _id: 'r1',
    productId: '1',
    userId: 'user1',
    userName: 'John Doe',
    rating: 5,
    title: 'Amazing phone!',
    content: 'Amazing phone with great features!',
    reviewDate: '2023-06-01',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: true,
    images: ['https://via.placeholder.com/100'],
    videos: [],
    adminResponse: 'Thank you for your feedback!',
    flagged: false,
  },
  {
    _id: 'r2',
    productId: '1',
    userId: 'user2',
    userName: 'Jane Smith',
    rating: 4,
    title: 'Good, but a bit expensive',
    content: 'Expensive but worth it.',
    reviewDate: '2023-06-02',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: true,
    images: [],
    videos: [],
    adminResponse: null,
    flagged: false,
  },
  {
    _id: 'r3',
    productId: '2',
    userId: 'user3',
    userName: 'Alice Johnson',
    rating: 5,
    title: 'Best Galaxy phone yet!',
    content: 'The best Galaxy phone yet!',
    reviewDate: '2023-06-03',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: false,
    images: [],
    videos: [],
    adminResponse: null,
    flagged: false,
  },
  {
    _id: 'r4',
    productId: '2',
    userId: 'user4',
    userName: 'Bob Brown',
    rating: 4,
    title: 'Great camera and performance',
    content: 'Great camera and performance.',
    reviewDate: '2023-06-04',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: true,
    images: [],
    videos: [],
    adminResponse: 'Glad you enjoyed the camera!',
    flagged: false,
  },
  {
    _id: 'r5',
    productId: '3',
    userId: 'user5',
    userName: 'Charlie Davis',
    rating: 5,
    title: 'Best noise-canceling headphones!',
    content: 'Best noise canceling headphones on the market!',
    reviewDate: '2023-06-05',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: false,
    images: ['https://via.placeholder.com/100'],
    videos: [],
    adminResponse: null,
    flagged: false,
  },
  {
    _id: 'r6',
    productId: '3',
    userId: 'user6',
    userName: 'Diana Evans',
    rating: 4.5,
    title: 'Comfortable and great sound quality',
    content: 'Comfortable and great sound quality.',
    reviewDate: '2023-06-06',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: true,
    images: [],
    videos: [],
    adminResponse: 'We appreciate your feedback!',
    flagged: false,
  },
  {
    _id: 'r7',
    productId: '4',
    userId: 'user7',
    userName: 'Edward Fisher',
    rating: 5,
    title: 'The best ultrabook!',
    content: 'The best ultrabook you can buy!',
    reviewDate: '2023-06-07',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: true,
    images: [],
    videos: [],
    adminResponse: null,
    flagged: false,
  },
  {
    _id: 'r8',
    productId: '4',
    userId: 'user8',
    userName: 'Fiona Green',
    rating: 4.5,
    title: 'Sleek design and powerful performance',
    content: 'Sleek design and powerful performance.',
    reviewDate: '2023-06-08',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: true,
    images: [],
    videos: [],
    adminResponse: null,
    flagged: false,
  },
  {
    _id: 'r9',
    productId: '5',
    userId: 'user9',
    userName: 'George Harris',
    rating: 5,
    title: 'Super comfortable and stylish',
    content: 'Super comfortable and stylish!',
    reviewDate: '2023-06-09',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: true,
    images: [],
    videos: [],
    adminResponse: 'Glad you liked them!',
    flagged: false,
  },
  {
    _id: 'r10',
    productId: '5',
    userId: 'user10',
    userName: 'Helen Irving',
    rating: 4,
    title: 'Great for everyday wear',
    content: 'Great for everyday wear.',
    reviewDate: '2023-06-10',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: false,
    images: [],
    videos: [],
    adminResponse: null,
    flagged: false,
  },
  {
    _id: 'r11',
    productId: '6',
    userId: 'user11',
    userName: 'Ian Jacobs',
    rating: 5,
    title: 'Incredible image quality and performance',
    content: 'Incredible image quality and performance!',
    reviewDate: '2023-06-11',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: true,
    images: [],
    videos: [],
    adminResponse: null,
    flagged: false,
  },
  {
    _id: 'r12',
    productId: '6',
    userId: 'user12',
    userName: 'Jane King',
    rating: 4.5,
    title: 'Best mirrorless camera',
    content: 'Best mirrorless camera on the market.',
    reviewDate: '2023-06-12',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: false,
    images: [],
    videos: [],
    adminResponse: 'Thank you for the great review!',
    flagged: false,
  },
  {
    _id: 'r13',
    productId: '7',
    userId: 'user13',
    userName: 'Kyle Lewis',
    rating: 5,
    title: 'Unmatched noise cancellation',
    content: 'Unmatched noise cancellation and sound quality!',
    reviewDate: '2023-06-13',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: true,
    images: [],
    videos: [],
    adminResponse: null,
    flagged: false,
  },
  {
    _id: 'r14',
    productId: '7',
    userId: 'user14',
    userName: 'Laura Miller',
    rating: 4.5,
    title: 'Extremely comfortable',
    content: 'Extremely comfortable for long listening sessions.',
    reviewDate: '2023-06-14',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: true,
    images: [],
    videos: [],
    adminResponse: 'Thank you for your feedback!',
    flagged: false,
  },
  {
    _id: 'r15',
    productId: '8',
    userId: 'user15',
    userName: 'Michael Nelson',
    rating: 5,
    title: 'The best laptop for creatives',
    content: 'The best laptop for creative professionals!',
    reviewDate: '2023-06-15',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: true,
    images: [],
    videos: [],
    adminResponse: 'We appreciate your feedback!',
    flagged: false,
  },
  {
    _id: 'r16',
    productId: '8',
    userId: 'user16',
    userName: 'Nina Olson',
    rating: 4.5,
    title: 'M1 chip is amazing',
    content: 'M1 chip is a game changer.',
    reviewDate: '2023-06-16',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: true,
    images: [],
    videos: [],
    adminResponse: null,
    flagged: false,
  },
  {
    _id: 'r17',
    productId: '9',
    userId: 'user17',
    userName: 'Oscar Perez',
    rating: 5,
    title: 'Great for tracking workouts',
    content: 'Great for tracking my workouts!',
    reviewDate: '2023-06-17',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: true,
    images: [],
    videos: [],
    adminResponse: 'Thank you for your feedback!',
    flagged: false,
  },
  {
    _id: 'r18',
    productId: '9',
    userId: 'user18',
    userName: 'Paula Quinn',
    rating: 4,
    title: 'Useful features and good battery life',
    content: 'Useful features and good battery life.',
    reviewDate: '2023-06-18',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: true,
    images: [],
    videos: [],
    adminResponse: null,
    flagged: false,
  },
  {
    _id: 'r19',
    productId: '10',
    userId: 'user19',
    userName: 'Quincy Ross',
    rating: 5,
    title: 'Great smart speaker',
    content: 'Great smart speaker for the price!',
    reviewDate: '2023-06-19',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: false,
    images: [],
    videos: [],
    adminResponse: null,
    flagged: false,
  },
  {
    _id: 'r20',
    productId: '10',
    userId: 'user20',
    userName: 'Rachel Smith',
    rating: 4,
    title: 'Works well with smart home devices',
    content: 'Works well with my smart home devices.',
    reviewDate: '2023-06-20',
    status: ReviewStatus.APPROVED,
    isVerifiedPurchase: false,
    images: [],
    videos: [],
    adminResponse: null,
    flagged: false,
  }
];

export default reviewsData;
