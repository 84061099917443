import React, { useState } from "react";
import styles from "./OrderAdress.module.scss"; // Assuming you use CSS Modules for styling
import { AddressType } from "../../../../types/Data/AdressType";

interface OrderAdressProps {
    shippingAddress: Partial<AddressType>;
    billingAddress: Partial<AddressType>;
    onSaveShippingAddress?: (updatedAddress: Partial<AddressType>) => void; // Save handler for shipping address
    onSaveBillingAddress?: (updatedAddress: Partial<AddressType>) => void;  // Save handler for billing address
}

const OrderAdress: React.FC<OrderAdressProps> = ({ 
    shippingAddress, 
    billingAddress, 
    onSaveShippingAddress, 
    onSaveBillingAddress 
}) => {
    const [isEditingShipping, setIsEditingShipping] = useState(false);
    const [isEditingBilling, setIsEditingBilling] = useState(false);

    // Local state to manage form inputs for both addresses
    const [shippingForm, setShippingForm] = useState(shippingAddress);
    const [billingForm, setBillingForm] = useState(billingAddress);

    // Handlers for input changes
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, setForm: React.Dispatch<React.SetStateAction<Partial<AddressType>>>) => {
        const { name, value } = e.target;
        setForm(prev => ({ ...prev, [name]: value }));
    };

    // Save handlers
    const handleSaveShipping = () => {
        onSaveShippingAddress && onSaveShippingAddress(shippingForm);
        setIsEditingShipping(false);
    };

    const handleSaveBilling = () => {
        onSaveBillingAddress && onSaveBillingAddress(billingForm);
        setIsEditingBilling(false);
    };

    return (
        <div className={styles.orderCustomerInfo}>
            <h2>Order Adress</h2>
            
            <hr className={styles.separator} />
            
            <div className={styles.section}>
                <div className={styles.adressHeader}>
                    <h4>Shipping Address</h4>
                    {isEditingShipping ? (
                            <button className={styles.saveButton} onClick={handleSaveShipping}>Save</button>
                        ) : (
                            <button className={styles.editButton} onClick={() => setIsEditingShipping(true)}>Edit</button>
                        )}
                </div>
                {isEditingShipping ? (
                    <div className={styles.addressForm}>
                        <input 
                            type="text" 
                            name="street" 
                            value={shippingForm.street || ''} 
                            onChange={(e) => handleInputChange(e, setShippingForm)} 
                            placeholder="Street"
                            className={styles.inputField}
                        />
                        <input 
                            type="text" 
                            name="city" 
                            value={shippingForm.city || ''} 
                            onChange={(e) => handleInputChange(e, setShippingForm)} 
                            placeholder="City"
                            className={styles.inputField}
                        />
                        <input 
                            type="text" 
                            name="state" 
                            value={shippingForm.state || ''} 
                            onChange={(e) => handleInputChange(e, setShippingForm)} 
                            placeholder="State"
                            className={styles.inputField}
                        />
                        <input 
                            type="text" 
                            name="postalCode" 
                            value={shippingForm.postalCode || ''} 
                            onChange={(e) => handleInputChange(e, setShippingForm)} 
                            placeholder="Postal Code"
                            className={styles.inputField}
                        />
                        <input 
                            type="text" 
                            name="country" 
                            value={shippingForm.country || ''} 
                            onChange={(e) => handleInputChange(e, setShippingForm)} 
                            placeholder="Country"
                            className={styles.inputField}
                        />
                    </div>
                ) : (
                    <p className={styles.address}>
                        {shippingAddress?.street}, {shippingAddress?.city}, {shippingAddress?.state}, {shippingAddress?.postalCode}, {shippingAddress?.country}
                    </p>
                )}
            </div>
            
            <hr className={styles.separator} />
            
            <div className={styles.section}>
                <div className={styles.adressHeader}>
                    <h4>Billing Address</h4>
                    {isEditingBilling ? (
                            <button className={styles.saveButton} onClick={handleSaveBilling}>Save</button>
                        ) : (
                            <button className={styles.editButton} onClick={() => setIsEditingBilling(true)}>Edit</button>
                    )}
                </div>
                {isEditingBilling ? (
                    <div className={styles.addressForm}>
                        <input 
                            type="text" 
                            name="street" 
                            value={billingForm.street || ''} 
                            onChange={(e) => handleInputChange(e, setBillingForm)} 
                            placeholder="Street"
                            className={styles.inputField}
                        />
                        <input 
                            type="text" 
                            name="city" 
                            value={billingForm.city || ''} 
                            onChange={(e) => handleInputChange(e, setBillingForm)} 
                            placeholder="City"
                            className={styles.inputField}
                        />
                        <input 
                            type="text" 
                            name="state" 
                            value={billingForm.state || ''} 
                            onChange={(e) => handleInputChange(e, setBillingForm)} 
                            placeholder="State"
                            className={styles.inputField}
                        />
                        <input 
                            type="text" 
                            name="postalCode" 
                            value={billingForm.postalCode || ''} 
                            onChange={(e) => handleInputChange(e, setBillingForm)} 
                            placeholder="Postal Code"
                            className={styles.inputField}
                        />
                        <input 
                            type="text" 
                            name="country" 
                            value={billingForm.country || ''} 
                            onChange={(e) => handleInputChange(e, setBillingForm)} 
                            placeholder="Country"
                            className={styles.inputField}
                        />
                    </div>
                ) : (
                    <p className={styles.address}>
                        {billingAddress?.street}, {billingAddress?.city}, {billingAddress?.state}, {billingAddress?.postalCode}, {billingAddress?.country}
                    </p>
                )}
            </div>
        </div>
    );
};

export default OrderAdress;
