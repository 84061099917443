import { ChatRoomType } from "../../types/Data/ChatRoomType";
import { MessageType } from "../../types/Data/MessageType";
import CustomerMessageHistoryV1 from "./CustomerMessageHistoryV1/CustomerMessageHistoryV1";

enum CustomerMessageHistoryVersions {
    V1 = "v1",
};

const CustomerMessageHistoryMapping:Record<CustomerMessageHistoryVersions, React.FC<any>> = {
    [CustomerMessageHistoryVersions.V1]: CustomerMessageHistoryV1,
};

interface CustomerMessageHistoryProps {
    chatRooms?: ChatRoomType[],
    onClick?: (chatRoomId:string) => void,
};

export {
    CustomerMessageHistoryMapping,
    CustomerMessageHistoryVersions
};

export type {
    CustomerMessageHistoryProps
};