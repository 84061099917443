import { ReturnRequestType } from "../../types/Data/ReturnRequestType";
import CustomerReturnHistoryV1 from "./CustomerReturnHistoryV1/CustomerReturnHistoryV1";

enum CustomerReturnHistoryComponentVersions {
    V1 = "v1",
};

const CustomerReturnHistoryComponentMapping:Record<CustomerReturnHistoryComponentVersions, React.FC<any>> = {
    [CustomerReturnHistoryComponentVersions.V1]: CustomerReturnHistoryV1,  
};

interface CustomerReturnHistoryComponentProps {
    returnRequest?: Partial<ReturnRequestType>;
};

export {
    CustomerReturnHistoryComponentVersions,
    CustomerReturnHistoryComponentMapping
};

export type {
    CustomerReturnHistoryComponentProps
};
  