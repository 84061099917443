import React from 'react'
import styles from '../Component.module.scss';
import ShippingInformationFormV1 from '../../UIElements/ShippingInformationForm/ShippingInformationFormV1/ShippingInformationFormV1';
import ShippingInformationFormV2 from '../../UIElements/ShippingInformationForm/ShippingInformationFormV2/ShippingInformationFormV2';
import ShippingInformationFormV3 from '../../UIElements/ShippingInformationForm/ShippingInformationFormV3/ShippingInformationFormV3';

const ShippingInformationFormComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <ShippingInformationFormV1/>
        </div>
        <div className={styles.element}>
            <ShippingInformationFormV2/>
        </div>
        <div className={styles.element}>
            <ShippingInformationFormV3/>
        </div>
    </div>
  )
}

export default ShippingInformationFormComponentPage