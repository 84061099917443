import CompanyHistoryV1 from "../../UIElements/CompanyHistory/CompanyHistoryV1/CompanyHistoryV1";
import CompanyHistoryV2 from "../../UIElements/CompanyHistory/CompanyHistoryV2/CompanyHistoryV2";
import CompanyHistoryV3 from "../../UIElements/CompanyHistory/CompanyHistoryV3/CompanyHistoryV3";

enum CompanyHistoryVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const CompanyHistoryMapping:Record<CompanyHistoryVersions, React.FC<any>> = {
    [CompanyHistoryVersions.V1]: CompanyHistoryV1,
    [CompanyHistoryVersions.V2]: CompanyHistoryV2,
    [CompanyHistoryVersions.V3]: CompanyHistoryV3,
};

interface CompanyHistoryComponentProps {
    title?: string;
    milestones?: {
      date?: string;
      event?: string;
      description?: string;
      imageUrl?: string;
    }[];
};

export {
  CompanyHistoryVersions,
  CompanyHistoryMapping
};

export type {
    CompanyHistoryComponentProps
};
  