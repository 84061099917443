import { BadgeVersions } from "../../../UIElements/Badge/BadgeComponentProps";
import { BillignInformationFormVersions } from "../../../UIElements/BillingInformationComponentForm/BillingInformationFormComponentProps";
import { BlogSectionVersions } from "../../../UIElements/BlogSection/BlogSectionComponentProps";
import { ButtonAddToCartVersions } from "../../../UIElements/Button/ButtonAddToCart/ButtonAddToCartComponentProps";
import { ButtonBuyVersions } from "../../../UIElements/Button/ButtonBuy/ButtonBuyComponentProps";
import { ButtonProceedToCheckoutVersions } from "../../../UIElements/Button/ButtonProceedToCheckout/ButtonCheckoutComponent";
import { ButtonAddToWishlistVersions } from "../../../UIElements/Button/ButtonWishList/ButtonAddToWishlistComponentProps";
import { CartItemListVersions } from "../../../UIElements/CartItemList/CartItemListComponentProps";
import { CartSummarySidebarVersions } from "../../../UIElements/CartSummarySidebar/CartSummarySidebarComponentProps";
import { CategoryDisplayVersions } from "../../../UIElements/CategoryDisplay/CategoryDisplayComponentProps";
import { CouponPromotionVersions } from "../../../UIElements/CouponPromotion/CouponPromotionComponentProps";
import { CustomerSupportVersions } from "../../../UIElements/CustomerSupport/CustomerSupportComponentProps";
import { FeaturedProductsVersions } from "../../../UIElements/FeaturedProducts/FeaturedProductsComponentProps";
import { FeatureSectionVersions } from "../../../UIElements/FeatureSection/FeatureSectionComponentProps";
import { FilterModalComponentVersions } from "../../../UIElements/FilterModal/FilterModalComponentProps";
import { HeroBannerVersions } from "../../../UIElements/HeorBanner/HeroBannerComponentProps";
import { IncentiveVersions } from "../../../UIElements/Incentive/IncentiveComponentProps";
import { NewsLetterSignupVersions } from "../../../UIElements/NewsLetterSignup/NewsLetterSignupProps";
import { OrderConfirmationVersions } from "../../../UIElements/OrderConfirmation/OrderConfirmationComponentProps";
import { OrderSummaryVersions } from "../../../UIElements/OrderSummary/OrderSummaryComponentProps";
import { PaginationVersions } from "../../../UIElements/Pagination/PaginationComponentProps";
import { PaymentMethodSelectorVersions } from "../../../UIElements/PaymentMethodSelector/PaymentMethodSelectorComponentProps";
import { ProductDescriptionVersions } from "../../../UIElements/ProductDescription/ProducrDescriptionComponentProps";
import { ProductImageGalleryVersions } from "../../../UIElements/ProductImageGallery/ProductImageGalleryComponentProps";
import { ProductListVersions } from "../../../UIElements/ProductList/ProductListComponentProps";
import { ProductNameVersions } from "../../../UIElements/ProductName/ProductTitleComponentProps";
import { ProductPriceVersions } from "../../../UIElements/ProductPrice/ProductPricingComponentProps";
import { ProductRattingVersions } from "../../../UIElements/ProductRating/ProductRatingComponentProps";
import { ProductReviewVersions } from "../../../UIElements/ProductReview/ProductReviewComponentProps";
import { PromotionalBannerVersions } from "../../../UIElements/PromotionalBanner/PromotionalBannerComponentProps";
import { QuantitySelectorVersions } from "../../../UIElements/QuantitySelector/QuantitySelectorComponentProps";
import { ShippingInformationFormVersions } from "../../../UIElements/ShippingInformationForm/ShippingInformationFormComponentProps";
import { SocialMediaLinksVersions } from "../../../UIElements/SocialMediaLinks/SocialMediaLnksComponentProps";
import { SortVersions } from "../../../UIElements/Sort/SortComponentProps";
import { StepIndicatorVersions } from "../../../UIElements/StepIndicator/StepIndicatorComponentProps";
import { TestimonialVersions } from "../../../UIElements/Testimonial/TestimonialComponentProps";
import { TrustSignalsVersions } from "../../../UIElements/TrustSignals/TrustSignalsComponentProps";
import { VariantSelectorVersions } from "../../../UIElements/VariantSelector/VariantSelectorComponentProps";
import { BaseElementType, ComponentType } from "../../../types/Data/BaseElementType";
import { CompanyHistoryVersions } from "../../../UIElements/CompanyHistory/CompanyHistoryComponentProps";
import { CompanyOverviewVersions } from "../../../UIElements/CompanyOverview/CompanyOverviewComponentProps";
import { ContactInfoVersions } from "../../../UIElements/ContactInfo/ContactInfoComponentProps";
import { HeroSectionVersions } from "../../../UIElements/HeroSection/HeroSectionComponentProps";
import { LogoCloudVersions } from "../../../UIElements/LogoClouds/LogoCloudComponentProps";
import { TrustBadgeIconVersions } from "../../../UIElements/TrustBadgeIcons/TrustBadgeIconsComponentProps";
import { OrderErrorVersions } from "../../../UIElements/OrderError/OrderErrorComponentProps";
import { ContactFormVersions } from "../../../UIElements/ContactForm/ContactFormComponentProps";
import { CustomerEditProfilComponentVersions } from "../../../UIElements/CustomerEditProfilForm/CustomerEditProfilComponentProps";
import { CustomerOrderHistoryComponentVersions } from "../../../UIElements/CustomerOrderHistory/CustomerOrderHistoryType";
import { CustomerWishListComponentVersions } from "../../../UIElements/CustomerWishList/CustomerWishListComponentProps";
import { CustomerOrderDetailtComponentVersions } from "../../../UIElements/CustomerOrderDetail/customerOrderDetailComponentProps";
import { CustomerOrderTrackingComponentVersions } from "../../../UIElements/CustomerOrderTracking/customerOrderTrackingComponentProps";
import { CustomerProductReviewComponentVersions } from "../../../UIElements/CustomerProductReview/CustomerProductReviewComponentProps";
import { CustomerReturnRequestComponentVersions } from "../../../UIElements/CustomerOrderReturnRequest/CustomerOrderReturnRequestComponentProps";
import { CustomerNavigationComponentVersions } from "../../../UIElements/CustomerNavigation/CustomerNavigationComponentProps";
import CustomerReturnInfoCardV1 from "../../../UIElements/CustomerReturnHistory/CustomerReturnHistoryV1/CustomerReturnHistoryV1";
import { CustomerReturnHistoryComponentVersions } from "../../../UIElements/CustomerReturnHistory/CustomerReturnHistoryComponentProps";
import { TabNavigationVersions } from "../../../UIElements/TabNavigation/TabNavigationComponentProps";
import { FooterVersions } from "../../../UIElements/Footer/FooterComponentProps";
import { defaultCompanyHistoryProps } from "../../../UIElements/CompanyHistory/companyHistoryDefaultProps";
import { defaultTrustBadgeItems } from "../../../UIElements/TrustBadgeIcons/defaultTrustBadgeData";
import { CustomerMessageHistoryVersions } from "../../../UIElements/CustomerMessageHistory/CustomerMessageHistoryType";
import { CustomerChatRoomVersions } from "../../../UIElements/CustomerChatRoom/CustomerChatRoomProps";

const elementsExample: BaseElementType<any>[] = [
  {
    _id: new Date().toISOString(),
    page: '',  
    type: ComponentType.TabNavigation,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: TabNavigationVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {   
      showCart: true,
      showUserIcon: true,
      headerStyle: 'sticky',
      backgroundColor: '#ffffff',
      textColor: '#000000',
      fontStyle:'normal',
      headerHeight:  100,
      borderStyle: 'solid',
      shadow: true,
      displayMode: 'inline',
      items: [], 
    }
  },
  {
    _id: new Date().toISOString(),
    page: '',  
    type: ComponentType.Footer,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: FooterVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {   
      showCompanyInfo : true,
      showSocialMedia : true,
      showPaymentMethods : true,
      showDeliveryMethods : true,
      showNewsletterSubscription : true,
      backgroundColor : '',
      textColor : 'string',
      items : [], 
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'home',  
    type: ComponentType.HeroBanner,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: HeroBannerVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: { 
      slides : [
        {
          id: "banner1",
          title: "Discover the Future",
          text: "Explore the latest gadgets and tech innovations that are shaping tomorrow.",
          imageUrl: "https://images.unsplash.com/photo-1678852524356-08188528aed9?q=80&w=2360&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Replace with a real image URL
          buttonText: "Shop Now",
          buttonUrl: "/shop/new-releases",
          layout: "left", // or "right", "top", "bottom"
        },
        {
          id: "banner2",
          title: "Smart Home Solutions",
          text: "Make your home smarter with our selection of smart devices.",
          imageUrl: "https://images.unsplash.com/photo-1558089687-f282ffcbc126?q=80&w=2342&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Replace with a real image URL
          buttonText: "Discover",
          buttonUrl: "/shop/smart-home",
          layout: "right",
        },
        {
          id: "banner4",
          title: "Unleash Your Gaming Potential",
          text: "Upgrade your gaming setup with the latest consoles, accessories, and more.",
          imageUrl: "https://images.pexels.com/photos/9069002/pexels-photo-9069002.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", // Replace with a real image URL
          buttonText: "Browse Gaming",
          buttonUrl: "/shop/gaming",
          layout: "bottom",
        },
      ]    
    }
  },

  {
    _id: new Date().toISOString(),
    page: 'home',  
    type: ComponentType.FeaturedProducts,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: FeaturedProductsVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {    
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'home',  
    type: ComponentType.CategoryDisplay,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CategoryDisplayVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {    
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'home',  
    type: ComponentType.Incentive,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: IncentiveVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: { 
      title: 'Why Shop With Us?',
      description: 'We provide the best shopping experience for all your tech needs.',
      items: [
        {
          icon: 'FaShippingFast',
          title: 'Free Shipping',
          description: 'Enjoy free shipping on all orders above $50. Get your tech delivered fast!',
        },
        {
          icon: 'FaLock',
          title: 'Secure Payment',
          description: 'Your payment information is processed securely with advanced encryption.',
        },
        {
          icon: 'IoStarOutline',
          title: 'Premium Quality',
          description: 'We only offer top-tier, quality-certified tech products for our customers.',
        },
        {
          icon: 'FaGift',
          title: 'Exclusive Offers',
          description: 'Sign up for our newsletter and get access to exclusive offers and discounts.',
        },
        {
          icon: 'FaInfoCircle',
          title: 'Expert Support',
          description: 'Need help? Our tech experts are here to assist you 24/7 with any inquiries.',
        }
      ]  
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'home',  
    type: ComponentType.PromotionalBanner,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: PromotionalBannerVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {    
      banner: {
        title: "Fall Sale: Up to 50% Off",
        text: "Don't miss our biggest sale of the season! Grab your favorites at incredible discounts.",
        imageUrl: "https://static.lpnt.fr//images/2023/12/08/25689994lpw-25689997-article-jpg_9961404.jpg",
        buttonText: "Shop Now",
        buttonUrl: "/shop/fall-sale",
        version: PromotionalBannerVersions.V1,
      },
    }
  },

  {
    _id: new Date().toISOString(),
    page: 'home',  
    type: ComponentType.FeatureSection,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: FeatureSectionVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {
      title: 'Why Choose Us?',
      description: 'Explore the unique advantages that make us your go-to tech store. From performance to security, we have everything you need for a superior shopping experience.',
      features: [
        {
            title: 'Fast Performance',
            description: 'Our platform is designed for speed, giving you a smooth shopping experience every time.',
            icon: 'FaRocket'
        },
        {
            title: 'Top Security',
            description: 'Your data and transactions are protected with advanced security measures.',
            icon: 'FaShieldAlt'
        },
        {
            title: 'Customizable Solutions',
            description: 'We offer personalized recommendations and customizable bundles to fit your tech needs.',
            icon: 'FaCogs'
        },
        {
            title: 'Customer Support 24/7',
            description: 'Our support team is always available to assist you with any questions or concerns.',
            icon: 'FaHandsHelping'
        },
        {
            title: 'Hassle-Free Returns',
            description: 'Enjoy easy returns with our no-questions-asked return policy.',
            icon:'FaSyncAlt'
        },
      ]
    },
  },
  {
    _id: new Date().toISOString(),
    page: 'home',  
    type: ComponentType.NewsletterSignup,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: NewsLetterSignupVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {    
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'home',  
    type: ComponentType.BlogSection,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: BlogSectionVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {    
    }
  },
  
  {
    _id: new Date().toISOString(),
    page: 'home',  
    type: ComponentType.Testimonial,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: TestimonialVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'home',  
    type: ComponentType.CompanyHistory,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CompanyHistoryVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: { 
      ...defaultCompanyHistoryProps  
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'home',  
    type: ComponentType.CompanyOverview,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CompanyOverviewVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  
  {
    _id: new Date().toISOString(),
    page: 'home',  
    type: ComponentType.HeroSection,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: HeroSectionVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {  
      title: 'Discover the Future of Tech',
      text: 'Upgrade your life with the latest gadgets and cutting-edge technology. Shop now for exclusive deals on smartphones, laptops, smart home devices, and more.',
      imageUrl:'https://media.ldlc.com/apple/macbook_air_m3/images/hero_small_2x.png?1708555710106',
      buttonText: 'Shop Now',
      buttonUrl: '/shop/tech',
      layout: 'left',  
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'home',  
    type: ComponentType.LogoCloud,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: LogoCloudVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {  
      title: 'Trusted by leading tech innovators',
      logos: [
        "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/768px-Google_%22G%22_logo.svg.png",  // Google
        "https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg",       // Amazon
        "https://www.etapes.com/wp-content/uploads/2024/08/66467be0da27b14fe731a6dd_Apple-Icone.png",        // Apple
        "https://www.microsoft.com/fr-fr/microsoft-365/blog/wp-content/uploads/sites/34/2022/06/cropped-microsoft_logo_element.png",    // Microsoft
      ] 
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'home',  
    type: ComponentType.SocialMedia,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: SocialMediaLinksVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {    
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'home',  
    type: ComponentType.TrustBadgeIcon,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: TrustBadgeIconVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {  
      ...defaultTrustBadgeItems   
    }
  }, 
  {
    _id: new Date().toISOString(),
    page: 'home',  
    type: ComponentType.CustomerSupport,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CustomerSupportVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'shop',  
    type: ComponentType.ProductList,     
    version: ProductListVersions.V1,
    isVisible: true,
    position: {
        row: 3,    // Appears on the second row
        column: 1, // Spans from the first column
        span: 12,  // Spans the entire width (12 columns in a 12-column layout)
    },
    style: undefined,
    props: { }
},
{
    _id: new Date().toISOString(),
    page: 'shop',  
    type: ComponentType.Sort,      
    version: SortVersions.V1,
    isVisible: true,
    position: {
        row: 2,     // Appears in the first row (top)
        column: 12, // Last column (right-aligned)
    },
    style: undefined,
    props: { }
},
{
    _id: new Date().toISOString(),
    page: 'shop',  
    type: ComponentType.FilterModal,      
    version: FilterModalComponentVersions.V2,
    isVisible: true,
    position: {
        row: 2,    // Appears in the first row (top)
        column: 1, // First column (left-aligned)
    },
    style: undefined,
    props: { }
},
{
    _id: new Date().toISOString(),
    page: 'shop',  
    type: ComponentType.Pagination,     
    version: PaginationVersions.V2,
    isVisible: true,
    position: {
        row: 4,   // Appears in the third row
        column: 1,
        span: 12, // Spans full width
    },
    style: undefined,
    props: { }
},

  {
    _id: new Date().toISOString(),
    page: 'shop',  
    type: ComponentType.Badge,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: BadgeVersions.V1,
    isVisible: true,
    position: {
      row: 1,     // Appears in the first row (top)
      column: 1, // Last column (right-aligned)
      span: 12
    },
    style: undefined,
    props: {     
    }
  },
  
  {
    _id: new Date().toISOString(),
    page: 'product',  
    type: ComponentType.ProductImageGallery,     
    version: ProductImageGalleryVersions.V2,
    isVisible: true,
    position: {
      row: 1,
      column: 1,
      span: 6, 
      spanRows: 8
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'product',  
    type: ComponentType.ProductName,    
    version: ProductNameVersions.V1,
    isVisible: true,
    position: {
      row: 1,
      column: 7,  
      span: 6,   
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'product',  
    type: ComponentType.ProductDescription,    
    version: ProductDescriptionVersions.V2,
    isVisible: true,
    position: {
      row: 9,
      column: 1,
      span: 12,  
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'product',  
    type: ComponentType.VariantSelector,     
    version: VariantSelectorVersions.V1,
    isVisible: true,
    position: {
      row: 2,
      column: 7,
      span: 6,
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'product',  
    type: ComponentType.QuantitySelector,    
    version: QuantitySelectorVersions.V1,
    isVisible: true,
    position: {
      row: 3,
      column: 7,
      span: 3,
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'product',  
    type: ComponentType.ButtonAddToCart,     
    version: ButtonAddToCartVersions.V1,
    isVisible: true,
    position: {
      row: 4,
      column: 7,
      span: 6,
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'product',  
    type: ComponentType.ButtonAddWishList,    
    version: ButtonAddToWishlistVersions.V1,
    isVisible: true,
    position: {
      row: 3,
      column: 10,
      span: 3,
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'product',  
    type: ComponentType.ButtonBuy,     
    version: ButtonBuyVersions.V1,
    isVisible: true,
    position: {
      row: 5,
      column: 7,
      span: 6,
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'product',  
    type: ComponentType.ProductReviews,     
    version: ProductReviewVersions.V1,
    isVisible: true,
    position: {
      row: 11,
      column: 1,
      span: 12,
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'product',  
    type: ComponentType.TrustBadgeIcon,     
    version: TrustBadgeIconVersions.V1,
    isVisible: true,
    position: {
      row: 12,
      column: 1,
      span: 12,
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'product',  
    type: ComponentType.FeaturedProducts,     
    version: FeaturedProductsVersions.V6,
    isVisible: true,
    position: {
      row: 10,
      column: 1,
      span: 12,
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'cart',  
    type: ComponentType.StepIndicator,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: StepIndicatorVersions.V1,
    isVisible: true,
    position: {
      row: 1,
      column: 1,
      span: 12  // Spanning the entire width for the step indicator
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'cart',  
    type: ComponentType.CartItemList,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CartItemListVersions.V1,
    isVisible: true,
    position: {
      row: 2,
      column: 1,
      span: 8
    },
    style: undefined,
    props: {     
    }
  }, 
  {
    _id: new Date().toISOString(),
    page: 'cart',  
    type: ComponentType.OrderSummary,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: OrderSummaryVersions.V1,
    isVisible: true,
    position: {
      row: 2,
      column: 9,
      span: 4
    },    
    style: undefined,
    props: {     
    }
  }, 
  {
    _id: new Date().toISOString(),
    page: 'cart',  
    type: ComponentType.ButtonCheckout,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: ButtonProceedToCheckoutVersions.V1,
    isVisible: true,
    position: {
      row: 3,
      column: 9,
      span: 4
    },
    style: undefined,
    props: {     
    }
  }, 
  {
    _id: new Date().toISOString(),
    page: 'cart',  
    type: ComponentType.CouponPromotion,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CouponPromotionVersions.V1,
    isVisible: true,
    position: {
      row: 3,
      column: 1,
      span: 8
    },
    style: undefined,
    props: {     
    }
  }, 
  {
    _id: new Date().toISOString(),
    page: 'cart',  
    type: ComponentType.TrustSignals,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: TrustSignalsVersions.V1,
    isVisible: true,
    position: {
      row: 4,
      column: 1,
      span: 8
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'cart',  
    type: ComponentType.CustomerSupport,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CustomerSupportVersions.V2,
    isVisible: true,
    position: {
      row: 5,
      column: 1,
      span: 8
    },
    style: undefined,
    props: {     
    }
  },


  {
    _id: new Date().toISOString(),
    page: 'billingShipping',  
    type: ComponentType.StepIndicator,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: StepIndicatorVersions.V1,
    isVisible: true,
    position: {
      row: 1,
      column: 1,
      span: 12  // Spanning the entire width for the progress bar
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'billingShipping',  
    type: ComponentType.ShippingInformation,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: ShippingInformationFormVersions.V1,
    isVisible: true,
    position: {
      row: 2,
      column: 1,
      span: 8  // Spanning the left side of the grid for the form
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'billingShipping',  
    type: ComponentType.BillingInformation,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: BillignInformationFormVersions.V1,
    isVisible: true,
    position: {
      row: 3,
      column: 1,
      span: 8  // Spanning the left side of the grid, below the shipping form
    },
    style: undefined,
    props: {     
    }
  }, 
  {
    _id: new Date().toISOString(),
    page: 'billingShipping',  
    type: ComponentType.CartSummarySidebar,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CartSummarySidebarVersions.V1,
    isVisible: true,
    position: {
      row:2,
      column: 9,
      span: 5,
      spanRows:3
    },
    style: undefined,
    props: {     
    }
  }, 

  {
    _id: new Date().toISOString(),
    page: 'payment',  
    type: ComponentType.StepIndicator,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: StepIndicatorVersions.V1,
    isVisible: true,
    position: {
      row: 1,
      column: 1,
      span: 12  // Spanning the entire width for the step indicator
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'payment',  
    type: ComponentType.PaymentMethodSelector,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: PaymentMethodSelectorVersions.V1,
    isVisible: true,
    position: {
      row: 2,
      column: 1,
      span: 8  // Spanning the left side of the grid for the payment method selection
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'payment',  
    type: ComponentType.OrderSummary,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: OrderSummaryVersions.V1,
    isVisible: true,
    position: {
      row: 2,
      column: 9,
      span: 4  // Spanning the right side of the grid for the order summary
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'payment',  
    type: ComponentType.CustomerSupport,  // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CustomerSupportVersions.V1,
    isVisible: true,
    position: {
      row: 3,
      column: 9,
      span: 4  // Positioned below the order summary for customer support access
    },
    style: undefined,
    props: {     
    }
  }, 
  {
    _id: new Date().toISOString(),
    page: 'orderReview',  
    type: ComponentType.StepIndicator,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: StepIndicatorVersions.V1,
    isVisible: true,
    position: {
      row: 1,
      column: 1,
      span: 12  // Spanning the entire width for the step indicator
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'orderReview',  
    type: ComponentType.CartSummarySidebar,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CartSummarySidebarVersions.V1,
    isVisible: true,
    position: {
      row: 2,
      column: 1,
      span: 8  // Spanning the left side of the grid for the cart summary
    },
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'orderReview',  
    type: ComponentType.TrustSignals,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: TrustSignalsVersions.V1,
    isVisible: true,
    position: {
      row: 2,
      column: 9,
      span: 4  // Spanning the right side of the grid, complementing the cart summary
    },
    style: undefined,
    props: {     
    }
  }, 
  
  {
    _id: new Date().toISOString(),
    page: 'orderConfirmation',  
    type: ComponentType.OrderConfirmation,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: OrderConfirmationVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'orderError',  
    type: ComponentType.OrderError,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: OrderErrorVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'contactUs',  
    type: ComponentType.ContactForm,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: ContactFormVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'customerProfil',  
    type: ComponentType.CustomerNavigation,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CustomerNavigationComponentVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'customerProfil',  
    type: ComponentType.CustomerEditProfilForm,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CustomerEditProfilComponentVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'customerOrderHistory',  
    type: ComponentType.CustomerNavigation,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CustomerNavigationComponentVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'customerOrderHistory',  
    type: ComponentType.CustomerOrderHistory,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CustomerOrderHistoryComponentVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'customerWishList',  
    type: ComponentType.CustomerNavigation,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CustomerNavigationComponentVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'customerWishList',  
    type: ComponentType.CustomerWishList,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CustomerWishListComponentVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'customerOrderDetail',  
    type: ComponentType.CustomerOrderDetail,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CustomerOrderDetailtComponentVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'customerOrderDetail',  
    type: ComponentType.CustomerOrderTracking,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CustomerOrderTrackingComponentVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'customerOrderDetail',  
    type: ComponentType.CustomerProductReview,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CustomerProductReviewComponentVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'customerOrderDetail',  
    type: ComponentType.CustomerReturnRequest,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CustomerReturnRequestComponentVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'customerReturnRequest',  
    type: ComponentType.CustomerNavigation,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CustomerNavigationComponentVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'customerReturnRequest',  
    type: ComponentType.CustomerReturnHistory,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CustomerReturnHistoryComponentVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'customerMessageHistory',  
    type: ComponentType.CustomerNavigation,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CustomerNavigationComponentVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'customerMessageHistory',  
    type: ComponentType.CustomerMessageHistory,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CustomerMessageHistoryVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
  {
    _id: new Date().toISOString(),
    page: 'customerChatRoom',  
    type: ComponentType.CustomerChatRoom,      // Specifies the type of component (e.g., 'categoryDisplay', 'testimonial')
    version: CustomerChatRoomVersions.V1,
    isVisible: true,
    position:undefined,
    style: undefined,
    props: {     
    }
  },
]

export default elementsExample

