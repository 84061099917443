import { ContactInfoComponentProps } from "./ContactInfoComponentProps";

const defaultContactInfoProps: ContactInfoComponentProps = {
    address: "1234 Elm Street, Suite 567, Cityville, Countryland",
    phone: "+1 (555) 123-4567",
    email: "contact@company.com",
    workingHours: "Mon-Fri: 9:00 AM - 5:00 PM"
};

export {
    defaultContactInfoProps
}