// store/settingsSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import exampleDataShopSettings from './exampleDataShopSettings';
import { GeneralSettingsType } from '../../../types/Data/ShopSettingsType';
import { getShopSettings } from '../../../api/generalSettings/generalSettingsApi';

// Async Thunks
export const fetchShopSettings = createAsyncThunk(
  'refund/fetchShopSettings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getShopSettings();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

interface ShopSettingStateType {
  settings: GeneralSettingsType,
  loading: boolean,
  error: string | any
};

const initialState: ShopSettingStateType = {
  settings: {...exampleDataShopSettings},
  loading: false,
  error: null
};

const shopSettingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    dispatchUpdateSettings(state, action: PayloadAction<GeneralSettingsType>) {
      state.settings = action.payload
    },
    dispatchLoadSettings(state, action:PayloadAction<GeneralSettingsType>) {
      state.settings = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShopSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShopSettings.fulfilled, (state, action: PayloadAction<GeneralSettingsType>) => {
        state.loading = false;
        state.settings = action.payload;
      })
      .addCase(fetchShopSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
  }
});

export const { dispatchUpdateSettings, dispatchLoadSettings } = shopSettingsSlice.actions;
export default shopSettingsSlice.reducer;
