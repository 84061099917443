import React from 'react';
import styles from './SettingsPage.module.scss';
import DashboardHeader from '../../components/Header/DashboardHeader/DashboardHeader';
import ShopSettings from './ShopSettings/ShopSettings';

const SettingsPage: React.FC = () => {
  return (
    <div className={styles.settings_page}>
      <DashboardHeader title="General Settings" />
      <ShopSettings />
    </div>
  );
};

export default SettingsPage;
