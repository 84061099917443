import React from 'react';
import styles from './HeroSectionV1.module.scss';
import { BannerSlideType } from '../../../types/Data/BannerSlideType';
import { HeroSectionComponentProps } from '../HeroSectionComponentProps';
import defaultHeroSectionProps from '../heroSectionDefaulftProps';

const HeroSectionV1: React.FC<HeroSectionComponentProps> = ({
  title = defaultHeroSectionProps.title,
  text = defaultHeroSectionProps.text,
  imageUrl = defaultHeroSectionProps.imageUrl,
  videoUrl = defaultHeroSectionProps.videoUrl,
  buttonText = defaultHeroSectionProps.buttonText,
  buttonUrl = defaultHeroSectionProps.buttonUrl,
  layout = defaultHeroSectionProps.layout,
}) => {
  return (
    <div className={styles.hero_section}>
      <div className={styles.hero_overlay}>
      { layout === 'left' &&  <img className={styles.hero_image} src={imageUrl}/> }
        <div className={styles.hero_content}>
          <h1 className={styles.hero_title}>{title}</h1>
          <p className={styles.hero_subtitle}>{text}</p>
          <a className={styles.hero_button} href={buttonUrl}>
            {buttonText}
          </a>
        </div>
        { layout === 'right' &&  <img className={styles.hero_image} src={imageUrl}/> }
      </div>
    </div>
  );
};

export default HeroSectionV1;