import React from 'react';
import styles from './ButtonBuyV3.module.scss';
import { ButtonComponentProps } from '../../../../components/Button/ButtonComponentProps';

const ButtonBuyV3: React.FC<ButtonComponentProps> = () => (
  <button className={styles.rippleEffectButton}>
    Buy Now
  </button>
);

export default ButtonBuyV3;
