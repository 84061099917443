import React from 'react'
import styles from '../Component.module.scss';
import FeaturedProductsV1 from '../../UIElements/FeaturedProducts/FeaturedProductsV1/FeaturedProductsV1';
import FeaturedProductsV3 from '../../UIElements/FeaturedProducts/FeaturedProductsV3/FeaturedProductsV3';
import FeaturedProductsV2 from '../../UIElements/FeaturedProducts/FeaturedProductsV2/FeaturedProductsV2';
import FeaturedProductsV4 from '../../UIElements/FeaturedProducts/FeaturedProductsV4/FeaturedProductsV4';
import FeaturedProductsV5 from '../../UIElements/FeaturedProducts/FeaturedProductsV5/FeaturedProductsV5';

const FeaturedProductComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <FeaturedProductsV1/>
        </div>
        <div className={styles.element}>
            <FeaturedProductsV2/>
        </div>
        <div className={styles.element}>
            <FeaturedProductsV3/>
        </div>
        <div className={styles.element}>
            <FeaturedProductsV4/>
        </div>
        <div className={styles.element}>
            <FeaturedProductsV5/>
        </div>
    </div>
  )
}

export default FeaturedProductComponentPage