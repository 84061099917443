import React from "react";
import styles from './FooterDeliveryMethod.module.scss';
import { FaShippingFast } from "react-icons/fa";
import { ShippingMethodType } from "../../../../types/Data/ShippingSettingsType";

interface FooterDeliveryMethodProps {
    deliveryMethods: ShippingMethodType [];
}

const FooterDeliveryMethod: React.FC<FooterDeliveryMethodProps> = ({ deliveryMethods }) => {
    return (
        <div className={styles.footerContainer}>
            <div className={styles.label}> <FaShippingFast/>Delivery Methods</div>
            <div className={styles.deliveryMethods}>
                {deliveryMethods.map((method: ShippingMethodType, index: number) => (
                    <div className={styles.deliveryIconWrapper}>
                    <img
                        key={index}
                        src={`${method.logoUrl}`}
                        alt={method.name}
                        className={styles.deliveryIcon}
                    />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FooterDeliveryMethod;
