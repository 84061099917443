import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/store";
import styles from "./BlogSectionV1.module.scss";
import HtmlSanitizer from "../../../utils/sanitizeHtml/sanitizeHtml";
import { BlogPostType } from "../../../types/Data/BlogPostType";
import { BlogSectionComponentProps } from "../BlogSectionComponentProps";

const BlogSectionV1: React.FC<BlogSectionComponentProps> = ({onClick=()=>null}) => {
  const blogPosts = useSelector((state: RootState) => state.dashboard.blogPosts.blogPosts) as BlogPostType[];

  const createExcerpt = (html: string, length: number) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    const text = div.textContent || div.innerText || '';
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

const sanitizeHtml = new HtmlSanitizer();

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Blog Articles</h3>
      <div className={styles.blogSectionV1}>
        {blogPosts.map((post: BlogPostType) => {
          const sanitizedExcerpt = sanitizeHtml.sanitize(createExcerpt(post.content, 100));

          return (
              <div key={post._id} className={styles.blogCard} onClick={()=>onClick && onClick(post._id!)}>
              <img src={post.imageUrl} alt={post.title} className={styles.blogImage} />
              <div className={styles.blogContent}>
                  <h3>{post.title}</h3>
                  <p>{sanitizedExcerpt}</p>
              </div>
              </div>
          )
          })}
      </div>
    </div>
  );
};

export default BlogSectionV1;
