import React from 'react';
import styles from './OrderErrorV2.module.scss';
import { FaTimesCircle } from 'react-icons/fa';
import { OrderErrorComponentProps } from '../OrderErrorComponentProps';

const OrderErrorV2: React.FC<OrderErrorComponentProps> = ({ message = "Error", onRetry }) => {
  return (
    <div className={styles.orderError}>
      <div className={styles.iconContainer}>
        <FaTimesCircle className={styles.icon} />
      </div>
      <h2>Something Went Wrong</h2>
      <p>{message}</p>
      {onRetry && <button onClick={onRetry}>Retry</button>}
    </div>
  );
};

export default OrderErrorV2;
