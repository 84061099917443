import { OrderItemType } from "../../types/Data/OrderType";
import CustomerOrderReturnRequestV1 from "./CustomerOrderReturnRequestV1/CustomerOrderReturnRequestV1";


enum CustomerReturnRequestComponentVersions {
    V1 = "v1",
};

const CustomerReturnRequestComponentMapping:Record<CustomerReturnRequestComponentVersions, React.FC<any>> = {
    [CustomerReturnRequestComponentVersions.V1]: CustomerOrderReturnRequestV1,  
};

interface CustomerOrderReturnRequestComponentProps {
    items?: OrderItemType[];
    onSubmit?: (selectedItems: OrderItemType[], reason: string, notes: string) => void;
};

export {
    CustomerReturnRequestComponentVersions,
    CustomerReturnRequestComponentMapping
};

export type {
    CustomerOrderReturnRequestComponentProps
};