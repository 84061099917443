import React from 'react'
import styles from '../Component.module.scss';
import ContactInfoV1 from '../../UIElements/ContactInfo/ContactInfoV1/ContactInfoV1';
import ContactInfoV2 from '../../UIElements/ContactInfo/ContactInfoV2/ContactInfoV2';
import ContactInfoV3 from '../../UIElements/ContactInfo/ContactInfoV3/ContactInfoV3';

const ContactInfoComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <ContactInfoV1/>
        </div>
        <div className={styles.element}>
            <ContactInfoV2/>
        </div>
        <div className={styles.element}>
            <ContactInfoV3/>
        </div>
    </div>
  )
}

export default ContactInfoComponentPage