import React from 'react';
import styles from './CategoryFilterV1.module.scss';
import { CategoryComponentFilterProps } from '../../FilterComponentProps';

const CategoryFilterV1: React.FC<CategoryComponentFilterProps> = ({ options = ["electronis", "computer", "phone"], selectedOptions = [], onSelect = () => null }) => {
  return (
    <div className={styles.categoryFilterContainer}>
      <h3>Filter by Category</h3>
      <div className={styles.categoryList}>
        {options.map((category, index) => (
          <div
            key={index}
            className={`${styles.categoryItem} ${selectedOptions.includes(category) ? styles.selected : ''}`}
            onClick={() => onSelect(category)}
          >
            {category}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryFilterV1;
