import React from 'react';
import { EmailTemplateType } from '../../../../types/Data/EmailTemplateType';

const SeasonalSaleEmail:React.FC<EmailTemplateType> = ({ data }) => {
    const styles: {[key:string]: React.CSSProperties} = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            width: '100%',
            margin: '0 auto',
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            overflow: 'hidden',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            animation: 'slideIn 1s ease-in-out'
        },
        header: {
            backgroundColor: '#4bc0c0',
            color: '#ffffff',
            textAlign: 'center',
            padding: '20px',
            fontSize: '24px',
            fontWeight: 'bold'
        },
        body: {
            padding: '20px',
            textAlign: 'center'
        },
        bodyH2: {
            color: '#333333',
            fontSize: '22px',
            marginBottom: '20px'
        },
        bodyP: {
            color: '#666666',
            fontSize: '16px',
            marginBottom: '30px'
        },
        ctaButton: {
            display: 'inline-block',
            padding: '10px 20px',
            backgroundColor: '#3498db',
            color: '#ffffff',
            textDecoration: 'none',
            borderRadius: '4px',
            fontSize: '16px',
            fontWeight: 'bold',
            transition: 'background-color 0.3s ease, transform 0.2s ease'
        },
        ctaButtonHover: {
            backgroundColor: '#2980b9',
            transform: 'translateY(-2px)'
        },
        footer: {
            backgroundColor: '#f8f9fa',
            color: '#666666',
            textAlign: 'center',
            padding: '10px',
            fontSize: '14px'
        }
    };

    return (
        <div style={styles.container}>
            <style>
              {
                `@keyframes slideIn {
                  from { transform: translateX(-100%); }
                  to { transform: translateX(0); }
                }`
              }
            </style>
            <div style={styles.header}>
                Seasonal Sale!
            </div>
            <div style={styles.body}>
                {data?.body && <div 
                  dangerouslySetInnerHTML={{ __html: data.body }} 
                />}
                {data?.importantLinks && data.importantLinks.map((link, index) => (
                    <a 
                      key={index} 
                      href={link.url} 
                      style={styles.ctaButton}
                      onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = styles?.ctaButtonHover?.backgroundColor!;
                          e.currentTarget.style.transform = styles?.ctaButtonHover?.transform!;
                      }}
                      onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = styles?.ctaButton?.backgroundColor!;
                          e.currentTarget.style.transform = '';
                      }}
                    >
                      {link.label}
                    </a>
                ))}
            </div>
            <div style={styles.footer}>
                <p>Thank you for shopping with us!</p>
                <p>Unsubscribe | Contact Us</p>
            </div>
        </div>
    );
};

export default SeasonalSaleEmail;
