import React, { useState, useEffect } from 'react';
import styles from './OrderSummary.module.scss';
import { ProductType, VariantCombinationType } from '../../../../types/Data/ProductType';
import { OrderItemType } from '../../../../types/Data/OrderType';


interface OrderSummaryProps {
    items: OrderItemType[];
    onQuantityChange: (index: number, quantity: number) => void;
    onRemoveItem: (index: number) => void;
    shippingCost: number;
    discount: number;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({ items, onQuantityChange, onRemoveItem, shippingCost, discount }) => {
    const [subtotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        const calculatedSubtotal = items.reduce((acc, item) => acc + item.unitPrice * item.quantity, 0);
        setSubtotal(calculatedSubtotal);
        setTotal(calculatedSubtotal + shippingCost - discount);
    }, [items, shippingCost, discount]);

    const handleQuantityChange = (index: number, quantity: number) => {
        if (quantity >= 1) {
            onQuantityChange(index, quantity);
        }
    };

    return (
        <div className={styles.orderSummary}>
            <h4>Order Summary</h4>
            <ul className={styles.itemList}>
                {items.map((item, index) => (
                    <li key={`${item.productId}-${item.variant ? item.variant : 'main'}`} className={styles.item}>
                        <div className={styles.itemInfo}>
                            <span className={styles.itemName}>
                                {item.name} {item.variant ? `${item.variant}` : ''}
                            </span>
                            <span className={styles.itemPrice}>${item.unitPrice.toFixed(2)}</span>
                        </div>
                        <div className={styles.itemActions}>
                            <input 
                                type="number"
                                min="1"
                                value={item.quantity}
                                onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                                className={styles.quantityInput}
                            />
                            <button onClick={() => onRemoveItem(index)} className={styles.removeButton}>Remove</button>
                        </div>
                    </li>
                ))}
            </ul>

            <div className={styles.summarySection}>
                <div className={styles.summaryRow}>
                    <span>Subtotal:</span>
                    <span>${subtotal.toFixed(2)}</span>
                </div>
                <div className={styles.summaryRow}>
                    <span>Shipping Cost:</span>
                    <span>${shippingCost.toFixed(2)}</span>
                </div>
                <div className={styles.summaryRow}>
                    <span>Discount:</span>
                    <span>-${discount.toFixed(2)}</span>
                </div>
                <div className={styles.totalRow}>
                    <span>Total:</span>
                    <span>${total.toFixed(2)}</span>
                </div>
            </div>
        </div>
    );
};

export default OrderSummary;
