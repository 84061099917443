

interface MessageType {
    _id: string;
    chatRoom: string; // Reference to the ChatRoom
    sender: string; // Can be either an Admin or a Customer
    senderModel: 'admin' | 'customer'; // This defines the type of sender (Admin or Customer)
    content: string;
    createdAt: Date;
};
  
export function isMessageArray(messages: any): messages is MessageType[] {
  return Array.isArray(messages) && messages.every(
    (message) =>
      typeof message._id === 'string' &&
      typeof message.chatRoom === 'string' &&
      typeof message.sender === 'string' &&
      (message.senderModel === 'admin' || message.senderModel === 'customer') &&
      typeof message.content === 'string' &&
      message.createdAt instanceof Date
  );
};

export function isMessage(value: any): value is MessageType {
  return (
    value &&
    typeof value._id === 'string' &&
    typeof value.chatRoom === 'string' &&
    typeof value.sender === 'string' &&
    (value.senderModel === 'admin' || value.senderModel === 'customer') &&
    typeof value.content === 'string' &&
    value.createdAt instanceof Date
  );
}


export type { 
    MessageType
};