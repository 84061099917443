import React, { useState } from 'react';
import styles from './PriceRangeFilterV1.module.scss';
import { PriceRangeFilterComponentProps } from '../../FilterComponentProps';


const PriceRangeFilterV1: React.FC<PriceRangeFilterComponentProps> = ({ minPrice = 10, maxPrice = 1000, onPriceChange = () => null }) => {
  const [range, setRange] = useState([minPrice, maxPrice]);

  const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newRange = name === 'min' ? [Number(value), range[1]] : [range[0], Number(value)];
    setRange(newRange);
    onPriceChange(newRange[0], newRange[1]);
  };

  return (
    <div className={styles.priceRangeContainer}>
      <h3>Filter by Price</h3>
      <div className={styles.sliderContainer}>
        <input
          type="range"
          name="min"
          min={minPrice}
          max={maxPrice}
          value={range[0]}
          onChange={handleRangeChange}
          className={styles.slider}
        />
        <input
          type="range"
          name="max"
          min={minPrice}
          max={maxPrice}
          value={range[1]}
          onChange={handleRangeChange}
          className={styles.slider}
        />
      </div>
      <div className={styles.priceLabels}>
        <span>{`$${range[0]}`}</span>
        <span>{`$${range[1]}`}</span>
      </div>
    </div>
  );
};

export default PriceRangeFilterV1;
