import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../Store/store';
import styles from './SubscriberList.module.scss';
import { deleteSubscriber } from '../../DashboardState/subscribers/subscribersSlice';
import { SubscriberType } from '../../../types/Data/SubscriberType';

interface SubscriberListProps {
  subscribers: SubscriberType[],
  onEdit: (subscriber: SubscriberType) => void
};

const SubscriberList: React.FC<SubscriberListProps> = ({ subscribers=[], onEdit=()=>null }) => {
  const dispatch = useDispatch();

  const handleDelete = (id: string) => {
    dispatch(deleteSubscriber(id));
  };

  return (
    <div className={styles.subscriber_list}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Date Subscribed</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subscribers.map(subscriber => (
            <tr key={subscriber.id}>
              <td>{subscriber.name}</td>
              <td>{subscriber.email}</td>
              <td>{subscriber.dateSubscribed}</td>
              <td className={styles.actions}>
                <button className={`${styles.button} ${styles.edit}`} onClick={() => onEdit(subscriber)}>Edit</button>
                <button className={`${styles.button} ${styles.delete}`} onClick={() => handleDelete(subscriber.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubscriberList;
