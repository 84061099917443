import React from 'react';
import { FaSearch } from 'react-icons/fa';
import styles from './SearchBarV5.module.scss';
import { SearchBarComponentProps } from '../SearchBarComponentProps';


const SearchBarV5: React.FC<SearchBarComponentProps> = ({ value, onSearch, wrapperStyle }) => {
    return (
        <div className={styles.search_bar} style={{...wrapperStyle}}>
            <FaSearch className={styles.search_icon} />
            <input
                type="text"
                value={value}
                onChange={(e) => onSearch && onSearch(e.target.value)}
                placeholder="Search products..."
                className={styles.search_input}
            />
        </div>
    );
};

export default SearchBarV5;
