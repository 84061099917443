import React from 'react';
import styles from './FeedbackInfo.module.scss';
import { FaUser, FaEnvelope, FaComment, FaCalendarAlt, FaStar, FaInfoCircle } from 'react-icons/fa';
import { CustomerFeedbackType } from '../../../../types/Data/feedback';

interface FeedbackInfoProps {
    feedback?: CustomerFeedbackType;
}

const FeedbackInfo: React.FC<FeedbackInfoProps> = ({feedback}) => {
 

  return (
      <div className={styles.content}>
        <div className={styles.feedbackDetails}>
          <div className={styles.detailItem}>
            <FaUser className={styles.icon} />
            <p><strong>Customer Name:</strong> {feedback?.customer_name}</p>
          </div>
          <div className={styles.detailItem}>
            <FaEnvelope className={styles.icon} />
            <p><strong>Email:</strong> {feedback?.email}</p>
          </div>
          <div className={styles.detailItem}>
            <FaComment className={styles.icon} />
            <p><strong>Message:</strong> {feedback?.message}</p>
          </div>
          <div className={styles.detailItem}>
            <FaCalendarAlt className={styles.icon} />
            <p><strong>Date:</strong> { feedback?.date && new Date(feedback?.date).toLocaleDateString()}</p>
          </div>
          <div className={styles.detailItem}>
            <FaStar className={styles.icon} />
            <p><strong>Rating:</strong> {feedback?.rating} / 5</p>
          </div>
          <div className={styles.detailItem}>
            <FaInfoCircle className={styles.icon} />
            <p><strong>Status:</strong> {feedback?.status}</p>
          </div>
        </div>
      </div>

);
};

export default FeedbackInfo;
