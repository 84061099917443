import { AdminPermissionsType, AdminType } from "../../../types/Data/AdminType";

const adminPermissionsMock: AdminPermissionsType[] = [
    {
        canManageProducts: true,
        canManageOrders: true,
        canManageUsers: true,
        canManageCategories: true,
        canManageDiscounts: true,
        canManageSettings: true,
        canAccessReports: true,
    },
    {
        canManageProducts: true,
        canManageOrders: true,
        canManageUsers: false,
        canManageCategories: true,
        canManageDiscounts: false,
        canManageSettings: false,
        canAccessReports: true,
    },
    {
        canManageProducts: false,
        canManageOrders: true,
        canManageUsers: false,
        canManageCategories: false,
        canManageDiscounts: false,
        canManageSettings: false,
        canAccessReports: true,
    },
    {
        canManageProducts: false,
        canManageOrders: true,
        canManageUsers: true,
        canManageCategories: false,
        canManageDiscounts: false,
        canManageSettings: false,
        canAccessReports: false,
    },
];

const exampleAdmin: AdminType[] = [
    {
        _id: "admin001",
        username: "superadmin",
        email: "superadmin@example.com",
        passwordHash: "hashedpassword1",
        role: "SUPER_ADMIN",
        isActive: true,
        permissions: adminPermissionsMock[0],
        firstName: "John",
        lastName: "Doe",
        profileImageUrl: "https://example.com/images/admin1.jpg",
        lastLoginAt: new Date("2024-01-10T08:30:00Z"),
        createdAt: new Date("2023-06-15T09:00:00Z"),
        updatedAt: new Date("2024-01-10T08:31:00Z"),
    },
    {
        _id: "admin002",
        username: "admin",
        email: "admin@example.com",
        passwordHash: "hashedpassword2",
        role: "ADMIN",
        isActive: true,
        permissions: adminPermissionsMock[1],
        firstName: "Jane",
        lastName: "Smith",
        profileImageUrl: "https://example.com/images/admin2.jpg",
        lastLoginAt: new Date("2024-01-11T10:45:00Z"),
        createdAt: new Date("2023-07-20T10:00:00Z"),
        updatedAt: new Date("2024-01-11T10:46:00Z"),
    },
    {
        _id: "admin003",
        username: "mod_jones",
        email: "moderator@example.com",
        passwordHash: "hashedpassword3",
        role: "MODERATOR",
        isActive: true,
        permissions: adminPermissionsMock[2],
        firstName: "Tom",
        lastName: "Jones",
        profileImageUrl: "https://example.com/images/admin3.jpg",
        lastLoginAt: new Date("2024-01-09T09:00:00Z"),
        createdAt: new Date("2023-09-01T12:00:00Z"),
        updatedAt: new Date("2024-01-09T09:01:00Z"),
    },
    {
        _id: "admin004",
        username: "support_anna",
        email: "support@example.com",
        passwordHash: "hashedpassword4",
        role: "CUSTOMER_SUPPORT",
        isActive: true,
        permissions: adminPermissionsMock[3],
        firstName: "Anna",
        lastName: "Brown",
        profileImageUrl: "https://example.com/images/admin4.jpg",
        lastLoginAt: new Date("2024-01-12T11:15:00Z"),
        createdAt: new Date("2023-10-05T14:00:00Z"),
        updatedAt: new Date("2024-01-12T11:16:00Z"),
    },
];

export default exampleAdmin;
