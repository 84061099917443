import React, { useEffect } from "react";
import styles from './RecentReview.module.scss';
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../Store/store";
import { fetchOrders } from "../../DashboardState/orders/ordersSlice";
import LoadingIndicatorV1 from "../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1";
import ErrorBannerV1 from "../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1";
import { ReviewType } from "../../../types/Data/ReviewType";
import { useNavigate } from "react-router-dom";

interface RecentReviewProps {
    title?: string;
}

const RecentReview: React.FC<RecentReviewProps> = ({ title = "Recent Review" }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const reviews = useSelector((state: RootState) => state.dashboard.reviews.reviews) as ReviewType[];
    const loading = useSelector((state: RootState) => state.dashboard.reviews.loading) as boolean;
    const error = useSelector((state: RootState) => state.dashboard.reviews.error);

    useEffect(() => {
        if (reviews.length === 0) {
            dispatch(fetchOrders({ limit: 10, page: 1 }));
        }
    }, [dispatch, reviews.length]);

    const handleClick = (reviewId: string) => {
        navigate(`/dashboard/reviews/edit/${reviewId}`)
    };

    return (
        <div className={styles.recentActivity}>
            <h3>{title}</h3>
            {loading && <LoadingIndicatorV1 />}
            {error && <ErrorBannerV1 message={error} />}
            <ul className={styles.list}>
                {reviews.slice(0, 4).map((review) => (
                    <li className={styles.item} key={review._id} onClick={()=>handleClick(review._id)}>
                        {`ReviewId: ${review._id} - Rating: ${review.rating} - Content: ${review.content!.slice(0, 40)}${review.content!.length > 40 ? '...' : ''}`}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RecentReview;
