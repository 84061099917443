import React from 'react'
import styles from '../Component.module.scss';
import ColorSelectorV1 from '../../UIElements/ColorSelector/ColorSelectorV1/ColorSelectorV1';

const ColorSelectorComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <ColorSelectorV1/>
        </div>
    </div>
  )
}

export default ColorSelectorComponentPage