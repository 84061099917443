import React from 'react';
import styles from './ReturnItemDetails.module.scss';
import { ReturnOrderItemType, ReturnItemCondition, RefundEligibility, ReturnAdminApprovalStatus } from '../../../../types/Data/ReturnRequestType';

interface ReturnItemDetailsProps {
    items: ReturnOrderItemType[];
    onConditionChange: (index: number, condition: ReturnItemCondition) => void;
    onRefundEligibilityChange: (index: number, eligibility: RefundEligibility) => void;
    onAdminApprovalChange: (index: number, status: ReturnAdminApprovalStatus) => void;
}

const ReturnItemDetails: React.FC<ReturnItemDetailsProps> = ({
    items,
    onConditionChange,
    onRefundEligibilityChange,
    onAdminApprovalChange
}) => {
    return (
        <div className={styles.returnItemDetails}>
            <h4>Return Item Details</h4>
            <ul>
                {items.map((item, index) => (
                    <li key={item.product.toString()} className={styles.item}>
                        <div className={styles.itemHeader}>
                            <span>{item.productName} - Qty: {item.quantity} - Price: ${item.price}</span>
                        </div>
                        <div className={styles.itemBody}>
                            <div className={styles.inputGroup}>
                                <label>Condition After Return:</label>
                                <select
                                    value={item.conditionAfterReturn || ''}
                                    onChange={(e) => onConditionChange(index, e.target.value as ReturnItemCondition)}
                                >
                                    <option value="">Select Condition</option>
                                    {Object.values(ReturnItemCondition).map(condition => (
                                        <option key={condition} value={condition}>{condition}</option>
                                    ))}
                                </select>
                            </div>
                            <div className={styles.inputGroup}>
                                <label>Refund Eligibility:</label>
                                <select
                                    value={item.refundEligibility}
                                    onChange={(e) => onRefundEligibilityChange(index, e.target.value as RefundEligibility)}
                                >
                                    <option value="">Select Eligibility</option>
                                    {Object.values(RefundEligibility).map(eligibility => (
                                        <option key={eligibility} value={eligibility}>{eligibility}</option>
                                    ))}
                                </select>
                            </div>
                            <div className={styles.inputGroup}>
                                <label>Admin Approval Status:</label>
                                <select
                                    value={item.adminApprovalStatus}
                                    onChange={(e) => onAdminApprovalChange(index, e.target.value as ReturnAdminApprovalStatus)}
                                >
                                    <option value="">Select Status</option>
                                    {Object.values(ReturnAdminApprovalStatus).map(status => (
                                        <option key={status} value={status}>{status}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ReturnItemDetails;
