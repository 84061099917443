import React, { InputHTMLAttributes, useRef, useState } from 'react';
import styles from './FileUploadV1.module.scss';
import { FileUploadComponentProps } from '../FileUploadComponentProps';

const FileUploadV1: React.FC<FileUploadComponentProps> = ({ label, onFileSelect, wrapperStyle, ...props }) => {
  const [fileName, setFileName] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setFileName(file.name);
      onFileSelect && onFileSelect(file);
    } else {
      setFileName('');
      onFileSelect && onFileSelect(null);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleDelete = () => {
    setFileName('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    onFileSelect && onFileSelect(null);
  };

  return (
    <div className={styles.fileUpload} style={wrapperStyle}>
      {label && <p className={styles.label}>{label}</p>}
      <button type="button" onClick={handleButtonClick} className={styles.uploadButton}>
        {fileName || 'Choose a file'}
      </button>
      <input
        {...props}
        type="file"
        ref={fileInputRef}
        className={styles.fileInput}
        onChange={handleFileChange}
      />
      {fileName && (
        <div className={styles.fileDetails}>
          <span>{fileName}</span>
          <button onClick={handleDelete} className={styles.deleteButton}>Delete</button>
        </div>
      )}
    </div>
  );
};

export default FileUploadV1;
