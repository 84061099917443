import { PaymentMethodType, PaymentSettingsType } from "../../../types/Data/PayementSettingsType";

const dataExamplePaymentSettings: PaymentMethodType[] = [
      {
        name: 'Stripe',
        publicKey: 'your-stripe-public-key',
        privateKey: 'your-stripe-private-key',
        enabled: true
      },
      // Add more payment methods as needed
]

export default dataExamplePaymentSettings
  