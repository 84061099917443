import ColorSelectorV1 from "../../UIElements/ColorSelector/ColorSelectorV1/ColorSelectorV1";
import { ColorType } from "../../types/Data/ColorType";

enum ColorSelectorVersions {
    V1 = "v1",
};

const ColorSelectorMapping:Record<ColorSelectorVersions, React.FC<any>> = {
    [ColorSelectorVersions.V1]: ColorSelectorV1,
};

interface ColorSelectorComponentProps {
    colors?: ColorType[];
};

export {
    ColorSelectorVersions,
    ColorSelectorMapping
};

export type {
    ColorSelectorComponentProps 
}