import React from 'react'
import styles from '../Component.module.scss';
import QuantitySelectorV1 from '../../UIElements/QuantitySelector/QuantitySelectorV1/QuantitySelectorV1';
import QuantitySelectorV2 from '../../UIElements/QuantitySelector/QuantitySelectorV2/QuantitySelectorV2';
import QuantitySelectorV3 from '../../UIElements/QuantitySelector/QuantitySelectorV3/QuantitySelectorV3';

const QuantitySelectorComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <QuantitySelectorV1/>
        </div>
        <div className={styles.element}>
            <QuantitySelectorV2/>
        </div>
        <div className={styles.element}>
            <QuantitySelectorV3/>
        </div>
    </div>
  )
}

export default QuantitySelectorComponentPage