import { ProductType } from "../../types/Data/ProductType";
import { ProductPriceVersions } from "../ProductPrice/ProductPricingComponentProps";
import { ProductRattingVersions } from "../ProductRating/ProductRatingComponentProps";
import ProductNameV1 from "./ProductNameV1/ProductNameV1";

enum ProductNameVersions {
    V1 = "v1",
};

const ProductNameMapping:Record<ProductNameVersions, React.FC<any>> = {
    [ProductNameVersions.V1]: ProductNameV1,
};

interface ProductTitleComponentProps {
    product?: Partial<ProductType>;
    subtitle?: string;
    color?: string;
    fontSize?: string;
    fontWeight?: string;
    underline?: boolean;
    icon?: React.ReactNode;
    productPriceVersion?: ProductPriceVersions;
    productRatingVersion?: ProductRattingVersions;
}

export {
    ProductNameMapping,
    ProductNameVersions
};

export type {
    ProductTitleComponentProps
}