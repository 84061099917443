import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Store/store";
import styles from './EcommerceContactUs.module.scss';
import { BaseElementType } from "../../types/Data/BaseElementType";
import renderPageElement from "../renderPageElement";

const EcommerceContactUs: React.FC = () => {
    const contactUsPageElements = useSelector<RootState>((state) => state.ecommerce.ui.elements.filter((el)=>el.page==='contactUs')) as BaseElementType<any>[];

    return (

            <div className={styles.contactUsPageContainer}>
                {contactUsPageElements.map((element, index) => {
                    const Component = renderPageElement(element);
                    return (
                        <div className={styles.animatedSection} key={index}>
                            <Component />
                        </div>
                    );
                })}
            </div>

    );
};

export default EcommerceContactUs;
