// discountCodeApi.ts

import { AxiosResponse } from 'axios';
import api from '../config';
import { DiscountCodeType, DiscountConditionsType } from '../../types/Data/DiscountCodeType';
import { ApiResponse } from '../apiType';


export interface PaginationParams {
  search?: string;
  isActive?: boolean;
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
}

  // Create a new discount code
  export const createDiscountCode = async (data: DiscountCodeType): Promise<ApiResponse<DiscountCodeType>> => {
    try {
      const response: AxiosResponse<ApiResponse<DiscountCodeType>> = await api.post('/discount-codes', data);
      return response.data;
    } catch (error) {
      // Handle error as needed
      throw error;
    }
  };

  // Update an existing discount code by ID
  export const updateDiscountCode = async (
    discountCodeId: string,
    data: Partial<DiscountCodeType>
  ): Promise<ApiResponse<DiscountCodeType>> => {
    try {
      const response: AxiosResponse<ApiResponse<DiscountCodeType>> = await api.put(
        `/discount-codes/${discountCodeId}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Get a discount code by ID
  export const getDiscountCodeById = async (discountCodeId: string): Promise<ApiResponse<DiscountCodeType>> => {
    try {
      const response: AxiosResponse<ApiResponse<DiscountCodeType>> = await api.get(`/discount-codes/${discountCodeId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Get a discount code by code
  export const getDiscountCodeByCode = async (code: string): Promise<ApiResponse<DiscountCodeType>> => {
    try {
      const response: AxiosResponse<ApiResponse<DiscountCodeType>> = await api.get(`/discount-codes/code/${code}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Get all discount codes with optional filters, pagination, and sorting
  export const getAllDiscountCodes = async (
    params: PaginationParams
  ): Promise<ApiResponse<{discountCodes:DiscountCodeType[], total:number, pages:number}>> => {
    try {
      const response: AxiosResponse<ApiResponse<{discountCodes:DiscountCodeType[], total:number, pages:number}>> = await api.get('/discount-codes', {
        params,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Validate a discount code
  export const validateDiscountCode = async (
    code: string,
    conditions: DiscountConditionsType
  ): Promise<ApiResponse<DiscountCodeType>> => {
    try {
      const response: AxiosResponse<ApiResponse<DiscountCodeType>> = await api.post(
        `/discount-codes/validate/${code}`,
        conditions
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Apply discount code to an order total
  export const applyDiscount = async (
    code: string,
    orderTotal: number,
    conditions: DiscountConditionsType
  ): Promise<ApiResponse<number>> => {
    try {
      const response: AxiosResponse<ApiResponse<number>> = await api.post(
        `/discount-codes/apply/${code}`,
        { orderTotal, conditions }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Delete a discount code by ID
  export const deleteDiscountCode = async (discountCodeId: string): Promise<ApiResponse<null>> => {
    try {
      const response: AxiosResponse<ApiResponse<null>> = await api.delete(
        `/discount-codes/${discountCodeId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Activate a discount code
 export const activateDiscountCode = async (discountCodeId: string): Promise<ApiResponse<DiscountCodeType>> => {
    try {
      const response: AxiosResponse<ApiResponse<DiscountCodeType>> = await api.post(
        `/discount-codes/${discountCodeId}/activate`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Deactivate a discount code
  export const deactivateDiscountCode = async (discountCodeId: string): Promise<ApiResponse<DiscountCodeType>> => {
    try {
      const response: AxiosResponse<ApiResponse<DiscountCodeType>> = await api.post(
        `/discount-codes/${discountCodeId}/deactivate`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
};

