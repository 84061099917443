import React, { useEffect, useState } from 'react';
import { IoRemove } from 'react-icons/io5';
import styles from '../../UIElementsMainSettings/UiElementEditor.module.scss';
import Input4 from '../../../components/Input/InputText/InputV4/InputV4';
import { BaseElementType, ComponentType } from '../../../types/Data/BaseElementType';
import ButtonV1 from '../../../components/Button/ButtonV1/ButtonV1';
import { TrustSignalsComponentProps, TrustSignalsMapping, TrustSignalsVersions } from '../TrustSignalsComponentProps';
import { trustSignalsDefaultProps } from '../trustSignalsDefaultProps';
import UIElementsMainSettings from '../../UIElementsMainSettings/UIElementsMainSettings';
import ButtonDelete from '../../../components/Button/ButtonDelete/ButtonDelete';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../Store/store';
import { fetchCreateElement, fetchUpdateElement } from '../../../Dashboard/DashboardState/element/elementSlice';
import LoadingIndicatorV1 from '../../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1';
import ErrorBannerV1 from '../../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1';

interface TrustSignalsEditorProps {
  element?: BaseElementType<TrustSignalsComponentProps>;
}

const TrustSignalsComponentEditor: React.FC<TrustSignalsEditorProps> = ({ element }) => {
  
  const defaultElement: BaseElementType<TrustSignalsComponentProps> = {
    type: ComponentType.TrustSignals,
    version: 'v1',
    page: 'home',
    props: trustSignalsDefaultProps,
    position: undefined,
    isVisible: true,
  };

  const newItem = {
    title: '',
    description: '',
    icon: '',
  };

  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state:RootState)=>state.dashboard.elements.loading);
  const error = useSelector((state:RootState)=>state.dashboard.elements.error);

  const [currentElement, setCurrentElement] = useState<BaseElementType<TrustSignalsComponentProps>>(element ? element : defaultElement);

  useEffect(() => {
    if (element) {
      setCurrentElement(element);
    } else {
      setCurrentElement(defaultElement);
    }
  }, [element]);

  const handleElementUpdate = (key: string, value: any) => {
    setCurrentElement((prevElement) => ({
      ...prevElement,
      [key]: value,
    }));
  };

  const handleElementVisibilityUpdate = () => {
    setCurrentElement((prevElement) => ({ ...prevElement, isVisible: !prevElement.isVisible }));
  };

  const handlePropsChange = (key: string, value: any) => {
    setCurrentElement((prevElement) => ({
        ...prevElement,
        props: {
          ...prevElement.props,
          [key]: value
        },
    }));
  };

  const handleSaveElement = () => {
    if (currentElement?._id) {
      dispatch(fetchUpdateElement({id:currentElement._id, element:currentElement}))
    } else {
      dispatch(fetchCreateElement(currentElement))
    }
  };

  const SelectedComponent = TrustSignalsMapping[currentElement.version as TrustSignalsVersions] || TrustSignalsMapping[TrustSignalsVersions.V1];

  const elementsVersion = Object.keys(TrustSignalsVersions).map(key => ({
    label: key.replace(/^V/, 'V'),
    value: TrustSignalsVersions[key as keyof typeof TrustSignalsVersions],
  }));

  return (
    <div className={styles.container}>
      { error && <ErrorBannerV1 message={error}/>}
      { loading && <LoadingIndicatorV1/>}
      <div className={styles.componentPreview}>
        <SelectedComponent {...currentElement.props} />
      </div>
      <div className={styles.componentEditor}>
        <UIElementsMainSettings
            element={currentElement}
            elementVersions={elementsVersion}
            onElementUpdate={handleElementUpdate}
            onVisibilitytUpdate={handleElementVisibilityUpdate}
        />

        {/* Items Section */}
        <div className={styles.itemsSection}>
          <Input4
            label="Secure Payment Text"
            type="number"
            value={currentElement.props?.securePaymentText || ''}
            onChange={(e) => handlePropsChange('securePaymentText', e.target.value)}
          />
          <Input4
            label="Guarantee Text"
            type="number"
            value={currentElement.props?.guaranteeText || ''}
            onChange={(e) => handlePropsChange('guaranteeText', e.target.value)}
          />
          <Input4
            label="Satisfaction Text"
            type="text"
            value={currentElement.props?.satisfactionText || ''}
            onChange={(e) => handlePropsChange('satisfactionText', e.target.value)}
          />
          <Input4
            label="Customer Support Text"
            type="text"
            value={currentElement.props?.customerSupportText || ''}
            onChange={(e) => handlePropsChange('customerSupportText', e.target.value)}
          />
          <Input4
            label="Payment Methods"
            type="text"
            value={currentElement.props?.paymentMethods?.join( ) || ''}
            onChange={(e) => handlePropsChange('paymentMethods', e.target.value.split(' '))}
          />
          <Input4
            label="Quality Guarantee Text"
            type="text"
            value={currentElement.props?.qualityGuaranteeText || ''}
            onChange={(e) => handlePropsChange('qualityGuaranteeText', e.target.value)}
          />
          <Input4
            label="Secure Checkout Text"
            type="text"
            value={currentElement.props?.secureCheckoutText || ''}
            onChange={(e) => handlePropsChange('secureCheckoutText', e.target.value)}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <ButtonV1 label="Save Element" onClick={handleSaveElement} />
        <ButtonDelete/>
      </div>
    </div>
  );
};

export default TrustSignalsComponentEditor;
