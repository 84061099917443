// store/paymentSettingsSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PrivatePolicyType } from '../../../types/Data/PrivatePolicyType';
import { examplePrivatePolicy } from './examplePrivatePolicy';
import { getPrivatePolicy } from '../../../api/privatePolicy/privatePolicyApi';

export const fetchPrivatePolicy = createAsyncThunk(
  'privatePolicy/fetchPrivatePolicy',
  async (_, { rejectWithValue }) => {
      try {
          const response = await getPrivatePolicy();
          return {
              privatePolicy: response.data.data as PrivatePolicyType,
          };
          
      } catch (err: any) {
          return rejectWithValue('Failed to load orders');
      }
  }
);

interface PrivatePolicyState {
  privatePolicy: PrivatePolicyType;
  loading: boolean;
  error: string | null;
}

const initialState: PrivatePolicyState = {
  privatePolicy: examplePrivatePolicy,
  loading: false,
  error: null,
};

const privatePolicySlice = createSlice({
  name: 'privatePolicy',
  initialState,
  reducers: {
    dispatchUpdatePrivatePolicy(state, action: PayloadAction<PrivatePolicyType>) {
      state.privatePolicy = action.payload;
    },
    dispatchRemovePrivatePolicy(state, action: PayloadAction<null>) {
      state.privatePolicy.content = ''
    },
    dispatchLoadPrivatePolicy(state, action:PayloadAction<string>) {
      state.privatePolicy.content = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchPrivatePolicy.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchPrivatePolicy.fulfilled, (state, action: PayloadAction<{privatePolicy:PrivatePolicyType}>) => {
            state.privatePolicy = action.payload.privatePolicy;
        })
        .addCase(fetchPrivatePolicy.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
},
});

export const { dispatchUpdatePrivatePolicy, dispatchRemovePrivatePolicy, dispatchLoadPrivatePolicy, } = privatePolicySlice.actions;
export default privatePolicySlice.reducer;
