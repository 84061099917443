import React, { ChangeEvent } from 'react';
import styles from './CustomerAddressForm.module.scss';
import { AddressType } from '../../../types/Data/AdressType';
import { CustomerAdressFormComponentProps } from '../CustomerAdressFormComponentProps';


const CustomerAddressForm: React.FC<CustomerAdressFormComponentProps> = ({ address, onChange }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    onChange && onChange({ ...address, [name]: value });
  };

  return (
    <div className={styles.addressForm}>
      <div className={styles.field}>
        <label>Street</label>
        <input
          type="text"
          name="street"
          value={address?.street || ''}
          onChange={handleChange}
        />
      </div>
      <div className={styles.field}>
        <label>City</label>
        <input
          type="text"
          name="city"
          value={address?.city || ''}
          onChange={handleChange}
        />
      </div>
      <div className={styles.field}>
        <label>State</label>
        <input
          type="text"
          name="state"
          value={address?.state || ''}
          onChange={handleChange}
        />
      </div>
      <div className={styles.field}>
        <label>Postal Code</label>
        <input
          type="text"
          name="postalCode"
          value={address?.postalCode || ''}
          onChange={handleChange}
        />
      </div>
      <div className={styles.field}>
        <label>Country</label>
        <input
          type="text"
          name="country"
          value={address?.country || ''}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default CustomerAddressForm;
