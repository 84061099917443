import React from 'react';
import styles from './ProductCardListV1.module.scss';
import { ProductCardListComponentProps } from '../ProductCardListComponentProps';

const ProductCardListV1: React.FC<ProductCardListComponentProps> = ({ product, onProductClick = () => null }) => {
  return (
    <div className={styles.productCard} onClick={()=>onProductClick(product?._id!)}>
      <img src={product?.thumbnail || product?.images![0].url} alt={product?.name} className={styles.productImage} />
      <div className={styles.productInfo}>
        <h3 className={styles.productName}>{product?.name}</h3>
        <p className={styles.productPrice}>
          {product?.currency} {product?.discountPrice ?? product?.price}
        </p>
        <p className={styles.productCategory}>{product?.categories!.join(', ')}</p>
        <button className={styles.productButton}>View Details</button>
      </div>
    </div>
  );
};

export default ProductCardListV1;
