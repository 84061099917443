import React from 'react';
import styles from './BlogPostCard.module.scss';
import HtmlSanitizer from '../../../utils/sanitizeHtml/sanitizeHtml';
import Button1 from '../../../components/Button/ButtonV1/ButtonV1';
import { useNavigate } from 'react-router-dom';
import { BlogPostType } from '../../../types/Data/BlogPostType';

interface BlogPostCardProps {
  post: BlogPostType;
  onDelete: (id: string) => void;
}

const BlogPostCard: React.FC<BlogPostCardProps> = ({ post, onDelete }) => {
    const navigate = useNavigate();
  
    const createExcerpt = (html: string, length: number) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        const text = div.textContent || div.innerText || '';
        return text.length > length ? text.substring(0, length) + '...' : text;
      };
    
    const sanitizeHtml = new HtmlSanitizer();
    const sanitizedExcerpt = sanitizeHtml.sanitize(createExcerpt(post.content, 100));

    const handleEdit = (id:string) => {
        navigate(`/dashboard/blogPost/edit?blogPostId=${id}`);
    };

    return (
        <div className={styles.blog_post_card}>
            <img src={post.imageUrl} alt={post.title} className={styles.image} />
            <div className={styles.content}>
                <h3 className={styles.title}>{post.title}</h3>
                <p className={styles.date}>{ post?.createdAt && new Date(post?.createdAt).toDateString()}</p>
                <div className={styles.excerpt} dangerouslySetInnerHTML={{ __html: sanitizedExcerpt }} />
                <div className={styles.actions}>
                <Button1 label="Edit" onClick={() => handleEdit(post._id!)} />
                <Button1 label="Delete" onClick={() => onDelete(post._id!)} />
                </div>
            </div>
        </div>
  );
};

export default BlogPostCard;
