import React from 'react';
import styles from './PricingV1.module.scss';
import { PricingCardProps } from '../PricingCardProps';

interface PricingProps {
  label?: string;
  title?: string;
  text?: string;
  items?: PricingCardProps[];
}

const PricingV1: React.FC<PricingProps> = ({
  label = "Pricing",
  title = "Choose the right pricing for you",
  text = "",
  items = [],
}) => {
  return (
    <div className={styles.pricing_container}>
      <div className={styles.pricing_header}>
        <h6>{label}</h6>
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
      <div className={styles.pricing_list}>
        {items.map((item, index) => (
          <div
            className={styles.pricing_card}
            style={{ "--delay-index": index } as React.CSSProperties}
            key={index}
          >
            <div className={styles.header}>
              <h3>{item.title}</h3>
              <p className={styles.price}>{item.price}</p>
            </div>
            <ul className={styles.features}>
              {item.features.map((feature, idx) => (
                <li key={idx}>{feature}</li>
              ))}
            </ul>
            <a className={styles.button} href={item.buttonUrl}>
              {item.buttonText}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingV1;
