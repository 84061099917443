import React, { useState, useEffect } from 'react';
import styles from './ProductPromotionTable.module.scss'; // Assuming you're using CSS Modules
import { ProductPromotionType, PromotionType } from '../../../../types/Data/PromotionType';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Store/store';
import { ProductType } from '../../../../types/Data/ProductType';
import { getAllProducts } from '../../../../api/product/productApi';
import { handleApiError } from '../../../../api/apiError';

interface ProductPromotionTableProps {
  discountProducts?: ProductPromotionType[];
  onProductsChange?: (products: ProductPromotionType[]) => void;
  discountType: 'percentage' | 'fixed';
};

const ProductPromotionTable: React.FC<ProductPromotionTableProps> = ({ discountProducts = [], onProductsChange = () => null, discountType }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const productsFromState = useSelector<RootState>((state) => state.dashboard.products.products) as ProductType[];
  const [filteredProducts, setFilteredProducts] = useState<Partial<ProductType>[]>(productsFromState);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getProducts = async () => {
    setIsLoading(true);
    try {
      const response = await getAllProducts({ search: searchQuery });
      if (response?.status === 200) {
        setFilteredProducts(response.data.data.products);
      } else {
        alert('An error occurred while fetching products.');
      }
    } catch (error: any) {
      const handledError = handleApiError(error);
      console.error(handledError);
      alert(handledError.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      getProducts();
    } else {
      setFilteredProducts(productsFromState);
    }
  }, [searchQuery, productsFromState]);

  const handleDiscountChange = (productId: string, value: string) => {
    const updatedProducts = [...discountProducts];
    const existingProductIndex = updatedProducts.findIndex((product) => product.productId === productId);

    const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
   
      if (existingProductIndex !== -1) {
        updatedProducts[existingProductIndex] = {
          ...updatedProducts[existingProductIndex],
          [discountType === 'percentage' ? 'discountPercentage' : 'discountAmount']: parsedValue,
        };
      } else {
        updatedProducts.push({
          productId,
          [discountType === 'percentage' ? 'discountPercentage' : 'discountAmount']: parsedValue,
        }); 
    }
  return onProductsChange(updatedProducts)
}

  return (
    <div className={styles.tableContainer}>
      <div className={styles.searchBar}>
        <input
          type="text"
          placeholder="Search products..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className={styles.searchInput}
        />
      </div>

      {isLoading ? (
        <p>Loading products...</p>
      ) : (
        <table className={styles.productTable}>
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Price</th>
              <th>Purchase Price</th>
              <th>Max Discount %</th>
              <th>{discountType === 'percentage' ? 'Discount Percentage' : 'Discount Amount'}</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product) => {
              const discountProduct = discountProducts.find((el) => el.productId === product._id);
              const maxDiscountValue = product.price! - product.purchasePrice!;

              return (
                <tr key={product._id}>
                  <td>{product.name}</td>
                  <td>${product.price?.toFixed(2)}</td>
                  <td>${product.purchasePrice?.toFixed(2)}</td>
                  <td>
                    {((maxDiscountValue / product.price!) * 100).toFixed(2)}%
                  </td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      max={discountType === 'percentage' ? 100 : maxDiscountValue}
                      value={discountProduct ? discountProduct[discountType === 'percentage' ? 'discountPercentage' : 'discountAmount'] : ''}
                      onChange={(e) =>
                        handleDiscountChange(product._id!, e.target.value)
                      }
                      className={styles.discountInput}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ProductPromotionTable;
