import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import reviewsData from './reviewsData';
import { ReviewType, ReviewStatus } from '../../../types/Data/ReviewType';
import { getAllReviews } from '../../../api/review/reviewApi';


interface ReviewsState {
  reviews: Partial<ReviewType>[];
  loading: boolean;
  error: string | null;
  total: number;
  page: number;
  pages: number;
};

const initialState: ReviewsState = {
  reviews: [...reviewsData] as Partial<ReviewType>[],
  loading: false,
  error: null,
  page: 1,
  pages: 1,
  total:10,
};

// Create the async thunk for fetching products
export const fetchReviews = createAsyncThunk(
  'reviews/fetchReviews',
  async (params: { page: number; [key: string]: any }, { rejectWithValue }) => {
      try {
          const data = await getAllReviews(params);
        return data.data;
      } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || 'Failed to fetch reviews');
      }
  }
);

// Create the products slice
const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
      dispatchAddReview(state, action: PayloadAction<ReviewType>) {
          state.reviews.push(action.payload);
      },
      dispatchUpdateReview(state, action: PayloadAction<ReviewType>) {
          const index = state.reviews.findIndex(review => review._id! === action.payload._id);
          if (index !== -1) {
              state.reviews[index] = action.payload;
          }
      },
      dispatchDeleteReview(state, action: PayloadAction<string>) {
          state.reviews = state.reviews.filter(review => review._id! !== action.payload);
      },
      dispatchLoadReviews(state, action: PayloadAction<Partial<ReviewType>[]>) {
          state.reviews = action.payload;
      }
  },
  extraReducers: (builder) => {
      builder
          .addCase(fetchReviews.pending, (state) => {
              state.loading = true;
              state.error = null;
          })
          .addCase(fetchReviews.fulfilled, (state, action: PayloadAction<{ reviews: Partial<ReviewType>[]; total:number; page:number; pages:number }>) => {
              state.reviews = action.payload.reviews;
              state.page = action.payload.page;
              state.pages = action.payload.pages;
              state.total = action.payload.total;
          })
          .addCase(fetchReviews.rejected, (state, action) => {
              state.loading = false;
              state.error = action.payload as string || action.error.message as string || 'Unknown error';
          });
  },
});

export const {
  dispatchAddReview,
  dispatchUpdateReview,
  dispatchDeleteReview,
  dispatchLoadReviews,
} = reviewsSlice.actions;

export default reviewsSlice.reducer;
