import React from 'react';
import styles from './ProductImageGalleryV4.module.scss';
import { ProductImageGalleryComponentProps } from '../ProductImageGalleryComponentProps';
import { defaultProductImageGalleryProps } from '../defaultProductImageGalleryProps';

const ProductImageGalleryV4:React.FC<ProductImageGalleryComponentProps>   = ({ images = defaultProductImageGalleryProps.images }) => {
  return (
    <div className={styles.gridContainer}>
      {images.map((image, index) => (
        <div key={index} className={styles.gridItem}>
          <img src={image.url} alt={image.alt} className={styles.image} />
        </div>
      ))}
    </div>
  );
};

export default ProductImageGalleryV4;
