import { TabNavigationComponentProps } from "./TabNavigationComponentProps";

const tabNavigationDefaultProps: TabNavigationComponentProps = {
    showCart: true,
    showUserIcon: true,
    headerStyle: 'sticky',
    backgroundColor: '#ffffff',
    textColor: '#000000',
    fontStyle:'normal',
    headerHeight:  100,
    borderStyle: 'solid',
    shadow: true,
    displayMode: 'inline',
    items: [],
};

export {
    tabNavigationDefaultProps
}