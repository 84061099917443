import BreadcrumbV1 from "../../UIElements/Breadcrumb/BreadcrumbV1/BreadcrumbV1";
import BreadcrumbV2 from "../../UIElements/Breadcrumb/BreadcrumbV2/BreadcrumbV2";
import BreadcrumbV3 from "../../UIElements/Breadcrumb/BreadcrumbV3/BreadcrumbV3";

enum BreadcrumbVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const BreadcrumbMapping:Record<BreadcrumbVersions, React.FC<any>> = {
    [BreadcrumbVersions.V1]: BreadcrumbV1,
    [BreadcrumbVersions.V2]: BreadcrumbV2,
    [BreadcrumbVersions.V3]: BreadcrumbV3,
};

interface BreadcrumbType {
    label: string;
    href: string;
  }
  
interface BreadcrumbComponentProps {
    breadcrumbs?: BreadcrumbType[];
    separator?: string; 
}
export {
  BreadcrumbMapping,
  BreadcrumbVersions
}



export type {
  BreadcrumbType,
  BreadcrumbComponentProps
}