import ProductListV1 from "./ProductListV1/ProductListV1";
import ProductListV2 from "./ProductListV2/ProductListV2";
import ProductListV3 from "./ProductListV3/ProductListV3";
import { ProductType } from "../../types/Data/ProductType";
import { ProductCardListVersions } from "../ProductCardList/ProductCardListComponentProps";

enum ProductListVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const ProductListMapping:Record<ProductListVersions, React.FC<any>> = {
    [ProductListVersions.V1]: ProductListV1,
    [ProductListVersions.V2]: ProductListV2,
    [ProductListVersions.V3]: ProductListV3,
};

interface ProductListComponentProps {
    products?: ProductType[];
    onProductClick?: (productId: string) => void;
    cardVersion?: ProductCardListVersions;
}

export {
    ProductListMapping,
    ProductListVersions,
}

export type {
    ProductListComponentProps
}