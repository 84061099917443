import BadgeV1 from "./BadgeV1/BadgeV1";
import BadgeV2 from "./BadgeV2/BadgeV2";
import BadgeV3 from "./BadgeV3/BadgeV3";


interface BadgeType {
    label: string;
    color?: string;
}

enum BadgeVersions {
    V1 = "v1",
    V2 = "v2",
    V3 = "v3",
};

const BadgeMapping:Record<BadgeVersions, React.FC<any>> = {
    [BadgeVersions.V1]: BadgeV1,
    [BadgeVersions.V2]: BadgeV2,
    [BadgeVersions.V3]: BadgeV3,
};
  
interface BadgeComponentProps {
    badges?: BadgeType[];
    onBadgeChange?:(badges:BadgeType[])=>void
};

export {
    BadgeVersions,
    BadgeMapping
};

export type {
    BadgeType,
    BadgeComponentProps
}