import React from 'react';
import styles from './CompanyHistoryV3.module.scss';
import { defaultCompanyHistoryProps } from '../companyHistoryDefaultProps';
import { CompanyHistoryComponentProps } from '../CompanyHistoryComponentProps';

const CompanyHistoryV3: React.FC<CompanyHistoryComponentProps> = ({ 
    title = defaultCompanyHistoryProps.title, 
    milestones = defaultCompanyHistoryProps.milestones
}) => {
  return (
    <div className={styles.historySection}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.grid}>
        {milestones!.map((milestone, index) => (
          <div key={index} className={styles.gridItem}>
            <div className={styles.date}>{milestone.date}</div>
            {milestone.imageUrl && <img src={milestone.imageUrl} alt={milestone.event} className={styles.image} />}
            <h3 className={styles.event}>{milestone.event}</h3>
            {milestone.description && <p className={styles.description}>{milestone.description}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanyHistoryV3;
