enum PromotionGenre {
    PercentageDiscount = 'PercentageDiscount',
    FixedAmountDiscount = 'FixedAmountDiscount',
    BOGO = 'BOGO',  // Buy-One-Get-One
    BundleDeal = 'BundleDeal',
    FreeShipping = 'FreeShipping',
    Other = 'Other',  // For any custom or future promotion types
};

interface ProductPromotionType {
    productId: string;  // Reference to the Product model
    discountPercentage?: number;  // Optional, for percentage-based promotions
    discountAmount?: number;  // Optional, for fixed amount discounts
    customLabel?: string;  // For additional customization like "50% off"
};

// Interface for banner properties associated with a promotion
interface PromotionBannerType {
    imageUrl: string;  // URL to the banner image
    headline: string;  // Main headline text
    subheadline?: string;  // Optional subheadline text
    buttonText?: string;  // Optional text for a call-to-action button
    buttonUrl?: string;  // Optional URL where the button should navigate
    backgroundColor?: string;  // Optional background color for the banner
    textColor?: string;  // Optional text color for the banner content
};

interface PromotionCondition {
    minPurchaseAmount?: number; // Minimum purchase amount required
    minQuantity?: number; // Minimum quantity of products in the cart
    customerIsFirstTimeBuyer?: boolean; // Whether the customer is a first-time buyer
    applicableCategories?: string[]; // List of applicable product category IDs
    applicableProducts?: string[]; // List of specific product IDs to which the promotion applies
    validCustomerGroups?: string[]; // List of customer groups eligible for the promotion
    startTime?: Date; // Specific start time for the condition to be valid
    endTime?: Date; // Specific end time for the condition to be valid
    customCondition?: string; // For any custom or advanced conditions as a string of JSON logic or similar
}

interface PromotionType {
    _id?: string;
    name: string;  // Name of the promotion (e.g., "Summer Sale")
    description?: string;  // Optional, for detailed descriptions
    promotionType: PromotionGenre;  // The type of promotion
    conditions?: PromotionCondition;  // Array of conditions (e.g., ["Min purchase $50"])
    startDate: Date | string;  // When the promotion starts
    endDate: Date | string;  // When the promotion ends
    products: ProductPromotionType[];  // List of products with their specific promotions
    isActive: boolean;  // To quickly enable/disable the promotion
    banner?: PromotionBannerType;
    priority: number;  // Priority of the promotion
    isCumulative: boolean;  // Whether the promotion can be combined with others
};

export {
    PromotionGenre,
};

export type {
    ProductPromotionType,
    PromotionBannerType,
    PromotionCondition,
    PromotionType,
};

