// src/components/FileUpload.tsx
import React, { useState, useRef } from 'react';
import styles from './DragAndDropV1.module.scss';
import { DragAndDropComponentProps } from '../DragAndDropComponentProps';

const DragAndDropV1: React.FC<DragAndDropComponentProps> = ({ onFilesAdded, wrapperStyle }) => {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files);
    onFilesAdded && onFilesAdded(files);
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    onFilesAdded && onFilesAdded(files);
  };

  const openFileDialog = () => {
    fileInputRef.current?.click();
  };

  return (
    <div
      className={`${styles.fileUpload} ${isDragging ? styles.dragging : ''}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={openFileDialog}
      style={{...wrapperStyle}}
    >
      <input
        ref={fileInputRef}
        className={styles.fileInput}
        type="file"
        multiple
        onChange={handleFileSelect}
      />
      <p>Drag & drop files here, or click to select files</p>
    </div>
  );
};

export default DragAndDropV1;
