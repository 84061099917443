import React, { useEffect, useState } from "react";

import styles from './ProductListPage.module.scss'
import DashboardHeader from "../../components/Header/DashboardHeader/DashboardHeader";
import Filters from "./ProductFilters/ProductFilters";
import ProductList from "./ProductList/ProductsList";
import { useDispatch, useSelector } from "react-redux";
import { dispatchLoadProducts,  } from "../DashboardState/products/productsSlice";
import { AppDispatch, RootState } from "../../Store/store";
import LoadingIndicatorV1 from "../../components/LoadingIndicator/LoadingIndicatorV1/LoadingIndicatorV1";
import ErrorBannerV1 from "../../components/ErrorBanner/ErrorBannerV1/ErrorBannerV1";
import exampleProducts from "../DashboardState/products/dataProducts";

const ProductListPage = () => {
    const dispatch = useDispatch<AppDispatch>(); // Typing the dispatch with your store's dispatch type
    const [query, setQuery] = useState({
        search: '',
        category: '',
        sortBy: '',
        minPrice: 0,
        maxPrice: 1000,
        inStock: true,
        rating: 0,
    })

    //dispatch(dispatchLoadProducts(exampleProducts))
    
    const [page, setPage] = useState(1); // Separate page state

    // Select loading, error, and products state from Redux store
    const { loading, error, products } = useSelector((state: RootState) => state.dashboard.products);


    const handleQueryChange = (key:string, value:any ) => {
        setQuery((prev) => ({...prev, [key]:value}))
        setPage(1); // Reset page to 1 when query changes
    };

    /* 
    useEffect(() => {
        // Fetch products when query changes
        dispatch(fetchProducts({ ...query, page }));
    }, [query, dispatch]);
    */

    return (
        <div>
            <DashboardHeader title="Product List"/>
            <div className={styles.content}>
                <Filters
                    query={query}
                    onQueryChange={handleQueryChange}
                />
                {/* Handle loading state */}
                {loading && <LoadingIndicatorV1 />}
                
                {/* Handle error state */}
                {error && <ErrorBannerV1 message={typeof error === 'string' ? error : 'An error occurred'} />}
                
                {/* Display product list if not loading and no error */}
                {<ProductList products={products} />}
            </div>
        </div>
    )
};

export default ProductListPage