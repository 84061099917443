import React, { useEffect, useState } from "react";
import DashboardHeader from "../../../components/Header/DashboardHeader/DashboardHeader";
import styles from './ImageUploadPage.module.scss';
import ImageUploadComponent from "../ImageUploadComponent/ImageUploadComponent";
import { uploadMultipleImages } from "../../../api/image/imageApi";

const ImageUploadPage:React.FC = () => {
    
    const handleImagesUpload = async (files: File[]) => {
        const formData = new FormData();
    
        // Append each file individually to the formData
        files.forEach((file, idx) => {
            formData.append('images', file); // 'images' key for each file
        });
    
        try {
            const response = await uploadMultipleImages(formData);
    
            if (response.status === 201) {
                alert('Images uploaded succesfully')
            }
        } catch (error: any) {
            console.log(error);
            alert(`An error occurred while uploading images: ${error.message}`);
        }
    };

    return (
        <div>
            <DashboardHeader title="Upload Images"/>
            <div className={styles.content}>
                <ImageUploadComponent
                
                    onUpload={handleImagesUpload}
                />
            </div>
        </div>
    )
};

export default ImageUploadPage