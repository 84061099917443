import react from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import { CustomerType } from '../../../types/Data/CustomerType';
import styles from './CustomerAnalyticsPage.module.scss'
import ChartCustomerGrowth from './charts/ChartCustomerGrowth/ChartCustomerEvolutionOverTime';
import DashboardHeader from '../../../components/Header/DashboardHeader/DashboardHeader';
import ChartCustomerLoyalty from './charts/ChartCustomerLoyalty/ChartCustomerLoyalty';
import ChartCustomerAgeDistribution from './charts/ChartCustomerAgeDistribution/ChartCustomerAgeDistribution';
import ChartCustomerGenderDistribution from './charts/ChartCustomerGenderDistribution/ChartCustomerGenderDistribution';
import ChartCustomerRegionDistribution from './charts/ChartCustomerRegionDistribution/ChartCustomerRegionDistribution';
import ChartCustomerCommunicationPreference from './charts/ChartCustomerCommunicationPreference/ChartCustomerCommunicationPreference';

const CustomerAnalyticsPage: React.FC = () => {
    const customers = useSelector<RootState>((state) => state.dashboard.customers.customers) as CustomerType[];

    return (
        <div className={styles.analyticsPage}>
            <DashboardHeader title="Sales & Analytics Report" />
            <div className={styles.gridContainer}>
                {/* Sales Evolution */}
                <div className={styles.card}>
                    <ChartCustomerGrowth />
                </div>

                <div className={styles.card}>
                    <ChartCustomerLoyalty customers={customers}/>
                </div>

                {/* Order & Payment Breakdown */}
                <div className={styles.card}>
                    <ChartCustomerAgeDistribution />
                </div>

                <div className={styles.card}>
                    <ChartCustomerGenderDistribution />
                </div>

                <div className={styles.card}>
                    <ChartCustomerRegionDistribution />
                </div>

                <div className={styles.card}>
                    <ChartCustomerCommunicationPreference />
                </div>
            </div>
        </div>
    );
};

export default CustomerAnalyticsPage;
