import React, { useState } from 'react';
import styles from './Filters.module.scss';
import SearchBar4 from '../../../components/SearchBar/SearchBarV4/SerachBarV4';
import Select4 from '../../../components/Select/Select4/Select4';
import Checkbox1 from '../../../components/Checkbox/CheckboxV1/CheckboxV1';
import { ReviewStatus } from '../../../types/Data/ReviewType';

interface FiltersProps {
    query: {
        search: string;
        status?: string;
        rating: undefined,
        flagged: undefined,
        sortBy: undefined,
    };
    onQueryChange: (key: string, value: any) => void;
}

const Filters: React.FC<FiltersProps> = ({
    query,
    onQueryChange,
}) => {

    const statusOptions = Object.values(ReviewStatus).map((value)=>({label:value, value}))


    const ratings = [
        { label: "All Ratings", value: 0 },
        { label: "1 Star & Up", value: 1 },
        { label: "2 Stars & Up", value: 2 },
        { label: "3 Stars & Up", value: 3 },
        { label: "4 Stars & Up", value: 4 },
        { label: "5 Stars", value: 5 }
    ];

    const sortOptions = [
        { label: "Date (Newest First)", value: "date_desc" },
        { label: "Date (Oldest First)", value: "date_asc" },
        { label: "Rating (High to Low)", value: "rating_desc" },
        { label: "Rating (Low to High)", value: "rating_asc" }
    ];

    return (
        <div className={styles.filters}>
            <SearchBar4
                placeholder="Search by customer name..."
                value={query.search}
                onSearch={(value) => onQueryChange('search', value)}
            />
            <div className={styles.selectRow}>
                <Select4
                    options={statusOptions}
                    placeholder='Filter by Status'
                    onChange={(value) => onQueryChange('status', value as string)}
                    value={query.toString()}
                />
                <Select4
                    options={ratings}
                    placeholder='Filter by Rating'
                    onChange={(value) => onQueryChange('rating', value as string)}
                    value={query.toString()}
                />
                <Select4
                    options={sortOptions}
                    placeholder='Sort By'
                    onChange={(value) => onQueryChange('sortBy', value as string)}
                    value={query.sortBy}
                />
                <Checkbox1
                    label='Flagged'
                    checked={query.flagged}
                    onChange={(checked) => onQueryChange('flagged', checked)}
                />
            </div>
        </div>
    );
};

export default Filters;
