import HeroSectionV1 from "./HeroSectionV1/HeroSectionV1";

enum HeroSectionVersions {
    V1 = "v1", 
};

const HeroSectionMapping:Record<HeroSectionVersions, React.FC<any>> = {
    [HeroSectionVersions.V1]: HeroSectionV1,
};


interface HeroSectionComponentProps {
    title?: string,
    text?: string,
    imageUrl?: string,
    videoUrl?: string,
    buttonText?: string,
    buttonUrl?: string,
    layout?: 'left' | 'right',
};

export {
    HeroSectionVersions,
    HeroSectionMapping,
};

export type {
    HeroSectionComponentProps 
};