import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Store/store";
import { ProductType } from "../../types/Data/ProductType";
import styles from '../Home/EcommerceHome.module.scss';
import { useLocation, useNavigate } from "react-router-dom";
import renderPageElement from "../renderPageElement";

const InvalidComponent: React.FC = () => <div>Invalid Component</div>;

const EcommerceShop: React.FC = () => {
  const navigate = useNavigate();

  const shopPageElements = useSelector((state:RootState)=> state.ecommerce.ui.elements).filter((el)=>el.page === 'shop');
  const products = useSelector<RootState>((state) => state.dashboard.products.products) as ProductType[];


  const location = useLocation();
  const category = new URLSearchParams(location.search).get('category');

  const [query, setQuert] = useState({ category: undefined });

  const handleProductClick = (id:string) => {
    navigate(`/ecommerce/product/${id}`)
  }

  return (
    <div className={styles.page}>
    <div className={styles.grid}>
        {shopPageElements.map((element, index) => {
            const Component = renderPageElement(element);
            const { row, column, span, order, spanRows } = element.position || {};
            return (
                <div
                    key={index}  // Add unique key to prevent warnings
                    className={styles.gridItem}  // Add a specific class for the grid items
                    style={{
                        gridColumn: column ? `${column} / span ${span || 1}` : '1 / span 12',  // Set column start and span
                        gridRow: row && spanRows ? `${row} / span ${spanRows}` : row || 'auto',  // Set row start and span
                        order: order || 'initial',  // Set the order in case it's specified
                    }}
                >
                    <Component {...element.props} />
                </div>
            );
        })}
    </div>
</div>
  );
};

export default EcommerceShop;
