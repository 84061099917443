import React from "react";
import styles from "./TestimonialV3.module.scss";

interface Testimonial {
  id: string;
  name: string;
  role: string;
  text: string;
  avatar: string;
}

const testimonials: Testimonial[] = [
  {
    id: "1",
    name: "John Doe",
    role: "CEO of Company",
    text: "This is the best service I've ever used!",
    avatar: "https://via.placeholder.com/80",
  },
  {
    id: "2",
    name: "Jane Smith",
    role: "Marketing Manager",
    text: "Absolutely wonderful experience!",
    avatar: "https://via.placeholder.com/80",
  },
];

const TestimonialV3: React.FC = () => {
  return (
    <div className={styles.testimonialV3}>
      {testimonials.map((testimonial, index) => (
        <div
          key={testimonial.id}
          className={styles.testimonialCard}
          style={{ animationDelay: `${index * 0.1}s` }}
        >
          <div className={styles.testimonialContent}>
            <img src={testimonial.avatar} alt={testimonial.name} className={styles.avatar} />
            <div className={styles.name}>{testimonial.name}</div>
            <div className={styles.role}>{testimonial.role}</div>
            <div className={styles.text}>{testimonial.text}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TestimonialV3;
