import React, { useState, useEffect, ReactNode } from "react";
import styles from "./OrderPayment.module.scss";
import { OrderType, PaymentStatus } from "../../../../types/Data/OrderType";
import Input4 from "../../../../components/Input/InputText/InputV4/InputV4";
import Select4 from "../../../../components/Select/Select4/Select4";

// Default payment info object
const defaultOrderPayment = {
    paymentProvider: '',
    paymentMethod: '',
    transactionId: '',
    paymentFees: 0,
    paymentStatus: 'Pending', // Assuming "Pending" as a default
};

interface OrderPaymentProps {
    order: Partial<OrderType>;
    onUpdate: (updatedPayment: any) => void; // Callback to pass updated payment info to parent
    children?: ReactNode;
}

const OrderPayment: React.FC<OrderPaymentProps> = ({ order, onUpdate, children }) => {
    const [editablePayment, setEditablePayment] = useState(order.payment || defaultOrderPayment);

    useEffect(() => {
        setEditablePayment(order.payment || defaultOrderPayment);
    }, [order]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditablePayment({
            ...editablePayment,
            [name]: value,
        });
    };

    const handleUpdatePayment = () => {
        onUpdate(editablePayment); // Send updated payment info to parent
    };

    const paymentStatusOptions = Object.values(PaymentStatus).map((value) => ({ label: value, value }));

    return (
        <div className={styles.paymentInfo}>
            <h2>Payment Information</h2>
            <Input4
                label="Payment Provider"
                type="text"
                name="paymentProvider"
                value={editablePayment?.paymentProvider || ''}
                onChange={handleInputChange}
            />
            <Input4
                wrapperStyle={{ marginTop: 10 }}
                label="Payment Method"
                type="text"
                name="paymentMethod"
                value={editablePayment?.paymentMethod || ''}
                onChange={handleInputChange}
            />
            <Input4
                wrapperStyle={{ marginTop: 10 }}
                label="Transaction Id"
                type="text"
                name="transactionId"
                value={editablePayment?.transactionId || ''}
                onChange={handleInputChange}
            />
            <Input4
                wrapperStyle={{ marginTop: 10 }}
                label="Payment Fees"
                type="number"
                name="paymentFees"
                value={editablePayment?.paymentFees || 0}
                onChange={handleInputChange}
            />
            <Select4
                label="Payment Status"
                options={paymentStatusOptions}
                onChange={(value) => handleInputChange({ target: { name: 'paymentStatus', value } } as React.ChangeEvent<HTMLInputElement>)}
                value={editablePayment?.paymentStatus || ''}
            />
            { children && children }
        </div>
    );
};

export default OrderPayment;
