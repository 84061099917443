import React from 'react';
import { FaCheckCircle, FaTruck, FaBoxOpen, FaShippingFast, FaCircle } from 'react-icons/fa';
import styles from './CustomerOrderTrackingV1.module.scss';
import { CustomerOrderTrackingComponentProps } from '../customerOrderTrackingComponentProps';
import customerOrderTrackingDefaultProps from '../customerOrderTrackingDefaultProps';

const CustomerOrderTrackingV1: React.FC<CustomerOrderTrackingComponentProps> = ({ 
    currentStep = customerOrderTrackingDefaultProps.currentStep, 
    trackingUrl = customerOrderTrackingDefaultProps.trackingUrl
}) => {
  const steps = [
    { label: 'Order Placed', icon: <FaCircle /> },
    { label: 'Shipped', icon: <FaTruck /> },
    { label: 'Out for Delivery', icon: <FaShippingFast /> },
    { label: 'Delivered', icon: <FaBoxOpen /> },
  ];

  return (
    <div className={styles.trackingContainer}>
      <div className={styles.stepper}>
        {steps.map((step, index) => (
          <div key={index} className={styles.step}>
            <div
              className={`${styles.stepIcon} ${index <= currentStep! ? styles.active : ''}`}
            >
              {index <= currentStep! ? <FaCheckCircle /> : step.icon}
            </div>
            <p className={`${styles.stepLabel} ${index <= currentStep! ? styles.active : ''}`}>
              {step.label}
            </p>
            {index < steps.length - 1 && (
              <div className={`${styles.line} ${index < currentStep! ? styles.active : ''}`} />
            )}
          </div>
        ))}
      </div>

      {trackingUrl && (
        <div className={styles.trackOrder}>
          <a href={trackingUrl} target="_blank" rel="noopener noreferrer">
            Track Your Order
          </a>
        </div>
      )}
    </div>
  );
};

export default CustomerOrderTrackingV1;
