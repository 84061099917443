import React, { useState, ChangeEvent, FormEvent } from 'react';
import styles from '../../Home/EcommerceHome.module.scss';
import { CustomerType } from '../../../types/Data/CustomerType';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/store';
import renderPageElement from '../../renderPageElement';
import { ComponentType } from '../../../types/Data/BaseElementType';
import { useNavigate } from 'react-router-dom';

const CustomerWishListPage: React.FC = () => {
  const navigate = useNavigate();
  // Assuming customer is fetched asynchronously, initially it might be undefined

  const customeWishListPageElements = useSelector((state:RootState)=> state.ecommerce.ui.elements).filter((el) => el.page === 'customerWishList');

  const customer = useSelector((state:RootState)=>state.ecommerce.user.userInfo) as CustomerType;

  const handleNavigate = (path:string) => {
    switch (path) {
        case 'profile':
            navigate('/ecommerce/customer')
            break;
        case 'orders':
            navigate('/ecommerce/customer/orders')
            break;
        case 'wishlist':
            navigate('/ecommerce/customer/wishlist')
            break;
        case 'returns':
            navigate('/ecommerce/customer/returns')
            break;
        case 'messages':
            navigate('/ecommerce/customer/messages')
            break;
        case 'logout':
            navigate('/ecommerce/login')
            break;
        default:
            break;
    }
  }

  const onSave = (data: CustomerType) => {
    console.log('Saving customer data:', data);
    // Implement actual save logic here (API call, etc.)
  };

  const onCancel = () => {
    console.log('Canceling changes');
    // Implement cancel logic here
  };

  const getComponentProps = (
    componentType: ComponentType,
  ) => {
    switch (componentType) {
      case ComponentType.CustomerEditProfilForm:
        return {
          customer: customer,
          onSave: onSave,
          onCancel: onCancel
        };
      case ComponentType.CustomerNavigation:
        return {
            onClick: handleNavigate,
            activeTab: 'wishlist',
         };
      default:
        return {};
    }
  };

  return (
    <div className={styles.page}>
        <div className={styles.grid}>
            {customeWishListPageElements.map((element, index) => {
                const Component = renderPageElement(element);
                const { row, column, span, order, spanRows } = element.position || {};
                const props = {...getComponentProps(element.type), ...element.props}
                return (
                    <div
                        key={index}  // Add unique key to prevent warnings
                        className={styles.gridItem}  // Add a specific class for the grid items
                        style={{
                            gridColumn: column ? `${column} / span ${span || 1}` : '1 / span 12',  // Set column start and span
                            gridRow: row && spanRows ? `${row} / span ${spanRows}` : row || 'auto',  // Set row start and span
                            order: order || 'initial',  // Set the order in case it's specified
                        }}
                    >
                        <Component {...props}/>
                    </div>
                );
            })}
        </div>
    </div>
    );
};

export default CustomerWishListPage;
