import React from 'react'
import styles from '../Component.module.scss';
import NewsletterSignupV1 from '../../UIElements/NewsLetterSignup/NewsLetterSignupV1/NewsletterSignupV1';
import NewsletterSignupV2 from '../../UIElements/NewsLetterSignup/NewsLetterSignupV2/NewsLetterSignupV2';
import NewsletterSignupV3 from '../../UIElements/NewsLetterSignup/NewsLetterSignupV3/NewsLetterSignupV3';

const NewsLetterSignupComponentPage = () => {
  return (
    <div className={styles.container_element}>
        <div className={styles.element}>
            <NewsletterSignupV1/>
        </div>
        <div className={styles.element}>
            <NewsletterSignupV2/>
        </div>
        <div className={styles.element}>
            <NewsletterSignupV3/>
        </div>
    </div>
  )
}

export default NewsLetterSignupComponentPage