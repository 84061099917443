import { AddressType } from "./AdressType";
import { CustomerType } from "./CustomerType";
import { OrderItemType, OrderSummary, Payment, Shipping, Notes, Coupon } from "./OrderType";

export enum CartStatus {
    ACTIVE = "Active",
    IN_PROGRESS = "In Progress",
    COMPLETED = "Completed",
    ABANDONED = "Abandoned"
}

interface CartType {
    _id?: string;
    customer?: Partial<CustomerType>; // Customer details, if available
    items: OrderItemType[]; // Items in the cart
    cartSummary: OrderSummary; // Updated as items or prices change
    payment?: Payment; // Payment info, once chosen
    shipping?: Shipping; // Shipping info, once selected
    status: CartStatus; // Reflects the current cart status
    coupons?: Coupon | null; // Applied coupons, if any
    notes?: Notes; // Customer and internal notes
    createdAt?: Date;
    updatedAt?: Date;
}

// Initialize the cart with essential properties
export const initializeCart = (cartId: string): CartType => ({
    _id:undefined,
    items: [],
    cartSummary: { subtotal: 0, totalAmount: 0 }, // Subtotal and total will update
    status: CartStatus.ACTIVE,
    createdAt: new Date(),
    updatedAt: new Date()
});

export type { 
    CartType 
};
