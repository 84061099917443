import React, { useState } from 'react';
import styles from './CustomerOrderReturnRequestV1.module.scss'; // Adjust the path as necessary
import { CustomerOrderReturnRequestComponentProps } from '../CustomerOrderReturnRequestComponentProps';
import { OrderItemType } from '../../../types/Data/OrderType';
import { returnRequestDefaultProps } from '../customerOrderReturnrequestDefaultProps';

const CustomerOrderReturnRequestV1: React.FC<CustomerOrderReturnRequestComponentProps> = ({ 
  items = returnRequestDefaultProps.items, 
  onSubmit = returnRequestDefaultProps.onSubmit 
}) => {
  const [selectedItems, setSelectedItems] = useState<OrderItemType[]>([]);
  const [returnReason, setReturnReason] = useState('');
  const [notes, setNotes] = useState('');

  const handleItemSelection = (item: OrderItemType) => {
    setSelectedItems((prev) => {
      if (prev.includes(item)) {
        return prev.filter((i) => i !== item);
      } else {
        return [...prev, item];
      }
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(selectedItems, returnReason, notes);
  };

  return (
    <div className={styles.returnRequest}>
      <h2>Request a Return</h2>
      <form onSubmit={handleSubmit}>
        <table className={styles.itemsTable}>
          <thead>
            <tr>
              <th>Select</th>
              <th>Item Name</th>
              <th>Quantity</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr key={item.productId} className={selectedItems.includes(item) ? styles.selectedRow : ''}>
                <td>
                  <input
                    type="checkbox"
                    onChange={() => handleItemSelection(item)}
                    checked={selectedItems.includes(item)}
                  />
                </td>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td>${item.unitPrice.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className={styles.formGroup}>
          <label htmlFor="returnReason">Return Reason</label>
          <select id="returnReason" value={returnReason} onChange={(e) => setReturnReason(e.target.value)}>
            <option value="">Select Reason</option>
            <option value="Damaged Item">Damaged Item</option>
            <option value="Wrong Item">Wrong Item</option>
            <option value="Not as Described">Not as Described</option>
            <option value="Changed Mind">Changed Mind</option>
          </select>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="notes">Additional Notes</label>
          <textarea
            id="notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Add any additional notes here..."
          />
        </div>

        <button type="submit" className={styles.submitButton}>Submit Return Request</button>
      </form>
    </div>
  );
};

export default CustomerOrderReturnRequestV1;
